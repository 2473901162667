import {
  TextField,
  Box,
  Stack,
  Button,
  IconButton,
  ClickAwayListener,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import apolloClient from 'shared/apolloClient';
import { CalendarIcon, CrossIcon, ReportsIcon } from 'shared/components/icons';
import { ErrorSnackbar } from 'shared/components/Snackbars';
import { usePalette } from 'shared/hooks';
import { downloadReportCSV } from 'shared/utils/csv';
import { isValidDate } from 'shared/utils/date';
import { reportQuery } from './api';
import { defaultReportDates } from './Reports';

const RangeShortcut = {
  today: 'TODAY',
  thisWeek: 'THIS_WEEK',
  currentMonth: 'CURRENT_MONTH',
  reset: 'RESET'
};

type RangeShortcutType = keyof typeof RangeShortcut;

const buildHandleRangeClick = (setValue: (dates: DateRange<Date>) => void) => (range: RangeShortcutType) => {
  const today = dayjs();

  switch (range) {
    case RangeShortcut.today:
      setValue([today.startOf('day').toDate(), today.toDate()]);
      break;
    case RangeShortcut.thisWeek:
      setValue([today.startOf('week').toDate(), today.endOf('week').toDate()]);
      break;
    case RangeShortcut.currentMonth:
      setValue([today.startOf('month').toDate(), today.endOf('month').toDate()]);
      break;
    case RangeShortcut.reset:
      setValue([null, null]);
      break;
    default:
      break;
  }
};

interface RangeShortcutsPanelProps {
  setValue: (dates: DateRange<Date>) => void;
  value?: DateRange<Date>;
  refetchReport: (arg: DateRange<Date>) => any;
  clearReportDates: () => void;
}

function RangeShortcutsPanel({
  setValue,
  value,
  refetchReport,
  clearReportDates
}: RangeShortcutsPanelProps): JSX.Element {
  const intl = useIntl();
  const { secondary } = usePalette();
  const [rangeDate, setRangeDate] = useState<DateRange<Date>>(defaultReportDates);
  const rangeShortcuts = [
    {
      range: RangeShortcut.today,
      label: intl.formatMessage({ id: 'today' })
    },
    {
      range: RangeShortcut.thisWeek,
      label: intl.formatMessage({ id: 'this_week' })
    },
    {
      range: RangeShortcut.currentMonth,
      label: intl.formatMessage({ id: 'this_month' })
    }
  ];
  const [period, setPeriod] = useState<RangeShortcutType | null>(RangeShortcut.thisWeek as RangeShortcutType);
  const setValueFunc = useCallback(
    (value: DateRange<Date>) => {
      setValue(value);
      setRangeDate(value);
    },
    [setValue]
  );

  const handleRangeClick = useCallback(
    (range: RangeShortcutType) => {
      if (range === RangeShortcut.reset) {
        setPeriod(null);
        buildHandleRangeClick(setValueFunc)(range);
        clearReportDates();
        return;
      }
      buildHandleRangeClick(setValueFunc)(range);
      buildHandleRangeClick(refetchReport)(range);
      setPeriod(range);
    },
    [clearReportDates, refetchReport, setValueFunc]
  );

  useEffect(() => {
    if (period === null && value?.[0] === rangeDate[0] && value?.[1] === rangeDate[1]) {
      buildHandleRangeClick(setValueFunc)(RangeShortcut.reset as RangeShortcutType);
      clearReportDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    if (value?.[0] !== rangeDate[0] || value?.[1] !== rangeDate[1]) {
      setPeriod(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ToggleButtonGroup
      value={period}
      exclusive
      onChange={(e, v) => {
        handleRangeClick(v);
      }}
      orientation='horizontal'
      sx={{
        color: secondary[800],
        gap: '6px',
        mr: '4px',
        '& > button': {
          fontSize: '16px',
          lineHeight: '20px',
          color: secondary[700],
          padding: '12px 24px',
          whiteSpace: 'nowrap',
          display: 'flex',
          gap: '12px',
          textTransform: 'none',
          '&.Mui-selected': {
            background: `${secondary[100]} !important`,
            borderColor: `${secondary[600]} !important`,
            color: secondary[800]
          }
        }
      }}
    >
      {rangeShortcuts.map(({ range, label }) => (
        <ToggleButton key={range} value={range}>
          {label}
          {period === range && (
            <IconButton
              onClick={() => {
                handleRangeClick(RangeShortcut.reset as RangeShortcutType);
              }}
            >
              <CrossIcon color={secondary[700]} />
            </IconButton>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export type DateRange<TDate> = [TDate | null, TDate | null];

export interface PaperContentComponentProps {
  refetchReport: (arg: DateRange<Date>) => void;
  value: DateRange<Date>;
  setValue: (dates: DateRange<Date>) => void;
  clearReportDates: () => void;
}

export default function PaperContentComponent({
  refetchReport,
  value,
  setValue,
  clearReportDates
}: PaperContentComponentProps): JSX.Element {
  const intl = useIntl();
  const { primary, secondary, info } = usePalette();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const toggleCalendars = useCallback(() => {
    setIsCalendarOpen((prev) => !prev);
  }, []);

  const cancelButtonHandler = useCallback(() => {
    setValue([null, null]);
    clearReportDates();
    setIsCalendarOpen(false);
  }, [clearReportDates, setValue]);

  const generateButtonHandler = useCallback(() => {
    refetchReport(value);
    setIsCalendarOpen(false);
  }, [refetchReport, value]);

  const downloadReport = useCallback(async () => {
    const reportsResult = await apolloClient.query({
      query: reportQuery,
      variables: {
        offset: 0,
        limit: 99999,
        filter: {
          startDate: value[0] ?? dayjs().startOf('week').hour(12).toDate(),
          endDate: value[1] ?? new Date()
        }
      }
    });
    const reportsResultData = reportsResult.data.report.data;
    if (!reportsResultData.length) {
      return toast(
        <ErrorSnackbar
          title={intl.formatMessage({ id: 'table.empty' })}
          message={intl.formatMessage({ id: 'try_selecting_other_options' })}
        />
      );
    }
    await downloadReportCSV(reportsResultData, intl.formatMessage, value);
  }, [intl, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={4} alignItems='center' position='relative'>
        <Box display='flex' gap={3}>
          <RangeShortcutsPanel
            setValue={setValue}
            value={value}
            refetchReport={refetchReport}
            clearReportDates={clearReportDates}
          />
          <TextField
            label={intl.formatMessage({ id: 'from' })}
            value={isValidDate(value[0]) ? dayjs(value[0]).format('DD/MM/YYYY') : ''}
            InputProps={{
              endAdornment: (
                <IconButton onClick={toggleCalendars}>
                  <CalendarIcon />
                </IconButton>
              ),
              sx: { maxWidth: '180px' }
            }}
          />
          <TextField
            label={intl.formatMessage({ id: 'To' })}
            value={isValidDate(value[1]) ? dayjs(value[1]).format('DD/MM/YYYY') : ''}
            InputProps={{
              endAdornment: (
                <IconButton onClick={toggleCalendars}>
                  <CalendarIcon />
                </IconButton>
              ),
              sx: { maxWidth: '180px' }
            }}
          />
          <Button
            onClick={downloadReport}
            variant='contained'
            sx={{ marginLeft: '4px', display: 'flex', gap: '10px', minWidth: '220px' }}
          >
            <ReportsIcon color='#ffffff' />
            {intl.formatMessage({ id: 'download_report' })}
          </Button>
        </Box>
        {isCalendarOpen && (
          <ClickAwayListener onClickAway={toggleCalendars}>
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                top: '30px',
                right: 0,
                zIndex: 1,
                background: '#FFF',
                border: `1px solid ${primary[600]}`,
                '& > div:first-of-type': {
                  borderRight: `1px solid ${primary[600]}`
                },
                '& .MuiDayPicker-header': {
                  borderBottom: `1px solid ${info[500]}`,
                  margin: '0 16px 8px',
                  '& > span': {
                    color: secondary[600],
                    fontSize: '16px'
                  }
                }
              }}
            >
              <Box>
                <StaticDatePicker
                  inputFormat='DD/MM/YYYY'
                  mask='__/__/____'
                  displayStaticWrapperAs='desktop'
                  value={isValidDate(value[0]) ? value[0] : ''}
                  onChange={(input) => {
                    // @ts-expect-error: type conflict
                    setValue((prev) => [dayjs(input).toDate(), prev[1]]);
                  }}
                  renderInput={(props: any) => <TextField {...props} />}
                  shouldDisableDate={(day) => dayjs(day).isAfter(value[1])}
                  renderDay={(_day, _value, DayComponentProps) => (
                    <PickersDay
                      {...DayComponentProps}
                      disableHighlightToday
                      showDaysOutsideCurrentMonth
                      className='pickers-day'
                    />
                  )}
                />
                <Button variant='text' onClick={cancelButtonHandler} sx={{ margin: '12px 16px 16px' }}>
                  {intl.formatMessage({ id: 'cancel' })}
                </Button>
              </Box>
              <Box>
                <StaticDatePicker
                  inputFormat='DD/MM/YYYY'
                  mask='__/__/____'
                  displayStaticWrapperAs='desktop'
                  value={isValidDate(value[1]) ? value[1] : ''}
                  onChange={(input) => {
                    // @ts-expect-error: type conflict
                    setValue((prev) => [prev[0], dayjs(input).endOf('day').toDate()]);
                  }}
                  renderInput={(props: any) => <TextField {...props} />}
                  shouldDisableDate={(day) => dayjs(day).isBefore(value[0])}
                  renderDay={(_day, _value, DayComponentProps) => (
                    <PickersDay
                      {...DayComponentProps}
                      disableHighlightToday
                      showDaysOutsideCurrentMonth
                      className='pickers-day'
                    />
                  )}
                />
                <Button
                  onClick={generateButtonHandler}
                  variant='outlined'
                  sx={{ margin: '12px 16px 16px', float: 'right' }}
                >
                  {intl.formatMessage({ id: 'generate' })}
                </Button>
              </Box>
            </Box>
          </ClickAwayListener>
        )}
      </Stack>
    </LocalizationProvider>
  );
}
