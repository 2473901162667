import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  type SxProps,
  type Theme,
  Box
} from '@mui/material';
import { Form, Formik, type FormikHelpers, type FormikValues } from 'formik';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CrossIcon } from './icons';

interface Props<T> {
  disabled?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  size?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  loading?: boolean;
  header?: string;
  children: JSX.Element | JSX.Element[];
  initialValues?: Partial<T>;
  confirmBtnName?: string;
  sx?: SxProps<Theme>;
  noButtons?: boolean;
  resetBtnConfig?: {
    title: string;
    handler: () => void;
    disabled?: boolean;
  };
}

export const FormDialog = <T extends FormikValues>({
  disabled,
  isOpen,
  onClose,
  onSubmit,
  size = 'md',
  loading = false,
  header,
  children,
  initialValues = {},
  sx = {},
  confirmBtnName = 'Confirm',
  noButtons = false,
  resetBtnConfig
}: Props<T>): JSX.Element => {
  const intl = useIntl();

  const handleClose = useMemo(() => disabled ? () => { } : onClose, [disabled, onClose]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      maxWidth={size}
      sx={{ '& .MuiPaper-root': { borderRadius: '16px', position: 'initial' }, ...sx }}
    >
      <DialogTitle sx={{ padding: '24px 24px 16px' }}>
        <Typography variant='h6' color='secondary.800'>
          {header}
        </Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues as T}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <Form>
            <>
              <DialogContent sx={{ padding: '10px 24px 16px' }}>
                {React.Children.map(children, (child) => {
                  return React.cloneElement(child, { ...props, loading });
                })}
              </DialogContent>
              {!noButtons && (
                <DialogActions sx={{ padding: '0 24px 24px' }}>
                  <Button
                    disabled={disabled}
                    variant='text'
                    color='inherit'
                    onClick={handleClose}
                    sx={{ padding: '8px 24px' }}
                  >
                    {intl.formatMessage({ id: 'cancel' })}
                  </Button>
                  <Box>
                    {!!resetBtnConfig && (
                      <Button
                        sx={{ mr: '16px' }}
                        disabled={resetBtnConfig.disabled}
                        variant='contained'
                        color='error'
                        type='reset'
                        onClick={resetBtnConfig.handler}
                      >
                        {resetBtnConfig.title}
                      </Button>
                    )}
                    <Button
                      size='medium'
                      variant='contained'
                      sx={{ color: '#ffffff' }}
                      type='submit'
                      disabled={disabled || loading}
                    >
                      {confirmBtnName}
                    </Button>
                  </Box>
                </DialogActions>
              )}
            </>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
