import { AppBar, Button, Grid, Toolbar, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { CompanyContext } from 'shared/contexts';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette } from 'shared/hooks';
import { AttentionSnackbar } from '../Snackbars';
import { AccountMenu } from './AccountMenu';
import { GlobalSearch } from './GlobalSearch';

interface HeaderProps {
  title: string;
  withSearch?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ title, withSearch }) => {
  const { formatMessage } = useIntl();
  const { secondary, info } = usePalette();

  const { openModal } = useContext(RegistrationContext);
  const { paymentFailureCode } = useContext(CompanyContext);

  return (
    <AppBar
      position='static'
      color='inherit'
      elevation={1}
      sx={{ p: 0, boxShadow: 'none', borderBottom: `1px solid ${secondary[200]}` }}
    >
      <Toolbar sx={{ padding: '0 !important' }}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          sx={{
            padding: '16px 53px 15px 23px',
            backgroundColor: info[100]
          }}
        >
          <Grid
            item
            display='flex'
            alignItems='center'
            sx={{
              '& > *:not(:first-of-type)': {
                marginLeft: '35px'
              }
            }}
          >
            <Typography variant='h2' color='secondary.800'>
              {title}
            </Typography>
          </Grid>
          {withSearch && (
            <Grid item display='flex' gap={4}>
              <GlobalSearch />
              <Button
                size='medium'
                variant='contained'
                onClick={() => {
                  if (paymentFailureCode) {
                    toast(
                      <AttentionSnackbar
                        title={formatMessage({ id: 'feature_unavailable' })}
                        message={formatMessage({ id: `feature_unavailable_${paymentFailureCode.toLowerCase()}` })}
                      />
                    );
                    return;
                  }
                  openModal();
                }}
              >
                {formatMessage({ id: 'new_registration' })}
              </Button>
            </Grid>
          )}
          <Grid item>
            <AccountMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
