import { useQuery } from '@apollo/client';
import { Box, IconButton, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback, useEffect, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Loader, PageTitle, SearchFilter } from 'shared/components';
import { ChevronIcon, CrossIcon } from 'shared/components/icons';
import { useFiltersQuery, usePalette } from 'shared/hooks';
import { type AgenciesQuery, type AgenciesQueryVariables } from './__graphql__/AgenciesQuery';
import { AddAgencyWidget } from './AddAgencyWidget';
import { AddConsultantWidget } from './AddConsultantWidget';
import { AgenciesTable } from './AgenciesTable';
import { agenciesQuery } from './api';
import { ConsultantsTable } from './ConsultantsTable';

export enum AgencyTab {
  agencies = 'agencies',
  consultants = 'consultants'
}
export const Agencies: FC = () => {
  const intl = useIntl();
  const { secondary } = usePalette();
  const { state } = useLocation();
  const { parsedQuery, pushQuery } = useFiltersQuery();

  const [activeTab, setActiveTab] = useState<AgencyTab>(AgencyTab.agencies);
  const [query, setQuery] = useState('');
  const [agency, setAgency] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const agenciesResult = useQuery<AgenciesQuery, AgenciesQueryVariables>(agenciesQuery, {
    variables: {
      offset: 0,
      limit: 1000
    }
  });

  useEffect(() => {
    setActiveTab((parsedQuery?.tab as AgencyTab) ?? AgencyTab.agencies);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const changeActiveTabHandler = useCallback(
    (v: AgencyTab) => {
      pushQuery({});
      setQuery('');
      setActiveTab(v);
      if (v === AgencyTab.agencies) {
        setAgency(undefined);
      }
    },
    [pushQuery]
  );

  const selectAgency = useCallback(
    (agencyId: string) => {
      setAgency(agencyId);
      changeActiveTabHandler(AgencyTab.consultants);
    },
    [changeActiveTabHandler]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <PageTitle name=''>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            '& > div:not(:last-child) > *': {
              marginRight: '16px'
            }
          }}
        >
          <Box>
            <ToggleButtonGroup
              sx={{ color: secondary[800] }}
              value={activeTab}
              exclusive
              onChange={(e, v) => {
                changeActiveTabHandler(v);
              }}
              orientation='horizontal'
              className='stacked'
            >
              {Object.values(AgencyTab).map((tab) => (
                <ToggleButton key={tab} value={tab}>
                  {intl.formatMessage({ id: tab })}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {activeTab === AgencyTab.consultants && (
              <Select
                displayEmpty
                value={agency}
                className='filter'
                onChange={(e) => {
                  setAgency(e.target.value);
                }}
                renderValue={() => {
                  if (!agency) {
                    return intl.formatMessage({ id: 'agency_filter' });
                  }
                  return agenciesResult.data?.agencies.data?.find((a) => a.id === agency)?.name;
                }}
                sx={{
                  padding: 0,
                  mr: 8,
                  '& .MuiSelect-select': {
                    color: agency ? secondary[700] : secondary[600]
                  }
                }}
                IconComponent={ChevronIcon}
                endAdornment={
                  agency ? (
                    <IconButton
                      sx={{ right: '26px', position: 'absolute', padding: '2px' }}
                      onClick={() => {
                        setAgency(undefined);
                      }}
                    >
                      <CrossIcon />
                    </IconButton>
                  ) : null
                }
              >
                {agenciesResult.data?.agencies.data?.map(({ id, name }) => (
                  <MenuItem key={id} value={id} sx={{ maxWidth: '240px' }}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <SearchFilter value={query} onChange={setQuery} />
          </Box>
          {activeTab === AgencyTab.agencies ? <AddAgencyWidget /> : <AddConsultantWidget />}
        </Box>
      </PageTitle>
      {isLoading ? (
        <Loader />
      ) : activeTab === AgencyTab.agencies ? (
        <AgenciesTable query={query} selectAgency={selectAgency} setQuery={setQuery} />
      ) : (
        <ConsultantsTable query={query} agencyFilter={agency} setQuery={setQuery} setAgency={setAgency} />
      )}
    </Box>
  );
};
