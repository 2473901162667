import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { RegistrationStatus } from '__graphql__/globalTypes';
import React, { type FC, useCallback, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { CrossIcon } from 'shared/components/icons';
import {
  type UpdateStatus,
  type UpdateStatusVariables
} from 'shared/components/RegistrationModal/__graphql__/UpdateStatus';
import { updateStatusMutation } from 'shared/components/RegistrationModal/api';
import { usePalette, useRoles } from 'shared/hooks';
import { type Documents_documents_data } from './__graphql__/Documents';
import { type RejectDocuments, type RejectDocumentsVariables } from './__graphql__/RejectDocuments';
import { type VerifyDocuments, type VerifyDocumentsVariables } from './__graphql__/VerifyDocuments';
import { rejectDocumentsMutation, verifyDocumentsMutation } from './api';
import { EmailWidget } from './EmailWidget';
import { PreviewImage } from './PreviewImage';

interface Props {
  documents?: Documents_documents_data[];
  id: string;
  children?: React.ReactNode;
  status: RegistrationStatus;
}

export const DocumentsWidget: FC<Props> = ({ documents, status, id, children }) => {
  const intl = useIntl();
  const { secondary } = usePalette();
  const { isCustomerCare } = useRoles();
  const [isDocumentsOpened, setIsDocumentsOpened] = useState(false);
  const [selectedDocument, selectDocument] = useState<Documents_documents_data | undefined>(documents?.[0]);

  useEffect(() => {
    if (!selectedDocument && !!documents) {
      selectDocument(documents[0]);
    }
  }, [selectedDocument, documents]);

  const [verifyAll] = useMutation<VerifyDocuments, VerifyDocumentsVariables>(verifyDocumentsMutation, {
    refetchQueries: ['Documents'],
    awaitRefetchQueries: true,
    context: {
      uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
    }
  });

  const [rejectAll] = useMutation<RejectDocuments, RejectDocumentsVariables>(rejectDocumentsMutation, {
    refetchQueries: ['Documents'],
    awaitRefetchQueries: true,
    context: {
      uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
    }
  });

  const [updateStatus] = useMutation<UpdateStatus, UpdateStatusVariables>(updateStatusMutation, {
    refetchQueries: ['RegistrationDetails'],
    awaitRefetchQueries: true
  });

  const handleClose = useCallback(() => {
    setIsDocumentsOpened(false);
    selectDocument(undefined);
  }, []);

  const verifyDocuments = useAsyncCallback(async () => {
    await verifyAll({ variables: { ids: documents!.map((d) => d.id) } });
    await updateStatus({ variables: { id, status: RegistrationStatus.tUPLOAD_TO_PAYROLL } });
    handleClose();
  });

  const rejectDocuments = useAsyncCallback(async (htmlText: string) => {
    await rejectAll({ variables: { ids: documents!.map((d) => d.id) } });
    await updateStatus({ variables: { id, status: RegistrationStatus.oAWAITING_ID, htmlText } });
    handleClose();
  });

  return (
    <>
      <Button
        onClick={() => {
          setIsDocumentsOpened(true);
        }}
        sx={{ p: 0 }}
      >
        {children}
      </Button>
      <Dialog
        open={isDocumentsOpened}
        onClose={handleClose}
        sx={{ '& .MuiPaper-rounded': { borderRadius: '16px', maxWidth: '1056px' } }}
      >
        <DialogTitle sx={{ padding: '24px 24px 12px' }}>
          <Typography variant='h1' color='secondary.800'>
            {intl.formatMessage({ id: 'id_documents' })}
          </Typography>
          <IconButton onClick={handleClose}>
            <CrossIcon color={secondary[700]} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: '0 24px 16px' }}>
          <Typography variant='h3' color='secondary.800' sx={{ marginBottom: '16px' }}>
            {intl.formatMessage({ id: documents?.[0] ? documents[0].documentType : 'ID' })}
          </Typography>
          <Grid container gap='15px'>
            <Grid
              item
              sx={{
                overflow: 'auto',
                padding: '16px',
                backgroundColor: secondary[100],
                border: `1px solid ${secondary[400]}`,
                borderRadius: '16px',
                height: '520px',
                width: '272px',
                '& > *:not(:last-child)': {
                  marginBottom: '8px'
                }
              }}
            >
              {documents?.map((d) => (
                <PreviewImage
                  key={d.id}
                  width='236px'
                  height='176px'
                  document={d}
                  selectDocument={selectDocument}
                  isSelected={selectedDocument === d}
                />
              ))}
            </Grid>
            <Grid item width='718px'>
              <PreviewImage withDownload height='516px' document={selectedDocument} />
            </Grid>
          </Grid>
        </DialogContent>
        {!documents?.every((d) => d.verified) && (
          <DialogActions sx={{ padding: '0 24px 24px' }}>
            <EmailWidget
              disabled={
                isCustomerCare ||
                !documents?.length ||
                rejectDocuments.loading ||
                ![RegistrationStatus.lID_CHECKS, RegistrationStatus.oAWAITING_ID].includes(status)
              }
              onSubmit={rejectDocuments.execute}
            />
            <Button
              variant='contained'
              disabled={
                isCustomerCare ||
                !documents?.length ||
                verifyDocuments.loading ||
                ![RegistrationStatus.lID_CHECKS, RegistrationStatus.oAWAITING_ID].includes(status)
              }
              onClick={verifyDocuments.execute}
            >
              {intl.formatMessage({ id: 'verify_all' })}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
