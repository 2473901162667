import { Box, IconButton, TextField, Typography } from '@mui/material';
import { useState, type FC, useContext, Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'shared/components';
import { CheckmarkIcon, CrossIcon, PencilIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';

interface Props {
  readOnly?: boolean;
}

export const StepPropertyBlock: FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const { form, section, updateSection } = useContext(FormBuilderContext);
  const { secondary, primary } = usePalette();
  const [isEdit, setIsEdit] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  useEffect(() => {
    setIsEdit(false);
    if (section?.id) {
      setFieldValue((section.order + +!section.parent).toString());
    }
  }, [section?.id, section?.order, section?.parent]);

  const maxStep = section?.parent
    ? `${section.parent.order + 1}.${form?.sections.find((s) => s.id === section.parent?.id)?.children.length}`
    : `${(form?.sections.length ?? 0) + 1}`;

  const currentStep = section?.parent ? `${section.parent.order + 1}.${fieldValue}` : fieldValue;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: isEdit ? 'column' : 'row',
        gap: isEdit ? '12px' : '0px',
        background: 'secondary.50',
        borderRadius: '8px',
        p: '16px',
        border: `1px solid ${secondary[200]}`
      }}
    >
      {isEdit ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant='body1' sx={{ color: 'secondary.600' }}>
              {formatMessage({ id: 'step' })}
            </Typography>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <IconButton
                onClick={async () => {
                  if (fieldValue) {
                    await updateSection({
                      variables: { id: section?.id!, data: { order: section?.parent ? +fieldValue : +fieldValue - 1 } }
                    });
                    setIsEdit(false);
                  }
                }}
              >
                <CheckmarkIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsEdit(false);
                  if (section) {
                    setFieldValue((section.order + +!section.parent).toString());
                  }
                }}
              >
                <CrossIcon color={primary[600]} />
              </IconButton>
            </Box>
          </Box>
          <TextField
            InputProps={{
              startAdornment: (
                <Typography variant='body2' sx={{ color: 'secondary.500', fontWeight: '400' }}>
                  {formatMessage({ id: 'step' })}
                </Typography>
              ),
              endAdornment: (
                <Typography
                  variant='body2'
                  sx={{ color: 'secondary.500', fontWeight: '400', minWidth: '37px' }}
                >{`${formatMessage({
                  id: 'of'
                })} ${maxStep}`}</Typography>
              )
            }}
            onChange={({ target, nativeEvent }) => {
              const inputValue = target.value;
              const isChild = section?.parent;
              const isInputValid = inputValue && +inputValue > 1 && +maxStep >= +inputValue;
              const isBackspaceEvent = (nativeEvent as any).inputType === 'deleteContentBackward';

              if (isChild) {
                const maxOrder = +maxStep.split('.')[1];
                const currentValue = +(inputValue.split('.')[1] ?? '');
                if (maxOrder >= currentValue) {
                  setFieldValue(currentValue ? currentValue.toString() : '');
                }
              } else if (isInputValid || isBackspaceEvent) {
                setFieldValue(inputValue);
              }
            }}
            label={formatMessage({ id: 'step' })}
            value={currentStep}
          />
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography variant='body1' sx={{ color: 'secondary.600' }}>
              {formatMessage({ id: 'step' })}
            </Typography>
            <Typography sx={{ color: 'secondary.700' }}>{`${formatMessage({ id: 'step' })} ${currentStep}`}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            {!readOnly && (
              <IconButton
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                <PencilIcon />
              </IconButton>
            )}
            <Tooltip
              text={
                <>
                  {formatMessage({ id: 'section_tooltips.step' })
                    .split('\n')
                    .map((line, index) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                </>
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};
