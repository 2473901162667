import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { type SnackbarProps } from 'shared/components/Snackbars';
import { ProfileInfoContext } from 'shared/contexts';
import { type UseUploadPhoto, type ValidateFileErrors } from './interfaces';

export function useUploadPhoto(): UseUploadPhoto {
  const { formatMessage } = useIntl();
  const { user } = useContext(ProfileInfoContext);

  const [uploadedImage, setUploadedImage] = useState<string>(user.avatarUrl ?? '');

  const isViolateImageDimensions = async (file: File): Promise<boolean> => {
    const MAX_PX_DIMENSION = 600;

    const result = await new Promise<boolean>((resolve) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const exceededLimitDimension = img.width > MAX_PX_DIMENSION || img.height > MAX_PX_DIMENSION;

          if (!exceededLimitDimension) {
            setUploadedImage(img.src);
          }

          resolve(exceededLimitDimension);
        };
      };
      reader.readAsDataURL(file);
    });

    return result;
  };

  const validateFile = async (file: File | undefined): Promise<ValidateFileErrors> => {
    if (!file) {
      return {
        exceededLimitSize: false,
        incorrectFormat: false
      };
    }

    const MAX_SIZE_IN_MB = 0.5;
    const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/png'];

    const incorrectFormat = !ALLOWED_MIME_TYPES.includes(file.type);
    const fileSizeInMB = file.size / (1024 * 1024);
    const isTooHeavy = fileSizeInMB > MAX_SIZE_IN_MB;
    const isTooLarge = !incorrectFormat && (await isViolateImageDimensions(file));
    const exceededLimitSize = isTooHeavy || isTooLarge;

    return {
      exceededLimitSize,
      incorrectFormat
    };
  };

  const getErrorNotificationContent = (errors: ValidateFileErrors): SnackbarProps | null => {
    const { exceededLimitSize, incorrectFormat } = errors;

    if (incorrectFormat) {
      return {
        title: formatMessage({ id: 'incorrect_file_type' }),
        message: formatMessage({ id: 'incorrect_file_type_message' })
      };
    } else if (exceededLimitSize) {
      return {
        title: formatMessage({ id: 'photo_size_is_too_big' }),
        message: formatMessage({ id: 'photo_is_too_big_message' })
      };
    }

    return null;
  };

  return { validateFile, getErrorNotificationContent, uploadedImage };
}
