import { Box, type SxProps, type Theme } from '@mui/material';
import { Loader } from '../Loader';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  loading: boolean;
  sx?: SxProps<Theme>;
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, index, loading, sx, ...other } = props;

  return (
    <Box
      sx={{
        width: '400px',
        minWidth: '400px',
        '@media only screen and (max-width: 432px)': {
          width: '-webkit-fill-available',
          minWidth: 'unset'
        },
        ...(sx ?? {})
      }}
      role='tabpanel'
      id={`registration-tabpanel-${index}`}
      {...other}
    >
      {loading ? (
        <Box sx={{ pt: '100px' }}>
          <Loader />
        </Box>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
}

export default TabPanel;
