import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { UserOrderField } from '__graphql__/globalTypes';
import { useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { EmptyTableBox, ActionsPopup } from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { AuthContext, SearchContext } from 'shared/contexts';
import { useCustomStyles, type UseTableSort } from 'shared/hooks';
import { getContractorStatusColor } from 'shared/utils/colors';
import { isAdmin } from 'shared/utils/users';
import { type Contractors_contractors_data } from './__graphql__/Contractors';

interface Props {
  data?: Contractors_contractors_data[] | null;
  openDeleteModal: (contractor: Contractors_contractors_data) => void;
  openModal: (contractor: Contractors_contractors_data) => void;
  setUserForActivation: (contractor: Contractors_contractors_data) => void;
  sendLink: (id: string) => Promise<void>;
  page: number;
  rowsPerPage: number;
  sort: UseTableSort<UserOrderField>;
}

const tableHeaderConfig = [
  { field: UserOrderField.firstName, labelId: 'name' },
  { field: UserOrderField.email, labelId: 'email' },
  { field: UserOrderField.phoneNumber, labelId: 'phone_number' },
  { field: UserOrderField.assignedTo, labelId: 'assigned_to' },
  { field: UserOrderField.referrals, labelId: 'referrals' },
  { field: UserOrderField.status, labelId: 'status' }
];

export function ContractorsTable(props: Props): JSX.Element {
  const {
    data,
    page,
    rowsPerPage,
    openModal,
    openDeleteModal,
    sendLink,
    setUserForActivation,
    sort: { createSortHandler, direction, field }
  } = props;
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const customStyles = useCustomStyles();
  const { search } = useContext(SearchContext);
  const { authState } = useContext(AuthContext);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaderConfig.map((cell) => (
              <TableCell key={cell.field}>
                <TableSortLabel onClick={createSortHandler(cell.field)} hideSortIcon={true}>
                  {formatMessage({ id: cell.labelId })}
                  <SortIcon active={field === cell.field} direction={direction} />
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>{formatMessage({ id: 'actions' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((contractor) => (
            <TableRow key={contractor.id}>
              <TableCell>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={`${contractor.firstName ?? ''} ${contractor.lastName ?? ''}`}
                />
              </TableCell>
              <TableCell>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={contractor.email ?? ''}
                />
              </TableCell>
              <TableCell>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={contractor.phoneNumber ?? ''}
                />
              </TableCell>
              <TableCell>
                {`${contractor.manager?.firstName ?? ''}
                ${contractor.manager?.lastName ?? ''}`}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{contractor.registrationsCount}</TableCell>
              <TableCell className='badge status'>
                <Box
                  sx={{
                    backgroundColor: getContractorStatusColor(contractor.status)
                  }}
                >
                  {formatMessage({ id: `contractor.status.${contractor.status as string}` })}
                </Box>
              </TableCell>
              <TableCell sx={{ width: 0 }}>
                <ActionsPopup
                  items={[
                    {
                      id: 'view',
                      onClick: () => {
                        navigate(contractor.id);
                      }
                    },
                    {
                      id: 'edit',
                      onClick: () => {
                        openModal(contractor);
                      }
                    },
                    {
                      id: 'send_link',
                      onClick: async () => {
                        await sendLink(contractor.id);
                      },
                      disabled: !contractor.email || !contractor.isActive
                    },
                    {
                      id: contractor.isActive ? 'deactivate' : 'activate',
                      onClick: () => {
                        setUserForActivation(contractor);
                      }
                    },
                    ...(isAdmin(authState.user?.role)
                      ? [
                          {
                            id: 'delete',
                            onClick: () => {
                              openDeleteModal(contractor);
                            }
                          }
                        ]
                      : [])
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
          {!data?.length && !page && (
            <TableRow
              style={{
                height: 64 * rowsPerPage,
                background: 'inherit'
              }}
            >
              <TableCell
                colSpan={tableHeaderConfig.length}
                style={{
                  height: 64 * rowsPerPage,
                  padding: 0
                }}
              >
                <EmptyTableBox />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
