import { Box } from '@mui/material';
import { RegistrationStatus } from '__graphql__/globalTypes';
import { ReferralModal } from 'consultant/components/ReferralModal';
import { CookiesControlTool, Header } from 'contractor/components';
import { Dashboard } from 'contractor/pages';
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RegistrationModal, StaticFooter } from 'shared/components';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette } from 'shared/hooks';

function App(): JSX.Element {
  const { info } = usePalette();
  const { registration } = useContext(RegistrationContext);

  return (
    <Box>
      <Header />
      <Box
        sx={{
          height: 'calc(100vh - 64px)',
          bgcolor: info[200],
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '@media only screen and (max-width: 600px)': {
            height: 'calc(100vh - 56px)'
          }
        }}
      >
        <Routes>
          <Route index element={<Dashboard />} />
        </Routes>
        <StaticFooter />
        <CookiesControlTool />
        {registration?.status === RegistrationStatus.gIN_PROGRESS ||
        registration?.status === RegistrationStatus.iFORM_SENT ? (
          <RegistrationModal />
        ) : (
          <ReferralModal />
        )}
      </Box>
    </Box>
  );
}

export default App;
