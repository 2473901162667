import { useMutation, useQuery } from '@apollo/client';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { OrderDirection, SuperAdminOrderField, type UpdateSuperAdminInput } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { inviteResendMutation, updateSuperAdminMutation } from 'shared/api';
import { type InviteResend, type InviteResendVariables } from 'shared/api/__graphql__/InviteResend';
import { type UpdateSuperAdmin, type UpdateSuperAdminVariables } from 'shared/api/__graphql__/UpdateSuperAdmin';
import { API_CONTEXT } from 'shared/api/api-contexts';
import {
  ActionsPopup,
  ConfirmationDialog,
  EmptyTableBox,
  FormDialog,
  Loader,
  PageTitle,
  PageWrapper,
  SearchFilter
} from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import SuccessSnackbar from 'shared/components/Snackbars/SuccessSnackbar';
import { StyledTablePagination } from 'shared/components/StyledTablePagination';
import AuthContext from 'shared/contexts/authContext';
import { useCustomStyles, useFiltersQuery, useTablePagination, useTableSort } from 'shared/hooks';
import { getRoleColor, getUserStatusColor } from 'shared/utils/colors';
import { type ActivateSuperAdmin, type ActivateSuperAdminVariables } from './__graphql__/ActivateSuperAdmin';
import { type DeactivateSuperAdmin, type DeactivateSuperAdminVariables } from './__graphql__/DeactivateSuperAdmin';
import { type DeleteSuperAdmin, type DeleteSuperAdminVariables } from './__graphql__/DeleteSuperAdmin';
import {
  type SuperAdmins as SuperAdminsQuery,
  type SuperAdminsVariables,
  type SuperAdmins_superAdmins_data
} from './__graphql__/SuperAdmins';
import { AddForm, AddWidget } from './AddWidget';
import {
  activateSuperAdminMutation,
  deactivateSuperAdminMutation,
  deleteSuperAdminMutation,
  superAdminsQuery
} from './api';

export const SuperAdmins = (): JSX.Element => {
  const intl = useIntl();
  const { authState } = useContext(AuthContext);
  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const customStyles = useCustomStyles();
  const { field, direction, createSortHandler, setSort } = useTableSort<SuperAdminOrderField>({
    defaultField: SuperAdminOrderField.firstName,
    defaultDirection: OrderDirection.asc
  });
  const { parsedQuery, pushQuery } = useFiltersQuery();

  const [query, setQuery] = useState('');
  const [saForActivation, setSAForActivation] = useState<SuperAdmins_superAdmins_data | undefined>();
  const [saForEdit, setSAForEdit] = useState<SuperAdmins_superAdmins_data | undefined>();
  const [saForDelete, setSAForDelete] = useState<SuperAdmins_superAdmins_data | undefined>();

  const result = useQuery<SuperAdminsQuery, SuperAdminsVariables>(superAdminsQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
            field,
            direction
          }
          : null,
      offset,
      limit,
      query: query?.length > 2 ? query.trim() : null
    },
    context: API_CONTEXT.SUPERADMIN
  });

  useEffect(() => {
    pushQuery({
      filters: [
        ...(query
          ? [
            {
              id: 'search',
              value: query
            }
          ]
          : [])
      ],
      sort: [
        ...(field
          ? [
            {
              id: field,
              desc: direction === OrderDirection.desc
            }
          ]
          : [])
      ],
      pageIndex: tablePaginationProps.page
    });
  }, [direction, field, pushQuery, tablePaginationProps.page, query]);

  useEffect(() => {
    setSort(
      (parsedQuery?.sortBy as SuperAdminOrderField) ?? SuperAdminOrderField.firstName,
      (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
    );
    setQuery((parsedQuery?.search as string) ?? '');
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateSuperAdmin] = useMutation<UpdateSuperAdmin, UpdateSuperAdminVariables>(updateSuperAdminMutation, {
    refetchQueries: ['SuperAdmins'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.SUPERADMIN
  });

  const [deleteSuperAdmin] = useMutation<DeleteSuperAdmin, DeleteSuperAdminVariables>(deleteSuperAdminMutation, {
    refetchQueries: ['SuperAdmins'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.SUPERADMIN
  });

  const [activateSuperAdmin] = useMutation<ActivateSuperAdmin, ActivateSuperAdminVariables>(
    activateSuperAdminMutation,
    {
      refetchQueries: ['SuperAdmins'],
      awaitRefetchQueries: true,
      context: API_CONTEXT.SUPERADMIN
    }
  );

  const [deactivateSuperAdmin] = useMutation<DeactivateSuperAdmin, DeactivateSuperAdminVariables>(
    deactivateSuperAdminMutation,
    {
      refetchQueries: ['SuperAdmins'],
      awaitRefetchQueries: true,
      context: API_CONTEXT.SUPERADMIN
    }
  );

  const [resendInvite] = useMutation<InviteResend, InviteResendVariables>(inviteResendMutation, {
    context: API_CONTEXT.AUTH
  });

  const handleResendInvite = useCallback(
    async (id: string) => {
      try {
        await resendInvite({ variables: { id } });
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'access_link_was_successfully_sent' })}
            message={intl.formatMessage({ id: 'the_user_received_new_link' })}
          />
        );
      } catch (e) {
        console.log('Error: ', e);
      }
    },
    [intl, resendInvite]
  );

  const handleCloseActivatingModal = useCallback(() => {
    setSAForActivation(undefined);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setSAForEdit(undefined);
  }, []);

  const handleCloseDeletingModal = useCallback(() => {
    setSAForDelete(undefined);
  }, []);

  const handleUpdate = useAsyncCallback(async (values: UpdateSuperAdminInput & { name: string }) => {
    await updateSuperAdmin({
      variables: {
        data: {
          id: values.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName
        }
      }
    });
    toast(
      <SuccessSnackbar
        title={intl.formatMessage({ id: 'sa_updated' })}
      />,
      {
        position: toast.POSITION.TOP_RIGHT
      }
    );
    handleCloseEditModal();
  });

  const handleActivate = useAsyncCallback(async () => {
    const handler = saForActivation?.isActive ? deactivateSuperAdmin : activateSuperAdmin;
    await handler({ variables: { id: saForActivation?.id! } });
    toast(
      <SuccessSnackbar
        title={intl.formatMessage({ id: 'sa_updated' })}
        message={intl.formatMessage({
          id: saForActivation?.isActive ? 'the_user_deactivated' : 'the_user_is_activated'
        })}
      />,
      {
        position: toast.POSITION.TOP_RIGHT
      }
    );
    setSAForActivation(undefined);
  });

  const handleDelete = useAsyncCallback(async (id: string) => {
    await deleteSuperAdmin({ variables: { id } });
    handleCloseDeletingModal();
    toast(
      <SuccessSnackbar
        title={intl.formatMessage({ id: 'super_admin_removal.success.title' })}
        message={intl.formatMessage({ id: 'super_admin_removal.success.content' })}
      />
    );
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <SearchFilter value={query} onChange={setQuery} />
            <AddWidget />
          </Box>
        </PageTitle>
        {result.loading || handleUpdate.loading ? (
          <Loader />
        ) : (
          <PageWrapper>
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(SuperAdminOrderField.firstName)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'name' })}
                          <SortIcon active={field === SuperAdminOrderField.firstName} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(SuperAdminOrderField.email)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'email' })}
                          <SortIcon active={field === SuperAdminOrderField.email} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(SuperAdminOrderField.role)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'role' })}
                          <SortIcon active={field === SuperAdminOrderField.role} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(SuperAdminOrderField.lastLogin)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'last_login' })}
                          <SortIcon active={field === SuperAdminOrderField.lastLogin} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(SuperAdminOrderField.isActive)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'status' })}
                          <SortIcon active={field === SuperAdminOrderField.isActive} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>{intl.formatMessage({ id: 'actions' })}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.data?.superAdmins.data?.map((sa) => (
                      <TableRow key={sa.id}>
                        <TableCell>
                          <Highlighter
                            autoEscape
                            highlightStyle={customStyles.highlighter}
                            searchWords={[`${query}`]}
                            textToHighlight={`${sa.firstName ?? ''} ${sa.lastName ?? ''}`}
                          />
                        </TableCell>
                        <TableCell>
                          <Highlighter
                            autoEscape
                            highlightStyle={customStyles.highlighter}
                            searchWords={[`${query}`]}
                            textToHighlight={sa.email ?? ''}
                          />
                        </TableCell>
                        <TableCell className='badge'>
                          <Box
                            sx={{
                              backgroundColor: getRoleColor(sa.role)
                            }}
                          >
                            {intl.formatMessage({ id: `user.role.${sa.role}` })}
                          </Box>
                        </TableCell>
                        <TableCell>{sa.lastLogin ? dayjs(sa.lastLogin).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                        <TableCell className='badge'>
                          <Box
                            sx={{
                              backgroundColor: getUserStatusColor(sa.isActive)
                            }}
                          >
                            {intl.formatMessage({ id: `status.${sa.isActive ? 'ACTIVE' : 'INACTIVE'}` })}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ width: 0 }}>
                          <ActionsPopup
                            items={[
                              ...(sa.id !== authState.user?.id
                                ? [
                                  {
                                    id: 'edit',
                                    onClick: () => {
                                      setSAForEdit(sa);
                                    }
                                  },
                                  {
                                    id: sa.isActive ? 'deactivate' : 'activate',
                                    onClick: () => {
                                      setSAForActivation(sa);
                                    }
                                  }
                                ]
                                : []),
                              {
                                id: 'send_link',
                                onClick: async () => {
                                  await handleResendInvite(sa.id);
                                },
                                disabled: !sa.email || !sa.isActive
                              },
                              ...(sa.id !== authState.user?.id
                                ? [
                                  {
                                    id: 'delete',
                                    onClick: () => {
                                      setSAForDelete(sa);
                                    }
                                  }
                                ]
                                : [])
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {!result.data?.superAdmins.data?.length && tablePaginationProps.page === 0 && (
                      <TableRow
                        style={{
                          height: 64 * tablePaginationProps.rowsPerPage,
                          background: 'inherit'
                        }}
                      >
                        <TableCell
                          colSpan={5}
                          style={{
                            height: 64 * tablePaginationProps.rowsPerPage,
                            padding: 0
                          }}
                        >
                          <EmptyTableBox />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <StyledTablePagination count={result.data?.superAdmins.total ?? 0} {...tablePaginationProps} />
              <ConfirmationDialog
                open={!!saForActivation}
                onClose={handleCloseActivatingModal}
                onConfirm={handleActivate.execute}
                confirmButtonContent={intl.formatMessage({ id: saForActivation?.isActive ? 'deactivate' : 'activate' })}
                title={intl.formatMessage({
                  id: saForActivation?.isActive ? 'superadmin_deactivation' : 'superadmin_activation'
                })}
                content={intl.formatMessage(
                  {
                    id: saForActivation?.isActive ? 'superadmin_deactivation_confirm' : 'superadmin_activation_confirm'
                  },
                  {
                    firstName: saForActivation?.firstName,
                    lastName: saForActivation?.lastName,
                    email: saForActivation?.email
                  }
                )}
              />
              <ConfirmationDialog
                open={!!saForDelete}
                onClose={handleCloseDeletingModal}
                onConfirm={async () => {
                  await handleDelete.execute(saForDelete?.id!);
                }}
                confirmButtonContent={intl.formatMessage({ id: 'delete' })}
                title={intl.formatMessage({ id: 'super_admin_removal' })}
                content={intl.formatMessage(
                  { id: 'super_admin_removal_content' },
                  {
                    firstName: saForDelete?.firstName,
                    lastName: saForDelete?.lastName,
                    email: saForDelete?.email
                  }
                )}
              />
              <FormDialog
                isOpen={!!saForEdit}
                onClose={handleCloseEditModal}
                header={intl.formatMessage({ id: 'edit_superadmin' })}
                size='xs'
                loading={handleUpdate.loading}
                confirmBtnName={intl.formatMessage({ id: 'save' })}
                initialValues={{
                  ...saForEdit
                }}
                onSubmit={handleUpdate.execute}
              >
                <AddForm />
              </FormDialog>
            </>
          </PageWrapper>
        )}
      </Box>
    </Box>
  );
};
