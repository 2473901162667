import { Box, Button, ClickAwayListener, IconButton, TextField } from '@mui/material';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { CalendarIcon } from 'shared/components/icons';
import { usePalette } from 'shared/hooks';
import { isValidDate } from 'shared/utils/date';
import { type DateRange } from '../types';
import { DateShortcuts } from './DateShortcuts';

interface Props {
  value: DateRange;
  onChange: (_: DateRange) => void;
}

export const DateFilter: FC<Props> = (props) => {
  const { value, onChange } = props;
  const { formatMessage } = useIntl();
  const [range, setRange] = useState<DateRange>([null, null]);
  const { primary, secondary, info } = usePalette();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display='flex' gap={3}>
        <DateShortcuts value={value} onChange={onChange} openCalendar={() => setIsCalendarOpen(true)} />
        <TextField
          label={formatMessage({ id: 'from' })}
          value={isValidDate(value[0]) ? dayjs(value[0]).format('DD/MM/YYYY') : ''}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setIsCalendarOpen((prev) => !prev)}>
                <CalendarIcon />
              </IconButton>
            ),
            sx: { maxWidth: '180px' }
          }}
        />
        <TextField
          label={formatMessage({ id: 'To' })}
          value={isValidDate(value[1]) ? dayjs(value[1]).format('DD/MM/YYYY') : ''}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setIsCalendarOpen((prev) => !prev)}>
                <CalendarIcon />
              </IconButton>
            ),
            sx: { maxWidth: '180px' }
          }}
        />
      </Box>
      {isCalendarOpen && (
        <ClickAwayListener onClickAway={() => setIsCalendarOpen((prev) => !prev)}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: '30px',
              right: 0,
              zIndex: 1,
              background: '#FFF',
              border: `1px solid ${primary[600]}`,
              '& > div:first-of-type': {
                borderRight: `1px solid ${primary[600]}`
              },
              '& .MuiDayPicker-header': {
                borderBottom: `1px solid ${info[500]}`,
                margin: '0 16px 8px',
                '& > span': {
                  color: secondary[600],
                  fontSize: '16px'
                }
              }
            }}
          >
            <Box>
              <StaticDatePicker
                inputFormat='DD/MM/YYYY'
                mask='__/__/____'
                displayStaticWrapperAs='desktop'
                value={isValidDate(range[0]) ? range[0] : ''}
                onChange={(input): void => {
                  setRange((prev) => [dayjs(input).toDate(), prev[1]]);
                }}
                renderInput={(props: any) => <TextField {...props} />}
                shouldDisableDate={(day) => dayjs(day).isAfter(range[1])}
                renderDay={(_day, _value, DayComponentProps) => (
                  <PickersDay
                    {...DayComponentProps}
                    disableHighlightToday
                    showDaysOutsideCurrentMonth
                    className='pickers-day'
                  />
                )}
              />
              <Button
                variant='text'
                onClick={() => {
                  setRange([null, null]);
                  onChange([null, null]);
                  setIsCalendarOpen(false);
                }}
                sx={{ margin: '12px 16px 16px' }}
              >
                {formatMessage({ id: 'cancel' })}
              </Button>
            </Box>
            <Box>
              <StaticDatePicker
                inputFormat='DD/MM/YYYY'
                mask='__/__/____'
                displayStaticWrapperAs='desktop'
                value={isValidDate(range[1]) ? range[1] : ''}
                onChange={(input) => {
                  setRange((prev) => [prev[0], dayjs(input).endOf('day').toDate()]);
                }}
                renderInput={(props: any) => <TextField {...props} />}
                shouldDisableDate={(day) => dayjs(day).isBefore(range[0])}
                renderDay={(_day, _value, DayComponentProps) => (
                  <PickersDay
                    {...DayComponentProps}
                    disableHighlightToday
                    showDaysOutsideCurrentMonth
                    className='pickers-day'
                  />
                )}
              />
              <Button
                onClick={() => {
                  onChange(range);
                  setIsCalendarOpen(false);
                }}
                variant='outlined'
                sx={{ margin: '12px 16px 16px', float: 'right' }}
              >
                {formatMessage({ id: 'generate' })}
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </LocalizationProvider>
  );
};
