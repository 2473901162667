import { Button, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { Input } from 'shared/components';
import { ArrowIcon } from 'shared/components/icons';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import routes from 'shared/constants/routes';
import AuthContext from 'shared/contexts/authContext';
import { type ForgotPasswordParams } from 'shared/types/auth';
import { isGoneError } from 'shared/utils/errors';
import { Validator } from 'shared/utils/validator';

export const ForgotPassword = (): JSX.Element => {
  const intl = useIntl();

  const { error, setError, forgotPassword } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isGoneError(error?.code)) {
      toast(
        <AttentionSnackbar
          title={intl.formatMessage({ id: 'system_unavailable' })}
          message={intl.formatMessage({ id: 'system_deactivated' })}
        />,
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
    }
    return () => {
      setError(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Formik<ForgotPasswordParams>
      initialValues={{
        email: ''
      }}
      onSubmit={async (formValues: ForgotPasswordParams) => {
        await forgotPassword.execute(formValues);
      }}
    >
      <Form>
        <Grid container justifyContent='center'>
          <Grid item width='320px' mr={{ xs: 0 }}>
            <Button
              variant='text'
              sx={{
                padding: '8px 6px',
                gap: '10px',
                marginBottom: '16px'
              }}
              onClick={() => {
                forgotPassword.result ? forgotPassword.reset() : navigate(routes.login);
              }}
            >
              <ArrowIcon direction='left' />
              {forgotPassword.result
                ? intl.formatMessage({ id: 'password_recovery' })
                : intl.formatMessage({ id: 'back to login' })}
            </Button>
            <Typography
              variant='h1'
              color='secondary.800'
              sx={{
                marginBottom: '8px'
              }}
            >
              {forgotPassword.result
                ? intl.formatMessage({ id: 'check_email' })
                : intl.formatMessage({ id: 'password_recovery' })}
            </Typography>
            <Typography variant='body1' color='secondary.800' mb={4}>
              {forgotPassword.result
                ? intl.formatMessage({ id: 'check_email_reset_link' })
                : intl.formatMessage({ id: 'please_enter_your_email' })}
            </Typography>
            {!forgotPassword.result && (
              <Field
                id='email'
                name='email'
                type='email'
                component={Input}
                label={intl.formatMessage({ id: 'email' })}
                sx={{
                  marginBottom: '16px'
                }}
                validate={Validator.pipe(Validator.methods.required(), Validator.methods.email())}
              />
            )}
            <Button type='submit' size='large' variant='contained'>
              {forgotPassword.result ? intl.formatMessage({ id: 'resend' }) : intl.formatMessage({ id: 'send_link' })}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};
