import { Box, Typography } from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { Field, FormikContext, type FieldProps } from 'formik';
import { useContext, useState, type ChangeEvent, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Validator } from 'shared/utils/validator';
import { Tooltip } from '../Tooltip';
import { TimeBaseComponent } from './TimeComponent';
import { type ComponentProps, type FormComponentProps } from './types';

const Component: FC<ComponentProps> = (props): JSX.Element => {
  const { field, text, tooltip } = props;

  const { formatMessage } = useIntl();

  const fromOnChange = ({ target }: ChangeEvent<any>): void => {
    const currentValue: Dayjs = dayjs(target.value);
    field.onChange({ target: { value: [currentValue.toDate(), field.value[1]], name: field.name } });
  };

  const toOnChange = ({ target }: ChangeEvent<any>): void => {
    const currentValue: Dayjs = dayjs(target.value);
    field.onChange({ target: { value: [field.value[0], currentValue.toDate()], name: field.name } });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', pb: '6px' }}>
        <Typography variant='body1' color='secondary.800' sx={{ pb: '8px' }}>
          {text}
        </Typography>
        {tooltip && <Tooltip text={tooltip} />}
      </Box>
      <Box display='flex' gap={3} flexBasis='70%' alignItems='center'>
        <Typography variant='body1' color='secondary.800'>
          {formatMessage({ id: 'from' })}
        </Typography>
        <TimeBaseComponent
          {...props}
          tooltip={null}
          text={formatMessage({ id: 'time' })}
          field={{ ...props.field, onChange: fromOnChange, value: field.value?.[0] }}
        />
        <Typography variant='body1' color='secondary.800'>
          {formatMessage({ id: 'to' })}
        </Typography>
        <TimeBaseComponent
          {...props}
          tooltip={null}
          text={formatMessage({ id: 'time' })}
          field={{ ...props.field, onChange: toOnChange, value: field.value?.[1] }}
        />
      </Box>
    </Box>
  );
};

export const TimePickerComponent: FC<FormComponentProps> = (props) => {
  const { required: initialRequired, id } = props;
  const [value, setValue] = useState<Date[]>([]);
  const required = initialRequired ?? false;

  const formik = useContext(FormikContext);

  return formik ? (
    <Field
      {...props}
      name={id}
      component={Component}
      validate={required && Validator.pipe(Validator.methods.required())}
    />
  ) : (
    <Component
      {...props}
      field={
        {
          value,
          onChange: ({ target }: ChangeEvent<any>) => {
            setValue(target.value);
          }
        } as FieldProps['field']
      }
    />
  );
};
