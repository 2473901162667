import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useMemo, type FC, useState, useContext } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { updatePlanMutation, getCheckoutUrlQuery, startTrialMutation } from 'shared/api';
import { type CompanyAccess } from 'shared/api/__graphql__/CompanyAccess';
import { type CompanySubscriptions } from 'shared/api/__graphql__/CompanySubscriptions';
import { type GetCheckoutUrl, type GetCheckoutUrlVariables } from 'shared/api/__graphql__/GetCheckoutUrl';
import { type StartTrial } from 'shared/api/__graphql__/StartTrial';
import { type UpdatePlan, type UpdatePlanVariables } from 'shared/api/__graphql__/UpdatePlan';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Loader } from 'shared/components';
import {
  Agencies2Icon,
  CircleCheck2Icon,
  ContactIcon,
  CrossIcon,
  DashboardIcon,
  UsersIcon
} from 'shared/components/icons';
import { AttentionSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { TRIAL_PLAN_ID, PLAN_ACTIVATED_PARAM } from 'shared/constants';
import routes from 'shared/constants/routes';
import { ProductsContext } from 'shared/contexts/productsContext';
import { usePalette } from 'shared/hooks';

const PRODUCT_IMAGE = '/plan-icon.svg';

const opportunities = [
  { id: 'unlimited_contracts', icon: <ContactIcon /> },
  { id: 'unlimited_agencies', icon: <Agencies2Icon /> },
  { id: 'support_types', icon: <DashboardIcon /> },
  { id: 'unlimited_users', icon: <UsersIcon /> }
];

export const PLANS_FEATURES = [
  ['unlimited_referrals', 'standart_support'],
  ['onboardings_included', 'per_additional_onboarding', 'standart_support'],
  ['onboardings_included', 'per_additional_onboarding', 'standart_support'],
  ['onboardings_included', 'per_additional_onboarding', 'enhanced_support']
];

interface Props {
  open: boolean;
  onClose: () => void;
  isChange: boolean;
  companyAccess?: CompanyAccess;
  companySubscriptions?: CompanySubscriptions;
  currentPlanId?: string;
}

export const SelectPlanDialog: FC<Props> = ({
  open,
  onClose,
  isChange,
  companyAccess,
  companySubscriptions,
  currentPlanId
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { secondary, warning } = usePalette();
  const { products } = useContext(ProductsContext);
  const [selectedPlan, setSelectedPlan] = useState<string>('');

  const [startTrial] = useMutation<StartTrial>(startTrialMutation, {
    refetchQueries: ['CompanyAccess'],
    awaitRefetchQueries: true
  });

  const [changePlan] = useMutation<UpdatePlan, UpdatePlanVariables>(updatePlanMutation, {
    context: API_CONTEXT.PAYMENT,
    refetchQueries: ['CompanySubscriptions', 'Discount'],
    awaitRefetchQueries: true
  });

  const [fetchCheckoutUrl] = useLazyQuery<GetCheckoutUrl, GetCheckoutUrlVariables>(getCheckoutUrlQuery, {
    context: API_CONTEXT.PAYMENT
  });

  const handleClose = useMemo(() => (isChange ? onClose : undefined), [isChange, onClose]);

  const plans = useMemo(
    () => [
      {
        id: TRIAL_PLAN_ID,
        description: formatMessage({ id: 'to_try_system' }),
        name: formatMessage({ id: 'trial_plan' }),
        image: PRODUCT_IMAGE,
        priceId: 'trial',
        amount: 0,
        currency: 'gbp',
        additionalPriceId: '-',
        additionalAmount: 0,
        numberOfRegistrations: 0
      },
      ...(products
        .map(({ prices, ...p }) => ({
          ...p,
          ...prices.reduce<{
            id: string;
            description: string;
            name: string;
            image: string;
            priceId: string;
            amount: number;
            currency: string;
            additionalPriceId: string;
            additionalAmount: number;
            numberOfRegistrations: number;
          }>(
            (acc, val) => ({
              ...acc,
              ...(val.default
                ? {
                    priceId: val.id,
                    amount: val.amount,
                    currency: val.currency
                  }
                : {
                    additionalPriceId: val.id,
                    additionalAmount: val.amount,
                    numberOfRegistrations: val.startsFrom ?? 0
                  })
            }),
            // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
            {} as any
          )
        }))
        .sort((a: any, b: any) => a.amount - b.amount) ?? [])
    ],
    [products, formatMessage]
  );

  const onSubmit = useAsyncCallback(async () => {
    if (!selectedPlan) {
      toast(
        <AttentionSnackbar
          title={formatMessage({ id: 'plan_not_selected' })}
          message={formatMessage({ id: 'select_plan' })}
        />,
        {
          position: toast.POSITION.BOTTOM_CENTER
        }
      );
      return;
    }
    if (selectedPlan === TRIAL_PLAN_ID) {
      await startTrial();
      navigate(routes.myCompany + `?${PLAN_ACTIVATED_PARAM}=true`);
    } else if (currentPlanId && currentPlanId !== TRIAL_PLAN_ID) {
      await changePlan({
        variables: {
          data: {
            prices: products
              .find((p) => p.id === selectedPlan)
              ?.prices.map((pr) => ({ id: pr.id, default: pr.default }))!
          }
        }
      });
      toast(
        <SuccessSnackbar
          title={formatMessage({ id: 'plan_changed_successfully' })}
          message={formatMessage({ id: 'plan_activated_after_expiration' })}
        />
      );
      onClose();
    } else {
      const returnUrl = window.location.origin + '/' + routes.myCompany;
      const urlResponse = await fetchCheckoutUrl({
        variables: {
          data: {
            prices: products
              .find((p) => p.id === selectedPlan)
              ?.prices.map((pr) => ({ id: pr.id, default: pr.default }))!,
            cancelUrl: returnUrl,
            successUrl: returnUrl + `?${PLAN_ACTIVATED_PARAM}=true`
          }
        }
      });
      window.open(urlResponse.data?.getCheckoutUrl, '_self');
    }
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      sx={{ '& .MuiPaper-rounded': { borderRadius: '16px', gap: '24px', padding: '24px' } }}
    >
      {!products?.length ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <Loader sx={{ maxWidth: '100px', maxHeight: '100px' }} />
        </Box>
      ) : (
        <>
          <Box display='flex' flexDirection='column' gap='12px'>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              {isChange ? (
                <Typography variant='h6' color='secondary.800'>
                  {formatMessage({ id: 'change_plan' })}
                </Typography>
              ) : (
                <Typography variant='h1' color='secondary.800'>
                  {formatMessage({ id: 'welcome_to_system' })}
                </Typography>
              )}
              {!!handleClose && (
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              )}
            </Box>
            <Typography variant='subtitle2' color='secondary.800' fontWeight={400}>
              {formatMessage({ id: 'plans_give_access' })}
            </Typography>
            <Grid container flexWrap='nowrap' gap='12px'>
              {opportunities.map(({ id, icon }) => (
                <Grid
                  key={id}
                  item
                  xs={3}
                  display='flex'
                  flexDirection='row'
                  bgcolor='primary.200'
                  borderRadius='16px'
                  padding='12px'
                  gap='12px'
                >
                  {icon}
                  <Typography variant='subtitle2' color='secondary.800' fontWeight={400}>
                    {formatMessage({ id })}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Typography variant='subtitle2' color='secondary.800' fontWeight={400}>
            {formatMessage({ id: 'select_plan_or_contact' })}
          </Typography>
          <Box>
            <Grid container flexWrap='nowrap' gap='12px'>
              {plans.map((p, index) => (
                <Grid
                  key={p.id}
                  item
                  xs={3}
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'
                  bgcolor='secondary.50'
                  borderRadius='16px'
                  height='454px'
                  padding='24px'
                  border={selectedPlan === p.id ? `1px solid ${warning[200]}` : '1px solid transparent'}
                >
                  <Box display='flex' flexDirection='column' alignItems='flex-start'>
                    <img height='48px' src={p.image} alt='image' />
                    <Typography mt='24px' variant='subtitle2' color='secondary.800' fontWeight={600}>
                      {p.name}
                    </Typography>
                    <Typography variant='caption' fontSize='10px' lineHeight='12px' color='secondary.600'>
                      {p.description}
                    </Typography>
                    <Typography mt='12px' variant='h1' lineHeight='42px' color='secondary.800' fontWeight={600}>
                      {formatMessage({ id: `currency.${p.currency}` })}
                      {p.amount / 100}
                    </Typography>
                    <Typography variant='caption' color='secondary.600'>
                      {formatMessage({ id: 'month_wo_vat' })}
                    </Typography>
                    <Divider
                      sx={{
                        height: '1px',
                        width: '100%',
                        marginTop: '12px',
                        marginBottom: '12px',
                        color: secondary[200]
                      }}
                    />
                    <Box display='flex' flexDirection='column' gap='12px'>
                      {PLANS_FEATURES[index].map((point) => (
                        <Box key={`${p.id}_${point}`} display='flex' flexDirection='row' alignItems='center' gap='12px'>
                          <CircleCheck2Icon />
                          <Typography variant='caption' color='secondary.800'>
                            {formatMessage(
                              { id: point },
                              { number: p.numberOfRegistrations, amount: p.additionalAmount }
                            )}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Button
                    disabled={
                      p.id === TRIAL_PLAN_ID
                        ? !!companyAccess?.companyInfo.trialEndDate || !!companySubscriptions?.subscriptions.length
                        : currentPlanId === p.id
                    }
                    onClick={() => setSelectedPlan(p.id)}
                    color='error'
                    variant='contained'
                  >
                    {formatMessage({ id: 'get_started' })}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant='subtitle2' color='secondary.800' fontWeight={400}>
              {formatMessage({ id: 'onboardings_count_explanation' })}
            </Typography>
            <Button onClick={onSubmit.execute} variant='contained'>
              {formatMessage({ id: 'next' })}
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
};
