import { useMutation, useQuery } from '@apollo/client';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { OrderDirection, Role, UserOrderField, type UpdateUserInput } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { deleteUserMutation, inviteResendMutation, updateUserMutation } from 'shared/api';
import { type DeleteUser, type DeleteUserVariables } from 'shared/api/__graphql__/DeleteUser';
import { type InviteResend, type InviteResendVariables } from 'shared/api/__graphql__/InviteResend';
import { type UpdateUser, type UpdateUserVariables } from 'shared/api/__graphql__/UpdateUser';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { ActionsPopup, ConfirmationDialog, EmptyTableBox, FormDialog, Loader, PageWrapper } from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { StyledTablePagination } from 'shared/components/StyledTablePagination';
import { useCustomStyles, useFiltersQuery, usePalette, useRoles, useTablePagination, useTableSort } from 'shared/hooks';
import { type Consultants, type ConsultantsVariables, type Consultants_users_data } from './__graphql__/Consultants';
import { AddConsultantForm } from './AddConsultantWidget';
import { consultantsQuery } from './api';
import { AgencyTab } from '.';

interface ConsultantsTableProps {
  query: string;
  agencyFilter?: string;
  setQuery: (query: string) => void;
  setAgency: (agencyId?: string) => void;
}

export const ConsultantsTable: FC<ConsultantsTableProps> = ({ query, agencyFilter, setQuery, setAgency }) => {
  const intl = useIntl();
  const customStyles = useCustomStyles();
  const { primary, secondary, success, error } = usePalette();

  const { state } = useLocation();
  const { isAdmin, isSuperAdmin, isRegistrar } = useRoles();

  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { field, direction, createSortHandler, setSort } = useTableSort<UserOrderField>({});
  const { parsedQuery, pushQuery } = useFiltersQuery();

  const [consultantForAction, setConsultantForAction] = useState<{
    consultant?: Consultants_users_data;
    action?: 'deletion' | 'deactivation';
  }>({});
  const [consultantForEdit, setConsultantForEdit] = useState<Consultants_users_data | undefined>();

  const consultantsResult = useQuery<Consultants, ConsultantsVariables>(consultantsQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
              field,
              direction
            }
          : null,
      offset,
      limit,
      query: query?.length > 2 ? query.trim() : null,
      agencyId: agencyFilter
    },
    context: API_CONTEXT.AUTH
  });

  useEffect(() => {
    pushQuery({
      filters: [
        {
          id: 'tab',
          value: AgencyTab.consultants
        },
        ...(agencyFilter
          ? [
              {
                id: 'agency',
                value: agencyFilter ?? null
              }
            ]
          : []),
        ...(query
          ? [
              {
                id: 'search',
                value: query
              }
            ]
          : [])
      ],
      sort: [
        ...(field
          ? [
              {
                id: field,
                desc: direction === OrderDirection.desc
              }
            ]
          : [])
      ],
      pageIndex: tablePaginationProps.page
    });
  }, [agencyFilter, direction, field, pushQuery, query, tablePaginationProps.page]);

  useEffect(() => {
    setSort(
      (parsedQuery?.sortBy ?? UserOrderField.firstName) as UserOrderField,
      (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
    );
    setQuery((parsedQuery?.search as string) ?? '');
    if (!agencyFilter) {
      setAgency(parsedQuery?.agency ? String(parsedQuery?.agency) : undefined);
    }
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const isTableEmpty = useMemo(
    () => !consultantsResult.data?.users.data?.length && tablePaginationProps.page === 0,
    [consultantsResult.data?.users.data?.length, tablePaginationProps.page]
  );

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(updateUserMutation, {
    refetchQueries: ['Consultants'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const handleCloseActionModal = useCallback(() => {
    setConsultantForAction({});
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setConsultantForEdit(undefined);
  }, []);

  const handleUpdateConsultant = useAsyncCallback(
    async ({ id, name, email, agencyId }: UpdateUserInput & { name: string }) => {
      const [firstName = '', lastName = ''] = name.split(' ');

      await updateUser({
        variables: {
          data: {
            id,
            firstName,
            lastName,
            ...(email ? { email } : {}),
            agencyId
          }
        }
      });
      toast(<SuccessSnackbar title={intl.formatMessage({ id: 'the_consultant_successfully_updated' })} />);
      handleCloseEditModal();
    }
  );

  const [inviteUser] = useMutation<InviteResend, InviteResendVariables>(inviteResendMutation, {
    context: API_CONTEXT.AUTH
  });

  const sendInvite = useCallback(
    async (id: string) => {
      try {
        await inviteUser({ variables: { id } });
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'access_link_was_successfully_sent' })}
            message={intl.formatMessage({ id: 'the_user_received_new_link' })}
          />
        );
      } catch (e) {
        console.log('Error: ', e);
      }
    },
    [intl, inviteUser]
  );

  const [deleteUser] = useMutation<DeleteUser, DeleteUserVariables>(deleteUserMutation, {
    refetchQueries: ['Consultants'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const actionConsultantHandler = useCallback(
    async ({ consultant, action }: { consultant?: Consultants_users_data; action?: 'deletion' | 'deactivation' }) => {
      if (action === 'deactivation') {
        await updateUser({
          variables: {
            data: {
              id: consultant!.id,
              isActive: !consultant?.isActive
            }
          }
        });
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'the_consultant_successfully_updated' })}
            message={intl.formatMessage({
              id: consultant?.isActive ? 'the_user_deactivated' : 'the_user_is_activated'
            })}
          />
        );
      } else {
        await deleteUser({
          variables: {
            id: consultant!.id
          }
        });
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'the_consultant_successfully_deleted' })}
            message={intl.formatMessage({ id: 'consultant_data_was_successfully_removed' })}
          />
        );
      }

      handleCloseActionModal();
    },
    [deleteUser, handleCloseActionModal, intl, updateUser]
  );

  return (
    <>
      {consultantsResult.loading || handleUpdateConsultant.loading ? (
        <Loader />
      ) : (
        <PageWrapper>
          <>
            <TableContainer>
              <Table
                sx={{
                  '& tr': {
                    height: 'auto',
                    display: 'grid'
                  },
                  '& > thead tr': {
                    gridTemplateColumns: '10fr 10fr 9fr 8fr 8fr 8fr 5fr'
                  },
                  '& > tbody tr': {
                    gridTemplateColumns: isTableEmpty ? 'auto' : '10fr 10fr 9fr 8fr 8fr 8fr 5fr'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(UserOrderField.firstName)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'name' })}
                        <SortIcon active={field === UserOrderField.firstName} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(UserOrderField.email)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'email' })}
                        <SortIcon active={field === UserOrderField.email} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(UserOrderField.agencyName)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'agency' })}
                        <SortIcon active={field === UserOrderField.agencyName} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(UserOrderField.registrations)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'registrations' })}
                        <SortIcon active={field === UserOrderField.registrations} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(UserOrderField.lastLogin)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'last_login' })}
                        <SortIcon active={field === UserOrderField.lastLogin} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(UserOrderField.isActive)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'status' })}
                        <SortIcon active={field === UserOrderField.isActive} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>{intl.formatMessage({ id: 'action' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    '& td': {
                      lineHeight: '28px',
                      '&, & span': {
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }
                  }}
                >
                  {consultantsResult.data?.users.data?.map((consultant) => (
                    <TableRow
                      key={consultant.id}
                      sx={{
                        '& a': {
                          color: secondary[700],
                          fontWeight: '400',
                          textDecoration: 'underline',
                          '&:hover': {
                            color: primary[600]
                          },
                          '&:active': {
                            color: primary[800]
                          }
                        }
                      }}
                    >
                      <TableCell>
                        <Highlighter
                          autoEscape
                          highlightStyle={customStyles.highlighter}
                          searchWords={[`${query}`]}
                          textToHighlight={`${consultant.firstName ?? ''} ${consultant.lastName ?? ''}`}
                        />
                      </TableCell>
                      <TableCell>{consultant.email}</TableCell>
                      <TableCell>{consultant.agency?.name}</TableCell>
                      <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                        {consultant.consultantRegistrations?.length}
                      </TableCell>
                      <TableCell>
                        {consultant.lastLogin ? dayjs(consultant.lastLogin).format('DD/MM/YYYY HH:mm') : ''}
                      </TableCell>
                      <TableCell className='badge status'>
                        <Box
                          sx={{
                            backgroundColor: consultant.isActive ? success[200] : error[100]
                          }}
                        >
                          {intl.formatMessage({ id: `status.${consultant.isActive ? 'ACTIVE' : 'INACTIVE'}` })}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ '&, & span': { display: 'block' } }}>
                        <ActionsPopup
                          items={[
                            {
                              id: 'edit',
                              onClick: () => {
                                setConsultantForEdit(consultant);
                              }
                            },
                            {
                              id: 'send_link',
                              onClick: async () => {
                                await sendInvite(consultant.id);
                              },
                              disabled: !consultant.email || !consultant.isActive
                            },
                            {
                              id: consultant.isActive ? 'deactivate' : 'activate',
                              onClick: () => {
                                setConsultantForAction({ consultant, action: 'deactivation' });
                              }
                            },
                            ...(isAdmin || isSuperAdmin || isRegistrar
                              ? [
                                  {
                                    id: 'delete',
                                    onClick: () => {
                                      setConsultantForAction({ consultant, action: 'deletion' });
                                    }
                                  }
                                ]
                              : [])
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {isTableEmpty && (
                    <TableRow
                      style={{
                        height: 64 * tablePaginationProps.rowsPerPage,
                        background: 'inherit'
                      }}
                    >
                      <TableCell
                        colSpan={5}
                        style={{
                          height: 64 * tablePaginationProps.rowsPerPage,
                          padding: 0
                        }}
                      >
                        <EmptyTableBox />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <StyledTablePagination count={consultantsResult.data?.users.total ?? 0} {...tablePaginationProps} />
            <ConfirmationDialog
              open={!!consultantForAction.consultant}
              onClose={handleCloseActionModal}
              onConfirm={async () => {
                await actionConsultantHandler(consultantForAction);
              }}
              confirmButtonContent={intl.formatMessage({
                id:
                  consultantForAction.action === 'deletion'
                    ? 'delete'
                    : consultantForAction.consultant?.isActive
                    ? 'deactivate'
                    : 'activate'
              })}
              title={intl.formatMessage({
                id:
                  consultantForAction.action === 'deletion'
                    ? 'consultant_removal'
                    : consultantForAction.consultant?.isActive
                    ? 'consultant_deactivation'
                    : 'consultant_activation'
              })}
              content={intl.formatMessage(
                {
                  id:
                    consultantForAction.action === 'deletion'
                      ? 'user_deletion_confirm'
                      : consultantForAction.consultant?.isActive
                      ? 'user_deactivation_confirm'
                      : 'user_activation_confirm'
                },
                {
                  role: Role.CONSULTANT.charAt(0) + Role.CONSULTANT.substring(1).toLowerCase(),
                  firstName: consultantForAction.consultant?.firstName,
                  lastName: consultantForAction.consultant?.lastName,
                  email: consultantForAction.consultant?.email
                }
              )}
            />
            <FormDialog
              isOpen={!!consultantForEdit}
              onClose={handleCloseEditModal}
              header={intl.formatMessage({ id: 'edit_consultant' })}
              size='xs'
              loading={handleUpdateConsultant.loading}
              confirmBtnName={intl.formatMessage({ id: 'save' })}
              initialValues={{
                ...consultantForEdit,
                agency: consultantForEdit?.agency?.name,
                agencyId: consultantForEdit?.agency?.id,
                name: `${consultantForEdit?.firstName} ${consultantForEdit?.lastName}`
              }}
              onSubmit={async (consultant: UpdateUserInput & { name: string; agency?: string }) => {
                await handleUpdateConsultant.execute(consultant);
              }}
            >
              <AddConsultantForm emailExists={!!consultantForEdit?.email} />
            </FormDialog>
          </>
        </PageWrapper>
      )}
    </>
  );
};
