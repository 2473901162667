import { Box, Collapse, Tab, Tabs, Typography } from '@mui/material';
import { BASIC_INFO_SECTION } from 'admin/pages/Forms/ViewForm/constants';
import { useContext, type FC, useState } from 'react';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { ArrowIcon, CircleCheckIcon } from '../icons';

interface Props {
  parentTabIndex: number;
  childTabIndex: number;
  changeParentTab: (index: number, f: () => void) => void;
  changeChildTab: (index: number) => void;
}

export const RegistrationSteps: FC<Props> = (props) => {
  const { changeParentTab, parentTabIndex, childTabIndex, changeChildTab } = props;
  const { completenessOfRegistration, sections } = useContext(RegistrationContext);
  const [isChildrenShown, setIsChildrenShown] = useState(true);

  const getIcon = (stepNumber: number, sectionId: string): JSX.Element => {
    return completenessOfRegistration[sectionId] ? (
      <Box sx={{ border: 'none !important' }}>
        <CircleCheckIcon />
      </Box>
    ) : (
      <Box>
        <Typography>{stepNumber}</Typography>
      </Box>
    );
  };

  return (
    <Tabs
      value={parentTabIndex}
      orientation='vertical'
      variant='scrollable'
      scrollButtons={false}
      sx={{
        maxWidth: '100%',
        minWidth: '250px',
        maxHeight: 'calc(100vh - 90px - 190px)'
      }}
    >
      <Tab
        icon={getIcon(1, BASIC_INFO_SECTION.id)}
        iconPosition='start'
        label={BASIC_INFO_SECTION.name}
        onClick={() => {
          changeParentTab(0, () => setIsChildrenShown(false));
        }}
        value={0}
        className={completenessOfRegistration[BASIC_INFO_SECTION.id] ? 'done' : ''}
        disableRipple
      />
      {sections.map((item, index) => {
        const { id, name, children } = item;
        const sectionIndex = index + 1;
        const isParentActive = parentTabIndex === sectionIndex;
        const collapseOpened = isParentActive && isChildrenShown;

        return [
          <Tab
            key={id}
            icon={getIcon(sectionIndex + 1, id)}
            iconPosition='start'
            label={
              <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                {name}
                {children.length ? (
                  collapseOpened ? (
                    <ArrowIcon direction='up' />
                  ) : (
                    <ArrowIcon direction='down' />
                  )
                ) : null}
              </Box>
            }
            onClick={() => {
              changeParentTab(sectionIndex, () => setIsChildrenShown((v) => (isParentActive ? !v : true)));
            }}
            value={sectionIndex}
            disableRipple
            className={completenessOfRegistration[id] ? 'done' : ''}
          />,
          ...(children.length
            ? [
                <Collapse
                  key={`${id}_children`}
                  in={collapseOpened}
                  timeout='auto'
                  sx={{ marginTop: collapseOpened ? 'unset' : '-8px' }}
                >
                  <Tabs
                    value={childTabIndex}
                    onChange={(_, v) => {
                      changeChildTab(v);
                    }}
                    orientation='vertical'
                    className='nested'
                    sx={{ minWidth: '250px', borderRadius: 0 }}
                  >
                    <Tab sx={{ display: 'none' }} value={-1} />
                    {children.map(({ name }, index) => (
                      <Tab key={name} label={name} value={index} className='nested' disableRipple />
                    ))}
                  </Tabs>
                </Collapse>
              ]
            : [])
        ];
      })}
    </Tabs>
  );
};
