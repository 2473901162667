import { useQuery } from '@apollo/client';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { OrderDirection, RegistrationOrderField, RegistrationStatus } from '__graphql__/globalTypes';
import { RegistrationsTable } from 'admin/components';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Loader, PageTitle, PageWrapper, SelectFilter } from 'shared/components';
import { CSVReferralsUploadWidget } from 'shared/components/CSVReferralsUploadWidget';
import { useFiltersQuery, usePalette, useTablePagination, useTableSort } from 'shared/hooks';
import { OwnerOption } from 'shared/types';
import { type Registrations as RegistrationsQuery, type RegistrationsVariables } from './__graphql__/Registrations';
import { registrationsQuery } from './api';

enum TypeOption {
  current = 'current',
  dummyRecord = 'dummyRecord',
  locked = 'locked'
}

export const Dashboard: FC = () => {
  const intl = useIntl();
  const { primary, secondary } = usePalette();
  const location = useLocation();

  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { parsedQuery, pushQuery } = useFiltersQuery();
  const { field, direction, createSortHandler, setSort } = useTableSort<RegistrationOrderField>({
    defaultField: RegistrationOrderField.status,
    defaultDirection: OrderDirection.asc
  });
  const [statuses, setStatuses] = useState<RegistrationStatus[]>([]);
  const [types, setTypes] = useState<TypeOption[]>([TypeOption.current]);
  const [owner, setOwner] = useState<OwnerOption>(OwnerOption.all);

  const getTypeQuery = useCallback((t: TypeOption[]) => {
    if (!t?.length || t.length === 3) return {};
    if (t.length === 1) return { [t[0]]: true };
    if (!t.includes(TypeOption.locked)) return { [TypeOption.locked]: false };
    if (!t.includes(TypeOption.dummyRecord)) return { [TypeOption.dummyRecord]: false };
    if (!t.includes(TypeOption.current)) return { [TypeOption.current]: false };
    return {};
  }, []);

  const result = useQuery<RegistrationsQuery, RegistrationsVariables>(registrationsQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
              field,
              direction
            }
          : null,
      offset,
      limit,
      filter: {
        ...(statuses?.length ? { statuses } : {}),
        ...getTypeQuery(types),
        ...(owner === OwnerOption.my ? { addedByMe: true } : {})
      },
      ...(location.state?.search ? { query: location.state.search.toLowerCase() } : {})
    }
  });

  useEffect(() => {
    pushQuery(
      {
        filters: [
          ...(statuses?.length
            ? [
                {
                  id: 'statuses',
                  value: statuses
                }
              ]
            : []),
          ...(types.length
            ? [
                {
                  id: 'types',
                  value: types
                }
              ]
            : [
                {
                  id: 'types',
                  value: 'all'
                }
              ]),
          { id: 'owner', value: owner }
        ],
        sort: [
          ...(field
            ? [
                {
                  id: field,
                  desc: direction === OrderDirection.desc
                }
              ]
            : [])
        ],
        pageIndex: tablePaginationProps.page
      },
      location.state?.search ? { search: location.state?.search } : undefined
    );
  }, [direction, field, owner, pushQuery, statuses, tablePaginationProps.page, types, location.state?.search]);

  useEffect(() => {
    setSort(
      (parsedQuery?.sortBy ?? RegistrationOrderField.status) as RegistrationOrderField,
      (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
    );
    setStatuses((parsedQuery?.statuses as RegistrationStatus[]) ?? []);
    setTypes(parsedQuery?.types === 'all' ? [] : (parsedQuery?.types as TypeOption[]) ?? [TypeOption.current]);
    setOwner((parsedQuery?.owner as OwnerOption) ?? OwnerOption.all);
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    tablePaginationProps.onPageChange(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses?.length, types?.length, owner]);

  const statusOptions = useMemo(
    () =>
      Object.values(RegistrationStatus).map((s) => ({
        value: s,
        label: intl.formatMessage({ id: `registration.status.${s}` })
      })),
    [intl]
  );
  const typeOptions = useMemo(
    () =>
      Object.values(TypeOption).map((t) => ({
        value: t,
        label: intl.formatMessage({ id: `registration.type.${t}` })
      })),
    [intl]
  );
  const ownerOptions = useMemo(() => Object.values(OwnerOption), []);

  if (result.loading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box
              sx={{
                '& > *:first-of-type': {
                  marginRight: '12px'
                },
                '& > *:nth-of-type(2)': {
                  marginRight: '22px'
                }
              }}
            >
              <SelectFilter
                values={statuses}
                placeholder={intl.formatMessage({ id: 'status' })}
                allItemLabel={intl.formatMessage({ id: 'registration.status.all' })}
                options={statusOptions}
                onApply={setStatuses}
              />
              <SelectFilter
                defaultValue={[TypeOption.current]}
                values={types}
                placeholder={intl.formatMessage({ id: 'type' })}
                allItemLabel={intl.formatMessage({ id: 'registration.type.all' })}
                options={typeOptions}
                onApply={setTypes}
              />
              <ToggleButtonGroup
                value={owner}
                exclusive
                onChange={(e, v) => {
                  if (v !== null) {
                    setOwner(v);
                  }
                }}
                orientation='horizontal'
                sx={{
                  color: secondary[800],
                  flexWrap: 'wrap',
                  gap: '8px',
                  '& > button.Mui-selected': {
                    backgroundColor: `${primary[400]} !important`,
                    borderColor: `${primary[600]} !important`
                  }
                }}
              >
                {ownerOptions.map((o) => (
                  <ToggleButton key={o} value={o}>
                    {intl.formatMessage({ id: `registration.owner.${o}` })}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <CSVReferralsUploadWidget />
          </Box>
        </PageTitle>
        <PageWrapper>
          <RegistrationsTable
            data={result.data?.registrations.data}
            total={result.data?.registrations.total}
            field={field}
            direction={direction}
            createSortHandler={createSortHandler}
            tablePaginationProps={tablePaginationProps}
          />
        </PageWrapper>
      </Box>
    </Box>
  );
};
