import { type FormikConfig } from 'formik';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { isConflictError } from 'shared/utils/errors';
import { PropertyBlock } from './PropretyBlock';
import { type ComponentPropertiesProps } from './types';

export const VariableProp: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  const handleSubmit: FormikConfig<any>['onSubmit'] = async (values, helpers) => {
    const error = await onSubmit(values);
    if (isConflictError(error?.extensions?.code as string)) {
      helpers.setFieldError('variable', formatMessage({ id: 'unique_var_error' }));
      return error;
    }
    return null;
  };

  return (
    <PropertyBlock
      value={{ variable: component?.variable }}
      onSubmit={handleSubmit}
      readOnly={isReadOnly}
      title={formatMessage({ id: 'component_properties.variable' })}
      tooltip={formatMessage({ id: 'component_tooltips.variable' })}
    />
  );
};
