import { type FetchResult, type MutationFunctionOptions, type MutationHookOptions } from '@apollo/client';
import { type AllowedCharacters, type DocumentType, type QuestionComponent } from '__graphql__/globalTypes';
import { createContext } from 'react';
import { type AddSection, type AddSectionVariables } from 'shared/hooks/useFormBuilder/__graphql__/AddSection';
import { type CloneQuestion, type CloneQuestionVariables } from 'shared/hooks/useFormBuilder/__graphql__/CloneQuestion';
import { type CloneSection, type CloneSectionVariables } from 'shared/hooks/useFormBuilder/__graphql__/CloneSection';
import {
  type CreateQuestion,
  type CreateQuestionVariables
} from 'shared/hooks/useFormBuilder/__graphql__/CreateQuestion';
import {
  type DeleteQuestion,
  type DeleteQuestionVariables
} from 'shared/hooks/useFormBuilder/__graphql__/DeleteQuestion';
import { type FormBuilderErrors_formErrors } from 'shared/hooks/useFormBuilder/__graphql__/FormBuilderErrors';
import { type FormBuilderQuestions_questions } from 'shared/hooks/useFormBuilder/__graphql__/FormBuilderQuestions';
import {
  type FormDetails_formDetails,
  type FormDetails_formDetails_sections
} from 'shared/hooks/useFormBuilder/__graphql__/FormDetails';
import {
  type UpdateQuestion,
  type UpdateQuestionVariables
} from 'shared/hooks/useFormBuilder/__graphql__/UpdateQuestion';
import { type UpdateSection, type UpdateSectionVariables } from 'shared/hooks/useFormBuilder/__graphql__/UpdateSection';
import { type UpsertForm, type UpsertFormVariables } from 'shared/hooks/useFormBuilder/__graphql__/UpsertForm';

export interface Component {
  id: string;
  text: string | null;
  component: QuestionComponent;
  variable: string;
  documentType: DocumentType | null;
  tooltip: string | null;
  options: Array<{
    optionId: string;
    value: string;
    label: string;
  }>;
  description: string | null;
  placeholder: string | null;
  minChar: number | null;
  maxChar: number | null;
  required: boolean | null;
  additionalText: string | null;
  allowedCharacters: AllowedCharacters | null;
  withTimePicker: boolean | null;
  rulesCount?: number | null;
  initialAnswer?: string | null;
}

export interface FormContext {
  form?: FormDetails_formDetails;
  isReadOnly: boolean;
  formErrors: Record<string, string>;
  loading: Record<'formDetails' | 'upsertForm', boolean>;
  section?: FormDetails_formDetails_sections;
  actionOnSection: 'edit' | 'add' | 'delete' | null;
  questions: FormBuilderQuestions_questions[];
  draggableId: string;
  component: Component | null;
  editMainProperty: boolean;
  submitForm: (_: MutationHookOptions<UpsertForm, UpsertFormVariables>) => Promise<void>;
  fetchErrors: (_?: boolean, _1?: string) => Promise<FormBuilderErrors_formErrors[]>;
  setEditMainProperty: (v: boolean) => void;
  setComponent: (_: Component | null) => void;
  setDraggableId: (_: string) => void;
  setSection: (_: FormDetails_formDetails_sections) => void;
  setActionOnSection: (_: FormContext['actionOnSection']) => void;
  createSection: (_: MutationFunctionOptions<AddSection, AddSectionVariables>) => Promise<FetchResult<AddSection>>;
  cloneSection: (_: MutationFunctionOptions<CloneSection, CloneSectionVariables>) => Promise<FetchResult<CloneSection>>;
  updateSection: (
    _: MutationFunctionOptions<UpdateSection, UpdateSectionVariables>
  ) => Promise<FetchResult<UpdateSection>>;
  upsertForm: (_: MutationFunctionOptions<UpsertForm, UpsertFormVariables>) => Promise<FetchResult<UpsertForm>>;
  createQuestion: (
    _: MutationFunctionOptions<CreateQuestion, CreateQuestionVariables>
  ) => Promise<FetchResult<CreateQuestion>>;
  updateQuestion: (
    _: MutationFunctionOptions<UpdateQuestion, UpdateQuestionVariables>
  ) => Promise<FetchResult<UpdateQuestion>>;
  cloneQuestion: (
    _: MutationFunctionOptions<CloneQuestion, CloneQuestionVariables>
  ) => Promise<FetchResult<CloneQuestion>>;
  deleteQuestion: (
    _: MutationFunctionOptions<DeleteQuestion, DeleteQuestionVariables>
  ) => Promise<FetchResult<DeleteQuestion>>;
}

export const FormBuilderContext = createContext<FormContext>({
  loading: {
    formDetails: false,
    upsertForm: false
  },
  formErrors: {},
  isReadOnly: false,
  actionOnSection: null,
  questions: [],
  draggableId: '',
  component: null,
  editMainProperty: false,
  fetchErrors: async () => [],
  submitForm: async () => {},
  setEditMainProperty: () => {},
  setComponent: () => {},
  setDraggableId: () => {},
  setSection: () => {},
  setActionOnSection: () => {},
  createSection: async () => await Promise.resolve({ data: { addSection: '' } }),
  cloneSection: async () => await Promise.resolve({ data: { cloneSection: true } }),
  updateSection: async () => await Promise.resolve({ data: { updateSection: '' } }),
  upsertForm: async () => await Promise.resolve({ data: { upsertForm: '' } }),
  createQuestion: async () => await Promise.resolve({ data: { createQuestion: '' } }),
  updateQuestion: async () => await Promise.resolve({ data: { updateQuestion: '' } }),
  cloneQuestion: async () => await Promise.resolve({ data: { cloneQuestion: '' } }),
  deleteQuestion: async () => await Promise.resolve({ data: { deleteQuestion: true } })
});
