import { Fragment, type FC } from 'react';

interface Props {
  text: string;
}

export const TextWithNewLines: FC<Props> = ({ text }) => {
  return (
    <>
      {text.split('\\n').map((line, index) => (
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
    </>
  );
};
