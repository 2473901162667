import { useMutation, type FetchResult } from '@apollo/client';
import { QuestionComponent } from '__graphql__/globalTypes';
import { type ParsedDocument, type ParsedDocumentVariables } from 'admin/pages/Forms/__graphql__/ParsedDocument';
import {
  type SaveDocumentContent,
  type SaveDocumentContentVariables
} from 'admin/pages/Forms/__graphql__/SaveDocumentContent';
import { parseDocumentMutation, saveDocumentContentMutation } from 'admin/pages/Forms/api';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import { ACCEPT_FILES } from 'shared/constants/file';
import { FormBuilderContext } from 'shared/contexts';
import { PropertyBlock } from '../../shared';
import { type ComponentPropertiesProps } from '../../shared/types';
import { DocumentPreviewModal } from './DocumentPreviewModal';
import { Renderer } from './Renderer';

const isDocx = (file: File): boolean => {
  const extension = file.name.split('.')?.pop();
  const acceptableTypes = Object.keys(ACCEPT_FILES['DOC/DOCX']);
  return ['doc', 'docx'].includes(extension!) && acceptableTypes.includes(file.type);
};

export const UploadDocumentProp: FC<Pick<ComponentPropertiesProps, 'isReadOnly'>> = ({ isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { form, component, upsertForm, fetchErrors } = useContext(FormBuilderContext);
  const [isOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [parsedDocument, setParsedDocument] = useState<ParsedDocument['parseDocument'] | null>(null);
  const [file, setFile] = useState<File | null>();

  const [parseDocument, { loading: isParsing }] = useMutation<ParsedDocument, ParsedDocumentVariables>(
    parseDocumentMutation,
    {
      context: API_CONTEXT.DOCUMENT
    }
  );

  const [saveDocContentToQuestion, { loading: isSavingDocContent }] = useMutation<
    SaveDocumentContent,
    SaveDocumentContentVariables
  >(saveDocumentContentMutation, {
    context: API_CONTEXT.DOCUMENT,
    refetchQueries: ['FormBuilderQuestions', 'PreviewDocument']
  });

  const loading = isParsing || isSavingDocContent;
  const componentName = formatMessage({ id: `components.${component?.component}` });
  const isContract = component?.component === QuestionComponent.CONTRACT;

  const interactiveComponent = (
    <Renderer
      readOnly={isReadOnly}
      parsedDocument={parsedDocument}
      loading={loading}
      isModalOpen={isOpen}
      handleFile={async (event) => {
        const file = event.target.files?.[0];
        if (file && !isDocx(file)) {
          toast(
            <AttentionSnackbar
              title={formatMessage({ id: 'incorrect_doc_type_title' })}
              message={formatMessage({ id: 'incorrect_doc_type_message' })}
            />
          );
        } else if (file) {
          const response = await parseDocument({ variables: { file } });
          setFileName(file.name);
          setParsedDocument(response.data?.parseDocument!);
          setFile(file);
          setIsOpen(true);
        }
      }}
    />
  );

  const onSubmit = async (values?: Record<'contractType', 'boolean'>): Promise<void> => {
    if (file) {
      const extension = fileName.split('.')?.pop();
      const fileToSend = new File([await file.arrayBuffer()], `${component?.id}.${extension}`, {
        type: 'application/octet-stream'
      });
      const requests: Array<Promise<FetchResult<any> | void>> = [
        saveDocContentToQuestion({
          variables: { data: fileToSend }
        }).then(async () => {
          const errors = await fetchErrors();
          if (errors.length) {
            toast(
              <AttentionSnackbar
                title={formatMessage({ id: 'signature_needed_title' })}
                message={formatMessage({ id: 'signature_needed_message' })}
              />
            );
          }
        })
      ];
      if (isContract) {
        requests.push(
          upsertForm({
            variables: { data: { id: form?.id, contractType: values?.contractType, name: form?.name! } }
          })
        );
      }
      await Promise.all(requests).then(() => {
        if (isContract) {
          form!.contractType = values?.contractType!;
        }
      });
    } else if (values?.contractType) {
      await upsertForm({
        variables: { data: { id: form?.id, contractType: values?.contractType, name: form?.name! } }
      }).then(async () => {
        const errors = await fetchErrors();
        if (errors.length) {
          toast(
            <AttentionSnackbar
              title={formatMessage({ id: 'signature_needed_title' })}
              message={formatMessage({ id: 'signature_needed_message' })}
            />
          );
        }
      });
    }
  };

  return (
    <>
      <PropertyBlock
        loading={loading}
        onCancel={() => {
          setParsedDocument(null);
        }}
        readOnly={isReadOnly || !isContract}
        onSubmit={onSubmit}
        title={componentName}
        value={{ contractType: form?.contractType ?? '' }}
        tooltip={formatMessage({ id: `component_tooltips.${component?.component.toLowerCase()}` })}
        ViewComponent={isContract ? <Renderer readOnly /> : interactiveComponent}
        EditComponent={interactiveComponent}
      />
      <DocumentPreviewModal
        onAdd={isContract ? undefined : onSubmit}
        setIsModalOpen={setIsOpen}
        isOpen={isOpen}
        parsedDocument={parsedDocument}
        onCancel={() => {
          setParsedDocument(null);
          setIsOpen(false);
        }}
      />
    </>
  );
};
