import {
  type Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  type ButtonProps
} from '@mui/material';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { usePalette } from 'shared/hooks';
import { CrossIcon } from './icons';
import { Loader } from './Loader';

interface Props {
  maxWidth?: Breakpoint;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  title: string;
  content?: string | JSX.Element;
  confirmButtonContent?: string;
  confirmButtonProps?: ButtonProps;
  additionalButton?: JSX.Element;
  loading?: boolean;
}

export const ConfirmationDialog: FC<Props> = ({
  maxWidth,
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirmButtonContent = 'Confirm',
  confirmButtonProps,
  additionalButton,
  loading
}) => {
  const intl = useIntl();
  const { secondary } = usePalette();

  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      sx={{ '& .MuiPaper-rounded': { borderRadius: '16px', maxWidth: maxWidth ? undefined : '550px' } }}
    >
      <DialogTitle sx={{ p: '24px 24px 16px' }}>
        <Typography variant='h6' color='secondary.800'>
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      {content && (
        <DialogContent sx={{ p: '24px 24px 16px' }}>
          <DialogContentText color={secondary[800]}>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          p: '0 24px 24px'
        }}
      >
        <Button onClick={onClose} variant='text' size='small'>
          {intl.formatMessage({ id: 'cancel' })}
        </Button>
        <div>
          {additionalButton}
          <Button {...(confirmButtonProps ?? {})} onClick={onConfirm} variant='contained' autoFocus>
            {loading ? <Loader /> : confirmButtonContent}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
