import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

type Direction = 'up' | 'down' | 'left' | 'right';

export const ChevronIcon: FC<IconProps & { direction?: Direction }> = (props) => {
  const { color, className, direction = 'down' } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];
  let path = '';

  switch (direction) {
    case 'up':
      path = 'M18 15L12 9L6 15';
      break;
    case 'down':
      path = 'M6 9L12 15L18 9';
      break;
    case 'left':
      path = 'M15 18L9 12L15 6';
      break;
    case 'right':
      path = 'M9 18L15 12L9 6';
      break;
  }

  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d={path} stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
