import gql from 'graphql-tag';

export const reportQuery = gql`
  query Report(
    $offset: Int!
    $limit: Int!
    $orderBy: RegistrationOrder
    $filter: ReportFilter!
  ) {
    report(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      newCount
      paymentsReceivedCount
      notProceedCount
      completedCount
      total
      data {
        id
        email
        firstName
        lastName
        phoneNumber
        title
        status
        createdAt
        updatedAt
        locked
        dummyRecord
      }
    }
  }
`;
