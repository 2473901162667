/* eslint-disable */
import { convertTimeToDate } from 'admin/pages/Forms/RuleBuilder/utils';
import { isValidDate } from './date';
import dayjs from 'dayjs';

/**
 * if .next() is called without args -> next validator will be called
 * if .next(null) is called with `null` -> pipeline will be stopped with a `valid` result
 * if .next(string) is called with any string -> pipeline will be stopped with an `invalid` result
 */
type TNext = (result?: string | null) => void;

export type TValidationFn = (value: unknown, next: TNext) => void;

type TMessageBuilder<T> = string | ((value: T) => string);

interface IValidationOptions<V = unknown> {
  /** Custom error message */
  message?: TMessageBuilder<V>;
  /** Custom error value for default error message */
  errorValue?: TMessageBuilder<V>;
}

const getErrorMessage = <T>(message: TMessageBuilder<T>, value: T): string => {
  return typeof message === 'function' ? message(value) : message;
};

const failPipeline = (stop: boolean): TValidationFn => {
  return (_, next) => {
    stop ? next(' ') : next();
  };
};

const required = (options?: IValidationOptions): TValidationFn => {
  return (value, next) => {
    const errorMessage = (): string => {
      return getErrorMessage(options?.message ?? 'Required', value);
    };

    if (value === undefined || value === null || value === '') {
      next(errorMessage());
      return;
    }

    if (typeof (value as any).toString === 'function') {
      const empty = /^\s*$/i.test((value as any).toString());
      if (empty) {
        next(errorMessage());
        return;
      } else {
        next();
        return;
      }
    }

    next();
  };
};

const optional = (): TValidationFn => (value, next) => {
  if (value === undefined || value === null) {
    next(null);
    return;
  } else if (typeof value === 'string') {
    const empty = /^\s*$/i.test(value);
    if (empty) {
      next(null);
      return;
    }
  }

  next();
};

export const isEmail = (value: any): boolean => {
  const emailRegex =
    // eslint-disable-next-line max-len, no-control-regex
    /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
  const isEmail = emailRegex.test(value as string);
  return isEmail;
};

const email = (): TValidationFn => (value, next) => {
  if (!value) {
    next(null);
    return;
  }

  next(isEmail(value) ? null : 'Wrong e-mail format');
};

const website = (): TValidationFn => (value, next) => {
  if (!value) {
    next(null);
    return;
  }
  const websiteRegex =
    // eslint-disable-next-line no-useless-escape
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;
  const iswebsite = websiteRegex.test(value as string);
  next(iswebsite ? null : 'Wrong website');
};

const phone = (): TValidationFn => (value, next) => {
  if (!value) {
    next(null);
    return;
  }
  // eslint-disable-next-line no-useless-escape
  const phoneRegex = /^[\+]?[0-9]{8,14}$/;
  const isPhone = phoneRegex.test(value as string);
  next(isPhone ? null : 'Wrong phone number');
};

const date = (): TValidationFn => (value, next) => {
  if (!value) {
    next(null);
    return;
  }
  next(isValidDate(value as string) ? null : 'Wrong date');
};

const password = (): TValidationFn => (value, next) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d?=.*[@#&()-[{}\]:;,?*'`~$^+=<>!"£%]{8,}$/;
  const isPassword = passwordRegex.test(value as string);
  next(isPassword ? null : 'Password must contain min 8 symbols including figures and uppercase letters');
};

const min = (amount: number, options?: IValidationOptions<number>): TValidationFn => {
  return (value, next) => {
    const actual = typeof value === 'number' ? value : parseFloat(value as any);
    if (Number.isNaN(actual)) {
      next();
      return;
    }

    if (actual < amount) {
      if (options?.message) {
        next(getErrorMessage(options.message, actual));
        return;
      }

      const val = options?.errorValue ? getErrorMessage(options.errorValue, amount) : amount.toString();

      next(`Should be more than ${val}`);
      return;
    }

    next();
  };
};

const minLength = (amount: number, options?: IValidationOptions<number>): TValidationFn => {
  return (value, next) => {
    if (`${value as string}`.length < amount) {
      const val = options?.errorValue ? getErrorMessage(options.errorValue, amount) : amount.toString();

      next(`Should has more characters than ${val}`);
      return;
    }

    next();
  };
};

const maxLength = (amount: number, options?: IValidationOptions<number>): TValidationFn => {
  return (value, next) => {
    if (`${value as string}`.length > amount) {
      const val = options?.errorValue ? getErrorMessage(options.errorValue, amount) : amount.toString();

      next(`Should has less characters than ${val}`);
      return;
    }

    next();
  };
};

const max = (amount: number, options?: IValidationOptions<number>): TValidationFn => {
  return (value, next) => {
    const actual = typeof value === 'number' ? value : parseFloat(value as any);
    if (Number.isNaN(actual)) {
      next();
      return;
    }

    if (actual > amount) {
      if (options?.message) {
        next(getErrorMessage(options.message, actual));
        return;
      }

      const val = options?.errorValue ? getErrorMessage(options.errorValue, actual) : actual.toString();

      next(`Should be less than ${val}`);
      return;
    }

    next();
  };
};

const boolean = (expected?: boolean, options?: IValidationOptions<boolean>): TValidationFn => {
  return (value, next) => {
    const result = expected !== undefined ? value === expected : value === true || value === false;

    if (result) {
      next();
      return;
    }

    next(getErrorMessage(options?.message ?? `Should be ${expected ?? 'set'}`, value as boolean));
  };
};

const maxHtmlLength = (amount: number, options?: IValidationOptions<boolean>): TValidationFn => {
  return (value, next) => {
    const div = document.createElement('div');
    div.innerHTML = value as string;
    const isExceededLimit = (div.textContent || div.innerText).length > amount;
    div.remove();
    if (isExceededLimit) {
      next(`Text length should be less then ${amount}`);
      return;
    }
    next();
  };
};

const startsWithLetterAndAlphanumeric = (): TValidationFn => (value, next) => {
  const isValid = /^[a-zA-Z][-a-zA-Z0-9]*$/.test(value as string);
  next(isValid ? null : 'Should start with letter and contain alphanumeric characters only');
};

export const isTimeRange = (v: string) =>
  /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM) - (0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/.test(v);

const timeRange = (): TValidationFn => (value, next) => {
  const v = value as string;
  if (isTimeRange(v)) {
    const [startTime, endTime] = v.split(' - ');
    const isValid = dayjs(convertTimeToDate(startTime)).isBefore(convertTimeToDate(endTime));
    next(isValid ? null : 'Invalid time range');
  } else {
    next('Invalid time range');
  }
};

export class Validator {
  private static processPipe(
    value: unknown,
    arr: TValidationFn[],
    index: number,
    resolve: () => void,
    reject: (value: string) => void,
    previousValidationResult?: string | null
  ): void {
    const pvr = previousValidationResult;

    if (pvr !== undefined) {
      if (pvr === null) {
        resolve();
        return;
      }
      if (typeof pvr === 'string') {
        reject(pvr);
        return;
      }
    }

    const nextValidator = arr[index];
    if (!nextValidator) {
      resolve();
      return;
    }

    nextValidator(value, (Validator.processPipe as any).bind(this, value, arr, index + 1, resolve, reject));
  }

  static pipe(...validators: TValidationFn[]) {
    return async (value: unknown) => {
      return await new Promise<string | void>((res) => {
        Validator.processPipe(value, validators, 0, res, res);
      });
    };
  }

  static get methods() {
    return {
      required,
      optional,
      email,
      website,
      phone,
      date,
      min,
      max,
      minLength,
      maxLength,
      boolean,
      password,
      failPipeline,
      maxHtmlLength,
      startsWithLetterAndAlphanumeric,
      timeRange
    };
  }
}
