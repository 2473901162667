import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Loader } from 'shared/components';
import { FileTextIcon } from 'shared/components/icons';
import routes from 'shared/constants/routes';
import { usePalette } from 'shared/hooks';
import { getStatusColor } from 'shared/utils/colors';
import { type ViewContractorRegistrations_registrations_data } from '../__graphql__/ViewContractorRegistrations';

interface Props {
  data?: ViewContractorRegistrations_registrations_data[] | null;
  loading: boolean;
}

export const ContractorRegistrationsList: FC<Props> = ({ data, loading }) => {
  const { formatMessage } = useIntl();
  const { primary, secondary } = usePalette();
  const navigate = useNavigate();

  if (loading) {
    return <Loader />;
  }

  if (!data?.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FileTextIcon size={24} color={primary[600]} />
        <Typography variant='body1' color='secondary.800'>
          {formatMessage({ id: 'no_registrations' })}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          width: '100%',
          height: '100%'
        }}
      >
        <Table>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item.id}
                sx={{
                  width: '100%',
                  padding: '12px 24px',
                  borderBottom: `1px solid ${secondary[100]}`
                }}
              >
                <TableCell>
                  <Box sx={{ display: 'flex' }}>
                    <FileTextIcon color={primary[600]} />
                    <Typography sx={{ ml: '14px', fontWeight: 400 }} variant='body2' color='secondary.800'>
                      {formatMessage({
                        id: item.form?.contractType ? `contractType.${item.form.contractType}` : 'no_contract'
                      })}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      maxWidth: '500px',
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {dayjs(item.createdAt).format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <Box
                    sx={{
                      padding: '8px 16px',
                      borderRadius: '4px',
                      maxWidth: '120px',
                      textAlign: 'center',
                      display: 'inline-block',
                      backgroundColor: getStatusColor(item.status)
                    }}
                  >
                    {formatMessage({ id: `registration.status.${item.status}` })}
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'end' }}>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      navigate(`/${routes.registration.replace(':id', item.id)}`);
                    }}
                  >
                    {formatMessage({ id: 'view' })}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
