import {
  ContractorStatus,
  type InvoiceStatus,
  RegistrationStatus,
  Role,
  TenantStatus,
  FormStatus
} from '__graphql__/globalTypes';

export const getStatusColor = (status: RegistrationStatus): string => {
  switch (status) {
    case RegistrationStatus.bREFERRED: {
      return '#E3D1FA';
    }
    case RegistrationStatus.iFORM_SENT: {
      return '#D9E3FA';
    }
    case RegistrationStatus.gIN_PROGRESS: {
      return '#D4EDF3';
    }
    case RegistrationStatus.lID_CHECKS: {
      return '#F4DBAB';
    }
    case RegistrationStatus.dNO_RESPONSE: {
      return '#EDE2BC';
    }
    case RegistrationStatus.oAWAITING_ID: {
      return '#E6EBAB';
    }
    case RegistrationStatus.rON_HOLD: {
      return '#FAC6A8';
    }
    case RegistrationStatus.tUPLOAD_TO_PAYROLL: {
      return '#A9EFB8';
    }
    case RegistrationStatus.vREGISTERED: {
      return '#F0CFF8';
    }
    case RegistrationStatus.xNOT_GOING_AHEAD: {
      return '#E0E3EB';
    }
    default: {
      return 'transparent';
    }
  }
};

export const getRoleColor = (role: Role): string => {
  switch (role) {
    case Role.ADMIN: {
      return '#FAC6A8';
    }
    case Role.CONSULTANT: {
      return '#D4EDF3';
    }
    case Role.CONTRACTOR: {
      return '#F0CFF8';
    }
    case Role.CUSTOMER_CARE: {
      return '#E6EBAB';
    }
    case Role.REGISTRAR: {
      return '#F4DBAB';
    }
    case Role.SUPER_ADMIN: {
      return '#A9EFB8';
    }
    default: {
      return 'transparent';
    }
  }
};

export const getActivityStatusColor = (status: TenantStatus): string => {
  switch (status) {
    case TenantStatus.ACTIVE: {
      return '#9DDC92';
    }
    case TenantStatus.INACTIVE: {
      return '#F7BCB4';
    }
    default: {
      return 'transparent';
    }
  }
};

export const getUserStatusColor = (isActive: boolean): string => {
  switch (isActive) {
    case true: {
      return '#9DDC92';
    }
    case false: {
      return '#F7BCB4';
    }
    default: {
      return 'transparent';
    }
  }
};

export const getContractorStatusColor = (status: ContractorStatus): string => {
  switch (status) {
    case ContractorStatus.CURRENT:
      return '#BFF4CB';
    case ContractorStatus.DUMMY:
      return '#FDD6EE';
    case ContractorStatus.ARCHIVED:
      return '#DCE5E6';
    default:
      return 'transparent';
  }
};

export const getTaskCategoryColor = (category: string): string => {
  switch (category) {
    case 'in_progress':
      return '#D9E3FA';
    case 'overdue':
      return '#F7BCB4';
    case 'upcoming':
      return '#FDD6EE';
    case 'due_today':
      return '#FAC6A8';
    case 'done':
      return '#BFF4CB';
    default:
      return 'transparent';
  }
};

export const getFormStatusColor = (status?: FormStatus): string => {
  switch (status) {
    case FormStatus.aPUBLISHED:
      return '#9DDC92';
    case FormStatus.bUNPUBLISHED:
      return '#F7BCB4';
    case FormStatus.cDRAFT:
      return '#D4EDF3';
    default:
      return 'transparent';
  }
};

export const getInvoiceStatusColor = (status: InvoiceStatus): string => {
  switch (status) {
    case 'draft':
      return 'lightgray';
    case 'paid':
      return '#9DDC92';
    case 'void':
      return 'lightblue';
    case 'open':
      return 'lightpink';
    case 'uncollectible':
      return 'lightorange';
    default:
      return 'transparent';
  }
};
