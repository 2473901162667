import { Operator, QuestionComponent } from '__graphql__/globalTypes';
import { Field, useFormikContext, type FieldAttributes, type FieldConfig } from 'formik';
import { path } from 'ramda';
import { useContext, useEffect, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'shared/components';
import { DateComponent } from 'shared/components/form/DateComponent';
import { SelectComponent } from 'shared/components/form/SelectComponent';
import { TimeComponent } from 'shared/components/form/TimeComponent';
import { type FormComponentProps } from 'shared/components/form/types';
import { FormBuilderContext } from 'shared/contexts';
import { Validator } from 'shared/utils/validator';
import { TimeRangeInput } from './TimeRangeInput';

interface Props {
  indexId: number;
  readonly?: boolean;
  validate?: FieldConfig['validate'];
}

export const ValueRulePart: FC<Props> = ({ indexId, validate, readonly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);
  const { values, setFieldValue, dirty } = useFormikContext();
  const name = `rules.${indexId}.comparedValue`;
  const operator = path(`rules.${indexId}.operator`.split('.'), values);
  const props = {
    id: name,
    text: formatMessage({ id: 'value' }),
    validate,
    disabled: readonly
  } as FieldAttributes<any>;
  const [prevOperator, setPrevOperator] = useState(operator);

  const selectProps = {
    ...props,
    disabled: readonly,
    name,
    label: formatMessage({ id: 'value' }),
    validate,
    options: component?.options,
    multiple: Array.isArray(path(name.split('.'), values))
  };

  useEffect(() => {
    if (!dirty && !prevOperator) {
      setPrevOperator(operator);
    }
  }, [dirty, operator, prevOperator]);

  useEffect(() => {
    if (operator && dirty && prevOperator !== operator) {
      const isSingularOperator = operator === Operator.IS || operator === Operator.IS_NOT;
      setFieldValue(name, isSingularOperator ? '' : []);
      setPrevOperator(operator);
    }
  }, [name, operator, setFieldValue, prevOperator, dirty]);

  switch (component?.component) {
    case QuestionComponent.DATE:
      return <DateComponent {...props} />;
    case QuestionComponent.TIME:
      return <TimeComponent {...props} />;
    case QuestionComponent.TIME_PICKER:
      return (
        <Field
          disabled={readonly}
          name={name}
          component={TimeRangeInput}
          validate={
            validate
              ? Validator.pipe(Validator.methods.required(), Validator.methods.timeRange())
              : Validator.pipe(Validator.methods.timeRange())
          }
        />
      );
    case QuestionComponent.SINGLE_SELECT:
    case QuestionComponent.OPTIONS_BIG:
    case QuestionComponent.OPTIONS_SMALL:
      return <SelectComponent {...selectProps} />;
    case QuestionComponent.DAY:
    case QuestionComponent.MULTIPLE_SELECT:
      return <SelectComponent {...selectProps} />;
    case QuestionComponent.FILE_UPLOAD: {
      const options: FormComponentProps['options'] = [
        { optionId: '1', label: formatMessage({ id: 'rule_compared_value.uploaded' }), value: 'true' },
        { optionId: '2', label: formatMessage({ id: 'rule_compared_value.not_uploaded' }), value: 'false' }
      ];
      return <SelectComponent {...selectProps} options={options} />;
    }
    case QuestionComponent.CHECKBOX: {
      const options: FormComponentProps['options'] = [
        { optionId: '1', label: formatMessage({ id: 'rule_compared_value.checked' }), value: 'true' },
        { optionId: '2', label: formatMessage({ id: 'rule_compared_value.unchecked' }), value: 'false' }
      ];
      return <SelectComponent {...selectProps} options={options} />;
    }
    case QuestionComponent.ASSIGNMENT_SCHEDULE:
    case QuestionComponent.DOCUMENT:
    case QuestionComponent.CONTRACT: {
      const options: FormComponentProps['options'] = [
        { optionId: '1', label: formatMessage({ id: 'rule_compared_value.signed' }), value: 'true' },
        { optionId: '2', label: formatMessage({ id: 'rule_compared_value.unsigned' }), value: 'false' }
      ];
      return <SelectComponent {...selectProps} options={options} />;
    }
    default: {
      return (
        <Field
          validate={validate}
          component={Input}
          name={name}
          label={formatMessage({ id: 'value' })}
          disabled={readonly}
        />
      );
    }
  }
};
