import { Box, Button } from '@mui/material';
import { Field, Form, Formik, type FormikHelpers } from 'formik';
import { type FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Input } from 'shared/components';
import { InputTooltip } from 'shared/components/form';
import { LockIcon } from 'shared/components/icons';
import { ErrorSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { FIVE_SECONDS_LOGOUT } from 'shared/constants';
import { AuthContext, ProfileInfoContext, type UserProfileInfo } from 'shared/contexts';
import { usePalette, useRoles } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { type UseChangeProfileInfo } from '../hooks/useChangeProfileInfo/interfaces';

type Props = Pick<UseChangeProfileInfo, 'updateProfile' | 'getFieldError'>;

export const ProfileForm: FC<Props> = ({ updateProfile, getFieldError }) => {
  const { user, closeModal } = useContext(ProfileInfoContext);
  const { secondary } = usePalette();
  const { forgotPassword, logout } = useContext(AuthContext);
  const { isAdmin, isSuperAdmin } = useRoles();

  const { formatMessage } = useIntl();

  const onSubmit = async (data: UserProfileInfo, helpers: FormikHelpers<UserProfileInfo>): Promise<void> => {
    const { setFieldError } = helpers;

    const notificationContent = await updateProfile.execute(data);

    if (notificationContent) {
      if (updateProfile.status === 'error') {
        toast(<ErrorSnackbar {...notificationContent} />);
      } else {
        toast(<SuccessSnackbar {...notificationContent} />);
        closeModal();
      }
    }

    const fieldErrors = getFieldError();

    if (fieldErrors) {
      setFieldError(fieldErrors.field, fieldErrors.message);
    }
  };

  const resetPassword = async (): Promise<void> => {
    await forgotPassword.execute({ email: user.email });

    toast(
      <SuccessSnackbar
        title={formatMessage({ id: 'recovery_link_was_sent' })}
        message={formatMessage({ id: 'logout_follow_email_instruction' })}
      />
    );

    closeModal();

    setTimeout(async () => {
      await logout.execute();
    }, FIVE_SECONDS_LOGOUT);
  };

  const commonFieldProps = {
    component: Input,
    sx: { height: '75px' }
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={{
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role
      }}
      onSubmit={onSubmit}
    >
      <Form noValidate>
        <Field
          {...commonFieldProps}
          name='firstName'
          label={formatMessage({ id: 'name' })}
          validate={Validator.pipe(Validator.methods.required())}
        />
        <Field {...commonFieldProps} name='lastName' label={formatMessage({ id: 'surname' })} />
        <Field
          {...commonFieldProps}
          name='email'
          type='email'
          disabled={!(isAdmin || isSuperAdmin)}
          label={formatMessage({ id: 'email' })}
          InputProps={{
            endAdornment: isSuperAdmin ? undefined : (
              <InputTooltip
                title={formatMessage({
                  id: 'receive_confirmation_letter_on_new_email'
                })}
              />
            )
          }}
          validate={Validator.pipe(Validator.methods.required(), Validator.methods.email())}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '1em' }}>
          <Button onClick={resetPassword} type='button' sx={{ float: 'right' }}>
            {formatMessage({ id: 'reset_password' })}
            <Box height='24px' marginLeft='10px'>
              <LockIcon color={secondary[700]} />
            </Box>
          </Button>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type='button' onClick={closeModal}>
            {formatMessage({ id: 'cancel' })}
          </Button>
          <Button variant='contained' type='submit'>
            {formatMessage({ id: 'save' })}
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};
