import { useQuery } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import React, { type FC } from 'react';
import { useIntl } from 'react-intl';
import { getDownloadUrlQuery } from 'shared/api';
import { type GetDownloadUrl, type GetDownloadUrlVariables } from 'shared/api/__graphql__/GetDownloadUrl';
import { Loader } from 'shared/components';
import { DashboardIcon } from 'shared/components/icons';
import { usePalette } from 'shared/hooks';
import { type Documents_documents_data } from './__graphql__/Documents';

interface Props {
  document?: Documents_documents_data;
  selectDocument?: React.Dispatch<React.SetStateAction<Documents_documents_data | undefined>>;
  width?: string;
  height: string;
  isSelected?: boolean;
  withDownload?: boolean;
}

export const PreviewImage: FC<Props> = ({
  width = '100%',
  height,
  document,
  selectDocument,
  isSelected,
  withDownload
}) => {
  const { primary, secondary, info } = usePalette();
  const intl = useIntl();
  const downloadUrlResult = useQuery<GetDownloadUrl, GetDownloadUrlVariables>(getDownloadUrlQuery, {
    variables: { id: document?.id ?? '' },
    context: {
      uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
    },
    skip: !document
  });

  const commonStyles = {
    width,
    height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: info[200],
    border: `2px solid ${isSelected ? primary[600] : secondary[400]}`,
    borderRadius: selectDocument ? '8px' : '16px'
  };

  if (!document) {
    return <Box sx={{ position: 'relative', ...commonStyles }}>IMG</Box>;
  }

  if (downloadUrlResult.loading) {
    return (
      <Box sx={{ position: 'relative', ...commonStyles }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box
      onClick={
        selectDocument
          ? () => {
              selectDocument(document);
            }
          : () => {}
      }
      sx={{
        position: 'relative',
        cursor: selectDocument ? 'pointer' : 'inherit',
        ...commonStyles
      }}
    >
      {document.contentType?.includes('image') ? (
        <img
          width='auto'
          height='auto'
          style={{ maxWidth: width, maxHeight: height }}
          src={downloadUrlResult.data?.getDowloadUrl}
          alt={document.filename}
        />
      ) : (
        <Box textAlign='center'>
          <DashboardIcon />
          <Typography variant={withDownload ? 'subtitle2' : 'body1'} color='secondary.700' marginTop='4px'>
            {document.filename}
          </Typography>
          {withDownload && (
            <Button
              variant='outlined'
              sx={{
                marginTop: '26px'
              }}
              onClick={() => {
                window.open(downloadUrlResult.data?.getDowloadUrl, '_blank');
              }}
            >
              {intl.formatMessage({ id: 'open_file_in_browser' })}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
