import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { type FormQuestions_formQuestions } from 'admin/pages/Forms/__graphql__/FormQuestions';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { EmptyTableBox, StyledTablePagination } from 'shared/components';
import { CopyIcon, SortIcon } from 'shared/components/icons';
import { type TablePagination, type UseTableSort, usePalette } from 'shared/hooks';
import { type WithoutGQLType } from 'shared/types/utils';
import { QuestionVariablesOrderField } from '../hooks';

interface Props {
  items: Array<WithoutGQLType<FormQuestions_formQuestions>>;
  total: number;
  pagination: TablePagination;
  sorting: UseTableSort<QuestionVariablesOrderField>;
}

export const VariablesTable: FC<Props> = ({ items, total, pagination, sorting }) => {
  const { formatMessage } = useIntl();
  const { primary } = usePalette();
  const { page, rowsPerPage } = pagination;
  const { createSortHandler, field, direction } = sorting;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total) : 0;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(QuestionVariablesOrderField.Variable)} hideSortIcon={true}>
                  {formatMessage({ id: 'variable' })}
                  <SortIcon active={field === QuestionVariablesOrderField.Variable} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={createSortHandler(QuestionVariablesOrderField.Description)}
                  hideSortIcon={true}
                >
                  {formatMessage({ id: 'description' })}
                  <SortIcon active={field === QuestionVariablesOrderField.Description} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={createSortHandler(QuestionVariablesOrderField.SectionName)}
                  hideSortIcon={true}
                >
                  {formatMessage({ id: 'section_name' })}
                  <SortIcon active={field === QuestionVariablesOrderField.SectionName} direction={direction} />
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Button onClick={() => navigator.clipboard.writeText(item.variable)}>
                    <CopyIcon color={primary[700]} />
                  </Button>
                  {item.variable}
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.section.name}</TableCell>
              </TableRow>
            ))}
            {!items.length && page === 0 && (
              <TableRow
                style={{
                  height: 64 * rowsPerPage,
                  background: 'inherit'
                }}
              >
                <TableCell
                  colSpan={7}
                  style={{
                    height: 64 * rowsPerPage,
                    padding: 0
                  }}
                >
                  <EmptyTableBox />
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 64 * emptyRows
                }}
              >
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination count={total} {...pagination} />
    </>
  );
};
