import { useQuery } from '@apollo/client';
import { Box, IconButton, Link } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import routes from 'shared/constants/routes';
import { CompanyContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { FacebookIcon, LinkedinIcon, MailIcon, TwitterIcon } from '../icons';
import { Loader } from '../Loader';
import { type CompanyLinks } from './__graphql__/CompanyLinks';
import { footerLinksQuery } from './api';

export const StaticFooter = (): JSX.Element => {
  const { info } = usePalette();
  const navigate = useNavigate();
  const { logo } = useContext(CompanyContext);
  const { data, loading } = useQuery<CompanyLinks>(footerLinksQuery);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '30px',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingX: '48px',
        paddingTop: '23px',
        paddingBottom: '24px',
        zIndex: 1,
        '@media only screen and (max-width: 767px)': {
          gap: '16px'
        },
        '@media only screen and (max-width: 540px)': {
          paddingX: '16px',
          alignItems: 'start'
        }
      }}
      bgcolor={info[400]}
    >
      <Link
        onClick={() => {
          navigate(routes.rootForNavigation);
        }}
        sx={{
          '& > img': {
            '@media only screen and (max-width: 767px)': {
              width: '92px',
              height: '20px'
            }
          }
        }}
      >
        <img src={logo?.url ? logo.url : '/swift-onboard-logo.svg'} alt='Logo' height='29px' />
      </Link>
      {loading || !data ? (
        <Loader />
      ) : (
        <>
          <Box
            display='flex'
            gap={9}
            sx={{
              '@media only screen and (max-width: 767px)': {
                gap: '8px'
              },
              '@media only screen and (max-width: 540px)': {
                flexDirection: 'column'
              }
            }}
          >
            {data.companyLinks.footerLinks.map(({ name, link }) => (
              <Link key={name} className='footer' href={link} target='_blank'>
                {name}
              </Link>
            ))}
          </Box>
          <Box
            display='flex'
            gap={6}
            sx={{
              '@media only screen and (max-width: 540px)': {
                gap: '12px'
              }
            }}
          >
            {!!data.companyLinks.emailLink && (
              <IconButton href={`mailto: ${data.companyLinks.emailLink}`} target='_blank'>
                <MailIcon />
              </IconButton>
            )}
            {!!data.companyLinks.linkedInLink && (
              <IconButton href={data.companyLinks.linkedInLink} target='_blank'>
                <LinkedinIcon />
              </IconButton>
            )}
            {!!data.companyLinks.facebookLink && (
              <IconButton href={data.companyLinks.facebookLink} target='_blank'>
                <FacebookIcon />
              </IconButton>
            )}
            {!!data.companyLinks.twitterLink && (
              <IconButton href={data.companyLinks.twitterLink} target='_blank'>
                <TwitterIcon />
              </IconButton>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
