import { Box, Button, Typography } from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Input, Select } from 'shared/components';
import { EraserIcon, ThreeDotsIcon, TrashIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { OPERATOR_OPTIONS_BY_COMPONENT } from './contstants';
import { RuleAction } from './RuleAction';
import { type RuleBuilderValues } from './types';
import { usePureRule } from './usePureRule';
import { isAnyValueInRule } from './utils';
import { ValueRulePart } from './ValueRulePart';

interface Props {
  indexId: number;
  onDelete: () => void;
  onErase: () => void;
  issuerId: string;
  readonly?: boolean;
}

export const Rule: FC<Props> = (props) => {
  const { onDelete, onErase, indexId, issuerId, readonly } = props;
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const pureRule = usePureRule({ issuerId });
  const { component } = useContext(FormBuilderContext);
  const {
    values: { rules }
  } = useFormikContext<RuleBuilderValues>();

  const currentRuleValues = rules[indexId];
  const validate =
    rules.length > 1 || isAnyValueInRule(currentRuleValues) ? Validator.pipe(Validator.methods.required()) : undefined;

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${secondary[300]}`,
          borderRadius: '8px',
          backgroundColor: 'secondary.50',
          p: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
          <Box sx={{ display: 'flex', gap: '16px', maxWidth: readonly ? '100%' : '80%', width: '100%' }}>
            <Box
              sx={{
                p: '0px 14px',
                borderRadius: '8px',
                border: `1px solid ${secondary[300]}`,
                backgroundColor: 'secondary.100',
                textTransform: 'uppercase',
                display: 'flex',
                maxHeight: '44px',
                alignItems: 'center'
              }}
            >
              <Typography sx={{ color: 'secondary.800' }} variant='subtitle1'>
                {formatMessage({ id: 'if' })}
              </Typography>
            </Box>

            <Field
              component={Input}
              name={`rules.${indexId}.issuerVariable`}
              label={currentRuleValues.issuerVariable ? formatMessage({ id: 'component' }) : ''}
              placeholder={formatMessage({ id: 'component' })}
              validate={validate}
              InputProps={{
                disabled: true
              }}
            />

            <Field
              component={Select}
              name={`rules.${indexId}.operator`}
              label={formatMessage({ id: 'operator' })}
              SelectProps={{
                IconComponent: ThreeDotsIcon,
                sx: { transform: 'none' }
              }}
              InputProps={{ disabled: OPERATOR_OPTIONS_BY_COMPONENT[component?.component!].length === 1 || readonly }}
              options={OPERATOR_OPTIONS_BY_COMPONENT[component?.component!]?.map((item) => ({
                label: formatMessage({ id: `operators.${item.toLowerCase()}` }),
                value: item
              }))}
              validate={validate}
            />

            <ValueRulePart readonly={readonly} validate={validate} indexId={indexId} />
          </Box>

          {!readonly && (
            <Box>
              <Button onClick={onErase}>
                <EraserIcon />
              </Button>
              <Button onClick={onDelete}>
                <TrashIcon color={secondary[700]} />
              </Button>
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Box
            sx={{
              alignSelf: 'flex-start',
              p: '8px 14px',
              borderRadius: '8px',
              border: `1px solid ${secondary[300]}`,
              backgroundColor: 'secondary.100',
              textTransform: 'uppercase',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography sx={{ color: 'secondary.800' }} variant='subtitle1'>
              {formatMessage({ id: 'then' })}
            </Typography>
          </Box>

          <FieldArray
            name={`rules.${indexId}.actions`}
            render={({ insert, remove }) => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                {currentRuleValues.actions.map((i, index) => (
                  <RuleAction
                    readonly={readonly}
                    validate={validate}
                    key={index}
                    parentIndexId={indexId}
                    indexId={index}
                    deleteAction={() => {
                      remove(index);
                    }}
                    addAction={() => {
                      insert(index + 1, pureRule.actions[0]);
                    }}
                  />
                ))}
              </Box>
            )}
          />
        </Box>
      </Box>
    </>
  );
};
