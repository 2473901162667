import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const BookWithQuestionMarkIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.5 2H20V22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 7.28197C10.2 6.88197 10.5 6.48197 10.9 6.28197C11.3141 6.04255 11.7976 5.95168 12.2704 6.02442C12.7432 6.09716 13.177 6.32914 13.5 6.68197C13.8 7.08197 14 7.48197 14 7.98197C14 9.28197 12 9.98197 12 9.98197M12 13.982H12.01'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
