import { List, ListItem, ListItemIcon, ListItemText, type SxProps, type Theme } from '@mui/material';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { TriangleListMarker } from './icons';

interface Props {
  itemsIntlIds: string[];
  sx?: SxProps<Theme>;
}

export const TriangleMarkeredList: FC<Props> = ({ itemsIntlIds, sx = {} }) => {
  const { formatMessage } = useIntl();

  return (
    <List
      sx={{
        border: 'none',
        p: '16px 16px 38px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px 5px',
        ...sx
      }}
    >
      {itemsIntlIds.map((id) => (
        <ListItem disablePadding key={id}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: '5px',
              justifyContent: 'center'
            }}
          >
            <TriangleListMarker />
          </ListItemIcon>
          <ListItemText primary={formatMessage({ id })} />
        </ListItem>
      ))}
    </List>
  );
};
