import { Box, type SxProps, type Theme } from '@mui/material';
import { type FC } from 'react';

interface Props {
  children: JSX.Element;
  sx?: SxProps<Theme>;
}

export const PageWrapper: FC<Props> = ({ sx = {}, children }) => (
  <Box sx={{ p: '0 40px 0 24px', ...sx }}>{children}</Box>
);
