import { FormControlLabel, Switch as MuiSwitch, type SwitchProps } from '@mui/material';
import { type FieldProps } from 'formik';
import { useCallback, type FC } from 'react';
import { usePalette } from 'shared/hooks';

type OnChange = NonNullable<SwitchProps['onChange']>;
type Props = FieldProps &
  Omit<SwitchProps, 'name' | 'value'> & {
    handleChange?: (changeValue: (v: boolean) => void, valueToSet: boolean) => void;
    label: string;
  };

export const Switch: FC<Props> = ({ field, form: { setFieldValue }, label, ...props }) => {
  const { secondary } = usePalette();

  const { onChange: propsOnChange, handleChange } = props;
  const { onChange: fieldOnChange, value } = field;

  const onChange = useCallback<OnChange>(
    (e, checked) => {
      if (handleChange) {
        handleChange((v) => setFieldValue(field.name, v), checked);
      } else {
        if (fieldOnChange) fieldOnChange(e);
        if (propsOnChange) propsOnChange(e, checked);
      }
    },
    [handleChange, fieldOnChange, propsOnChange, field.name, setFieldValue]
  );

  return (
    <FormControlLabel
      sx={{ color: secondary[700], justifyContent: 'flex-end' }}
      control={<MuiSwitch {...field} checked={value} onChange={onChange} />}
      label={label}
      labelPlacement='start'
    />
  );
};
