import { useMutation, useQuery } from '@apollo/client';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { OrderDirection, TaskCategory, TaskOrderField, type TaskOrder } from '__graphql__/globalTypes';
import { useContext, useEffect, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { API_CONTEXT } from 'shared/api/api-contexts';
import {
  Loader,
  PageTitle,
  PageWrapper,
  SearchFilter,
  SelectFilter,
  StyledTablePagination
} from 'shared/components';
import { AuthContext } from 'shared/contexts';
import { useFiltersQuery, usePalette, useTablePagination, useTableSort } from 'shared/hooks';
import { OwnerOption } from 'shared/types';
import { camelCaseToSnake } from 'shared/utils/strings';
import { type Tasks as TasksQuery, type TasksVariables } from './__graphql__/Tasks';
import { type UpdateTask, type UpdateTaskVariables } from './__graphql__/UpdateTask';
import { tasksQuery, updateTaskMutation } from './api';
import { TasksTable } from './TasksTable';
import { TaskWidget } from './TaskWidget';

const CATEGORY_FILTER_OPTIONS = [
  TaskCategory.Upcoming,
  TaskCategory.InProgress,
  TaskCategory.DueToday,
  TaskCategory.Overdue,
  TaskCategory.Done
];

export const Tasks: FC = () => {
  const { formatMessage } = useIntl();
  const { primary, secondary } = usePalette();
  const { authState } = useContext(AuthContext);
  const { state } = useLocation();

  const { parsedQuery, pushQuery } = useFiltersQuery();
  const { offset, limit, ...tablePaginationProps } = useTablePagination({
    defaultPage: parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0
  });
  const sort = useTableSort<TaskOrderField>({
    defaultField: (parsedQuery?.sortBy ?? TaskOrderField.Done) as TaskOrderField,
    defaultDirection: (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
  });

  const [changedTasks, onChangeTask] = useState<Record<string, boolean>>({});
  const [owner, setOwner] = useState<OwnerOption>((parsedQuery?.owner as OwnerOption) ?? OwnerOption.my);
  const [categoryFilter, setCategoryFilter] = useState<TaskCategory[]>(
    (parsedQuery?.categories ?? []) as TaskCategory[]
  );
  const [search, setSearch] = useState<string>('');

  const categoryOptions = CATEGORY_FILTER_OPTIONS.map((category) => ({
    value: category,
    label: formatMessage({ id: camelCaseToSnake(category) })
  }));

  const result = useQuery<TasksQuery, TasksVariables>(tasksQuery, {
    variables: {
      orderBy: {
        field: sort.field,
        direction: sort.direction
      } as TaskOrder,
      pagination: { offset, limit },
      filter: {
        search: search?.length > 2 ? search.trim() : null,
        categories: categoryFilter?.length ? categoryFilter : undefined,
        staffUserId: owner === OwnerOption.my ? authState.user?.id : undefined
      },
      ...(state?.search ? { query: state.search.toLowerCase() } : {})
    },
    context: API_CONTEXT.AUTH
  });

  const [updateTask, { loading: updateLoading }] = useMutation<UpdateTask, UpdateTaskVariables>(updateTaskMutation, {
    context: API_CONTEXT.AUTH
  });

  useEffect(() => {
    pushQuery(
      {
        filters: [
          ...(categoryFilter?.length
            ? [
              {
                id: 'categories',
                value: categoryFilter
              }
            ]
            : []),
          ...(search
            ? [
              {
                id: 'search',
                value: search
              }
            ]
            : []),
          { id: 'owner', value: owner }
        ],
        sort: sort?.field
          ? [
            {
              id: sort?.field,
              desc: sort?.direction === OrderDirection.desc
            }
          ]
          : [],
        pageIndex: tablePaginationProps.page
      },
      state
    );
  }, [sort?.direction, sort?.field, pushQuery, owner, search, tablePaginationProps.page, state, categoryFilter]);

  useEffect(() => {
    setSearch((parsedQuery?.search as string) ?? '');
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle>
          <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  '& > *:not(:last-child)': {
                    marginRight: '16px'
                  }
                }}
              >
                <ToggleButtonGroup
                  value={owner}
                  exclusive
                  onChange={(e, v) => {
                    if (v !== null) {
                      setOwner(v);
                    }
                  }}
                  orientation='horizontal'
                  sx={{
                    flexWrap: 'wrap',
                    color: secondary[800],
                    gap: '8px',
                    '& > button.Mui-selected': {
                      backgroundColor: `${primary[400]} !important`,
                      borderColor: `${primary[600]} !important`
                    }
                  }}
                >
                  {Object.values(OwnerOption)
                    .reverse()
                    .map((option) => (
                      <ToggleButton key={option} value={option}>
                        {formatMessage({ id: `${option}_tasks` })}
                      </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <SelectFilter
                  values={categoryFilter}
                  placeholder={formatMessage({ id: 'category' })}
                  allItemLabel={formatMessage({ id: 'all_task_categories' })}
                  options={categoryOptions}
                  onApply={(values) => {
                    setCategoryFilter(values);
                  }}
                />
                <SearchFilter value={search} onChange={setSearch} />
              </Box>
              <TaskWidget />
            </Box>
          </>
        </PageTitle>
        {result.loading || updateLoading ? (
          <Loader />
        ) : (
          <PageWrapper>
            <>
              <TasksTable
                data={result.data?.tasks.data}
                page={tablePaginationProps.page}
                rowsPerPage={tablePaginationProps.rowsPerPage}
                sort={sort}
                search={search}
                updateTaskDone={async (data) => {
                  await updateTask({ variables: { data } });
                }}
                changedTasks={changedTasks}
                onChangeTask={(id, done) =>
                  onChangeTask((prev) => ({
                    ...prev,
                    [id]: done
                  }))
                }
              />
              <StyledTablePagination count={result.data?.tasks.total ?? 0} {...tablePaginationProps} />
            </>
          </PageWrapper>
        )}
      </Box>
    </Box>
  );
};
