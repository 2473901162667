import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { RegistrationOrderField, type OrderDirection } from '__graphql__/globalTypes';
import { type Registrations_registrations_data } from 'admin/pages/Dashboard/__graphql__/Registrations';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { type FC } from 'react';
import { useIntl } from 'react-intl';
import { EmptyTableBox } from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { StyledTablePagination } from 'shared/components/StyledTablePagination';
import { getStatusColor } from 'shared/utils/colors';

dayjs.extend(relativeTime);

interface Props {
  field?: RegistrationOrderField;
  direction?: OrderDirection;
  createSortHandler: (newField: RegistrationOrderField) => () => void;
  data?: Registrations_registrations_data[] | null;
  total?: number;
  tablePaginationProps: {
    page: number;
    rowsPerPage: number;
    onPageChange: (_event: unknown, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };
}

export const ReferralsTable: FC<Props> = ({
  field,
  direction,
  createSortHandler,
  data,
  total,
  tablePaginationProps
}) => {
  const intl = useIntl();

  const emptyRows =
    tablePaginationProps.page > 0
      ? Math.max(0, (1 + tablePaginationProps.page) * tablePaginationProps.rowsPerPage - (total ?? 0))
      : 0;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(RegistrationOrderField.firstName)} hideSortIcon={true}>
                  {intl.formatMessage({ id: 'name' })}
                  <SortIcon active={field === RegistrationOrderField.firstName} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(RegistrationOrderField.email)} hideSortIcon={true}>
                  {intl.formatMessage({ id: 'surname' })}
                  <SortIcon active={field === RegistrationOrderField.email} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(RegistrationOrderField.phoneNumber)} hideSortIcon={true}>
                  {intl.formatMessage({ id: 'phone_number' })}
                  <SortIcon active={field === RegistrationOrderField.phoneNumber} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(RegistrationOrderField.createdAt)} hideSortIcon={true}>
                  {intl.formatMessage({ id: 'creation_date' })}
                  <SortIcon active={field === RegistrationOrderField.createdAt} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(RegistrationOrderField.status)} hideSortIcon={true}>
                  {intl.formatMessage({ id: 'status' })}
                  <SortIcon active={field === RegistrationOrderField.status} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>{intl.formatMessage({ id: 'type' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((reg) => (
              <TableRow key={reg.id}>
                <TableCell>{reg.firstName}</TableCell>
                <TableCell>{reg.lastName}</TableCell>
                <TableCell>{reg.phoneNumber}</TableCell>
                <TableCell>{dayjs(reg.createdAt).format('HH:mm DD/MM/YYYY')}</TableCell>
                <TableCell className='badge'>
                  <Box
                    sx={{
                      backgroundColor: getStatusColor(reg.status)
                    }}
                  >
                    {intl.formatMessage({ id: `registration.status.${reg.status}` })}
                  </Box>
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: `registration.type.${reg.dummyRecord ? 'dummyRecord' : reg.locked ? 'locked' : 'current'}`
                  })}
                </TableCell>
              </TableRow>
            ))}
            {!data?.length && tablePaginationProps.page === 0 && (
              <TableRow
                style={{
                  height: 64 * tablePaginationProps.rowsPerPage,
                  background: 'inherit'
                }}
              >
                <TableCell
                  colSpan={7}
                  style={{
                    height: 64 * tablePaginationProps.rowsPerPage,
                    padding: 0
                  }}
                >
                  <EmptyTableBox />
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 64 * emptyRows
                }}
              >
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination count={total ?? 0} {...tablePaginationProps} />
    </>
  );
};
