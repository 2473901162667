export const API_CONTEXT = {
  AUTH: {
    uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`
  },
  DOCUMENT: {
    uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
  },
  NOTIFICATION: {
    uri: `${process.env.REACT_APP_API_NOTIFICATION_BASE}graphql`
  },
  SUPERADMIN: {
    uri: `${process.env.REACT_APP_API_SUPERADMIN_BASE}graphql`
  },
  PAYMENT: {
    uri: `${process.env.REACT_APP_API_PAYMENT_BASE}graphql`
  }
};
