import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const TwitterIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21 2.00002C21 2.00002 20.3 4.10002 19 5.40002C20.6 15.4 9.6 22.7 1 17C3.2 17.1 5.4 16.4 7 15C2 13.5 -0.5 7.60002 2 3.00002C4.2 5.60002 7.6 7.10002 11 7.00002C10.1 2.80002 15 0.400023 18 3.20002C19.1 3.20002 21 2.00002 21 2.00002Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
