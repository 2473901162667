import { useApolloClient } from '@apollo/client';
import { type ErrorResponse } from '@apollo/client/link/error';
import { useCallback, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { API_CONTEXT } from 'shared/api/api-contexts';
import routes from 'shared/constants/routes';
import {
  AUTH_STATUS,
  type AuthError,
  type AuthState,
  type ChangeEmailParams,
  type ForgotPasswordParams,
  type InviteConfirmParams,
  type LoginParams,
  type ResetPasswordParams
} from 'shared/types/auth';
import { localStorageUtils } from 'shared/utils/local-storage';
import { useStableNavigate } from '../useStableNavigate';
import { type ChangeEmail, type ChangeEmailVariables } from './__graphql__/ChangeEmail';
import { type ForgotPassword, type ForgotPasswordVariables } from './__graphql__/ForgotPassword';
import { type InviteConfirm, type InviteConfirmVariables } from './__graphql__/InviteConfirm';
import { type ResetPassword, type ResetPasswordVariables } from './__graphql__/ResetPassword';
import { type SignIn, type SignInVariables } from './__graphql__/SignIn';
import { type SignOut } from './__graphql__/SignOut';
import {
  changeEmailMutation,
  forgotPasswordMutation,
  inviteConfirmMutation,
  resetPasswordMutation,
  signInMutation,
  signOutMutation
} from './api';

export const useAuthentication = (): any => {
  const [authState, setAuthState] = useState<AuthState>({ status: AUTH_STATUS.anonymous });
  const [rehydrated, setRehydrated] = useState<boolean>(false);
  const [error, setError] = useState<AuthError>();
  const apolloClient = useApolloClient();
  const navigate = useStableNavigate();

  const setAuth = useCallback((state: AuthState) => {
    setAuthState(state);
    localStorage.setItem('authState', JSON.stringify(state));
  }, []);

  const clearAuthState = useCallback(() => {
    setAuthState({
      status: AUTH_STATUS.anonymous,
      user: undefined,
      accessToken: undefined,
      refreshToken: undefined
    });
    setError(undefined);
  }, []);

  useEffect(() => {
    const existingState = localStorage.getItem('authState');
    if (existingState) {
      const parsedState = JSON.parse(existingState);
      if (parsedState?.status && parsedState?.user) setAuthState(parsedState);
    }
    setRehydrated(true);
  }, []);

  const login = useAsyncCallback(async ({ email, password, redirectTo = routes.root }: LoginParams) => {
    try {
      const response = await apolloClient.mutate<SignIn, SignInVariables>({
        mutation: signInMutation,
        variables: { data: { email, password } },
        context: API_CONTEXT.AUTH
      });
      const signedInUser = response.data?.signIn.user;
      setAuth({
        status: AUTH_STATUS.authenticated,
        user: signedInUser,
        accessToken: response.data?.signIn.accessToken,
        refreshToken: response.data?.signIn.refreshToken
      });

      navigate(redirectTo);
    } catch (originError) {
      setAuthState({
        status: AUTH_STATUS.anonymous,
        user: undefined,
        accessToken: undefined,
        refreshToken: undefined
      });
      const error = (originError as ErrorResponse).graphQLErrors?.[0];

      setError({
        code: error?.extensions.code as string,
        error: (error?.extensions.response as Record<string, string>)?.message
      });

      throw error?.extensions.code;
    }
  });

  const inviteConfirm = useAsyncCallback(async (params: InviteConfirmParams) => {
    try {
      const response = await apolloClient.mutate<InviteConfirm, InviteConfirmVariables>({
        mutation: inviteConfirmMutation,
        variables: {
          data: {
            password: params.password
          },
          token: params.token
        },
        context: API_CONTEXT.AUTH
      });
      setAuth({
        status: AUTH_STATUS.authenticated,
        user: response.data?.inviteConfirm.user,
        accessToken: response.data?.inviteConfirm.accessToken,
        refreshToken: response.data?.inviteConfirm.refreshToken
      });
      return true;
    } catch (error) {
      throw (error as ErrorResponse).graphQLErrors?.[0].extensions.code;
    }
  });

  const logout = useAsyncCallback(async (options?: { openRoute?: string }) => {
    try {
      await apolloClient.mutate<SignOut>({
        mutation: signOutMutation,
        context: {
          uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`
        }
      });
    } catch {}

    clearAuthState();
    localStorage.removeItem('authState');
    localStorageUtils.deleteProperty('warnings.emailsConfig.remindLater');
    localStorageUtils.deleteProperty('warnings.noFunds.remindLater');
    localStorageUtils.deleteProperty('warnings.cardExpiration.remindLater');
    await apolloClient.clearStore();

    const openRoute = options?.openRoute ?? routes.login;
    navigate(openRoute);
  });

  const forgotPassword = useAsyncCallback(async (params: ForgotPasswordParams) => {
    try {
      await apolloClient.mutate<ForgotPassword, ForgotPasswordVariables>({
        mutation: forgotPasswordMutation,
        variables: {
          email: params.email
        },
        context: {
          uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`
        }
      });
      return true;
    } catch (originError) {
      setAuthState({
        status: AUTH_STATUS.anonymous,
        user: undefined,
        accessToken: undefined,
        refreshToken: undefined
      });

      const error = (originError as ErrorResponse).graphQLErrors?.[0];
      setError({ code: error?.extensions.code as string });

      return false;
    }
  });

  const resetPassword = useAsyncCallback(async (params: ResetPasswordParams) => {
    try {
      await apolloClient.mutate<ResetPassword, ResetPasswordVariables>({
        mutation: resetPasswordMutation,
        variables: {
          data: {
            password: params.password
          },
          token: params.token
        },
        context: {
          uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`
        }
      });
      return true;
    } catch (error) {
      throw (error as ErrorResponse).graphQLErrors?.[0].extensions.code;
    }
  });

  const changeEmail = useAsyncCallback(async (params: ChangeEmailParams) => {
    try {
      const response = await apolloClient.mutate<ChangeEmail, ChangeEmailVariables>({
        mutation: changeEmailMutation,
        variables: {
          token: params.token
        },
        context: API_CONTEXT.AUTH
      });
      return response.data?.changeEmail;
    } catch (error) {
      throw (error as ErrorResponse).graphQLErrors?.[0].extensions.code;
    }
  });

  const fetchCurrentUser = useCallback(async () => {
    // const response = await apolloClient.query<CurrentUser>({
    //   query: queryCurrentUser,
    // });
    // await updateUser(response.data.me);
  }, []);

  return {
    authState,
    rehydrated,
    login,
    inviteConfirm,
    logout,
    forgotPassword,
    resetPassword,
    changeEmail,
    fetchCurrentUser,
    error,
    setError
  };
};

export type { AuthState, LoginParams };
