import { Operator, QuestionComponent } from '__graphql__/globalTypes';
import * as R from 'ramda';
import { type Component } from 'shared/contexts';
import { type WithoutGQLType } from 'shared/types/utils';
import { type FormQuestions_formQuestions } from '../__graphql__/FormQuestions';
import { type SectionsList_sections } from './__graphql__/SectionsList';
import { type RuleValues } from './types';

export function isAnyValueInRule(rule: RuleValues): boolean {
  const { actions, issuerId: _, issuerVariable: _1, ...item } = rule;
  const hasNonEmptyStringValue = R.pipe(R.values, R.any(R.complement(R.isEmpty)));
  const arrayContainNonEmptyString = R.pipe(R.map(hasNonEmptyStringValue), R.any(R.equals(true)));

  return hasNonEmptyStringValue(item) || arrayContainNonEmptyString(actions);
}

export function excludeSectionFromTargets(
  sections: SectionsList_sections[],
  issuerId: string
): SectionsList_sections[] {
  return sections.reduce<SectionsList_sections[]>((result, current) => {
    const { questions, children } = current;
    const issuerQuestionFromParent = questions.find(({ id }) => id === issuerId);
    const childSectionContainsIssuer = children.filter((child) => !!child.questions.find(({ id }) => id === issuerId));
    if (!issuerQuestionFromParent && !childSectionContainsIssuer.length) {
      result.push(current);
    }
    return result;
  }, []);
}

export function shouldExcludeComponentFromIssuers(
  question: WithoutGQLType<FormQuestions_formQuestions>,
  sectionId: string
): boolean {
  return !(question.section.id === sectionId || question.section.parentId === sectionId);
}

interface GetValueArgs {
  item: Component | null;
  value?: string;
  operator?: Operator;
}

export function getComparedValue(params: GetValueArgs): string[] | string | Date {
  const { item, value = '', operator } = params;

  if (!item) {
    return '';
  }

  const { component } = item;

  switch (component) {
    case QuestionComponent.MULTIPLE_SELECT:
    case QuestionComponent.DAY: {
      const singleValueOperators = [Operator.IS, Operator.IS_NOT];

      if (operator && singleValueOperators.includes(operator)) {
        return value;
      }
      return value ? JSON.parse(value) : [];
    }
    case QuestionComponent.TIME_PICKER: {
      if (value) {
        const [startDate, endDate] = JSON.parse(value);
        return `${convertDateToTime(new Date(startDate))} - ${convertDateToTime(new Date(endDate))}`;
      }
      return '';
    }
    default:
      return value;
  }
}

export function getOperatorValue(params: Omit<GetValueArgs, 'operator'>): string {
  const { item, value = '' } = params;

  if (!item) {
    return '';
  }

  const { component } = item;

  switch (component) {
    case QuestionComponent.ASSIGNMENT_SCHEDULE:
    case QuestionComponent.CONTRACT:
    case QuestionComponent.CHECKBOX:
    case QuestionComponent.DOCUMENT:
    case QuestionComponent.FILE_UPLOAD:
      return Operator.IS;

    default:
      return value;
  }
}

export const convertTimeToDate = (time: string): Date => {
  const [hours, minutes, period] = time.split(/[:\s]/);
  const date = new Date();

  if (period === 'PM' && hours !== '12') {
    date.setHours(parseInt(hours, 10) + 12);
  } else if (period === 'AM' && hours === '12') {
    date.setHours(0);
  } else {
    date.setHours(parseInt(hours, 10));
  }

  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const convertDateToTime = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const period = hours < 12 ? 'AM' : 'PM';

  return `${formattedHours}:${formattedMinutes} ${period}`;
};
