import { Box, type SxProps, type Theme } from '@mui/material';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';

interface Props {
  header: JSX.Element;
  body: JSX.Element;
  footer?: JSX.Element;
  sx?: SxProps<Theme>;
}

export const WidgetCard: FC<Props> = ({ header, footer, body, sx = {} }) => {
  const { secondary } = usePalette();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        borderRadius: '16px',
        border: `1px solid ${secondary[400]}`,
        maxHeight: 'calc(100vh - 220px)',
        overflowY: 'hidden',
        ...sx
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${secondary[400]}`,
          p: '13px',
          backgroundColor: '#fff',
          maxHeight: '65px',
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {header}
      </Box>
      <Box sx={{ backgroundColor: 'info.100', p: '16px', height: '100%', maxHeight: '100%', overflowY: 'auto' }}>
        {body}
      </Box>
      {!!footer && (
        <Box
          sx={{
            borderTop: `1px solid ${secondary[400]}`,
            p: '10px',
            textAlign: 'center',
            backgroundColor: '#fff'
          }}
        >
          {footer}
        </Box>
      )}
    </Box>
  );
};
