import { useLazyQuery, useMutation } from '@apollo/client';
import { DocumentRelation, DocumentType, Font, ThemeColor, type UpdateCompanyInfoData } from '__graphql__/globalTypes';
import { useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useLocation } from 'react-router';
import { createDocumentMutation, updateCompanyInfoMutation, uploadDocumentMutation } from 'shared/api';
import { type CreateDocument, type CreateDocumentVariables } from 'shared/api/__graphql__/CreateDocument';
import { type UploadDocument, type UploadDocumentVariables } from 'shared/api/__graphql__/UploadDocument';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { type CompanyDataContext } from 'shared/contexts';
import { type AttachedDocument } from 'shared/types';
import { type AppSetup } from './__graphql__/AppSetup';
import { type DeleteLogo, type DeleteLogoVariables } from './__graphql__/DeleteLogo';
import { appSetupQuery, deleteLogo } from './api';

export function useCompany(): CompanyDataContext {
  const location = useLocation();
  const [logo, setLogo] = useState<CompanyDataContext['logo']>({ url: '', name: '' });
  const [companyId, setCompanyId] = useState<Record<'prev' | 'current', null | string>>({ prev: null, current: null });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>();

  const [fetchSetupQuery, { data: companyData }] = useLazyQuery<AppSetup>(appSetupQuery);
  const fetchLogo = useAsyncCallback(async () => {
    const { data } = await fetchSetupQuery();

    setLogo(data?.companySetup.companyLogo!);
    return data?.companySetup.id ?? null;
  });

  const [createDocument] = useMutation<CreateDocument, CreateDocumentVariables>(createDocumentMutation, {
    context: API_CONTEXT.DOCUMENT
  });

  const [uploadDocument] = useMutation<UploadDocument, UploadDocumentVariables>(uploadDocumentMutation, {
    context: API_CONTEXT.DOCUMENT
  });

  const [deleteDocument] = useMutation<DeleteLogo, DeleteLogoVariables>(deleteLogo, {
    context: API_CONTEXT.DOCUMENT
  });

  const [changeTheme] = useMutation<UpdateCompanyInfoData>(updateCompanyInfoMutation, {
    refetchQueries: ['AppSetup'],
    awaitRefetchQueries: true
  });

  const resetLogo = useAsyncCallback(async (companyId: string) => {
    await deleteDocument({ variables: { relatedId: companyId } });
    await fetchLogo.execute();
  });

  const uploadLogo = useAsyncCallback(async (choosenFile: AttachedDocument, companyId: string) => {
    await deleteDocument({ variables: { relatedId: companyId } });
    const { type: contentType, name: filename } = choosenFile.file;
    const response = await createDocument({
      variables: {
        data: {
          contentType,
          filename,
          relatedId: companyId,
          documentType: DocumentType.COMPANY_LOGO,
          relatedTo: DocumentRelation.COMPANY
        }
      }
    });
    Object.defineProperty(choosenFile.file, 'name', {
      writable: true,
      value: `${companyId}|${response.data!.createDocument.id}|${choosenFile.filename}`
    });
    await uploadDocument({ variables: { file: choosenFile.file } });
    await fetchLogo.execute();
  });

  useEffect(() => {
    if (logo?.url === '' && !initialLoading) {
      setInitialLoading(true);
      fetchLogo.execute().then(() => {
        setInitialLoading(false);
      });
    } else if (initialLoading !== undefined) {
      const tenantId = location.pathname.match(/\/tenant\/([^/?]+)/)?.[1] ?? null;

      const shouldRefetch = tenantId ? tenantId !== companyId.current : companyId.current !== companyId.prev;

      if (shouldRefetch) {
        fetchLogo.execute().then((id) => {
          setCompanyId((v) =>
            tenantId
              ? { current: tenantId, prev: tenantId === v.current ? v.prev : v.current }
              : { current: id, prev: id }
          );
        });
      }
    }
  }, [fetchLogo, logo?.url, initialLoading, companyData?.companySetup.id, companyId, location.pathname]);

  return {
    isEdit,
    setIsEdit,
    name: companyData?.companySetup.name ?? '',
    themeColor: companyData?.companySetup.themeColor ?? ThemeColor.STEEL_BLUE,
    font: companyData?.companySetup.font ?? Font.MYRIAD_PRO,
    paymentFailureCode: companyData?.companySetup.paymentFailureCode as string,
    logo,
    uploadLogo: uploadLogo.execute,
    loading: uploadLogo.loading || resetLogo.loading || fetchLogo.loading,
    initialLoading,
    resetLogo: resetLogo.execute,
    changeTheme: async (themeColor) => {
      await changeTheme({ variables: { data: { themeColor } } });
    }
  };
}
