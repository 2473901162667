import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Button, ClickAwayListener, IconButton } from '@mui/material';
import { OrderDirection, TenantOrderField } from '__graphql__/globalTypes';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { API_CONTEXT } from 'shared/api/api-contexts';
import apolloClient from 'shared/apolloClient';
import { EmptySearchBox, Input } from 'shared/components';
import { CheckmarkIcon, ChevronIcon, CrossIcon, MagnifyingGlassIcon } from 'shared/components/icons';
import routes from 'shared/constants/routes';
import { SearchContext, TenantContext } from 'shared/contexts';
import { useCustomStyles, usePalette, useStableNavigate } from 'shared/hooks';
import { type TenantInfo } from 'shared/types';
import { type Tenant, type TenantVariables } from 'superAdmin/__graphql__/Tenant';
import { tenantQuery } from 'superAdmin/api';
import { type TenantsMatch, type TenantsMatchVariables } from './__graphql__/TenantsMatch';
import { tenantsMatchQuery } from './api';

interface ChooseTenantToolProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
}

const COUNT_OF_TENANTS = 7;

export const ChooseTenantTool: React.FC<ChooseTenantToolProps> = ({ open, setOpen }) => {
  const intl = useIntl();
  const { secondary, primary } = usePalette();
  const customStyles = useCustomStyles();
  const { tenantId } = useParams();
  const { setSearch } = useContext(SearchContext);
  const { tenant, setTenant } = useContext(TenantContext);
  const navigate = useStableNavigate();

  const [matches, setMatches] = useState<TenantInfo[] | null>(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const openDropdown = useCallback(() => {
    setDropdownIsOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setDropdownIsOpen(false);
  }, []);

  const clickAwayHandler = useCallback(
    (setFieldValue: (field: string, value: any) => void) => {
      closeDropdown();
      if (tenant) {
        setFieldValue('search', tenant.name);
      } else setFieldValue('search', '');
    },
    [closeDropdown, tenant]
  );

  const { setFieldValue, ...formik } = useFormik({
    initialValues: { search: '' },
    onSubmit: (_, formikHelpers) => {
      clickAwayHandler(formikHelpers.setFieldValue);
    }
  });

  const onTenantSelectHandler = useCallback(
    ({ name, id }: { name: string; id: string }) => {
      setFieldValue('search', name);
      setMatches(null);
      setTenant({ id, name });
      closeDropdown();
      navigate(routes.tenantView.replace(':tenantId', id));
    },
    [closeDropdown, navigate, setTenant, setFieldValue]
  );

  const [fetchTenant] = useLazyQuery<Tenant, TenantVariables>(tenantQuery, {
    context: API_CONTEXT.SUPERADMIN
  });

  useEffect(() => {
    if (tenant) {
      setFieldValue('search', tenant.name);
    }
  }, [tenant, setFieldValue]);

  useAsync(async () => {
    if (tenantId && !tenant) {
      const tenant = await fetchTenant({ variables: { id: tenantId } });
      if (tenant.data?.tenant) {
        onTenantSelectHandler(tenant.data?.tenant);
      }
    }
  }, []);

  const onCloseIconClickHandler = useCallback(() => {
    setFieldValue('search', '');
    setMatches(null);
    openDropdown();
  }, [openDropdown, setFieldValue]);

  const onViewAllClickHandler = useCallback(() => {
    if ([routes.root, routes.rootForNavigation].includes(window.location.pathname)) {
      navigate(routes.rootForNavigation, { state: { reset: true } });
    } else navigate(routes.rootForNavigation);
    setSearch(formik.values.search);
    setMatches(null);
    setTenant(null);
    setFieldValue('search', '');
    closeDropdown();
  }, [closeDropdown, navigate, setSearch, setTenant, setFieldValue, formik.values.search]);

  const defaultTenantsList = useQuery<TenantsMatch, TenantsMatchVariables>(tenantsMatchQuery, {
    variables: {
      orderBy: {
        field: TenantOrderField.name,
        direction: OrderDirection.asc
      },
      offset: 0,
      limit: COUNT_OF_TENANTS
    },
    context: API_CONTEXT.SUPERADMIN
  });

  const getTenantsList = useCallback(
    (searchLength: number): Array<{ name: string; id: string }> => {
      let list = (searchLength > 2 ? matches : defaultTenantsList.data?.tenants?.data) ?? [];
      if (tenant) {
        list = list.filter(({ id }) => id !== tenant.id);
        if (list.length === COUNT_OF_TENANTS) {
          list?.pop();
        }
        list?.unshift(tenant);
      }
      return list;
    },
    [tenant, matches, defaultTenantsList.data?.tenants?.data]
  );

  const onSearchChangeHandler = useCallback(
    async (query: string) => {
      openDropdown();
      if (query.length > 2) {
        const matchesResult = await apolloClient.query<TenantsMatch, TenantsMatchVariables>({
          query: tenantsMatchQuery,
          variables: {
            offset: 0,
            limit: COUNT_OF_TENANTS,
            query: query.toLowerCase()
          },
          context: API_CONTEXT.SUPERADMIN
        });
        setMatches(matchesResult.data.tenants.data?.map(({ name, id }) => ({ id, name })) ?? []);
      } else setMatches(null);
    },
    [openDropdown]
  );

  return (
    <FormikProvider value={{ setFieldValue, ...formik }}>
      <ClickAwayListener
        onClickAway={() => {
          clickAwayHandler(setFieldValue);
        }}
      >
        {open ? (
          <Form autoComplete='off'>
            <Field
              id='search'
              name='search'
              component={Input}
              placeholder={
                isInputFocused
                  ? intl.formatMessage({ id: 'search_and_select' })
                  : intl.formatMessage({ id: 'choose_tenant' })
              }
              onChange={async (e: any) => {
                await onSearchChangeHandler(e.target.value);
                formik.handleChange(e);
              }}
              onClick={openDropdown}
              onFocus={() => {
                openDropdown();
                setIsInputFocused(true);
              }}
              onBlur={() => {
                setIsInputFocused(false);
              }}
              InputProps={{
                endAdornment: formik.values.search ? (
                  <IconButton
                    onClick={() => {
                      onCloseIconClickHandler();
                    }}
                  >
                    <CrossIcon />
                  </IconButton>
                ) : (
                  <MagnifyingGlassIcon />
                )
              }}
              sx={{
                width: '200px',
                padding: '16px 16px 0',
                '& input': {
                  textOverflow: 'ellipsis'
                },
                ...(isInputFocused ? { '& input::placeholder': { color: secondary[500] } } : {})
              }}
            />
            <input type='submit' hidden />
            {dropdownIsOpen ? (
              <Box
                display='flex'
                flexDirection='column'
                sx={{
                  width: '200px',
                  border: `1px solid ${primary[600]} `,
                  borderRadius: '8px',
                  position: 'absolute',
                  background: '#FFFFFF',
                  py: '4px',
                  mt: '-1px',
                  marginX: '16px',
                  zIndex: 1
                }}
              >
                {matches?.length ||
                (formik.values.search.length <= 2 && defaultTenantsList.data?.tenants.data?.length) ||
                tenant ? (
                  <>
                    {getTenantsList(formik.values.search.length).map(({ name, id }) => (
                      <Button
                        key={id}
                        variant='text'
                        sx={{
                          padding: '8px 16px',
                          fontWeight: 400,
                          color: secondary[700],
                          justifyContent: 'space-between',
                          gap: 2,
                          '& > span': {
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis'
                          }
                        }}
                        onClick={() => {
                          onTenantSelectHandler({ name, id });
                        }}
                      >
                        <Highlighter
                          autoEscape
                          highlightStyle={customStyles.highlighter}
                          searchWords={[formik.values.search]}
                          textToHighlight={name}
                        />
                        {tenant?.id === id && <CheckmarkIcon />}
                      </Button>
                    ))}
                    <Button
                      variant='text'
                      sx={{
                        padding: '8px 16px',
                        justifyContent: 'space-between'
                      }}
                      onClick={onViewAllClickHandler}
                    >
                      {intl.formatMessage({ id: 'view_all_tenants' })}
                      <ChevronIcon direction='right' />
                    </Button>
                  </>
                ) : (
                  <EmptySearchBox />
                )}
              </Box>
            ) : null}
          </Form>
        ) : (
          <IconButton
            sx={{
              width: '56px',
              margin: '16px 16px 0',
              padding: '9px 16px',
              border: `1px solid ${secondary[400]}`,
              borderRadius: '8px'
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <MagnifyingGlassIcon />
          </IconButton>
        )}
      </ClickAwayListener>
    </FormikProvider>
  );
};
