/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Action
 */
export enum Action {
  HIDE = "HIDE",
  READ_ONLY = "READ_ONLY",
  SHOW = "SHOW",
}

/**
 * Properties by which agencies can be ordered.
 */
export enum AgencyOrderField {
  consultants = "consultants",
  createdAt = "createdAt",
  name = "name",
  phone = "phone",
  registrations = "registrations",
  updatedAt = "updatedAt",
  website = "website",
}

/**
 * AllowedCharacters
 */
export enum AllowedCharacters {
  ALL = "ALL",
  LETTERS = "LETTERS",
  NUMBERS = "NUMBERS",
}

/**
 * AuditAction
 */
export enum AuditAction {
  INSERT = "INSERT",
  NOTEADDED = "NOTEADDED",
  NOTEDELETED = "NOTEDELETED",
  NOTEEDITED = "NOTEEDITED",
  NOTIFICATION = "NOTIFICATION",
  STATUSUPDATE = "STATUSUPDATE",
  UPDATE = "UPDATE",
  USERUPDATE = "USERUPDATE",
}

/**
 * Properties by which audit can be ordered.
 */
export enum AuditOrderField {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

/**
 * Contractor status
 */
export enum ContractorStatus {
  ARCHIVED = "ARCHIVED",
  CURRENT = "CURRENT",
  DUMMY = "DUMMY",
}

/**
 * Properties by which documents can be ordered.
 */
export enum DocumentOrderField {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

/**
 * Document Relation
 */
export enum DocumentRelation {
  COMPANY = "COMPANY",
  FORM = "FORM",
  REGISTRATION = "REGISTRATION",
  USER = "USER",
}

/**
 * Document Status
 */
export enum DocumentStatus {
  DELETED = "DELETED",
  INITIAL = "INITIAL",
  UPLOADED = "UPLOADED",
}

/**
 * DocumentType
 */
export enum DocumentType {
  ASSIGNMENT_SCHEDULE = "ASSIGNMENT_SCHEDULE",
  COMPANY_LOGO = "COMPANY_LOGO",
  CONTRACT = "CONTRACT",
  DOCUMENT = "DOCUMENT",
  PROFILE_PHOTO = "PROFILE_PHOTO",
  REGISTRATION_FORM = "REGISTRATION_FORM",
}

/**
 * Email Templates Groups: SYSTEM, STAFF and CONTRACTOR
 */
export enum EmailTemplatesGroup {
  CONTRACTOR = "CONTRACTOR",
  STAFF = "STAFF",
  SYSTEM = "SYSTEM",
}

export enum Font {
  ASSISTANT = "ASSISTANT",
  MYRIAD_PRO = "MYRIAD_PRO",
}

/**
 * Errors before publish form
 */
export enum FormBuilderError {
  No_Contract = "No_Contract",
  No_Document = "No_Document",
  Signature_Required = "Signature_Required",
}

/**
 * Properties by which forms can be ordered.
 */
export enum FormOrderField {
  createdAt = "createdAt",
  creator = "creator",
  description = "description",
  name = "name",
  publishedAt = "publishedAt",
  status = "status",
}

/**
 * FormStatus
 */
export enum FormStatus {
  aPUBLISHED = "aPUBLISHED",
  bUNPUBLISHED = "bUNPUBLISHED",
  cDRAFT = "cDRAFT",
  dDELETED = "dDELETED",
}

/**
 * InvoiceStatus
 */
export enum InvoiceStatus {
  draft = "draft",
  open = "open",
  paid = "paid",
  uncollectible = "uncollectible",
  void = "void",
}

/**
 * Properties by which notes can be ordered.
 */
export enum NoteOrderField {
  createdAt = "createdAt",
  noteText = "noteText",
  updatedAt = "updatedAt",
}

/**
 * Operator
 */
export enum Operator {
  AFTER = "AFTER",
  BEFORE = "BEFORE",
  BEYOND = "BEYOND",
  INCLUDE = "INCLUDE",
  IS = "IS",
  IS_NOT = "IS_NOT",
  NOT_INCLUDE = "NOT_INCLUDE",
  WITHIN = "WITHIN",
}

/**
 * Possible directions in which to order a list of items when provided an `orderBy` argument.
 */
export enum OrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum PaymentFailureCode {
  CARD_DECLINED = "CARD_DECLINED",
  CARD_EXPIRED = "CARD_EXPIRED",
}

/**
 * QuestionComponent
 */
export enum QuestionComponent {
  ASSIGNMENT_SCHEDULE = "ASSIGNMENT_SCHEDULE",
  CHECKBOX = "CHECKBOX",
  CONTRACT = "CONTRACT",
  DATE = "DATE",
  DAY = "DAY",
  DOCUMENT = "DOCUMENT",
  FILE_UPLOAD = "FILE_UPLOAD",
  HEADER_1 = "HEADER_1",
  HEADER_2 = "HEADER_2",
  HEADER_3 = "HEADER_3",
  LONG_TEXT = "LONG_TEXT",
  MULTIPLE_SELECT = "MULTIPLE_SELECT",
  OPTIONS_BIG = "OPTIONS_BIG",
  OPTIONS_SMALL = "OPTIONS_SMALL",
  PARAGRAPH = "PARAGRAPH",
  SHORT_TEXT = "SHORT_TEXT",
  SINGLE_SELECT = "SINGLE_SELECT",
  TIME = "TIME",
  TIME_PICKER = "TIME_PICKER",
}

/**
 * Properties by which registrations can be ordered.
 */
export enum RegistrationOrderField {
  createdAt = "createdAt",
  email = "email",
  firstName = "firstName",
  phoneNumber = "phoneNumber",
  status = "status",
  updatedAt = "updatedAt",
}

/**
 * RegistrationStatus
 */
export enum RegistrationStatus {
  bREFERRED = "bREFERRED",
  dNO_RESPONSE = "dNO_RESPONSE",
  gIN_PROGRESS = "gIN_PROGRESS",
  iFORM_SENT = "iFORM_SENT",
  lID_CHECKS = "lID_CHECKS",
  oAWAITING_ID = "oAWAITING_ID",
  rON_HOLD = "rON_HOLD",
  tUPLOAD_TO_PAYROLL = "tUPLOAD_TO_PAYROLL",
  vREGISTERED = "vREGISTERED",
  xNOT_GOING_AHEAD = "xNOT_GOING_AHEAD",
}

/**
 * Properties by which section can be ordered.
 */
export enum RelationshipOrderField {
  description = "description",
  name = "name",
  relationship = "relationship",
  variable = "variable",
}

export enum RetentionPeriod {
  DAYS = "DAYS",
  MONTHS = "MONTHS",
  NEVER = "NEVER",
  WEEKS = "WEEKS",
}

/**
 * User role
 */
export enum Role {
  ADMIN = "ADMIN",
  CONSULTANT = "CONSULTANT",
  CONTRACTOR = "CONTRACTOR",
  CUSTOMER_CARE = "CUSTOMER_CARE",
  REGISTRAR = "REGISTRAR",
  SUPER_ADMIN = "SUPER_ADMIN",
}

/**
 * Relationship of section
 */
export enum SectionRelationshipType {
  Child = "Child",
  Parent = "Parent",
  Separated = "Separated",
}

/**
 * SubscriptionStatus
 */
export enum SubscriptionStatus {
  active = "active",
  canceled = "canceled",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  past_due = "past_due",
  trialing = "trialing",
  unpaid = "unpaid",
}

/**
 * Properties by which superadmins can be ordered.
 */
export enum SuperAdminOrderField {
  email = "email",
  firstName = "firstName",
  isActive = "isActive",
  lastLogin = "lastLogin",
  role = "role",
}

/**
 * Categories of tasks
 */
export enum TaskCategory {
  Done = "Done",
  DueToday = "DueToday",
  InProgress = "InProgress",
  Overdue = "Overdue",
  Upcoming = "Upcoming",
}

/**
 * Properties by which tasks can be ordered.
 */
export enum TaskOrderField {
  AssignedTo = "AssignedTo",
  Category = "Category",
  Contractor = "Contractor",
  Done = "Done",
  DueDate = "DueDate",
  Name = "Name",
  StartDate = "StartDate",
}

/**
 * Properties by which tenants can be ordered.
 */
export enum TenantOrderField {
  adminEmail = "adminEmail",
  adminName = "adminName",
  createdAt = "createdAt",
  favorites = "favorites",
  id = "id",
  name = "name",
  status = "status",
  updatedAt = "updatedAt",
}

/**
 * TenantStatus
 */
export enum TenantStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/**
 * Properties by which tenants can be ordered.
 */
export enum TenantWithAnalyticsOrderFields {
  LastActivity = "LastActivity",
  Name = "Name",
  Plan = "Plan",
  Registrations = "Registrations",
  Revenue = "Revenue",
  UsedRegistrations = "UsedRegistrations",
  Users = "Users",
}

export enum ThemeColor {
  AMETHYST = "AMETHYST",
  CRIMSON = "CRIMSON",
  ELECTRIC_PURPLE = "ELECTRIC_PURPLE",
  FOREST_GREEN = "FOREST_GREEN",
  PUMPKIN_ORANGE = "PUMPKIN_ORANGE",
  ROYAL_BLUE = "ROYAL_BLUE",
  SHAMROCK_GREEN = "SHAMROCK_GREEN",
  STEEL_BLUE = "STEEL_BLUE",
  TEAL_BLUE = "TEAL_BLUE",
}

/**
 * Title
 */
export enum Title {
  DR = "DR",
  LADY = "LADY",
  MISS = "MISS",
  MR = "MR",
  MRS = "MRS",
  MS = "MS",
  MX = "MX",
  PROF = "PROF",
  REV = "REV",
}

/**
 * Properties by which users can be ordered.
 */
export enum UserOrderField {
  agencyName = "agencyName",
  assignedTo = "assignedTo",
  email = "email",
  firstName = "firstName",
  isActive = "isActive",
  lastLogin = "lastLogin",
  phoneNumber = "phoneNumber",
  referrals = "referrals",
  registrations = "registrations",
  role = "role",
  status = "status",
}

export interface AddSectionArgs {
  formId: string;
  name: string;
}

export interface AddUserInput {
  assignedTo?: string | null;
  email?: string | null;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  role: Role;
  status?: string | null;
  title?: string | null;
}

export interface AgencyOrder {
  direction: OrderDirection;
  field: AgencyOrderField;
}

export interface AgencySave {
  id?: string | null;
  name?: string | null;
  phone?: string | null;
  website?: string | null;
}

export interface ApplyDiscount {
  endDate: DateTime;
  startDate: DateTime;
  valueInPercent: number;
}

export interface AuditFilter {
  registrationId?: string | null;
  userId?: string | null;
}

export interface AuditOrder {
  direction: OrderDirection;
  field: AuditOrderField;
}

export interface ChangeEmailData {
  content: string;
  id: number;
  isActive: boolean;
  sendTo: string[];
  senderName?: string | null;
  subject: string;
}

export interface ChangePlan {
  prices: CheckoutPrice[];
}

export interface Checkout {
  cancelUrl?: string | null;
  prices: CheckoutPrice[];
  successUrl: string;
}

export interface CheckoutPrice {
  default?: boolean | null;
  id: string;
}

export interface CompanyLinksData {
  emailLink?: string | null;
  facebookLink?: string | null;
  faqLink?: string | null;
  footerLinks?: CustomLink[] | null;
  linkedInLink?: string | null;
  twitterLink?: string | null;
}

export interface ContractorsArgs {
  filters: ContractorsFilter;
  orderBy?: UserOrder | null;
  pagination?: Pagination | null;
}

export interface ContractorsFilter {
  assignedTo?: string | null;
  search?: string | null;
  statuses?: ContractorStatus[] | null;
}

export interface CreateQuestionInput {
  additionalText?: string | null;
  allowedCharacters?: AllowedCharacters | null;
  component: QuestionComponent;
  maxChar?: number | null;
  minChar?: number | null;
  options?: OptionInput[] | null;
  order: number;
  required?: boolean | null;
  sectionId: string;
  text?: string | null;
}

export interface CreateTaskData {
  contractorId?: string | null;
  description?: string | null;
  dueDate: DateTime;
  name: string;
  staffUserId: string;
  startDate: DateTime;
}

export interface CreateUserEmail {
  content: string;
  subject: string;
  to: string;
  userId: string;
}

export interface CustomLink {
  link: string;
  name: string;
}

export interface DocumentCreate {
  contentType: string;
  documentType: string;
  filename: string;
  relatedId: string;
  relatedTo: string;
}

export interface DocumentFilter {
  documentType?: DocumentType | null;
  relatedId: string;
  relatedTo: DocumentRelation;
}

export interface DocumentOrder {
  direction: OrderDirection;
  field: DocumentOrderField;
}

export interface FormOrder {
  direction: OrderDirection;
  field: FormOrderField;
}

export interface FormQuestionAnswerInput {
  answer: string[];
  id?: string | null;
  questionId: string;
  registrationId: string;
}

export interface InviteConfirmInput {
  password: string;
}

export interface InviteInput {
  agencyId?: string | null;
  assignedTo?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  role: string;
  status?: string | null;
  title?: string | null;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface NoteFilter {
  registrationId?: string | null;
  userId?: string | null;
}

export interface NoteOrder {
  direction: OrderDirection;
  field: NoteOrderField;
}

export interface NoteSave {
  id?: string | null;
  noteText: string;
  registrationId?: string | null;
  userId?: string | null;
}

export interface OptionInput {
  label: string;
  optionId: string;
  value: string;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export interface PaginationArgs {
  limit: number;
  offset: number;
}

export interface RegistrationFilter {
  addedByMe?: boolean | null;
  contractorId?: string | null;
  current?: boolean | null;
  dummyRecord?: boolean | null;
  locked?: boolean | null;
  statuses?: RegistrationStatus[] | null;
}

export interface RegistrationOrder {
  direction: OrderDirection;
  field: RegistrationOrderField;
}

export interface RegistrationSave {
  agencyId?: string | null;
  consultantId?: string | null;
  contractType?: string | null;
  contractorId?: string | null;
  email?: string | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  note?: string | null;
  phoneNumber?: string | null;
  title?: string | null;
  weeklyFee?: string | null;
}

export interface RegistrationUpload {
  agencyId?: string | null;
  consultantId?: string | null;
  contractType?: string | null;
  createdAt?: string | null;
  email?: string | null;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  note?: string | null;
  phoneNumber?: string | null;
  title?: string | null;
}

export interface RelationshipFilters {
  direction: OrderDirection;
  field: RelationshipOrderField;
  formId: string;
  limit: number;
  offset: number;
  sectionId: string;
}

export interface ReportFilter {
  endDate?: DateTime | null;
  startDate?: DateTime | null;
}

export interface ResetPasswordInput {
  password: string;
}

export interface RuleActionInput {
  action: Action;
  id?: string | null;
  questionId?: string | null;
  sectionId?: string | null;
}

export interface SaveRuleInput {
  actions: RuleActionInput[];
  comparedValue: string;
  id?: string | null;
  issuerId: string;
  operator: Operator;
}

export interface SectionFilter {
  parentId?: string | null;
}

export interface SectionsArgs {
  contractType?: string | null;
  formId?: string | null;
  sectionFilter?: SectionFilter | null;
}

export interface SelfReferral {
  email?: string | null;
  firstName: string;
  lastName: string;
  note?: string | null;
  phoneNumber?: string | null;
  title?: string | null;
}

export interface SignDocumentInput {
  questionId: string;
  registrationId: string;
  signerName: string;
}

export interface SingleDocumentFilter {
  documentType?: string | null;
  id?: string | null;
  relatedId?: string | null;
}

export interface SuperAdminInviteInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface SuperAdminOrder {
  direction: OrderDirection;
  field: SuperAdminOrderField;
}

export interface TaskFilter {
  categories?: TaskCategory[] | null;
  search?: string | null;
  staffUserId?: string | null;
}

export interface TaskOrder {
  direction: OrderDirection;
  field: TaskOrderField;
}

export interface TenantCreate {
  adminEmail: string;
  adminName: string;
  id: string;
  name: string;
}

export interface TenantFilter {
  favourite?: boolean | null;
  setupFinished?: boolean | null;
}

export interface TenantOrder {
  direction: OrderDirection;
  field: TenantOrderField;
}

export interface TenantUpdate {
  adminEmail?: string | null;
  adminName?: string | null;
  billingContactEmail?: string | null;
  billingContactName?: string | null;
  id: string;
  name?: string | null;
  newId?: string | null;
  noFallback?: boolean | null;
  status?: string | null;
}

export interface TenantWithAnalyticsOrder {
  direction: OrderDirection;
  field: TenantWithAnalyticsOrderFields;
}

export interface TenantsAnalyticsFilters {
  endDate: DateTime;
  startDate: DateTime;
  tenantsIds: string[];
}

export interface UpdateCompanyInfoData {
  adminEmail?: string | null;
  adminName?: string | null;
  autoSuspension?: boolean | null;
  billingContactEmail?: string | null;
  billingContactName?: string | null;
  emailLink?: string | null;
  facebookLink?: string | null;
  id?: string | null;
  linkedInLink?: string | null;
  name?: string | null;
  newId?: string | null;
  noFallback?: boolean | null;
  retentionPeriodType?: RetentionPeriod | null;
  retentionPeriodValue?: number | null;
  status?: TenantStatus | null;
  themeColor?: string | null;
  twitterLink?: string | null;
}

export interface UpdateQuestionInput {
  additionalText?: string | null;
  allowedCharacters?: AllowedCharacters | null;
  description?: string | null;
  id: string;
  initialAnswer?: string | null;
  maxChar?: number | null;
  minChar?: number | null;
  options?: OptionInput[] | null;
  order?: number | null;
  placeholder?: string | null;
  required?: boolean | null;
  text?: string | null;
  tooltip?: string | null;
  variable?: string | null;
  withTimePicker?: boolean | null;
}

export interface UpdateSectionInput {
  description?: string | null;
  name?: string | null;
  order?: number | null;
  parentId?: string | null;
  variable?: string | null;
}

export interface UpdateSuperAdminInput {
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
}

export interface UpdateTaskData {
  contractorId?: string | null;
  description?: string | null;
  done?: boolean | null;
  dueDate?: DateTime | null;
  id: string;
  name?: string | null;
  staffUserId?: string | null;
  startDate?: DateTime | null;
}

export interface UpdateUserInput {
  agencyId?: string | null;
  assignedTo?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  isActive?: boolean | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  role?: string | null;
  status?: ContractorStatus | null;
  title?: Title | null;
}

export interface UpsertFormInput {
  contractType?: string | null;
  description?: string | null;
  id?: string | null;
  name: string;
  status?: FormStatus | null;
}

export interface UserFilter {
  agencyId?: string | null;
  assignedTo?: string | null;
  isActive?: boolean | null;
  roles?: Role[] | null;
  statuses?: ContractorStatus[] | null;
}

export interface UserOrder {
  direction: OrderDirection;
  field: UserOrderField;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
