import { useMutation, useQuery } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { Role, type CreateTaskData } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Field } from 'formik';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { usersWithNameQuery } from 'shared/api';
import { type UsersWithName, type UsersWithNameVariables } from 'shared/api/__graphql__/UsersWithName';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { CalendarRangeFormControl, FilterableSelect, FormDialog, Input } from 'shared/components';
import { MagnifyingGlassIcon } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { AuthContext } from 'shared/contexts';
import { Validator } from 'shared/utils/validator';
import { type CreateTask, type CreateTaskVariables } from './__graphql__/CreateTask';
import { createTaskMutation } from './api';

dayjs.extend(utc);

export const TaskForm: FC = () => {
  const { formatMessage } = useIntl();

  const staffUsers = useQuery<UsersWithName, UsersWithNameVariables>(usersWithNameQuery, {
    variables: {
      offset: 0,
      limit: 1000,
      filter: {
        roles: [Role.ADMIN, Role.CUSTOMER_CARE, Role.REGISTRAR],
        isActive: true
      }
    },
    context: API_CONTEXT.AUTH
  });

  const contractors = useQuery<UsersWithName, UsersWithNameVariables>(usersWithNameQuery, {
    variables: {
      offset: 0,
      limit: 1000,
      filter: {
        roles: [Role.CONTRACTOR],
        isActive: true
      }
    },
    context: API_CONTEXT.AUTH
  });

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Field
        id='title'
        name='name'
        validate={Validator.pipe(Validator.methods.required())}
        label={formatMessage({ id: 'title' })}
        component={Input}
      />
      <Field
        id='assignedTo'
        name='staffUserId'
        label={formatMessage({ id: 'assigned_to' })}
        validate={Validator.pipe(Validator.methods.required())}
        component={FilterableSelect}
        InputProps={{
          endAdornment: <MagnifyingGlassIcon />
        }}
        options={
          staffUsers?.data?.users?.data?.map(({ id, firstName, lastName }) => ({
            value: id,
            label: `${firstName} ${lastName}`.trim()
          })) ?? []
        }
      />
      <Field
        id='contractor'
        name='contractorId'
        label={formatMessage({ id: 'contractor' })}
        component={FilterableSelect}
        InputProps={{
          endAdornment: <MagnifyingGlassIcon />
        }}
        options={
          contractors?.data?.users?.data?.map(({ id, firstName, lastName }) => ({
            value: id,
            label: `${firstName} ${lastName}`.trim()
          })) ?? []
        }
      />
      <CalendarRangeFormControl />
      <Field
        id='taskDescription'
        name='description'
        label={formatMessage({ id: 'task_description' })}
        component={Input}
        InputProps={{ multiline: true, rows: 4 }}
      />
    </Box>
  );
};

export const TaskWidget: FC = () => {
  const { formatMessage } = useIntl();
  const { authState } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [createTask, { loading }] = useMutation<CreateTask, CreateTaskVariables>(createTaskMutation, {
    refetchQueries: ['Tasks'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const onSubmit = async (data: CreateTaskData): Promise<void> => {
    await createTask({ variables: { data } });
    setIsModalOpen(false);
    toast(
      <SuccessSnackbar
        title={formatMessage({ id: 'task_created' })}
        message={formatMessage({ id: 'task_created_message' })}
      />
    );
  };

  return (
    <Box>
      <Button sx={{ width: '140px' }} variant='contained' onClick={() => setIsModalOpen(true)}>
        {formatMessage({ id: 'new_task' })}
      </Button>
      <FormDialog
        header={formatMessage({ id: 'add_task' })}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{ height: '75%' }}
        size='sm'
        loading={loading}
        confirmBtnName={formatMessage({ id: 'save' })}
        initialValues={{
          name: '',
          staffUserId: authState.user?.id,
          startDate: dayjs.utc().startOf('day').toDate(),
          dueDate: dayjs.utc().endOf('day').toDate(),
          contractorId: null,
          description: null
        }}
        onSubmit={onSubmit}
      >
        <TaskForm />
      </FormDialog>
    </Box>
  );
};
