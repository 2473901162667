import gql from 'graphql-tag';

export const registrationQuery = gql`
  query Registration($id: String!) {
    registration(id: $id) {
      id
      title
      firstName
      lastName
      phoneNumber
      email
      status
      agencyId
      consultantId
      form {
        contractType
      }
      formId
      weeklyFee
      uploadedToPayroll
      agency {
        id
        name
      }
      consultant {
        id
        firstName
        lastName
      }
      questionAnswers {
        id
        answer
        questionId
      }
    }
  }
`;

export const saveRegistrationMutation = gql`
  mutation SaveRegistration($data: RegistrationSave!) {
    saveRegistration(data: $data) {
      id
    }
  }
`;

export const createFormAnswersMutation = gql`
  mutation CreateFormAnswers($data: [FormQuestionAnswerInput!]!) {
    createFormAnswers(data: $data)
  }
`;

export const documentsModalQuery = gql`
  query DocumentsModal($offset: Int!, $limit: Int!, $orderBy: DocumentOrder, $filter: DocumentFilter) {
    documents(limit: $limit, offset: $offset, orderBy: $orderBy, filter: $filter) {
      total
      data {
        id
        filename
        contentType
        status
        documentType
        verified
      }
    }
  }
`;

export const sectionsQuery = gql`
  fragment QuestionFields on Question {
    id
    required
    initialAnswer
    text
    component
    documentType
    tooltip
    variable
    description
    placeholder
    minChar
    allowedCharacters
    maxChar
    withTimePicker
    initialAnswer
    additionalText
    options {
      optionId
      label
      value
    }
  }

  query Sections($filters: SectionsArgs!) {
    sections(filters: $filters) {
      id
      name
      title
      description
      children {
        id
        name
        parent {
          name
        }
        title
        description
        questions {
          ...QuestionFields
        }
      }
      questions {
        ...QuestionFields
      }
    }
  }
`;

export const registrationAnswersQuery = gql`
  query RegistrationAnswers($registrationId: String!) {
    answers(registrationId: $registrationId) {
      id
      questionId
      answer
      question {
        component
      }
    }
  }
`;

export const formRulesQuery = gql`
  query FormRules($formId: String!) {
    formRules(formId: $formId) {
      id
      issuer {
        id
        component
      }
      operator
      comparedValue
      actions {
        id
        action
        sectionId
        questionId
      }
    }
  }
`;
