import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { type UpdateCompanyInfoData } from '__graphql__/globalTypes';
import { useState, type FC, useEffect, useCallback, useContext } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { companyIdPollingQuery, updateCompanyInfoMutation } from 'shared/api';
import { type CompanyIdPolling } from 'shared/api/__graphql__/CompanyIdPolling';
import { type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { CompanyForm, FormDialog } from 'shared/components';
import { InformationSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { TENANT_HEADER_NAME } from 'shared/constants';
import { CompanyContext, SubscriptionContext } from 'shared/contexts';
import { useRoles } from 'shared/hooks';
import { sleep } from 'shared/utils/tools';
import { getTenantUrl } from 'shared/utils/url';

interface Props {
  company: CompanyInfo_companyInfo;
}
export const CompanyInfoWidget: FC<Props> = ({ company }) => {
  const { isEdit, setIsEdit } = useContext(CompanyContext);
  const { isSuperAdmin } = useRoles();
  const { currentPlan } = useContext(SubscriptionContext);
  const [newId, setNewId] = useState<string>('');
  const { formatMessage } = useIntl();

  const [updateInfo, { loading }] = useMutation<UpdateCompanyInfoData>(updateCompanyInfoMutation, {
    refetchQueries: ['CompanyInfo'],
    awaitRefetchQueries: true
  });

  const [startChangeIdPolling, { data, stopPolling }] = useLazyQuery<CompanyIdPolling>(companyIdPollingQuery, {
    pollInterval: 5000,
    context: {
      headers: {
        [TENANT_HEADER_NAME]: newId
      }
    }
  });

  const handleStopPolling = useCallback(async () => {
    stopPolling();
    await startChangeIdPolling({ fetchPolicy: 'cache-only', pollInterval: 0 });
  }, [startChangeIdPolling, stopPolling]);

  const handleSuccess = useCallback(
    async (id: string) => {
      handleStopPolling();
      toast.dismiss();
      setIsEdit(false);
      toast(
        <SuccessSnackbar
          title={formatMessage({ id: 'url_changed' })}
          message={formatMessage({ id: 'url_changed_description' }, { name: '' })}
        />
      );
      setNewId('');
      await sleep(5000);
      window.open(`https://${getTenantUrl(id)}`, '_self');
    },
    [formatMessage, handleStopPolling, setIsEdit]
  );

  useEffect(() => {
    return () => {
      if (newId) {
        handleStopPolling();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newId]);

  useEffect(() => {
    if (data?.companyInfo?.id === newId) {
      handleSuccess(data?.companyInfo?.id);
    }
  }, [data?.companyInfo.id, newId, handleSuccess, stopPolling]);

  const handleUpdate = useAsyncCallback(async (data: UpdateCompanyInfoData) => {
    await updateInfo({ variables: { data } });
    if (data.newId) {
      setNewId(data.newId);
      await startChangeIdPolling();
      toast(
        <InformationSnackbar
          title={formatMessage({ id: 'url_change_in_process' })}
          message={formatMessage({ id: 'change_url_description' })}
        />,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
          closeOnClick: false,
          draggable: false
        }
      );
    } else {
      toast(<SuccessSnackbar title={formatMessage({ id: 'company_updated' })} />);
      setIsEdit(false);
    }
  });

  return (
    <>
      <Button type='button' size='medium' variant='contained' onClick={() => setIsEdit(true)}>
        {formatMessage({ id: 'edit_company' })}
      </Button>
      <FormDialog
        disabled={!!newId}
        isOpen={isEdit}
        size='md'
        onClose={() => setIsEdit(false)}
        header={formatMessage({ id: 'edit_company' })}
        loading={loading}
        initialValues={{ ...company, card: currentPlan?.cardNumber, expiringDate: currentPlan?.cardExpirationDate }}
        confirmBtnName={formatMessage({ id: 'save' })}
        onSubmit={async (data) => {
          const { id, name, adminEmail, adminName, billingContactEmail, billingContactName } = data;
          await handleUpdate.execute({
            name,
            adminEmail,
            adminName,
            billingContactEmail,
            billingContactName,
            ...(company.id !== id ? { newId: id } : {})
          });
        }}
      >
        {/* @ts-expect-error: error */}
        <CompanyForm forTenant={false} withBillingContact withPaymentInfo={!isSuperAdmin} />
      </FormDialog>
    </>
  );
};
