import gql from 'graphql-tag';

export const tenantQuery = gql`
  query Tenant($id: String!) {
    tenant(id: $id) {
      id
      name
    }
  }
`;

export const extendTrialMutation = gql`
  mutation ExtendTrial($trialEndDate: DateTime!) {
    extendTrial(trialEndDate: $trialEndDate)
  }
`;
