import { Box, Chip, List, ListItem, Typography, type AutocompleteRenderGetTagProps } from '@mui/material';
import { EmailTemplatesGroup } from '__graphql__/globalTypes';
import { Field } from 'formik';
import { equals } from 'ramda';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { CustomEditor, Input, InputMultipleValues, Switch } from 'shared/components';
import { InputTooltip } from 'shared/components/form';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import { usePalette } from 'shared/hooks';
import { camelCaseToHumanFriendly } from 'shared/utils/strings';
import { Validator, isEmail } from 'shared/utils/validator';
import { type EmailTemplates_emailTemplates as Template } from './__graphql__/EmailTemplates';

const VARIABLES = {
  agencies: [
    { value: 'Agency', description: 'agency_name' },
    { value: 'Consultant First Name', description: 'consultant_first_name' },
    { value: 'Consultant Last Name', description: 'consultant_last_name' }
  ],
  contractors: [
    { value: 'Contractor First Name', description: 'contractor_first_name' },
    { value: 'Contractor Last Name', description: 'contractor_last_name' }
  ],
  other: [
    { value: 'App Link', description: 'link_to_your_system' },
    { value: 'Tenant Name', description: 'name_of_company' },
    { value: 'Referral Link', description: 'link_to_registration' }
  ],
  staff_team: [
    { value: 'Staff First Name', description: 'staff_first_name' },
    { value: 'Staff Last Name', description: 'staff_last_name' }
  ]
};

export const EmailsTemplatesWidget: FC<Partial<Template> & Record<'to', string[] | undefined>> = ({
  group,
  templateId,
  contractType,
  to
}) => {
  const { formatMessage } = useIntl();
  const { secondary, info } = usePalette();

  const getVariablesByGroup = (values: Array<{ value: string; description: string }>): JSX.Element => {
    return (
      <List sx={{ border: 'none', p: 0 }}>
        {values.map(({ value, description }) => (
          <ListItem key={value} sx={{ p: 0 }}>
            <Typography sx={{ lineHeight: '28px' }}>
              <Typography component='span'>{`{${value}}`}</Typography>
              {` - ${formatMessage({ id: description })}`}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box>
      <Typography sx={{ mb: '16px' }}>
        {formatMessage({ id: `${contractType ? 'registered' : templateId}_description` })}
        {formatMessage({ id: 'viewing_predefined_email' })}
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', flex: 1 }}>
          <Typography variant='h3' sx={{ fontSize: '20px', color: secondary[700], fontWeight: 600 }}>
            {formatMessage({ id: 'email_template' })}
          </Typography>
          <Field
            id='from'
            name='from'
            component={Input}
            label={formatMessage({ id: 'from' })}
            validate={Validator.pipe(Validator.methods.required(), Validator.methods.maxLength(50))}
            InputLabelProps={{ className: 'form' }}
            InputProps={{
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputTooltip
                    title={formatMessage({
                      id: 'sender_name_tooltip'
                    })}
                  />
                </Box>
              ),
              className: 'form'
            }}
          />
          <Field
            id='to'
            name='to'
            component={InputMultipleValues}
            label={formatMessage({ id: 'to_email' })}
            validate={Validator.pipe(Validator.methods.required())}
            renderTags={(value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={!isEmail(option) ? `{${camelCaseToHumanFriendly(option)}}` : option}
                  {...getTagProps({ index })}
                  key={index}
                  disabled={!isEmail(option)}
                />
              ))
            }
            onChange={async (event: KeyboardEvent, options: string[]) => {
              const lastChoosenOption = options[options.length - 1];
              const validationError = await Validator.pipe(Validator.methods.email())(lastChoosenOption);
              return ((event.key === 'Backspace' && !options.length) || validationError) && !equals(to, options);
            }}
            InputProps={{
              readOnly: group === EmailTemplatesGroup.SYSTEM,
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputTooltip
                    title={formatMessage({
                      id: `${group?.toLowerCase()}_send_to_tooltip`
                    })}
                  />
                </Box>
              )
            }}
          />
          <Field
            id='subject'
            name='subject'
            component={Input}
            label={formatMessage({ id: 'subject' })}
            validate={Validator.pipe(Validator.methods.required(), Validator.methods.maxLength(100))}
            InputLabelProps={{ className: 'form' }}
            InputProps={{
              className: 'form'
            }}
          />
          <Field
            id='email'
            name='content'
            component={CustomEditor}
            label={formatMessage({ id: 'email' })}
            InputLabelProps={{ className: 'form' }}
            InputProps={{
              className: 'form'
            }}
          />
          <Field
            id='isActive'
            name='isActive'
            component={Switch}
            handleChange={(setValue: (v: boolean) => void, newValue: boolean) => {
              if (group === EmailTemplatesGroup.SYSTEM) {
                toast(
                  <AttentionSnackbar
                    title={formatMessage({ id: 'disable_impossible' })}
                    message={formatMessage({ id: 'cant_turn_off_system_emails' })}
                  />,
                  { position: toast.POSITION.BOTTOM_CENTER }
                );
              } else {
                setValue(newValue);
              }
            }}
            label={formatMessage({ id: 'include_in_mailing_list' })}
            InputLabelProps={{ className: 'form' }}
            InputProps={{
              className: 'form'
            }}
          />
        </Box>
        <Box>
          <Typography variant='h3' sx={{ fontSize: '20px', color: secondary[700], fontWeight: 600, mb: '16px' }}>
            {formatMessage({ id: 'variables' })}
          </Typography>
          <Box sx={{ p: '12px', backgroundColor: info[200], borderRadius: '8px' }}>
            {Object.entries(VARIABLES).map(([group, values], index) => (
              <Box key={group} sx={{ pb: index !== 3 ? '10px' : '0' }}>
                <Typography variant='subtitle2' color='secondary.700' sx={{ fontWeight: 600 }}>
                  {formatMessage({ id: group })}
                </Typography>
                {getVariablesByGroup(values)}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
