import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const CheckboxIcon: FC<Exclude<IconProps, 'color'> & { checked: boolean }> = (props) => {
  const { checked } = props;
  const { info, primary } = usePalette();

  return checked ? (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill={primary[300]} stroke={info[50]} />
      <path
        d='M5.14258 11.8595C6.88617 13.6195 10.3733 17.1395 10.3733 17.1395L19.714 7.71094'
        stroke={info[600]}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  ) : (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill={primary[200]} />
      <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill={primary[200]} stroke={info[500]} />
      <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' stroke={info[500]} />
    </svg>
  );
};
