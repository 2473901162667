import gql from 'graphql-tag';

export const profileUserQuery = gql`
  query ProfileUserData {
    activeUser: me {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

export const profilePhotoQuery = gql`
  query ProfilePhoto($documentFilter: SingleDocumentFilter!) {
    document(filter: $documentFilter) {
      id
    }
  }
`;

export const profilePhotoUrlQuery = gql`
  query ProfilePhotoUrl($id: String!) {
    avatarUrl: getDowloadUrl(id: $id)
  }
`;
