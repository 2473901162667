import { useMutation } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { RetentionPeriod, type UpdateCompanyInfoData } from '__graphql__/globalTypes';
import { Field, type FormikProps } from 'formik';
import { useState, type FC, type SyntheticEvent, useContext, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { cancelSubscriptionMutation, restoreSubscriptionMutation, updateCompanyInfoMutation } from 'shared/api';
import { type CancelSubscription } from 'shared/api/__graphql__/CancelSubscription';
import { type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { type RestoreSubscription } from 'shared/api/__graphql__/RestoreSubscription';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { ConfirmationDialog, FormDialog, Input, Select, TriangleMarkeredList } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { SubscriptionContext } from 'shared/contexts';
import { Validator } from 'shared/utils/validator';

type RetentionPeriodValues = Pick<CompanyInfo_companyInfo, 'retentionPeriodType' | 'retentionPeriodValue'>;
const retentionTypeOrderedOptions = [
  RetentionPeriod.NEVER,
  RetentionPeriod.MONTHS,
  RetentionPeriod.WEEKS,
  RetentionPeriod.DAYS
];

export const RetentionPeriodWidget: FC<RetentionPeriodValues> = (props) => {
  const { retentionPeriodType } = props;
  const [isRetentionPeriodModalOpen, setIsRetentionPeriodModalOpen] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [values, setValues] = useState<RetentionPeriodValues>(props);
  const { formatMessage } = useIntl();

  const [updateInfo, { loading }] = useMutation<UpdateCompanyInfoData>(updateCompanyInfoMutation, {
    refetchQueries: ['CompanyInfo'],
    awaitRefetchQueries: true
  });

  const onClose = useCallback(() => {
    setIsRetentionPeriodModalOpen(false);
    setIsReset(false);
  }, []);

  useEffect(() => {
    if (isRetentionPeriodModalOpen) {
      setValues(props);
    }
  }, [isRetentionPeriodModalOpen, props]);

  const Form: FC<FormikProps<RetentionPeriodValues>> = ({ setFieldValue }) => (
    <Box sx={{ display: 'flex', gap: '20px' }}>
      <Box sx={{ flex: '1.3' }}>
        <TriangleMarkeredList
          sx={{ maxHeight: '200px' }}
          itemsIntlIds={['retention_period_points.1', 'retention_period_points.2', 'retention_period_points.3']}
        />
      </Box>
      <Box sx={{ flex: '1' }}>
        <Typography sx={{ mb: '24px', fontWeight: 600 }}>{formatMessage({ id: 'timing' })}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Field
            id='retentionPeriodType'
            name='retentionPeriodType'
            label={formatMessage({ id: 'delete_in' })}
            onChange={({ target }: any) => {
              const newType = target.value;
              setFieldValue('retentionPeriodType', newType);
              setValues((v) => ({
                retentionPeriodValue: newType === RetentionPeriod.NEVER ? null : v.retentionPeriodValue,
                retentionPeriodType: newType
              }));
            }}
            component={Select}
            options={retentionTypeOrderedOptions.map((item) => ({
              label: formatMessage({ id: item.toLowerCase() }),
              value: item
            }))}
          />
          {values.retentionPeriodType !== RetentionPeriod.NEVER && (
            <Field
              id='retentionPeriodValue'
              name='retentionPeriodValue'
              validate={Validator.pipe(Validator.methods.required())}
              label={formatMessage({ id: values.retentionPeriodType.toLowerCase() })}
              onChange={({ target }: SyntheticEvent) => {
                const value = +(target as HTMLInputElement).value;
                setFieldValue('retentionPeriodValue', value);
              }}
              type='number'
              component={Input}
              InputProps={{ className: 'form', inputProps: { min: 1 } }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Button variant='contained' onClick={() => setIsRetentionPeriodModalOpen(true)}>
        {formatMessage({
          id: retentionPeriodType === RetentionPeriod.NEVER ? 'set_retention_period' : 'retention_period_set'
        })}
      </Button>
      <FormDialog
        isOpen={isRetentionPeriodModalOpen}
        onClose={onClose}
        header={formatMessage({ id: 'set_retention_period' })}
        initialValues={values}
        confirmBtnName={formatMessage({ id: 'save' })}
        loading={loading}
        resetBtnConfig={
          retentionPeriodType !== RetentionPeriod.NEVER
            ? {
              title: formatMessage({ id: 'reset' }),
              handler: () => {
                setValues({ retentionPeriodType: RetentionPeriod.NEVER, retentionPeriodValue: null });
                setIsReset(true);
              },
              disabled: isReset
            }
            : undefined
        }
        onSubmit={async (data) => {
          await updateInfo({
            variables: {
              data: { retentionPeriodType: data.retentionPeriodType, retentionPeriodValue: data.retentionPeriodValue }
            }
          });
          onClose();
          toast(<SuccessSnackbar title={formatMessage({ id: 'retention_period_saved' })} />);
        }}
      >
        {/* @ts-expect-error: props are getting here from React.clone */}
        <Form />
      </FormDialog>
    </>
  );
};

export const CancelSubscriptionWidget: FC = () => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { currentPlan } = useContext(SubscriptionContext);
  const { formatMessage } = useIntl();

  const [cancelSubscription, { loading: cancelLoading }] = useMutation<CancelSubscription>(cancelSubscriptionMutation, {
    context: API_CONTEXT.PAYMENT,
    refetchQueries: ['CompanySubscriptions']
  });

  const [restoreSubscription, { loading: restoreLoading }] =
    useMutation<RestoreSubscription>(restoreSubscriptionMutation, {
      context: API_CONTEXT.PAYMENT,
      refetchQueries: ['CompanySubscriptions']
    });

  const onClose = useCallback(() => {
    setIsCancelModalOpen(false);
    setIsConfirmed(false);
  }, []);

  return (
    <>
      <Button variant='contained' color='error' onClick={() => setIsCancelModalOpen(true)}>
        {formatMessage({ id: currentPlan?.cancelAtPeriodEnd ? 'subscription_canceled' : 'cancel_subscription' })}
      </Button>
      <ConfirmationDialog
        maxWidth="md"
        open={isCancelModalOpen}
        onClose={onClose}
        title={formatMessage({ id: currentPlan?.cancelAtPeriodEnd ? 'subscription_canceled' : 'cancel_subscription' })}
        confirmButtonContent={formatMessage({
          id: currentPlan?.cancelAtPeriodEnd ? 'save' : 'confirm_subscription_cancelation'
        })}
        confirmButtonProps={
          currentPlan?.cancelAtPeriodEnd
            ? { disabled: restoreLoading }
            : { disabled: cancelLoading, color: 'error' }
        }
        content={
          currentPlan?.cancelAtPeriodEnd
            ? <>
              {formatMessage({ id: 'restore_subsription_description' })}
              <TriangleMarkeredList
                itemsIntlIds={[
                  'restore_subsription_point.1',
                  'restore_subsription_point.2'
                ]}
              />
            </>
            : <>
              {formatMessage({ id: 'cancel_subsription_description' })}
              <TriangleMarkeredList
                itemsIntlIds={[
                  'cancel_subsription_point.1',
                  'cancel_subsription_point.2',
                  'cancel_subsription_point.3'
                ]}
              />
            </>
        }
        additionalButton={
          currentPlan?.cancelAtPeriodEnd
            ? <Button
              sx={{ mr: '16px' }}
              disabled={isConfirmed}
              variant='contained'
              color='error'
              type='button'
              onClick={() => setIsConfirmed(true)}
            >
              {formatMessage({ id: 'restore_subscription' })}
            </Button>
            : undefined
        }
        onConfirm={async () => {
          if (!currentPlan?.cancelAtPeriodEnd) {
            await cancelSubscription();
            toast(
              <SuccessSnackbar
                title={formatMessage({ id: 'subscription_canceled_successfully' })}
                message={formatMessage({ id: 'plan_canceled_access_will_lost' })}
              />
            );
          } else if (isConfirmed) {
            await restoreSubscription();
            toast(
              <SuccessSnackbar
                title={formatMessage({ id: 'subscription_activated_successfully' })}
                message={formatMessage({ id: 'subscription_is_active' })}
              />
            );
          }
          onClose();
        }}
      />
    </>
  );
};
