import { TextField } from '@mui/material';
import { type Title } from '__graphql__/globalTypes';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';

interface Props {
  label: string;
  value?: Title | string | null;
}

export const DetailsItem: FC<Props> = ({ label, value }) => {
  const { secondary } = usePalette();

  return (
    <TextField
      label={label}
      value={value || '-'}
      onChange={() => {}}
      disabled
      sx={{
        '& fieldset': {
          border: `1px solid ${secondary[500]} !important`
        },
        '& input.Mui-disabled': {
          color: secondary[700],
          '-webkit-text-fill-color': secondary[700]
        },
        '& label.Mui-disabled': {
          color: secondary[700]
        },
        '&:hover fieldset': {
          border: `1px solid ${secondary[500]} !important`
        }
      }}
    />
  );
};
