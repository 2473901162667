import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const ArrowIcon: FC<IconProps & { direction: 'up' | 'down' | 'left' }> = (props) => {
  const { color, direction } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  switch (direction) {
    case 'up':
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 15L12 9L6 15'
            stroke={currentColor}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      );
    case 'down':
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M6 9L12 15L18 9'
            stroke={currentColor}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      );
    case 'left':
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M19 12H5M12 19L5 12L12 5'
            stroke={currentColor}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      );
    default:
      return null;
  }
};
