import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Grid, IconButton, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import { ContractorStatus, type UpdateUserInput } from '__graphql__/globalTypes';
import { Audit, ContractorActivationDialog, ContractorRemovalDialog, Notes, TabPanel } from 'admin/components';
import dayjs from 'dayjs';
import { useCallback, useContext, useState, type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { contractorQuery, inviteResendMutation, updateUserMutation } from 'shared/api';
import { type Contractor, type ContractorVariables } from 'shared/api/__graphql__/Contractor';
import { type InviteResend, type InviteResendVariables } from 'shared/api/__graphql__/InviteResend';
import { type UpdateUser, type UpdateUserVariables } from 'shared/api/__graphql__/UpdateUser';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Card, CardContent, CardHeader, Footer, FormDialog, Loader } from 'shared/components';
import { ChevronIcon, CrossIcon, NoteIcon } from 'shared/components/icons';
import { ErrorSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { AuthContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { getContractorStatusColor } from 'shared/utils/colors';
import { getEmailFromValues, isAdmin } from 'shared/utils/users';
import { type Contractors_contractors_data } from '../Contractors/__graphql__/Contractors';
import { ContractorForm } from '../Contractors/AddContractorWidget';
import { DetailsItem } from '../RegistrationDetails/DetailsItem';
import { RelationsTabs } from './RelationsTabs';

export const ContractorDetails: FC = () => {
  const { formatMessage } = useIntl();
  const { primary, secondary, success, error, info } = usePalette();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);

  const [tab, setTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isUpdateActivation, setIsUpdateActivation] = useState(false);
  const [isNewNoteVisible, setIsNewNoteVisible] = useState(false);

  const { data } = useQuery<Contractor, ContractorVariables>(contractorQuery, {
    variables: { id },
    context: API_CONTEXT.AUTH,
    skip: !id
  });

  const contractor = data?.user;

  const [resendInviteMutation] = useMutation<InviteResend, InviteResendVariables>(inviteResendMutation, {
    context: API_CONTEXT.AUTH
  });

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(updateUserMutation, {
    refetchQueries: ['Contractor'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const openNewNoteInput = useCallback(() => {
    setTab(0);
    setIsNewNoteVisible(true);
  }, []);

  const closeNewNoteInput = useCallback(() => {
    setIsNewNoteVisible(false);
  }, []);

  const updateUserHandler = useAsyncCallback(async (data: UpdateUserInput) => {
    await updateUser({ variables: { data } });
  });

  const editUserHandler = useAsyncCallback(async (data: UpdateUserInput) => {
    await updateUser({ variables: { data } });
    setIsEdit(false);
    toast(<SuccessSnackbar title={formatMessage({ id: 'contractor_success_update' })} />);
  });

  const sendLink = useAsyncCallback(async () => {
    if (!contractor?.email) {
      toast(
        <ErrorSnackbar
          title={formatMessage({ id: 'no_email_in_contractor_info' })}
          message={formatMessage({ id: 'no_email_in_contractor_info_message' })}
        />,
        {
          position: toast.POSITION.BOTTOM_CENTER
        }
      );
      return;
    }
    await resendInviteMutation({ variables: { id } });
    toast(
      <SuccessSnackbar
        title={formatMessage({ id: 'access_link_was_successfully_sent' })}
        message={formatMessage({ id: 'the_user_received_new_link' })}
      />
    );
  });

  if (!id) {
    return <div>Wrong contractor</div>;
  }

  if (!contractor || sendLink.loading || updateUserHandler.loading) {
    return <Loader />;
  }

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          flexWrap='nowrap'
          bgcolor='#FFF'
          sx={{
            padding: '20px 48px 19px',
            borderBottom: `1px solid ${secondary[200]}`,
            '@media only screen and (max-width: 767px)': {
              paddingX: '16px 16px 11px'
            }
          }}
        >
          <Typography color='secondary.800' variant='h2'>
            {`${formatMessage({ id: 'contractor_id' })} ${id}`}
          </Typography>
          <IconButton onClick={() => navigate(-1)}>
            <CrossIcon />
          </IconButton>
        </Grid>

        <Grid container height='calc(100vh - 88px - 84px)' sx={{ overflowY: 'auto', '& > div': { overflowY: 'auto' } }}>
          <Grid item xs={8} sx={{ padding: '24px 48px 32px' }} bgcolor={info[200]}>
            <Card>
              <CardHeader>
                <Typography variant='subtitle1' color='secondary.800'>
                  {formatMessage({ id: 'basic_information' })}
                </Typography>
                <Grid sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                  {!!contractor?.lastLogin && (
                    <Typography color={secondary[600]}>
                      {formatMessage({ id: 'last_login' })} {dayjs(contractor.lastLogin).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      px: '30px',
                      height: '44px',
                      backgroundColor: contractor.isActive ? success[100] : error[100]
                    }}
                  >
                    <Box>{formatMessage({ id: contractor.isActive ? 'activated_user' : 'deactivated_user' })}</Box>
                  </Box>
                  <Select
                    sx={{
                      width: '224px',
                      mb: '0',
                      '& > div': {
                        borderRadius: '8px',
                        backgroundColor: getContractorStatusColor(contractor.status),
                        border: `1px solid ${getContractorStatusColor(contractor.status)}`,
                        '&[aria-expanded=true]': {
                          backgroundColor: '#FFFFFF',
                          border: `1px solid ${primary[600]}`
                        }
                      },
                      '& fieldset': {
                        border: 'none'
                      },
                      '& svg': {
                        color: secondary[700],
                        width: '24px',
                        height: '24px',
                        top: 'calc(50% - 12px)'
                      }
                    }}
                    value={contractor.status}
                    onChange={async ({ target }) => {
                      const status = target.value;
                      if (status !== contractor.status) {
                        await updateUserHandler.execute({
                          id: contractor.id,
                          status: target.value as ContractorStatus
                        });
                      }
                    }}
                    IconComponent={ChevronIcon}
                    MenuProps={{
                      MenuListProps: { sx: { border: 'none' } },
                      sx: {
                        '& .MuiPaper-root': {
                          border: `1px solid ${primary[600]}`,
                          borderRadius: '8px',
                          py: '4px',
                          mt: '0.5px'
                        }
                      }
                    }}
                  >
                    {Object.values(ContractorStatus).map((status) => (
                      <MenuItem
                        key={status}
                        value={status}
                        sx={{
                          '&, &:hover, &.Mui-selected, &.Mui-selected:hover': {
                            m: '8px 16px',
                            p: '8px 16px',
                            width: 'fit-content',
                            borderRadius: '4px',
                            backgroundColor: `${getContractorStatusColor(status)} !important`
                          }
                        }}
                      >
                        {formatMessage({ id: `contractor.status.${status as string}` })}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </CardHeader>
              <CardContent
                rowSpacing='20px'
                columnSpacing={{ xs: 0, sm: '12px', md: '12px' }}
                sx={{ paddingTop: '20px' }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <DetailsItem label={formatMessage({ id: 'title' })} value={contractor?.title} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <DetailsItem label={formatMessage({ id: 'phone_number' })} value={contractor?.phoneNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <DetailsItem label={formatMessage({ id: 'first_name' })} value={contractor?.firstName} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <DetailsItem label={formatMessage({ id: 'email' })} value={contractor?.email} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <DetailsItem label={formatMessage({ id: 'surname' })} value={contractor?.lastName} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <DetailsItem
                    label={formatMessage({ id: 'assigned_to' })}
                    value={`${contractor?.contractorManager?.firstName ?? ''} ${
                      contractor?.contractorManager?.lastName ?? ''
                    }`}
                  />
                </Grid>
              </CardContent>
            </Card>
            <RelationsTabs contractor={contractor} />
          </Grid>
          <Grid item xs={4} sx={{ borderLeft: `1px solid ${secondary[200]}` }} bgcolor={info[300]}>
            <Grid
              item
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              flexWrap='wrap'
              sx={{
                padding: '16px 24px',
                borderBottom: `1px solid ${secondary[200]}`
              }}
              bgcolor={info[200]}
            >
              <Tabs
                value={tab}
                onChange={(_, v) => {
                  setTab(v);
                }}
                aria-label='note tabs'
                sx={{
                  background: 'transparent',
                  padding: 0,
                  border: 'none',
                  borderRadius: 0,
                  '& .MuiTabs-flexContainer': {
                    gap: '8px'
                  },
                  '& button': {
                    borderRadius: '8px',
                    border: `1px solid ${secondary[400]}`,
                    bgcolor: info[200],
                    color: secondary[700],
                    '&.Mui-selected': {
                      bgcolor: primary[400],
                      color: secondary[800],
                      borderColor: primary[600]
                    }
                  }
                }}
              >
                <Tab label={formatMessage({ id: 'notes' })} id='notes' aria-controls='notes' />
                <Tab label={formatMessage({ id: 'audit_trail' })} id='audit_trail' aria-controls='audit_trail' />
              </Tabs>
              <Button variant='text' onClick={openNewNoteInput} sx={{ padding: '8px 24px' }}>
                <NoteIcon />
                <Box sx={{ ml: '10px' }}>{formatMessage({ id: 'add_note' })}</Box>
              </Button>
            </Grid>
            <Grid item sx={{ padding: '20px 24px' }}>
              <TabPanel value={tab} index={0}>
                <Notes
                  idData={{ userId: id }}
                  isNewNoteVisible={isNewNoteVisible}
                  openNewNoteInput={openNewNoteInput}
                  closeNewNoteInput={closeNewNoteInput}
                  emptyBoxIntlId='create_note_for_this_contractor'
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Audit userId={id} />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px'
            }}
          >
            <Button variant='contained' onClick={() => setIsEdit(true)}>
              {formatMessage({ id: 'edit' })}
            </Button>
            <Button variant='outlined' disabled={!contractor.isActive || !contractor.email} onClick={sendLink.execute}>
              {formatMessage({ id: 'send_link' })}
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px'
            }}
          >
            <Button variant='outlined' onClick={() => setIsUpdateActivation(true)}>
              {formatMessage({ id: contractor.isActive ? 'deactivate' : 'activate' })}
            </Button>
            {isAdmin(authState.user?.role) && (
              <Button variant='contained' color='error' onClick={() => setIsDelete(true)}>
                {formatMessage({ id: 'delete' })}
              </Button>
            )}
          </Box>
        </Box>
      </Footer>
      <FormDialog
        isOpen={isEdit}
        onClose={() => setIsEdit(false)}
        header={formatMessage({ id: 'edit_contractor' })}
        size='xs'
        loading={updateUserHandler.loading}
        confirmBtnName={formatMessage({ id: 'save' })}
        initialValues={contractor}
        onSubmit={async (values: Contractors_contractors_data) => {
          await editUserHandler.execute({
            id: values.id,
            title: values.title as any,
            assignedTo: values.assignedTo || null,
            ...getEmailFromValues(values),
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber
          });
        }}
      >
        <ContractorForm emailExists={!!contractor.email} />
      </FormDialog>
      <ContractorActivationDialog
        isOpen={isUpdateActivation}
        contractor={contractor}
        onClose={() => setIsUpdateActivation(false)}
      />
      <ContractorRemovalDialog isOpen={isDelete} contractor={contractor} onClose={() => setIsDelete(false)} />
    </Box>
  );
};
