import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';
import { InfoOctagonIcon } from '../icons';
import { type SnackbarProps } from './snackbar-props.interface';

const InformationSnackbar: FC<SnackbarProps> = ({ title, message, noIcon }) => {
  const { primary } = usePalette();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      gap={2}
      p={4}
      sx={{
        padding: '15px 23px 15px 16px',
        border: `1px solid ${primary[500]}`,
        borderRadius: '8px',
        background: '#FFF',
        '& svg': {
          minWidth: '24px',
          minHeight: '24px'
        }
      }}
    >
      <Box>
        {!!title && (
          <Typography variant='subtitle1' color='secondary.800' mb={1}>
            {title}
          </Typography>
        )}
        <Typography variant='body1' lineHeight='20px' color='secondary.800' mt={1}>
          {message}
        </Typography>
      </Box>
      {!noIcon && (
        <InfoOctagonIcon />
      )}
    </Box>
  );
};

export default InformationSnackbar;
