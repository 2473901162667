export const createLocalFile = (data: Blob, filename: string, returnUrl?: boolean): string | null => {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (window && window.URL) {
    const res = new Blob([data], {
      type: ''
    });
    const csvURL = window.URL.createObjectURL(res);
    if (returnUrl) return csvURL;
    const tempLink = document.createElement('a');
    document.body.appendChild(tempLink);
    tempLink.setAttribute('type', 'hidden');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
    tempLink.remove();
  }
  return null;
};

export const readFileAsync = async (file: File): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
};
