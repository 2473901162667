import { createContext } from 'react';

interface ITenantSearchContext {
  tenantSearch: string;
  setTenantSearch: (arg0: string) => void;
}

export const TenantSearchContext = createContext<ITenantSearchContext>({
  tenantSearch: '',
  setTenantSearch: () => {}
});
