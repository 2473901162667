import { useMutation } from '@apollo/client';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { type Action, QuestionComponent } from '__graphql__/globalTypes';
import { Field, Form, Formik } from 'formik';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Input, Loader } from 'shared/components';
import { CrossIcon, PencilIcon } from 'shared/components/icons';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { type Sections_sections_children } from 'shared/hooks/useRegistration/__graphql__/Sections';
import { Validator } from 'shared/utils/validator';
import { type SignDocument, type SignDocumentVariables } from './__graphql__/SignDocument';
import { signDocumentMutation } from './api';

interface Props {
  actions: Record<Action, boolean> | null;
  section: Sections_sections_children & { readonly?: boolean };
}

export const SignatureWidget: FC<Props> = ({ section, actions }) => {
  const { formatMessage } = useIntl();
  const [isOpened, setIsOpened] = useState(false);
  const { registration, documents, setPreviewFormValues, previewForm, previewFormValues } =
    useContext(RegistrationContext);
  const [signDocument] = useMutation<SignDocument, SignDocumentVariables>(signDocumentMutation, {
    context: API_CONTEXT.DOCUMENT,
    refetchQueries: ['DocumentsModal', 'RegistrationAnswers', 'PreviewDocument']
  });

  const questions = section.questions;

  const document = questions.find(({ component }) =>
    [QuestionComponent.ASSIGNMENT_SCHEDULE, QuestionComponent.CONTRACT, QuestionComponent.DOCUMENT].includes(component)
  );

  const isContract = document?.component === QuestionComponent.CONTRACT;
  const isAssignmentSchedule = document?.component === QuestionComponent.ASSIGNMENT_SCHEDULE;

  const docType = formatMessage({
    id: isAssignmentSchedule ? 'assignment_schedule' : isContract ? 'contract' : 'document'
  });

  const isSigned = previewForm.id
    ? !!previewFormValues[document?.id!]
    : !!documents.find((d) => {
        const fileNameWithoutExt = d.filename.split('.')[0];
        return fileNameWithoutExt === document?.id || fileNameWithoutExt === document?.component;
      });
  const disabled = isSigned || section.readonly || actions?.READ_ONLY;

  if (actions?.HIDE) {
    return null;
  }

  return (
    <>
      <Button
        disabled={disabled}
        size='medium'
        variant='outlined'
        onClick={() => {
          setIsOpened(true);
        }}
        sx={{ '@media only screen and (max-width: 479px)': { gridArea: 'sign' } }}
      >
        {formatMessage({ id: 'sign_document' })}
      </Button>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={isOpened}
        onClose={() => {
          setIsOpened(false);
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px'
          }
        }}
      >
        <Formik<{ signerName: string }>
          enableReinitialize
          initialValues={{ signerName: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            if (registration?.id) {
              await signDocument({
                variables: {
                  args: {
                    registrationId: registration?.id,
                    questionId: document?.id!,
                    signerName: values.signerName
                  }
                }
              });
            } else {
              setPreviewFormValues((prev: Record<string, any>) => ({ ...prev, [document?.id!]: 'signed_document' }));
            }
            setSubmitting(false);
            setIsOpened(false);
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <DialogTitle sx={{ p: '24px', pb: '16px' }}>
                <Typography variant='subtitle1' color='secondary.800' fontSize='24px' lineHeight='28px'>
                  {formatMessage({ id: 'document_signature' }, { docType })}
                </Typography>
                <IconButton
                  onClick={() => {
                    setIsOpened(false);
                  }}
                >
                  <CrossIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ p: '24px', pb: '16px' }}>
                <Typography variant='body1' color='secondary.800' lineHeight='20px'>
                  {formatMessage({ id: 'document_signature_body' }, { docType })}
                </Typography>
                <Field
                  component={Input}
                  name='signerName'
                  label={formatMessage({ id: 'fullName' })}
                  sx={{
                    marginTop: '16px',
                    width: '100%',
                    '& > *': {
                      width: '100%'
                    }
                  }}
                  validate={Validator.pipe(Validator.methods.required())}
                  error={errors.signerName && formatMessage({ id: 'please_fill_this_input_to_sign_the_document' })}
                />
              </DialogContent>
              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: '24px',
                  pb: '24px',
                  '@media only screen and (max-width: 479px)': {
                    flexDirection: 'column',
                    gap: '12px',
                    '& > button': {
                      width: '100%',
                      justifyContent: 'center'
                    }
                  }
                }}
              >
                <Button
                  size='small'
                  variant='text'
                  onClick={() => {
                    setIsOpened(false);
                  }}
                >
                  {formatMessage({ id: 'cancel' })}
                </Button>
                <Button size='large' variant='contained' type='submit' sx={{ width: 'auto' }}>
                  {isSubmitting ? (
                    <Loader />
                  ) : (
                    <>
                      {formatMessage({ id: 'document_sign' }, { docType: docType.toLowerCase() })}
                      <Box sx={{ ml: '12px', mr: '-12px', mt: '6px' }}>
                        <PencilIcon color='white' />
                      </Box>
                    </>
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
