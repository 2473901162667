import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const ActiveStarIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { warning } = usePalette();
  const currentColor = color ?? warning[200];

  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.9998 2.66675L20.1198 11.0134L29.3332 12.3601L22.6665 18.8534L24.2398 28.0267L15.9998 23.6934L7.75984 28.0267L9.33317 18.8534L2.6665 12.3601L11.8798 11.0134L15.9998 2.66675Z'
        fill={currentColor}
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
