import { createContext } from 'react';
import { type UseAsyncReturn } from 'react-async-hook';
import { type APIErrorCode } from 'shared/types';
import {
  AUTH_STATUS,
  type AuthError,
  type AuthState,
  type ChangeEmailParams,
  type ForgotPasswordParams,
  type InviteConfirmParams,
  type LoginParams,
  type ResetPasswordParams
} from 'shared/types/auth';

export interface AuthContextBase {
  login: UseAsyncReturn<void, [LoginParams]>;
  inviteConfirm: UseAsyncReturn<boolean, [InviteConfirmParams]>;
  logout: UseAsyncReturn<void, [options?: { openRoute?: string | undefined } | undefined]>;
  forgotPassword: UseAsyncReturn<boolean, [ForgotPasswordParams]>;
  resetPassword: UseAsyncReturn<boolean, [ResetPasswordParams]>;
  changeEmail: UseAsyncReturn<boolean, [ChangeEmailParams]>;
  fetchCurrentUser: () => Promise<void>;
  authState: AuthState;
  error?: AuthError;
  errorCode?: APIErrorCode;
  setError: (arg: any) => void;
}

interface AuthContextAnonymous extends AuthContextBase {
  authState: { status: Exclude<AUTH_STATUS, AUTH_STATUS.authenticated> };
}

export interface AuthContextAuthenticated extends AuthContextBase {
  authState: {
    status: AUTH_STATUS.authenticated;
    user: NonNullable<AuthState['user']>;
  };
}

export const isAuthenticated = (
  authContext: AuthContextBase
): authContext is AuthContextAuthenticated =>
  authContext.authState.status === AUTH_STATUS.authenticated;

export const isAnonymous = (authContext: AuthContextBase): authContext is AuthContextAnonymous =>
  authContext.authState.status === AUTH_STATUS.anonymous;

export const AuthContext = createContext<AuthContextBase>({
  authState: { status: AUTH_STATUS.anonymous },
  login: { execute: async () => { await Promise.resolve(); } } as any,
  inviteConfirm: { execute: async () => await Promise.resolve(false) } as any,
  forgotPassword: { execute: async () => await Promise.resolve(false) } as any,
  resetPassword: { execute: async () => await Promise.resolve(false) } as any,
  changeEmail: { execute: async () => await Promise.resolve(false) } as any,
  logout: { execute: async () => { await Promise.resolve(); } } as any,
  fetchCurrentUser: async () => { await Promise.resolve(); },
  error: undefined,
  setError: () => { },
  errorCode: undefined
});

export default AuthContext;
