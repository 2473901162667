import { Box } from '@mui/material';
import { Header } from 'consultant/components';
import { Dashboard } from 'consultant/pages';
import { Route, Routes } from 'react-router-dom';
import { usePalette } from 'shared/hooks';
import { ReferralModal } from './components/ReferralModal';

function App(): JSX.Element {
  const { info } = usePalette();
  return (
    <Box>
      <Header />
      <Box
        sx={{
          height: 'calc(100vh - 64px)',
          overflowY: 'auto',
          bgcolor: info[200]
        }}
      >
        <Routes>
          <Route index element={<Dashboard />} />
        </Routes>
      </Box>
      <ReferralModal />
    </Box>
  );
}

export default App;
