import { Box, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import {
  AgenciesIcon,
  ContactIcon,
  DashboardIcon,
  FormBuilderIcon,
  HomeIcon,
  PenToolIcon,
  ReportsIcon,
  TaskIcon,
  UsersIcon
} from 'shared/components/icons';
import routes from 'shared/constants/routes';
import { CompanyContext } from 'shared/contexts';
import { usePalette, useRoles, useTenantRoute } from 'shared/hooks';
import { localStorageUtils } from 'shared/utils/local-storage';
import { AttentionSnackbar } from '../Snackbars';

interface AdminDrawerListProps {
  open: boolean;
  hideMarker?: boolean;
}

export const AdminDrawerList: React.FC<AdminDrawerListProps> = ({ open, hideMarker }) => {
  const { formatMessage } = useIntl();
  const { warning, primary, secondary } = usePalette();
  const { mainRoute, navigateWithTenant } = useTenantRoute();
  const { isCustomerCare, isAdmin, isSuperAdmin } = useRoles();
  const { paymentFailureCode } = useContext(CompanyContext);
  const [isMarkerHidden, setIsMarkerHidden] = useState(hideMarker);

  const tenantMenu = useMemo(
    () => [
      {
        title: formatMessage({ id: 'dashboard' }),
        icon: <DashboardIcon />,
        route: routes.root
      },
      {
        title: formatMessage({ id: 'contractors' }),
        icon: <ContactIcon />,
        route: routes.contractors
      },
      {
        title: formatMessage({ id: 'tasks' }),
        icon: <TaskIcon />,
        route: routes.tasks
      },
      ...(isCustomerCare
        ? []
        : [
            {
              title: formatMessage({ id: 'reports' }),
              icon: <ReportsIcon />,
              route: routes.reports
            }
          ]),
      {
        title: formatMessage({ id: 'users' }),
        icon: <UsersIcon />,
        route: routes.users
      },
      {
        title: formatMessage({ id: 'agencies' }),
        icon: <AgenciesIcon />,
        route: routes.agencies
      },
      ...(isAdmin || isSuperAdmin
        ? [
            {
              title: formatMessage({ id: 'forms' }),
              icon: <FormBuilderIcon />,
              route: routes.forms
            },
            {
              title: formatMessage({ id: 'emails' }),
              icon: <PenToolIcon />,
              route: routes.emails,
              onClick: () => {
                setIsMarkerHidden(true);
                localStorageUtils.setProperty('warnings.emailsConfig.isMarkerHidden', true);
              },
              marker: (
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: warning[300],
                    position: 'absolute',
                    left: open ? '50%' : '65%',
                    top: open ? '20%' : '10%'
                  }}
                />
              )
            }
          ]
        : []),
      ...(isAdmin || isSuperAdmin
        ? [
            {
              title: formatMessage({ id: 'my_company' }),
              icon: <HomeIcon />,
              route: routes.myCompany
            }
          ]
        : [])
    ],
    [formatMessage, isCustomerCare, open, isAdmin, isSuperAdmin, warning]
  );

  return (
    <List
      sx={{
        border: 'none',
        p: '16px 16px 38px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: 2
      }}
    >
      {tenantMenu.map(({ title, icon, route, marker, onClick }) => (
        <ListItem key={title} disablePadding sx={{ mt: route === routes.myCompany ? 'auto' : 'unset' }}>
          <Button
            variant='text'
            onClick={() => {
              if (paymentFailureCode) {
                toast(
                  <AttentionSnackbar
                    title={formatMessage({ id: 'feature_unavailable' })}
                    message={formatMessage({ id: `feature_unavailable_${paymentFailureCode.toLowerCase()}` })}
                  />
                );
                return;
              }
              navigateWithTenant(route);
              if (onClick) onClick();
            }}
            disabled={route === '#'}
            sx={{
              width: '100%',
              padding: '10px 16px',
              color: secondary[800],
              justifyContent: open ? 'start' : 'center',
              ...(mainRoute === route
                ? {
                    backgroundColor: primary[200]
                  }
                : {})
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? '10px' : 'auto',
                justifyContent: 'center'
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={title}
              sx={{
                opacity: open ? 1 : 0,
                textAlign: 'start',
                '& > span': {
                  fontSize: '14px',
                  lineHeight: '20px'
                }
              }}
            />
            {!isMarkerHidden && marker}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};
