import { Box, Button, Popover } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { usePalette } from 'shared/hooks';

interface Props {
  items: Array<{
    id: string;
    onClick: () => Promise<void> | void;
    disabled?: boolean;
  }>;
  disabled?: boolean;
}

export function ActionsPopup({ items, disabled }: Props): JSX.Element {
  const { primary, info } = usePalette();
  const { formatMessage } = useIntl();
  const [_, setIsOpened] = useState(false);

  return (
    <PopupState variant='popover' popupId='popover'>
      {(popupState) => {
        const { onClick, onTouchStart, ...ariaProps } = bindTrigger(popupState);
        const { onClose, ...rest } = bindPopover(popupState);
        return (
          <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                disabled={disabled}
                sx={{ padding: 0, height: 'auto', justifyContent: 'center', color: info[600] }}
                variant='text'
                {...ariaProps}
                onClick={(event) => {
                  event.stopPropagation();
                  onClick(event);
                  setIsOpened(true);
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                  onTouchStart(event);
                }}
              >
                {'\u22EE'}
              </Button>
            </Box>
            <Popover
              {...rest}
              onClose={(e: any) => {
                e.stopPropagation();
                onClose();
                setIsOpened(false);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{
                '& .MuiPaper-rounded': { borderRadius: '8px' }
              }}
            >
              <Box
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'self-start',
                  border: `1px solid ${primary[600]}`,
                  borderRadius: '8px',
                  pY: '4px',
                  '& > button': {
                    width: '100%',
                    padding: '8px 16px'
                  }
                }}
              >
                {items.map(({ onClick, id, disabled }) => (
                  <Button
                    key={id}
                    disabled={disabled}
                    variant='text'
                    onClick={async (e) => {
                      e.stopPropagation();
                      await onClick();
                      popupState.close();
                      setIsOpened(false);
                    }}
                  >
                    {formatMessage({ id })}
                  </Button>
                ))}
              </Box>
            </Popover>
          </>
        );
      }}
    </PopupState>
  );
}
