import { Box, Button, Typography } from '@mui/material';
import { QuestionComponent, type OptionInput } from '__graphql__/globalTypes';
import { Field, FieldArray, useFormikContext, type FieldArrayRenderProps, type FormikConfig } from 'formik';
import { dissoc, insert, map, remove } from 'ramda';
import { useContext, type FC } from 'react';
import { DragDropContext, Draggable, type DraggableProvided } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { Input } from 'shared/components';
import { DragAndDropIcon, PlusIcon, TrashIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { StrictModeDroppable } from '../../StrictModeDroppable';
import { PropertyBlock } from './PropretyBlock';
import { type ComponentPropertiesProps } from './types';

interface OptionItemProps extends Partial<FieldArrayRenderProps> {
  provided?: DraggableProvided;
  index: number;
  isEdit?: boolean;
  withOptionHeader: boolean;
}

const Item: FC<OptionItemProps> = ({ provided, index, remove, isEdit, withOptionHeader }) => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const { values } = useFormikContext<{ options: OptionInput[] }>();

  const dragDropBtn = isEdit ? (
    <Box sx={{ mt: '8px' }}>
      <DragAndDropIcon />
    </Box>
  ) : null;

  const removeBtn = isEdit ? (
    <Button
      sx={{ minWidth: '44px', bgcolor: secondary[200] }}
      onClick={() => {
        remove?.(index);
      }}
    >
      <TrashIcon color={secondary[700]} />
    </Button>
  ) : null;

  const valueField = (
    <Field
      InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
      name={`options.${index}.value`}
      label={formatMessage({ id: 'ordered_option' }, { index: index + 1 })}
      component={Input}
    />
  );

  return (
    <Box
      {...provided?.dragHandleProps}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
      sx={{
        pb: '16px',
        display: 'flex',
        gap: '8px',
        cursor: isEdit ? 'grab' : 'default',
        ':last-of-type': {
          pb: 0
        }
      }}
    >
      {dragDropBtn}
      <Box sx={{ width: '100%', display: 'flex', gap: '8px', flexDirection: withOptionHeader ? 'column' : 'row' }}>
        {withOptionHeader ? (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '8px',
                pb: isEdit ? '8px' : '0px'
              }}
            >
              <Field
                InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                name={isEdit ? `options.${index}.label` : `options.${index}.value`}
                label={
                  isEdit
                    ? formatMessage({ id: 'ordered_option_header' }, { index: index + 1 })
                    : values.options?.[index]?.label
                }
                component={Input}
              />
              {removeBtn}
            </Box>
            {isEdit && valueField}
          </>
        ) : (
          <>
            {valueField}
            {removeBtn}
          </>
        )}
      </Box>
    </Box>
  );
};

const EditOptionsProperty: FC<Omit<ComponentPropertiesProps, 'onSubmit'> & { withOptionHeader: boolean }> = ({
  isReadOnly,
  withOptionHeader
}) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext<{ options: OptionInput[] }>();
  const value = formik.values.options;

  return (
    <DragDropContext
      onDragEnd={({ destination, draggableId }) => {
        if (destination) {
          const { index } = destination;
          const elementIndex = value.findIndex(({ optionId }) => optionId.toString() === draggableId);

          if (elementIndex === -1) {
            return;
          }

          let newArray = remove(elementIndex, 1, value);
          newArray = insert(index, value[elementIndex], newArray);

          formik.setFieldValue('options', newArray);
        }
      }}
    >
      <StrictModeDroppable droppableId='options'>
        {(provided) => (
          <Box sx={{ width: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
            <FieldArray
              name='options'
              render={(arrayHelpers) =>
                value.map((i, index) => (
                  <Draggable
                    isDragDisabled={isReadOnly}
                    key={i.optionId}
                    draggableId={i.optionId.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <Item
                        provided={provided}
                        index={index}
                        isEdit
                        withOptionHeader={withOptionHeader}
                        {...arrayHelpers}
                      />
                    )}
                  </Draggable>
                ))
              }
            />
            <Box>{provided.placeholder}</Box>
          </Box>
        )}
      </StrictModeDroppable>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Button
          onClick={() => {
            const optionId = (value.length + 1).toString();
            const newItemValue = `Option ${value.length + 1}`;
            formik.setFieldValue('options', [...value, { label: newItemValue, value: newItemValue, optionId }]);
          }}
        >
          <Typography variant='body2' sx={{ mr: '6px' }}>
            {formatMessage({ id: 'add_option' })}
          </Typography>
          <PlusIcon />
        </Button>
      </Box>
    </DragDropContext>
  );
};

export const OptionsProperty: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  const withOptionHeader = component?.component === QuestionComponent.OPTIONS_BIG;
  const items = component?.options ?? [];

  const handleSubmit: FormikConfig<{ options: OptionInput[] }>['onSubmit'] = async (values) => {
    const options = values.options.map(({ value, label, optionId }) => ({
      optionId,
      label: withOptionHeader ? label : value,
      value
    }));
    await onSubmit({ options });
  };

  return (
    <PropertyBlock
      onSubmit={handleSubmit}
      readOnly={isReadOnly}
      title={formatMessage({ id: 'component_properties.options' })}
      value={{ options: map(dissoc('__typename'), items) }}
      tooltip={formatMessage({ id: 'component_tooltips.options' })}
      ViewComponent={
        <>
          {items.map((i, index) => (
            <Item key={i.optionId} index={index} withOptionHeader={withOptionHeader} />
          ))}
        </>
      }
      EditComponent={<EditOptionsProperty isReadOnly={isReadOnly} withOptionHeader={withOptionHeader} />}
    />
  );
};
