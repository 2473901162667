import { useFormikContext } from 'formik';
import { path } from 'ramda';
import { type ChangeEvent, type FC } from 'react';
import { useIntl } from 'react-intl';
import { BaseInput } from 'shared/components/form/InputComponent';

export const TimeRangeInput: FC<any> = (props) => {
  const { name } = props.field;
  const { formatMessage } = useIntl();

  const { values, setFieldValue, errors } = useFormikContext<Record<string, string>>();

  const fieldValue = name ? path(name.split('.'), values) : '';
  const fieldError = name ? (path(name.split('.'), errors) as string) : '';

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    const formattedInput = formatTimeInput(inputValue, (e.nativeEvent as InputEvent).data);

    if (formattedInput !== null) {
      setFieldValue(name, formattedInput);
    }
  };

  const formatTimeInput = (input: string, enteredSymbol: string | null): string | null => {
    if (enteredSymbol === null) {
      let numberOfSymbolsToRemove = 0;

      switch (fieldValue.length) {
        case 19:
        case 17:
        case 16:
        case 14:
        case 6:
        case 3: {
          numberOfSymbolsToRemove = 2;
          break;
        }
        case 12:
        case 15:
        case 4:
        case 1: {
          numberOfSymbolsToRemove = 1;
          break;
        }
        case 11: {
          numberOfSymbolsToRemove = 5;
          break;
        }
      }

      return fieldValue.slice(0, fieldValue.length - numberOfSymbolsToRemove);
    }

    const from = {
      isHourFirstDigit: input.length === 1 && /[0,1]/.test(enteredSymbol),
      isHourSecondDigit:
        input.length === 2 &&
        ((input[0] === '0' && /[1-9]/.test(enteredSymbol)) || (input[0] === '1' && /[0,1,2]/.test(enteredSymbol))),
      isMinuteFirstDigit: input.length === 4 && /[0-5]/.test(enteredSymbol),
      isMinuteLastDigit: input.length === 5 && /[0-9]/.test(enteredSymbol),
      isAMPM: input.length === 7 && (enteredSymbol.toLowerCase() === 'a' || enteredSymbol.toLowerCase() === 'p')
    };

    const to = {
      isHourFirstDigit: input.length === 12 && /[0,1]/.test(enteredSymbol),
      isHourSecondDigit:
        input.length === 13 &&
        ((input[11] === '0' && /[0-9]/.test(enteredSymbol)) || (input[11] === '1' && /[0,1,2]/.test(enteredSymbol))),
      isMinuteFirstDigit: input.length === 15 && /[0-5]/.test(enteredSymbol),
      isMinuteLastDigit: input.length === 16 && /[0-9]/.test(enteredSymbol),
      isAMPM: input.length === 18 && (enteredSymbol.toLowerCase() === 'a' || enteredSymbol.toLowerCase() === 'p')
    };

    if (from.isHourFirstDigit || to.isHourFirstDigit) {
      return input;
    } else if (from.isHourSecondDigit || to.isHourSecondDigit) {
      return `${input}:`;
    } else if (from.isMinuteFirstDigit || to.isMinuteFirstDigit) {
      return input;
    } else if (from.isMinuteLastDigit || to.isMinuteLastDigit) {
      return `${input} `;
    } else if (from.isAMPM || to.isAMPM) {
      return `${input.slice(0, input.length - 1)}${enteredSymbol.toUpperCase()}M${input.length === 7 ? ' - ' : ''}`;
    } else if (input === '') {
      return input;
    }

    return null;
  };

  return (
    <BaseInput
      label={formatMessage({ id: 'value' })}
      disabled={props.disabled}
      value={fieldValue}
      error={!!fieldError}
      helperText={fieldError}
      onChange={onChange}
      placeholder='__:__ _M — __:__ _M'
    />
  );
};
