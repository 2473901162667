import { useQuery } from '@apollo/client';
import { OrderDirection } from '__graphql__/globalTypes';
import {
  type FormQuestions_formQuestions,
  type FormQuestions,
  type FormQuestionsVariables,
  type FormQuestions_formQuestions_section
} from 'admin/pages/Forms/__graphql__/FormQuestions';
import { formQuestionsQuery } from 'admin/pages/Forms/api';
import { prop } from 'ramda';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { useTablePagination, useTableSort, type TablePagination, type UseTableSort } from 'shared/hooks';
import { BASIC_INFO_QUESTIONS } from 'shared/hooks/useFormBuilder/constants';
import { type WithoutGQLType } from 'shared/types/utils';
import { TEXT_COMPONENTS } from '../../constants';

type Variables = Array<WithoutGQLType<FormQuestions_formQuestions>>;

const BASIC_INFO_QUESTIONS_VARIABLES = BASIC_INFO_QUESTIONS.reduce<Variables>(
  (result, { id, description, variable, component }) => {
    const isTextComponent = TEXT_COMPONENTS.includes(component);
    if (!isTextComponent) {
      result.push({
        id,
        section: { name: 'Basic info', id: 'basic_info', parentId: null } as FormQuestions_formQuestions_section,
        variable,
        description
      });
    }
    return result;
  },
  []
);

interface UseFormVariables {
  total: number;
  items: Variables;
  sorting: UseTableSort<QuestionVariablesOrderField>;
  pagination: TablePagination;
}

interface Props {
  aggregator?: (item: Variables[0]) => boolean;
  withBasicInfo?: boolean;
}

export enum QuestionVariablesOrderField {
  Variable = 'variable',
  SectionName = 'section.name',
  Description = 'description'
}

export function useFormVariables(props: Props = {}): UseFormVariables {
  const { aggregator, withBasicInfo } = props;
  const { formatMessage } = useIntl();
  const { form } = useContext(FormBuilderContext);
  const pagination = useTablePagination({ rowsPerPage: 7 });
  const sorting = useTableSort({
    defaultField: QuestionVariablesOrderField.Variable,
    defaultDirection: OrderDirection.asc
  });

  const { data } = useQuery<FormQuestions, FormQuestionsVariables>(formQuestionsQuery, {
    variables: { formId: form?.id! },
    skip: !form?.id
  });

  const allVariables = [
    ...(withBasicInfo ? BASIC_INFO_QUESTIONS_VARIABLES : []),
    ...(data?.formQuestions ?? []),
    ...(withBasicInfo
      ? [
          {
            id: 'Signature',
            section: {},
            description: formatMessage({ id: 'signature_description' }),
            variable: 'Signature'
          }
        ]
      : [])
  ] as Array<WithoutGQLType<FormQuestions_formQuestions>>;
  let total = allVariables.length;

  const sortedBy = sorting.field as QuestionVariablesOrderField;

  let aggregatedData = allVariables.sort((a, b) =>
    sorting.direction === OrderDirection.asc
      ? prop<string>(sortedBy, a)?.localeCompare(prop<string>(sortedBy, b))
      : prop<string>(sortedBy, b)?.localeCompare(prop<string>(sortedBy, a))
  );

  if (aggregator) {
    aggregatedData = aggregatedData.filter(aggregator);
    total = aggregatedData.length;
  }

  aggregatedData = aggregatedData.slice(pagination.offset, pagination.offset + pagination.limit);

  return {
    sorting,
    pagination,
    total,
    items: aggregatedData
  };
}
