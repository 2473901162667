import { useCallback, useMemo } from 'react';
import { type NavigateOptions, useMatch, useLocation } from 'react-router';
import routes from 'shared/constants/routes';
import { useStableNavigate } from '../useStableNavigate';

interface IUseTenantRoute {
  prefix: string;
  mainRoute: string;
  navigateWithTenant: (route: string, options?: NavigateOptions) => void;
}

export const useTenantRoute = (): IUseTenantRoute => {
  const navigate = useStableNavigate();
  const location = useLocation();
  const tenantViewMatch = useMatch(routes.tenantView + '*');
  const prefix = useMemo(() => tenantViewMatch
    ? tenantViewMatch.pathnameBase + '/'
    : '', [tenantViewMatch]);

  const mainRoute = useMemo(() => prefix
    ? location.pathname.replace(prefix, '')
    : location.pathname.substring(1), [prefix, location]);

  const navigateWithTenant = useCallback((route: string, options?: NavigateOptions) =>
    navigate(prefix + route, options), [prefix, navigate]);

  return {
    prefix,
    mainRoute,
    navigateWithTenant
  };
};
