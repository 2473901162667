import { useMutation } from '@apollo/client';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { FormStatus, QuestionComponent } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { useContext, useState, type FC } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ConfirmationDialog, Footer, Loader } from 'shared/components';
import { CrossIcon } from 'shared/components/icons';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import routes from 'shared/constants/routes';
import { FormBuilderContext } from 'shared/contexts';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette, useTenantNavigate } from 'shared/hooks';
import { getFormStatusColor } from 'shared/utils/colors';
import { type UpdateQuestionOrder, type UpdateQuestionOrderVariables } from '../__graphql__/UpdateQuestionOrder';
import { updateQuestionOrderMutation } from '../api';
import { getCreateComponentData } from '../utils';
import { Canvas } from './Canvas';
import { BASIC_INFO_SECTION, DroppableArea } from './constants';
import { Properties } from './Properties';
import { Structure } from './Structure';

export const ViewForm: FC = () => {
  const { secondary } = usePalette();
  const { formatMessage } = useIntl();
  const navigate = useTenantNavigate();
  const { setPreviewFormValues, setPreviewForm } = useContext(RegistrationContext);
  const { loading, form, fetchErrors, isReadOnly, submitForm, createQuestion, section, setDraggableId } =
    useContext(FormBuilderContext);

  const [isEmailWarningOpen, setIsEmailWarningOpen] = useState(false);

  const onPreview = (): void => {
    setPreviewForm({ name: form?.name!, id: form?.id! });
    setPreviewFormValues({ contractType: form?.contractType! });
  };

  const [updateQuestionOrder] = useMutation<UpdateQuestionOrder, UpdateQuestionOrderVariables>(
    updateQuestionOrderMutation,
    { refetchQueries: ['FormBuilderQuestions'], awaitRefetchQueries: true }
  );

  if (loading.formDetails) {
    return <Loader />;
  }

  const isPublished = form?.status === FormStatus.aPUBLISHED;

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          flexWrap='nowrap'
          bgcolor='#FFF'
          sx={{
            padding: '20px 48px 19px',
            borderBottom: `1px solid ${secondary[200]}`,
            '@media only screen and (max-width: 767px)': {
              paddingX: '16px 16px 11px'
            }
          }}
        >
          <Typography color='secondary.800' variant='h2'>
            {form?.name}
          </Typography>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <Box
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'secondary.100',
                  p: '8px 16px',
                  borderRadius: '8px'
                }}
              >
                <Typography sx={{ fontWeight: 400 }} variant='body2'>{`${formatMessage({ id: 'created_on' })}: ${dayjs(
                  form?.createdAt
                ).format('DD/MM/YYYY')}`}</Typography>
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                  backgroundColor: getFormStatusColor(form?.status),
                  p: '8px 16px',
                  borderRadius: '8px'
                }}
              >
                <Typography sx={{ fontWeight: 400 }} variant='body2'>
                  {formatMessage({ id: `form.status.${form?.status}` })}
                </Typography>
              </Box>
            </Box>
            <IconButton onClick={() => navigate(routes.forms)}>
              <CrossIcon />
            </IconButton>
          </Box>
        </Grid>

        <Grid container height='calc(100vh - 88px - 84px)' sx={{ gap: '16px', overflowY: 'hidden', p: '24px 48px' }}>
          <DragDropContext
            onBeforeCapture={({ draggableId }) => {
              setDraggableId(draggableId);
            }}
            onDragEnd={async ({ destination, draggableId }) => {
              if (
                isReadOnly ||
                destination?.droppableId !== DroppableArea.Canvas ||
                section?.id === BASIC_INFO_SECTION.id
              ) {
                return;
              }

              setDraggableId('');

              const data = getCreateComponentData({
                component: draggableId as QuestionComponent,
                sectionId: section?.id!,
                order: destination.index + 1
              });

              if (Object.values(QuestionComponent).includes(draggableId as QuestionComponent)) {
                await createQuestion({ variables: { data } });
              } else {
                await updateQuestionOrder({ variables: { id: draggableId, order: destination.index + 1 } });
              }
            }}
          >
            <Structure />
            <Canvas />
          </DragDropContext>
          <Properties />
        </Grid>
      </Box>
      <Footer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Button onClick={() => navigate(-1)}>{formatMessage({ id: 'cancel' })}</Button>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Button variant='outlined' onClick={onPreview}>
              {formatMessage({ id: 'preview' })}
            </Button>
            <Button
              variant='contained'
              color={isPublished ? 'error' : 'primary'}
              onClick={async () => {
                const status = isPublished ? FormStatus.bUNPUBLISHED : FormStatus.aPUBLISHED;

                if (!isPublished) {
                  const formErrors = await fetchErrors(true);
                  if (Object.keys(formErrors).length) {
                    formErrors.forEach(({ sectionName, error }) => {
                      toast(
                        sectionName ? (
                          <AttentionSnackbar
                            title={formatMessage({ id: 'section_need_to_be_fixed_title' }, { sectionName })}
                            message={formatMessage({ id: `publish_form_${error}` }, { sectionName })}
                          />
                        ) : (
                          <AttentionSnackbar
                            title={formatMessage({ id: 'form_need_to_be_fixed_title' })}
                            message={formatMessage({ id: `publish_form_${error}` })}
                          />
                        )
                      );
                    });
                    return;
                  }
                } else if (form.status === FormStatus.cDRAFT) {
                  setIsEmailWarningOpen(true);
                  return;
                }

                await submitForm({
                  variables: { data: { id: form?.id, name: form?.name!, status } },
                  refetchQueries: ['FormDetails'],
                  awaitRefetchQueries: true
                });
              }}
            >
              {formatMessage({ id: isPublished ? 'unpublish' : 'publish' })}
            </Button>
          </Box>
          {isEmailWarningOpen && (
            <ConfirmationDialog
              open
              onClose={() => {
                setIsEmailWarningOpen(false);
              }}
              onConfirm={async () => {
                await submitForm({
                  variables: { data: { id: form?.id, name: form?.name!, status: FormStatus.aPUBLISHED } },
                  refetchQueries: ['FormDetails'],
                  awaitRefetchQueries: true
                });
                toast(
                  <AttentionSnackbar
                    title={formatMessage({ id: 'email_update_need_title' })}
                    message={formatMessage({ id: 'email_update_need_message' })}
                  />
                );
              }}
              confirmButtonContent={formatMessage({ id: 'got_it' })}
              title={formatMessage({ id: 'email_template_warning' })}
              content={formatMessage({ id: 'email_template_warning_message' })}
            />
          )}
        </Box>
      </Footer>
    </Box>
  );
};
