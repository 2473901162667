import { useLazyQuery, useMutation } from '@apollo/client';
import { DocumentRelation, DocumentType, Role } from '__graphql__/globalTypes';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import {
  createDocumentMutation,
  requestEmailChangeMutation,
  updateSuperAdminMutation,
  updateUserMutation,
  uploadDocumentMutation
} from 'shared/api';
import { type CreateDocument, type CreateDocumentVariables } from 'shared/api/__graphql__/CreateDocument';
import { type RequestEmailChange, type RequestEmailChangeVariables } from 'shared/api/__graphql__/RequestEmailChange';
import { type UpdateSuperAdmin, type UpdateSuperAdminVariables } from 'shared/api/__graphql__/UpdateSuperAdmin';
import { type UpdateUser, type UpdateUserVariables } from 'shared/api/__graphql__/UpdateUser';
import { type UploadDocument, type UploadDocumentVariables } from 'shared/api/__graphql__/UploadDocument';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { type SnackbarProps } from 'shared/components/Snackbars';
import { FIVE_SECONDS_LOGOUT } from 'shared/constants';
import { type UserProfileInfo } from 'shared/contexts';
import {
  type ProfilePhotoUrl,
  type ProfilePhotoUrlVariables
} from 'shared/hooks/useProfileInfo/__graphql__/ProfilePhotoUrl';
import { profilePhotoQuery, profilePhotoUrlQuery } from 'shared/hooks/useProfileInfo/api';
import { CONFLICT_ERROR } from 'shared/utils/errors';
import { type FieldError, type UseChangeProfileInfo, type UseChangeProfileInfoParams } from './interfaces';

export function useChangeProfileInfo(params: UseChangeProfileInfoParams): UseChangeProfileInfo {
  const { logout, user, updateUserInfo } = params;

  const { formatMessage } = useIntl();
  const [avatar, setAvatar] = useState<File | null>(null);

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(updateUserMutation, {
    context: API_CONTEXT.AUTH
  });

  const [updateSuperAdmin] = useMutation<UpdateSuperAdmin, UpdateSuperAdminVariables>(updateSuperAdminMutation, {
    context: API_CONTEXT.SUPERADMIN
  });

  const [requestEmailChange] = useMutation<RequestEmailChange, RequestEmailChangeVariables>(
    requestEmailChangeMutation, {
    context: API_CONTEXT.AUTH
  });

  const [createDocument] = useMutation<CreateDocument, CreateDocumentVariables>(createDocumentMutation, {
    context: API_CONTEXT.DOCUMENT
  });

  const [uploadPhoto] = useMutation<UploadDocument, UploadDocumentVariables>(uploadDocumentMutation, {
    context: API_CONTEXT.DOCUMENT,
    refetchQueries: [profilePhotoQuery],
    awaitRefetchQueries: true
  });

  const [fetchPhotoUrl] = useLazyQuery<ProfilePhotoUrl, ProfilePhotoUrlVariables>(profilePhotoUrlQuery, {
    context: API_CONTEXT.DOCUMENT
  });

  const updateProfile = useAsyncCallback(
    async (userProfileInfo: UserProfileInfo): Promise<SnackbarProps | null> => {
      const { email, role, ...userInfo } = userProfileInfo;
      const isSuperAdmin = role === Role.SUPER_ADMIN;
      const requests: Array<Promise<any>> = [
        isSuperAdmin
          ? updateSuperAdmin({ variables: { data: { ...userInfo, email } } })
          : updateUser({ variables: { data: userInfo } })
      ];
      const isEmailChanged = user.email !== email;
      if (isEmailChanged && !isSuperAdmin) {
        requests.push(requestEmailChange({ variables: { newEmail: email } }));
      }

      if (avatar) {
        if (user.documentId) {
          Object.defineProperty(avatar, 'name', {
            writable: true,
            value: `${user.id}|${user.documentId}|${avatar.name}`
          });
          requests.push(
            uploadPhoto({ variables: { file: avatar } }).then(async () => {
              const { data } = await fetchPhotoUrl({ variables: { id: user.documentId! } });
              return { avatarUrl: data?.avatarUrl, documentId: user.documentId };
            })
          );
        } else {
          requests.push(
            createDocument({
              variables: {
                data: {
                  contentType: avatar.type,
                  documentType: DocumentType.PROFILE_PHOTO,
                  filename: avatar.name,
                  relatedId: user.id,
                  relatedTo: DocumentRelation.USER
                }
              }
            }).then(async ({ data }) => {
              Object.defineProperty(avatar, 'name', {
                writable: true,
                value: `${user.id}|${data?.createDocument.id}`
              });
              return await uploadPhoto({ variables: { file: avatar } });
            })
          );
        }
      }

      await Promise.all(requests)
        .then(([_, photoUpdates]) => {
          updateUserInfo({ ...userProfileInfo, ...(photoUpdates ?? {}) });
        })
        .catch((err) => {
          updateProfile.status = 'error';
          updateProfile.error = new Error(err.graphQLErrors[0].extensions.code);
        });

      if (updateProfile.status === 'error') {
        return null;
      }

      if (isEmailChanged && !isSuperAdmin) {
        setTimeout(async () => {
          await logout.execute();
        }, FIVE_SECONDS_LOGOUT);

        return {
          title: formatMessage({ id: 'email_updated' }),
          message: formatMessage({ id: 'logout_follow_email_instruction' })
        };
      }

      return {
        title: formatMessage({ id: 'profile_success_update' })
      };
    });

  const getFieldError = (): FieldError | null => {
    if (updateProfile.error?.message === CONFLICT_ERROR) {
      return { field: 'email', message: formatMessage({ id: 'email_already_in_system' }) };
    }
    return null;
  };

  return {
    changeAvatarFile: (file: File) => {
      setAvatar(file);
    },
    getFieldError,
    updateProfile
  };
}
