import { Operator, QuestionComponent } from '__graphql__/globalTypes';

const DATE_TIME_OPERATORS = [Operator.IS, Operator.BEFORE, Operator.AFTER];
const STANDART_OPERATORS = [Operator.IS, Operator.IS_NOT];
const INPUT_OPERATORS = [...STANDART_OPERATORS, Operator.INCLUDE, Operator.NOT_INCLUDE];
const SINGLE_STATE_OPERATORS = [Operator.IS];
const MULTIPLE_OPERATORS = [...INPUT_OPERATORS];

export const OPERATOR_OPTIONS_BY_COMPONENT: Record<QuestionComponent, Operator[]> = {
  [QuestionComponent.SHORT_TEXT]: INPUT_OPERATORS,
  [QuestionComponent.LONG_TEXT]: INPUT_OPERATORS,
  [QuestionComponent.SINGLE_SELECT]: STANDART_OPERATORS,
  [QuestionComponent.OPTIONS_SMALL]: STANDART_OPERATORS,
  [QuestionComponent.OPTIONS_BIG]: STANDART_OPERATORS,
  [QuestionComponent.MULTIPLE_SELECT]: MULTIPLE_OPERATORS,
  [QuestionComponent.CHECKBOX]: SINGLE_STATE_OPERATORS,
  [QuestionComponent.DATE]: DATE_TIME_OPERATORS,
  [QuestionComponent.DAY]: MULTIPLE_OPERATORS,
  [QuestionComponent.TIME]: DATE_TIME_OPERATORS,
  [QuestionComponent.TIME_PICKER]: [Operator.BEYOND, Operator.WITHIN],
  [QuestionComponent.CONTRACT]: SINGLE_STATE_OPERATORS,
  [QuestionComponent.ASSIGNMENT_SCHEDULE]: SINGLE_STATE_OPERATORS,
  [QuestionComponent.DOCUMENT]: SINGLE_STATE_OPERATORS,
  [QuestionComponent.FILE_UPLOAD]: SINGLE_STATE_OPERATORS,
  [QuestionComponent.HEADER_1]: [],
  [QuestionComponent.HEADER_2]: [],
  [QuestionComponent.HEADER_3]: [],
  [QuestionComponent.PARAGRAPH]: []
};

export const MAX_RULES = 10;
