import { useMutation, useQuery } from '@apollo/client';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ContractorStatus, OrderDirection, UserOrderField, type UpdateUserInput } from '__graphql__/globalTypes';
import { ContractorActivationDialog, ContractorRemovalDialog } from 'admin/components';
import { useCallback, useContext, useEffect, useMemo, useState, type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { inviteResendMutation, updateUserMutation } from 'shared/api';
import { type InviteResend, type InviteResendVariables } from 'shared/api/__graphql__/InviteResend';
import { type UpdateUser, type UpdateUserVariables } from 'shared/api/__graphql__/UpdateUser';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { FormDialog, Loader, PageTitle, PageWrapper, SelectFilter } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { StyledTablePagination } from 'shared/components/StyledTablePagination';
import AuthContext from 'shared/contexts/authContext';
import { useFiltersQuery, usePalette, useTablePagination, useTableSort } from 'shared/hooks';
import { OwnerOption } from 'shared/types';
import { getEmailFromValues } from 'shared/utils/users';
import {
  type Contractors as ContractorsQuery,
  type ContractorsVariables,
  type Contractors_contractors_data
} from './__graphql__/Contractors';
import { AddContractorWidget, ContractorForm } from './AddContractorWidget';
import { contractorsQuery } from './api';
import { ContractorsTable } from './ContractorsTable';

export const Contractors: FC = () => {
  const intl = useIntl();
  const { primary, secondary } = usePalette();

  const { authState } = useContext(AuthContext);
  const { parsedQuery, pushQuery } = useFiltersQuery();
  const location = useLocation();

  const { offset, limit, ...tablePaginationProps } = useTablePagination({
    defaultPage: parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0
  });
  const sort = useTableSort<UserOrderField>({
    defaultField: (parsedQuery?.sortBy ?? UserOrderField.firstName) as UserOrderField,
    defaultDirection: (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
  });

  const [statuses, setStatuses] = useState<ContractorStatus[]>(
    (parsedQuery?.statuses as ContractorStatus[]) ?? [ContractorStatus.CURRENT]
  );
  const [owner, setOwner] = useState<OwnerOption>((parsedQuery?.owner as OwnerOption) ?? OwnerOption.all);
  const [userForActivation, setUserForActivation] = useState<Contractors_contractors_data | undefined>();
  const [userForEdit, setUserForEdit] = useState<Contractors_contractors_data | undefined>();
  const [userForDelete, setUserForDelete] = useState<Contractors_contractors_data | undefined>();

  const statusOptions = useMemo(
    () =>
      Object.values(ContractorStatus).map((r) => ({
        value: r,
        label: intl.formatMessage({ id: `contractor.status.${r}` })
      })),
    [intl]
  );

  const ownerOptions = useMemo(() => Object.values(OwnerOption).reverse(), []);

  const result = useQuery<ContractorsQuery, ContractorsVariables>(contractorsQuery, {
    variables: {
      args: {
        orderBy:
          sort?.field && sort?.direction
            ? {
                field: sort.field,
                direction: sort.direction
              }
            : null,
        pagination: {
          offset,
          limit
        },
        filters: {
          ...(statuses?.length ? { statuses } : {}),
          ...(owner === OwnerOption.my ? { assignedTo: authState.user?.id } : {}),
          ...(location.state?.search ? { search: location.state.search.toLowerCase() } : {})
        }
      }
    },
    context: API_CONTEXT.AUTH
  });

  useEffect(() => {
    pushQuery(
      {
        filters: [
          ...(statuses?.length
            ? [
                {
                  id: 'statuses',
                  value: statuses
                }
              ]
            : []),
          { id: 'owner', value: owner }
        ],
        sort: [
          ...(sort?.field
            ? [
                {
                  id: sort?.field,
                  desc: sort?.direction === OrderDirection.desc
                }
              ]
            : [])
        ],
        pageIndex: tablePaginationProps.page
      },
      location.state?.search ? { search: location.state?.search } : undefined
    );
  }, [sort?.direction, sort?.field, pushQuery, statuses, owner, tablePaginationProps.page, location.state?.search]);

  useEffect(() => {
    tablePaginationProps.onPageChange(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses?.length, owner]);

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(updateUserMutation, {
    refetchQueries: ['Contractors'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const [inviteResend] = useMutation<InviteResend, InviteResendVariables>(inviteResendMutation, {
    context: API_CONTEXT.AUTH
  });

  const sendLink = useCallback(
    async (id: string) => {
      try {
        await inviteResend({ variables: { id } });
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'access_link_was_successfully_sent' })}
            message={intl.formatMessage({ id: 'the_user_received_new_link' })}
          />
        );
      } catch (e) {
        console.log('Error: ', e);
      }
    },
    [intl, inviteResend]
  );

  const handleCloseActivatingModal = useCallback(() => {
    setUserForActivation(undefined);
  }, []);

  const handleCloseDeletingModal = useCallback(() => {
    setUserForDelete(undefined);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setUserForEdit(undefined);
  }, []);

  const handleUpdateUser = useAsyncCallback(async (data: UpdateUserInput) => {
    await updateUser({ variables: { data } });
    handleCloseActivatingModal();
    handleCloseEditModal();
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle>
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '& > *:not(:last-child)': {
                  marginRight: '16px'
                }
              }}
            >
              <ToggleButtonGroup
                value={owner}
                exclusive
                onChange={(e, v) => {
                  if (v !== null) {
                    setOwner(v);
                  }
                }}
                orientation='horizontal'
                sx={{
                  color: secondary[800],
                  flexWrap: 'wrap',
                  gap: '8px',
                  '& > button.Mui-selected': {
                    backgroundColor: `${primary[400]} !important`,
                    borderColor: `${primary[600]} !important`
                  }
                }}
              >
                {ownerOptions.map((o) => (
                  <ToggleButton key={o} value={o}>
                    {intl.formatMessage({ id: `${o}_contractors` })}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <SelectFilter
                defaultValue={[ContractorStatus.CURRENT]}
                values={statuses}
                placeholder={intl.formatMessage({ id: 'status' })}
                allItemLabel={intl.formatMessage({ id: 'all_statuses' })}
                options={statusOptions}
                onApply={setStatuses}
              />
            </Box>
            <AddContractorWidget />
          </>
        </PageTitle>
        {result.loading || handleUpdateUser.loading ? (
          <Loader />
        ) : (
          <PageWrapper>
            <>
              <ContractorsTable
                data={result.data?.contractors?.data}
                openDeleteModal={setUserForDelete}
                openModal={setUserForEdit}
                sendLink={sendLink}
                page={tablePaginationProps.page}
                rowsPerPage={tablePaginationProps.rowsPerPage}
                sort={sort}
                setUserForActivation={setUserForActivation}
              />
              <StyledTablePagination count={result.data?.contractors.total ?? 0} {...tablePaginationProps} />
              <ContractorActivationDialog
                isOpen={!!userForActivation}
                onClose={handleCloseActivatingModal}
                contractor={userForActivation as Contractors_contractors_data}
              />
              <ContractorRemovalDialog
                isOpen={!!userForDelete}
                onClose={handleCloseDeletingModal}
                contractor={userForDelete as Contractors_contractors_data}
              />
              <FormDialog
                isOpen={!!userForEdit}
                onClose={handleCloseEditModal}
                header={intl.formatMessage({ id: 'edit_contractor' })}
                size='xs'
                loading={handleUpdateUser.loading}
                confirmBtnName={intl.formatMessage({ id: 'save' })}
                initialValues={userForEdit}
                onSubmit={async (values: Contractors_contractors_data) => {
                  await handleUpdateUser.execute({
                    id: values.id,
                    title: values.title as any,
                    assignedTo: values.assignedTo || null,
                    ...getEmailFromValues(values),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber
                  });
                  toast(<SuccessSnackbar title={intl.formatMessage({ id: 'contractor_success_update' })} />);
                }}
              >
                <ContractorForm emailExists={!!userForEdit?.email} />
              </FormDialog>
            </>
          </PageWrapper>
        )}
      </Box>
    </Box>
  );
};
