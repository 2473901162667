import { Role } from '__graphql__/globalTypes';

export function getEmailFromValues(values: Record<string, any>): Record<string, string> {
  return values.email ? { email: values.email } : {};
}

export function getDisplayRole(role: string): string {
  if (!role) {
    return '';
  }
  return role[0] + role.substring(1).replaceAll('_', ' ').toLowerCase();
}

export function isAdmin(role: Role | undefined): boolean {
  if (role) {
    const ADMIN_ROLES = [Role.SUPER_ADMIN, Role.ADMIN];
    return ADMIN_ROLES.includes(role);
  }
  return false;
}

export function isStaffUser(role: Role | undefined): boolean {
  if (role) {
    const ADMIN_ROLES = [Role.SUPER_ADMIN, Role.ADMIN, Role.CUSTOMER_CARE, Role.REGISTRAR];
    return ADMIN_ROLES.includes(role);
  }
  return false;
}
