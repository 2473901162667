import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const BurgerIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { secondary } = usePalette();
  const currentColor = color ?? secondary[700];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='3' y='6' width='19' height='2' rx='1' fill={currentColor} />
      <rect x='3' y='11' width='19' height='2' rx='1' fill={currentColor} />
      <rect x='3' y='16' width='19' height='2' rx='1' fill={currentColor} />
    </svg>
  );
};
