import { FormControlLabel, type SxProps, type Theme } from '@mui/material';
import { Field, FormikContext, type FieldProps } from 'formik';
import { path, split } from 'ramda';
import { useContext, type FC, useState } from 'react';
import { CustomCheckbox } from 'shared/components';
import { usePalette } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { type FormComponentProps } from './types';

interface Props extends FormComponentProps, Partial<FieldProps> {
  sx?: SxProps<Theme>;
  onChange?: (v: boolean) => Promise<void>;
}

const BaseCheckbox: FC<Props & Partial<FieldProps>> = (props) => {
  const { error } = usePalette();
  const { required: initialRequired, text, disabled, sx = {}, field, form, onChange } = props;
  const required = initialRequired ?? false;

  return (
    <FormControlLabel
      disabled={disabled}
      sx={{
        ...sx,
        '& .MuiFormControlLabel-asterisk': {
          color: error[300]
        }
      }}
      onChange={(e: any) => {
        if (form && field) {
          if (form.errors[field.name]) form.setFieldError(field.name, undefined);
          form.setFieldValue(field.name, e.target.checked);
        }
        onChange?.(e.target.checked);
      }}
      control={
        <CustomCheckbox
          required={required}
          checked={field?.value === 'true' || (field?.value as unknown as boolean) || false}
        />
      }
      required={required}
      label={text}
    />
  );
};

export const CheckboxComponent: FC<Props> = (props) => {
  const formik = useContext(FormikContext);
  const [value, setValue] = useState<boolean>(false);

  const { id, validate, required: initialRequired, field } = props;
  const error = path(split('.', (id || field?.name) ?? ''), formik?.errors) as string;
  const required = initialRequired ?? false;

  return formik ? (
    <Field
      {...props}
      component={BaseCheckbox}
      name={id}
      error={error}
      validate={validate || (required && Validator.pipe(Validator.methods.required()))}
    />
  ) : (
    <BaseCheckbox
      {...props}
      onChange={async (v: boolean) => {
        setValue(v);
      }}
      field={{ value } as FieldProps['field']}
    />
  );
};
