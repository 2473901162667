const routes = {
  login: '/login',
  inviteConfirm: '/invite-confirm/:token',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token',
  changeEmail: '/change-email/:token',
  root: '',
  rootForNavigation: '/',
  registration: ':id',
  users: 'users',
  contractors: 'contractors',
  contractor: 'contractors/:id',
  reports: 'reports',
  tenant: ':id',
  tenantView: 'tenant/:tenantId/',
  superAdmins: 'super-admins',
  agencies: 'agencies',
  forms: 'forms',
  form: 'forms/:id',
  tasks: 'tasks',
  emails: 'emails',
  myCompany: 'my-company',
  newReferral: 'new-referral/:token',
  analytics: 'analytics'
};

export default routes;
