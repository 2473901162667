import { type FormikProps, useFormikContext } from 'formik';
import { type FC, useContext, useEffect } from 'react';
import { RegistrationContext } from 'shared/contexts/registrationContext';

interface Props {
  submit?: (
    formikProps: Pick<FormikProps<Record<string, string | string[]>>, 'validateForm' | 'dirty' | 'submitForm'>
  ) => Promise<boolean>;
}

export const FormikListener: FC<Props> = ({ submit }) => {
  const { setIsDirty, setSectionValues } = useContext(RegistrationContext);

  const { dirty, submitForm, errors, values, validateForm } = useFormikContext<Record<string, string | string[]>>();

  useEffect(() => {
    setIsDirty([dirty, submit ? () => submit({ validateForm, submitForm, dirty }) : submitForm]);
  }, [dirty, errors, setIsDirty, submit, submitForm, validateForm]);

  useEffect(() => {
    setSectionValues(values);
  }, [setSectionValues, values]);

  return <></>;
};
