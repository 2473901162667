import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'shared/components';
import { CrossIcon } from 'shared/components/icons';
import { usePalette } from 'shared/hooks';
import { useFormVariables } from '../hooks';
import { VariablesTable } from '../shared';

export const FormVariablesProp: FC = () => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const [isOpen, setIsOpen] = useState(false);
  const formVariablesData = useFormVariables({ withBasicInfo: true });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: 'secondary.50',
          borderRadius: '8px',
          p: '16px',
          border: `1px solid ${secondary[200]}`
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: '12px' }}>
          <Typography variant='body1' sx={{ color: 'secondary.600' }}>
            {formatMessage({ id: 'component_properties.form_variables' })}
          </Typography>
          <Tooltip text={formatMessage({ id: 'component_tooltips.form_variables' })} />
        </Box>
        <Button variant='outlined' onClick={() => setIsOpen(true)}>
          {formatMessage({ id: 'view_vars' })}
        </Button>
      </Box>
      <Dialog
        fullWidth
        maxWidth='md'
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle>
          <Typography variant='h6' color='secondary.800' sx={{ lineHeight: '28px' }}>
            {formatMessage({ id: 'component_properties.form_variables' })}
          </Typography>
          <IconButton
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <VariablesTable {...formVariablesData} />
        </DialogContent>
      </Dialog>
    </>
  );
};
