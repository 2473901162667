import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const FormBuilderIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 3V5C3 6.1 3.9 7 5 7H8M13 10H20C20.2652 10 20.5196 9.89464 20.7071 9.70711C20.8946 9.51957 21 9.26522 21 9V6C21 5.73478 20.8946 5.48043 20.7071 5.29289C20.5196 5.10536 20.2652 5 20 5H17.5C17.3448 5 17.1916 4.96386 17.0528 4.89443C16.9139 4.825 16.7931 4.7242 16.7 4.6L15.8 3.4C15.7069 3.2758 15.5861 3.175 15.4472 3.10557C15.3084 3.03614 15.1552 3 15 3H13C12.7348 3 12.4804 3.10536 12.2929 3.29289C12.1054 3.48043 12 3.73478 12 4V9C12 9.26522 12.1054 9.51957 12.2929 9.70711C12.4804 9.89464 12.7348 10 13 10ZM13 21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V17C21 16.7348 20.8946 16.4804 20.7071 16.2929C20.5196 16.1054 20.2652 16 20 16H17.12C16.9336 16.0013 16.7506 15.9505 16.5916 15.8533C16.4326 15.7561 16.3039 15.6164 16.22 15.45L15.78 14.55C15.6961 14.3836 15.5674 14.2439 15.4084 14.1467C15.2494 14.0495 15.0664 13.9987 14.88 14H13C12.7348 14 12.4804 14.1054 12.2929 14.2929C12.1054 14.4804 12 14.7348 12 15V20C12 20.2652 12.1054 20.5196 12.2929 20.7071C12.4804 20.8946 12.7348 21 13 21Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 3V16C3 17.1 3.9 18 5 18H8'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
