import { useMutation } from '@apollo/client';
import { type Contractors_contractors_data } from 'admin/pages/Contractors/__graphql__/Contractors';
import { type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { deleteUserMutation } from 'shared/api';
import { type DeleteUser, type DeleteUserVariables } from 'shared/api/__graphql__/DeleteUser';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { ConfirmationDialog, TriangleMarkeredList } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { getDisplayRole } from 'shared/utils/users';

interface Props {
  isOpen: boolean;
  contractor: Pick<Contractors_contractors_data, 'firstName' | 'lastName' | 'email' | 'role' | 'isActive' | 'id'>;
  onClose: () => void;
}

export const ContractorRemovalDialog: FC<Props> = ({ isOpen, contractor, onClose }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { id, email, firstName, lastName, role } = contractor ?? {};
  const [deleteUser] = useMutation<DeleteUser, DeleteUserVariables>(deleteUserMutation, {
    context: API_CONTEXT.AUTH,
    awaitRefetchQueries: true,
    refetchQueries: ['Contractors']
  });

  const deleteUserHandler = useAsyncCallback(async () => {
    await deleteUser({ variables: { id } });
    toast(
      <SuccessSnackbar
        title={formatMessage({ id: 'contractor_success_delete' })}
        message={formatMessage({ id: 'contractor_success_delete_message' })}
      />
    );
    onClose();
    navigate(-1);
  });

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={onClose}
      onConfirm={deleteUserHandler.execute}
      confirmButtonContent={formatMessage({ id: 'delete' })}
      title={formatMessage({ id: 'contractor_removal' })}
      content={
        <>
          {formatMessage(
            { id: 'contractor_trying_to_delete' },
            {
              role: getDisplayRole(role),
              roleLowerCase: role?.toLowerCase(),
              firstName,
              lastName,
              email: email ?? ''
            }
          )}

          <TriangleMarkeredList
            itemsIntlIds={[
              'delete_contractor_point.1',
              'delete_contractor_point.2',
              'delete_contractor_point.3',
              'delete_contractor_point.4'
            ]}
          />
        </>
      }
    />
  );
};
