import { QuestionComponent } from '__graphql__/globalTypes';
import { type FormDetails_formDetails_sections } from 'shared/hooks/useFormBuilder/__graphql__/FormDetails';
import { type WithoutGQLType } from 'shared/types/utils';

export const BASIC_INFO_SECTION = {
  id: 'basic_info',
  name: 'Basic info',
  children: [],
  order: 0,
  variable: 'Basic_Info',
  parent: null,
  description: 'Basic info about the contractor'
} as WithoutGQLType<FormDetails_formDetails_sections>;

export enum DroppableArea {
  Section = 'section',
  Component = 'component',
  Canvas = 'canvas'
}

export const TEXT_COMPONENTS: QuestionComponent[] = [
  QuestionComponent.HEADER_1,
  QuestionComponent.HEADER_2,
  QuestionComponent.HEADER_3,
  QuestionComponent.PARAGRAPH
];
