import { type Role } from '__graphql__/globalTypes';
import { createContext } from 'react';

export interface UserProfileInfo {
  id: string;
  firstName: string;
  lastName: string | null;
  email: string;
  role?: Role;
  avatarUrl?: string;
  documentId?: string;
}

export interface ProfileContext {
  user: UserProfileInfo;
  isModalOpened: boolean;
  openModal: () => void;
  closeModal: () => void;
  updateUserInfo: (data: Partial<UserProfileInfo>) => void;
}

export const ProfileInfoContext = createContext<ProfileContext>({
  user: { id: '', firstName: '', lastName: '', email: '' },
  isModalOpened: false,
  openModal() {},
  closeModal() {},
  updateUserInfo() {}
});
