import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Card, FormControlLabel, IconButton, Switch, TextField, Typography } from '@mui/material';
import { TenantStatus, type UpdateCompanyInfoData } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { useContext, useMemo, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { companyInfoQuery, companySubscriptionsQuery, discountQuery, updateCompanyInfoMutation } from 'shared/api';
import { type CompanyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { type CompanySubscriptions } from 'shared/api/__graphql__/CompanySubscriptions';
import { type Discount } from 'shared/api/__graphql__/Discount';
import { type UpdateCompanyInfo, type UpdateCompanyInfoVariables } from 'shared/api/__graphql__/UpdateCompanyInfo';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { BillingHistory, CompanyForm, DiscountLabels, Footer, FormDialog, InfoCard, Loader } from 'shared/components';
import { InputTooltip } from 'shared/components/form';
import { CrossIcon, DiamondIcon, LightningIcon, RocketIcon } from 'shared/components/icons';
import { AttentionSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { TENANT_HEADER_NAME, TRIAL_PLAN_ID } from 'shared/constants';
import { ProductsContext } from 'shared/contexts/productsContext';
import { usePalette } from 'shared/hooks';
import { getCurrentPlan, type ICurrentPlan } from 'shared/utils/subscription';
import { getTenantUrl } from 'shared/utils/url';
import { TenantActionDialog, type TenantAction } from '../MyTenants/TenantActionDialog';
import { DiscountWidget, ExtendTrialWidget } from './PlanWidgets';

export interface PlanCardConfig extends ICurrentPlan {
  icon: JSX.Element | null;
  btn: string | null;
  btnDisabled?: boolean;
  onClick?: () => void;
}

const cardSx = { width: '430px', height: '200px', p: '24px', boxSizing: 'border-box' };

export const Tenant: FC = () => {
  const { products } = useContext(ProductsContext);
  const [editMode, setEditMode] = useState(false);
  const [isExtendTrialOpen, setIsExtendTrialOpen] = useState(false);
  const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  const [action, setAction] = useState<TenantAction>();
  const { secondary } = usePalette();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = pathname.slice(1);

  const { data: companyData, loading: companyLoading } = useQuery<CompanyInfo>(companyInfoQuery, {
    context: {
      headers: {
        [TENANT_HEADER_NAME]: id
      }
    }
  });

  const { data: discountData, loading: discountLoading } = useQuery<Discount>(discountQuery, {
    context: {
      ...API_CONTEXT.PAYMENT,
      headers: {
        [TENANT_HEADER_NAME]: id
      }
    }
  });

  const { data: companySubscriptions, loading: companySubscriptionsLoading } = useQuery<CompanySubscriptions>(
    companySubscriptionsQuery,
    {
      context: {
        ...API_CONTEXT.PAYMENT,
        headers: {
          [TENANT_HEADER_NAME]: id
        }
      }
    }
  );

  const [updateCompanyInfo, { loading: updateCompanyLoading }] = useMutation<
    UpdateCompanyInfo,
    UpdateCompanyInfoVariables
  >(updateCompanyInfoMutation, {
    refetchQueries: ['CompanyInfo'],
    awaitRefetchQueries: true,
    context: {
      headers: {
        [TENANT_HEADER_NAME]: id
      }
    }
  });

  const currentPlan: PlanCardConfig = useMemo(() => {
    const plan = getCurrentPlan({
      companySubscriptions,
      products,
      companyInfo: companyData?.companyInfo,
      formatMessage,
      discount: discountData?.discount
    });

    return {
      ...plan,
      icon: plan.productId === TRIAL_PLAN_ID ? <RocketIcon /> : <DiamondIcon />,
      btn: plan.productId === TRIAL_PLAN_ID ? 'extend_trial' : plan.discount ? 'discount_applied' : 'apply_discount',
      btnDisabled: !plan.productId,
      onClick:
        plan.productId === TRIAL_PLAN_ID
          ? () => {
              setIsExtendTrialOpen(true);
            }
          : () => {
              setIsDiscountOpen(true);
            }
    };
  }, [companySubscriptions, companyData, products, formatMessage, discountData]);

  if (companyLoading || companySubscriptionsLoading || discountLoading || !companyData) {
    return <Loader />;
  }

  const { name, adminName, adminEmail, billingContactName, billingContactEmail, status, autoSuspension, updatedAt } =
    companyData.companyInfo;
  const isActive = status === TenantStatus.ACTIVE;
  const statusAction = isActive ? 'deactivate' : 'activate';

  const onDelete = (): void => {
    if (isActive) {
      toast(
        <AttentionSnackbar
          title={formatMessage({ id: 'deactivate_tenant_first' })}
          message={formatMessage({ id: 'deactivate_to_delete' })}
        />
      );
      return;
    }

    setAction('delete');
  };

  return (
    <>
      <Box
        sx={{
          height: '85px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fff',
          borderBottom: `1px solid ${secondary[200]}`,
          px: '48px'
        }}
      >
        <Typography variant='h1' sx={{ fontSize: '32px' }}>
          {`${formatMessage({ id: 'tenant' })} ${companyData.companyInfo.id}`}
        </Typography>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <CrossIcon size={28} />
        </IconButton>
      </Box>

      <Box minHeight='calc(100vh - 86px - 84px - 32px)' sx={{ p: '32px 48px 40px 48px' }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', mb: '16px' }}>
            <Typography
              variant='h3'
              color='secondary.700'
              sx={{ mb: '16px', fontSize: '24px', lineHeight: '28px', fontWeight: 600, color: 'secondary.800' }}
            >
              {formatMessage({ id: 'about_company' })}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  p: '8px 20px',
                  borderRadius: '8px',
                  backgroundColor: 'secondary.100',
                  color: 'secondary.700',
                  mr: '12px'
                }}
              >
                {updatedAt ? (
                  <Typography>{`${formatMessage({ id: 'last_activity' })} ${dayjs(updatedAt).format(
                    'DD/MM/YYYY'
                  )}`}</Typography>
                ) : (
                  <Typography>{formatMessage({ id: 'no_activity' })}</Typography>
                )}
              </Box>
              <Box sx={{ p: '8px 20px', borderRadius: '8px', backgroundColor: isActive ? 'success.100' : 'error.100' }}>
                <Typography>{formatMessage({ id: isActive ? 'active' : 'deactivated' })}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <InfoCard sx={cardSx} title={formatMessage({ id: 'basic_info' })}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <TextField
                  label={formatMessage({ id: 'tenant_name' })}
                  value={name}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputTooltip
                          title={formatMessage({
                            id: 'about_tenant.name'
                          })}
                        />
                      </Box>
                    )
                  }}
                />
                <TextField
                  label={formatMessage({ id: 'tenant_url' })}
                  value={getTenantUrl(companyData.companyInfo.id)}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputTooltip
                          title={formatMessage({
                            id: 'about_tenant.link'
                          })}
                        />
                      </Box>
                    )
                  }}
                />
              </Box>
            </InfoCard>
            <InfoCard sx={cardSx} title={formatMessage({ id: 'admin_access' })}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <TextField
                  label={formatMessage({ id: 'admin_name' })}
                  value={adminName}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputTooltip
                          title={formatMessage({
                            id: 'about_tenant.admin_name'
                          })}
                        />
                      </Box>
                    )
                  }}
                />
                <TextField
                  label={formatMessage({ id: 'email' })}
                  value={adminEmail}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputTooltip
                          title={formatMessage({
                            id: 'about_tenant.admin_email'
                          })}
                        />
                      </Box>
                    )
                  }}
                />
              </Box>
            </InfoCard>
            <InfoCard sx={cardSx} title={formatMessage({ id: 'billing_contacts' })}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <TextField
                  label={formatMessage({ id: 'contact_name' })}
                  value={billingContactName}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputTooltip
                          title={formatMessage({
                            id: 'about_tenant.contact_name'
                          })}
                        />
                      </Box>
                    )
                  }}
                />
                <TextField
                  label={formatMessage({ id: 'contact_email' })}
                  value={billingContactEmail}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputTooltip
                          title={formatMessage({
                            id: 'about_tenant.contact_email'
                          })}
                        />
                      </Box>
                    )
                  }}
                />
              </Box>
            </InfoCard>
          </Box>
        </Box>

        <Box sx={{ my: '28px' }}>
          <Typography
            variant='h3'
            sx={{ mb: '16px', fontSize: '24px', lineHeight: '28px', fontWeight: 600, color: 'secondary.800' }}
          >
            {formatMessage({ id: 'billing_info' })}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Card
              sx={{
                ...cardSx,
                padding: '20px',
                border: `1px solid ${secondary[200]}`,
                borderRadius: '16px',
                boxShadow: 'none'
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {currentPlan.icon}
                    <Typography
                      variant='h4'
                      color='secondary.700'
                      sx={{
                        ml: '10px',
                        fontSize: '20px',
                        lineHeight: '24px',
                        fontWeight: 600
                      }}
                    >
                      {currentPlan.name}
                    </Typography>
                  </Box>
                  {!!currentPlan.icon && (
                    <Typography sx={{ color: secondary[600], fontSize: '14px' }}>
                      {dayjs(currentPlan?.startDate).format('DD/MM/YYYY')} -{' '}
                      {dayjs(currentPlan?.endDate).format('DD/MM/YYYY')}
                    </Typography>
                  )}
                </Box>
                <Typography
                  sx={{
                    color: 'secondary.700',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    fontSize: '14px',
                    lineHeight: '20px',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical'
                  }}
                >
                  {formatMessage(
                    { id: currentPlan?.productId === TRIAL_PLAN_ID ? 'trial_plan_info' : 'plan_info' },
                    {
                      price: currentPlan?.defaultPrice,
                      registrations: currentPlan?.numberOfRegistrations,
                      additionalPrice: currentPlan?.additionalPrice
                    }
                  )}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {currentPlan.btn && (
                    <Button
                      variant='outlined'
                      sx={{ alignSelf: 'flex-start' }}
                      onClick={currentPlan.onClick}
                      disabled={currentPlan.btnDisabled}
                    >
                      {formatMessage({ id: currentPlan.btn })}
                    </Button>
                  )}
                  <DiscountLabels {...currentPlan} />
                </Box>
              </Box>
            </Card>
            {currentPlan?.productId !== TRIAL_PLAN_ID && (
              <InfoCard sx={cardSx} title={formatMessage({ id: 'payment_info' })}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <TextField
                    label={formatMessage({ id: 'card_number' })}
                    value={currentPlan.cardNumber}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <InputTooltip
                            title={formatMessage({
                              id: 'billing_info.card'
                            })}
                          />
                        </Box>
                      )
                    }}
                  />
                  <TextField
                    label={formatMessage({ id: 'expiring_date' })}
                    value={currentPlan.cardExpirationDate}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <InputTooltip
                            title={formatMessage({
                              id: 'billing_info.expiring_date'
                            })}
                          />
                        </Box>
                      )
                    }}
                  />
                </Box>
              </InfoCard>
            )}
            {currentPlan?.productId !== TRIAL_PLAN_ID && (
              <Card
                sx={{
                  ...cardSx,
                  padding: '20px',
                  border: `1px solid ${secondary[200]}`,
                  borderRadius: '16px',
                  boxShadow: 'none'
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LightningIcon />
                    <Typography
                      variant='h4'
                      color='secondary.700'
                      sx={{ ml: '10px', fontSize: '20px', lineHeight: '24px', fontWeight: 600 }}
                    >
                      {formatMessage({ id: 'auto_suspension' })}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: 'secondary.700',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {formatMessage({ id: 'auto_suspension_explanation' })}
                  </Typography>
                  <FormControlLabel
                    sx={{ color: secondary[700], justifyContent: 'flex-end' }}
                    control={
                      <Switch
                        checked={autoSuspension}
                        onChange={async (_, checked: boolean) => {
                          await updateCompanyInfo({ variables: { data: { autoSuspension: checked } } });
                          toast(
                            <SuccessSnackbar
                              title={formatMessage({
                                id: checked ? 'auto_suspension_turned_on' : 'auto_suspension_turned_off'
                              })}
                              message={formatMessage({
                                id: checked ? 'auto_suspension_turned_on_message' : 'auto_suspension_turned_off_message'
                              })}
                            />
                          );
                        }}
                      />
                    }
                    label={formatMessage({ id: 'enable_auto_suspension' })}
                    labelPlacement='start'
                  />
                </Box>
              </Card>
            )}
          </Box>
        </Box>

        {currentPlan?.productId !== TRIAL_PLAN_ID && <BillingHistory tenantId={id} />}
      </Box>

      <Footer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px'
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                setEditMode(true);
              }}
            >
              {formatMessage({ id: 'edit' })}
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px'
            }}
          >
            <Button variant='outlined' onClick={() => setAction(statusAction)}>
              {formatMessage({ id: statusAction })}
            </Button>
            <Button variant='contained' color='error' onClick={onDelete}>
              {formatMessage({ id: 'delete' })}
            </Button>
          </Box>
        </Box>
      </Footer>

      <FormDialog
        isOpen={editMode}
        onClose={() => {
          setEditMode(false);
        }}
        header={formatMessage({ id: 'edit_tenant' })}
        size='md'
        confirmBtnName={formatMessage({ id: 'save' })}
        initialValues={companyData.companyInfo}
        loading={updateCompanyLoading}
        onSubmit={async ({
          billingContactName,
          billingContactEmail,
          adminEmail,
          adminName,
          name
        }: UpdateCompanyInfoData) => {
          await updateCompanyInfo({
            variables: {
              data: {
                billingContactName,
                billingContactEmail,
                adminEmail,
                adminName,
                name
              }
            }
          });
          toast(<SuccessSnackbar title={formatMessage({ id: 'tenant_successfully_updated' })} />);
          setEditMode(false);
        }}
      >
        {/* @ts-expect-error: error */}
        <CompanyForm withBillingContact />
      </FormDialog>

      {isExtendTrialOpen && (
        <ExtendTrialWidget
          plan={currentPlan}
          tenant={companyData.companyInfo}
          onClose={() => setIsExtendTrialOpen(false)}
        />
      )}
      {isDiscountOpen && (
        <DiscountWidget plan={currentPlan} tenant={companyData.companyInfo} onClose={() => setIsDiscountOpen(false)} />
      )}

      {action && (
        <TenantActionDialog action={action} tenant={companyData.companyInfo} onClose={() => setAction(undefined)} />
      )}
    </>
  );
};
