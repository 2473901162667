import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { type RegistrationUpload } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useCallback, useMemo, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { uploadRegistrationsMutation } from 'shared/api';
import {
  type UploadRegistrations,
  type UploadRegistrationsVariables
} from 'shared/api/__graphql__/UploadRegistrations';
import SuccessSnackbar from 'shared/components/Snackbars/SuccessSnackbar';
import { usePalette, useRoles } from 'shared/hooks';
import { FileFormat } from 'shared/types';
import { type AttachedDocument } from 'shared/types/file';
import { downloadCSVTemplate } from 'shared/utils/csv';
import { CustomDropzone } from './CustomDropzone';
import { CircleQuestionIcon, CrossIcon, OctagonXIcon, TaskIcon, TriangleListMarker } from './icons';

dayjs.extend(customParseFormat);

enum ReferralError {
  'obligatory' = 'obligatory',
  'duplicate' = 'duplicate',
  'incorrectFormat' = 'incorrectFormat',
  'minLength' = 'minLength',
  'incorrectDate' = 'incorrectDate'
}

type Referral = Record<string, { value: string; error: ReferralError | null }>;

export const CSVUpload: FC<{ setReferrals: (referrals: Referral[]) => void }> = ({ setReferrals }) => {
  const intl = useIntl();
  const { secondary, info } = usePalette();
  const [file, setFile] = useState<AttachedDocument>();
  const [error, setError] = useState<string | undefined>();

  const csvHeaders = useMemo(
    () => [
      `${intl.formatMessage({ id: 'first_name' })}*`,
      intl.formatMessage({ id: 'middle_name' }),
      `${intl.formatMessage({ id: 'last_name' })}*`,
      intl.formatMessage({ id: 'email' }),
      intl.formatMessage({ id: 'phone_number' }),
      intl.formatMessage({ id: 'note.consultant.label' }),
      intl.formatMessage({ id: 'created_at' })
    ],
    [intl]
  );

  const downloadTemplate = useCallback(async () => {
    await downloadCSVTemplate(csvHeaders);
  }, [csvHeaders]);

  const onDrop = useCallback(
    async (_: string, value: Array<string | AttachedDocument>) => {
      const { file } = value[0] as AttachedDocument;
      if (!['application/vnd.ms-excel', 'text/csv'].includes(file.type)) {
        setError('wrong_file_format');
        return;
      }

      if (file.size / 1024 / 1024 > 5) {
        setError('file_is_too_big');
      }
      setFile(value[0] as AttachedDocument);

      const readFileAsync = async (file: File): Promise<string> => {
        return await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.onerror = reject;
          reader.readAsText(file);
        });
      };

      function csvToArray(str: string, delimiter = ','): Referral[] {
        const headers = str
          .slice(0, str.indexOf('\n'))
          .split(delimiter)
          .map((el) => el.replace('\r', ''));
        const rows = str
          .slice(str.indexOf('\n') + 1)
          .split('\n')
          .map((el) => el.replace('\r', ''));
        const isCorrectStructure = csvHeaders.every((header, index) => header === headers[index]);
        if (!isCorrectStructure) {
          setError('incorrect_structure_error');
          return [];
        } else setError(undefined);

        const decodeHeader = (header: string): string => {
          switch (header) {
            case `${intl.formatMessage({ id: 'first_name' })}*`:
              return 'firstName';
            case intl.formatMessage({ id: 'middle_name' }):
              return 'middleName';
            case `${intl.formatMessage({ id: 'last_name' })}*`:
              return 'lastName';
            case intl.formatMessage({ id: 'email' }):
              return 'email';
            case intl.formatMessage({ id: 'note.consultant.label' }):
              return 'note';
            case intl.formatMessage({ id: 'created_at' }):
              return 'createdAt';
            default:
              return 'phoneNumber';
          }
        };

        const validation = (
          field: string,
          value: string,
          referrals: Referral[],
          currentIndex: number
        ): ReferralError | null => {
          const emailRegex =
            // eslint-disable-next-line no-control-regex, max-len
            /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
          // eslint-disable-next-line no-useless-escape
          const phoneRegex = /^[\+]?[0-9]{8,14}$/;

          switch (field) {
            case 'firstName':
            case 'lastName': {
              if (!value.length) {
                return ReferralError.obligatory;
              } else if (value.length < 3) {
                return ReferralError.minLength;
              }
              return null;
            }
            case 'phoneNumber':
              if (!value.length) return null;
              else if (!phoneRegex.test(value)) {
                return ReferralError.incorrectFormat;
              } else return null;
            case 'email':
              if (!value.length) return null;
              else if (
                referrals.filter((_, index) => index !== currentIndex).some((v) => v[field].value.trim() === value)
              ) {
                return ReferralError.duplicate;
              } else if (!emailRegex.test(value)) {
                return ReferralError.incorrectFormat;
              } else return null;
            case 'createdAt': {
              if (!value || dayjs(value, ['DD/MM/YYYY', 'D/M/YYYY']).isValid()) {
                return null;
              }
              return ReferralError.incorrectDate;
            }
            default:
              return null;
          }
        };

        const arrayOfValues = rows
          .filter((row) => row !== '')
          .map(function (row) {
            const values = row.split(delimiter);

            const el = headers.reduce<Referral>(function (object, header, index) {
              const field = decodeHeader(header);
              const value = values[index].trim();
              object[field] = {
                value,
                error: null
              };
              return object;
            }, {});
            return el;
          });

        return arrayOfValues.map((values, index) =>
          Object.entries(values).reduce<Referral>((object, [key, { value, error }]) => {
            object[key] = {
              value,
              error
            };

            const result = validation(key, value, arrayOfValues, index);

            if (result) {
              object[key].error = result;
            }
            return object;
          }, {})
        );
      }
      await readFileAsync(file).then((r) => {
        setReferrals(csvToArray(r));
      });
    },
    [csvHeaders, intl, setReferrals]
  );

  return (
    <>
      <Typography variant='body2' fontWeight={400} color='secondary.800' mb={4}>
        <Typography mb={2}>{intl.formatMessage({ id: 'follow_the_recommendations' })}</Typography>
        <Box display='flex' flexDirection='column' gap='7px'>
          {[
            intl.formatMessage({ id: 'all_required_fields_must_be_filled' }),
            intl.formatMessage({ id: 'all_data_formats_must_be_correct' }),
            intl.formatMessage({ id: 'the_table_should_not_have_repetitions' }),
            intl.formatMessage({ id: 'the_file_structure_should_be_like_below' })
          ].map((prompt, index) => (
            <Box key={index} display='flex' gap={2}>
              <Box>
                <TriangleListMarker />
              </Box>
              <Typography>{prompt}</Typography>
            </Box>
          ))}
        </Box>
      </Typography>
      <Box sx={{ border: `1px solid ${secondary[200]}`, borderRadius: '8px' }}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '& > th:not(:last-child)': {
                  borderRight: `1px solid ${secondary[100]}`
                },
                '& > th': {
                  borderBottomColor: secondary[100]
                }
              }}
            >
              {csvHeaders.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                height: 48,
                '& > td:not(:last-child)': {
                  borderRight: `1px solid ${secondary[100]}`
                },
                '& > td': {
                  padding: 0,
                  borderBottom: 'none',
                  backgroundColor: info[200]
                }
              }}
            >
              {csvHeaders.map((header) => (
                <TableCell key={header} />
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Button
        size='medium'
        variant='outlined'
        sx={{
          color: secondary[800],
          marginY: '16px',
          alignSelf: 'end'
        }}
        onClick={downloadTemplate}
      >
        {intl.formatMessage({ id: 'download_csv_template' })}
      </Button>
      <CustomDropzone
        // loading={onSave.loading}
        error={error}
        name='referrals'
        onChange={onDrop}
        contentDirection='row'
        docTypes={[FileFormat.CSV]}
        title={
          error
            ? 'the_file_structure_is_incorrect'
            : file?.filename
            ? file?.filename
            : 'select_csv_file_or_drag_and_drop'
        }
        boxSx={{
          '& label': {
            height: '138px'
          }
        }}
      />
    </>
  );
};

const CSVReview: FC<{ referrals: Referral[]; numberOfErrors: number }> = ({ referrals, numberOfErrors }) => {
  const intl = useIntl();
  const { secondary, success, error } = usePalette();

  const csvHeaders = useMemo(
    () => [
      `${intl.formatMessage({ id: 'first_name' })}*`,
      intl.formatMessage({ id: 'middle_name' }),
      `${intl.formatMessage({ id: 'last_name' })}*`,
      intl.formatMessage({ id: 'email' }),
      intl.formatMessage({ id: 'phone_number' }),
      intl.formatMessage({ id: 'note.consultant.label' }),
      intl.formatMessage({ id: 'created_at' })
    ],
    [intl]
  );

  return (
    <>
      <Box
        sx={{
          mb: '12px',
          p: '10px',
          borderRadius: '8px',
          background: numberOfErrors ? error[50] : success[50],
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle1' mr={1}>
            {numberOfErrors}
          </Typography>
          <Typography variant='body1' lineHeight='20px' mr='10px'>
            {intl.formatMessage({ id: 'number_errors_defined' })}
          </Typography>
          {numberOfErrors ? <OctagonXIcon color={error[200]} /> : <TaskIcon color={success[300]} />}
        </Box>
        <Typography>
          {intl.formatMessage({
            id: numberOfErrors
              ? 'your_file_should_be_fixed_before_system_upload'
              : 'your_file_can_be_successfully_uploaded_to_the_system'
          })}
        </Typography>
      </Box>
      <Box sx={{ border: `1px solid ${secondary[200]}`, borderRadius: '8px', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '& > th:not(:last-child)': {
                  borderRight: `1px solid ${secondary[100]}`
                },
                '& > th': {
                  borderBottomColor: secondary[100]
                }
              }}
            >
              {csvHeaders.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals.map((referral, index) => (
              <TableRow
                key={`${referral.phoneNumber.value}${index}`}
                sx={{
                  height: 48,
                  '& > td:not(:last-child)': {
                    borderRight: `1px solid ${secondary[100]}`
                  },
                  '& > td': {
                    borderBottom: 'none'
                  }
                }}
              >
                {Object.entries(referral).map(([key, { value, error: err }]) => (
                  <TableCell
                    key={key + value}
                    sx={{
                      ...(err
                        ? {
                            backgroundColor: error[50],
                            position: 'relative'
                          }
                        : {})
                    }}
                  >
                    {value || '-'}
                    {err && (
                      <Tooltip
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        enterTouchDelay={0}
                        placement='bottom-start'
                        PopperProps={{ sx: { '& > div.MuiTooltip-tooltip': { margin: '-15px 0 0 12px !important' } } }}
                        title={
                          <Typography
                            variant='body1'
                            lineHeight='20px'
                            sx={{
                              margin: '-5px -9px',
                              background: '#FFF',
                              border: `1px solid ${error[200]}`,
                              borderRadius: '8px',
                              maxWidth: '244px',
                              color: secondary[700],
                              p: '16px'
                            }}
                          >
                            {intl.formatMessage({ id: `upload_referral_error_${err}` })}
                          </Typography>
                        }
                      >
                        <IconButton
                          sx={{
                            '&, &:hover': {
                              p: '2px',
                              zIndex: 1,
                              position: 'absolute',
                              right: '18px'
                            }
                          }}
                        >
                          <CircleQuestionIcon color={error[200]} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export const CSVReferralsUploadWidget: FC = () => {
  const intl = useIntl();

  const [isModalOpen, setModalOpen] = useState(false);
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [isUpload, setIsUpload] = useState(true);
  const { isConsultant } = useRoles();
  const closeModal = useCallback(() => {
    setModalOpen(false);
    setIsUpload(true);
    setReferrals([]);
  }, []);

  const numberOfErrors = useMemo(
    () =>
      referrals.reduce((number, referral) => {
        Object.values(referral).forEach(({ error }) => error && ++number);
        return number;
      }, 0),
    [referrals]
  );

  const onCancelHandler = useCallback(() => {
    if (isUpload) {
      closeModal();
    } else {
      setIsUpload(true);
      setReferrals([]);
    }
  }, [closeModal, isUpload]);

  const onPreviewHandler = useCallback(() => {
    setIsUpload(false);
  }, []);

  const [uploadRegistrations] = useMutation<UploadRegistrations, UploadRegistrationsVariables>(
    uploadRegistrationsMutation,
    {
      refetchQueries: [isConsultant ? 'Referrals' : 'Registrations'],
      awaitRefetchQueries: true
    }
  );

  const onUploadHandler = useCallback(async () => {
    const formatedReferrals = referrals.map((referral) =>
      Object.entries(referral).reduce<Record<string, string>>((obj, [key, { value }]) => {
        switch (key) {
          case 'createdAt': {
            if (value) {
              obj[key] = dayjs(value, ['DD/MM/YYYY', 'D/M/YYYY']).toISOString();
            }
            return obj;
          }
          default: {
            obj[key] = value;
            return obj;
          }
        }
      }, {})
    ) as unknown as RegistrationUpload[];

    await uploadRegistrations({
      variables: {
        data: formatedReferrals
      }
    }).then((response) => {
      if (response.data?.uploadRegistrations) {
        closeModal();
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'successful_upload' })}
            message={intl.formatMessage(
              { id: 'new_referrals_successfully_uploaded' },
              { number: formatedReferrals.length }
            )}
          />
        );
      }
    });
  }, [closeModal, intl, referrals, uploadRegistrations]);

  return (
    <>
      <Button
        type='button'
        size='medium'
        variant='contained'
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {intl.formatMessage({ id: 'upload_referrals' })}
      </Button>
      <Dialog
        fullWidth
        open={isModalOpen}
        onClose={closeModal}
        maxWidth='md'
        sx={{ '& .MuiPaper-root': { borderRadius: '16px', position: 'initial' } }}
      >
        <DialogTitle sx={{ padding: '24px 24px 16px', alignItems: 'self-start' }}>
          <Typography variant='h1' color='secondary.800'>
            {intl.formatMessage({ id: isUpload ? 'csv_referrals_bulk_upload' : 'csv_preview' })}
          </Typography>
          <IconButton onClick={closeModal}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: '0 24px 16px', display: 'flex', flexDirection: 'column' }}>
          {isUpload ? (
            <CSVUpload setReferrals={setReferrals} />
          ) : (
            <CSVReview referrals={referrals} numberOfErrors={numberOfErrors} />
          )}
        </DialogContent>
        <DialogActions sx={{ padding: '0 24px 24px' }}>
          <Button variant='text' color='inherit' onClick={onCancelHandler} sx={{ padding: '8px 24px' }}>
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
          {isUpload ? (
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={onPreviewHandler}
              disabled={!referrals.length}
            >
              {intl.formatMessage({ id: 'preview' })}
            </Button>
          ) : (
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={onUploadHandler}
              disabled={!!numberOfErrors}
            >
              {intl.formatMessage({ id: 'upload' })}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
