import { QuestionComponent } from '__graphql__/globalTypes';
import { type FC } from 'react';
import { type Component } from 'shared/contexts';
import { CheckboxComponent } from './CheckboxComponent';
import { DateComponent } from './DateComponent';
import { DayComponent } from './DayComponent';
import { DocumentComponent } from './DocumentComponent';
import { FileUploadComponent } from './FileUploadComponent';
import { InputComponent } from './InputComponent';
import { OptionsBigComponent } from './OptionsBigComponent';
import { OptionsSmallComponent } from './OptionsSmallComponent';
import { SelectComponent } from './SelectComponent';
import { TextComponent } from './TextComponent';
import { TimeComponent } from './TimeComponent';
import { TimePickerComponent } from './TimePickerComponent';

const COMPONENT_MAP = {
  [QuestionComponent.HEADER_1]: TextComponent,
  [QuestionComponent.HEADER_2]: TextComponent,
  [QuestionComponent.HEADER_3]: TextComponent,
  [QuestionComponent.PARAGRAPH]: TextComponent,
  [QuestionComponent.LONG_TEXT]: InputComponent,
  [QuestionComponent.SHORT_TEXT]: InputComponent,
  [QuestionComponent.CHECKBOX]: CheckboxComponent,
  [QuestionComponent.SINGLE_SELECT]: SelectComponent,
  [QuestionComponent.MULTIPLE_SELECT]: SelectComponent,
  [QuestionComponent.OPTIONS_SMALL]: OptionsSmallComponent,
  [QuestionComponent.OPTIONS_BIG]: OptionsBigComponent,
  [QuestionComponent.DAY]: DayComponent,
  [QuestionComponent.DATE]: DateComponent,
  [QuestionComponent.TIME]: TimeComponent,
  [QuestionComponent.TIME_PICKER]: TimePickerComponent,
  [QuestionComponent.FILE_UPLOAD]: FileUploadComponent,
  [QuestionComponent.CONTRACT]: DocumentComponent,
  [QuestionComponent.ASSIGNMENT_SCHEDULE]: DocumentComponent,
  [QuestionComponent.DOCUMENT]: DocumentComponent
} as Record<QuestionComponent, FC<Component>>;

export const ComponentRenderer: FC<Component> = (item) => {
  const Component = COMPONENT_MAP[item.component];
  return Component ? <Component {...item} /> : null;
};
