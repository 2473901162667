import { Checkbox, type CheckboxProps } from '@mui/material';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';

export const CustomCheckbox: FC<CheckboxProps> = (props) => {
  const { primary, secondary, info } = usePalette();

  return (
    <Checkbox
      {...props}
      icon={
        props?.disabled ? (
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill={secondary[100]} stroke={secondary[300]} />
          </svg>
        ) : (
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill={primary[200]} stroke={info[500]} />
          </svg>
        )
      }
      checkedIcon={
        props?.disabled ? (
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill={secondary[100]} stroke={secondary[300]} />
            <path
              d='M5.14258 11.8634C6.88617 13.6234 10.3733 17.1434 10.3733 17.1434L19.714 7.71484'
              stroke={secondary[500]}
              strokeWidth='2'
              strokeLinecap='round'
            />
          </svg>
        ) : (
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill={primary[300]} stroke={primary[500]} />
            <path
              d='M5.14258 11.8634C6.88617 13.6234 10.3733 17.1434 10.3733 17.1434L19.714 7.71484'
              stroke={info[600]}
              strokeWidth='2'
              strokeLinecap='round'
            />
          </svg>
        )
      }
    />
  );
};
