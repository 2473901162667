import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { update } from 'ramda';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { CancelOctagonIcon, CrossIcon, PlusIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { isTimeRange } from 'shared/utils/validator';
import { type ClearRules, type ClearRulesVariables } from './__graphql__/ClearRules';
import { type IssuerRules, type IssuerRulesVariables } from './__graphql__/IssuerRules';
import { clearRulesMutation, rulesQuery, saveRulesMutation } from './api';
import { MAX_RULES } from './contstants';
import { Rule } from './Rule';
import { type RuleValues } from './types';
import { usePureRule } from './usePureRule';
import { convertTimeToDate, getComparedValue, getOperatorValue, isAnyValueInRule } from './utils';

interface Props {
  issuerId?: string;
  readonly?: boolean;
  onClose: () => void;
}

export const RuleBuilder: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { secondary, primary, error } = usePalette();
  const { onClose, issuerId = '', readonly } = props;
  const { component } = useContext(FormBuilderContext);
  const [saveRules] = useMutation(saveRulesMutation, {
    refetchQueries: ['CountOfIssuerRules'],
    awaitRefetchQueries: true
  });
  const [clearRules] = useMutation<ClearRules, ClearRulesVariables>(clearRulesMutation, {
    variables: { issuerId },
    awaitRefetchQueries: true,
    refetchQueries: ['CountOfIssuerRules']
  });
  const pureRule = usePureRule({ issuerId });

  const { data } = useQuery<IssuerRules, IssuerRulesVariables>(rulesQuery, {
    variables: { issuerId },
    skip: !issuerId
  });

  const initRules: RuleValues[] = data?.issuerRules.length
    ? data.issuerRules.map(({ issuer: { id: issuerId, variable }, actions, operator, comparedValue, id }) => ({
        id,
        issuerVariable: variable,
        issuerId,
        operator: getOperatorValue({ item: component, value: operator }),
        comparedValue: getComparedValue({ item: component, value: comparedValue, operator }),
        actions: actions.map(({ section, question, action, id: actionId }) => ({
          id: actionId,
          questionId: question?.id || '',
          sectionId: section?.id || '',
          action,
          targetVariable: section?.variable || question?.variable || ''
        }))
      }))
    : [{ ...pureRule }];

  return (
    <Dialog open sx={{ '.MuiDialog-container > .MuiPaper-root': { maxWidth: '850px' } }}>
      <DialogTitle sx={{ padding: '24px 24px 16px' }}>
        <Typography color='secondary.800' variant='h5'>
          {formatMessage({ id: readonly ? 'view_component_rule' : 'add_component_rule' })}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ '& path': { stroke: secondary[700] }, '&:hover path': { stroke: primary[600] } }}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {!readonly && (
          <Typography sx={{ color: 'secondary.800', fontWeight: 'normal', lineHeight: '24px' }} variant='body2'>
            {formatMessage({ id: 'enter_test_rule' })}
          </Typography>
        )}

        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ rules: initRules }}
          onSubmit={async ({ rules }) => {
            if (rules.length === 1 && !isAnyValueInRule(rules[0])) {
              await clearRules();
            } else {
              await saveRules({
                variables: {
                  data: rules.map(({ issuerVariable: _, actions, comparedValue, ...rule }) => {
                    const isTimeRangeComparedValue = typeof comparedValue === 'string' && isTimeRange(comparedValue);
                    let mappedComaparedValue = comparedValue;
                    if (isTimeRangeComparedValue) {
                      const [startTime, endTime] = comparedValue.split(' - ');
                      mappedComaparedValue = JSON.stringify([convertTimeToDate(startTime), convertTimeToDate(endTime)]);
                      console.log(mappedComaparedValue);
                    } else if (Array.isArray(comparedValue)) {
                      mappedComaparedValue = JSON.stringify(comparedValue);
                    }

                    return {
                      ...rule,
                      comparedValue: mappedComaparedValue,
                      actions: actions.map(({ targetVariable: _, action, sectionId, questionId, id }) => ({
                        action,
                        id,
                        sectionId: sectionId || null,
                        questionId: questionId || null
                      }))
                    };
                  })
                }
              });
            }

            onClose();
          }}
        >
          {({ values, setFieldValue, handleSubmit, setErrors, errors }) => {
            return (
              <>
                <Form
                  style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    maxHeight: '50%',
                    overflowY: 'auto'
                  }}
                >
                  <FieldArray
                    name='rules'
                    render={({ remove, replace }) =>
                      values.rules.map((_, index) => (
                        <Rule
                          readonly={readonly}
                          issuerId={issuerId}
                          key={index}
                          indexId={index}
                          onDelete={() => {
                            if (values.rules.length === 1) {
                              replace(index, pureRule);
                            } else {
                              remove(index);
                            }
                            setErrors({
                              rules: update(index, undefined, (errors.rules as any) ?? []) as any
                            });
                          }}
                          onErase={() => {
                            replace(index, pureRule);
                            setErrors({
                              rules: update(index, undefined, (errors.rules as any) ?? []) as any
                            });
                          }}
                        />
                      ))
                    }
                  />
                </Form>

                {!readonly && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                    <Button
                      disabled={values.rules.length === MAX_RULES}
                      onClick={() => {
                        setFieldValue('rules', [...values.rules, { ...pureRule }]);
                      }}
                      variant='outlined'
                      sx={{ maxWidth: '200px', display: 'flex', gap: '10px', height: '100%' }}
                    >
                      {formatMessage({ id: 'add_rule' })}
                      <PlusIcon />
                    </Button>

                    {values.rules.length === MAX_RULES && (
                      <Box
                        sx={{
                          p: '0 16px',
                          background: error[50],
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: '8px',
                          gap: '10px'
                        }}
                      >
                        <Typography variant='body1' sx={{ lineHeight: '20px', color: secondary[800] }}>
                          {formatMessage({ id: 'max_rules' })}
                        </Typography>
                        <CancelOctagonIcon />
                      </Box>
                    )}
                  </Box>
                )}

                {!readonly && (
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', pt: '16px' }}>
                    <Button onClick={onClose}>{formatMessage({ id: 'cancel' })}</Button>
                    <Button variant='contained' type='submit' onClick={() => handleSubmit()}>
                      {formatMessage({ id: 'save' })}
                    </Button>
                  </Box>
                )}
              </>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
