import gql from 'graphql-tag';

export const deleteLogo = gql`
  mutation DeleteLogo($relatedId: String!) {
    deleteDocumentsByRelatedId(relatedId: $relatedId)
  }
`;

export const appSetupQuery = gql`
  query AppSetup {
    companySetup {
      themeColor
      name
      paymentFailureCode
      companyLogo {
        name
        url
      }
      font
      id
    }
  }
`;
