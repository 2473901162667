import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const SearchFailedIcon: FC<IconProps> = (props) => {
  const { color, size = 56 } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width={size} height={size} viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='25.5' cy='28.5' r='19.5' fill={primary[300]} />
      <path
        d='M45.1103 26.4458V28.3903C45.0907 32.3148 43.8839 36.1415 41.6487 39.3673C39.4135 42.5932 36.2544 45.0671 32.5869 46.464C28.9193 47.8608 24.9149 48.1152 21.1001 47.1936C17.2853 46.272 13.8386 44.2176 11.2132 41.3005C8.58781 38.3834 6.90657 34.7401 6.39053 30.8496C5.87448 26.9591 6.54777 23.0036 8.32189 19.5029C10.096 16.0022 12.8879 13.1203 16.3306 11.2361C19.7732 9.35184 23.7054 8.55344 27.6103 8.94585'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.833 22.5569H19.8525M31.4997 22.5569H31.5191'
        stroke={currentColor}
        strokeWidth='3.11111'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M36.1252 10.1248L44.3748 18.3744M44.3748 10.1248L36.1252 18.3744'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M50.555 51.7222L41.2217 42.3889'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.4443 34.2222C33.4443 34.2222 30.5277 30.3333 25.6666 30.3333C20.8054 30.3333 17.8888 34.2222 17.8888 34.2222'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
