import { Avatar, type SxProps } from '@mui/material';
import { useContext, type FC } from 'react';
import { ProfileInfoContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { UserIcon } from './icons';

interface Props {
  source: string;
}

export const UserAvatar: FC<Props> = ({ source }) => {
  const { secondary } = usePalette();
  const { user } = useContext(ProfileInfoContext);

  const sx: SxProps = { width: '120px', height: '120px', border: `1px solid ${secondary[300]}`, borderRadius: '13px' };
  const { firstName, lastName } = user;

  if (!source) {
    return (
      <Avatar
        variant='square'
        src=''
        alt={`${firstName} ${lastName}`}
        sx={{
          ...sx,
          backgroundColor: secondary[100]
        }}
      >
        <UserIcon />
      </Avatar>
    );
  }

  return <Avatar variant='square' sx={sx} src={source} alt={`${firstName} ${lastName}`}></Avatar>;
};
