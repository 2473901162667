import { Grid, IconButton } from '@mui/material';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';
import { CrossIcon, ExternalLinkIcon } from '../icons';

interface Props {
  disabled?: boolean;
  filename: string;
  onOpen: () => void;
  onRemove: () => void;
}

export const UploadedDocument: FC<Props> = ({ disabled, filename, onOpen, onRemove }) => {
  const { secondary, primary } = usePalette();

  return (
    <Grid
      container
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: '16px',
        border: `1px solid ${secondary[300]}`,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        height: '46px'
      }}
    >
      <Grid
        item
        sx={{
          maxWidth: '85%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {filename}
      </Grid>
      <Grid item sx={{ display: 'flex', gap: '5px' }}>
        <IconButton onClick={onOpen}>
          <ExternalLinkIcon color={primary[600]} />
        </IconButton>
        <IconButton disabled={disabled} onClick={onRemove}>
          <CrossIcon color={primary[600]} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
