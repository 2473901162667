import gql from 'graphql-tag';

export const contractorRegistrationsQuery = gql`
  query ContractorRegistrations($offset: Int!, $limit: Int!) {
    registrations(limit: $limit, offset: $offset) {
      total
      data {
        id
        status
        updatedAt
        form {
          contractType
        }
      }
    }
  }
`;

export const contractorDocumentsQuery = gql`
  query ContractorDocuments($offset: Int!, $limit: Int!, $filter: DocumentFilter) {
    documents(limit: $limit, offset: $offset, filter: $filter) {
      total
      data {
        id
        filename
        contentType
        status
        documentType
        verified
      }
    }
  }
`;
