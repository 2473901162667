import { useQuery } from '@apollo/client';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { SelectFilter } from 'shared/components';
import { type TenantsList } from '../__graphql__/TenantsList';
import { tenantsListQuery } from '../api';

interface Props {
  value: string[];
  onChange: (_: string[]) => void;
}

export const TenantsFilter: FC<Props> = (props) => {
  const { value, onChange } = props;
  const { formatMessage } = useIntl();

  const { data: allTenants } = useQuery<TenantsList>(tenantsListQuery, {
    context: API_CONTEXT.SUPERADMIN
  });

  return (
    <SelectFilter
      defaultValue={[]}
      values={value}
      placeholder={formatMessage({ id: 'tenant_filter' })}
      options={allTenants?.tenants.data?.map(({ id, name }) => ({ value: id, label: name })) ?? []}
      onApply={onChange}
    />
  );
};
