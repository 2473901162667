import { Box, FormHelperText } from '@mui/material';
import { type FieldProps } from 'formik';
import { type FC } from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnUnderline,
  Editor,
  EditorProvider,
  HtmlButton,
  Toolbar
} from 'react-simple-wysiwyg';
import { useCustomStyles, usePalette } from 'shared/hooks';

type Props = FieldProps;

export const CustomEditor: FC<Props> = ({ field, form }) => {
  const { secondary } = usePalette();
  const resolvedErrorMessage =
    form && field && (form.touched[field.name] || form.submitCount > 0) ? form.errors[field.name] : undefined;
  const customStyles = useCustomStyles();

  return (
    <Box>
      <EditorProvider>
        <Editor {...field} style={customStyles.editor}>
          <Toolbar style={{ backgroundColor: secondary[100] }}>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnNumberedList />
            <BtnBulletList />
            <HtmlButton />
          </Toolbar>
        </Editor>
      </EditorProvider>
      {!!resolvedErrorMessage && (
        <FormHelperText
          variant='filled'
          sx={{ fontSize: '10px', lineHeight: '12px', mt: '2px' }}
          error={!!resolvedErrorMessage}
        >
          {resolvedErrorMessage as string}
        </FormHelperText>
      )}
    </Box>
  );
};
