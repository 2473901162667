import { useLazyQuery } from '@apollo/client';
import { Link, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import {
  BookWithQuestionMarkIcon,
  ChevronIcon,
  LogoutIcon,
  UserConfigurationIcon,
  UserIcon
} from 'shared/components/icons';
import { ProfileInfoContext } from 'shared/contexts';
import AuthContext, { type AuthContextAuthenticated } from 'shared/contexts/authContext';
import { usePalette } from 'shared/hooks';
import { Loader } from '../Loader';
import { type CompanyFAQLink } from './__graphql__/CompanyFAQLink';
import { companyFAQLinkQuery } from './api';

export function AccountMenu(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const [currentTenant, setCurrentTenant] = useState<string | null>(null);
  const { secondary } = usePalette();
  const [fetchFAQLink, { loading, data }] = useLazyQuery<CompanyFAQLink>(companyFAQLinkQuery);

  useEffect(() => {
    const locationTenant = location.pathname.match(/\/tenant\/([^/?]+)/)?.[1] ?? null;

    if (locationTenant !== currentTenant) {
      fetchFAQLink().then(() => setCurrentTenant(locationTenant));
    }
  }, [currentTenant, fetchFAQLink, location.pathname]);

  useEffect(() => {
    fetchFAQLink();
  }, [fetchFAQLink]);

  const {
    openModal,
    user: { firstName, lastName, avatarUrl }
  } = useContext(ProfileInfoContext);

  const { logout, authState } = useContext(AuthContext) as AuthContextAuthenticated;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const logoutHandler = useCallback(async () => {
    handleClose();
    await logout.execute();
  }, [handleClose, logout]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Avatar
          variant='square'
          src={avatarUrl}
          alt={`${firstName} ${lastName}`}
          sx={{
            width: 44,
            height: 44,
            border: `1px solid ${secondary[300]}`,
            borderRadius: '8px',
            backgroundColor: secondary[100]
          }}
        >
          {!avatarUrl && <UserIcon />}
        </Avatar>
        <Box ml={2} mr='2px'>
          <Typography color='parimary.main' lineHeight='20px' textAlign='start'>
            {lastName?.[0] ? `${firstName} ${lastName[0]}.` : firstName}
          </Typography>
          <Typography color={secondary[600]} fontSize='14px' lineHeight='16px' textAlign='start'>
            {intl.formatMessage({ id: `user.role.${authState.user.role}` })}
          </Typography>
        </Box>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{
            ml: 2,
            '& svg': {
              color: secondary[700],
              '& > path': {
                stroke: 'currentColor'
              }
            }
          }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <ChevronIcon direction={open ? 'up' : 'down'} color={secondary[700]} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ display: 'flex' }} onClick={openModal}>
          <ListItemIcon>
            <UserConfigurationIcon />
          </ListItemIcon>
          {intl.formatMessage({ id: 'my_profile' })}
        </MenuItem>
        {!!data?.companyLinks.faqLink && (
          <MenuItem sx={{ display: 'flex', p: 0 }}>
            <Link
              sx={{
                p: '6px 16px',
                display: 'flex',
                color: 'inherit',
                fontWeight: 'inherit',
                '&:hover': { textDecoration: 'unset' },
                width: '100%'
              }}
              href={data.companyLinks.faqLink}
              target='blank'
            >
              <ListItemIcon>
                <BookWithQuestionMarkIcon />
              </ListItemIcon>
              FAQ
            </Link>
          </MenuItem>
        )}
        <MenuItem sx={{ display: 'flex' }} onClick={logoutHandler}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {intl.formatMessage({ id: 'logout' })}
        </MenuItem>
      </Menu>
    </>
  );
}
