import { TextField, type OutlinedTextFieldProps, type TextFieldProps } from '@mui/material';
import { AllowedCharacters, QuestionComponent } from '__graphql__/globalTypes';
import { Field, FormikContext, type FieldProps, type FieldValidator } from 'formik';
import { path, split } from 'ramda';
import { useContext, useState, type ChangeEvent, type FC } from 'react';
import { usePalette } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { Input } from '../Input';
import { InputTooltip } from './InputTooltip';
import { type FormComponentProps } from './types';

export interface BaseInputProps extends Omit<TextFieldProps, 'name'> {
  placeholder?: string;
  field?: FieldProps['field'];
}

export const BaseInput: FC<BaseInputProps> = (props) => {
  const { field = {}, onChange, onBlur, sx = {} } = props;
  const { error } = usePalette();

  return (
    <TextField
      {...field}
      {...props}
      variant='outlined'
      onChange={onChange}
      onBlur={onBlur}
      sx={{
        ...sx,
        '& .MuiFormLabel-asterisk': {
          color: error[300]
        }
      }}
    />
  );
};

type PropertiesToOmit = 'id' | 'required' | 'placeholder';
interface Props extends FormComponentProps, Partial<Omit<OutlinedTextFieldProps, PropertiesToOmit>> {
  validate?: FieldValidator;
}

export const InputComponent: FC<Props> = (props) => {
  const {
    required: initialRequired,
    text,
    placeholder,
    component,
    tooltip,
    id,
    disabled,
    type,
    InputProps = {},
    validate,
    allowedCharacters
  } = props;
  const required = initialRequired ?? false;
  const isTextArea = component === QuestionComponent.LONG_TEXT;
  const onlyNumbers = allowedCharacters === AllowedCharacters.NUMBERS;

  const formik = useContext(FormikContext);

  const [value, setValue] = useState<string>('');
  const error = path(split('.', id), formik?.errors) as string;

  const inputData = {
    multiline: isTextArea,
    label: text,
    error: !!error,
    helperText: error,
    disabled,
    type: type ?? 'text',
    rows: isTextArea ? 4 : undefined,
    placeholder: placeholder ?? undefined,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      const eventValue = event.target.value;
      if (allowedCharacters === AllowedCharacters.LETTERS) {
        const regex = /^[a-zA-Z\s]+$/;
        if (regex.test(eventValue) || eventValue === '') {
          formik?.setFieldValue(id, eventValue);
          setValue(eventValue);
        }
      } else if (onlyNumbers) {
        const regex = /^[0-9]+$/;
        if (regex.test(eventValue) || eventValue === '') {
          formik?.setFieldValue(id, eventValue);
          setValue(eventValue);
        }
      } else {
        formik?.setFieldValue(id, eventValue);
        setValue(eventValue);
      }
    },
    InputProps: {
      ...InputProps,
      endAdornment: tooltip ? <InputTooltip title={tooltip} /> : null
    },
    InputLabelProps: { required }
  };

  return formik ? (
    <Field
      {...inputData}
      component={Input}
      name={id}
      error={error}
      validate={validate || (required && Validator.pipe(Validator.methods.required()))}
    />
  ) : (
    <BaseInput {...inputData} value={value} />
  );
};
