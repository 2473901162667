import gql from 'graphql-tag';

export const getFormErrorsQuery = gql`
  query FormBuilderErrors($id: String!, $isPublishing: Boolean) {
    formErrors(id: $id, isPublishing: $isPublishing) {
      questionId
      sectionId
      sectionName
      error
    }
  }
`;

export const getFormDetailsQuery = gql`
  fragment SectionDetails on Section {
    id
    name
    variable
    order
    description
    parent {
      id
      order
    }
  }

  query FormDetails($id: String!) {
    formDetails(id: $id) {
      id
      name
      createdAt
      publishedAt
      status
      contractType
      sections {
        ...SectionDetails
        children {
          ...SectionDetails
        }
      }
    }
  }
`;

export const addSectionMutation = gql`
  mutation AddSection($args: AddSectionArgs!) {
    addSection(args: $args)
  }
`;

export const cloneSectionMutation = gql`
  mutation CloneSection($id: String!) {
    cloneSection(id: $id)
  }
`;

export const updateSectionMutation = gql`
  mutation UpdateSection($id: String!, $data: UpdateSectionInput!) {
    updateSection(id: $id, data: $data)
  }
`;

export const upsertFormMutation = gql`
  mutation UpsertForm($data: UpsertFormInput!) {
    upsertForm(data: $data)
  }
`;

export const createQuestionMutation = gql`
  mutation CreateQuestion($data: CreateQuestionInput!) {
    createQuestion(data: $data)
  }
`;

export const updateQuestionMutation = gql`
  mutation UpdateQuestion($data: UpdateQuestionInput!) {
    updateQuestion(data: $data)
  }
`;

export const getQuestionsQuery = gql`
  query FormBuilderQuestions($sectionId: String!) {
    questions(sectionId: $sectionId) {
      id
      text
      component
      variable
      documentType
      tooltip
      initialAnswer
      options {
        optionId
        value
        label
      }
      minChar
      maxChar
      allowedCharacters
      placeholder
      description
      required
      withTimePicker
      additionalText
      rulesCount
    }
  }
`;

export const cloneQuestionMutation = gql`
  mutation CloneQuestion($id: String!) {
    cloneQuestion(id: $id)
  }
`;

export const deleteQuestionMutation = gql`
  mutation DeleteQuestion($id: String!) {
    deleteQuestion(id: $id)
  }
`;
