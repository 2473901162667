import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import { FormBuilderContext } from 'shared/contexts';
import { FormVariablesProp, UploadDocumentProp } from './components';
import { CheckboxRenderer, PropertyBlock, VariableProp } from './shared';
import { type ComponentPropertiesProps } from './shared/types';

export const DocumentComponentProperties: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component, updateQuestion, fetchErrors } = useContext(FormBuilderContext);

  return (
    <>
      <VariableProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <PropertyBlock
        readOnly
        title={formatMessage({ id: 'component_properties.type' })}
        value={{ component: formatMessage({ id: `components.${component?.component}` }) }}
        tooltip={formatMessage({ id: 'component_tooltips.type' })}
      />
      <FormVariablesProp />
      <PropertyBlock
        onSubmit={async (values: { required: boolean }) => {
          await updateQuestion({
            variables: { data: { ...values, id: component?.id! } }
          });
          const errors = await fetchErrors();
          if (values.required) {
            const currentDoc = errors.find(({ questionId }) => questionId === component?.id);
            if (currentDoc) {
              toast(
                <AttentionSnackbar
                  title={formatMessage({ id: 'signature_needed_title' })}
                  message={formatMessage({ id: 'signature_needed_message' })}
                />
              );
            }
          }
          return null;
        }}
        readOnly
        title={formatMessage({ id: 'component_properties.signature' })}
        value={{ required: component?.required }}
        tooltip={formatMessage({ id: 'component_tooltips.signature' })}
        ViewComponent={
          <CheckboxRenderer isReadOnly={isReadOnly} name='required' label={formatMessage({ id: 'required' })} />
        }
      />
      <UploadDocumentProp isReadOnly={isReadOnly} />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.description' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.description' })}
        value={{ description: component?.description }}
        tooltip={formatMessage({ id: 'component_tooltips.description' })}
      />
    </>
  );
};
