import { Box } from '@mui/material';
import { Field } from 'formik';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'shared/components';
import { InputTooltip } from 'shared/components/form';
import { Validator } from 'shared/utils/validator';

export const AddConsultantToAgencyForm: FC = () => {
  const intl = useIntl();

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Field
        id='name'
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required())}
        sx={{ mt: 2 }}
      />
      <Field
        id='email'
        name='email'
        label={intl.formatMessage({ id: 'email' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.email())}
      />
      <Field
        id='agency'
        name='agency'
        label={intl.formatMessage({ id: 'agency' })}
        disabled
        component={Input}
        InputProps={{
          endAdornment: <InputTooltip title={intl.formatMessage({ id: 'add_consultant_to_agency_tooltip' })} />
        }}
      />
    </Box>
  );
};
