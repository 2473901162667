import gql from 'graphql-tag';

export const referralsQuery = gql`
  query Referrals(
    $offset: Int!
    $limit: Int!
    $orderBy: RegistrationOrder
    $filter: RegistrationFilter
    $query: String
  ) {
    registrations(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      filter: $filter
      query: $query
    ) {
      total
      data {
        id
        email
        firstName
        lastName
        phoneNumber
        title
        status
        createdAt
        updatedAt
        locked
        dummyRecord
      }
    }
  }
`;
