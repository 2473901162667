import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const DocumentWithPenIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M40 39V40C40 41.0609 39.5786 42.0783 38.8284 42.8284C38.0783 43.5786 37.0609 44 36 44H12C10.9391 44 9.92172 43.5786 9.17157 42.8284C8.42143 42.0783 8 41.0609 8 40V8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4H29L36 11'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M36.84 19.2204C37.23 18.8304 37.6931 18.521 38.2026 18.31C38.7122 18.0989 39.2584 17.9902 39.81 17.9902C40.3616 17.9902 40.9078 18.0989 41.4174 18.31C41.9269 18.521 42.39 18.8304 42.78 19.2204C43.17 19.6105 43.4794 20.0735 43.6905 20.5831C43.9016 21.0927 44.0102 21.6389 44.0102 22.1904C44.0102 22.742 43.9016 23.2882 43.6905 23.7978C43.4794 24.3074 43.17 24.7704 42.78 25.1604L33.9 34.0004L26 36.0004L27.98 28.1004L36.84 19.2204Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M14 14H31' stroke={currentColor} strokeWidth='2' strokeLinecap='round' />
      <path d='M14 19H21' stroke={currentColor} strokeWidth='2' strokeLinecap='round' />
      <path d='M14 29H21' stroke={currentColor} strokeWidth='2' strokeLinecap='round' />
      <path d='M14 24H26' stroke={currentColor} strokeWidth='2' strokeLinecap='round' />
      <path d='M25 19H29' stroke={currentColor} strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};
