import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { Field, Formik, Form as FormikForm } from 'formik';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Input, Loader } from 'shared/components';
import { InputTooltip } from 'shared/components/form';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import routes from 'shared/constants/routes';
import { FormBuilderContext } from 'shared/contexts';
import { useTenantNavigate } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';

interface Props {
  children: JSX.Element;
  mode: 'add' | 'edit';
  initialValues: {
    name: string;
    description: string;
    id?: string;
  };
  closeModal: () => void;
}

export const Form: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <FormikForm style={{ width: '100%', paddingTop: '12px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Field
        id='name'
        name='name'
        label={formatMessage({ id: 'form_name' })}
        validate={Validator.pipe(Validator.methods.required())}
        component={Input}
        InputProps={{
          endAdornment: (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InputTooltip title={formatMessage({ id: 'form_name_tooltip' })} />
            </Box>
          )
        }}
      />
      <Field component={Input} name='description' label={formatMessage({ id: 'description' })} multiline minRows={3} />
    </FormikForm>
  );
};

type Values = Record<'name' | 'description', string>;

export const FormWidgetContent: FC<Props> = ({ children, initialValues, closeModal, mode }) => {
  const { formatMessage } = useIntl();
  const navigate = useTenantNavigate();
  const [navigateToForm, setNavigateToForm] = useState(false);
  const { upsertForm, loading } = useContext(FormBuilderContext);

  const onSubmit = async (values: Values, action: Props['mode']): Promise<void> => {
    const { data } = await upsertForm({
      variables: { data: values },
      awaitRefetchQueries: !navigateToForm,
      refetchQueries: navigateToForm ? [] : ['Forms']
    });
    toast(<SuccessSnackbar title={formatMessage({ id: action === 'add' ? 'form_added' : 'form_edited' })} />);
    if (navigateToForm) {
      navigate(routes.form.replace(':id', data?.upsertForm!));
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values, mode);
        closeModal();
      }}
    >
      {({ handleSubmit }) =>
        loading.upsertForm ? (
          <DialogContent>
            <Loader />
          </DialogContent>
        ) : (
          <>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
              <Button onClick={closeModal}>{formatMessage({ id: 'cancel' })}</Button>
              <Box sx={{ display: 'flex', gap: '12px' }}>
                <Button type='button' variant='outlined' onClick={() => handleSubmit()}>
                  {formatMessage({ id: 'save' })}
                </Button>
                <Button
                  type='button'
                  size='medium'
                  variant='contained'
                  onClick={() => {
                    handleSubmit();
                    setNavigateToForm(true);
                  }}
                >
                  {formatMessage({ id: 'save_and_open_form' })}
                </Button>
              </Box>
            </DialogActions>
          </>
        )
      }
    </Formik>
  );
};
