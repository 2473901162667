import { Outlet, Route, Routes } from 'react-router-dom';
import { Navigation, RegistrationModal } from 'shared/components';
import { ProfileModal } from 'shared/components/ProfileModal';
import routes from 'shared/constants/routes';
import {
  FormBuilderContext,
  ProfileInfoContext,
  SearchContext,
  SubscriptionContextProvider,
  TenantContext,
  TenantSearchContext
} from 'shared/contexts';
import { ProductsContextProvider } from 'shared/contexts/productsContext';
import { useFormBuilder, useSearch, useTenant } from 'shared/hooks';
import { useProfileInfo } from 'shared/hooks/useProfileInfo';
import { appRoutes as adminRoutes } from '../admin/App';
import { TenantSetupTrackingContextProvider } from './contexts';
import { Analytics, MyTenants, SuperAdmins, Tenant } from './pages';

const AdminAppHelper = (): JSX.Element => {
  return (
    <SubscriptionContextProvider>
      <>
        <Outlet />
        <RegistrationModal />
      </>
    </SubscriptionContextProvider>
  );
};

function SuperAdminRoutes(): JSX.Element {
  const formBuilderContextData = useFormBuilder();
  const searchValue = useSearch();

  return (
    <FormBuilderContext.Provider value={formBuilderContextData}>
      <ProductsContextProvider>
        <SearchContext.Provider value={searchValue}>
          <Routes>
            <Route element={<Navigation view='superadmin' />}>
              <Route index element={<MyTenants />} />
              <Route path={routes.tenant} element={<Tenant />} />
              <Route path={routes.analytics} element={<Analytics />} />
              <Route path={routes.tenantView}>
                <Route element={<AdminAppHelper />}>{adminRoutes.map((r) => r)}</Route>
              </Route>
              <Route path={routes.superAdmins} element={<SuperAdmins />} />
            </Route>
          </Routes>
        </SearchContext.Provider>
      </ProductsContextProvider>
    </FormBuilderContext.Provider>
  );
}

function App(): JSX.Element {
  const tenantSearch = useSearch();
  const tenant = useTenant();
  const profileContextData = useProfileInfo();

  return (
    <ProfileInfoContext.Provider value={profileContextData}>
      <TenantSetupTrackingContextProvider>
        <TenantSearchContext.Provider
          value={{
            tenantSearch: tenantSearch.search,
            setTenantSearch: tenantSearch.setSearch
          }}
        >
          <TenantContext.Provider value={tenant}>
            <SuperAdminRoutes />
          </TenantContext.Provider>
        </TenantSearchContext.Provider>
      </TenantSetupTrackingContextProvider>
      <ProfileModal />
    </ProfileInfoContext.Provider>
  );
}

export default App;
