import gql from 'graphql-tag';

export const registrationDetailsQuery = gql`
  query RegistrationDetails($id: String!) {
    registration(id: $id) {
      id
      title
      firstName
      lastName
      phoneNumber
      email
      status
      previousStatus
      form {
        contractType
      }
      weeklyFee
      locked
      formSent
      uploadedToPayroll
      paymentReceived
      dummyRecord
    }
  }
`;

export const generateRegistrationFormMutation = gql`
  mutation GenerateRegistrationForm($registrationId: String!) {
    generateRegistrationForm(registrationId: $registrationId)
  }
`;

export const registrationFormQuery = gql`
  query RegistrationForm($id: String!) {
    registration(id: $id) {
      questionAnswers {
        id
        answer
        questionId
      }
    }
  }
`;

export const documentsQuery = gql`
  query Documents($offset: Int!, $limit: Int!, $orderBy: DocumentOrder, $filter: DocumentFilter) {
    documents(limit: $limit, offset: $offset, orderBy: $orderBy, filter: $filter) {
      total
      data {
        id
        filename
        contentType
        status
        documentType
        verified
      }
    }
  }
`;

export const archiveRegistrationMutation = gql`
  mutation ArchiveRegistration($id: String!) {
    archieveRegistration(id: $id) {
      id
    }
  }
`;

export const unarchiveRegistrationMutation = gql`
  mutation UnarchiveRegistration($id: String!) {
    unarchieveRegistration(id: $id) {
      id
    }
  }
`;

export const verifyDocumentsMutation = gql`
  mutation VerifyDocuments($ids: [String!]!) {
    verifyDocuments(ids: $ids)
  }
`;

export const unverifyDocumentsMutation = gql`
  mutation UnverifyDocuments($ids: [String!]!) {
    unverifyDocuments(ids: $ids)
  }
`;

export const rejectDocumentsMutation = gql`
  mutation RejectDocuments($ids: [String!]!) {
    rejectDocuments(ids: $ids)
  }
`;

export const setFormSentMutation = gql`
  mutation SetFormSent($id: String!) {
    setFormSent(id: $id)
  }
`;

export const setUploadToPayrollMutation = gql`
  mutation SetUploadToPayroll($id: String!, $state: Boolean!) {
    setUploadToPayroll(id: $id, state: $state)
  }
`;

export const setPaymentReceivedMutation = gql`
  mutation setPaymentReceived($id: String!, $state: Boolean!) {
    setPaymentReceived(id: $id, state: $state)
  }
`;

export const setDummyRecordMutation = gql`
  mutation SetDummyRecord($id: String!, $state: Boolean!) {
    setDummyRecord(id: $id, state: $state)
  }
`;

export const deleteRegistrationMutation = gql`
  mutation DeleteRegitration($id: String!) {
    deleteRegistration(id: $id) {
      id
    }
  }
`;

export const deleteRegistrationDocumentsMutation = gql`
  mutation DeleteRegitrationDocuments($relatedId: String!) {
    deleteDocumentsByRelatedId(relatedId: $relatedId)
  }
`;
