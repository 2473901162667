import { Box, Modal } from '@mui/material';
import React, { useContext } from 'react';
import { ProfileInfoContext, AuthContext } from 'shared/contexts';
import { UploadPhoto, Header, ProfileForm } from './components';
import { useChangeProfileInfo } from './hooks';

export const ProfileModal: React.FC = () => {
  const { closeModal, isModalOpened, user, updateUserInfo } = useContext(ProfileInfoContext);
  const { logout } = useContext(AuthContext);

  const { changeAvatarFile, updateProfile, getFieldError } = useChangeProfileInfo({ logout, user, updateUserInfo });

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}
      sx={{
        width: '368px',
        height: '630px',
        mt: '3%',
        ml: 'calc(50% - (368px / 2) - (44px / 2))'
      }}
    >
      <Box
        sx={{
          borderRadius: '1em',
          backgroundColor: '#ffffff',
          width: 'inherit',
          height: 'inherit',
          padding: '24px'
        }}
      >
        <Header />
        <UploadPhoto changeAvatarFile={changeAvatarFile} />
        <ProfileForm
          updateProfile={updateProfile}
          getFieldError={getFieldError}
        />
      </Box>
    </Modal>
  );
};
