import { Box, type SxProps } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import { usePalette } from 'shared/hooks';
import loaderAnimation from './animation.json';

export const Loader: React.FC<{ sx?: SxProps }> = ({ sx = {} }) => {
  const { secondary } = usePalette();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={{
          maxWidth: '40px',
          maxHeight: '40px',
          width: '100%',
          '& > div > svg > g': {
            '& > g:first-of-type > g > path': {
              stroke: secondary[300]
            },
            '& > g:nth-of-type(2) > g > path': {
              stroke: secondary[400]
            },
            '& > g:nth-of-type(3) > g > path': {
              stroke: secondary[500]
            },
            '& > g:last-child > g > path': {
              stroke: secondary[600]
            }
          },
          ...sx
        }}
      >
        <Lottie options={defaultOptions} />
      </Box>
    </Box>
  );
};
