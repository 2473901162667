import { Box, Button } from '@mui/material';
import { Action } from '__graphql__/globalTypes';
import { Field, useFormikContext, type FieldConfig } from 'formik';
import { useState, type FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Input, Select } from 'shared/components';
import { PlusIcon, ThreeDotsIcon, TrashIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { TargetWidget } from './TargetWidget';
import { type RuleBuilderValues } from './types';

interface Props {
  readonly?: boolean;
  parentIndexId: number;
  indexId: number;
  addAction: () => void;
  deleteAction: () => void;
  validate?: FieldConfig['validate'];
}

export const RuleAction: FC<Props> = (props) => {
  const { parentIndexId, indexId, addAction, validate, deleteAction, readonly } = props;
  const { formatMessage } = useIntl();
  const [isTargetModalOpened, setIsTargetModalOpened] = useState(false);
  const { values } = useFormikContext<RuleBuilderValues>();
  const { component, section } = useContext(FormBuilderContext);
  const actionValues = values.rules[parentIndexId].actions[indexId];
  const path = `rules.${parentIndexId}.actions.${indexId}`;

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: '16px' }}>
      <Field
        component={Select}
        name={`${path}.action`}
        label={formatMessage({ id: 'action' })}
        validate={validate}
        disabled={readonly}
        SelectProps={{
          IconComponent: ThreeDotsIcon,
          sx: { transform: 'none' }
        }}
        options={[Action.HIDE, Action.SHOW, Action.READ_ONLY].map((item) => ({
          label: formatMessage({ id: `actions_options.${item.toLowerCase()}` }),
          value: item
        }))}
        sx={{ flex: 0.8 }}
      />
      <Field
        component={Input}
        onClick={() => {
          if (!readonly) {
            setIsTargetModalOpened(true);
          }
        }}
        name={`${path}.targetVariable`}
        label={actionValues.targetVariable ? formatMessage({ id: 'component_section' }) : ''}
        placeholder={formatMessage({ id: 'component_section' })}
        validate={validate}
        InputProps={{
          endAdornment: (
            <Box sx={{ display: 'flex' }}>
              <ThreeDotsIcon />
            </Box>
          ),
          readOnly: true,
          disabled: (!component && !!section) || readonly,
          sx: { cursor: 'pointer' }
        }}
        sx={{ cursor: 'pointer', flex: 1 }}
      />
      {!readonly && (
        <Button
          variant='outlined'
          onClick={addAction}
          sx={{
            maxWidth: '200px',
            display: 'flex',
            gap: '10px',
            height: '100%',
            py: '7px !important',
            maxHeight: '44px'
          }}
        >
          <Box>{formatMessage({ id: 'add_action' })}</Box>
          <PlusIcon />
        </Button>
      )}
      <Button
        variant='outlined'
        onClick={indexId ? deleteAction : undefined}
        sx={{
          p: 0,
          maxWidth: '44px',
          height: '100%',
          maxHeight: '44px',
          visibility: indexId ? 'visible' : 'hidden'
        }}
      >
        <TrashIcon />
      </Button>

      {isTargetModalOpened && (
        <TargetWidget onClose={() => setIsTargetModalOpened(false)} parentIndexId={parentIndexId} indexId={indexId} />
      )}
    </Box>
  );
};
