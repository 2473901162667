import { QuestionComponent, Title } from '__graphql__/globalTypes';
import { type FormBuilderQuestions_questions } from './__graphql__/FormBuilderQuestions';

const OPTIONAL_PROPERTIES = {
  __typename: 'Question',
  documentType: null,
  tooltip: null,
  options: [],
  minChar: null,
  maxChar: null,
  allowedCharacters: null,
  placeholder: null,
  description: null,
  required: null,
  withTimePicker: null,
  additionalText: null,
  initialAnswer: null
} as unknown as FormBuilderQuestions_questions;

export enum BasicInfoVariables {
  Caption = 'Basic_Info_Caption',
  Title = 'Basic_Info_Title',
  FirstName = 'Basic_Info_First_Name',
  Surname = 'Basic_Info_Surname',
  PhoneNumber = 'Basic_Info_Phone_Number',
  Email = 'Basic_Info_Email',
  AgencyInfo = 'Agency_Info',
  Agency = 'Agency_Info_Agency',
  Consultant = 'Agency_Info_Consultant',
  ContractType = 'Agency_Info_Contract_Type',
  WeeklyFee = 'Agency_Info_Weekly_Fee'
}

export const BASIC_INFO_QUESTIONS: FormBuilderQuestions_questions[] = [
  {
    ...OPTIONAL_PROPERTIES,
    id: BasicInfoVariables.Caption,
    text: 'Basic info',
    variable: BasicInfoVariables.Caption,
    component: QuestionComponent.HEADER_3
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'title',
    text: 'Title',
    variable: BasicInfoVariables.Title,
    component: QuestionComponent.SINGLE_SELECT,
    options: Object.values(Title).map((t, index) => ({
      optionId: (index + 1).toString(),
      value: t,
      label: t
    })) as FormBuilderQuestions_questions['options'],
    description: "Contractor's title"
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'firstName',
    text: 'First name',
    variable: BasicInfoVariables.FirstName,
    description: "Contractor's first name",
    component: QuestionComponent.SHORT_TEXT
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'lastName',
    text: 'Surname',
    variable: BasicInfoVariables.Surname,
    description: "Contractor's surname",
    component: QuestionComponent.SHORT_TEXT
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'phoneNumber',
    text: 'Phone number',
    variable: BasicInfoVariables.PhoneNumber,
    description: "Contractor's phone number",
    component: QuestionComponent.SHORT_TEXT
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'email',
    text: 'Email',
    variable: BasicInfoVariables.Email,
    description: "Contractor's email",
    component: QuestionComponent.SHORT_TEXT
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: BasicInfoVariables.AgencyInfo,
    text: 'Agency info',
    variable: BasicInfoVariables.AgencyInfo,
    component: QuestionComponent.HEADER_3
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'agencyId',
    text: 'Agency name',
    variable: BasicInfoVariables.Agency,
    description: "Agency's name",
    component: QuestionComponent.SINGLE_SELECT
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'consultantId',
    text: 'Consultant',
    description: 'Consultant from the agency',
    variable: BasicInfoVariables.Consultant,
    component: QuestionComponent.SINGLE_SELECT
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'contractType',
    text: 'Contract type',
    description: 'List of contract types you should define in the form with documents uploading for every type',
    variable: BasicInfoVariables.ContractType,
    component: QuestionComponent.SINGLE_SELECT
  },
  {
    ...OPTIONAL_PROPERTIES,
    id: 'weeklyFee',
    text: 'Weekly fee',
    description: 'Weekly fee',
    variable: BasicInfoVariables.WeeklyFee,
    component: QuestionComponent.SHORT_TEXT
  }
];
