import { useQuery } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { QuestionComponent } from '__graphql__/globalTypes';
import { useContext, useState, type FC } from 'react';
import { Draggable, type DraggableProvided, type DraggableStateSnapshot } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { DragAndDropIcon, PlusIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { type AddedComponents, type AddedComponentsVariables } from '../__graphql__/AddedComponents';
import { addedComponentsQuery } from '../api';
import { getCreateComponentData, shouldBeUniqueComponent } from '../utils';
import { BASIC_INFO_SECTION, DroppableArea } from './constants';
import { StrictModeDroppable } from './StrictModeDroppable';

const COMPONENTS = [
  {
    group: 'simple_text',
    items: [
      { value: QuestionComponent.HEADER_1, index: 0 },
      { value: QuestionComponent.HEADER_2, index: 1 },
      { value: QuestionComponent.HEADER_3, index: 2 },
      { value: QuestionComponent.PARAGRAPH, index: 3 }
    ]
  },
  {
    group: 'inputs',
    items: [
      { value: QuestionComponent.SHORT_TEXT, index: 4 },
      { value: QuestionComponent.LONG_TEXT, index: 5 },
      { value: QuestionComponent.SINGLE_SELECT, index: 6 },
      { value: QuestionComponent.MULTIPLE_SELECT, index: 7 },
      { value: QuestionComponent.OPTIONS_SMALL, index: 8 },
      { value: QuestionComponent.OPTIONS_BIG, index: 9 },
      { value: QuestionComponent.CHECKBOX, index: 10 },
      { value: QuestionComponent.DATE, index: 11 },
      { value: QuestionComponent.DAY, index: 12 },
      { value: QuestionComponent.TIME, index: 13 },
      { value: QuestionComponent.TIME_PICKER, index: 14 }
    ]
  },
  {
    group: 'documents_files',
    items: [
      { value: QuestionComponent.CONTRACT, index: 15 },
      { value: QuestionComponent.DOCUMENT, index: 16 },
      { value: QuestionComponent.ASSIGNMENT_SCHEDULE, index: 17 },
      { value: QuestionComponent.FILE_UPLOAD, index: 18 }
    ]
  }
];

interface RenderListItemArgs {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  componentType: QuestionComponent;
  disabled?: boolean;
}

const ListItem: FC<RenderListItemArgs> = (props) => {
  const { provided, snapshot, componentType, disabled } = props;
  const { formatMessage } = useIntl();
  const { secondary, primary } = usePalette();
  const { draggableId, createQuestion, questions, section } = useContext(FormBuilderContext);

  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    disabled: {
      border: secondary[300],
      iconColor: secondary[500],
      backgroundColor: secondary[50],
      color: secondary[500]
    },
    dragged: {
      border: primary[600],
      iconColor: primary[600],
      backgroundColor: '#fff',
      color: secondary[700]
    },
    default: {
      border: secondary[400],
      iconColor: secondary[600],
      backgroundColor: secondary[50],
      color: secondary[700]
    }
  };

  const currentStyle = snapshot.isDragging ? styles.dragged : disabled ? styles.disabled : styles.default;

  return (
    <>
      <Box
        ref={provided.innerRef}
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        sx={{
          cursor: 'grab',
          justifyContent: 'space-between',
          borderRadius: '8px',
          display: 'flex',
          p: '10px 12px',
          border: `1px solid ${currentStyle.border}`,
          background: currentStyle.backgroundColor,
          mb: '8px',
          transform: draggableId === componentType ? undefined : 'none !important'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box sx={{ display: 'flex', svg: { minWidth: 24 } }}>
          <DragAndDropIcon color={currentStyle.iconColor} />
          <Typography variant='body2' sx={{ ml: '4px', fontWeight: '400', color: currentStyle.color }}>
            {formatMessage({ id: `components.${componentType}` })}
          </Typography>
        </Box>
        {isHovered && !disabled && (
          <IconButton
            onClick={async () => {
              const data = getCreateComponentData({
                sectionId: section?.id!,
                component: componentType,
                order: questions.length + 1
              });

              await createQuestion({ variables: { data } });
            }}
          >
            <PlusIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export const ComponentsList: FC = () => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const { draggableId, form, isReadOnly, section, questions } = useContext(FormBuilderContext);

  const { data } = useQuery<AddedComponents, AddedComponentsVariables>(addedComponentsQuery, {
    variables: { formId: form?.id! },
    skip: !form?.id
  });

  const alreadyAddedUnique = (componentType: QuestionComponent): boolean =>
    !!data?.addedComponents.find((c) => c === componentType);

  const componentPerSection = (componentType: QuestionComponent): boolean => {
    const singleComponents = [
      QuestionComponent.ASSIGNMENT_SCHEDULE,
      QuestionComponent.CONTRACT,
      QuestionComponent.DOCUMENT
    ];
    return (
      (!!questions.length && singleComponents.includes(componentType)) ||
      !!questions.find(({ component }) => singleComponents.includes(component))
    );
  };

  return (
    <StrictModeDroppable droppableId={DroppableArea.Component} isCombineEnabled={false} isDropDisabled={true}>
      {(droppableProps) => (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}
        >
          {COMPONENTS.map(({ group, items }) => (
            <Box key={group} sx={{ pb: '4px' }} {...droppableProps.droppableProps} ref={droppableProps.innerRef}>
              <Typography variant='body1' color='secondary.700' sx={{ lineHeight: '20px', pb: '8px' }}>
                {formatMessage({ id: group })}
              </Typography>
              {items.map(({ value: componentType, index }) => {
                const disabled =
                  (shouldBeUniqueComponent(componentType) && alreadyAddedUnique(componentType)) ||
                  isReadOnly ||
                  componentPerSection(componentType) ||
                  section?.id === BASIC_INFO_SECTION.id;

                return (
                  <Draggable
                    isDragDisabled={disabled}
                    disableInteractiveElementBlocking={true}
                    key={componentType}
                    draggableId={componentType}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <ListItem
                          provided={provided}
                          snapshot={snapshot}
                          componentType={componentType}
                          disabled={disabled}
                        />
                        {snapshot.isDragging && draggableId === componentType && (
                          <Box
                            sx={{
                              position: 'relative',
                              borderRadius: '8px',
                              display: 'flex',
                              p: '10px 12px',
                              border: `1px solid ${secondary[300]}`,
                              background: secondary[50],
                              mb: '8px',
                              transform: 'none !important'
                            }}
                          >
                            <Typography variant='body2' sx={{ ml: '4px', fontWeight: '400', color: secondary[500] }}>
                              {formatMessage({ id: `components.${componentType}` })}
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </Draggable>
                );
              })}
              <Box sx={{ display: 'none' }}>{droppableProps.placeholder}</Box>
            </Box>
          ))}
        </Box>
      )}
    </StrictModeDroppable>
  );
};
