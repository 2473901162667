import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';
import { SuccessOctagonIcon } from '../icons';
import { type SnackbarProps } from './snackbar-props.interface';

const SuccessSnackbar: FC<SnackbarProps> = ({ title, message }) => {
  const { success } = usePalette();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      gap={2}
      p={4}
      sx={{
        backgroundColor: 'success.50',
        border: `1px solid ${success[300]}`,
        borderRadius: '8px',
        '& svg': {
          minWidth: '24px',
          minHeight: '24px'
        }
      }}
    >
      <Box>
        <Typography variant='subtitle1' color='secondary.800' mb={1}>
          {title}
        </Typography>
        {!!message && (
          <Typography variant='body1' color='secondary.800' mt={1}>
            {message}
          </Typography>
        )}
      </Box>
      <SuccessOctagonIcon />
    </Box>
  );
};
export default SuccessSnackbar;
