import { type SxProps, type Theme } from '@mui/material';
import { type Palette } from 'shared/hooks';
import { type FormDetails_formDetails_sections } from 'shared/hooks/useFormBuilder/__graphql__/FormDetails';

export function getAllFormSections(
  sections: FormDetails_formDetails_sections[] = []
): FormDetails_formDetails_sections[] {
  return sections.flatMap((i) => [i, ...i.children]) as FormDetails_formDetails_sections[];
}

interface GetComponentWrapperStylesArgs {
  palettes: Palette;
  currentId: string;
  activeId: string | undefined;
  requiredAttention?: boolean;
}

export function getComponentWrapperStyles(props: GetComponentWrapperStylesArgs): SxProps<Theme> {
  const {
    activeId,
    currentId,
    requiredAttention,
    palettes: { secondary, primary, warning }
  } = props;

  return {
    cursor: 'pointer',
    p: '16px',
    borderRadius: '8px',
    background: requiredAttention ? warning[50] : secondary[100],
    border: requiredAttention
      ? `1px dashed ${warning[200]}`
      : activeId === currentId
      ? `1px dashed ${primary[500]}`
      : 'none'
  };
}
