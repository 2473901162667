import { useMutation } from '@apollo/client';
import { Box, Button, Card, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { BillingHistory, DiscountLabels, InfoCard } from 'shared/components';
import { InputTooltip } from 'shared/components/form';
import { DiamondIcon } from 'shared/components/icons';
import { AttentionSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { TRIAL_PLAN_ID } from 'shared/constants';
import { CompanyContext, SubscriptionContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { localStorageUtils } from 'shared/utils/local-storage';
import { getTenantUrl } from 'shared/utils/url';
import { type RetryPayment } from './__graphql__/RetryPayment';
import { retryPaymentMutation } from './api';
import { CancelSubscriptionWidget, RetentionPeriodWidget } from './CompanyWidgets';

interface Props {
  data: CompanyInfo_companyInfo;
}

const getInputProps = (message: string): Record<string, any> => ({
  readOnly: true,
  endAdornment: (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <InputTooltip title={message} />
    </Box>
  )
});

export const SystemSettingsTab: FC<Props> = ({ data }) => {
  const { currentPlan, openModal } = useContext(SubscriptionContext);
  const { paymentFailureCode } = useContext(CompanyContext);
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const { id, name, adminEmail, adminName, billingContactEmail, billingContactName } = data;

  const [retryPayment] = useMutation<RetryPayment>(retryPaymentMutation, {
    context: API_CONTEXT.PAYMENT,
    refetchQueries: ['AppSetup'],
    awaitRefetchQueries: true
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
      <Box>
        <Typography
          variant='h3'
          color='secondary.700'
          sx={{ mb: '16px', fontSize: '20px', lineHeight: '24px', fontWeight: 600 }}
        >
          {formatMessage({ id: 'about_company' })}
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          <InfoCard title={formatMessage({ id: 'basic_info' })}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <TextField
                label={formatMessage({ id: 'company_name' })}
                value={name}
                InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.name' }))}
              />
              <TextField
                label={formatMessage({ id: 'system_url' })}
                value={getTenantUrl(id)}
                InputProps={getInputProps(formatMessage({ id: 'company_form.tooltip.url' }))}
              />
            </Box>
          </InfoCard>
          <InfoCard title={formatMessage({ id: 'admin_access' })}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <TextField
                label={formatMessage({ id: 'admin_name' })}
                value={adminName}
                InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.adminName' }))}
              />
              <TextField
                label={formatMessage({ id: 'email' })}
                value={adminEmail}
                InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.adminEmail' }))}
              />
            </Box>
          </InfoCard>
          <InfoCard title={formatMessage({ id: 'billing_contacts' })}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <TextField
                label={formatMessage({ id: 'contact_name' })}
                value={billingContactName}
                InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.contactName' }))}
              />
              <TextField
                label={formatMessage({ id: 'contact_email' })}
                value={billingContactEmail}
                InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.contactEmail' }))}
              />
            </Box>
          </InfoCard>
        </Box>
      </Box>

      <Box>
        <Typography
          variant='h3'
          color='secondary.700'
          sx={{ mb: '16px', fontSize: '20px', lineHeight: '24px', fontWeight: 600 }}
        >
          {formatMessage({ id: 'billing_info' })}
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          <Card
            sx={{
              width: '350px',
              height: '150px',
              padding: '20px',
              border: `1px solid ${secondary[200]}`,
              borderRadius: '16px',
              boxShadow: 'none'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DiamondIcon />
                  <Typography
                    variant='h4'
                    color='secondary.700'
                    sx={{ ml: '10px', fontSize: '20px', lineHeight: '24px', fontWeight: 600 }}
                  >
                    {currentPlan?.name}
                  </Typography>
                </Box>
                <Typography sx={{ color: secondary[600], fontSize: '14px' }}>
                  {`${dayjs(currentPlan?.startDate).format('DD/MM/YYYY')} -
                  ${dayjs(currentPlan?.endDate).format('DD/MM/YYYY')}`}
                </Typography>
              </Box>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                }}
              >
                {formatMessage(
                  { id: currentPlan?.productId === TRIAL_PLAN_ID ? 'trial_plan_info' : 'plan_info' },
                  {
                    price: currentPlan?.defaultPrice,
                    registrations: currentPlan?.numberOfRegistrations,
                    additionalPrice: currentPlan?.additionalPrice
                  }
                )}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant='outlined' sx={{ alignSelf: 'flex-start' }} onClick={() => openModal(true)}>
                  {formatMessage({
                    id:
                      currentPlan?.changedAt &&
                      dayjs(+currentPlan?.changedAt).add(1, 'month').isAfter(currentPlan?.endDate)
                        ? 'plan_changed'
                        : 'change_plan'
                  })}
                </Button>
                {!!currentPlan && <DiscountLabels {...currentPlan} />}
              </Box>
            </Box>
          </Card>
          {currentPlan?.cardNumber && (
            <InfoCard
              title={
                paymentFailureCode ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {formatMessage({ id: 'payment_info' })}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        onClick={async () => {
                          const { data } = await retryPayment();
                          if (data?.retryPayment) {
                            toast(
                              <SuccessSnackbar
                                title={formatMessage({ id: 'payment_success' })}
                                message={formatMessage({ id: 'payment_success_message' })}
                              />
                            );
                            localStorageUtils.deleteProperty('warnings.noFunds');
                            localStorageUtils.deleteProperty('warnings.cardExpiration');
                          } else {
                            toast(
                              <AttentionSnackbar
                                title={formatMessage({ id: 'payment_error' })}
                                message={formatMessage({ id: 'payment_error_message' })}
                              />
                            );
                          }
                        }}
                      >
                        {formatMessage({ id: 'retry_payment' })}
                      </Button>
                      <InputTooltip
                        palette='warning'
                        title={formatMessage({ id: `payment_info_${paymentFailureCode}_tooltip` })}
                      />
                    </Box>
                  </Box>
                ) : (
                  formatMessage({ id: 'payment_info' })
                )
              }
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <TextField
                  label={formatMessage({ id: 'card_number' })}
                  value={currentPlan?.cardNumber}
                  InputProps={getInputProps(formatMessage({ id: 'card_number' }))}
                />
                <TextField
                  label={formatMessage({ id: 'expiring_date' })}
                  value={currentPlan?.cardExpirationDate}
                  InputProps={getInputProps(formatMessage({ id: 'expiring_date' }))}
                />
              </Box>
            </InfoCard>
          )}
        </Box>
      </Box>

      {currentPlan?.productId !== TRIAL_PLAN_ID && <BillingHistory />}

      <Box>
        <InfoCard
          title={formatMessage({ id: 'data_retention_period' })}
          sx={{ width: '100%', boxSizing: 'border-box', height: 'auto', p: '24px' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Typography>{formatMessage({ id: 'data_retention_period_explanation' })}</Typography>
            <RetentionPeriodWidget {...data} />
          </Box>
        </InfoCard>
      </Box>

      {currentPlan?.productId !== TRIAL_PLAN_ID && (
        <Box>
          <InfoCard
            title={formatMessage({ id: 'cancel_subscription' })}
            sx={{ width: '100%', boxSizing: 'border-box', height: 'auto', p: '24px' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Typography>{formatMessage({ id: 'cancel_subscription_info' })}</Typography>
              <CancelSubscriptionWidget />
            </Box>
          </InfoCard>
        </Box>
      )}
    </Box>
  );
};
