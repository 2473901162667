export enum OwnerOption {
  all = 'all',
  my = 'my'
}

export enum APIErrorCode {
  SystemSuspended = 'system_suspended'
}

export enum FileFormat {
  PDF = 'PDF',
  DOC_DOCX = 'DOC/DOCX',
  JPG_JPEG = 'JPG/JPEG',
  PNG = 'PNG',
  CSV = 'CSV'
}
