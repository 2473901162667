import { Box, Typography } from '@mui/material';
import { AllowedCharacters } from '__graphql__/globalTypes';
import { type FormikConfig } from 'formik';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { InputComponent } from 'shared/components/form/InputComponent';
import { SelectComponent } from 'shared/components/form/SelectComponent';
import { FormBuilderContext, type Component } from 'shared/contexts';
import { Validator } from 'shared/utils/validator';
import { PropertyBlock, RequirementProp, VariableProp } from './shared';
import { type ComponentPropertiesProps } from './shared/types';

const LengthProp: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  const handleSubmit: FormikConfig<any>['onSubmit'] = async (values, helpers) => {
    if (values.minChar > values.maxChar) {
      helpers.setFieldError('minChar', formatMessage({ id: 'min_char_error' }));
      helpers.setFieldError('maxChar', formatMessage({ id: 'max_char_error' }));
      return true;
    }
    await onSubmit(values);
    return null;
  };

  return (
    <PropertyBlock
      onSubmit={handleSubmit}
      readOnly={isReadOnly}
      title={formatMessage({ id: 'component_properties.length' })}
      value={{ minChar: component?.minChar, maxChar: component?.maxChar }}
      tooltip={formatMessage({ id: 'component_tooltips.length' })}
      EditComponent={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
          <InputComponent
            {...(component as Component)}
            id='minChar'
            type='number'
            InputProps={{ inputProps: { min: 3, max: 60 } }}
            validate={Validator.pipe(Validator.methods.required(), Validator.methods.min(3), Validator.methods.max(60))}
            text={formatMessage({ id: 'component_properties.min_char_length' }, { value: component?.minChar! })}
          />
          <InputComponent
            {...(component as Component)}
            id='maxChar'
            InputProps={{ inputProps: { min: 3, max: 60 } }}
            validate={Validator.pipe(Validator.methods.required(), Validator.methods.min(3), Validator.methods.max(60))}
            type='number'
            text={formatMessage({ id: 'component_properties.max_char_length' }, { value: component?.maxChar })}
          />
        </Box>
      }
      ViewComponent={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
            {formatMessage({ id: 'min_char' }, { value: component?.minChar })}
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
            {formatMessage({ id: 'max_char' }, { value: component?.maxChar })}
          </Typography>
        </Box>
      }
    />
  );
};

const AllowedCharactersProp: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  const options = Object.values(AllowedCharacters).map((i) => ({
    optionId: i,
    label: formatMessage({ id: `allowed_characters.${i}` }),
    value: i
  }));

  return (
    <PropertyBlock
      onSubmit={onSubmit}
      readOnly={isReadOnly}
      title={formatMessage({ id: 'component_properties.allowed_characters' })}
      value={{ allowedCharacters: component?.allowedCharacters }}
      tooltip={formatMessage({ id: 'component_tooltips.allowed_characters' })}
      EditComponent={
        <SelectComponent
          {...(component as Component)}
          id='allowedCharacters'
          text={formatMessage({ id: 'component_properties.characters_type' })}
          options={options}
        />
      }
      ViewComponent={
        <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
          {formatMessage({ id: `allowed_characters.${component?.allowedCharacters}` })}
        </Typography>
      }
    />
  );
};

export const InputComponentProperties: FC<ComponentPropertiesProps> = ({ isReadOnly, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  return (
    <>
      <VariableProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <PropertyBlock
        readOnly
        title={formatMessage({ id: 'component_properties.type' })}
        value={{ component: formatMessage({ id: `components.${component?.component}` }) }}
        tooltip={formatMessage({ id: 'component_tooltips.type' })}
      />
      <PropertyBlock
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.label' })}
        value={{ text: component?.text }}
        tooltip={formatMessage({ id: 'component_tooltips.label' })}
        isMainProperty
      />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.hint' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.hint' })}
        value={{ placeholder: component?.placeholder }}
        tooltip={formatMessage({ id: 'component_tooltips.hint' })}
      />
      <RequirementProp isReadOnly={isReadOnly} onSubmit={onSubmit} />
      <LengthProp isReadOnly={isReadOnly} onSubmit={onSubmit} />
      <AllowedCharactersProp isReadOnly={isReadOnly} onSubmit={onSubmit} />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.tooltip' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.tooltip' })}
        value={{ tooltip: component?.tooltip }}
        tooltip={formatMessage({ id: 'component_tooltips.tooltip' })}
      />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.description' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.description' })}
        value={{ description: component?.description }}
        tooltip={formatMessage({ id: 'component_tooltips.description' })}
      />
    </>
  );
};
