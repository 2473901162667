import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Card, IconButton, Tooltip, Typography } from '@mui/material';
import { groupBy, replace } from 'ramda';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { FormDialog, Loader } from 'shared/components';
import { CircleQuestionIcon, MailIcon } from 'shared/components/icons';
import { CompanyContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { humanFriendlyToVariables, variablesToHumanFriendly } from 'shared/utils/strings';
import { type EmailTemplate, type EmailTemplateVariables } from './__graphql__/EmailTemplate';
import { type EmailTemplates, type EmailTemplates_emailTemplates as Template } from './__graphql__/EmailTemplates';
import { type UpdateEmailTemplate, type UpdateEmailTemplateVariables } from './__graphql__/UpdateEmailTemplate';
import { emailTemplateQuery, emailTemplatesListQuery, updateEmailTemplateMutation } from './api';
import { EmailsTemplatesWidget } from './EmailTemplatesWidget';

export const EmailsTemplates: FC = () => {
  const { formatMessage } = useIntl();
  const { primary, secondary, success, warning } = usePalette();
  const { name } = useContext(CompanyContext);
  const [editedEmail, setEditedEmail] = useState<Template | null>(null);

  const { data, loading } = useQuery<EmailTemplates>(emailTemplatesListQuery, { context: API_CONTEXT.NOTIFICATION });
  const [updateTemplate, { loading: updateLoading }] = useMutation<UpdateEmailTemplate, UpdateEmailTemplateVariables>(
    updateEmailTemplateMutation,
    {
      refetchQueries: ['EmailTemplates'],
      awaitRefetchQueries: true,
      context: API_CONTEXT.NOTIFICATION
    }
  );

  const { data: choosenEmailData, loading: fetchEmailLoading } = useQuery<EmailTemplate, EmailTemplateVariables>(
    emailTemplateQuery,
    {
      context: API_CONTEXT.NOTIFICATION,
      variables: { id: editedEmail?.id! },
      skip: !editedEmail?.id
    }
  );

  if (loading || fetchEmailLoading) {
    return <Loader />;
  }

  const emailTemplates = groupBy(({ group }) => group.toLowerCase(), data?.emailTemplates ?? []);

  const initialFromValue = choosenEmailData?.emailTemplate.senderName || name;

  const getSectionHeader = (group: string, items: Template[]): JSX.Element => {
    const isActionsRequired = items.some(({ isActive }) => !isActive);

    return (
      <Box sx={{ display: 'flex', mb: '12px' }}>
        <Typography variant='h3' sx={{ color: secondary[700], fontSize: '20px', lineHeight: '24px', fontWeight: 600 }}>
          {formatMessage({ id: `${group}_emails` })}
        </Typography>
        <Tooltip
          onClick={(e) => e.stopPropagation()}
          enterTouchDelay={0}
          placement='bottom-start'
          PopperProps={{ sx: { '& > div.MuiTooltip-tooltip': { margin: '-15px 0px 0px 10px !important' } } }}
          title={
            <Typography
              variant='body1'
              lineHeight='20px'
              sx={{
                margin: '-5px -9px',
                background: '#FFF',
                border: `1px solid ${isActionsRequired ? warning[300] : primary[600]}`,
                borderRadius: '8px',
                maxWidth: '244px',
                color: secondary[700],
                p: '16px'
              }}
            >
              {formatMessage({ id: `${group}_emails_tooltip` })}
              {isActionsRequired && ` ${formatMessage({ id: 'additional_configuration_required' })}`}
            </Typography>
          }
        >
          <IconButton sx={{ '&, &:hover': { zIndex: 1 }, ml: '14px' }}>
            <CircleQuestionIcon color={isActionsRequired ? warning[300] : primary[600]} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const initialValues = {
    id: choosenEmailData?.emailTemplate.id,
    subject: choosenEmailData?.emailTemplate.subject,
    from: initialFromValue,
    to: choosenEmailData?.emailTemplate.sendTo,
    content: `${variablesToHumanFriendly(
      choosenEmailData?.emailTemplate.content
    )}<i id="signature" style="font-weight: bold; color: ${secondary[500]}; display: block">(signature)</i>`,
    isActive: choosenEmailData?.emailTemplate.isActive
  };

  return (
    <Box sx={{ padding: '24px' }}>
      {Object.entries(emailTemplates).map(([group, items]) => (
        <Box sx={{ mb: '24px' }} key={group}>
          {getSectionHeader(group, items)}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            {items.map((template) => (
              <Card
                key={template.templateId}
                sx={{
                  width: '350px',
                  height: '200px',
                  padding: '20px',
                  border: `1px solid ${secondary[200]}`,
                  borderRadius: '16px',
                  boxShadow: 'none'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '10px',
                        padding: '4px 8px',
                        backgroundColor: template.isActive ? success[100] : warning[100],
                        borderRadius: '4px',
                        lineHeight: '12px'
                      }}
                    >
                      {formatMessage({ id: template.isActive ? 'active' : 'inactive' })}
                    </Typography>
                  </Box>
                  <MailIcon color={primary[600]} />
                </Box>
                <Box sx={{ py: '18px' }}>
                  <Typography
                    variant='h4'
                    sx={{ fontSize: '20px', color: secondary[700], lineHeight: '24px', fontWeight: 600, mb: '12px' }}
                  >
                    {formatMessage(
                      { id: `${template.contractType ? 'registered' : template.templateId}_title` },
                      { contractType: template.contractType }
                    )}
                  </Typography>
                  <Typography variant='body1' sx={{ mb: '24px' }}>
                    {formatMessage({ id: `${template.contractType ? 'registered' : template.templateId}_description` })}
                  </Typography>
                  <Button onClick={() => setEditedEmail(template)} variant='outlined'>
                    {formatMessage({ id: 'edit_template' })}
                  </Button>
                </Box>
              </Card>
            ))}
          </Box>
        </Box>
      ))}
      <FormDialog
        initialValues={initialValues}
        header={
          editedEmail
            ? formatMessage(
                { id: `${editedEmail.contractType ? 'registered' : editedEmail.templateId}_title` },
                { contractType: editedEmail.contractType }
              )
            : ''
        }
        confirmBtnName={formatMessage({ id: 'save' })}
        isOpen={!!editedEmail}
        loading={updateLoading}
        onClose={() => setEditedEmail(null)}
        onSubmit={async (data, { setTouched }) => {
          setTouched({ to: true, from: true, subject: true, content: true });
          const { content, from, to, id, subject, isActive } = data;
          const isSenderNameChanged = initialFromValue !== from;

          const formattedContent = replace(
            /<i[^>]*id="signature"[^>]*>.*<\/i>/g,
            ''
          )(humanFriendlyToVariables(content));
          await updateTemplate({
            variables: {
              data: {
                id,
                content: formattedContent,
                ...(isSenderNameChanged && { senderName: from }),
                sendTo: to,
                subject,
                isActive
              }
            }
          });
          setEditedEmail(null);
        }}
      >
        <EmailsTemplatesWidget {...editedEmail} to={initialValues.to} />
      </FormDialog>
    </Box>
  );
};
