import { Box, Button, TextField, Typography } from '@mui/material';
import { ThemeColor } from '__graphql__/globalTypes';
import { useCallback, useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { CustomDropzone, InfoCard } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import routes from 'shared/constants/routes';
import { CompanyContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import palette from 'shared/palettes.json';
import { FileFormat, type AttachedDocument } from 'shared/types';
import { getTenantUrl } from 'shared/utils/url';
import { FAQForm } from './FAQForm';
import { FooterLinksForm } from './FooterLinksForm';

const AVAILABLE_COLORS = [
  ThemeColor.ROYAL_BLUE,
  ThemeColor.AMETHYST,
  ThemeColor.ELECTRIC_PURPLE,
  ThemeColor.CRIMSON,
  ThemeColor.PUMPKIN_ORANGE,
  ThemeColor.FOREST_GREEN,
  ThemeColor.SHAMROCK_GREEN,
  ThemeColor.TEAL_BLUE,
  ThemeColor.STEEL_BLUE
].reduce<Array<[ThemeColor, string]>>((result, key) => {
  result.push([key, palette[key as keyof typeof palette].primary[600]]);
  return result;
}, []);

interface Props {
  companyInfo: CompanyInfo_companyInfo;
}

export const ThemeSettingsTab: FC<Props> = (props) => {
  const { companyInfo } = props;
  const { id } = companyInfo;
  const { logo, resetLogo, uploadLogo, changeTheme } = useContext(CompanyContext);
  const [uploadedLogo, setUploadedLogo] = useState<{ file?: AttachedDocument; error?: string }>({});

  const { formatMessage } = useIntl();
  const { primary, secondary } = usePalette();

  const onDrop = useCallback(
    async (_: string, value: Array<string | AttachedDocument>) => {
      // @ts-expect-error: error
      if (value.some((v) => !['image/png', 'image/jpeg'].includes(v.file.type))) {
        setUploadedLogo({ error: 'wrong_file_format' });
        // @ts-expect-error: error
      } else if (value.some((v) => v.file.size / 1024 / 1024 > 5)) {
        setUploadedLogo({ error: 'file_is_too_big' });
      } else {
        setUploadedLogo({ file: value[0] as AttachedDocument });
        await uploadLogo(value[0] as AttachedDocument, id);
      }
    },
    [id, uploadLogo]
  );

  const referralLink = `${getTenantUrl(id)}/${routes.newReferral.replace(':token', companyInfo.referralLinkToken)}`;
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <InfoCard
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            height: 'auto',
            p: '24px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flex: '0.5' }}>
            <Typography
              variant='h4'
              color='secondary.700'
              sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 600, mb: '12px' }}
            >
              {formatMessage({ id: 'colour_theme' })}
            </Typography>
            <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: 'secondary.700' }}>
              {formatMessage({ id: 'choose_accent_colour' })}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: '8px', flex: '1', justifyContent: 'flex-end' }}>
            {AVAILABLE_COLORS.map(([name, color]) => (
              <Button
                onClick={async () => {
                  await changeTheme(name);
                }}
                key={color}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  padding: 0,
                  borderRadius: '12px',
                  backgroundColor: color === primary[600] ? secondary[100] : undefined,
                  border: color === primary[600] ? `1px solid ${secondary[500]}` : undefined,
                  '&:hover': { background: 'none' }
                }}
              >
                <Box
                  sx={{
                    backgroundColor: color,
                    width: '30px',
                    height: '30px',
                    borderRadius: '8px'
                  }}
                ></Box>
              </Button>
            ))}
          </Box>
        </InfoCard>

        <InfoCard sx={{ width: '100%', boxSizing: 'border-box', height: 'auto', p: '24px', display: 'flex' }}>
          <Box sx={{ flex: '0.5' }}>
            <Typography
              variant='h4'
              color='secondary.700'
              sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 600, mb: '12px' }}
            >
              {formatMessage({ id: 'logo' })}
            </Typography>
            <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: 'secondary.700' }}>
              {formatMessage({ id: 'logo_explanation' })}
            </Typography>
          </Box>
          <Box sx={{ flex: '1' }}>
            <CustomDropzone
              name='logo'
              onChange={onDrop}
              contentDirection='row'
              docTypes={[FileFormat.PNG, FileFormat.JPG_JPEG]}
              error={uploadedLogo.error && formatMessage({ id: 'wrong_format' }, { formats: 'PNG, JPG, JPEG' })}
              title={(uploadedLogo.error ?? uploadedLogo.file?.filename ?? logo?.name) || undefined}
              singleUpload
              onReset={async () => {
                await resetLogo(id);
                setUploadedLogo({});
              }}
            />
          </Box>
        </InfoCard>

        <InfoCard
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            height: 'auto',
            p: '24px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <FooterLinksForm links={companyInfo} />
        </InfoCard>

        <InfoCard
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            height: 'auto',
            p: '24px',
            display: 'flex'
          }}
        >
          <FAQForm faqLink={companyInfo.faqLink} />
        </InfoCard>

        <InfoCard
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            height: 'auto',
            p: '24px',
            display: 'flex'
          }}
        >
          <Box sx={{ flex: '0.5' }}>
            <Typography
              variant='h4'
              color='secondary.700'
              sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 600, mb: '12px' }}
            >
              {formatMessage({ id: 'referral_link' })}
            </Typography>
            <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: 'secondary.700' }}>
              {formatMessage({ id: 'copy_referral_link' })}
            </Typography>
          </Box>
          <Box sx={{ flex: '0.5', display: 'flex', gap: '16px' }}>
            <TextField
              label={formatMessage({ id: 'referral_link' })}
              value={referralLink}
              InputProps={{ readOnly: true }}
            />
            <Button
              variant='contained'
              onClick={async () => {
                await navigator.clipboard.writeText(referralLink);
                toast(<SuccessSnackbar title={formatMessage({ id: 'referral_link_copied' })} />);
              }}
            >
              {formatMessage({ id: 'copy' })}
            </Button>
          </Box>
        </InfoCard>
      </Box>
    </Box>
  );
};
