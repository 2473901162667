import { Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { EmptyTableBox, StyledTablePagination } from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { type WithoutGQLType } from 'shared/types/utils';
import { type FormQuestions_formQuestions } from '../__graphql__/FormQuestions';
import { QuestionVariablesOrderField, useFormVariables } from '../ViewForm/Properties/hooks';
import { shouldExcludeComponentFromIssuers } from './utils';

interface Props {
  choosenId: string;
  onChange: (item: WithoutGQLType<FormQuestions_formQuestions>) => void;
}

export const ComponentsTable: FC<Props> = (props) => {
  const { onChange, choosenId } = props;
  const { formatMessage } = useIntl();
  const { component, section } = useContext(FormBuilderContext);
  const {
    items,
    total,
    pagination,
    sorting: { createSortHandler, direction, field }
  } = useFormVariables({
    aggregator: (q) => (!component ? shouldExcludeComponentFromIssuers(q, section?.id!) : q.id !== component?.id)
  });

  const emptyRows = pagination.getEmptyRows(total);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel onClick={createSortHandler(QuestionVariablesOrderField.Variable)} hideSortIcon={true}>
                  {formatMessage({ id: 'variable' })}
                  <SortIcon active={field === QuestionVariablesOrderField.Variable} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={createSortHandler(QuestionVariablesOrderField.Description)}
                  hideSortIcon={true}
                >
                  {formatMessage({ id: 'description' })}
                  <SortIcon active={field === QuestionVariablesOrderField.Description} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={createSortHandler(QuestionVariablesOrderField.SectionName)}
                  hideSortIcon={true}
                >
                  {formatMessage({ id: 'section_name' })}
                  <SortIcon active={field === QuestionVariablesOrderField.SectionName} direction={direction} />
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Radio
                    checked={item.id === choosenId}
                    onClick={() => {
                      onChange(item);
                    }}
                  />
                </TableCell>
                <TableCell>{item.variable}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.section.name}</TableCell>
              </TableRow>
            ))}
            {!items.length && pagination.page === 0 && (
              <TableRow
                style={{
                  height: 64 * pagination.rowsPerPage,
                  background: 'inherit'
                }}
              >
                <TableCell
                  colSpan={4}
                  style={{
                    height: 64 * pagination.rowsPerPage,
                    padding: 0
                  }}
                >
                  <EmptyTableBox />
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 64 * emptyRows
                }}
              >
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination count={total} {...pagination} />
    </>
  );
};
