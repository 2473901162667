import { Box, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { type FC } from 'react';
import { CheckboxComponent } from 'shared/components/form/CheckboxComponent';

interface Props {
  label: string;
  name: string;
  isReadOnly?: boolean;
}

export const CheckboxRenderer: FC<Props> = ({ isReadOnly = false, name, label }) => {
  const { submitForm } = useFormikContext();

  return (
    <Box sx={{ display: 'flex', mt: '10px' }}>
      <Field name={name} disabled={isReadOnly} component={CheckboxComponent} onChange={submitForm} />
      <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
        {label}
      </Typography>
    </Box>
  );
};
