import { useMutation } from '@apollo/client';
import { type ErrorResponse } from '@apollo/client/link/error';
import { Button } from '@mui/material';
import { Role, type SuperAdminInviteInput } from '__graphql__/globalTypes';
import { Field } from 'formik';
import { type FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { FormDialog, Input, Select } from 'shared/components';
import ErrorSnackbar from 'shared/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'shared/components/Snackbars/SuccessSnackbar';
import { isConflictError } from 'shared/utils/errors';
import { Validator } from 'shared/utils/validator';
import { type InviteSuperAdmin, type InviteSuperAdminVariables } from './__graphql__/InviteSuperAdmin';
import { inviteSuperAdminMutation } from './api';

export const AddForm: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Field
        id='firstName'
        name='firstName'
        label={intl.formatMessage({ id: 'name' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3))}
        sx={{
          mt: '4px',
          marginBottom: '12px'
        }}
      />
      <Field
        id='lastName'
        name='lastName'
        label={intl.formatMessage({ id: 'surname' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3))}
        sx={{
          mt: '4px',
          marginBottom: '12px'
        }}
      />
      <Field
        id='email'
        name='email'
        label={intl.formatMessage({ id: 'email' })}
        type='email'
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.email())}
        sx={{
          marginBottom: '12px'
        }}
      />
      <Field
        disabled
        id='role'
        name='role'
        label={intl.formatMessage({ id: 'role' })}
        component={Select}
        validate={Validator.pipe(Validator.methods.required())}
        options={[
          {
            value: Role.SUPER_ADMIN,
            label: intl.formatMessage({ id: `user.role.${Role.SUPER_ADMIN}` })
          }
        ]}
      />
    </>
  );
};

export const AddWidget: FC = () => {
  const intl = useIntl();

  const [isModalOpen, setModalOpen] = useState(false);

  const [inviteSuperAdmin, { loading }] = useMutation<InviteSuperAdmin, InviteSuperAdminVariables>(
    inviteSuperAdminMutation,
    {
      refetchQueries: ['SuperAdmins'],
      awaitRefetchQueries: true,
      context: API_CONTEXT.SUPERADMIN
    }
  );

  return (
    <>
      <Button
        type='button'
        size='medium'
        variant='contained'
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {intl.formatMessage({ id: 'new_user' })}
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        header={intl.formatMessage({ id: 'add_new_superadmin' })}
        size='xs'
        loading={loading}
        initialValues={{
          role: Role.SUPER_ADMIN
        }}
        confirmBtnName={intl.formatMessage({ id: 'save_and_send_email' })}
        onSubmit={async (
          { role: _, ...values }: SuperAdminInviteInput & { name: string; role: Role },
          formikHelpers
        ) => {
          try {
            await inviteSuperAdmin({ variables: { data: values } });
            setModalOpen(false);
            toast(
              <SuccessSnackbar
                title={intl.formatMessage({ id: 'sa_created' })}
                message={intl.formatMessage({ id: 'sa_created.description' })}
              />,
              {
                position: toast.POSITION.TOP_RIGHT
              }
            );
          } catch (e) {
            if (isConflictError((e as ErrorResponse).graphQLErrors?.[0].extensions.code as string)) {
              toast(
                <ErrorSnackbar
                  title={intl.formatMessage({ id: 'email_alredy_in_use' })}
                  message={intl.formatMessage({ id: 'you_cant_create_user_with_existing_email' })}
                />,
                {
                  position: toast.POSITION.BOTTOM_CENTER
                }
              );
              formikHelpers?.setFieldError('email', ' ');
            }
          }
        }}
      >
        <AddForm />
      </FormDialog>
    </>
  );
};
