import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const SadSmileIcon: FC<IconProps> = (props) => {
  const { color, size = 48 } = props;
  const { error } = usePalette();
  const currentColor = color ?? error[500];

  return (
    <svg width={size} height={size} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M24.0006 42.2858C28.8502 42.2858 33.5013 40.3593 36.9305 36.93C40.3597 33.5008 42.2863 28.8497 42.2863 24.0001C42.2863 19.1504 40.3597 14.4994 36.9305 11.0701C33.5013 7.64088 28.8502 5.71436 24.0006 5.71436C19.1509 5.71436 14.4998 7.64088 11.0706 11.0701C7.64137 14.4994 5.71484 19.1504 5.71484 24.0001C5.71484 28.8497 7.64137 33.5008 11.0706 36.93C14.4998 40.3593 19.1509 42.2858 24.0006 42.2858Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.2857 20.5714C19.5481 20.5714 20.5714 19.5481 20.5714 18.2857C20.5714 17.0233 19.5481 16 18.2857 16C17.0233 16 16 17.0233 16 18.2857C16 19.5481 17.0233 20.5714 18.2857 20.5714Z'
        fill={currentColor}
      />
      <path
        d='M29.7154 20.5714C30.9778 20.5714 32.0011 19.5481 32.0011 18.2857C32.0011 17.0233 30.9778 16 29.7154 16C28.453 16 27.4297 17.0233 27.4297 18.2857C27.4297 19.5481 28.453 20.5714 29.7154 20.5714Z'
        fill={currentColor}
      />
      <path
        d='M17.1445 30.8573C18.5228 27.8105 20.8085 26.2859 24.0017 26.2859C27.1948 26.2859 29.4805 27.8105 30.8588 30.8573'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
