import { Box, MenuItem, TextField, type TextFieldProps } from '@mui/material';
import { type FieldProps } from 'formik';
import { path, split } from 'ramda';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';
import { ChevronIcon } from './icons';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    options: Array<{ label: string; value: string; disabled?: boolean }>;
  };

const SelectComponent = ({
  disabled,
  field,
  error = false,
  options,
  placeholder = '',
  ...props
}: Props): JSX.Element => {
  const { secondary } = usePalette();

  return (
    <TextField
      select
      disabled={disabled}
      error={error}
      size='small'
      variant='outlined'
      {...field}
      {...props}
      SelectProps={{
        displayEmpty: true,
        renderValue: (value: unknown) => (
          <>
            {value ? (
              options?.find((o) => o.value === value)?.label
            ) : (
              <span style={{ color: secondary[600] }}>{placeholder}</span>
            )}
          </>
        ),
        IconComponent: (props) => (
          <Box {...props} sx={{ pr: '10px', display: 'flex' }}>
            {props.SelectProps?.IconComponent ?? <ChevronIcon />}
          </Box>
        )
      }}
    >
      {options?.map((item) => (
        <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const Select: FC<Props> = ({ field, form, ...props }) => {
  const resolvedErrorMessage =
    form && field?.name && (path(split('.', field.name), form.touched) || form.submitCount > 0)
      ? path(split('.', field.name), form.errors)
      : undefined;

  return (
    <SelectComponent
      field={field}
      form={form}
      helperText={resolvedErrorMessage as string | undefined}
      error={!!resolvedErrorMessage}
      {...props}
    />
  );
};
