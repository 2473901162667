import { Box, IconButton, TextField } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { MagnifyingGlassIcon, CrossIcon } from './icons';

interface IProps {
  onChange: (value: string) => void;
  value?: string;
  placeholderId?: string;
}

export const SearchFilter = ({ onChange, value, placeholderId }: IProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <TextField
      placeholder={formatMessage({ id: placeholderId ?? 'search' })}
      onChange={(e: any) => {
        onChange(e.target.value);
      }}
      value={value}
      InputProps={{
        endAdornment: value ? (
          <IconButton
            onClick={() => {
              onChange('');
            }}
          >
            <CrossIcon />
          </IconButton>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <MagnifyingGlassIcon />
          </Box>
        )
      }}
      className='search'
    />
  );
};
