import queryString, { type ParseOptions } from 'query-string';
import { omit } from 'ramda';
import { useCallback, useMemo } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

const getNumber = (number: any, defaultNumber?: number): number => {
  return isNaN(number) ? number : defaultNumber;
};

export interface FilterFragment {
  id: string;
  value: string | Array<string | null> | null;
}

export interface SortFragment {
  id: string;
  desc?: boolean;
}

export interface FilterData {
  filters?: FilterFragment[];
  sort?: SortFragment[];
  pageSize?: number;
  pageIndex?: number;
}

export enum SortOrder {
  DESC = 'desc',
  ASC = 'asc'
}

const queryParseOptions: ParseOptions = {
  arrayFormat: 'bracket',
  parseNumbers: true,
  parseBooleans: true
};

const convertFiltersToQuery = (data: FilterData): Record<string, string | number | boolean | undefined> => ({
  ...data.filters?.reduce((acc, val) => ({ ...acc, [val.id]: val.value }), {}),
  ...(data.sort?.length
    ? {
      sortBy: data.sort[0].id,
      sortOrder: data.sort[0].desc ? SortOrder.DESC : SortOrder.ASC
    }
    : {}),
  pageSize: data?.pageSize,
  pageIndex: data?.pageIndex
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFiltersQuery = (
  { defaultPageSize = 10, defaultPageIndex = 0 } = {
    defaultPageSize: 10,
    defaultPageIndex: 0
  }
) => {
  const history = useNavigate();
  const { search } = useLocation();

  const { query: parsedQuery } = useMemo(() => queryString.parseUrl(search, queryParseOptions), [search]);

  const parsedFilters = useMemo(
    () => omit(['pageSize', 'pageIndex', 'sortBy', 'sortOrder'], parsedQuery),
    [parsedQuery]
  );

  const pageSize = useMemo(
    () => getNumber(parsedQuery.pageSize, defaultPageSize),
    [parsedQuery.pageSize, defaultPageSize]
  );
  const pageIndex = useMemo(
    () => getNumber(parsedQuery.pageIndex, defaultPageIndex),
    [parsedQuery.pageIndex, defaultPageIndex]
  );

  const sort = useMemo(() => {
    if (parsedQuery.sortBy && parsedQuery.sortOrder) {
      const sortFragment = {
        id: parsedQuery.sortBy,
        desc: parsedQuery.sortOrder === SortOrder.DESC
      } as SortFragment;
      return [sortFragment];
    }
    return [];
  }, [parsedQuery.sortBy, parsedQuery.sortOrder]);

  const filters: FilterFragment[] = useMemo(
    () =>
      Object.entries(parsedFilters).map(([id, value]) => ({
        id,
        value
      })),
    [parsedFilters]
  );

  const pushQuery = useCallback(
    (data: FilterData, state?: any) => {
      history({
        ...history,
        search: queryString.stringify(convertFiltersToQuery(data), queryParseOptions)
      }, { state });
    },
    [history]
  );

  const push = useCallback(
    (data: Record<string, string | string[] | number>) => {
      history({
        ...history,
        search: queryString.stringify(data, queryParseOptions)
      });
    },
    [history]
  );

  return {
    parsedQuery,
    parsedFilters,
    filters,
    pageIndex,
    pageSize,
    sort,
    pushQuery,
    push,
    convertFiltersToQuery
  };
};
