import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const CloudIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.0004 23.9999V41.9999M24.0004 23.9999L19.0004 27.9999M24.0004 23.9999L29.0004 27.9999M10.0684 18.2339C8.17683 18.7099 6.52417 19.8602 5.42092 21.4688C4.31767 23.0773 3.83978 25.0334 4.07703 26.9695C4.31427 28.9055 5.25034 30.6883 6.70934 31.9829C8.16835 33.2775 10.0499 33.9947 12.0004 33.9999H14.0004'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.6603 14.2759C30.9767 11.5691 29.2895 9.22341 26.9406 7.71444C24.5917 6.20547 21.7569 5.64611 19.0108 6.14973C16.2648 6.65336 13.813 8.18229 12.1524 10.4266C10.4919 12.6709 9.74684 15.4627 10.0683 18.2359C10.0683 18.2359 10.3743 19.9999 11.0003 20.9999'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M34 34C35.4121 33.999 36.8079 33.699 38.0957 33.1197C39.3835 32.5404 40.534 31.6949 41.4715 30.6389C42.4089 29.583 43.1121 28.3404 43.5348 26.9931C43.9574 25.6457 44.0899 24.2241 43.9235 22.8219C43.7572 21.4196 43.2957 20.0685 42.5696 18.8575C41.8434 17.6464 40.869 16.6029 39.7105 15.7956C38.5519 14.9882 37.2356 14.4354 35.848 14.1735C34.4604 13.9116 33.0331 13.9465 31.66 14.276L29 15'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
