import { type Theme, createTheme } from '@mui/material';
import { Font, type ThemeColor } from '__graphql__/globalTypes';
import paletteSrc from './palettes.json';

interface ThemeProps {
  themeColor: ThemeColor;
  font: Font;
}

const MAPPED_FONTS = {
  [Font.MYRIAD_PRO]: 'Myriad Pro',
  [Font.ASSISTANT]: 'Assistant'
};

export function setupTheme(props: ThemeProps): Theme {
  const { themeColor, font } = props;
  const fontFamily = MAPPED_FONTS[font];
  const palette = paletteSrc[themeColor as keyof typeof paletteSrc];
  const theme = createTheme({
    spacing: 4,
    palette,
    typography: {
      fontFamily,
      h1: {
        fontFamily: font === Font.MYRIAD_PRO ? 'omnes-arabic' : fontFamily,
        fontWeight: 400,
        fontSize: '42px',
        lineHeight: '48px'
      },
      h2: {
        fontFamily: font === Font.MYRIAD_PRO ? 'omnes-arabic' : fontFamily,
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '48px',
        '@media only screen and (max-width: 479px)': {
          fontSize: '24px',
          lineHeight: '24px'
        }
      },
      h3: {
        fontFamily,
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: '32px'
      },
      h5: {
        fontFamily,
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '32px'
      },
      h6: {
        fontFamily,
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '28px'
      },
      subtitle1: {
        fontFamily,
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px'
      },
      subtitle2: {
        fontFamily,
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '28px'
      },
      body1: {
        fontFamily,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px'
      },
      body2: {
        fontFamily,
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '28px'
      },
      caption: {
        fontFamily,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            '.MuiFormHelperText-root': {
              color: `${palette.error[500]} !important`
            }
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            '&.pickers-day': {
              width: '32px',
              height: '32px',
              margin: '0 4px 4px',
              borderRadius: 0,
              color: palette.secondary[800],
              fontSize: '16px',
              lineHeight: '20px',
              '&:hover, &.Mui-selected:hover': {
                background: palette.primary[200]
              },
              '&.Mui-selected, &.Mui-selected:focus': {
                border: `1px solid ${palette.primary[600]}`,
                background: palette.primary[300],
                color: palette.secondary[800]
              },
              '&.MuiPickersDay-dayOutsideMonth': {
                color: palette.secondary[500]
              }
            }
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'text' },
            style: {
              border: 'none',
              padding: '10px',
              minWidth: '24px',
              justifyContent: 'start'
            }
          }
        ],
        styleOverrides: {
          root: {
            fontSize: '16px',
            lineHeight: '28px',
            fontWeight: 600,
            textWrap: 'nowrap',
            textTransform: 'none',
            height: '44px',
            border: `1px solid ${palette.primary[600]}`,
            borderRadius: '8px',
            padding: '8px 32px',
            '&, &:hover': {
              boxShadow: 'none'
            },
            '@media only screen and (max-width: 479px)': {
              paddingLeft: '16px',
              paddingRight: '16px'
            }
          },
          textPrimary: {
            color: palette.secondary[700],
            '&:hover': {
              background: palette.primary[300]
            },
            '&:disabled': {
              color: palette.secondary[500]
            },
            '&:active': {
              background: palette.primary[300]
            }
          },
          outlinedPrimary: {
            color: palette.secondary[800],
            '&:hover': {
              background: palette.primary[300],
              borderColor: palette.primary[700]
            },
            '&:disabled': {
              color: palette.info[500],
              borderColor: palette.info[500]
            },
            '&:active': {
              background: palette.primary[400],
              borderColor: palette.primary[800]
            }
          },
          containedPrimary: {
            background: palette.primary[600],
            color: '#FFFFFF',
            '&:hover': {
              background: palette.primary[700],
              borderColor: palette.primary[700]
            },
            '&:disabled': {
              background: palette.info[500],
              borderColor: palette.info[500],
              color: '#FFF'
            },
            '&:active': {
              background: palette.primary[800],
              borderColor: palette.primary[800]
            }
          },
          containedError: {
            background: palette.error[200],
            border: `1px solid ${palette.error[200]}`,
            '&:hover': {
              background: palette.error[300],
              borderColor: palette.error[300]
            },
            '&:disabled': {
              background: palette.error[100],
              borderColor: palette.error[100],
              color: '#FFF'
            }
          },
          sizeSmall: {
            width: 'auto'
          },
          sizeMedium: {
            paddingX: '32px'
          },
          sizeLarge: {
            width: '320px',
            minWidth: '265px'
          },
          containedInherit: {
            color: '#4A4A4A',
            backgroundColor: 'transparent'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
            '&.search': {
              width: '320px'
            },
            '& > .MuiFormHelperText-root': {
              color: palette.secondary[600],
              fontSize: '10px',
              lineHeight: '12px',
              marginTop: '2px',
              '&.Mui-error.Mui-focused': {
                display: 'none'
              }
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: `1px solid ${palette.secondary[400]}`
          },
          root: {
            width: '100%',
            height: '44px',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            '&.filter': {
              width: '240px'
            },
            '&.search': {
              width: '320px'
            },
            '& > button': {
              '& svg': {
                color: palette.primary[600],
                '& > path': {
                  stroke: 'currentColor'
                }
              },
              '&:hover > svg': {
                color: palette.primary[700]
              },
              '&:active > svg': {
                color: palette.primary[800]
              }
            },
            '&.Mui-focused fieldset, &:hover:not(.Mui-disabled) fieldset': {
              borderColor: `${palette.primary[600]} !important`,
              borderWidth: '1px !important'
            },
            '& > .MuiInputAdornment-root': {
              marginLeft: '4px',
              marginRight: '4px',
              '&:first-of-type:not(:last-of-type)': {
                marginRight: '14px'
              }
            },
            '& > .MuiOutlinedInput-adornedEnd, & .MuiInputAdornment-positionEnd > button, & svg': {
              color: palette.primary[600]
            },
            '&.Mui-error:not(.Mui-focused)': {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: palette.error[100]
              },
              '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: `${palette.error[500]} !important`
              },
              '& input': {
                color: palette.error[500]
              },
              '& input::placeholder': {
                color: palette.error[200]
              },
              '& > .MuiOutlinedInput-adornedEnd, & .MuiInputAdornment-positionEnd > button, & svg > path': {
                stroke: palette.error[200]
              }
            }
          },
          input: {
            fontSize: '16px',
            lineHeight: '28px',
            height: '28px',
            color: palette.secondary[700],
            padding: '8px 16px',
            '&::placeholder': {
              color: palette.secondary[600],
              opacity: 1
            }
          },
          multiline: {
            height: 'auto',
            display: 'flex',
            alignItems: 'flex-start',
            padding: '8px 16px',
            '& > textarea': {
              padding: 0
            },
            '& .MuiInputAdornment-root': {
              marginTop: '14px'
            }
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: palette.secondary[800]
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.secondary[700],
            '&.Mui-focused': {
              color: palette.primary[600]
            },
            '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
              transform: 'translate(14px, 12px) scale(1)',
              maxWidth: '89%'
            },
            '&:not(.Mui-focused):not(.MuiFormLabel-filled):not(.Mui-error)': {
              color: palette.secondary[600]
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            '&.MuiInputBase-inputAdornedEnd': {
              paddingRight: '52px'
            }
          },
          icon: {
            color: palette.primary[600],
            width: '24px',
            height: '24px',
            top: 'calc(50% - 12px)'
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '8px !important'
          },
          list: { width: 'auto !important' }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: '4px 0px',
            border: `1px solid ${palette.primary[600]}`,
            borderRadius: '8px'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            outline: 'none',
            display: 'block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            maxHeight: '140px'
          },
          inputRoot: {
            flexWrap: 'inherit'
          },
          popper: {
            border: `1px solid ${palette.primary[600]}`,
            borderRadius: '8px',
            overflowY: 'auto',
            '& > div': {
              boxShadow: 'none'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            margin: 0
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        },
        styleOverrides: {
          root: {
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '28px',
            color: palette.secondary[700],
            '&.active': {
              color: palette.primary[800]
            },
            '&.footer': {
              fontWeight: 400,
              lineHeight: '20px',
              color: palette.secondary[800],
              '@media only screen and (max-width: 767px)': {
                fontSize: '14px',
                lineHeight: '16px'
              }
            }
          }
        }
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border: `1px solid ${palette.secondary[300]}`,
            borderRadius: '8px'
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: '51px',
            background: '#FFFFFF',

            '&.disabled td': {
              color: palette.secondary[500]
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 400,
            color: palette.secondary[800],
            padding: '16px 16px 15px',
            '&.badge': {
              '& > div': {
                padding: '8px 16.5px',
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 400,
                borderRadius: '4px',
                width: '100%',
                maxWidth: '80px',
                margin: '0 auto',
                textAlign: 'center'
              },
              '&.status > div': {
                padding: '8px 23px'
              }
            },
            '&.disabled, &.disabled > *': {
              color: palette.secondary[500]
            }
          },
          head: {
            color: palette.secondary[600],
            lineHeight: '28px',
            padding: '12px 15px 11px 16px'
          }
        }
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            width: '100%',
            justifyContent: 'space-between'
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            height: 'fit-content',
            padding: '16px',
            backgroundColor: palette.info[300],
            border: `1px solid ${palette.secondary[300]}`,
            borderRadius: '16px',
            '& .MuiTabs-flexContainerVertical': {
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            },
            '@media only screen and (max-width: 767px)': {
              width: 'calc(100vw - 48px)',
              border: 'none',
              background: 'none',
              padding: '0'
            },
            '&.nested': {
              padding: '0',
              border: 'none',
              borderRadius: '0'
            }
          },
          indicator: {
            display: 'none'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            border: '1px solid #FFF',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 400,
            textTransform: 'none',
            color: palette.secondary[800],
            background: '#FFF',
            minHeight: '44px',
            padding: '10px 9px 10px 10px',
            justifyContent: 'start',
            textAlign: 'start',
            borderRadius: '8px',
            transition: 'all .3s',
            '& svg': {
              color: palette.primary[600],
              '&:not(.MuiTab-iconWrapper) > path': {
                stroke: 'currentColor'
              }
            },
            '&:hover': {
              background: palette.secondary[100],
              borderColor: palette.secondary[100],
              '& svg': {
                color: palette.primary[700]
              }
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: palette.secondary[100],
              borderColor: palette.secondary[400],
              '& svg': {
                color: palette.primary[800]
              }
            },
            '& > div.MuiTab-iconWrapper': {
              minWidth: '18px',
              minHeight: '18px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: palette.secondary[500],
              margin: '1px 11px 1px 1px',
              '& > p': {
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 600,
                color: palette.secondary[500]
              }
            },
            '&.done': {
              border: `1px solid ${palette.secondary[300]}`,
              background: palette.info[300],
              '& > .MuiTab-iconWrapper': {
                marginRight: '10px',
                margin: '0 10px 0 0 !important',
                width: '24px',
                height: '24px',
                minHeight: '24px',
                minWidth: '24px'
              },
              '&:hover': {
                background: palette.info[400],
                borderColor: palette.secondary[400]
              },
              '&:active': {
                backgroundColor: palette.info[400],
                borderColor: palette.secondary[500]
              }
            },
            '&.Mui-selected': {
              border: `1px solid ${palette.info[50]}`,
              background: palette.primary[200],
              '& > .MuiTab-iconWrapper': {
                borderColor: palette.primary[600],
                margin: '1px 11px 1px 1px',
                '& > p': {
                  color: palette.primary[600]
                }
              },
              '&:hover': {
                background: palette.primary[300]
              },
              '&:active': {
                backgroundColor: palette.primary[300],
                borderColor: palette.primary[600]
              }
            },
            '@media only screen and (max-width: 767px)': {
              maxWidth: 'none'
            },
            '&.nested': {
              alignItems: 'start',
              paddingLeft: '62px',
              minHeight: 'unset',
              '&.Mui-selected': {
                paddingLeft: '61px'
              }
            }
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '34px 41px 20px 41px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '0px 41px 18px 41px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: 'space-between',
            padding: '20px 41px 43px 41px'
          }
        }
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            gap: '16px',
            border: 'none',

            '&.stacked': {
              flexWrap: 'wrap',
              gap: 0,
              '& > button': {
                '&.Mui-selected': {
                  backgroundColor: `${palette.primary[400]} !important`,
                  borderColor: `${palette.primary[600]} !important`,
                  zIndex: 1
                },
                '&:first-of-type:not(:last-of-type)': {
                  borderRadius: '8px 0px 0px 8px'
                },
                '&:last-child:not(:first-of-type)': {
                  borderRadius: '0px 8px 8px 0px'
                }
              }
            }
          },
          grouped: {
            '&, &:not(:first-of-type), &:not(:last-of-type)': {
              border: `1px solid ${palette.secondary[400]}`,
              background: palette.info[200],
              borderRadius: '8px',
              '& > svg': {
                minWidth: '24px'
              },
              '&.Mui-selected': {
                border: `1px solid ${palette.success[300]}`,
                backgroundColor: palette.success[100]
              }
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            marginRight: '16px'
          }
        }
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            '& button': {
              borderColor: palette.secondary[400],
              color: palette.secondary[800],
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 400,
              borderRadius: '4px',
              backgroundColor: '#FFFFFF',
              '&.Mui-selected': {
                borderColor: palette.primary[600],
                backgroundColor: palette.primary[400]
              },
              '& > svg': {
                color: palette.info[600]
              }
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              '& svg': {
                color: palette.primary[800]
              }
            },
            '& svg': {
              color: palette.primary[600]
            }
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& > .MuiDrawer-paper': {
              borderRight: `1px solid ${palette.secondary[100]}`
            }
          }
        }
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            flexWrap: 'wrap',
            gap: '8px'
          },
          grouped: {
            '&.Mui-selected': {
              borderColor: palette.primary[600],
              backgroundColor: palette.primary[400]
            }
          }
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderColor: palette.primary[600],
            backgroundColor: palette.primary[400],
            padding: '7px 23px',
            fontSize: '16px',
            lineHeight: '28px',
            fontWeight: 400,
            textTransform: 'none',
            letterSpacing: 'normal'
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: '60px',
            height: '39px',
            '.Mui-checked .MuiSwitch-thumb': {
              backgroundColor: palette.primary[600],
              border: `1px solid ${palette.primary[700]}`
            },
            '.Mui-checked + .MuiSwitch-track': {
              backgroundColor: `${palette.primary[400]} !important`,
              border: `1px solid ${palette.info[50]}`
            }
          },
          track: {
            backgroundColor: palette.secondary[200],
            border: `1px solid ${palette.secondary[400]}`
          },
          thumb: {
            borderRadius: '8px',
            backgroundColor: palette.secondary[400],
            border: `1px solid ${palette.secondary[500]}`
          }
        }
      }
    }
  });

  return theme;
}
