import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { FormOrderField, FormStatus, OrderDirection, type FormOrder } from '__graphql__/globalTypes';
import { pick } from 'ramda';
import { useEffect, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Loader, PageTitle, PageWrapper, SearchFilter, SelectFilter, StyledTablePagination } from 'shared/components';
import { useFiltersQuery, useTablePagination, useTableSort } from 'shared/hooks';
import { type Forms, type FormsVariables } from './__graphql__/Forms';
import { getFormsQuery } from './api';
import { FormsTable } from './FormsTable';
import { NewFormWidget } from './FormWidget';

export const FormsPage: FC = () => {
  const { formatMessage } = useIntl();
  const { parsedQuery, pushQuery } = useFiltersQuery();
  const [search, setSearch] = useState<string>('');
  const { state } = useLocation();
  const [statuses, setStatuses] = useState<FormStatus[]>((parsedQuery.statuses ?? []) as FormStatus[]);
  const { offset, limit, ...tablePaginationProps } = useTablePagination({
    defaultPage: parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0
  });
  const sort = useTableSort<FormOrderField>({
    defaultField: (parsedQuery?.sortBy ?? FormOrderField.status) as FormOrderField,
    defaultDirection: (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
  });

  const { data, loading } = useQuery<Forms, FormsVariables>(getFormsQuery, {
    variables: {
      limit,
      offset,
      orderBy: pick(['field', 'direction'], sort) as FormOrder,
      search,
      statuses
    }
  });

  useEffect(() => {
    pushQuery(
      {
        filters: [
          ...(statuses.length
            ? [
                {
                  id: 'statuses',
                  value: statuses
                }
              ]
            : []),
          ...(search
            ? [
                {
                  id: 'search',
                  value: search
                }
              ]
            : [])
        ],
        sort: sort?.field
          ? [
              {
                id: sort?.field,
                desc: sort?.direction === OrderDirection.desc
              }
            ]
          : [],
        pageIndex: tablePaginationProps.page
      },
      state
    );
  }, [sort?.direction, sort?.field, pushQuery, statuses, search, tablePaginationProps.page, state]);

  useEffect(() => {
    setSearch((parsedQuery?.search as string) ?? '');
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle>
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '& > *:not(:last-child)': {
                  marginRight: '16px'
                }
              }}
            >
              <SearchFilter placeholderId='form_name' value={search} onChange={setSearch} />
              <SelectFilter
                defaultValue={[]}
                values={statuses}
                placeholder={formatMessage({ id: 'status' })}
                allItemLabel={formatMessage({ id: 'all_statuses' })}
                options={[FormStatus.aPUBLISHED, FormStatus.bUNPUBLISHED, FormStatus.cDRAFT].map((status) => ({
                  value: status,
                  label: formatMessage({ id: `form.status.${status}` })
                }))}
                onApply={(values) => {
                  setStatuses(values);
                }}
              />
            </Box>
            <NewFormWidget />
          </>
        </PageTitle>
        {loading ? (
          <Loader />
        ) : (
          <PageWrapper>
            <>
              <FormsTable
                data={data?.forms.data}
                page={tablePaginationProps.page}
                rowsPerPage={tablePaginationProps.rowsPerPage}
                sort={sort}
                search={search}
              />
              <StyledTablePagination count={data?.forms.total ?? 0} {...tablePaginationProps} />
            </>
          </PageWrapper>
        )}
      </Box>
    </Box>
  );
};
