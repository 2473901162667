import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { CrossIcon } from 'shared/components/icons';
import { usePalette } from 'shared/hooks';
import { ComponentsTable } from './ComponentsTable';
import { SectionsTable } from './SectionsTable';
import { type RuleBuilderValues, type FormItem } from './types';

interface Props {
  parentIndexId: number;
  indexId: number;
  onClose: () => void;
}

enum Tab {
  Components = 'components',
  Sections = 'sections'
}

export const TargetWidget: FC<Props> = ({ onClose, parentIndexId, indexId }) => {
  const { formatMessage } = useIntl();
  const { secondary, primary } = usePalette();
  const [tab, setTab] = useState(Tab.Components);
  const { values, setFieldValue } = useFormikContext<RuleBuilderValues>();

  const ruleValues = values.rules[parentIndexId];
  const actionValues = ruleValues.actions[indexId];
  const currentFormItem: FormItem | null =
    actionValues.questionId || actionValues.sectionId
      ? {
          id: actionValues.questionId || actionValues.sectionId,
          variable: actionValues.targetVariable,
          targetType: actionValues.questionId ? 'question' : 'section'
        }
      : null;

  const [target, setTarget] = useState<FormItem | null>(currentFormItem);

  return (
    <Dialog open sx={{ '.MuiDialog-container > .MuiPaper-root': { maxWidth: '800px', width: '100%' } }}>
      <DialogTitle>
        <Typography color='secondary.800' variant='h5'>
          {formatMessage({ id: 'select_component_section' })}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ '& path': { stroke: secondary[700] }, '&:hover path': { stroke: primary[600] } }}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ToggleButtonGroup
          value={tab}
          sx={{ color: secondary[800], mb: '16px' }}
          exclusive
          onChange={(e, v) => {
            if (v !== null) {
              setTab(v);
            }
          }}
          orientation='horizontal'
          className='stacked'
        >
          {Object.values(Tab).map((i) => (
            <ToggleButton key={i} value={i}>
              {formatMessage({ id: i })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {tab === Tab.Components && (
          <ComponentsTable
            choosenId={target?.id ?? ''}
            onChange={({ id, variable }) => {
              setTarget({ id, variable, targetType: 'question' });
            }}
          />
        )}
        {tab === Tab.Sections && (
          <SectionsTable
            choosenId={target?.id ?? ''}
            onChange={({ id, variable }) => {
              setTarget({ id, variable, targetType: 'section' });
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{formatMessage({ id: 'cancel' })}</Button>
        <Button
          variant='contained'
          onClick={() => {
            if (target) {
              const { targetType, variable, id } = target;
              const path = `rules.${parentIndexId}.actions.${indexId}`;
              if (target.targetType === 'question') {
                setFieldValue(`${path}.sectionId`, '');
              } else {
                setFieldValue(`${path}.questionId`, '');
              }
              setFieldValue(`${path}.${targetType}Id`, id);
              setFieldValue(`${path}.targetVariable`, variable);
              onClose();
            }
          }}
        >
          {formatMessage({ id: 'add_to_expression' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
