import { useQuery } from '@apollo/client';
import { Box, LinearProgress, Typography, linearProgressClasses, useMediaQuery } from '@mui/material';
import { useContext, type FC } from 'react';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { type RegistrationProgress, type RegistrationProgressVariables } from './__graphql__/RegistrationProgress';
import { getRegistrationProgressQuery } from './api';

export const LinearProgressWithLabel: FC = () => {
  const { registration } = useContext(RegistrationContext);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const { data } = useQuery<RegistrationProgress, RegistrationProgressVariables>(getRegistrationProgressQuery, {
    variables: { id: registration?.id ?? '' },
    skip: !registration?.id || !isMobile
  });

  if (!isMobile) {
    return null;
  }

  const value = data?.registrationProgress ?? 0;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 4,
        paddingBottom: '23px',
        '@media only screen and (min-width: 621px)': {
          display: 'none'
        }
      }}
    >
      <Box sx={{ width: '100%', mr: 5 }}>
        <LinearProgress
          variant='determinate'
          color='success'
          sx={{
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: '6px'
            },
            borderRadius: '6px'
          }}
          value={data?.registrationProgress ?? 0}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='primary.main'>{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
