import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const DragAndDropIcon: FC<IconProps> = (props) => {
  const { color, size = 24 } = props;
  const { secondary } = usePalette();
  const currentColor = color ?? secondary[600];

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='6' r='1' fill={currentColor} />
      <circle cx='14' cy='6' r='1' fill={currentColor} />
      <circle cx='10' cy='10' r='1' fill={currentColor} />
      <circle cx='14' cy='10' r='1' fill={currentColor} />
      <circle cx='10' cy='14' r='1' fill={currentColor} />
      <circle cx='14' cy='14' r='1' fill={currentColor} />
      <circle cx='10' cy='18' r='1' fill={currentColor} />
      <circle cx='14' cy='18' r='1' fill={currentColor} />
    </svg>
  );
};
