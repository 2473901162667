import gql from 'graphql-tag';

export const contractorsQuery = gql`
  query Contractors($args: ContractorsArgs!) {
    contractors(args: $args) {
      total
      data {
        id
        email
        firstName
        lastName
        isActive
        role
        status
        title
        phoneNumber
        assignedTo
        role
        manager {
          firstName
          lastName
        }
        registrationsCount
      }
    }
  }
`;
