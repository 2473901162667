import { FC } from 'react';
import { IconProps } from './props';
import { usePalette } from 'shared/hooks';

export const EmptyDocumentWithPenIcon: FC<IconProps> = (props) => {
  const { color, size = 24 } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width={size} height={size} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 14.25V4.75C4 4.21957 4.21071 3.71086 4.58579 3.33579C4.96086 2.96071 5.46957 2.75 6 2.75H14.5L20 8.25V20.75C20 21.2804 19.7893 21.7891 19.4142 22.1642C19.0391 22.5393 18.5304 22.75 18 22.75H12.5'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 2.75V8.75H20M10.42 13.36C10.615 13.165 10.8465 13.0103 11.1013 12.9048C11.3561 12.7992 11.6292 12.7449 11.905 12.7449C12.1808 12.7449 12.4539 12.7992 12.7087 12.9048C12.9635 13.0103 13.195 13.165 13.39 13.36C13.585 13.555 13.7397 13.7865 13.8452 14.0413C13.9508 14.2961 14.0051 14.5692 14.0051 14.845C14.0051 15.1208 13.9508 15.3939 13.8452 15.6487C13.7397 15.9035 13.585 16.135 13.39 16.33L7.95 21.75L4 22.75L4.99 18.8L10.42 13.36Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
