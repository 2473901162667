import { Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useAsync } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { toast } from 'react-toastify';
import { Loader } from 'shared/components';
import SuccessSnackbar from 'shared/components/Snackbars/SuccessSnackbar';
import AuthContext from 'shared/contexts/authContext';
import { FORBIDDEN_ERROR, NOT_FOUND_ERROR } from 'shared/utils/errors';

export const ChangeEmail = (): JSX.Element => {
  const intl = useIntl();

  const { changeEmail, logout } = useContext(AuthContext);
  const { token } = useParams();

  useAsync(async () => {
    if (token) {
      const success = await changeEmail.execute({ token });
      if (success) {
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'email_successfully_changed' })}
            message={intl.formatMessage({ id: 'now_you_can_use_it_to_login' })}
          />
        );
        await logout.execute();
      }
    }
  }, []);

  const mapErrorToExplanation = useCallback(
    (error: string) => {
      switch (error) {
        case FORBIDDEN_ERROR:
          return intl.formatMessage({ id: 'token_expired_try_again' });
        case NOT_FOUND_ERROR:
          return intl.formatMessage({ id: 'wrong_token' });
        default:
          return intl.formatMessage({ id: 'missing_token' });
      }
    },
    [intl]
  );

  if (!token || changeEmail.error) {
    return (
      <Typography
        variant='h2'
        color='error'
        sx={{
          marginBottom: '16px'
        }}
      >
        {mapErrorToExplanation(changeEmail.error as unknown as string)}
      </Typography>
    );
  }

  return (
    <Loader />
  );
};
