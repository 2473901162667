import { AppBar, Box, Button, ClickAwayListener, Grid, IconButton, Link, Toolbar } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { type FC, useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import apolloClient from 'shared/apolloClient';
import { EmptySearchBox, Input } from 'shared/components';
import { ChevronIcon, CircleQuestionIcon, CrossIcon } from 'shared/components/icons';
import routes from 'shared/constants/routes';
import { CompanyContext } from 'shared/contexts';
import AuthContext from 'shared/contexts/authContext';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette } from 'shared/hooks';
import { type ReferralsMatch, type ReferralsMatchVariables } from './__graphql__/ReferralsMatch';
import { referralsMatchQuery } from './api';

export const Header: FC = () => {
  const intl = useIntl();
  const { primary, secondary } = usePalette();
  const { openModal } = useContext(RegistrationContext);

  const { logout } = useContext(AuthContext);
  const { logo } = useContext(CompanyContext);
  const navigate = useNavigate();

  const [matches, setMatches] = useState<string[] | null>(null);

  const onSearchChangeHandler = useCallback(async (query: string) => {
    if (query.length > 2) {
      const mathesResult = await apolloClient.query<ReferralsMatch, ReferralsMatchVariables>({
        query: referralsMatchQuery,
        variables: {
          offset: 0,
          limit: 5,
          query: query.toLowerCase()
        }
      });
      setMatches(
        [
          ...new Set(
            mathesResult.data.registrations.data?.reduce((result, registration) => {
              const fields = [
                registration.firstName,
                registration.lastName,
                `${registration.firstName} ${registration.lastName}`,
                registration.email,
                registration.phoneNumber
              ];
              const matchString = fields.find((v) => (v ? v.toLowerCase().includes(query.toLowerCase()) : false));
              if (matchString) result.push(matchString);

              return result;
            }, [] as string[])
          )
        ] ?? []
      );
    } else setMatches(null);
  }, []);

  return (
    <AppBar position='static' color='inherit' elevation={1} sx={{ p: 0, mb: '1px' }}>
      <Toolbar sx={{ paddingX: '48px !important' }}>
        <Grid container justifyContent='space-between'>
          <Grid item display='flex' alignItems='center'>
            <Link
              onClick={() => {
                navigate(routes.rootForNavigation);
              }}
              sx={{ display: 'flex' }}
              mr='150px'
            >
              <img height='40px' src={logo?.url ? logo.url : '/swift-onboard-logo.svg'} alt='logo' />
            </Link>
          </Grid>
          <Grid item display='flex' gap={8}>
            <Formik
              initialValues={{
                search: ''
              }}
              onSubmit={(values) => {
                navigate(routes.rootForNavigation, { state: { search: values.search } });
                setMatches(null);
              }}
            >
              {({ values, setFieldValue, handleChange }) => (
                <ClickAwayListener
                  onClickAway={() => {
                    setMatches(null);
                  }}
                >
                  <Form>
                    <Field
                      id='search'
                      name='search'
                      component={Input}
                      placeholder={intl.formatMessage({ id: 'search' })}
                      onChange={async (e: any) => {
                        await onSearchChangeHandler(e.target.value);
                        handleChange(e);
                      }}
                      InputProps={{
                        endAdornment: values.search ? (
                          <IconButton
                            onClick={() => {
                              setFieldValue('search', '');
                              setMatches(null);
                              navigate(routes.rootForNavigation, { state: { search: '' } });
                            }}
                          >
                            <CrossIcon />
                          </IconButton>
                        ) : (
                          <CircleQuestionIcon />
                        )
                      }}
                      className='search'
                    />
                    <input type='submit' hidden />
                    {matches !== null ? (
                      <Box
                        display='flex'
                        flexDirection='column'
                        sx={{
                          width: '320px',
                          border: `1px solid ${primary[600]}`,
                          borderRadius: '8px',
                          position: 'absolute',
                          background: '#FFFFFF',
                          py: '4px',
                          mt: '-1px',
                          zIndex: 1
                        }}
                      >
                        {matches.length ? (
                          <>
                            {matches.map((match, index) => (
                              <Button
                                key={`${match}${index}`}
                                variant='text'
                                sx={{
                                  padding: '8px 16px',
                                  fontWeight: 400,
                                  color: secondary[700]
                                }}
                                onClick={() => {
                                  setFieldValue('search', match);
                                  navigate(routes.rootForNavigation, { state: { search: match } });
                                  setMatches(null);
                                }}
                              >
                                {match}
                              </Button>
                            ))}
                            <Button
                              variant='text'
                              sx={{
                                padding: '8px 16px',
                                justifyContent: 'space-between'
                              }}
                              onClick={() => {
                                navigate(routes.rootForNavigation, { state: { search: values.search } });
                                setMatches(null);
                              }}
                            >
                              {intl.formatMessage({ id: 'view_all_results' })}
                              <ChevronIcon direction='right' />
                            </Button>
                          </>
                        ) : (
                          <EmptySearchBox />
                        )}
                      </Box>
                    ) : null}
                  </Form>
                </ClickAwayListener>
              )}
            </Formik>
            <Button size='medium' variant='contained' onClick={openModal}>
              {intl.formatMessage({ id: 'new_referral' })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              onClick={async () => {
                await logout.execute();
              }}
            >
              {intl.formatMessage({ id: 'logout' })}
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
