import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Link,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { OrderDirection, TenantOrderField, TenantStatus, type TenantUpdate } from '__graphql__/globalTypes';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import {
  ActionsPopup,
  CompanyForm,
  EmptyTableBox,
  FormDialog,
  Loader,
  PageTitle,
  PageWrapper,
  SearchFilter
} from 'shared/components';
import { ActiveStarIcon, SortIcon, StarIcon } from 'shared/components/icons';
import { InformationSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { StyledTablePagination } from 'shared/components/StyledTablePagination';
import routes from 'shared/constants/routes';
import { TenantContext } from 'shared/contexts';
import { SearchContext } from 'shared/contexts/searchContext';
import { useCustomStyles, useFiltersQuery, usePalette, useTablePagination, useTableSort } from 'shared/hooks';
import { getActivityStatusColor } from 'shared/utils/colors';
import { getTenantUrl } from 'shared/utils/url';
import {
  type RemoveTenantFromFavourite,
  type RemoveTenantFromFavouriteVariables
} from './__graphql__/RemoveTenantFromFavourite';
import { type SetTenantToFavourite, type SetTenantToFavouriteVariables } from './__graphql__/SetTenantToFavourite';
import { type Tenants, type TenantsVariables, type Tenants_tenants_data } from './__graphql__/Tenants';
import { type UpdateTenant, type UpdateTenantVariables } from './__graphql__/UpdateTenant';
import { AddTenantWidget } from './AddTenantWidget';
import {
  removeTenantFromFavouriteMutation,
  setTenantToFavouriteMutation,
  tenantsQuery,
  updateTenantMutation
} from './api';
import { type TenantAction, TenantActionDialog } from './TenantActionDialog';

enum FavouriteOption {
  all = 'all',
  favourite = 'favourite'
}

export const MyTenants = (): JSX.Element => {
  const intl = useIntl();
  const { primary, secondary, warning } = usePalette();
  const customStyles = useCustomStyles();
  const { search } = useContext(SearchContext);
  const { setTenant } = useContext(TenantContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { field, direction, createSortHandler, setSort } = useTableSort<TenantOrderField>({});
  const { parsedQuery, pushQuery } = useFiltersQuery();

  const [query, setQuery] = useState('');
  const [favouriteFilter, setFavouriteFilter] = useState<FavouriteOption>(FavouriteOption.all);
  const [tenantForAction, setTenantForAction] = useState<{
    tenant?: Tenants_tenants_data;
    action?: TenantAction;
  }>({});
  const [tenantForEdit, setTenantForEdit] = useState<Tenants_tenants_data | undefined>();
  const favouriteOptions = useMemo(() => Object.values(FavouriteOption), []);

  const tenantsResult = useQuery<Tenants, TenantsVariables>(tenantsQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
            field,
            direction
          }
          : null,
      offset,
      limit,
      query: query?.length > 2 ? query : null,
      filter: {
        ...(favouriteFilter === FavouriteOption.favourite ? { favourite: true } : {})
      }
    },
    context: API_CONTEXT.SUPERADMIN
  });

  useEffect(() => {
    setQuery(search);
  }, [search]);

  useEffect(() => {
    pushQuery({
      filters: [
        {
          id: 'favourites',
          value: favouriteFilter
        }
      ],
      sort: [
        ...(field
          ? [
            {
              id: field,
              desc: direction === OrderDirection.desc
            }
          ]
          : [])
      ],
      pageIndex: tablePaginationProps.page
    });
  }, [direction, favouriteFilter, field, pushQuery, tablePaginationProps.page]);

  useEffect(() => {
    setSort(
      (parsedQuery?.sortBy ?? TenantOrderField.name) as TenantOrderField,
      (parsedQuery?.sortOrder ?? OrderDirection.asc) as OrderDirection
    );
    setFavouriteFilter((parsedQuery?.favourites as FavouriteOption) ?? FavouriteOption.all);
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const isTableEmpty = useMemo(
    () => !tenantsResult.data?.tenants.data?.length && tablePaginationProps.page === 0,
    [tablePaginationProps.page, tenantsResult.data?.tenants.data]
  );

  const [updateTenant] = useMutation<UpdateTenant, UpdateTenantVariables>(updateTenantMutation, {
    refetchQueries: ['Tenants'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.SUPERADMIN
  });

  const handleCloseActivatingModal = useCallback(() => {
    setTenantForAction({ tenant: undefined, action: undefined });
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setTenantForEdit(undefined);
  }, []);

  const handleUpdateTenant = useAsyncCallback(async (data: TenantUpdate) => {
    await updateTenant({ variables: { data } });
    toast(<SuccessSnackbar title={intl.formatMessage({ id: 'tenant_successfully_updated' })} />);
    handleCloseActivatingModal();
    handleCloseEditModal();
  });

  const [setUserToFavourite] = useMutation<SetTenantToFavourite, SetTenantToFavouriteVariables>(
    setTenantToFavouriteMutation,
    {
      context: API_CONTEXT.SUPERADMIN
    }
  );

  const [removeTenantFromFavourite] = useMutation<RemoveTenantFromFavourite, RemoveTenantFromFavouriteVariables>(
    removeTenantFromFavouriteMutation,
    {
      context: API_CONTEXT.SUPERADMIN
    }
  );

  const onFavouriteClickHandler = useCallback(
    async (tenantId: string, value: number | null) => {
      try {
        value !== null
          ? await setUserToFavourite({ variables: { id: tenantId } })
          : await removeTenantFromFavourite({ variables: { id: tenantId } });
      } catch (e) {
        console.log('Error: ', e);
      }
    },
    [removeTenantFromFavourite, setUserToFavourite]
  );

  if (tenantsResult.loading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <PageTitle>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            '& > div:not(:last-child) > *': {
              marginRight: '16px'
            }
          }}
        >
          <Box>
            <ToggleButtonGroup
              value={favouriteFilter}
              exclusive
              onChange={(e, v) => {
                setFavouriteFilter(v);
              }}
              orientation='horizontal'
              sx={{
                flexWrap: 'wrap',
                gap: '8px',
                color: secondary[800],
                '& > button.Mui-selected': {
                  backgroundColor: `${primary[400]} !important`,
                  borderColor: `${primary[600]} !important`
                }
              }}
            >
              {favouriteOptions.map((f) => (
                <ToggleButton key={f} value={f}>
                  {intl.formatMessage({ id: `my_tenants.favourite.${f}` })}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <SearchFilter value={query} onChange={setQuery} />
          </Box>
          <AddTenantWidget />
        </Box>
      </PageTitle>
      {
        // eslint-disable-next-line multiline-ternary
        tenantsResult.loading || handleUpdateTenant.loading ? (
          <Loader />
        ) : (
          <PageWrapper>
            <>
              <TableContainer>
                <Table
                  sx={{
                    '& tr': {
                      height: 'auto',
                      display: 'grid'
                    },
                    '& > thead tr': {
                      gridTemplateColumns: 'repeat(6, 2fr) 1fr'
                    },
                    '& > tbody tr': {
                      gridTemplateColumns: isTableEmpty ? 'auto' : 'repeat(6, 2fr) 1fr'
                    }
                  }}
                >
                  <TableHead
                    sx={{
                      '& th': {
                        padding: '12px 19px 11px 24px'
                      }
                    }}
                  >
                    <TableRow>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(TenantOrderField.name)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'tenant' })}
                          <SortIcon active={field === TenantOrderField.name} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(TenantOrderField.adminName)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'admin' })}
                          <SortIcon active={field === TenantOrderField.adminName} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(TenantOrderField.adminEmail)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'email' })}
                          <SortIcon active={field === TenantOrderField.adminEmail} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(TenantOrderField.id)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'url' })}
                          <SortIcon active={field === TenantOrderField.id} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(TenantOrderField.status)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'status' })}
                          <SortIcon active={field === TenantOrderField.status} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(TenantOrderField.favorites)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'favourite' })}
                          <SortIcon active={field === TenantOrderField.favorites} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>{intl.formatMessage({ id: 'action' })}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& td': {
                        lineHeight: '28px',
                        padding: '20px 24px 16px',
                        '&, & span': {
                          whiteSpace: 'nowrap',
                          overflowX: 'hidden',
                          textOverflow: 'ellipsis'
                        }
                      }
                    }}
                  >
                    {tenantsResult.data?.tenants.data?.map((tenant) => {
                      const disabled = !tenant.setupFinished || !!tenant.newId;
                      const commonProps = {
                        className: disabled ? 'disabled' : undefined
                      };
                      return (
                        <TableRow
                          key={tenant.id}
                          onClick={() => {
                            if (disabled) {
                              toast(
                                <InformationSnackbar
                                  title={intl.formatMessage({ id: 'tenant_in_progress' })}
                                  message={intl.formatMessage({ id: 'tenant_create_info' })}
                                />
                              );
                            } else {
                              navigate(tenant.id);
                            }
                          }}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: secondary[200]
                            },
                            '& a': {
                              color: secondary[700],
                              fontWeight: '400',
                              textDecoration: 'underline',
                              '&:hover': {
                                color: primary[600]
                              },
                              '&:active': {
                                color: primary[800]
                              }
                            }
                          }}
                        >
                          <TableCell {...commonProps}>
                            <Highlighter
                              autoEscape
                              highlightStyle={customStyles.highlighter}
                              searchWords={[`${query}`]}
                              textToHighlight={tenant.name ?? ''}
                            />
                          </TableCell>
                          <TableCell {...commonProps}>{tenant.adminName ?? ''}</TableCell>
                          <TableCell {...commonProps}>
                            <Link
                              onClick={async (e) => {
                                e.stopPropagation();
                                await navigator.clipboard.writeText(tenant.adminEmail);
                                toast(
                                  <InformationSnackbar
                                    noIcon
                                    message={intl.formatMessage({ id: 'email_successfully_copied' })}
                                  />,
                                  {
                                    position: toast.POSITION.TOP_CENTER
                                  }
                                );
                              }}
                            >
                              {tenant.adminEmail ?? ''}
                            </Link>
                          </TableCell>
                          <TableCell {...commonProps}>
                            <Link
                              onClick={async (e) => {
                                e.stopPropagation();
                                await navigator.clipboard.writeText(`https://${getTenantUrl(tenant.id)}`);
                                toast(
                                  <InformationSnackbar
                                    message={intl.formatMessage({ id: 'url_successfully_copied' })}
                                  />,
                                  {
                                    position: toast.POSITION.TOP_CENTER
                                  }
                                );
                              }}
                            >
                              {getTenantUrl(tenant.id)}
                            </Link>
                          </TableCell>
                          <TableCell className='badge'>
                            <Box
                              sx={{
                                backgroundColor: getActivityStatusColor(tenant.status)
                              }}
                            >
                              {intl.formatMessage({ id: `status.${tenant.status}` })}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ '&, & span': { display: 'block' } }}>
                            <Rating
                              defaultValue={tenant.favourites[0]?.userId ? 1 : 0}
                              max={1}
                              emptyIcon={<StarIcon />}
                              icon={<ActiveStarIcon />}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onChange={async (e, value) => {
                                e.stopPropagation();
                                await onFavouriteClickHandler(tenant.id, value);
                              }}
                              sx={{
                                margin: '0 auto',
                                width: 'fit-content',
                                '& .MuiRating-icon': {
                                  justifyContent: 'center',
                                  display: 'flex',
                                  transform: 'none'
                                },
                                '&:not(:active) .MuiRating-iconHover > svg > path': {
                                  stroke: warning[200],
                                  fill: warning[100]
                                },
                                '&:active .MuiRating-iconActive > svg > path': {
                                  stroke: warning[300],
                                  fill: warning[300]
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ '&, & span': { display: 'block' } }}>
                            <ActionsPopup
                              disabled={disabled}
                              items={[
                                {
                                  id: 'open',
                                  onClick: () => {
                                    setTenant({ id: tenant.id, name: tenant.name });
                                    navigate(routes.tenantView.replace(':tenantId', tenant.id));
                                  }
                                },
                                {
                                  id: 'view',
                                  onClick: () => {
                                    navigate(tenant.id);
                                  }
                                },
                                {
                                  id: 'edit',
                                  onClick: () => {
                                    setTenantForEdit(tenant);
                                  }
                                },
                                {
                                  id: tenant.status === TenantStatus.ACTIVE ? 'deactivate' : 'activate',
                                  onClick: () => {
                                    setTenantForAction({
                                      tenant,
                                      action: tenant.status === TenantStatus.ACTIVE ? 'deactivate' : 'activate'
                                    });
                                  }
                                },
                                {
                                  id: 'delete',
                                  disabled: tenant.status === TenantStatus.ACTIVE,
                                  onClick: () => {
                                    setTenantForAction({ tenant, action: 'delete' });
                                  }
                                }
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {isTableEmpty && (
                      <TableRow
                        style={{
                          height: 64 * tablePaginationProps.rowsPerPage,
                          background: 'inherit'
                        }}
                      >
                        <TableCell
                          colSpan={5}
                          style={{
                            height: 64 * tablePaginationProps.rowsPerPage,
                            padding: 0
                          }}
                        >
                          <EmptyTableBox />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <StyledTablePagination count={tenantsResult.data?.tenants.total ?? 0} {...tablePaginationProps} />
              {tenantForAction.action && (
                <TenantActionDialog
                  action={tenantForAction.action}
                  tenant={tenantForAction.tenant!}
                  onClose={handleCloseActivatingModal}
                />
              )}
              <FormDialog
                isOpen={!!tenantForEdit}
                onClose={handleCloseEditModal}
                header={intl.formatMessage({ id: 'edit_tenant' })}
                size='md'
                loading={handleUpdateTenant.loading}
                confirmBtnName={intl.formatMessage({ id: 'save' })}
                initialValues={tenantForEdit}
                onSubmit={async ({
                  billingContactName,
                  billingContactEmail,
                  adminEmail,
                  adminName,
                  id,
                  name
                }: TenantUpdate) => {
                  await handleUpdateTenant.execute({
                    billingContactName,
                    billingContactEmail,
                    adminEmail,
                    adminName,
                    name,
                    id: tenantForEdit?.id || id
                  });
                }}
              >
                {/* @ts-expect-error: error */}
                <CompanyForm withBillingContact />
              </FormDialog>
            </>
          </PageWrapper>
        )
      }
    </Box>
  );
};
