import gql from 'graphql-tag';

export const analyticsQuery = gql`
  query AnalyticsOfTenants($filter: TenantsAnalyticsFilters!, $orderBy: TenantWithAnalyticsOrder!) {
    analytics(filter: $filter, orderBy: $orderBy) {
      general {
        registrations
        tenants
        users
        totalRevenue
      }
      items {
        name
        registrations
        revenue
        users
        lastActivityDate
        plan
        totalItems
        usedItems
      }
    }
  }
`;
