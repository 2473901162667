import { Box } from '@mui/material';
import { TEXT_COMPONENTS } from 'admin/pages/Forms/ViewForm/constants';
import { type FormikProps } from 'formik';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';
import {
  type Sections_sections,
  type Sections_sections_questions
} from 'shared/hooks/useRegistration/__graphql__/Sections';
import { ComponentRenderer } from '../form';

interface Props extends Sections_sections_questions, FormikProps<Record<string, any>> {
  disabled?: boolean;
  section: Pick<Sections_sections, 'questions'>;
}

export const QuestionRenderer: FC<Props> = (props) => {
  const { secondary } = usePalette();
  const { component } = props;

  if (TEXT_COMPONENTS.includes(component)) {
    return (
      <Box
        sx={{
          mt: '-4px',
          backgroundColor: '#FFFFFF',
          border: `1px solid ${secondary[300]}`,
          borderRadius: '8px',
          padding: '16px',
          whiteSpace: 'pre-line',
          color: secondary[800]
        }}
      >
        <ComponentRenderer {...props} />
      </Box>
    );
  }

  return <ComponentRenderer {...props} />;
};
