import { Box, Button } from '@mui/material';
import { QuestionComponent } from '__graphql__/globalTypes';
import { type ParsedDocument } from 'admin/pages/Forms/__graphql__/ParsedDocument';
import { Field, useFormikContext } from 'formik';
import { useContext, type FC, useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Input, Loader } from 'shared/components';
import { ACCEPT_FILES } from 'shared/constants/file';
import { FormBuilderContext } from 'shared/contexts';
import { Validator } from 'shared/utils/validator';

interface Props {
  loading?: boolean;
  readOnly?: boolean;
  handleFile?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  parsedDocument?: ParsedDocument['parseDocument'] | null;
  isModalOpen?: boolean;
}
export const Renderer: FC<Props> = ({ readOnly, handleFile, loading, parsedDocument, isModalOpen }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);
  const isContract = component?.component === QuestionComponent.CONTRACT;
  const { values } = useFormikContext<{ contractType: string }>();
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (shouldReset && inputRef.current) {
      inputRef.current.value = '';
      setShouldReset(false);
    }
  }, [shouldReset]);

  return (
    <Box sx={{ width: '100%' }}>
      {isContract && (
        <Field
          disabled={readOnly}
          name='contractType'
          label={formatMessage({ id: 'component_properties.contract_type' })}
          component={Input}
          validate={Validator.pipe(Validator.methods.required())}
        />
      )}
      <Button
        disabled={isContract ? readOnly || !values.contractType : readOnly}
        onClick={() => {
          document.getElementById('upload-document')?.click();
        }}
        sx={{ mt: isContract ? '12px' : '0px', width: '100%' }}
        variant='contained'
      >
        {loading ? (
          <Loader />
        ) : (
          `${formatMessage({
            id: (parsedDocument || component?.text) && !isModalOpen ? 'change' : 'upload'
          })} ${formatMessage({ id: `components.${component?.component}` }).toLowerCase()}`
        )}
      </Button>
      <input
        ref={inputRef}
        onChange={(e) => {
          handleFile?.(e);
          setShouldReset(true);
        }}
        accept={Object.keys(ACCEPT_FILES['DOC/DOCX']).join(', ')}
        style={{ display: 'none' }}
        id='upload-document'
        type='file'
      />
    </Box>
  );
};
