import { useContext } from 'react';
import { type NavigateFunction } from 'react-router';
import routes from 'shared/constants/routes';
import { StableNavigateContext, TenantContext } from 'shared/contexts';

export function useTenantNavigate(): NavigateFunction {
  const navigateRef = useContext(StableNavigateContext);
  const tenantContext = useContext(TenantContext);

  const navigate = navigateRef?.current;

  if (tenantContext?.tenant && navigate) {
    const tenantPath = routes.tenantView.replace(':tenantId', tenantContext.tenant?.id);

    return (to) => {
      switch (typeof to) {
        case 'number':
          return navigate(to);
        case 'string':
          return navigate(`/${tenantPath}${to}`);
        default:
          to.pathname = `/${tenantPath}${to.pathname}`;
          return navigate(to);
      }
    };
  } else if (navigate) {
    return (...args) => navigate(...(args as Parameters<NavigateFunction>));
  }

  throw new Error('Navigate from StableNavigateContext is not provided');
}
