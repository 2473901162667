import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { CheckboxRenderer } from './CheckboxRenderer';
import { PropertyBlock } from './PropretyBlock';
import { type ComponentPropertiesProps } from './types';

export const RequirementProp: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  return (
    <PropertyBlock
      onSubmit={onSubmit}
      readOnly
      title={formatMessage({ id: 'component_properties.requirement' })}
      value={{ required: component?.required }}
      tooltip={formatMessage({ id: 'component_tooltips.requirement' })}
      ViewComponent={
        <CheckboxRenderer name='required' label={formatMessage({ id: 'obligatory' })} isReadOnly={isReadOnly} />
      }
    />
  );
};
