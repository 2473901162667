import { Box, FormControl, InputLabel, MenuItem, Select, type SelectProps } from '@mui/material';
import { QuestionComponent } from '__graphql__/globalTypes';
import { Field, FormikContext, type FieldProps } from 'formik';
import { path, split } from 'ramda';
import { useContext, useState, type FC } from 'react';
import { ChevronIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { ErrorHelper } from '../ErrorHelper';
import { InputTooltip } from './InputTooltip';
import { type FormComponentProps } from './types';

interface BaseSelectProps extends FormComponentProps, Partial<FieldProps> {
  selectProps: SelectProps;
}

const BaseSelect: FC<BaseSelectProps> = (props) => {
  const { error } = usePalette();

  const {
    field = {} as FieldProps['field'],
    form,
    id,
    options,
    selectProps: { label, endAdornment, required, multiple, value },
    sx = {}
  } = props;

  const errorMessage = path(split('.', id), form?.errors) as string;

  const baseValue = form ? field.value : value;

  return (
    <FormControl
      error={!!errorMessage}
      required={required}
      variant='outlined'
      sx={{
        ...sx,
        width: '100%',
        '& .MuiFormLabel-asterisk': {
          color: error[300]
        }
      }}
    >
      <InputLabel required={required} id={id}>
        {label}
      </InputLabel>
      <Select
        {...props.selectProps}
        {...field}
        value={multiple ? baseValue || [] : baseValue || ''}
        error={!!errorMessage}
        labelId={id}
        endAdornment={endAdornment ? <Box sx={{ display: 'flex', mr: '38px' }}>{endAdornment}</Box> : null}
        displayEmpty
        IconComponent={ChevronIcon}
        sx={{
          '.MuiSelect-icon': {
            right: '18px'
          }
        }}
        MenuProps={{
          sx: {
            maxWidth: '400px',
            '.MuiMenuItem-root': {
              textWrap: 'wrap'
            }
          }
        }}
      >
        <MenuItem value='' disabled sx={{ display: 'none' }} />
        {(options ?? []).map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <ErrorHelper error={errorMessage} />
    </FormControl>
  );
};

export const SelectComponent: FC<FormComponentProps & { multiple?: boolean }> = (props) => {
  const { text, id, component, disabled, tooltip, required: initialRequired, multiple, validate } = props;
  const isMultiple = component === QuestionComponent.MULTIPLE_SELECT;
  const [value, setValue] = useState<string | string[]>(isMultiple ? [] : '');
  const required = initialRequired ?? false;
  const formik = useContext(FormikContext);
  const formBuilder = useContext(FormBuilderContext);

  const selectProps: SelectProps = {
    required,
    label: text,
    disabled,
    value,
    multiple: multiple || isMultiple,
    endAdornment: tooltip ? <InputTooltip title={tooltip} /> : null
  };

  return formik ? (
    <Field
      {...props}
      component={BaseSelect}
      name={id}
      selectProps={selectProps}
      validate={validate || (required && Validator.pipe(Validator.methods.required()))}
    />
  ) : (
    <BaseSelect
      {...props}
      selectProps={{
        ...selectProps,
        onFocus: () => {
          formBuilder.setComponent(props);
        },
        onChange(event) {
          setValue(event.target.value as typeof value);
        }
      }}
    />
  );
};
