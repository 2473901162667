import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useCallback, useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { CrossIcon } from 'shared/components/icons';
import { RegistrationContext } from 'shared/contexts/registrationContext';

interface Props {
  isOpened: boolean;
  close: () => void;
  continueFunc: () => void;
  isOnClosingProcess?: boolean;
}

export const UnsavedDataConfirmation: FC<Props> = ({ isOpened, close, continueFunc, isOnClosingProcess = false }) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    isDirty: [_, saveFunc],
    setIsDirty
  } = useContext(RegistrationContext);

  const continueFunction = useCallback(() => {
    continueFunc();
    close();
    setIsDirty([false]);
  }, [close, continueFunc, setIsDirty]);

  const saveAndCloseFunc = useCallback(async () => {
    const isSavedSuccesfully = await saveFunc();
    if (isSavedSuccesfully !== false) {
      continueFunction();
    }
  }, [continueFunction, saveFunc]);

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={isOpened}
      onClose={close}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px'
        }
      }}
    >
      <DialogTitle sx={{ p: '24px', pb: '16px' }}>
        <Typography variant='subtitle1' color='secondary.800' fontSize='24px' lineHeight='28px'>
          {intl.formatMessage({ id: 'you_have_unsaved_data' })}
        </Typography>
        <IconButton onClick={close}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: '24px', pb: '16px' }}>
        <Typography variant='body1' color='secondary.800'>
          {intl.formatMessage({ id: 'it_seems_you_didnt_save' })}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '12px',
          px: '24px',
          pb: '24px',
          '@media only screen and (max-width: 479px)': {
            flexDirection: 'column',
            gap: '12px',
            '& > button': {
              justifyContent: 'center'
            }
          }
        }}
      >
        <Button variant='text' onClick={close} sx={{ padding: '8px 24px' }}>
          {intl.formatMessage({ id: 'cancel' })}
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
          <Button variant='outlined' type='submit' onClick={continueFunction}>
            {intl.formatMessage({ id: isOnClosingProcess ? 'close' : 'continue' })}
          </Button>
          <Button variant='contained' type='submit' onClick={saveAndCloseFunc}>
            {intl.formatMessage({ id: isOnClosingProcess ? 'save_and_close' : 'save_and_continue' })}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
