import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { SearchFailedIcon } from './icons';

export const EmptyTableBox = (): JSX.Element => {
  const intl = useIntl();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '160px',
        height: 'calc(100% - 160px)'
      }}
    >
      <SearchFailedIcon />
      <Typography variant='body1' textAlign='center' paddingTop={6}>
        {intl.formatMessage({ id: 'table.empty' })}
      </Typography>
    </Box>
  );
};
