import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { type ParsedDocument } from 'admin/pages/Forms/__graphql__/ParsedDocument';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { DocumentComponent } from 'shared/components/form/DocumentComponent';
import { CrossIcon } from 'shared/components/icons';
import { FormBuilderContext, type Component } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { useFormVariables } from '../../hooks';
import { VariablesTable } from '../../shared';

enum DocumentPreviewTabs {
  Text = 'text',
  Variables = 'variables'
}

interface Props {
  isOpen: boolean;
  parsedDocument: ParsedDocument['parseDocument'] | null | undefined;
  setIsModalOpen: (value: boolean) => void;
  onAdd?: () => Promise<void>;
  onCancel: () => void;
}

export const DocumentPreviewModal: FC<Props> = (props) => {
  const { isOpen, setIsModalOpen, parsedDocument, onAdd, onCancel } = props;

  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const { component } = useContext(FormBuilderContext);
  const formVariablesData = useFormVariables({
    withBasicInfo: true,
    aggregator: ({ variable }) => (parsedDocument?.variables ?? []).includes(variable)
  });

  const [currentTab, setCurrentTab] = useState<DocumentPreviewTabs>(DocumentPreviewTabs.Text);

  return (
    <Dialog fullWidth maxWidth='md' open={isOpen} onClose={onCancel}>
      <DialogTitle>
        <Typography variant='h5' sx={{ lineHeight: '28px', fontWeight: 600 }}>
          {formatMessage({ id: 'component_properties.document_preview' })}
        </Typography>
        <IconButton onClick={onCancel}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ToggleButtonGroup
          sx={{ color: secondary[800], width: '100%', maxWidth: '200px' }}
          value={currentTab}
          exclusive
          onChange={(_, v) => {
            if (v) {
              setCurrentTab(v);
            }
          }}
          orientation='horizontal'
          className='stacked'
        >
          {Object.values(DocumentPreviewTabs).map((tab) => (
            <ToggleButton key={tab} value={tab} sx={{ flex: 1, p: '4px' }}>
              {formatMessage({ id: tab })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Box sx={{ mt: '16px' }}>
          {currentTab === DocumentPreviewTabs.Text && (
            <DocumentComponent {...(component as Component)} documentContent={parsedDocument?.content} />
          )}
          {currentTab === DocumentPreviewTabs.Variables && <VariablesTable {...formVariablesData} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{formatMessage({ id: 'cancel' })}</Button>
        <Button
          size='medium'
          variant='contained'
          onClick={async () => {
            await onAdd?.();
            setIsModalOpen(false);
          }}
        >
          {formatMessage({ id: 'add_to_form' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
