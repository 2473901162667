import { Box, Button, Typography, type SxProps, type Theme } from '@mui/material';
import { useCallback, useMemo, type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import Dropzone, { type Accept } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { getDownloadUrlQuery } from 'shared/api';
import { type GetDownloadUrl, type GetDownloadUrlVariables } from 'shared/api/__graphql__/GetDownloadUrl';
import { API_CONTEXT } from 'shared/api/api-contexts';
import apolloClient from 'shared/apolloClient';
import { ACCEPT_FILES } from 'shared/constants/file';
import { usePalette } from 'shared/hooks';
import { type DocumentsModal_documents_data } from 'shared/hooks/useRegistration/__graphql__/DocumentsModal';
import { FileFormat, type AttachedDocument } from 'shared/types';
import { readFileAsync } from 'shared/utils/file';
import translations from '../../translations/en.json';
import { CloudIcon, SadSmileIcon } from '../icons';
import { Loader } from '../Loader';
import { UploadedDocument } from './UploadedDocument';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  documents?: DocumentsModal_documents_data[];
  name: string;
  value?: string | Array<string | AttachedDocument>;
  onChange: (name: string, v: Array<string | AttachedDocument>) => void;
  onRemove?: (_: Array<string | AttachedDocument>) => void;
  boxSx?: SxProps<Theme>;
  contentDirection?: 'column' | 'row';
  docTypes?: FileFormat[];
  title?: string;
  error?: string;
  onDisableHelperFunc?: () => void;
  singleUpload?: boolean;
  onReset?: () => void;
  additionalText?: string;
}

export const CustomDropzone: FC<Props> = ({
  loading,
  disabled,
  documents,
  onChange,
  onRemove,
  name,
  value,
  boxSx,
  contentDirection = 'column',
  docTypes = [FileFormat.PNG, FileFormat.JPG_JPEG],
  title = 'select_file_or_drag_and_drop',
  additionalText = '',
  error: err,
  onDisableHelperFunc,
  singleUpload,
  onReset
}) => {
  const intl = useIntl();
  const { primary, secondary, error, info } = usePalette();

  const accept = docTypes.reduce<Accept>((result, t) => ({ ...result, ...ACCEPT_FILES[t] }), {});

  const [previousIds, newDocuments] = useMemo(() => {
    if (!value?.length) return [[], []];
    if (typeof value === 'string') return [value.split(','), []];
    return [
      value.filter((doc) => typeof doc === 'string') as string[],
      value.filter((doc) => !!(doc as any).file) as AttachedDocument[]
    ];
  }, [value]);

  const onAreaClickHandler = useCallback(
    (e: any) => {
      if (disabled && onDisableHelperFunc) {
        e.preventDefault();
        e.stopPropagation();
        onDisableHelperFunc();
      }
    },
    [disabled, onDisableHelperFunc]
  );

  const onDrop = useAsyncCallback(async (acceptedFiles: File[]) => {
    if (disabled && onDisableHelperFunc) {
      onDisableHelperFunc();
      return;
    }

    const filesToSet = await Promise.all(
      acceptedFiles.map(async (file) => {
        const binaryStr = await readFileAsync(file);
        return {
          id: Math.floor(Math.random() * 100000) + 1,
          file,
          filename: file.name,
          preview: URL.createObjectURL(file),
          dataUrl: binaryStr
        };
      })
    );
    onChange(name, [...previousIds, ...newDocuments, ...filesToSet]);
  });

  const handleOpenDocument = useAsyncCallback(async (doc: DocumentsModal_documents_data) => {
    const urlResponse = await apolloClient.query<GetDownloadUrl, GetDownloadUrlVariables>({
      query: getDownloadUrlQuery,
      variables: { id: doc.id },
      context: API_CONTEXT.DOCUMENT
    });
    const url = urlResponse.data?.getDowloadUrl;
    if (url) {
      window.open(url, '_blank');
    }
  });

  return (
    <Box sx={boxSx} onClick={onAreaClickHandler}>
      <Dropzone
        disabled={disabled && !onDisableHelperFunc}
        onDrop={async (acceptedFiles) => {
          await onDrop.execute(acceptedFiles);
        }}
        noClick={disabled}
        noKeyboard={disabled}
        noDrag={disabled && !onDisableHelperFunc}
        accept={accept}
        multiple={!singleUpload}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps()}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ background: info[400], borderRadius: '10px' }}
          >
            <input
              {...getInputProps()}
              accept={Object.keys(accept).join(',')}
              style={{ display: 'none' }}
              id={name}
              name={name}
              multiple={!singleUpload}
              type='file'
              disabled={disabled && !onDisableHelperFunc}
            />
            <Box
              component='label'
              padding={contentDirection === 'row' ? '50px 67px' : 8}
              display='flex'
              flexDirection={contentDirection}
              justifyContent='space-between'
              alignItems='center'
              gap={4}
              sx={{
                background: err ? error[50] : info[200],
                border: err ? `1px dashed ${error[100]}` : `1px dashed ${info[500]}`,
                borderRadius: '16px',
                width: '100%',
                '@media only screen and (max-width: 496px)': {
                  display: 'flex',
                  flexDirection: 'column'
                },
                cursor: 'pointer'
              }}
            >
              <Box sx={{ display: 'flex', gap: 4 }}>
                {err ? <SadSmileIcon /> : <CloudIcon color={disabled ? info[500] : primary[600]} />}
                <Box>
                  <Typography variant='body1' color={disabled ? secondary[600] : secondary[800]}>
                    {
                      // @ts-expect-error: error
                      translations[title] ? intl.formatMessage({ id: title }) : title
                    }
                  </Typography>
                  <Typography variant='body1' fontSize='14px' lineHeight='16px' color={secondary[500]}>
                    {err || !additionalText
                      ? intl.formatMessage({ id: err || 'wrong_format' }, { formats: docTypes.join(', ') })
                      : additionalText}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '14px' }}>
                <Box
                  sx={{
                    padding: '8px 24px',
                    borderRadius: '8px',
                    '&:hover': {
                      background: primary[300],
                      borderColor: primary[700]
                    },
                    ...(disabled
                      ? {
                          border: `1px solid ${secondary[300]}`,
                          color: secondary[500]
                        }
                      : {
                          border: `1px solid ${secondary[400]}`,
                          color: secondary[800]
                        })
                  }}
                >
                  {intl.formatMessage({ id: 'select_file' })}
                </Box>
                {onReset && !(translations as Record<string, any>)[title] && (
                  <Button
                    variant='outlined'
                    onClick={(e) => {
                      e.stopPropagation();
                      onReset();
                    }}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Dropzone>
      <Box
        sx={{
          marginTop: '16px',
          '& > *:not(:last-child)': {
            marginBottom: '12px'
          }
        }}
      >
        {loading ? (
          <Loader />
        ) : newDocuments.length || documents?.length ? (
          <>
            <Typography variant='body1' lineHeight='20px' color='secondary.800'>
              {intl.formatMessage({ id: 'uploaded_files' })}
            </Typography>
            {newDocuments.map((doc) => (
              <UploadedDocument
                key={doc.id}
                filename={doc.filename}
                onOpen={() => {
                  window.open(doc.preview, '_blank');
                }}
                onRemove={() => {
                  const numberOfFiles = (value as AttachedDocument[]).length;
                  const newValue =
                    numberOfFiles === 1 ? [] : (value as AttachedDocument[])?.filter((file) => file?.id !== doc?.id);
                  if (onRemove) {
                    onRemove(newValue);
                  } else {
                    onChange(name, newValue);
                  }
                }}
              />
            ))}
            {documents?.map((doc) => (
              <UploadedDocument
                disabled={disabled}
                key={doc.id}
                filename={doc.filename}
                onOpen={async () => {
                  await handleOpenDocument.execute(doc);
                }}
                onRemove={async () => {
                  const filteredIds = [...previousIds.filter((id) => id !== doc.id)] as string[];
                  const newValue = [...filteredIds, ...newDocuments];
                  if (onRemove) {
                    onRemove(newValue);
                  } else {
                    onChange(name, newValue);
                  }
                }}
              />
            ))}
          </>
        ) : null}
      </Box>
    </Box>
  );
};
