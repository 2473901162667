import { FC } from 'react';
import { IconProps } from './props';
import { usePalette } from 'shared/hooks';

export const ChartsIcon: FC<IconProps> = (props) => {
  const { color, size = 24 } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width={size} height={size} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3 3.75V21.75H21' stroke='#2C9CAC' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10 10.75H8C7.44772 10.75 7 11.1977 7 11.75V16.75C7 17.3023 7.44772 17.75 8 17.75H10C10.5523 17.75 11 17.3023 11 16.75V11.75C11 11.1977 10.5523 10.75 10 10.75Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 5.75H16C15.4477 5.75 15 6.19772 15 6.75V16.75C15 17.3023 15.4477 17.75 16 17.75H18C18.5523 17.75 19 17.3023 19 16.75V6.75C19 6.19772 18.5523 5.75 18 5.75Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
