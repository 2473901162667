import gql from 'graphql-tag';

export const superAdminsQuery = gql`
  query SuperAdmins(
    $offset: Int!
    $limit: Int!
    $orderBy: SuperAdminOrder
    $query: String
  ) {
    superAdmins(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      query: $query
    ) {
      total
      data {
        id
        email
        firstName
        lastName
        role
        isActive
        lastLogin
      }
    }
  }
`;

export const inviteSuperAdminMutation = gql`
  mutation InviteSuperAdmin($data: SuperAdminInviteInput!) {
    inviteSuperAdmin(data: $data)
  }
`;

export const deleteSuperAdminMutation = gql`
  mutation DeleteSuperAdmin($id: String!) {
    deleteSuperAdmin(id: $id) {
      id
    }
  }
`;

export const activateSuperAdminMutation = gql`
  mutation ActivateSuperAdmin($id: String!) {
    activateSuperAdmin(id: $id) {
      id
    }
  }
`;

export const deactivateSuperAdminMutation = gql`
  mutation DeactivateSuperAdmin($id: String!) {
    deactivateSuperAdmin(id: $id) {
      id
    }
  }
`;
