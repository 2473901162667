/* eslint-disable */
import dayjs from 'dayjs';
import { type IntlShape } from 'react-intl';
import translations from 'shared/translations/en.json';

const download = function (data: any, fileName: string = 'registration.csv'): void {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', fileName);

  // Performing a download with click
  a.click();
};

const csvmaker = function (data: any): string {
  // Empty array for storing the values
  const csvRows = [];

  // Headers is basically a keys of an
  // object which is id, name, and
  // profession
  const headers = Object.keys(data[0]);

  // As for making csv format, headers
  // must be separated by comma and
  // pushing it into array
  csvRows.push(headers.join(','));

  // Pushing Object values into array
  // with comma separation
  data.forEach((d: any) => {
    const values = Object.values(d).join(',');
    csvRows.push(values);
  });

  // Returning the array joining with new line
  return csvRows.join('\n');
};

export const getCSV = async function (data: any, fileName?: string): Promise<void> {
  const csvdata = csvmaker(data);
  download(csvdata, fileName);
};

export const downloadRegistrationCSV = async (
  contractQuestions: any[],
  // TODO
  registrationForm: any[],
  intl: IntlShape
): Promise<void> => {
  const data = contractQuestions?.map((cq) => {
    const form = registrationForm.find((f) => f.formQuestion.id === cq.id);
    return {
      // ...cq,
      question: intl
        .formatMessage({ id: `questions.${cq.question.id}.question` })
        .replaceAll(',', '‚')
        .replaceAll('\n', '_'),
      // @ts-expect-error: error
      answer: translations.questions[cq.question.id].answers?.[form?.answer]
        ? intl
            .formatMessage({ id: `questions.${cq.question.id}.answers.${form?.answer?.toString()}` })
            .replaceAll(',', '‚')
            .replaceAll('\n', '_')
        : form?.answer?.toString().replaceAll(',', '‚') || '-'
    };
  });
  await getCSV(data);
};

export const downloadCSVTemplate = async (headers: string[]): Promise<void> => {
  const data: Record<string, string> = {};
  headers.forEach((header) => {
    data[header] = '';
  });

  await getCSV([data], 'Referrals_template.csv');
};

export const downloadReportCSV = async (
  registrations: any[],
  formatMessage: IntlShape['formatMessage'],
  dates: any[]
): Promise<void> => {
  const data: Array<Record<string, string>> = registrations?.map((reg) => {
    return {
      [formatMessage({ id: 'name' })]: `${reg.firstName} ${reg.lastName}`,
      [formatMessage({ id: 'email' })]: reg.email,
      [formatMessage({ id: 'phone_number' })]: reg.phoneNumber,
      [formatMessage({ id: 'time_in_stage' })]: dayjs(reg.createdAt).fromNow(true),
      [formatMessage({ id: 'last_update' })]: dayjs(reg.updatedAt).fromNow(true),
      [formatMessage({ id: 'status' })]: formatMessage({ id: `registration.status.${reg.status}` }),
      [formatMessage({ id: 'type' })]: formatMessage({
        id: `registration.type.${reg.dummyRecord ? 'dummyRecord' : reg.locked ? 'locked' : 'current'}`
      })
    };
  });
  await getCSV(data, `Report_${dates[0] ?? '*'}-${dates[1] ?? '*'}.csv`);
};
