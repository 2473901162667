import { Font, ThemeColor } from '__graphql__/globalTypes';
import { createContext } from 'react';
import { type AppSetup_companySetup_companyLogo } from 'shared/hooks/useCompany/__graphql__/AppSetup';
import { type AttachedDocument } from 'shared/types';
import { type WithoutGQLType } from 'shared/types/utils';

type Logo = WithoutGQLType<AppSetup_companySetup_companyLogo> | null;

export interface CompanyDataContext {
  name: string;
  themeColor: ThemeColor;
  font: Font;
  logo: Logo;
  uploadLogo: (file: AttachedDocument, companyId: string) => Promise<void>;
  resetLogo: (companyId: string) => Promise<void>;
  loading: boolean;
  initialLoading: boolean | undefined;
  paymentFailureCode?: string;
  changeTheme: (color: ThemeColor) => Promise<void>;
  isEdit: boolean;
  setIsEdit: (isOpen: boolean) => void;
}

export const CompanyContext = createContext<CompanyDataContext>({
  name: '',
  themeColor: ThemeColor.STEEL_BLUE,
  font: Font.MYRIAD_PRO,
  logo: null,
  uploadLogo: async () => {},
  resetLogo: async () => {},
  loading: false,
  initialLoading: false,
  changeTheme: async () => {},
  paymentFailureCode: undefined,
  isEdit: false,
  setIsEdit: () => {}
});
