import { useLazyQuery, useQuery } from '@apollo/client';
import { SubscriptionStatus } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { companySubscriptionsQuery, companyAccessQuery, discountQuery } from 'shared/api';
import { type CompanyAccess } from 'shared/api/__graphql__/CompanyAccess';
import { type CompanySubscriptions } from 'shared/api/__graphql__/CompanySubscriptions';
import { type Discount } from 'shared/api/__graphql__/Discount';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { SelectPlanDialog } from 'shared/components';
import { TENANT_HEADER_NAME } from 'shared/constants';
import { useRoles } from 'shared/hooks';
import { type ICurrentPlan, getCurrentPlan } from 'shared/utils/subscription';
import { ProductsContext } from './productsContext';

export const SubscriptionContext = createContext<{
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  isCardExpired: boolean;
  currentPlan?: ICurrentPlan;
  loading: boolean;
}>({
  openModal: () => { },
  isCardExpired: false,
  loading: false
});

export const SubscriptionContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { formatMessage } = useIntl();
  const { isAdmin } = useRoles();
  const { products } = useContext(ProductsContext);
  const [modalSetToOpened, setModalSetToOpened] = useState<boolean>(false);

  const tenantId = useMemo(
    () => location.pathname.match(/\/tenant\/([^/?]+)/)?.[1] ?? null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const onOpen = useCallback(() => {
    setModalSetToOpened(true);
  }, []);

  const onClose = useCallback(() => {
    setModalSetToOpened(false);
  }, []);

  const [fetchCompanyAccess, { data: companyAccess, loading: companyAccessLoading }] =
    useLazyQuery<CompanyAccess>(companyAccessQuery);
  const [fetchCompanySubscriptions, { data: companySubscriptions, loading: companySubscriptionsLoading }] =
    useLazyQuery<CompanySubscriptions>(companySubscriptionsQuery, {
      context: API_CONTEXT.PAYMENT
    });

  const { data: discountData, loading: discountLoading } = useQuery<Discount>(discountQuery, {
    context: {
      ...API_CONTEXT.PAYMENT,
      headers: {
        [TENANT_HEADER_NAME]: tenantId ?? companyAccess?.companyInfo.id
      }
    },
    skip: !isAdmin || (!tenantId && !companyAccess?.companyInfo.id)
  });

  useEffect(() => {
    if (isAdmin || tenantId) {
      fetchCompanyAccess();
      fetchCompanySubscriptions();
    }
  }, [fetchCompanyAccess, fetchCompanySubscriptions, isAdmin, tenantId]);

  const currentPlan = useMemo(() => {
    return getCurrentPlan({
      companySubscriptions,
      products,
      companyInfo: companyAccess?.companyInfo,
      formatMessage,
      discount: discountData?.discount
    });
  }, [companySubscriptions, companyAccess, products, formatMessage, discountData?.discount]);

  const modalShouldBeOpened = useMemo(() => {
    if (isAdmin && companyAccess && companySubscriptions) {
      const activeSubscription = companySubscriptions.subscriptions
        .find(s => [SubscriptionStatus.active, SubscriptionStatus.past_due].includes(s.status));
      if (!activeSubscription && (
        !companyAccess.companyInfo.trialStartDate ||
        (companyAccess.companyInfo.trialStartDate && dayjs(companyAccess.companyInfo.trialEndDate).isBefore(new Date()))
      )) {
        return true;
      }
    }
    return false;
  }, [isAdmin, companyAccess, companySubscriptions]);

  return (
    <SubscriptionContext.Provider value={{
      openModal: onOpen,
      isCardExpired: false,
      currentPlan,
      loading: companyAccessLoading || companySubscriptionsLoading || discountLoading
    }}>
      {children}
      <SelectPlanDialog
        open={modalSetToOpened || modalShouldBeOpened}
        isChange={modalSetToOpened}
        onClose={onClose}
        companyAccess={companyAccess}
        companySubscriptions={companySubscriptions}
        currentPlanId={currentPlan.productId}
      />
    </SubscriptionContext.Provider>
  );
};
