import gql from 'graphql-tag';

export const saveRulesMutation = gql`
  mutation SaveRules($data: [SaveRuleInput!]!) {
    saveRules(data: $data)
  }
`;

export const sectionsListQuery = gql`
  query SectionsList($filters: SectionsArgs!) {
    sections(filters: $filters) {
      id
      name
      variable
      description
      relationshipType
      children {
        questions {
          id
        }
      }
      questions {
        id
      }
    }
  }
`;

export const rulesQuery = gql`
  query IssuerRules($issuerId: String!) {
    issuerRules(issuerId: $issuerId) {
      id
      issuer {
        id
        variable
      }
      operator
      comparedValue
      actions {
        id
        action
        section {
          id
          variable
        }
        question {
          id
          variable
        }
      }
    }
  }
`;

export const clearRulesMutation = gql`
  mutation ClearRules($issuerId: String!) {
    clearRules(issuerId: $issuerId)
  }
`;
