import { Box, Button, Typography } from '@mui/material';
import { QuestionComponent, type Action } from '__graphql__/globalTypes';
import { TEXT_COMPONENTS } from 'admin/pages/Forms/ViewForm/constants';
import { Form, Formik, type FormikProps } from 'formik';
import { useCallback, useContext, useEffect, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette } from 'shared/hooks';
import {
  type Sections_sections_children,
  type Sections_sections_children_questions
} from 'shared/hooks/useRegistration/__graphql__/Sections';
import { ErrorSnackbar } from '../Snackbars';
import { FormikListener } from './FormikListener';
import { QuestionRenderer } from './QuestionRenderer';
import { RuleProcessor } from './rule-processor';
import { SignatureWidget } from './SignatureWidget';
import TabPanel from './TabPanel';
import { useOnSubmitSection } from './useOnSubmitSection';

type Section = Omit<Sections_sections_children, 'questions'> & {
  questions: Array<Sections_sections_children_questions & { readonly?: boolean }>;
};

interface Props {
  section: Section & { readonly?: boolean };
  parentTab: number;
  childTab: number;
  goToPreviousTab: () => void;
  goToNextTab: () => void;
}

export const SectionForm: FC<Props> = (props) => {
  const { section, childTab, parentTab, goToPreviousTab, goToNextTab } = props;
  const {
    loading,
    previewForm,
    answers,
    combinedAnswers,
    previewFormValues,
    rules,
    sections,
    setIsDirty,
    invalidSections,
    closeModal
  } = useContext(RegistrationContext);
  const questions = section.questions;
  const { secondary } = usePalette();
  const { formatMessage } = useIntl();
  const { submitSection, submitApplication } = useOnSubmitSection();

  const [shouldSubmitApplication, setShouldSubmitApplication] = useState<boolean>();

  useEffect(() => {
    if (shouldSubmitApplication) {
      submitApplication();
    } else {
      setShouldSubmitApplication(undefined);
    }

    return () => {
      setShouldSubmitApplication(undefined);
    };
  }, [shouldSubmitApplication, submitApplication]);

  const initialValues = section.questions.reduce<Record<string, string | string[]>>(
    (result, { id, component, initialAnswer }) => {
      const multipleAnswersComponents = [QuestionComponent.MULTIPLE_SELECT, QuestionComponent.TIME_PICKER];

      if (multipleAnswersComponents.includes(component)) {
        const value =
          (previewForm.id ? previewFormValues[id] : answers[id]) ?? ((initialAnswer && [initialAnswer]) || []);
        result[id] = value;
      } else if (component === QuestionComponent.FILE_UPLOAD) {
        const value = (previewForm.id ? previewFormValues[id] : answers[id]) ?? initialAnswer ?? [];
        result[id] = value;
      } else if (!TEXT_COMPONENTS.includes(component)) {
        const value = (previewForm.id ? previewFormValues[id] : answers[id]?.[0]) ?? initialAnswer ?? '';
        result[id] = value;
      }

      return result;
    },
    {}
  );

  const document = questions.find(({ component }) =>
    [QuestionComponent.ASSIGNMENT_SCHEDULE, QuestionComponent.CONTRACT, QuestionComponent.DOCUMENT].includes(component)
  );

  const saveAndNext = useCallback(
    async (
      formikProps: Pick<FormikProps<Record<string, string | string[]>>, 'validateForm' | 'dirty' | 'submitForm'>
    ) => {
      if (formikProps.dirty) {
        const result = await formikProps.validateForm();
        if (!Object.keys(result).length && !invalidSections.includes(section.id)) {
          await formikProps.submitForm();
          goToNextTab?.();
        } else {
          toast(
            <ErrorSnackbar
              title={formatMessage({ id: 'section_errors_title' })}
              message={formatMessage({ id: 'section_errors_message' })}
            />
          );
          return false;
        }
      } else {
        goToNextTab?.();
      }
      return true;
    },
    [formatMessage, goToNextTab, invalidSections, section.id]
  );

  const shouldSignDocument = !!document?.required;
  let docActions: Record<Action, boolean> | null = null;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, helpers) => {
        await submitSection({ values, helpers, submittedSection: section });
      }}
    >
      {(formikProps) => (
        <Form>
          <TabPanel
            index={section.id}
            loading={loading}
            sx={
              document
                ? { width: '100%' }
                : {
                    '@media only screen and (max-width: 620px)': {
                      margin: '0 auto',
                      width: '100%'
                    }
                  }
            }
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
              mb={6}
              sx={{
                minHeight: 'calc(100vh - 147px - 83px)',
                '@media only screen and (max-width: 767px)': {
                  minHeight: 'calc(100vh - 184px - 83px)'
                }
              }}
            >
              <Typography variant='h3' color='secondary.800'>
                {section.parent?.name ? `${section.parent.name} (${childTab + 1})` : section.name}
              </Typography>
              {section.title ? (
                <Typography variant='subtitle2' color='secondary.800' mb={-1}>
                  {section.title}
                </Typography>
              ) : null}

              {questions.map((q) => {
                const questionRules = rules.filter(
                  ({ actions }) => !!actions.find(({ questionId }) => questionId === q.id)
                );
                let actions: Record<Action, boolean> | null = null;
                if (questionRules.length) {
                  for (const rule of questionRules) {
                    actions = RuleProcessor.getAppliedActions({ rule, answers: combinedAnswers, targetId: q.id });
                    docActions = actions;
                    if (actions.SHOW) {
                      return (
                        <QuestionRenderer
                          key={q.id}
                          {...q}
                          {...formikProps}
                          disabled={section.readonly || actions.READ_ONLY}
                          section={section}
                        />
                      );
                    }
                    if (actions.HIDE) {
                      return null;
                    }
                  }
                }
                return (
                  <Box
                    key={q.id}
                    sx={{
                      maxWidth: document?.id === q.id ? '700px' : '400px',
                      '@media only screen and (max-width: 620px)': {
                        maxWidth: '100%'
                      }
                    }}
                  >
                    <QuestionRenderer
                      {...q}
                      {...formikProps}
                      disabled={section.readonly || q.readonly}
                      section={section}
                    />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
          <Box
            sx={{
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'space-between',
              background: secondary[100],
              p: '20px 48px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100vw'
            }}
          >
            <Button disabled={formikProps.isSubmitting} variant='outlined' onClick={goToPreviousTab}>
              {formatMessage({ id: 'back' })}
            </Button>

            <Box sx={{ display: 'flex', gap: '16px' }}>
              {shouldSignDocument && <SignatureWidget section={section} actions={docActions} />}
              {parentTab === sections.length && (sections[parentTab - 1]?.children.length ?? 0) - 1 === childTab ? (
                <Button
                  disabled={formikProps.isSubmitting}
                  onClick={async () => {
                    if (previewForm.id) {
                      closeModal();
                    } else {
                      formikProps.setSubmitting(true);
                      const result = await formikProps.validateForm();
                      if (!Object.keys(result).length && !invalidSections.length) {
                        await formikProps.submitForm().then(() => setShouldSubmitApplication(true));
                      } else {
                        toast(
                          <ErrorSnackbar
                            title={formatMessage({ id: 'application_is_not_fully_filled' })}
                            message={formatMessage({ id: 'please_check_all_steps' })}
                          />
                        );
                      }
                      setIsDirty([false, () => {}]);
                      formikProps.setSubmitting(false);
                    }
                  }}
                  variant='contained'
                  color='error'
                >
                  {formatMessage({ id: 'save_and_submit_application' })}
                </Button>
              ) : (
                <Button
                  onClick={() => saveAndNext(formikProps)}
                  disabled={formikProps.isSubmitting}
                  variant='contained'
                >
                  {formatMessage({ id: formikProps.dirty ? 'save_and_next' : 'next' })}
                </Button>
              )}
            </Box>
          </Box>
          <FormikListener submit={saveAndNext} />
        </Form>
      )}
    </Formik>
  );
};
