import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const CircleCheckIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { success } = usePalette();
  const currentColor = color ?? success[300];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 12L11 14L15 10' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
