import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const CloneIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15 12V18M12 15H18' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M20 8H10C8.89543 8 8 8.89543 8 10V20C8 21.1046 8.89543 22 10 22H20C21.1046 22 22 21.1046 22 20V10C22 8.89543 21.1046 8 20 8Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 16C2.9 16 2 15.1 2 14V4C2 2.9 2.9 2 4 2H14C15.1 2 16 2.9 16 4'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
