import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { AuditAction, AuditOrderField, OrderDirection } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { useCallback, useMemo, type FC } from 'react';
import { useIntl } from 'react-intl';
import { auditQuery } from 'shared/api';
import { type Audit as AuditQuery, type AuditVariables } from 'shared/api/__graphql__/Audit';
import { usePalette } from 'shared/hooks';

interface Props {
  userId?: string;
  registrationId?: string;
}

export const Audit: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();

  const { data } = useQuery<AuditQuery, AuditVariables>(auditQuery, {
    variables: {
      limit: 100,
      offset: 0,
      orderBy: {
        field: AuditOrderField.createdAt,
        direction: OrderDirection.desc
      },
      filter: props
    }
  });

  const sortedAudit = useMemo(
    () => data?.audit?.data?.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)),
    [data?.audit?.data]
  );

  const getAuditStringByAction = useCallback(
    (action: AuditAction, data?: any | null) => {
      switch (action) {
        case AuditAction.INSERT:
          return formatMessage({ id: 'created_registration' });
        case AuditAction.UPDATE:
          return formatMessage({ id: 'updated_registration' });
        case AuditAction.NOTEADDED:
          return formatMessage({ id: 'added_note' });
        case AuditAction.NOTEEDITED:
          return formatMessage({ id: 'edited_note' });
        case AuditAction.NOTEDELETED:
          return formatMessage({ id: 'deleted_note' });
        case AuditAction.USERUPDATE:
          return formatMessage({ id: 'updated_contractor' });
        case AuditAction.NOTIFICATION:
          return formatMessage(
            { id: 'sent_notification' },
            {
              name: data?.template ? formatMessage({ id: `template.${data.template}` }) : formatMessage({ id: 'some' })
            }
          );
        case AuditAction.STATUSUPDATE:
          return formatMessage(
            { id: 'set_status_to' },
            {
              name: data?.status
                ? formatMessage({ id: `registration.status.${data.status}` })
                : formatMessage({ id: 'some' })
            }
          );
      }
    },
    [formatMessage]
  );

  return (
    <Box gap='8px'>
      {sortedAudit?.map(({ id, createdAt, action, addedBy, data }) => (
        <Box
          key={id}
          bgcolor='#FFFFFF'
          display='flex'
          flexDirection='row'
          gap={3}
          sx={{
            padding: '12px 12px 6px',
            border: `1px solid ${secondary[300]}`,
            borderRadius: '8px'
          }}
        >
          <Typography variant='body1' color={secondary[500]} alignSelf='start' lineHeight='28px'>
            {dayjs(createdAt).format('HH:mm DD/MM/YYYY')}
          </Typography>
          <Typography variant='body2' color='secondary.700'>
            {addedBy ? `${addedBy.firstName} ${addedBy.lastName?.[0]}.` : formatMessage({ id: 'system' })}{' '}
            <Typography variant='body2' fontWeight={400} display='inline-block'>
              {getAuditStringByAction(action, data)}
            </Typography>
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
