import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Carousel } from 'react-responsive-carousel';
import { CustomCheckbox } from 'shared/components';
import { ChartsIcon, DocumentFormIcon, EmptyDocumentWithPenIcon } from 'shared/components/icons';
import { useCustomStyles, usePalette } from 'shared/hooks';
import { localStorageUtils } from 'shared/utils/local-storage';

interface Props {
  next: () => void;
  closeModal: () => void;
}

const IMAGES = ['/form-builder-intro-1.svg', '/form-builder-intro-2.svg', '/form-builder-intro-3.svg'];
const FEATURES_ICONS = [<DocumentFormIcon key={1} />, <ChartsIcon key={2} />, <EmptyDocumentWithPenIcon key={3} />];

export const FormBuilderIntro: FC<Props> = ({ closeModal, next }) => {
  const { carousel } = useCustomStyles();
  const { primary } = usePalette();
  const { formatMessage } = useIntl();
  const [hideIntro, setHideIntro] = useState<boolean>(false);

  const onNextButtonClick = (): void => {
    localStorageUtils.setItem('hideFormBuilderIntro', hideIntro);
    next();
  };

  return (
    <>
      <DialogContent sx={{ pb: 0 }}>
        <Box>
          <Typography variant='body1'>{formatMessage({ id: 'form_builder_welcome' })}</Typography>
          <Box sx={{ display: 'flex', gap: '16px', py: '16px' }}>
            {FEATURES_ICONS.map((icon, index) => (
              <Box key={index} sx={{ backgroundColor: primary[200], p: '12px', borderRadius: '16px', display: 'flex' }}>
                <Box sx={{ mr: '8px' }}>{icon}</Box>
                <Typography variant='subtitle2'>{formatMessage({ id: `form_builder_intro.${index + 1}` })}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={carousel}>
            <Carousel autoPlay showThumbs={false} interval={2500} stopOnHover showStatus={false} infiniteLoop>
              {IMAGES.map((i) => (
                <Box key={i}>
                  <img src={i} />
                </Box>
              ))}
            </Carousel>
          </Box>
          <Box sx={{ display: 'flex', mt: '16px' }}>
            <CustomCheckbox value={false} onChange={({ target }) => setHideIntro(target.checked)} />
            <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
              {formatMessage({ id: 'do_not_show_again' })}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>{formatMessage({ id: 'cancel' })}</Button>
        <Button size='medium' variant='contained' onClick={onNextButtonClick}>
          {formatMessage({ id: 'next' })}
        </Button>
      </DialogActions>
    </>
  );
};
