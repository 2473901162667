import { QuestionComponent } from '__graphql__/globalTypes';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { BASIC_INFO_SECTION } from '../constants';
import { DefaultValueProperty, OptionsProperty, PropertyBlock, RequirementProp, VariableProp } from './shared';
import { type ComponentPropertiesProps } from './shared/types';

export const SelectComponentProperties: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component, section } = useContext(FormBuilderContext);
  const isCustomSection = section?.id !== BASIC_INFO_SECTION.id;
  const withDefault = component?.component === QuestionComponent.OPTIONS_SMALL;

  return (
    <>
      <VariableProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <PropertyBlock
        readOnly
        title={formatMessage({ id: 'component_properties.type' })}
        value={{ component: formatMessage({ id: `components.${component?.component}` }) }}
        tooltip={formatMessage({ id: 'component_tooltips.type' })}
      />
      <PropertyBlock
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.question_text' })}
        value={{ text: component?.text }}
        tooltip={formatMessage({ id: 'component_tooltips.question_text' })}
        isMainProperty
      />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.hint' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.hint' })}
        value={{ placeholder: component?.placeholder }}
        tooltip={formatMessage({ id: 'component_tooltips.hint' })}
      />
      {isCustomSection && <OptionsProperty isReadOnly={isReadOnly} onSubmit={onSubmit} />}
      <RequirementProp isReadOnly={isReadOnly} onSubmit={onSubmit} />
      {withDefault && <DefaultValueProperty onSubmit={onSubmit} readOnly={isReadOnly} />}
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.tooltip' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.tooltip' })}
        value={{ tooltip: component?.tooltip }}
        tooltip={formatMessage({ id: 'component_tooltips.tooltip' })}
      />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.description' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.description' })}
        value={{ description: component?.description }}
        tooltip={formatMessage({ id: 'component_tooltips.description' })}
      />
    </>
  );
};
