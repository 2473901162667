import { useContext } from 'react';
import { FormBuilderContext } from 'shared/contexts';
import { type RuleValues } from './types';
import { getComparedValue, getOperatorValue } from './utils';

interface Args {
  issuerId?: string;
  targetId?: string;
}

export function usePureRule(args: Args): RuleValues {
  const { issuerId = '' } = args;
  const { component } = useContext(FormBuilderContext);

  const ruleWithPredefinedValues: RuleValues = {
    operator: getOperatorValue({ item: component }),
    comparedValue: getComparedValue({ item: component }),
    issuerId,
    issuerVariable: component?.variable ?? '',
    actions: [{ questionId: '', sectionId: '', action: '', targetVariable: '' }]
  };

  return ruleWithPredefinedValues;
}
