import { useMutation } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { type CreateUserEmail } from '__graphql__/globalTypes';
import { Field } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { type Contractor_user } from 'shared/api/__graphql__/Contractor';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { CustomEditor, FormDialog, Input } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { Validator } from 'shared/utils/validator';
import { type SendContractorEmail, type SendContractorEmailVariables } from '../__graphql__/SendContractorEmail';
import { sendContractorEmailMutation } from '../api';

export const ContractorEmailForm = ({ disabled }: { disabled?: boolean }): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Field
        disabled
        id='to'
        name='to'
        label={formatMessage({ id: 'to' })}
        component={Input}
        InputLabelProps={{ sx: { textTransform: 'capitalize' } }}
      />
      <Field
        id='subject'
        name='subject'
        label={formatMessage({ id: 'subject' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.maxLength(100))}
        disabled={disabled}
      />
      <Field
        id='content'
        name='content'
        label={formatMessage({ id: 'text' })}
        component={CustomEditor}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.maxHtmlLength(1000))}
        disabled={disabled}
      />
    </Box>
  );
};

export const ContractorEmailWidget = ({ contractor }: { contractor: Contractor_user }): JSX.Element => {
  const { formatMessage } = useIntl();

  const [isModalOpen, setModalOpen] = useState(false);

  const [sendEmail] = useMutation<SendContractorEmail, SendContractorEmailVariables>(sendContractorEmailMutation, {
    refetchQueries: ['ContractorEmails'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  return (
    <>
      <Button
        type='button'
        size='medium'
        variant='contained'
        onClick={() => {
          setModalOpen(true);
        }}
        disabled={!contractor.email}
      >
        {formatMessage({ id: 'new_email' })}
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        header={formatMessage({ id: 'new_email' })}
        size='sm'
        confirmBtnName={formatMessage({ id: 'send' })}
        initialValues={{
          to: contractor.email!
        }}
        onSubmit={async (values: Omit<CreateUserEmail, 'userId'>) => {
          await sendEmail({ variables: { data: { ...values, userId: contractor.id } } });
          toast(
            <SuccessSnackbar
              title={formatMessage({ id: 'email_sent.title' })}
              message={formatMessage({ id: 'email_sent.description' })}
            />
          );
          setModalOpen(false);
        }}
      >
        <ContractorEmailForm />
      </FormDialog>
    </>
  );
};
