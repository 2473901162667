import { Card, type SxProps, type Theme, Typography } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';
import { usePalette } from 'shared/hooks';

interface Props {
  title?: string | JSX.Element;
  sx?: SxProps<Theme>;
}

export const InfoCard: FC<PropsWithChildren<Props>> = ({ children, title, sx }) => {
  const { secondary } = usePalette();

  return (
    <Card
      sx={{
        width: '350px',
        padding: '20px',
        border: `1px solid ${secondary[200]}`,
        borderRadius: '16px',
        boxShadow: 'none',
        ...sx
      }}
    >
      {!!title && (
        <Typography
          variant='subtitle2'
          color='secondary.700'
          sx={{ fontWeight: 600, mb: '12px' }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Card>
  );
};
