import { useQuery } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { RegistrationStatus } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { useContext, useMemo, type FC } from 'react';
import { useIntl } from 'react-intl';
import { contractorQuery } from 'shared/api';
import { type Contractor, type ContractorVariables } from 'shared/api/__graphql__/Contractor';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Loader, PageTitle } from 'shared/components';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { localStorageUtils } from 'shared/utils/local-storage';
import {
  type ContractorRegistrations,
  type ContractorRegistrationsVariables,
  type ContractorRegistrations_registrations_data
} from './__graphql__/ContractorRegistrations';
import { contractorRegistrationsQuery } from './api';
import { RegistrationContainer } from './RegistrationContainer';

export const Dashboard: FC = () => {
  const intl = useIntl();
  const result = useQuery<ContractorRegistrations, ContractorRegistrationsVariables>(contractorRegistrationsQuery, {
    variables: {
      offset: 0,
      limit: 99
    }
  });

  const { openWithBasicInfo, openModalWithId } = useContext(RegistrationContext);
  const { data, loading: contractorInfoLoading } = useQuery<Contractor, ContractorVariables>(contractorQuery, {
    variables: { id: localStorageUtils.getItem('authState.user.id') },
    context: API_CONTEXT.AUTH
  });

  const registrations = useMemo(() => {
    const current: ContractorRegistrations_registrations_data[] = [];
    const completed: ContractorRegistrations_registrations_data[] = [];
    result.data?.registrations.data?.forEach((registration) => {
      registration.status === RegistrationStatus.vREGISTERED
        ? completed.push(registration)
        : current.push(registration);
    });
    return { current, completed };
  }, [result.data?.registrations.data]);

  if (result.loading || contractorInfoLoading || !data) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        pb: 10,
        '@media only screen and (max-width: 767px)': {
          overflowX: 'scroll'
        },
        '@media only screen and (max-width: 479px)': {
          '& > div': {
            paddingX: '20px'
          }
        }
      }}
    >
      <PageTitle name={intl.formatMessage({ id: 'dashboard' })}>
        <Button variant='contained' onClick={() => openWithBasicInfo({ ...data.user, contractorId: data.user.id })}>
          {intl.formatMessage({ id: 'new_assignment' })}
        </Button>
      </PageTitle>
      <Box
        px={12}
        sx={{
          minWidth: '670px',
          '@media only screen and (max-width: 479px)': {
            minWidth: 'unset'
          }
        }}
      >
        {registrations.current.length ? (
          <Box display='flex' flexDirection='column' gap={3}>
            <Typography color='secondary.800' variant='h2' lineHeight='24px' fontSize='24px'>
              {intl.formatMessage({ id: 'current_registrations' })}
            </Typography>
            {registrations.current.map(({ id, status, updatedAt, form }) => (
              <RegistrationContainer
                key={id}
                contractType={form?.contractType!}
                date={dayjs(updatedAt).format('DD/MM/YYYY')}
                status={status}
                id={id}
                openModal={() => {
                  openModalWithId(id);
                }}
              />
            ))}
          </Box>
        ) : null}
        {registrations.completed.length ? (
          <Box display='flex' flexDirection='column' gap={3} mt={7}>
            <Typography color='secondary.800' variant='h2' lineHeight='24px' fontSize='24px'>
              {intl.formatMessage({ id: 'completed_registrations' })}
            </Typography>
            {registrations.completed.map(({ id, status, updatedAt, form }) => (
              <RegistrationContainer
                key={id}
                contractType={form?.contractType!}
                date={dayjs(updatedAt).format('DD/MM/YYYY')}
                status={status}
                id={id}
                openModal={() => {
                  openModalWithId(id);
                }}
              />
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
