import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { SearchFailedIcon } from './icons';

interface Props {
  message?: string;
}

export const EmptySearchBox: FC<Props> = ({ message }) => {
  const intl = useIntl();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingY: '12px',
        alignItems: 'center'
      }}
    >
      <SearchFailedIcon size={40} />
      <Typography variant='body1' textAlign='center' paddingTop={3}>
        {intl.formatMessage({ id: message ?? 'no_matches_found' })}
      </Typography>
    </Box>
  );
};
