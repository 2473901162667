import { Action, Operator, QuestionComponent } from '__graphql__/globalTypes';
import dayjs, { type Dayjs } from 'dayjs';
import { type FormRules_formRules } from 'shared/hooks/useRegistration/__graphql__/FormRules';

interface ApplyArgs {
  comparedValue: string;
  currentValue: string[];
  issuerType: QuestionComponent;
}

interface AppliedActionsArgs {
  rule: FormRules_formRules;
  answers: Record<string, string[]>;
  targetId: string;
}

interface OperatorStrategy {
  apply: (args: ApplyArgs) => boolean;
}

type DateTimeComparisonFunction = (answer: Dayjs, compared: Dayjs) => boolean;

class DateTimeStrategyBase {
  protected isMatchForTimeComponent(args: ApplyArgs, comparisonFunction: DateTimeComparisonFunction): boolean {
    const { comparedValue, currentValue } = args;
    const comparedDate = dayjs(comparedValue);
    const answerDate = dayjs(currentValue[0]);

    const comparedTodayTime = dayjs().set('hour', comparedDate.hour()).set('minute', comparedDate.minute());
    const answerTodayTime = dayjs().set('hour', answerDate.hour()).set('minute', answerDate.minute());

    return comparisonFunction(answerTodayTime, comparedTodayTime);
  }

  protected isMatchForDateComponent(args: ApplyArgs, comparisonFunction: DateTimeComparisonFunction): boolean {
    const { comparedValue, currentValue } = args;
    const comparedDate = dayjs(comparedValue);
    const answer = dayjs(currentValue[0]);

    return comparisonFunction(answer, comparedDate);
  }
}

class AfterOperatorStrategy extends DateTimeStrategyBase {
  apply(args: ApplyArgs): boolean {
    const { currentValue, issuerType } = args;
    if (!currentValue[0]) {
      return false;
    }

    return issuerType === QuestionComponent.TIME
      ? this.isMatchForTimeComponent(args, (answer, compared) => answer.isAfter(compared))
      : this.isMatchForDateComponent(args, (answer, compared) => answer.isAfter(compared, 'date'));
  }
}

class BeforeOperatorStrategy extends DateTimeStrategyBase {
  apply(args: ApplyArgs): boolean {
    const { currentValue, issuerType } = args;
    if (!currentValue[0]) {
      return false;
    }

    return issuerType === QuestionComponent.TIME
      ? this.isMatchForTimeComponent(args, (answer, compared) => answer.isBefore(compared))
      : this.isMatchForDateComponent(args, (answer, compared) => answer.isBefore(compared, 'date'));
  }
}

class WithinOperatorStrategy extends DateTimeStrategyBase implements OperatorStrategy {
  apply(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    if (!currentValue[0] || !currentValue[1]) {
      return false;
    }
    const [from, to] = JSON.parse(comparedValue) as string[];

    if (!comparedValue[0] || !comparedValue[1]) {
      return false;
    }

    const startComparisonFunction: DateTimeComparisonFunction = (answerTime, comparedTime) =>
      answerTime.isAfter(comparedTime) || answerTime.isSame(comparedTime);
    const endComparisonFunction: DateTimeComparisonFunction = (answerTime, comparedTime) =>
      answerTime.isBefore(comparedTime) || answerTime.isSame(comparedTime);

    return (
      this.isMatchForTimeComponent(
        { ...args, currentValue: [currentValue[0]], comparedValue: from },
        startComparisonFunction
      ) &&
      this.isMatchForTimeComponent(
        { ...args, currentValue: [currentValue[1]], comparedValue: to },
        endComparisonFunction
      )
    );
  }
}

class BeyondOperatorStrategy extends WithinOperatorStrategy implements OperatorStrategy {
  apply(args: ApplyArgs): boolean {
    return !super.apply(args);
  }
}

class IncludeOperatorStrategy implements OperatorStrategy {
  apply(args: ApplyArgs): boolean {
    switch (args.issuerType) {
      case QuestionComponent.MULTIPLE_SELECT:
        return this.isMatchForMultipleSelectComponent(args);
      case QuestionComponent.DAY:
        return this.isMatchForWeekDaysComponent(args);
      default:
        return this.isMatchForTextComponent(args);
    }
  }

  private isMatchForMultipleSelectComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    const parsedValue: string[] = JSON.parse(comparedValue);
    return currentValue.some((v) => parsedValue.includes(v));
  }

  private isMatchForTextComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    return currentValue[0]?.includes(comparedValue);
  }

  private isMatchForWeekDaysComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;

    const getDaysOnly = (dayWithTime: string): string => dayWithTime.split('/')[0];
    const currentDaysValue = currentValue.map(getDaysOnly);
    const comparedDaysValues: string[] = JSON.parse(comparedValue).map(getDaysOnly);
    return currentDaysValue.some((v) => comparedDaysValues.includes(v));
  }
}

class NotIncludeOperatorStrategy extends IncludeOperatorStrategy implements OperatorStrategy {
  apply(args: ApplyArgs): boolean {
    return !super.apply(args);
  }
}

class IsOperatorStrategy implements OperatorStrategy {
  apply(args: ApplyArgs): boolean {
    if (!args.currentValue) {
      return false;
    }

    switch (args.issuerType) {
      case QuestionComponent.MULTIPLE_SELECT:
        return this.isMatchForMultipleSelectComponent(args);
      case QuestionComponent.DAY:
        return this.isMatchForWeekDaysComponent(args);
      case QuestionComponent.TIME:
        return this.isMatchForTimeComponent(args);
      case QuestionComponent.CONTRACT:
      case QuestionComponent.ASSIGNMENT_SCHEDULE:
      case QuestionComponent.DOCUMENT:
        return this.isMatchForDocumentComponent(args);
      case QuestionComponent.FILE_UPLOAD:
        return this.isFileUploaded(args);
      case QuestionComponent.CHECKBOX:
        return this.isMatchForCheckboxComponent(args);
      default:
        return this.isMatchForTextComponent(args);
    }
  }

  private isFileUploaded(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    const isUploaded = !!currentValue[0];
    const shouldBeUploaded = comparedValue === 'true';

    if (shouldBeUploaded) {
      return isUploaded;
    }

    return !isUploaded;
  }

  private isMatchForDocumentComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    const isSigned = !!currentValue[0];
    const shouldBeUploaded = comparedValue === 'true';

    if (shouldBeUploaded) {
      return isSigned;
    }

    return !isSigned;
  }

  private isMatchForCheckboxComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    const checkboxValue = currentValue[0];
    const shouldBeChecked = comparedValue === 'true';

    if (shouldBeChecked) {
      return !!checkboxValue;
    }

    return !checkboxValue;
  }

  private isMatchForWeekDaysComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;

    const getDaysOnly = (dayWithTime: string): string => dayWithTime.split('/')[0];
    const currentDaysValue = currentValue.map(getDaysOnly);
    const comparedDaysValues = JSON.parse(comparedValue).map(getDaysOnly);

    return this.areArraysEquivalent(comparedDaysValues, currentDaysValue);
  }

  private isMatchForTimeComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;

    if (!comparedValue[0]) {
      return false;
    }

    const answerDate = dayjs(currentValue[0]);
    const comparedDate = dayjs(comparedValue);

    const isSameHour = answerDate.hour() === comparedDate.hour();
    const isSameMinute = answerDate.minute() === comparedDate.minute();

    return isSameHour && isSameMinute;
  }

  private isMatchForMultipleSelectComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    const parsedValue: string[] = JSON.parse(comparedValue);

    return this.areArraysEquivalent(currentValue, parsedValue);
  }

  private isMatchForTextComponent(args: ApplyArgs): boolean {
    const { comparedValue, currentValue } = args;
    return currentValue[0] === comparedValue;
  }

  private areArraysEquivalent(arr1: string[], arr2: string[]): boolean {
    const currentValuesSize = new Set(arr1).size;
    const isSameSize = new Set(arr2).size === currentValuesSize;
    const isSameValues = new Set([...arr2, ...arr1]).size === currentValuesSize;

    return isSameSize && isSameValues;
  }
}

class IsNotOperatorStrategy extends IsOperatorStrategy implements OperatorStrategy {
  apply(args: ApplyArgs): boolean {
    return !super.apply(args);
  }
}

export class RuleProcessor {
  static getAppliedActions(args: AppliedActionsArgs): Record<Action, boolean> {
    const { rule, answers, targetId } = args;

    const {
      operator,
      issuer: { id, component },
      actions,
      comparedValue
    } = rule;

    const currentValue = answers[id] ?? [];
    const isMatch = RuleProcessor.getStrategy(operator).apply({ currentValue, comparedValue, issuerType: component });

    const result: Record<Action, boolean | null> = {
      [Action.HIDE]: null,
      [Action.SHOW]: null,
      [Action.READ_ONLY]: false
    };

    for (const { questionId, sectionId, action } of actions) {
      if (targetId !== questionId && sectionId !== targetId) {
        continue;
      }

      result[action] = isMatch;
    }

    return RuleProcessor.applyDefaults(result);
  }

  private static applyDefaults(result: Record<Action, boolean | null>): Record<Action, boolean> {
    const withDefaults = { ...result };

    if (result.SHOW === false) {
      withDefaults[Action.HIDE] = true;
    }

    if (result.HIDE === false) {
      withDefaults[Action.SHOW] = true;
    }

    return withDefaults as Record<Action, boolean>;
  }

  private static getStrategy(operator: Operator): OperatorStrategy {
    switch (operator) {
      case Operator.AFTER:
        return new AfterOperatorStrategy();
      case Operator.BEFORE:
        return new BeforeOperatorStrategy();
      case Operator.IS:
        return new IsOperatorStrategy();
      case Operator.IS_NOT:
        return new IsNotOperatorStrategy();
      case Operator.INCLUDE:
        return new IncludeOperatorStrategy();
      case Operator.NOT_INCLUDE:
        return new NotIncludeOperatorStrategy();
      case Operator.WITHIN:
        return new WithinOperatorStrategy();
      case Operator.BEYOND:
        return new BeyondOperatorStrategy();
    }
  }
}
