import { type FetchResult } from '@apollo/client';
import { createContext } from 'react';
import { type RegistrationBasicInfo } from 'shared/hooks';
import { type CreateFormAnswers } from 'shared/hooks/useRegistration/__graphql__/CreateFormAnswers';
import { type DocumentsModal_documents_data } from 'shared/hooks/useRegistration/__graphql__/DocumentsModal';
import { type FormRules_formRules } from 'shared/hooks/useRegistration/__graphql__/FormRules';
import { type Registration_registration } from 'shared/hooks/useRegistration/__graphql__/Registration';
import { type SaveRegistration } from 'shared/hooks/useRegistration/__graphql__/SaveRegistration';
import { type Sections_sections } from 'shared/hooks/useRegistration/__graphql__/Sections';

export enum RegFormPart {
  basic_form = 'basic_form',
  contract = 'contract',
  assignment_schedule = 'assignment_schedule',
  upload_id = 'upload_id'
}

export interface IRegistrationContext {
  invalidSections: string[];
  setInvalidSections: React.Dispatch<React.SetStateAction<string[]>>;
  save: (_: any) => Promise<FetchResult<SaveRegistration>>;
  id: string | undefined;
  registration: Registration_registration | undefined;
  loading: boolean;
  setId: React.Dispatch<React.SetStateAction<string>>;
  openModal: () => void;
  openWithBasicInfo: (_: RegistrationBasicInfo) => void;
  openModalWithId: (arg0: string) => void;
  closeModal: () => void;
  isOpened: boolean;
  sendAnswer: (_: any) => Promise<FetchResult<CreateFormAnswers, Record<string, any>, Record<string, any>>>;
  documents: DocumentsModal_documents_data[];
  completenessOfRegistration: Record<string, boolean>;
  isDirty: any[];
  setIsDirty: (arg0: any[]) => void;
  basicInfo?: RegistrationBasicInfo;
  clearBasicInfo: () => void;
  sections: Array<Sections_sections & { readonly?: boolean }>;
  answers: Record<string, string[]>;
  previewForm: { name: string; id: string };
  setPreviewForm: (_: IRegistrationContext['previewForm']) => void;
  previewFormValues: Record<string, any>;
  setPreviewFormValues: (_: Record<string, any>) => void;
  rules: FormRules_formRules[];
  setSectionValues: (_: Record<string, string | string[]>) => void;
  combinedAnswers: Record<string, string[]>;
}

export const RegistrationContext = createContext<IRegistrationContext>({
  save: (_: any) => Promise.resolve() as any,
  invalidSections: [],
  setInvalidSections: () => {},
  id: '',
  registration: undefined,
  loading: false,
  setId: ((_: string) => {}) as React.Dispatch<React.SetStateAction<string>>,
  openModal: () => {},
  openWithBasicInfo: () => {},
  openModalWithId: (_: string) => {},
  closeModal: () => {},
  isOpened: false,
  sendAnswer: async (_: any) => await Promise.resolve({}),
  documents: [],
  completenessOfRegistration: {
    basic_form: false,
    registration_form: false,
    bank_details: false,
    contract: false,
    assignment_schedule: false,
    upload_id: false
  },
  isDirty: [false, () => {}],
  setIsDirty: () => {},
  clearBasicInfo: () => {},
  sections: [],
  answers: {},
  previewForm: { name: '', id: '' },
  setPreviewForm: () => {},
  previewFormValues: {},
  setPreviewFormValues: () => {},
  rules: [],
  combinedAnswers: {},
  setSectionValues: () => {}
});
