import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { type Tasks_tasks_data } from './__graphql__/Tasks';

dayjs.extend(utc);

enum TaskCategory {
  Upcoming = 'upcoming',
  InProgress = 'in_progress',
  DueToday = 'due_today',
  Overdue = 'overdue',
  Done = 'done'
}

export function getTaskCategory(task: Tasks_tasks_data): TaskCategory {
  const { startDate, dueDate, done } = task;
  if (done) {
    return TaskCategory.Done;
  }

  const now = dayjs.utc();

  const dueDateObj = dayjs.utc(dueDate);
  const startDateObj = dayjs.utc(startDate);

  if (dueDateObj.isBefore(now, 'day')) {
    return TaskCategory.Overdue;
  } else if (dueDateObj.isSame(now, 'day')) {
    return TaskCategory.DueToday;
  } else if ((startDateObj.isBefore(now, 'day') || startDateObj.isSame(now, 'day')) && dueDateObj.isAfter(now, 'day')) {
    return TaskCategory.InProgress;
  } else if (startDateObj.isAfter(now, 'day')) {
    return TaskCategory.Upcoming;
  }

  return TaskCategory.Done;
}
