import gql from 'graphql-tag';

export const emailTemplatesListQuery = gql`
  query EmailTemplates {
    emailTemplates {
      id
      group
      isActive
      contractType
      templateId
    }
  }
`;

export const emailTemplateQuery = gql`
  query EmailTemplate($id: Int!) {
    emailTemplate(id: $id) {
      id
      content
      isActive
      group
      sendTo
      subject
      senderName
    }
  }
`;

export const updateEmailTemplateMutation = gql`
  mutation UpdateEmailTemplate($data: ChangeEmailData!) {
    updateEmailTemplate(data: $data) {
      id
    }
  }
`;
