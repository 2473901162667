import { useQuery } from '@apollo/client';
import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { invoicesQuery } from 'shared/api';
import { type Invoices } from 'shared/api/__graphql__/Invoices';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Loader, StyledTablePagination } from 'shared/components';
import { TENANT_HEADER_NAME } from 'shared/constants';
import { ProductsContext } from 'shared/contexts/productsContext';
import { useTablePagination } from 'shared/hooks';
import { getInvoiceStatusColor } from 'shared/utils/colors';
import { getTruncatedNumber } from 'shared/utils/formatters';

interface Props {
  tenantId?: string;
}

export const BillingHistory: FC<Props> = ({ tenantId }) => {
  const { products } = useContext(ProductsContext);
  const { formatMessage } = useIntl();
  const { palette } = useTheme();
  const tablePaginationProps = useTablePagination({
    defaultPage: 0
  });

  const invoicesResult = useQuery<Invoices>(invoicesQuery, {
    context: {
      ...API_CONTEXT.PAYMENT,
      ...(tenantId
        ? {
            headers: {
              [TENANT_HEADER_NAME]: tenantId
            }
          }
        : {})
    }
  });

  const emptyRows =
    tablePaginationProps.page > 0
      ? Math.max(
          0,
          (1 + tablePaginationProps.page) * tablePaginationProps.rowsPerPage - invoicesResult.data?.invoices?.length!
        )
      : 0;

  return (
    <Box>
      <Typography
        variant='h3'
        color='secondary.700'
        sx={{ mb: '16px', fontSize: '20px', lineHeight: '24px', fontWeight: 600 }}
      >
        {formatMessage({ id: 'billing_history' })}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{formatMessage({ id: 'invoice' })}</TableCell>
              <TableCell>{formatMessage({ id: 'plan_name' })}</TableCell>
              <TableCell>{formatMessage({ id: 'payment_date' })}</TableCell>
              <TableCell>{formatMessage({ id: 'amount' })}</TableCell>
              <TableCell>{formatMessage({ id: 'card' })}</TableCell>
              <TableCell>{formatMessage({ id: 'status' })}</TableCell>
              <TableCell>{formatMessage({ id: 'action' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicesResult.data?.invoices
              .slice(
                tablePaginationProps.page * tablePaginationProps.rowsPerPage,
                (tablePaginationProps.page + 1) * tablePaginationProps.rowsPerPage
              )
              .map(({ id, amountPaid, created, status, productId, card, url }) => (
                <TableRow key={id}>
                  <TableCell>
                    #
                    {(invoicesResult.data?.invoices?.length ?? 0) -
                      (invoicesResult.data?.invoices?.findIndex((i) => i.id === id) ?? 0)}
                  </TableCell>
                  <TableCell>{products?.find((p) => p.id === productId)?.name}</TableCell>
                  <TableCell>{dayjs(created * 1000).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>£{getTruncatedNumber(amountPaid / 100)}</TableCell>
                  <TableCell>**** **** **** {card}</TableCell>
                  <TableCell className='badge'>
                    <Box
                      sx={{
                        textAlign: 'center',
                        py: '8px',
                        borderRadius: '4px',
                        backgroundColor: getInvoiceStatusColor(status)
                      }}
                    >
                      {formatMessage({ id: status })}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ position: 'relative', paddingTop: 0, paddingBottom: 0 }}>
                    <Select
                      displayEmpty
                      value=''
                      renderValue={() => {
                        return (
                          <Typography variant='body2' color='secondary.main'>
                            {formatMessage({ id: 'invoice' })}
                          </Typography>
                        );
                      }}
                      sx={{
                        fieldset: {
                          borderColor: palette.primary.main
                        }
                      }}
                      labelId='invoice-label'
                    >
                      <MenuItem
                        value='open'
                        onClick={() => {
                          if (url) window.open(url, '_blank');
                        }}
                      >
                        <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
                          {formatMessage({ id: 'open' })}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value='download'
                        onClick={() => {
                          if (url) window.open(`${url.split('?')[0]}/pdf`, '_blank');
                        }}
                      >
                        <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
                          {formatMessage({ id: 'download' })}
                        </Typography>
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            {invoicesResult.loading && (
              <TableRow
                style={{
                  background: 'inherit'
                }}
              >
                <TableCell
                  colSpan={7}
                  style={{
                    height: 64 * 2,
                    padding: 0
                  }}
                >
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {!invoicesResult.loading && !invoicesResult.data?.invoices.length && (
              <TableRow
                style={{
                  background: 'inherit'
                }}
              >
                <TableCell colSpan={7}>
                  <Typography variant='body1' textAlign='center'>
                    {formatMessage({ id: 'no_invoices_yet' })}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 64 * emptyRows
                }}
              >
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(invoicesResult?.data?.invoices?.length ?? 0) > tablePaginationProps.rowsPerPage && (
        <StyledTablePagination count={invoicesResult.data?.invoices.length ?? 0} {...tablePaginationProps} />
      )}
    </Box>
  );
};
