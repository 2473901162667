import gql from 'graphql-tag';

export const footerLinksQuery = gql`
  query CompanyLinks {
    companyLinks {
      footerLinks {
        name
        link
      }
      emailLink
      linkedInLink
      facebookLink
      twitterLink
    }
  }
`;
