import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { TRIAL_PLAN_ID } from 'shared/constants';
import { getDiscountedPrice, type ICurrentPlan } from 'shared/utils/subscription';

export const DiscountLabels: FC<ICurrentPlan> = ({ discount, productId, defaultPrice }) => {
  if (!discount || productId === TRIAL_PLAN_ID) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', gap: '14px' }}>
      <Box sx={{ backgroundColor: 'success.100', p: '8px 12px', borderRadius: '8px' }}>
        <Typography variant='body2' sx={{ color: 'secondary.700', fontSize: '20px' }}>
          {discount.valueInPercent}%
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: 'warning.100', p: '8px 12px', borderRadius: '8px' }}>
        <Typography variant='body2' sx={{ color: 'secondary.700', fontSize: '20px' }}>
          £{getDiscountedPrice(discount.valueInPercent, defaultPrice)}
        </Typography>
      </Box>
    </Box>
  );
};
