import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import apolloClient from 'shared/apolloClient';
import { StableNavigateContextProvider } from 'shared/contexts';
import { translationMessages } from 'shared/translations';
import { Language } from 'shared/types';
import { flattenMessages } from 'shared/utils/flattenMessages';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <IntlProvider
        locale={Language.EN}
        defaultLocale={Language.EN}
        messages={flattenMessages(translationMessages['en-US'])}
      >
        <BrowserRouter>
          <StableNavigateContextProvider>
            <App />
          </StableNavigateContextProvider>
        </BrowserRouter>
      </IntlProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
