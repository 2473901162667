import { Button, Grid, IconButton, Link, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, type NavigateFunction } from 'react-router';
import { toast } from 'react-toastify';
import { Input } from 'shared/components';
import { EyeIcon } from 'shared/components/icons';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import ErrorSnackbar from 'shared/components/Snackbars/ErrorSnackbar';
import routes from 'shared/constants/routes';
import AuthContext from 'shared/contexts/authContext';
import { type LoginParams } from 'shared/types/auth';
import {
  I_AM_TEAPOT_ERROR,
  isAuthenticationError,
  isBadInputError,
  isForbiddenError,
  isGoneError
} from 'shared/utils/errors';
import { Validator } from 'shared/utils/validator';

export const Login = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { login, error, setError } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const mapErrorToExplanation = useCallback(
    (error: string, navigate: NavigateFunction) => {
      switch (error) {
        case I_AM_TEAPOT_ERROR:
          return (
            <span>
              {formatMessage({ id: 'detected_number_attempts' })}
              <Link
                onClick={() => {
                  navigate(routes.forgotPassword);
                }}
              >
                {formatMessage({ id: 'reset_password_here' })}
              </Link>
            </span>
          );
        default:
          return null;
      }
    },
    [formatMessage]
  );

  useEffect(() => {
    const errorCode = error?.code;
    switch (true) {
      case error?.error === 'system_suspended': {
        toast(
          <AttentionSnackbar
            title={formatMessage({ id: 'system_is_unavailable' })}
            message={formatMessage({ id: 'system_is_unavailable_message' })}
          />
        );
        break;
      }
      case isAuthenticationError(errorCode) || isBadInputError(errorCode): {
        toast(
          <ErrorSnackbar
            title={formatMessage({ id: 'login_error' })}
            message={formatMessage({ id: 'email_or_password_is_wrong' })}
          />
        );
        break;
      }
      case isForbiddenError(errorCode): {
        toast(
          <ErrorSnackbar
            title={formatMessage({ id: 'login_error' })}
            message={formatMessage({ id: 'account_disabled' })}
          />
        );
        break;
      }
      case isGoneError(errorCode): {
        toast(
          <AttentionSnackbar
            title={formatMessage({ id: 'system_unavailable' })}
            message={formatMessage({ id: 'system_deactivated' })}
          />,
          {
            position: toast.POSITION.TOP_RIGHT
          }
        );
        break;
      }
    }

    return () => {
      setError(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Formik<LoginParams>
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={async (formValues: LoginParams) => {
        await login.execute(formValues);
      }}
      validateOnChange={false}
    >
      <Form>
        <Grid container justifyContent='center'>
          <Grid item width='320px' mr={{ xs: 0 }}>
            <Typography
              variant='h1'
              color='secondary.800'
              sx={{
                marginBottom: '16px'
              }}
            >
              {formatMessage({ id: 'login' })}
            </Typography>
            {login.error && (
              <Typography
                variant='body1'
                color='error'
                sx={{
                  marginBottom: '16px'
                }}
              >
                {mapErrorToExplanation(login.error as unknown as string, navigate)}
              </Typography>
            )}
            <Field
              id='email'
              name='email'
              type='email'
              component={Input}
              label={formatMessage({ id: 'email' })}
              sx={{
                marginBottom: '12px'
              }}
              validate={Validator.pipe(Validator.methods.required(), Validator.methods.email())}
            />
            <Field
              id='password'
              name='password'
              type={isPasswordVisible ? 'text' : 'password'}
              component={Input}
              label={formatMessage({ id: 'password' })}
              sx={{
                marginBottom: '16px'
              }}
              validate={Validator.pipe(Validator.methods.required())}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setIsPasswordVisible((pr) => !pr);
                    }}
                  >
                    <EyeIcon variant={isPasswordVisible ? 'closed' : 'opened'} />
                  </IconButton>
                )
              }}
            />
            <Button
              type='submit'
              size='large'
              variant='contained'
              sx={{
                marginBottom: '12px'
              }}
            >
              {formatMessage({ id: 'login' })}
            </Button>
            <Button
              size='large'
              variant='outlined'
              sx={{
                marginBottom: '12px'
              }}
              onClick={() => {
                navigate(routes.forgotPassword);
              }}
            >
              {formatMessage({ id: 'password_recovery' })}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};
