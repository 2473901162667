import { useQuery } from '@apollo/client';
import { ToggleButtonGroup, ToggleButton, Grid, Button } from '@mui/material';
import { OrderDirection, RegistrationOrderField } from '__graphql__/globalTypes';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { type Contractor_user } from 'shared/api/__graphql__/Contractor';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Card, CardHeader } from 'shared/components';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette } from 'shared/hooks';
import { type ContractorEmails, type ContractorEmailsVariables } from '../__graphql__/ContractorEmails';
import {
  type ViewContractorRegistrations,
  type ViewContractorRegistrationsVariables
} from '../__graphql__/ViewContractorRegistrations';
import { contractorEmailsQuery, contractorRegistrationsQuery } from '../api';
import { ContractorEmailsList } from './ContractorEmailsList';
import { ContractorEmailWidget } from './ContractorEmailWidget';
import { ContractorRegistrationsList } from './ContractorRegistrationsList';

enum ETabs {
  Registrations = 'registrations',
  Emails = 'emails'
}

export const RelationsTabs = ({ contractor }: { contractor: Contractor_user }): JSX.Element => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();

  const [tab, setTab] = useState<ETabs>(ETabs.Registrations);
  const { openWithBasicInfo } = useContext(RegistrationContext);

  const { data, loading } = useQuery<ContractorEmails, ContractorEmailsVariables>(contractorEmailsQuery, {
    variables: { userId: contractor.id },
    context: API_CONTEXT.AUTH,
    skip: !contractor.id
  });

  const { data: registrations, loading: registrationLoading } = useQuery<
    ViewContractorRegistrations,
    ViewContractorRegistrationsVariables
  >(contractorRegistrationsQuery, {
    variables: {
      offset: 0,
      limit: 99,
      filter: { contractorId: contractor.id },
      orderBy: { field: RegistrationOrderField.createdAt, direction: OrderDirection.desc }
    },
    skip: !contractor.id
  });

  return (
    <Card>
      <CardHeader>
        <ToggleButtonGroup
          value={tab}
          sx={{ color: secondary[800] }}
          exclusive
          onChange={(e, v) => {
            if (v !== null) {
              setTab(v);
            }
          }}
          orientation='horizontal'
          className='stacked'
        >
          {Object.values(ETabs).map((tab) => (
            <ToggleButton key={tab} value={tab} sx={{ minWidth: '135px' }}>
              {formatMessage({ id: tab })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {tab === ETabs.Emails ? (
          <ContractorEmailWidget contractor={contractor} />
        ) : (
          <Button variant='contained' onClick={() => openWithBasicInfo({ ...contractor, contractorId: contractor.id })}>
            {formatMessage({ id: 'new_registration' })}
          </Button>
        )}
      </CardHeader>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          height: '420px'
        }}
      >
        {tab === ETabs.Registrations && (
          <ContractorRegistrationsList data={registrations?.registrations.data} loading={registrationLoading} />
        )}
        {tab === ETabs.Emails && <ContractorEmailsList data={data?.userEmails} loading={loading} />}
      </Grid>
    </Card>
  );
};
