import dayjs, { type Dayjs } from 'dayjs';

const getTimezoneOffset = (value: Date): number => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (value: string | Date | Dayjs | null): null | Date => {
  if (!value) return null;
  // @ts-expect-error: a lot of types
  const dateTime = new Date(value);
  const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
  return utcFromLocal;
};

export const localToUTC = (value: Date | string | null): null | Date => {
  if (!value) return null;
  const dateTime = new Date(value);
  const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
  return utcFromLocal;
};

export const isValidDate = (value: string | null | Dayjs | Date): boolean => {
  if (!value) return false;
  if (dayjs.isDayjs(value)) return true;
  if (value instanceof Date) return true;
  if (value?.length < 10) return false;
  if (new Date(value).toString() === 'Invalid Date') return false;
  if (isNaN(+new Date(value))) return false;
  return true;
};
