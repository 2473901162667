import gql from 'graphql-tag';

export const usersQuery = gql`
  query Users(
    $offset: Int!
    $limit: Int!
    $orderBy: UserOrder
    $filter: UserFilter
    $query: String
  ) {
    users(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      filter: $filter
      query: $query
    ) {
      total
      data {
        id
        email
        firstName
        lastName
        role
        isActive
        lastLogin
        agencyId
      }
    }
  }
`;

export const agenciesList = gql`
  query AgenciesList(
    $offset: Int!
    $limit: Int!
    $orderBy: AgencyOrder
  ) {
    agencies(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const createAgencyMutation = gql`
  mutation CreateAgency($data: AgencySave!) {
    saveAgency(data: $data) {
      id
    }
  }
`;
