import { ApolloClient, InMemoryCache, ApolloLink, gql, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, type ErrorResponse } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { type GraphQLError } from 'graphql';
import { TENANT_HEADER_NAME } from './constants';

const RETRY_OPERATIONS: string[] = [];
const IGNORED_ERRORS: string[] = ['SignIn', 'RefreshToken'];

const getNewToken = (): Observable<void> => {
  // @ts-expect-error: error
  return new Observable(async (subscriber) => {
    const authState = localStorage.getItem('authState');
    if (!authState) {
      localStorage.removeItem('authState');
      await apolloClient.clearStore();
      window.location.replace('/login');
      return;
    }
    let parsedState = JSON.parse(authState);
    apolloClient
      .mutate({
        mutation: gql`
          mutation RefreshToken($token: JWT!) {
            refreshToken(token: $token) {
              accessToken
              refreshToken
            }
          }
        `,
        context: {
          uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`
        },
        variables: { token: parsedState.refreshToken }
      })
      .then(
        (result) => {
          if (subscriber.closed) return;

          const { accessToken, refreshToken } = result.data.refreshToken;
          parsedState = { ...parsedState, accessToken, refreshToken };
          localStorage.setItem('authState', JSON.stringify(parsedState));

          subscriber.next(accessToken);
          subscriber.complete();
        },
        (err) => {
          subscriber.error(err);
          subscriber.complete();
          throw new Error(err);
        }
      )
      .catch(async () => {
        localStorage.removeItem('authState');
        await apolloClient.clearStore();
        window.location.replace('/login');
      });

    return subscriber;
  });
};

const errorLink = onError(({ operation, graphQLErrors = [], forward }: ErrorResponse) => {
  graphQLErrors.forEach(formatError);

  if (IGNORED_ERRORS.includes(operation.operationName)) {
    return;
  }
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return getNewToken().flatMap(() => forward(operation));
        default:
      }
    }
  }
  // return forward(operation);
});

const retryLink = new RetryLink({
  delay: {
    initial: 200,
    jitter: false
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => Boolean(error) && RETRY_OPERATIONS.includes(operation.operationName)
  }
});

const formatError = (error: GraphQLError): void => {
  switch (error.extensions?.code) {
    default:
    // error.message = i18n.t(`apiErrors:${error.message}`, error.extensions?.exception);
  }
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authState = localStorage.getItem('authState');
  const token = authState ? JSON.parse(authState)?.accessToken : undefined;
  // Tenant extract
  const [hostname] = window.location.hostname.split('.');
  // SuperAdmin case tenant extract
  const splittedPathname = window.location.pathname.split('/');
  const tenantPartIndex = splittedPathname.findIndex((v) => v === 'tenant');
  let tenantId;
  if (tenantPartIndex !== -1) {
    tenantId = splittedPathname[tenantPartIndex + 1];
  } else if (hostname && !['localhost', 'dev', 'stage', 'swiftonboard', 'onboarding'].includes(hostname)) {
    tenantId = hostname;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      [TENANT_HEADER_NAME]: 'alliancecombined',
      ...(tenantId ? { [TENANT_HEADER_NAME]: tenantId } : {}),
      ...headers,
      authorization: token ? `Bearer ${token as string}` : ''
    }
  };
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_BASE}graphql`
});
// console.log(window.location, getApolloContext())
// const httpLinkAuth = new HttpLink({
//   uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`,
// });

/** Concatenates incoming arrays with cached arrays for paginated queries. */
// const queryWithPaginationOptions: FieldPolicy = {
//   // Group query results with same args but different offset or limit into one cache.
//   keyArgs: (args) => {
//     const argsWithoutListParams = omit(args, 'offset', 'limit');

//     return JSON.stringify(argsWithoutListParams);
//   },
//   merge: (existing, incoming, { variables }) => {
//     if (variables?.offset === undefined || variables?.limit === undefined) {
//       return incoming;
//     }

//     return mergeWith({}, existing, incoming, (existingArray, incomingArray) => {
//       if (Array.isArray(incomingArray) && Array.isArray(existingArray)) {
//         return [...existingArray.slice(0, variables.offset), ...incomingArray];
//       }
//       return undefined;
//     });
//   },
// };

export const cache = new InMemoryCache();

const link = ApolloLink.from([retryLink, errorLink, authLink, uploadLink]);

const apolloClient = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    query: { fetchPolicy: 'network-only' },
    watchQuery: { fetchPolicy: 'network-only', nextFetchPolicy: 'no-cache' }
  }
});

export default apolloClient;
