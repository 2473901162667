import { type TextFieldProps } from '@mui/material/TextField';
import { type FieldProps } from 'formik';
import { path, split } from 'ramda';
import { useCallback, type ChangeEvent, type FC, type FocusEvent } from 'react';
import { isValidDate } from 'shared/utils/date';
import { BaseInput } from './form/InputComponent';

type Props = FieldProps & Omit<TextFieldProps, 'name' | 'value'>;

export const Input: FC<Props> = ({ field, form, ...props }) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.target.value = e.target.value.trimStart();

      if (field.onChange) field.onChange(e);
      if (props.onChange) props.onChange(e);
      if (form.errors[field.name]) form.setFieldError(field.name, undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onChange, props.onChange, form.errors[field.name]]
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      // Locale issue if value is date
      if (!isValidDate(e.target.value)) {
        const trimmedValue = e.target.value.trim();
        if (trimmedValue !== e.target.value) {
          e.target.value = trimmedValue;
          form.setFieldValue(field.name, e.target.value);
        }
      }

      if (field.onBlur) field.onBlur(e);
      if (props.onBlur) props.onBlur(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onBlur, props.onBlur]
  );

  const resolvedErrorMessage =
    form && field?.name && (path(split('.', field.name), form.touched) || form.submitCount > 0)
      ? path(split('.', field.name), form.errors)
      : undefined;

  return (
    <BaseInput
      field={field}
      helperText={resolvedErrorMessage as string | undefined}
      error={!!resolvedErrorMessage}
      {...props}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
