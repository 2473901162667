import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FormikContext, type FieldProps, Field } from 'formik';
import { useEffect, type FC, useContext, useState, type ChangeEvent } from 'react';
import { usePalette } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { ErrorHelper } from '../ErrorHelper';
import { CircleCheckIcon } from '../icons';
import { TextWithNewLines } from '../TextWithNewLines';
import { InputTooltip } from './InputTooltip';
import { type FormComponentProps, type ComponentProps } from './types';

export const Component: FC<ComponentProps> = (props) => {
  const { form, id, text, disabled, field = {} as FieldProps['field'], options, tooltip } = props;
  const { error, secondary, primary } = usePalette();
  const errorMessage = form?.errors[id] as string;

  return (
    <Box>
      <Box
        position='relative'
        display='flex'
        flexDirection='column'
        gap={3}
        padding={4}
        sx={{
          background: '#FFFFFF',
          border: `1px solid ${errorMessage ? error[100] : secondary[300]}`,
          borderRadius: '8px',
          '&:hover': {
            border: `1px solid ${errorMessage ? error[500] : primary[600]}`
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='body1' color='secondary.800' whiteSpace='pre-line'>
            <TextWithNewLines text={text ?? ''} />
          </Typography>
          {!!tooltip && <InputTooltip title={tooltip} />}
        </Box>
        <ToggleButtonGroup
          {...props}
          {...field}
          placeholder={props.placeholder ?? ''}
          onBlur={() => {
            form?.validateField(field.name);
          }}
          disabled={disabled}
          exclusive
          orientation='horizontal'
          sx={{
            flexWrap: 'wrap',
            color: secondary[800]
          }}
        >
          {(options ?? []).map((answer) => (
            <ToggleButton
              id={id}
              key={answer.value}
              value={answer.value}
              sx={{ display: 'flex', gap: '12px', whiteSpace: 'wrap', flexGrow: 1, textTransform: 'none' }}
            >
              {field.value === answer.value && <CircleCheckIcon />}
              {answer.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <ErrorHelper error={form?.errors[id] as string} />
    </Box>
  );
};

export const OptionsSmallComponent: FC<FormComponentProps> = (props) => {
  const { id, options, required: initialRequired, initialAnswer } = props;
  const [value, setValue] = useState<string>(initialAnswer ?? '');
  const required = initialRequired ?? false;

  const formik = useContext(FormikContext);

  useEffect(() => {
    if (value !== initialAnswer) {
      setValue(initialAnswer ?? '');
    }
  }, [initialAnswer, value]);

  return formik ? (
    <Field
      {...props}
      name={id}
      component={Component}
      validate={required && Validator.pipe(Validator.methods.required())}
    />
  ) : (
    <Component
      {...props}
      options={options ?? []}
      field={
        {
          value,
          onChange: (e: ChangeEvent<any>) => {
            setValue(e.target.value);
          }
        } as FieldProps['field']
      }
    />
  );
};
