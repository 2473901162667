import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Fragment, useContext, useEffect, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'shared/components';
import { CheckmarkIcon, ChevronIcon, CrossIcon, PencilIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { type ComponentPropertiesProps } from './types';

interface Props extends Pick<ComponentPropertiesProps, 'onSubmit'> {
  readOnly?: boolean;
}

export const DefaultValueProperty: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { secondary, primary } = usePalette();
  const { readOnly, onSubmit } = props;
  const { section, component } = useContext(FormBuilderContext);
  const initAnswer = component?.initialAnswer ?? '';
  const [fieldValue, setFieldValue] = useState<string>(initAnswer);
  const [isEdit, setIsEdit] = useState(false);

  console.log(fieldValue);
  useEffect(() => {
    setFieldValue(initAnswer);
    setIsEdit(false);
  }, [section, initAnswer]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: isEdit ? 'column' : 'row',
        gap: isEdit ? '12px' : '0px',
        background: 'secondary.50',
        borderRadius: '8px',
        p: '16px',
        border: `1px solid ${secondary[200]}`
      }}
    >
      {isEdit ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant='body1' sx={{ color: 'secondary.600' }}>
              {formatMessage({ id: 'component_properties.default_value' })}
            </Typography>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <IconButton
                onClick={async () => {
                  await onSubmit({ initialAnswer: fieldValue || null });
                  setIsEdit(false);
                }}
              >
                <CheckmarkIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setFieldValue('');
                  setIsEdit(false);
                }}
              >
                <CrossIcon color={primary[600]} />
              </IconButton>
            </Box>
          </Box>
          <Select
            displayEmpty
            label={formatMessage({ id: 'component_properties.default_value' })}
            value={fieldValue}
            onChange={(e) => {
              setFieldValue(e.target.value);
            }}
            renderValue={(v) => {
              if (!v) {
                return formatMessage({ id: 'component_placeholders.default_value' });
              }
              return v;
            }}
            IconComponent={ChevronIcon}
            endAdornment={
              fieldValue ? (
                <IconButton
                  sx={{ right: '26px', position: 'absolute', padding: '2px' }}
                  onClick={() => {
                    setFieldValue('');
                  }}
                >
                  <CrossIcon />
                </IconButton>
              ) : null
            }
          >
            {/* <MenuItem value=''>Not selected</MenuItem> */}
            {(component?.options ?? []).map((i) => (
              <MenuItem value={i.value} key={i.optionId}>
                {i.label}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography variant='body1' sx={{ color: 'secondary.600' }}>
              {formatMessage({ id: 'component_properties.default_value' })}
            </Typography>
            <Typography sx={{ color: component?.initialAnswer ? 'secondary.700' : 'secondary.400' }}>
              {component?.initialAnswer ?? formatMessage({ id: 'component_placeholders.default_value' })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            {!readOnly && (
              <IconButton
                onClick={() => {
                  setFieldValue(initAnswer);
                  setIsEdit(true);
                }}
              >
                <PencilIcon />
              </IconButton>
            )}
            <Tooltip
              text={
                <>
                  {formatMessage({ id: 'component_tooltips.default_value' })
                    .split('\n')
                    .map((line, index) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                </>
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};
