import { Button, Grid, Typography } from '@mui/material';
import { useRef, type ChangeEvent, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ErrorSnackbar } from 'shared/components/Snackbars';
import { UserAvatar } from 'shared/components/UserAvatar';
import { usePalette } from 'shared/hooks';
import { useUploadPhoto } from '../hooks';

interface Props {
  changeAvatarFile: (file: File) => void;
}

export const UploadPhoto: FC<Props> = ({ changeAvatarFile }) => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const inputRef = useRef<HTMLInputElement>(null);

  const { validateFile, getErrorNotificationContent, uploadedImage } = useUploadPhoto();

  const triggerFileManager = (): void => {
    inputRef.current?.click();
  };

  const processFile = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.[0];
    const validationErrors = await validateFile(file);
    const errorNotificationContent = getErrorNotificationContent(validationErrors);

    if (errorNotificationContent) {
      toast(<ErrorSnackbar {...errorNotificationContent} />, { position: toast.POSITION.BOTTOM_CENTER });
    } else if (file) {
      changeAvatarFile(file);
    }
  };

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      sx={{ padding: '16px 10px 24px 16px' }}
    >
      <UserAvatar source={uploadedImage} />
      <Typography
        sx={{
          my: '1em',
          color: secondary[600],
          fontSize: '16px',
          lineHeight: '18px'
        }}
      >
        {formatMessage({ id: 'file_restrictions' })}
      </Typography>
      <Button onClick={triggerFileManager} variant='outlined' sx={{ color: secondary[800] }}>
        <input hidden accept='.jpg,.jpeg,.png,image/jpeg,image/png' type='file' onChange={processFile} ref={inputRef} />
        {formatMessage({ id: 'edit_profile_photo' })}
      </Button>
    </Grid>
  );
};
