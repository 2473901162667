import { FileFormat } from 'shared/types';

export const ACCEPT_FILES = {
  [FileFormat.PDF]: {
    'application/pdf': ['.pdf']
  },
  [FileFormat.DOC_DOCX]: {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
  },
  [FileFormat.JPG_JPEG]: {
    'image/jpeg': ['.jpeg', '.jpg']
  },
  [FileFormat.PNG]: {
    'image/png': ['.png']
  },
  [FileFormat.CSV]: { 'text/csv': ['.csv'] }
};
