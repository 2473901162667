import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import { DocumentRelation, type DocumentType, RegistrationStatus, Role, Title } from '__graphql__/globalTypes';
import { Field, Form, Formik } from 'formik';
import { useCallback, useContext, useMemo, useState, type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { createDocumentMutation, uploadDocumentMutation } from 'shared/api';
import { type CreateDocument, type CreateDocumentVariables } from 'shared/api/__graphql__/CreateDocument';
import { type UploadDocument, type UploadDocumentVariables } from 'shared/api/__graphql__/UploadDocument';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { CustomDropzone, Input, Select } from 'shared/components';
import { CrossIcon, TriangleListMarker } from 'shared/components/icons';
import { type Agencies, type AgenciesVariables } from 'shared/components/RegistrationModal/__graphql__/Agencies';
import { agenciesQuery } from 'shared/components/RegistrationModal/api';
import { FormikListener } from 'shared/components/RegistrationModal/FormikListener';
import { UnsavedDataConfirmation } from 'shared/components/RegistrationModal/UnsavedDataConfirmation';
import { AttentionSnackbar, ErrorSnackbar } from 'shared/components/Snackbars';
import AuthContext from 'shared/contexts/authContext';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette, useRoles } from 'shared/hooks';
import { FileFormat } from 'shared/types';
import { type AttachedDocument } from 'shared/types/file';
import { Validator } from 'shared/utils/validator';

export const ReferralModal: FC = () => {
  const { formatMessage } = useIntl();
  const { primary, secondary, info } = usePalette();

  const { closeModal, registration, isDirty, setIsDirty, setId, id, loading, save, basicInfo, isOpened, documents } =
    useContext(RegistrationContext);
  const { isContractor } = useRoles();
  const { authState } = useContext(AuthContext);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isOnClosingProcess, setIsOnClosingProcess] = useState(false);
  const [confirmationFunc, setConfirmationFunc] = useState({ func: () => {} });

  // basic info functions

  const agenciesResult = useQuery<Agencies, AgenciesVariables>(agenciesQuery, {
    variables: {
      offset: 0,
      limit: 1000
    },
    skip: loading
  });
  const currentAgency = useMemo(
    () =>
      agenciesResult.data?.agencies.data?.find((agency) =>
        agency.consultants?.find((consultant) => consultant.id === authState.user?.id)
      ),
    [agenciesResult.data?.agencies.data, authState.user?.id]
  );

  const titleOptions = useMemo(() => Object.values(Title).map((t) => ({ value: t, label: t })), []);
  // documents functions

  const [files, setFiles] = useState<AttachedDocument[]>([]);
  const [docsError, setDocsError] = useState<string>();

  const documentsOptions = useMemo(
    () =>
      ['BIRTH_CERTIFICATE', 'ID', 'VISA'].map((t) => ({
        value: t,
        label: formatMessage({ id: t })
      })),
    [formatMessage]
  );

  const [createDocument] = useMutation<CreateDocument, CreateDocumentVariables>(createDocumentMutation, {
    context: API_CONTEXT.DOCUMENT
  });
  const [uploadDocument] = useMutation<UploadDocument, UploadDocumentVariables>(uploadDocumentMutation, {
    context: API_CONTEXT.DOCUMENT
  });

  const onDrop = useCallback(
    (_: string, value: Array<string | AttachedDocument>) => {
      const successfullUpload = (): void => {
        setDocsError(undefined);
        setFiles(value as AttachedDocument[]);
      };
      if (value.length < files.length) {
        setFiles(value as AttachedDocument[]);
        return;
      }
      // @ts-expect-error: error
      if (value.some((v) => !['application/pdf', 'image/png', 'image/jpeg'].includes(v.file.type))) {
        setDocsError('wrong_file_format');
        // @ts-expect-error: error
      } else if (value.some((v) => v.file.size / 1024 / 1024 > 5)) {
        setDocsError('file_is_too_big');
      } else successfullUpload();
    },
    [files.length]
  );

  const onSave = useAsyncCallback(async (documentType: DocumentType, registrationId: string) => {
    await Promise.all(
      files.map(async (file) => {
        const response = await createDocument({
          variables: {
            data: {
              contentType: file.file.type,
              documentType,
              filename: file.file.name,
              relatedId: registrationId,
              relatedTo: DocumentRelation.REGISTRATION
            }
          }
        });
        Object.defineProperty(file.file, 'name', {
          writable: true,
          value: `${registrationId}|${response.data!.createDocument.id}`
        });
        await uploadDocument({ variables: { file: file.file } });
      })
    );
    setFiles([]);
    setDocsError(undefined);
  });

  // general functions

  const openConfirmation = useCallback((isClosingProcess: boolean, func: () => void) => {
    setIsConfirmationOpen(true);
    setIsOnClosingProcess(isClosingProcess);
    setConfirmationFunc({ func });
  }, []);

  const onClose = useCallback(() => {
    if (isDirty[0]) {
      openConfirmation(true, () => {
        closeModal();
        setDocsError(undefined);
      });
      return;
    }
    closeModal();
  }, [closeModal, isDirty, openConfirmation]);

  const disabled = !!registration?.id;
  const idDocs = documents.filter(({ documentType }) => ['BIRTH_CERTIFICATE', 'ID', 'VISA'].includes(documentType));
  const isUploadIdDisabled = [
    RegistrationStatus.lID_CHECKS,
    RegistrationStatus.tUPLOAD_TO_PAYROLL,
    RegistrationStatus.vREGISTERED,
    RegistrationStatus.xNOT_GOING_AHEAD
  ].includes(registration?.status!);

  return (
    <Modal open={isOpened} onClose={onClose} componentsProps={{ backdrop: { style: { background: info[200] } } }}>
      <>
        <Box
          bgcolor='#FFF'
          sx={{
            padding: '20px 48px 19px',
            borderBottom: `1px solid ${secondary[200]}`,
            '@media only screen and (max-width: 767px)': {
              paddingX: '16px',
              paddingTop: '16px',
              paddingBottom: '10px'
            }
          }}
        >
          <Grid container direction='row' justifyContent='space-between' alignItems='center' flexWrap='nowrap'>
            <Typography color='secondary.800' variant='h2'>
              {authState.user?.role === Role.CONTRACTOR
                ? formatMessage({ id: 'my_registration' })
                : (registration?.id && formatMessage({ id: 'registration_id' }) + ' ' + registration?.id) ||
                  formatMessage({ id: 'new_registration' })}
            </Typography>
            <IconButton
              onClick={onClose}
              sx={{ '& path': { stroke: secondary[700] }, '&:hover path': { stroke: primary[600] } }}
            >
              <CrossIcon />
            </IconButton>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: info[200],
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 90px - 90px)'
          }}
        >
          <Formik
            enableReinitialize
            validateOnChange={false}
            initialValues={{
              title: basicInfo?.title ?? registration?.title,
              firstName: basicInfo?.firstName ?? registration?.firstName,
              middleName: '',
              lastName: basicInfo?.lastName ?? registration?.lastName,
              phoneNumber: basicInfo?.phoneNumber ?? registration?.phoneNumber,
              email: basicInfo?.email ?? registration?.email,
              agencyId: currentAgency?.id,
              consultantId: registration?.consultantId || isContractor ? null : authState.user?.id,
              documentType: idDocs[0]?.documentType ?? ''
            }}
            onSubmit={async ({ documentType, ...values }, { setSubmitting }) => {
              try {
                const response = await save({
                  variables: {
                    data: {
                      ...values,
                      ...(id ? { id } : {})
                    }
                  }
                });
                if (response.data?.saveRegistration.id) {
                  setId(response.data.saveRegistration.id);
                }

                await onSave.execute(documentType, response.data?.saveRegistration.id as string);
                setSubmitting(false);
                setIsDirty([false, () => {}]);
                closeModal();
              } catch (e) {
                console.log(e);
              }
            }}
            initialTouched={{ documentType: true }}
          >
            {({ values, setFieldError, isSubmitting, submitForm, handleChange, errors }) => (
              <Form>
                <Box
                  display='flex'
                  sx={{
                    minHeight: 'calc(100vh - 88px - 84px)',
                    '@media only screen and (max-width: 910px)': {
                      flexDirection: 'column'
                    },
                    '@media only screen and (max-width: 767px)': {
                      minHeight: 'calc(100vh - 224px - 83px)'
                    }
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap='13px'
                    sx={{
                      padding: '34px 56px 34px 48px',
                      width: '400px',
                      borderRight: `1px solid ${secondary[200]}`,
                      '@media only screen and (max-width: 910px)': {
                        borderRight: 'none'
                      },
                      '@media only screen and (max-width: 496px)': {
                        px: '16px',
                        maxWidth: '100%',
                        boxSizing: 'border-box'
                      }
                    }}
                  >
                    <Typography variant='h3' mb='3px' color='secondary.800'>
                      {formatMessage({ id: 'basic_info' })}
                    </Typography>
                    <Field
                      labelId='title-label'
                      id='title'
                      name='title'
                      component={Select}
                      placeholder={formatMessage({ id: 'title' })}
                      options={titleOptions}
                      disabled={disabled}
                    />
                    <Field
                      component={Input}
                      name='firstName'
                      label={formatMessage({ id: 'first_name' })}
                      validate={Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3))}
                      helperText={errors.firstName ? formatMessage({ id: 'obligatory_field' }) : null}
                      disabled={disabled}
                    />
                    <Field
                      component={Input}
                      name='middleName'
                      label={formatMessage({ id: 'middle_name' })}
                      disabled={disabled}
                    />
                    <Field
                      component={Input}
                      name='lastName'
                      label={formatMessage({ id: 'surname' })}
                      validate={Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3))}
                      helperText={errors.lastName ? formatMessage({ id: 'obligatory_field' }) : null}
                      disabled={disabled}
                    />
                    <Field
                      component={Input}
                      name='phoneNumber'
                      label={formatMessage({ id: 'phone_number' })}
                      validate={Validator.pipe(Validator.methods.phone())}
                      disabled={disabled}
                    />
                    <Field
                      component={Input}
                      name='email'
                      label={formatMessage({ id: 'email' })}
                      validate={Validator.pipe(Validator.methods.email())}
                      disabled={disabled}
                    />
                    <Field
                      multiline
                      component={Input}
                      name='note'
                      label={formatMessage({ id: 'note.consultant.label' })}
                      sx={{
                        '& textarea': {
                          minHeight: '120px'
                        }
                      }}
                      disabled={disabled}
                    />
                  </Box>

                  <Box
                    sx={{
                      padding: '33px 71px',
                      '@media only screen and (max-width: 496px)': {
                        px: '16px',
                        maxWidth: '100%',
                        boxSizing: 'border-box'
                      }
                    }}
                  >
                    <Typography variant='h3' mb={4} color='secondary.800'>
                      {formatMessage({ id: 'upload_id' })}
                    </Typography>
                    <Typography variant='body2' fontWeight={400} color='secondary.800' mb={3}>
                      <Typography mb={3} width='520px' sx={{ maxWidth: '100%' }}>
                        {formatMessage({ id: 'documentsDescription' })}
                      </Typography>
                      <Typography>{formatMessage({ id: 'documentsPrompt' })}</Typography>
                    </Typography>
                    <Field
                      component={Select}
                      labelId='documentType-label'
                      name='documentType'
                      id='documentType'
                      className='regForm'
                      disabled={isUploadIdDisabled}
                      onChange={(e: any) => {
                        if (files.length && values.documentType && e.target.value !== values.documentType) {
                          e.preventDefault();
                          toast(
                            <ErrorSnackbar
                              title={formatMessage({ id: 'you_are_trying_to_change_document_type' })}
                              message={formatMessage({ id: 'to_select_another_document_type' })}
                            />
                          );
                        } else {
                          setFieldError('documentType', undefined);
                          handleChange(e);
                        }
                      }}
                      placeholder={formatMessage({ id: 'document_type' })}
                      options={documentsOptions}
                      sx={{
                        width: '400px',
                        '& > *': {
                          width: '100% !important'
                        },
                        maxWidth: '100%'
                      }}
                    />
                    <Typography variant='body2' fontWeight={400} color='secondary.800' mt={8} mb={6}>
                      <Typography mb={4}>{formatMessage({ id: 'file_upload_recomendations' })}</Typography>
                      <Box display='flex' flexDirection='column' gap={2}>
                        {[
                          formatMessage({ id: 'all_edges_and_corners_of_the_document' }),
                          formatMessage({ id: 'the_sharpness_of_the_image' }),
                          formatMessage({ id: 'avoid_objects_that_block_the_image' }),
                          formatMessage({ id: 'take_a_picture_in_good_light' })
                        ].map((prompt, index) => (
                          <Box key={index} display='flex' gap={3} sx={{ pb: '6px' }}>
                            <Box>
                              <TriangleListMarker />
                            </Box>
                            <Typography>{prompt}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Typography>
                    <CustomDropzone
                      loading={onSave.loading}
                      disabled={!values.documentType || isUploadIdDisabled}
                      documents={idDocs}
                      name='uploadId'
                      onChange={onDrop}
                      value={files}
                      singleUpload
                      contentDirection='row'
                      docTypes={[FileFormat.PDF, FileFormat.PNG, FileFormat.JPG_JPEG]}
                      error={docsError && formatMessage({ id: 'wrong_format' }, { formats: 'PDF, PNG, JPG' })}
                      title={docsError}
                      onDisableHelperFunc={() => {
                        setFieldError('documentType', ' ');
                        toast(
                          <AttentionSnackbar
                            title={formatMessage({ id: 'you_have_not_selected_a_document_type' })}
                            message={formatMessage({ id: 'you_are_trying_to_upload_a_file_without' })}
                          />
                        );
                      }}
                    />
                  </Box>
                  <FormikListener />
                </Box>

                <Box
                  display='flex'
                  justifyContent='space-between'
                  gap={3}
                  sx={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: secondary[100],
                    p: '20px 48px',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100vw',
                    '@media only screen and (max-width: 479px)': {
                      '& > button': {
                        width: '100%'
                      }
                    }
                  }}
                >
                  <Button variant='text' onClick={onClose}>
                    {formatMessage({ id: 'cancel' })}
                  </Button>
                  <Button onClick={submitForm} disabled={isSubmitting} variant='contained'>
                    {formatMessage({ id: 'save' })}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
        <UnsavedDataConfirmation
          isOpened={isConfirmationOpen}
          close={() => {
            setIsConfirmationOpen(false);
          }}
          continueFunc={confirmationFunc.func}
          isOnClosingProcess={isOnClosingProcess}
        />
      </>
    </Modal>
  );
};
