import { useState } from 'react';

interface Defaults {
  defaultPage?: number;
  rowsPerPage?: number;
}

export interface TablePagination {
  page: number;
  rowsPerPage: number;
  onPageChange: (_event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  offset: number;
  limit: number;
  getEmptyRows: (total: number) => number;
}

export const useTablePagination = (defaults: Defaults = {}): TablePagination => {
  const [page, setPage] = useState(defaults.defaultPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(defaults.rowsPerPage ?? 10);

  const onPageChange: TablePagination['onPageChange'] = (_, newPage) => {
    setPage(newPage);
  };
  const onRowsPerPageChange: TablePagination['onRowsPerPageChange'] = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  const getEmptyRows = (total: number): number => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total) : 0);

  return {
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    getEmptyRows
  };
};
