import { MenuItem, Select, type SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, type FC } from 'react';
import { useIntl } from 'react-intl';
import { ChevronIcon } from 'shared/components/icons';
import { type DateRange } from '../types';

enum RangeShortcut {
  Today = 'TODAY',
  ThisWeek = 'THIS_WEEK',
  ThisMonth = 'THIS_MONTH',
  Custom = 'CUSTOM'
}

interface Props {
  onChange: (dates: DateRange) => void;
  openCalendar: () => void;
  value: DateRange;
}

const getDateRange = (range: RangeShortcut): DateRange => {
  const today = dayjs();
  const endOfToday = today.endOf('day').toDate();

  switch (range) {
    case RangeShortcut.ThisWeek:
      return [today.startOf('week').toDate(), endOfToday];
    case RangeShortcut.ThisMonth:
      return [today.startOf('month').toDate(), endOfToday];
    case RangeShortcut.Today:
    default:
      return [today.startOf('day').toDate(), endOfToday];
  }
};

export const DateShortcuts: FC<Props> = ({ onChange, value, openCalendar }) => {
  const { formatMessage } = useIntl();
  const rangeShortcuts = [RangeShortcut.Today, RangeShortcut.ThisWeek, RangeShortcut.ThisMonth].map((range) => ({
    range,
    label: formatMessage({ id: range.toLowerCase() })
  }));

  const handleShortcutChange = useCallback(
    (event: SelectChangeEvent<RangeShortcut>) => {
      const rangeShortcut = event.target.value as RangeShortcut;
      if (rangeShortcut === RangeShortcut.Custom) {
        openCalendar();
      } else {
        const newRange = getDateRange(rangeShortcut);
        onChange(newRange);
      }
    },
    [onChange, openCalendar]
  );

  const currentShortcut =
    Object.values(RangeShortcut).find((shortcut) => {
      const range = getDateRange(shortcut);

      if (!range[0]) {
        return null;
      }

      const isStartSame = dayjs(range[0]).isSame(dayjs(value[0]), 'day');
      const isEndSame = dayjs(range[1]).isSame(dayjs(value[1]), 'day');

      return isStartSame && isEndSame;
    }) || RangeShortcut.Custom;

  return (
    <Select
      IconComponent={ChevronIcon}
      value={currentShortcut}
      placeholder={formatMessage({ id: 'tenant_filter' })}
      onChange={handleShortcutChange}
    >
      {rangeShortcuts.map(({ range, label }) => (
        <MenuItem key={range} value={range} sx={{ maxWidth: '240px' }}>
          {label}
        </MenuItem>
      ))}
      <MenuItem
        sx={{ maxWidth: '240px' }}
        value={RangeShortcut.Custom}
        onClick={(e) => {
          e.stopPropagation();
          openCalendar();
        }}
      >
        {formatMessage({ id: 'custom' })}
      </MenuItem>
    </Select>
  );
};
