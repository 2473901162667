import gql from 'graphql-tag';

export const agenciesQuery = gql`
  query Agencies($offset: Int!, $limit: Int!, $orderBy: AgencyOrder) {
    agencies(limit: $limit, offset: $offset, orderBy: $orderBy) {
      total
      data {
        id
        name
        createdAt
        updatedAt
        consultants {
          id
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const deleteDocumentsMutation = gql`
  mutation DeleteDocuments($ids: [String!]!) {
    deleteDocuments(ids: $ids)
  }
`;

export const setSubmittedMutation = gql`
  mutation SetSubmitted($id: String!) {
    setSubmitted(id: $id)
  }
`;

export const updateStatusMutation = gql`
  mutation UpdateStatus($id: String!, $status: String!, $htmlText: String) {
    updateStatus(id: $id, status: $status, htmlText: $htmlText)
  }
`;

export const availableFormTypesQuery = gql`
  query AvailableFormTypes {
    availableFormTypes
  }
`;
