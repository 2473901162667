import { createContext, useRef, type MutableRefObject } from 'react';
import { useNavigate, type NavigateFunction } from 'react-router-dom';

// This context resolves one issue:
// useNavigate hook is dependant on location
// So each page change force to render component that use it
// This could leads to waste re-renders
// For example useAuthentication hook that handles auth use navigation
// And as it is high-level component, the page change should not affect all components below

export const StableNavigateContext = createContext<MutableRefObject<
  NavigateFunction
> | null>(null);

export const StableNavigateContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  return (
    <StableNavigateContext.Provider value={navigateRef}>
      {children}
    </StableNavigateContext.Provider>
  );
};
