import { compose, replace } from 'ramda';

export const camelCaseToSnake = (str: string): string =>
  str
    .replace(/([A-Z])/g, '_$1')
    .toLowerCase()
    .slice(1);

export const camelCaseToHumanFriendly = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(/\s+/)
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
};

export const variablesToHumanFriendly = (str: string = ''): string => {
  const replacement = (_: string, placeholder: string): string =>
    `{${camelCaseToHumanFriendly(placeholder.slice(2, -2))}}`;
  return compose(replace(/({{.+?}})/g, replacement))(str);
};

export const humanFriendlyToVariables = (str: string = ''): string => {
  const fromHumanFriendlyToCamelCase = (placeholder: string): string =>
    placeholder
      .slice(1, -1)
      .split(/\s+/)
      .map((word, index) => `${!index ? word[0].toLowerCase() : word[0].toUpperCase()}${word.slice(1)}`)
      .join('');

  return compose(replace(/({.+?})/g, (_, placeholder) => `{{${fromHumanFriendlyToCamelCase(placeholder)}}}`))(str);
};

export const capitalizeFirstLetter = (str: string): string => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};
