import { useMutation } from '@apollo/client';
import {
  DocumentRelation,
  DocumentType,
  QuestionComponent,
  type FormQuestionAnswerInput
} from '__graphql__/globalTypes';
import { type FormikHelpers } from 'formik';
import { find, propEq } from 'ramda';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { createDocumentMutation, uploadDocumentMutation } from 'shared/api';
import { type CreateDocument, type CreateDocumentVariables } from 'shared/api/__graphql__/CreateDocument';
import { type UploadDocument, type UploadDocumentVariables } from 'shared/api/__graphql__/UploadDocument';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { useRoles } from 'shared/hooks';
import { type Registration_registration_questionAnswers } from 'shared/hooks/useRegistration/__graphql__/Registration';
import {
  type Sections_sections,
  type Sections_sections_children
} from 'shared/hooks/useRegistration/__graphql__/Sections';
import { type AttachedDocument } from 'shared/types';
import { isBadInputError, retrieveErrorCode } from 'shared/utils/errors';
import { ErrorSnackbar } from '../Snackbars';
import { type DeleteDocuments, type DeleteDocumentsVariables } from './__graphql__/DeleteDocuments';
import { type SetSubmitted, type SetSubmittedVariables } from './__graphql__/SetSubmitted';
import { deleteDocumentsMutation, setSubmittedMutation } from './api';
import { type DynamicFormikValues } from './types';

interface SubmitSectionProps {
  values: DynamicFormikValues;
  helpers: FormikHelpers<DynamicFormikValues>;
  submittedSection: Sections_sections_children | Sections_sections;
}

interface UseOnSubmitSection {
  submitSection: (_: SubmitSectionProps) => Promise<void>;
  submitApplication: () => Promise<void>;
}

export function useOnSubmitSection(): UseOnSubmitSection {
  const { id, registration, sendAnswer, closeModal, setIsDirty, answers, completenessOfRegistration } =
    useContext(RegistrationContext);
  const { isStaffUser } = useRoles();
  const { formatMessage } = useIntl();

  const [uploadDocument] = useMutation<UploadDocument, UploadDocumentVariables>(uploadDocumentMutation, {
    context: API_CONTEXT.DOCUMENT
  });
  const [createDocument] = useMutation<CreateDocument, CreateDocumentVariables>(createDocumentMutation, {
    context: API_CONTEXT.DOCUMENT
  });
  const [deleteDocuments] = useMutation<DeleteDocuments, DeleteDocumentsVariables>(deleteDocumentsMutation, {
    context: API_CONTEXT.DOCUMENT
  });
  const [setSubmitted] = useMutation<SetSubmitted, SetSubmittedVariables>(setSubmittedMutation, {
    refetchQueries: ['Registration', ...(isStaffUser ? ['RegistrationDetails'] : [])],
    awaitRefetchQueries: true
  });

  const submitSection: UseOnSubmitSection['submitSection'] = async ({
    values,
    helpers: { setSubmitting },
    submittedSection
  }) => {
    const fileUploadQuestionsIds = submittedSection.questions.reduce<string[]>((res, q) => {
      if (q.component === QuestionComponent.FILE_UPLOAD) {
        res.push(q.id);
      }
      return res;
    }, []);

    const newDocsIds = new Map();

    if (fileUploadQuestionsIds.length) {
      const { docsToRemove, docsToUpload } = fileUploadQuestionsIds.reduce<{
        docsToRemove: string[];
        docsToUpload: Array<AttachedDocument & { questionId: string }>;
      }>(
        (result, questionId) => {
          newDocsIds.set(questionId, []);
          const savedDocuments = answers[questionId] ?? [];
          const uploadedDocuments = (values[questionId] as Array<string | AttachedDocument>)?.filter(
            (d) => !(d as AttachedDocument).file
          ) as string[];
          const newDocuments = (values[questionId] as Array<string | AttachedDocument>)?.filter(
            (d) => !!(d as AttachedDocument).file
          ) as AttachedDocument[];
          const idsToRemove = savedDocuments.filter((doc) => !uploadedDocuments.includes(doc));
          newDocsIds.get(questionId).push(...savedDocuments.filter((doc) => uploadedDocuments.includes(doc)));

          if (idsToRemove.length) {
            result.docsToRemove.push(...idsToRemove);
          }

          if (newDocuments.length) {
            result.docsToUpload.push(...newDocuments.map((i) => ({ ...i, questionId })));
          }

          return result;
        },
        { docsToRemove: [], docsToUpload: [] }
      );

      if (docsToRemove.length) {
        await deleteDocuments({ variables: { ids: docsToRemove } });
      }

      if (docsToUpload.length) {
        await Promise.all(
          docsToUpload.map(async (doc) => {
            const response = await createDocument({
              variables: {
                data: {
                  contentType: doc.file.type,
                  documentType: DocumentType.DOCUMENT,
                  filename: doc.file.name,
                  relatedId: id!,
                  relatedTo: DocumentRelation.REGISTRATION
                }
              }
            });
            const docId = response.data!.createDocument.id;
            Object.defineProperty(doc.file, 'name', {
              writable: true,
              value: `${id}|${docId}`
            });
            await uploadDocument({ variables: { file: doc.file } });
            newDocsIds.get(doc.questionId).push(docId);
          })
        );
      }
    }

    await sendAnswer({
      refetchQueries: ['DocumentsModal', 'RegistrationAnswers'],
      awaitRefetchQueries: true,
      variables: {
        data: Object.entries(values).reduce<FormQuestionAnswerInput[]>((res, [questionId, answer]) => {
          const data = {
            id: (
              find(propEq('questionId', questionId))(
                registration?.questionAnswers ?? []
              ) as Registration_registration_questionAnswers
            )?.id,
            questionId,
            answer: Array.isArray(answer) ? answer : [answer.toString()],
            registrationId: id!
          };

          if (fileUploadQuestionsIds.includes(questionId)) {
            data.answer = newDocsIds.get(questionId);
          }

          return [...res, data];
        }, [])
      }
    });

    setSubmitting(false);
    setIsDirty([false, () => {}]);
  };

  const submitApplication: UseOnSubmitSection['submitApplication'] = async () => {
    if (Object.values(completenessOfRegistration).every((step) => step)) {
      try {
        await setSubmitted({
          variables: {
            id: id!
          },
          refetchQueries: ['Registration', 'Documents', ...(isStaffUser ? ['RegistrationDetails'] : [])],
          awaitRefetchQueries: true
        });
        closeModal();
      } catch (err) {
        const code = retrieveErrorCode(err);

        if (isBadInputError(code)) {
          toast(
            <ErrorSnackbar
              title={formatMessage({ id: 'registration_already_submitted' })}
              message={formatMessage({ id: 'registration_processing' })}
            />
          );
        }
      }
    } else {
      toast(
        <ErrorSnackbar
          title={formatMessage({ id: 'application_is_not_fully_filled' })}
          message={formatMessage({ id: 'please_check_all_steps' })}
        />
      );
    }
  };

  return { submitSection, submitApplication };
}
