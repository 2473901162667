import { useQuery } from '@apollo/client';
import { createContext } from 'react';
import { productsQuery } from 'shared/api';
import { type Products_products, type Products } from 'shared/api/__graphql__/Products';
import { API_CONTEXT } from 'shared/api/api-contexts';

export const ProductsContext = createContext<{
  products: Products_products[];
}>({
  products: []
});

export const ProductsContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { data: products } = useQuery<Products>(productsQuery, {
    context: API_CONTEXT.PAYMENT
  });

  return (
    <ProductsContext.Provider value={{ products: products?.products ?? [] }}>
      {children}
    </ProductsContext.Provider>
  );
};
