import { useMutation } from '@apollo/client';
import { type UpdateUserInput } from '__graphql__/globalTypes';
import { type Contractors_contractors_data } from 'admin/pages/Contractors/__graphql__/Contractors';
import { type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { updateUserMutation } from 'shared/api';
import { type UpdateUser, type UpdateUserVariables } from 'shared/api/__graphql__/UpdateUser';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { ConfirmationDialog } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { getDisplayRole } from 'shared/utils/users';

interface Props {
  isOpen: boolean;
  contractor: Pick<Contractors_contractors_data, 'firstName' | 'lastName' | 'email' | 'role' | 'isActive' | 'id'>;
  onClose: () => void;
}

export const ContractorActivationDialog: FC<Props> = ({ isOpen, contractor, onClose }) => {
  const { formatMessage } = useIntl();

  const { firstName, lastName, email, role, isActive, id } = contractor ?? {};

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(updateUserMutation, {
    refetchQueries: ['Contractor', 'Contractors'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const updateUserHandler = useAsyncCallback(async (data: UpdateUserInput) => {
    await updateUser({ variables: { data } });
  });

  const translation = isActive
    ? {
        successNotificationTitle: 'contractor_success_deactivate',
        successNotificationMessage: 'contractor_success_deactivate_message',
        confirmBtn: 'deactivate',
        title: 'user_deactivation',
        message: 'user_deactivation_confirm'
      }
    : {
        successNotificationTitle: 'contractor_success_activate',
        successNotificationMessage: 'contractor_success_activate_message',
        confirmBtn: 'activate',
        title: 'user_activation',
        message: 'user_activation_confirm'
      };

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={onClose}
      onConfirm={async () => {
        await updateUserHandler.execute({
          id,
          isActive: !isActive
        });
        onClose();
        toast(
          <SuccessSnackbar
            title={formatMessage({
              id: translation.successNotificationTitle
            })}
            message={formatMessage({
              id: translation.successNotificationMessage
            })}
          />
        );
      }}
      confirmButtonContent={formatMessage({
        id: translation.confirmBtn
      })}
      title={formatMessage({
        id: translation.title
      })}
      content={formatMessage(
        { id: translation.message },
        {
          role: getDisplayRole(role),
          firstName,
          lastName,
          email
        }
      )}
    />
  );
};
