import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const TriangleListMarker: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.845 15.0009C7.60948 14.9995 7.3769 14.9484 7.1625 14.8509C6.93062 14.7487 6.73306 14.5819 6.59342 14.3704C6.45377 14.159 6.37795 13.9118 6.375 13.6584V7.34338C6.37795 7.08999 6.45377 6.8428 6.59342 6.63134C6.73306 6.41988 6.93062 6.25309 7.1625 6.15088C7.42931 6.02485 7.72615 5.97631 8.0192 6.01079C8.31225 6.04527 8.58973 6.16137 8.82 6.34588L12.645 9.50338C12.795 9.62279 12.9161 9.77449 12.9994 9.94719C13.0826 10.1199 13.1259 10.3092 13.1259 10.5009C13.1259 10.6926 13.0826 10.8819 12.9994 11.0546C12.9161 11.2273 12.795 11.379 12.645 11.4984L8.82 14.6559C8.54429 14.8795 8.19998 15.0013 7.845 15.0009ZM7.845 7.50088V13.4259L11.49 10.5009L7.845 7.50088Z'
        fill={currentColor}
      />
    </svg>
  );
};
