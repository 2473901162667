import { useQuery } from '@apollo/client';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState, type FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { companyInfoQuery } from 'shared/api';
import { type CompanyInfo, type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { Loader, PageTitle, PageWrapper } from 'shared/components';
import { AttentionSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { PLAN_ACTIVATED_PARAM } from 'shared/constants';
import { CompanyContext, SubscriptionContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { CompanyInfoWidget } from './CompanyInfoWidget';
import { SystemSettingsTab } from './SystemSettingsTab';
import { ThemeSettingsTab } from './ThemeSettingsTab';

enum SettingsTab {
  Tenant = 'System',
  Theme = 'Theme'
}

export const MyCompany: FC = () => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const { loading: subscriptionLoading } = useContext(SubscriptionContext);
  const { paymentFailureCode } = useContext(CompanyContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<SettingsTab>(SettingsTab.Tenant);

  const { data, loading } = useQuery<CompanyInfo>(companyInfoQuery);

  const companyInfo = data?.companyInfo ?? ({} as CompanyInfo_companyInfo);

  useEffect(() => {
    if (searchParams.get(PLAN_ACTIVATED_PARAM) === 'true') {
      toast(
        <SuccessSnackbar
          title={formatMessage({ id: 'plan_activated' })}
          message={formatMessage({ id: 'now_can_use_system' })}
        />
      );
      setSearchParams();
    }
  }, [searchParams, formatMessage, setSearchParams]);

  return (
    <Box>
      <PageTitle>
        <>
          <ToggleButtonGroup
            sx={{
              color: secondary[800]
            }}
            value={activeTab}
            exclusive
            onChange={(e, v) => {
              if (paymentFailureCode) {
                toast(
                  <AttentionSnackbar
                    title={formatMessage({ id: 'feature_unavailable' })}
                    message={formatMessage({ id: `feature_unavailable_${paymentFailureCode.toLowerCase()}` })}
                  />
                );
              } else if (v) {
                setActiveTab(v);
              }
            }}
            orientation='horizontal'
            className='stacked'
          >
            {Object.values(SettingsTab).map((tab) => (
              <ToggleButton key={tab} value={tab}>
                {formatMessage({ id: `${tab.toLowerCase()}_settings` })}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <CompanyInfoWidget company={companyInfo} />
        </>
      </PageTitle>
      <PageWrapper sx={{ pb: '40px' }}>
        {loading || subscriptionLoading ? (
          <Loader />
        ) : activeTab === SettingsTab.Tenant ? (
          <SystemSettingsTab data={companyInfo} />
        ) : (
          <ThemeSettingsTab companyInfo={companyInfo} />
        )}
      </PageWrapper>
    </Box>
  );
};
