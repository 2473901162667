import { useMutation, useQuery } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { NoteOrderField, OrderDirection } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { deleteNoteMutation, notesQuery, saveNoteMutation } from 'shared/api';
import { type DeleteNote, type DeleteNoteVariables } from 'shared/api/__graphql__/DeleteNote';
import { type Notes as NotesQuery, type NotesVariables } from 'shared/api/__graphql__/Notes';
import { type SaveNote, type SaveNoteVariables } from 'shared/api/__graphql__/SaveNote';
import { EditIcon, FileTextIcon, TrashIcon } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import AuthContext from 'shared/contexts/authContext';
import { usePalette } from 'shared/hooks';
import { NoteInput } from './NoteInput';

interface Props {
  closeNewNoteInput: () => void;
  openNewNoteInput: () => void;
  isNewNoteVisible: boolean;
  emptyBoxIntlId: string;
  idData: { registrationId?: string; userId?: string };
}

export const Notes: FC<Props> = ({ emptyBoxIntlId, idData, isNewNoteVisible, closeNewNoteInput, openNewNoteInput }) => {
  const { authState } = useContext(AuthContext);
  const { primary, secondary } = usePalette();

  const { formatMessage } = useIntl();

  const [note, setNote] = useState('');
  const [editedNote, setEditedNote] = useState<string | null>(null);

  const onCloseNewNoteInput = useCallback((): void => {
    closeNewNoteInput();
    setNote('');
    setEditedNote(null);
  }, [closeNewNoteInput]);

  const onStartEditingNote = useCallback(
    (id: string, noteText: string) => {
      setNote(noteText);
      openNewNoteInput();
      setEditedNote(id);
    },
    [openNewNoteInput]
  );

  const onNewNoteChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  }, []);

  const { data } = useQuery<NotesQuery, NotesVariables>(notesQuery, {
    variables: {
      limit: 100,
      offset: 0,
      orderBy: {
        field: NoteOrderField.createdAt,
        direction: OrderDirection.desc
      },
      filter: idData
    }
  });

  const [saveNote] = useMutation<SaveNote, SaveNoteVariables>(saveNoteMutation, {
    refetchQueries: ['Notes', 'Audit'],
    awaitRefetchQueries: true
  });

  const [deleteNote] = useMutation<DeleteNote, DeleteNoteVariables>(deleteNoteMutation, {
    refetchQueries: ['Notes', 'Audit'],
    awaitRefetchQueries: true
  });

  const deleteNoteHandler = useCallback(
    async (id: string) => {
      await deleteNote({ variables: { id } });
      toast(<SuccessSnackbar title={formatMessage({ id: 'note_deleted' })} />);
    },
    [formatMessage, deleteNote]
  );

  const saveNoteHandler = useCallback(async () => {
    await saveNote({
      variables: {
        data: {
          noteText: note,
          ...idData,
          ...(editedNote ? { id: editedNote } : {})
        }
      }
    });
    onCloseNewNoteInput();
    toast(<SuccessSnackbar title={formatMessage({ id: editedNote ? 'note_updated' : 'note_added' })} />);
  }, [onCloseNewNoteInput, formatMessage, editedNote, idData, note, saveNote]);

  const dataNotes = data?.notes?.data ?? [];

  const notes = dataNotes.map(({ id, createdAt, noteText, owner }) =>
    id === editedNote ? (
      <NoteInput
        key={id}
        note={note}
        owner={owner}
        createdAt={createdAt}
        onNewNoteChangeHandler={onNewNoteChangeHandler}
        saveNoteHandler={saveNoteHandler}
        closeNewNoteInput={onCloseNewNoteInput}
      />
    ) : (
      <Box
        key={id}
        bgcolor='#FFFFFF'
        p={3}
        display='flex'
        flexDirection='column'
        gap={2}
        sx={{ border: `1px solid ${secondary[300]}`, borderRadius: '8px' }}
      >
        <Typography variant='body1' color='secondary.800'>
          {noteText}
        </Typography>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex' alignItems='center' gap={3}>
            <Typography variant='body2' color='secondary.700'>
              {owner?.lastName?.[0] ? `${owner?.firstName} ${owner?.lastName[0]}.` : owner?.firstName}
            </Typography>
            <Typography variant='body1' color={secondary[500]}>
              {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Box>
          {authState.user?.id === owner.id && (
            <Box display='flex' gap={3}>
              <IconButton
                onClick={() => {
                  onStartEditingNote(id, noteText);
                }}
                sx={{ p: 0, height: '24px' }}
              >
                <EditIcon size={24} color={secondary[700] }/>
              </IconButton>
              <IconButton onClick={() => deleteNoteHandler(id)} sx={{ p: 0, height: '24px' }}>
                <TrashIcon size={24} color={secondary[700]} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    )
  );

  const noNotes = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 'calc((100vh - 350px)/2)'
      }}
    >
      <FileTextIcon color={primary[600] }/>
      <Typography variant='body1' color='secondary.800'>
        {formatMessage({ id: emptyBoxIntlId })}
      </Typography>
    </Box>
  );

  return (
    <Box gap='12px' sx={{ '& *': { fontSize: '14px !important', lineHeight: '20px !important' } }}>
      {isNewNoteVisible && !editedNote && (
        <NoteInput
          note={note}
          onNewNoteChangeHandler={onNewNoteChangeHandler}
          saveNoteHandler={saveNoteHandler}
          closeNewNoteInput={onCloseNewNoteInput}
        />
      )}
      {notes.length ? notes : noNotes}
    </Box>
  );
};
