import { useMutation, useQuery } from '@apollo/client';
import { type ErrorResponse } from '@apollo/client/link/error';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Role, type InviteInput } from '__graphql__/globalTypes';
import { Field } from 'formik';
import React, { type FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { inviteMutation } from 'shared/api';
import { type Invite, type InviteVariables } from 'shared/api/__graphql__/Invite';
import { FormDialog, Input, Select } from 'shared/components';
import { CircleQuestionIcon, PlusIcon } from 'shared/components/icons';
import ErrorSnackbar from 'shared/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'shared/components/Snackbars/SuccessSnackbar';
import AuthContext from 'shared/contexts/authContext';
import { usePalette } from 'shared/hooks';
import { isConflictError } from 'shared/utils/errors';
import { Validator } from 'shared/utils/validator';
import { type AgenciesList, type AgenciesListVariables } from './__graphql__/AgenciesList';
import { type CreateAgency, type CreateAgencyVariables } from './__graphql__/CreateAgency';
import { agenciesList, createAgencyMutation } from './api';

export const AddUserForm: FC<any> = ({ values, setFieldValue, validateForm }) => {
  const intl = useIntl();
  const { primary, secondary, info } = usePalette();

  const { authState } = useContext(AuthContext);
  const [isAgenciesListOpen, setIsAgenciesListOpen] = useState(false);

  const agenciesResult = useQuery<AgenciesList, AgenciesListVariables>(agenciesList, {
    variables: {
      offset: 0,
      limit: 1000
    }
  });

  const agencies = agenciesResult.data?.agencies.data;

  const filteredAgencies = useMemo(
    () =>
      values.agency?.length
        ? agencies?.filter((agency) => agency.name.toLowerCase().includes(values.agency.toLowerCase()))
        : agencies ?? [],
    [agencies, values.agency]
  );
  const [createAgency] = useMutation<CreateAgency, CreateAgencyVariables>(createAgencyMutation, {
    refetchQueries: ['AgenciesList'],
    awaitRefetchQueries: true
  });

  const roles = useMemo(() => {
    switch (authState.user?.role) {
      case Role.ADMIN:
      case Role.SUPER_ADMIN:
        return [Role.ADMIN, Role.REGISTRAR, Role.CONSULTANT, Role.CUSTOMER_CARE];
      case Role.REGISTRAR:
        return [Role.CONSULTANT];
      case Role.CUSTOMER_CARE:
        return [Role.CONSULTANT];
      case Role.CONSULTANT:
        return [];
      default:
        return [];
    }
  }, [authState.user?.role]);

  const roleOptions = useMemo(
    () =>
      roles.map((e) => ({
        value: e,
        label: intl.formatMessage({ id: `user.role.${e}` })
      })),
    [intl, roles]
  );

  const createAgencyHandler = useCallback(
    async (newAgency: string) => {
      if (agencies?.find((a) => a.name.toLowerCase() === newAgency.toLowerCase())) {
        toast(
          <ErrorSnackbar
            title={intl.formatMessage({ id: 'the_agency_already_exists' })}
            message={intl.formatMessage({ id: 'you_can_choose_it_from' })}
          />,
          {
            position: toast.POSITION.BOTTOM_CENTER
          }
        );
        return;
      }

      const result = await createAgency({ variables: { data: { name: newAgency } } });

      setFieldValue('agencyId', result.data?.saveAgency.id);
      toast(
        <SuccessSnackbar
          title={intl.formatMessage({ id: 'new_agency_successfully_created' })}
          message={intl.formatMessage({ id: 'this_agency_is_added' })}
        />,
        {
          position: toast.POSITION.BOTTOM_CENTER
        }
      );
    },
    [agencies, createAgency, intl, setFieldValue]
  );

  useEffect(() => {
    if (values.agencyId && agencies) {
      setFieldValue('agency', agencies?.find((a) => a.id === values.agencyId)!.name);
      validateForm();
    }
  }, [agencies, setFieldValue, validateForm, values.agencyId]);

  return (
    <>
      <Field
        id='name'
        name='name'
        label={intl.formatMessage({ id: 'name' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required())}
        sx={{
          mt: '4px',
          marginBottom: '12px'
        }}
      />
      <Field
        id='email'
        name='email'
        label={intl.formatMessage({ id: 'email' })}
        type='email'
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.email())}
        sx={{
          marginBottom: '12px'
        }}
      />
      <Field
        id='role'
        name='role'
        label={intl.formatMessage({ id: 'role' })}
        component={Select}
        validate={Validator.pipe(Validator.methods.required())}
        options={roleOptions}
      />
      {values.role === Role.CONSULTANT ? (
        <Box>
          <Field
            id='agency'
            name='agency'
            onFocus={() => {
              setIsAgenciesListOpen(true);
            }}
            onBlur={() =>
              setTimeout(() => {
                setIsAgenciesListOpen(false);
              }, 100)
            }
            focused={values.agency}
            sx={{
              mt: '12px',
              width: '100%',
              '& > *': {
                width: '100% !important'
              }
            }}
            component={Input}
            label={intl.formatMessage({ id: 'agency' })}
            validate={async () => {
              if (values.agency && !agencies?.find((a) => a.name === values.agency)) {
                toast(
                  <ErrorSnackbar
                    title={intl.formatMessage({ id: 'new_agency_is_not_saved' })}
                    message={intl.formatMessage({ id: 'please_save_new_agency_first' })}
                  />,
                  {
                    position: toast.POSITION.BOTTOM_CENTER
                  }
                );
              }

              return await Validator.pipe(
                Validator.methods.required(),
                Validator.methods.failPipeline(values.agency && !agencies?.find((a) => a.name === values.agency))
              )(values.agency);
            }}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <IconButton
                    sx={{ right: '40px', position: 'absolute', p: '2px' }}
                    onClick={async () => {
                      await createAgencyHandler(values.agency);
                    }}
                  >
                    <PlusIcon />
                  </IconButton>
                  <Tooltip
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    enterTouchDelay={0}
                    placement='bottom-start'
                    title={
                      <Typography
                        variant='body1'
                        lineHeight='20px'
                        sx={{
                          margin: '-5px -9px',
                          background: '#FFF',
                          border: `1px solid ${primary[600]}`,
                          borderRadius: '8px',
                          maxWidth: '244px',
                          color: secondary[700],
                          p: '16px'
                        }}
                      >
                        {intl.formatMessage({ id: 'here_you_can_search' })}
                      </Typography>
                    }
                  >
                    <IconButton
                      sx={{
                        '&, &:hover': {
                          p: '2px',
                          background: info[200],
                          zIndex: 1,
                          position: 'absolute',
                          right: '12px'
                        }
                      }}
                    >
                      <CircleQuestionIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )
            }}
          />
          {filteredAgencies?.length && isAgenciesListOpen ? (
            <Box
              display='flex'
              flexDirection='column'
              sx={{
                width: '396px',
                maxHeight: '140px',
                overflowY: 'auto',
                border: `1px solid ${primary[600]}`,
                borderRadius: '8px',
                position: 'absolute',
                background: '#FFFFFF',
                py: '4px',
                mt: '-1px',
                zIndex: 5
              }}
            >
              {filteredAgencies.map((agency) => (
                <Button
                  key={agency.id}
                  variant='text'
                  sx={{
                    padding: '8px 16px',
                    fontWeight: 400,
                    color: secondary[700]
                  }}
                  onClick={() => {
                    setFieldValue('agency', agency.name);
                    setFieldValue('agencyId', agency.id);
                    setIsAgenciesListOpen(false);
                  }}
                >
                  {agency.name}
                </Button>
              ))}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

export const AddUserWidget: FC = () => {
  const intl = useIntl();

  const [isModalOpen, setModalOpen] = useState(false);

  const [inviteUser, { loading }] = useMutation<Invite, InviteVariables>(inviteMutation, {
    refetchQueries: ['Users'],
    awaitRefetchQueries: true,
    context: {
      uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`
    }
  });

  return (
    <>
      <Button
        type='button'
        size='medium'
        variant='contained'
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {intl.formatMessage({ id: 'add_new_user' })}
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        header={intl.formatMessage({ id: 'add_new_user' })}
        size='xs'
        loading={loading}
        confirmBtnName={intl.formatMessage({ id: 'save_and_send_email' })}
        onSubmit={async (
          { name, agency: _, ...values }: InviteInput & { name: string; agency?: string },
          formikHelpers
        ) => {
          const [firstName = '', lastName = ''] = name.split(' ');
          try {
            await inviteUser({ variables: { data: { ...values, firstName, lastName } } });
            toast(
              <SuccessSnackbar
                title={intl.formatMessage({ id: 'new_user_successfully_created' })}
                message={intl.formatMessage({ id: 'the_user_has_revceived_the_system_access' })}
              />
            );
            setModalOpen(false);
          } catch (e) {
            if (isConflictError((e as ErrorResponse).graphQLErrors?.[0].extensions.code as string)) {
              toast(
                <ErrorSnackbar
                  title={intl.formatMessage({ id: 'email_alredy_in_use' })}
                  message={intl.formatMessage({ id: 'you_cant_create_user_with_existing_email' })}
                />,
                {
                  position: toast.POSITION.BOTTOM_CENTER
                }
              );
              formikHelpers?.setFieldError('email', ' ');
            }
          }
        }}
      >
        <AddUserForm />
      </FormDialog>
    </>
  );
};
