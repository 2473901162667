import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme, type CSSObject, type Theme } from '@mui/material/styles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { CompanyContext } from 'shared/contexts';
import { usePalette, useRoles, useTenantRoute } from 'shared/hooks';
import { ChevronIcon } from '../icons';
import { Loader } from '../Loader';
import { AdminDrawerList } from './AdminDrawerList';
import { ChooseTenantTool } from './ChooseTenantTool';
import { Header } from './Header';
import { SuperAdminDrawerList } from './SuperAdminDrawerList';

const drawerWidth = 232;
const closedDrawerWidth = 88;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth
});

const buttonOpenedMixin = (theme: Theme): CSSObject => ({
  left: `calc(${drawerWidth - 20}px)`,
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const buttonClosedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  left: `calc(${closedDrawerWidth - 20}px)`,
  transform: 'rotateY(180deg)'
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export function Navigation({
  view,
  banners,
  hideMarker
}: {
  view: 'admin' | 'superadmin';
  banners?: JSX.Element[];
  hideMarker?: boolean;
}): JSX.Element {
  const intl = useIntl();
  const { info, secondary } = usePalette();
  const theme = useTheme();
  const { isSuperAdmin } = useRoles();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const { id } = useParams();
  const { prefix, mainRoute } = useTenantRoute();
  const { logo, loading } = useContext(CompanyContext);

  const isPanelHidden = useMemo(() => !!id, [id]);
  const isAdminView = useMemo(() => view === 'admin' || !!prefix, [view, prefix]);

  const toggleDrawer = useCallback(() => {
    setOpen((prev) => {
      localStorage.setItem('navigation', String(!prev));
      return !prev;
    });
  }, []);

  useEffect(() => {
    const navigationState = localStorage.getItem('navigation');
    setOpen(navigationState === 'true');
  }, [location]);

  return (
    <Box display='flex'>
      {!isPanelHidden && (
        <Drawer variant='permanent' open={open} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              py: '20px',
              px: '16px',
              marginBottom: '8px'
            }}
          >
            {loading ? (
              <Loader sx={{ m: '0 auto', width: '40px' }} />
            ) : (
              <img
                src={logo?.url ? logo?.url : '/swift-onboard-logo.svg'}
                alt='Logo'
                style={{
                  maxWidth: '170px',
                  maxHeight: '40px',
                  height: '40px',
                  marginLeft: open ? '16px' : '6px'
                }}
              />
            )}
            <Box
              sx={{
                position: 'absolute',
                width: '172px',
                height: '80px',
                background: open ? 'transparent' : 'linear-gradient(to left, #fff 70%, rgba(255,255,255,0) 100%)'
              }}
            />
          </Box>
          {isSuperAdmin && <ChooseTenantTool open={open} setOpen={setOpen} />}
          {isAdminView ? <AdminDrawerList open={open} hideMarker={hideMarker} /> : <SuperAdminDrawerList open={open} />}
        </Drawer>
      )}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100vh', bgcolor: info[200] }}>
        {isPanelHidden ? null : (
          <>
            {banners}
            <Header
              title={intl.formatMessage({ id: `${isAdminView ? 'admin' : 'superadmin'}.page_title.${mainRoute}` })}
              withSearch={isAdminView}
            />
          </>
        )}
        <Box component='main' sx={{ overflowY: 'auto' }}>
          {!isPanelHidden && (
            <IconButton
              onClick={toggleDrawer}
              sx={{
                ...(open && {
                  ...buttonOpenedMixin(theme)
                }),
                ...(!open && {
                  ...buttonClosedMixin(theme)
                }),
                width: '40px',
                height: '40px',
                border: `1px solid ${secondary[300]}`,
                borderRadius: '4px',
                position: 'absolute',
                bottom: '96px',
                background: '#FFF',
                zIndex: 2,
                '&:hover': {
                  background: '#FFF'
                }
              }}
            >
              <ChevronIcon direction='left' />
            </IconButton>
          )}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
