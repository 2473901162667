import { Box, IconButton, Input, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { type Notes_notes_data_owner } from 'shared/api/__graphql__/Notes';
import { EditIcon, TrashIcon } from 'shared/components/icons';
import { ProfileInfoContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';

interface NoteInputProps {
  owner?: Notes_notes_data_owner | null;
  createdAt?: Date | null;
  note?: string;
  onNewNoteChangeHandler: (arg: any) => void;
  saveNoteHandler: () => void;
  closeNewNoteInput: () => void;
}

export const NoteInput: FC<NoteInputProps> = ({
  note,
  owner,
  createdAt,
  onNewNoteChangeHandler,
  saveNoteHandler,
  closeNewNoteInput
}) => {
  const intl = useIntl();
  const { primary, secondary, info } = usePalette();

  const { user } = useContext(ProfileInfoContext);

  const userToShow = owner ?? user;

  return (
    <Box
      bgcolor='#FFFFFF'
      p={3}
      display='flex'
      flexDirection='column'
      gap='8px'
      sx={{ border: `1px solid ${primary[600]}`, borderRadius: '8px' }}
    >
      <Input
        value={note}
        onChange={onNewNoteChangeHandler}
        sx={{
          '&:before, &:hover:before, &:after': {
            border: '0 !important'
          },
          p: 0,
          color: secondary[800]
        }}
        multiline
        minRows={1}
        placeholder={intl.formatMessage({ id: 'start_typing_your_note' })}
      />
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' alignItems='center' gap={3}>
          <Typography variant='body2' color={secondary[500]}>
            {userToShow?.lastName?.[0] ? `${userToShow?.firstName} ${userToShow?.lastName[0]}.` : userToShow?.firstName}
          </Typography>
          <Typography variant='body1' color={secondary[500]}>
            {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </Box>
        <Box display='flex' gap={3} alignItems='center' sx={{ '& > button': { p: 0, height: '24px' } }}>
          <IconButton disabled={!note?.length} onClick={saveNoteHandler}>
            <EditIcon color={note?.length ? secondary[700] : info[500]} />
          </IconButton>
          <IconButton onClick={closeNewNoteInput}>
            <TrashIcon color={secondary[700]} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
