import gql from 'graphql-tag';

export const tenantsQuery = gql`
  query Tenants($offset: Int!, $limit: Int!, $orderBy: TenantOrder, $filter: TenantFilter, $query: String) {
    tenants(limit: $limit, offset: $offset, orderBy: $orderBy, filter: $filter, query: $query) {
      total
      data {
        id
        adminEmail
        adminName
        billingContactName
        billingContactEmail
        name
        status
        setupFinished
        newId
        createdAt
        updatedAt
        favourites {
          userId
        }
      }
    }
  }
`;

export const setTenantToFavouriteMutation = gql`
  mutation SetTenantToFavourite($id: String!) {
    setTenantToFavourite(id: $id) {
      id
    }
  }
`;

export const removeTenantFromFavouriteMutation = gql`
  mutation RemoveTenantFromFavourite($id: String!) {
    removeTenantFromFavourite(id: $id) {
      id
    }
  }
`;

export const createTenantMutation = gql`
  mutation CreateTenant($data: TenantCreate!) {
    createTenant(data: $data)
  }
`;

export const updateTenantMutation = gql`
  mutation UpdateTenant($data: TenantUpdate!) {
    updateTenant(data: $data) {
      id
    }
  }
`;

export const activateTenantMutation = gql`
  mutation ActivateTenant($id: String!) {
    activateTenant(id: $id) {
      id
    }
  }
`;

export const deactivateTenantMutation = gql`
  mutation DeactivateTenant($id: String!) {
    deactivateTenant(id: $id) {
      id
    }
  }
`;

export const deleteTenantMutation = gql`
  mutation DeleteTenant($id: String!) {
    deleteTenant(id: $id)
  }
`;
