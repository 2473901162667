import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { OrderDirection, RegistrationOrderField } from '__graphql__/globalTypes';
import { RegistrationsTable } from 'admin/components';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useCallback, useEffect, useState, type FC } from 'react';
import { useNavigate } from 'react-router';
import { Loader, PageTitle, PageWrapper } from 'shared/components';
import routes from 'shared/constants/routes';
import { useFiltersQuery, useRoles, useTablePagination, useTableSort } from 'shared/hooks';
import { reportQuery } from './api';
import Calendars, { type DateRange } from './Calendars';
import { Statistic } from './Statistic';

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1
});

export const defaultReportDates = [dayjs().startOf('week').hour(12).toDate(), new Date()] as DateRange<Date>;

export const Reports: FC = () => {
  const navigate = useNavigate();
  const { isCustomerCare } = useRoles();

  const [calendarDates, setCalendarDates] = useState<DateRange<Date>>(defaultReportDates);
  const [reportDates, setReportDates] = useState<DateRange<Date>>(defaultReportDates);
  const { parsedQuery, pushQuery } = useFiltersQuery();
  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { field, direction, createSortHandler, setSort } = useTableSort<RegistrationOrderField>({
    defaultField: RegistrationOrderField.status,
    defaultDirection: OrderDirection.asc
  });

  const report = useQuery(reportQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
              field,
              direction
            }
          : null,
      offset,
      limit,
      filter: {
        startDate: reportDates[0] ?? defaultReportDates[0],
        endDate: reportDates[1] ?? defaultReportDates[1]
      }
    }
  });

  useEffect(() => {
    pushQuery({
      filters: [
        ...(reportDates[0]
          ? [
              {
                id: 'startDate',
                value: reportDates[0].toDateString()
              }
            ]
          : []),
        ...(reportDates[1]
          ? [
              {
                id: 'endDate',
                value: reportDates[1].toDateString()
              }
            ]
          : [])
        // { id: 'owner', value: owner }
      ],
      sort: [
        ...(field
          ? [
              {
                id: field,
                desc: direction === OrderDirection.desc
              }
            ]
          : [])
      ],
      pageIndex: tablePaginationProps.page
    });
  }, [direction, field, pushQuery, reportDates, tablePaginationProps.page]);

  useEffect(() => {
    if (isCustomerCare) navigate(routes.rootForNavigation);

    setSort(
      (parsedQuery?.sortBy ?? RegistrationOrderField.status) as RegistrationOrderField,
      (parsedQuery?.sortOrder ?? OrderDirection.asc) as OrderDirection
    );

    const dates = [
      parsedQuery?.startDate ? dayjs(parsedQuery?.startDate as string).toDate() : defaultReportDates[0],
      parsedQuery?.endDate ? dayjs(parsedQuery?.endDate as string).toDate() : defaultReportDates[1]
    ];
    // @ts-expect-error: type conflict
    setReportDates(dates);
    // @ts-expect-error: type conflict
    setCalendarDates(dates);
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValues = useCallback((dates: DateRange<Date>) => {
    setCalendarDates(dates);
  }, []);

  const refetchReport = useCallback((dates: DateRange<Date>) => {
    setReportDates(dates);
  }, []);

  const clearReportDates = useCallback(() => {
    setReportDates(defaultReportDates);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle>
          <Calendars
            refetchReport={refetchReport}
            clearReportDates={clearReportDates}
            value={calendarDates}
            setValue={setValues}
          />
        </PageTitle>
        <PageWrapper>
          {report.loading ? (
            <Loader />
          ) : (
            <>
              <Grid container sx={{ marginBottom: '16px' }} spacing={6}>
                <Grid item xs={3}>
                  <Statistic nameIntl='new_registrations' value={report?.data?.report?.newCount} />
                </Grid>
                <Grid item xs={3}>
                  <Statistic nameIntl='payments_received' value={report?.data?.report?.paymentsReceivedCount} />
                </Grid>
                <Grid item xs={3}>
                  <Statistic nameIntl='completed_registrations' value={report?.data?.report?.completedCount} />
                </Grid>
                <Grid item xs={3}>
                  <Statistic nameIntl='did_not_proceed' value={report?.data?.report?.notProceedCount} />
                </Grid>
              </Grid>
              <RegistrationsTable
                urlPrefix='../'
                data={report.data?.report.data}
                total={report.data?.report.total}
                field={field}
                direction={direction}
                createSortHandler={createSortHandler}
                tablePaginationProps={tablePaginationProps}
              />
            </>
          )}
        </PageWrapper>
      </Box>
    </Box>
  );
};
