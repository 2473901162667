import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { TenantWithAnalyticsOrderFields } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { EmptyTableBox } from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { type UseTableSort } from 'shared/hooks';
import { getTruncatedNumber } from 'shared/utils/formatters';
import { type AnalyticsOfTenants_analytics_items } from './__graphql__/AnalyticsOfTenants';

interface Props {
  data: AnalyticsOfTenants_analytics_items[];
  page: number;
  rowsPerPage: number;
  sort: UseTableSort<TenantWithAnalyticsOrderFields>;
}

export const TenantsTable: FC<Props> = (props) => {
  const {
    rowsPerPage,
    data,
    page,
    sort: { createSortHandler, field, direction }
  } = props;
  const { formatMessage } = useIntl();

  const isTableEmpty = !data?.length && page === 0;

  return (
    <TableContainer>
      <Table
        sx={{
          '& tr': {
            height: 'auto',
            display: 'grid'
          },
          '& > thead tr': {
            gridTemplateColumns: 'repeat(6, 2fr) 1fr'
          },
          '& > tbody tr': {
            gridTemplateColumns: isTableEmpty ? 'auto' : 'repeat(6, 2fr) 1fr'
          }
        }}
      >
        <TableHead
          sx={{
            '& th': {
              padding: '12px 19px 11px 24px'
            }
          }}
        >
          <TableRow>
            <TableCell>
              <TableSortLabel onClick={createSortHandler(TenantWithAnalyticsOrderFields.Name)} hideSortIcon={true}>
                {formatMessage({ id: 'tenant_name' })}
                <SortIcon active={field === TenantWithAnalyticsOrderFields.Name} direction={direction} />
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={createSortHandler(TenantWithAnalyticsOrderFields.Registrations)}
                hideSortIcon={true}
              >
                {formatMessage({ id: 'total_registrations' })}
                <SortIcon active={field === TenantWithAnalyticsOrderFields.Registrations} direction={direction} />
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel onClick={createSortHandler(TenantWithAnalyticsOrderFields.Revenue)} hideSortIcon={true}>
                {formatMessage({ id: 'total_revenue' })}
                <SortIcon active={field === TenantWithAnalyticsOrderFields.Revenue} direction={direction} />
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel onClick={createSortHandler(TenantWithAnalyticsOrderFields.Users)} hideSortIcon={true}>
                {formatMessage({ id: 'users' })}
                <SortIcon active={field === TenantWithAnalyticsOrderFields.Users} direction={direction} />
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={createSortHandler(TenantWithAnalyticsOrderFields.LastActivity)}
                hideSortIcon={true}
              >
                {formatMessage({ id: 'last_activity' })}
                <SortIcon active={field === TenantWithAnalyticsOrderFields.LastActivity} direction={direction} />
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel onClick={createSortHandler(TenantWithAnalyticsOrderFields.Plan)} hideSortIcon={true}>
                {formatMessage({ id: 'plan' })}
                <SortIcon active={field === TenantWithAnalyticsOrderFields.Plan} direction={direction} />
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ minWidth: '110px' }}>
              <TableSortLabel
                onClick={createSortHandler(TenantWithAnalyticsOrderFields.UsedRegistrations)}
                hideSortIcon={true}
              >
                {formatMessage({ id: 'used_registrations' })}
                <SortIcon active={field === TenantWithAnalyticsOrderFields.UsedRegistrations} direction={direction} />
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& td': {
              lineHeight: '28px',
              padding: '20px 24px 16px',
              '&, & span': {
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                textOverflow: 'ellipsis'
              }
            }
          }}
        >
          {data?.map((tenant) => {
            return (
              <TableRow key={tenant.name}>
                <TableCell>{tenant.name}</TableCell>
                <TableCell>{tenant.registrations}</TableCell>
                <TableCell>{getTruncatedNumber(tenant.revenue)}</TableCell>
                <TableCell>{tenant.users}</TableCell>
                <TableCell>
                  {tenant.lastActivityDate ? dayjs(tenant.lastActivityDate).format('DD/MM/YYYY') : ''}
                </TableCell>
                <TableCell>{tenant.plan}</TableCell>
                <TableCell sx={{ minWidth: '110px' }}>{`${tenant.usedItems}/${tenant.totalItems}`}</TableCell>
              </TableRow>
            );
          })}
          {isTableEmpty && (
            <TableRow
              style={{
                height: 64 * rowsPerPage,
                background: 'inherit'
              }}
            >
              <TableCell
                colSpan={5}
                style={{
                  height: 64 * rowsPerPage,
                  padding: 0
                }}
              >
                <EmptyTableBox />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
