import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormDialog, Loader } from 'shared/components';
import { MailIcon } from 'shared/components/icons';
import { usePalette } from 'shared/hooks';
import { type ContractorEmails_userEmails } from '../__graphql__/ContractorEmails';
import { ContractorEmailForm } from './ContractorEmailWidget';

interface IProps {
  data?: ContractorEmails_userEmails[];
  loading: boolean;
}

export const ContractorEmailsList = ({ data, loading }: IProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { primary, secondary } = usePalette();

  const [emaileToView, setEmailToView] = useState<ContractorEmails_userEmails | undefined>();
  if (loading) {
    return <Loader />;
  }
  if (!data?.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <MailIcon color={primary[600]} />
        <Typography variant='body1' color='secondary.800'>
          {formatMessage({ id: 'no_emails_create_new' })}
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          width: '100%',
          height: '100%'
        }}
      >
        {data?.map((email) => (
          <Box
            key={email.id}
            sx={{
              height: '75px',
              boxSizing: 'border-box',
              padding: '12px 24px',
              borderBottom: `1px solid ${secondary[100]}`,
              cursor: 'pointer'
            }}
            onClick={() => {
              setEmailToView(email);
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  variant='body2'
                  color='secondary.800'
                >
                  {email.subject}
                </Typography>
                <Typography color={secondary[500]}>{dayjs(email.createdAt).format('DD/MM/YYYY, HH:mm')}</Typography>
              </Box>
              <Typography
                variant='caption'
                color={secondary[600]}
                sx={{
                  maxWidth: '500px',
                  whiteSpace: 'nowrap',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {email.content?.replace(/(<([^>]+)>)/gi, ' ').replaceAll('&nbsp;', ' ')}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <FormDialog
        isOpen={!!emaileToView}
        onClose={() => {
          setEmailToView(undefined);
        }}
        header={formatMessage({ id: 'email' })}
        size='sm'
        initialValues={emaileToView}
        onSubmit={() => {}}
        noButtons
      >
        <ContractorEmailForm disabled />
      </FormDialog>
    </>
  );
};
