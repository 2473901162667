import { Box } from '@mui/material';
import { OrderDirection } from '__graphql__/globalTypes';
import { FC } from 'react';
import { usePalette } from 'shared/hooks';

interface Props {
  active?: boolean;
  direction?: OrderDirection;
}

export const SortIcon: FC<Props> = (props) => {
  const { active, direction } = props;
  const { primary, secondary } = usePalette();
  const activeColor = primary[600];
  const inactiveColor = secondary[500];
  const ascArrowColor = active && direction === OrderDirection.asc ? activeColor : inactiveColor;
  const descArrowColor = active && direction === OrderDirection.desc ? activeColor : inactiveColor;

  return (
    <Box mt={1}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11 17L7 21M7 21L3 17M7 21V9'
          stroke={ascArrowColor}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21 7L17 3M17 3L13 7M17 3V15'
          stroke={descArrowColor}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};
