import gql from 'graphql-tag';

export const removeDiscountMutation = gql`
  mutation RemoveDiscount {
    removeDiscount
  }
`;

export const setDiscountMutation = gql`
  mutation SetDiscount($data: ApplyDiscount!) {
    applyDiscount(data: $data)
  }
`;
