import gql from 'graphql-tag';

export const getDownloadUrlQuery = gql`
  query GetDownloadUrl($id: String!) {
    getDowloadUrl(id: $id)
  }
`;

export const removeDocumentMutation = gql`
  mutation RemoveDocument($id: String!) {
    updateDocument(data: { id: $id, status: DELETED }) {
      id
    }
  }
`;

export const inviteMutation = gql`
  mutation Invite($data: InviteInput!) {
    invite(data: $data) {
      id
    }
  }
`;

export const inviteResendMutation = gql`
  mutation InviteResend($id: String!) {
    inviteResend(userId: $id)
  }
`;

export const deleteUserMutation = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const updateUserMutation = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
    }
  }
`;

export const updateSuperAdminMutation = gql`
  mutation UpdateSuperAdmin($data: UpdateSuperAdminInput!) {
    updateSuperAdmin(data: $data) {
      id
    }
  }
`;

export const uploadDocumentMutation = gql`
  mutation UploadDocument($file: Upload!) {
    uploadDocument(file: $file)
  }
`;

export const createDocumentMutation = gql`
  mutation CreateDocument($data: DocumentCreate!) {
    createDocument(data: $data) {
      id
    }
  }
`;

export const auditQuery = gql`
  query Audit($limit: Int!, $offset: Int!, $orderBy: AuditOrder, $filter: AuditFilter) {
    audit(limit: $limit, offset: $offset, orderBy: $orderBy, filter: $filter) {
      data {
        id
        createdAt
        action
        data
        addedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const notesQuery = gql`
  query Notes($limit: Int!, $offset: Int!, $orderBy: NoteOrder, $filter: NoteFilter) {
    notes(limit: $limit, offset: $offset, orderBy: $orderBy, filter: $filter) {
      data {
        createdAt
        id
        noteText
        owner {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const saveNoteMutation = gql`
  mutation SaveNote($data: NoteSave!) {
    saveNote(data: $data) {
      id
    }
  }
`;

export const deleteNoteMutation = gql`
  mutation DeleteNote($id: String!) {
    deleteNote(id: $id) {
      id
    }
  }
`;

export const addNewUserMutation = gql`
  mutation AddNewUser($data: AddUserInput!) {
    addUser(data: $data) {
      id
    }
  }
`;

export const requestEmailChangeMutation = gql`
  mutation RequestEmailChange($newEmail: String!, $userId: String) {
    requestEmailChange(newEmail: $newEmail, userId: $userId)
  }
`;

export const usersWithNameQuery = gql`
  query UsersWithName($offset: Int!, $limit: Int!, $filter: UserFilter) {
    users(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        firstName
        lastName
      }
    }
  }
`;

export const uploadRegistrationsMutation = gql`
  mutation UploadRegistrations($data: [RegistrationUpload!]!) {
    uploadRegistrations(data: $data)
  }
`;

export const companyInfoQuery = gql`
  query CompanyInfo {
    companyInfo {
      name
      id
      adminName
      adminEmail
      billingContactName
      billingContactEmail
      status
      updatedAt
      autoSuspension
      trialStartDate
      trialEndDate
      retentionPeriodType
      retentionPeriodValue
      footerLinks {
        name
        link
      }
      emailLink
      linkedInLink
      facebookLink
      twitterLink
      faqLink
      referralLinkToken
    }
  }
`;

export const updateCompanyInfoMutation = gql`
  mutation UpdateCompanyInfo($data: UpdateCompanyInfoData!) {
    updateCompanyInfo(data: $data)
  }
`;

export const companyIdPollingQuery = gql`
  query CompanyIdPolling {
    companyInfo {
      id
    }
  }
`;

export const productsQuery = gql`
  query Products {
    products {
      id
      description
      name
      image
      prices {
        id
        amount
        startsFrom
        currency
        default
      }
    }
  }
`;

export const getCheckoutUrlQuery = gql`
  query GetCheckoutUrl($data: Checkout!) {
    getCheckoutUrl(data: $data)
  }
`;

export const startTrialMutation = gql`
  mutation StartTrial {
    startTrial
  }
`;

export const companySubscriptionsQuery = gql`
  query CompanySubscriptions {
    subscriptions {
      id
      productId
      current_period_start
      current_period_end
      status
      cancelAtPeriodEnd
      changedAt
      changedFrom
      paymentMethod {
        expirationMonth
        expirationYear
        cardLast4Digits
      }
    }
  }
`;

export const invoicesQuery = gql`
  query Invoices {
    invoices {
      id
      amountPaid
      created
      status
      card
      productId
      url
    }
  }
`;

export const contractorQuery = gql`
  query Contractor($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      lastLogin
      status
      isActive
      title
      phoneNumber
      email
      role
      assignedTo
      contractorManager {
        id
        firstName
        lastName
      }
      contractorRegistrations {
        id
      }
    }
  }
`;

export const companyAccessQuery = gql`
  query CompanyAccess {
    companyInfo {
      id
      autoSuspension
      trialStartDate
      trialEndDate
    }
  }
`;

export const updatePlanMutation = gql`
  mutation UpdatePlan($data: ChangePlan!) {
    changePlan(data: $data)
  }
`;

export const cancelSubscriptionMutation = gql`
  mutation CancelSubscription {
    cancelSubscription
  }
`;

export const restoreSubscriptionMutation = gql`
  mutation RestoreSubscription {
    restoreSubscription
  }
`;

export const updatePaymentInfoLinkQuery = gql`
  query UpdatePaymentInfoLink($returnUrl: String!) {
    updatePaymentInStripeLink(returnUrl: $returnUrl)
  }
`;

export const discountQuery = gql`
  query Discount {
    discount {
      startDate
      endDate
      valueInPercent
    }
  }
`;
