import { useQuery } from '@apollo/client';
import { Box, Button, ClickAwayListener, Grid, Typography } from '@mui/material';
import { DocumentRelation, DocumentType, type RegistrationStatus } from '__graphql__/globalTypes';
import { type Documents_documents_data } from 'admin/pages/RegistrationDetails/__graphql__/Documents';
import { useCallback, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { getDownloadUrlQuery } from 'shared/api';
import { type GetDownloadUrl, type GetDownloadUrlVariables } from 'shared/api/__graphql__/GetDownloadUrl';
import apolloClient from 'shared/apolloClient';
import {
  ChevronIcon,
  DocumentsIcon,
  FileLockedIcon,
  FileWithPenIcon,
  FolderDownloadIcon
} from 'shared/components/icons';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import { usePalette } from 'shared/hooks';
import { getStatusColor } from 'shared/utils/colors';
import { createLocalFile } from 'shared/utils/file';
import {
  type ContractorDocuments,
  type ContractorDocumentsVariables,
  type ContractorDocuments_documents_data
} from './__graphql__/ContractorDocuments';
import { contractorDocumentsQuery } from './api';

export interface RegistrationContainerProps {
  contractType: string | null;
  date: string;
  status: RegistrationStatus;
  id: string;
  openModal: () => void;
}

export const RegistrationContainer: React.FC<RegistrationContainerProps> = ({
  contractType,
  date,
  status,
  id,
  openModal
}) => {
  const intl = useIntl();
  const { primary, secondary, info } = usePalette();

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const documentsResult = useQuery<ContractorDocuments, ContractorDocumentsVariables>(contractorDocumentsQuery, {
    variables: {
      limit: 100,
      offset: 0,
      filter: {
        relatedId: id,
        relatedTo: DocumentRelation.REGISTRATION
      }
    },
    skip: !id,
    context: {
      uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
    }
  });

  const [contract, assignmentSchedule, registrationForm] = useMemo(
    () => [
      documentsResult.data?.documents?.data?.find(
        (d: Documents_documents_data) => d.documentType === DocumentType.CONTRACT
      ),
      documentsResult.data?.documents?.data?.find(
        (d: Documents_documents_data) => d.documentType === DocumentType.ASSIGNMENT_SCHEDULE
      ),
      documentsResult.data?.documents?.data?.find(
        (d: Documents_documents_data) => d.documentType === DocumentType.REGISTRATION_FORM
      )
    ],
    [documentsResult.data?.documents]
  );

  const isDownloadAvailable = useMemo(
    () => contract || assignmentSchedule || registrationForm,
    [contract, assignmentSchedule, registrationForm]
  );

  const dowloadFile = useAsyncCallback(
    async (
      document: DocumentType.CONTRACT | DocumentType.ASSIGNMENT_SCHEDULE | DocumentType.REGISTRATION_FORM | 'all'
    ) => {
      const downloadDoc = async (doc: ContractorDocuments_documents_data): Promise<void> => {
        const urlResponse = await apolloClient.query<GetDownloadUrl, GetDownloadUrlVariables>({
          query: getDownloadUrlQuery,
          variables: { id: doc.id },
          context: {
            uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
          }
        });
        const url = urlResponse.data?.getDowloadUrl;
        if (url) {
          const buffer = await fetch(url);
          createLocalFile(await buffer.blob(), doc.filename);
        }
      };

      const documents = {
        [DocumentType.CONTRACT]: {
          value: contract,
          downloadFunc: async () => {
            await downloadDoc(contract!);
          },
          error: intl.formatMessage({ id: 'download_contract_error' }),
          message: intl.formatMessage({ id: 'download_contract_error_message' })
        },
        [DocumentType.ASSIGNMENT_SCHEDULE]: {
          value: assignmentSchedule,
          error: intl.formatMessage({ id: 'download_assaignment_schedule_error' }),
          message: intl.formatMessage({ id: 'download_assaignment_schedule_error_message' })
        },
        [DocumentType.REGISTRATION_FORM]: {
          value: registrationForm,
          error: intl.formatMessage({ id: 'download_registration_error' }),
          message: intl.formatMessage({ id: 'download_registration_error_message' })
        }
      };

      if (document === 'all') {
        if (contract && assignmentSchedule && registrationForm) {
          const docs = [contract, assignmentSchedule, registrationForm];
          await Promise.all(
            docs.map(async (d) => {
              await downloadDoc(d);
            })
          );
          return;
        } else {
          return toast(
            <AttentionSnackbar
              title={intl.formatMessage({ id: 'download_all_error' })}
              message={intl.formatMessage({ id: 'download_all_error_message' })}
            />
          );
        }
      }

      if (documents[document].value) {
        await downloadDoc(documents[document].value!);
      } else {
        toast(<AttentionSnackbar title={documents[document].error} message={documents[document].message} />);
      }
    }
  );

  const onDownloadHandler = useCallback(() => {
    isDownloadAvailable
      ? setIsMenuOpened((prev) => !prev)
      : toast(
          <AttentionSnackbar
            title={intl.formatMessage({ id: 'download_error' })}
            message={intl.formatMessage({ id: 'download_error_message' })}
          />
        );
  }, [intl, isDownloadAvailable]);

  return (
    <Grid
      container
      columns={5}
      spacing={2}
      sx={{
        m: 0,
        backgroundColor: '#FFFFFF',
        border: `1px solid ${secondary[300]}`,
        borderRadius: '8px',
        '& p': {
          padding: '20px 24px 16px'
        },
        '& > .MuiGrid-item': {
          paddingTop: 0
        },
        '@media only screen and (max-width: 479px)': {
          padding: '16px',
          gap: '12px',
          '& > div.MuiGrid-item': {
            padding: 0
          },
          '& p': {
            padding: 0
          }
        }
      }}
    >
      <Grid
        item
        xs={1}
        sx={{
          '@media only screen and (max-width: 479px)': {
            flexBasis: '45%',
            minWidth: '45%'
          }
        }}
      >
        <Typography color='secondary.800' variant='body2' fontWeight={400} textAlign='start'>
          {contractType ?? '-'}
        </Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          '@media only screen and (max-width: 479px)': {
            flexBasis: '45%',
            minWidth: '45%'
          }
        }}
      >
        <Typography
          color='secondary.800'
          variant='body2'
          fontWeight={400}
          sx={{
            '@media only screen and (max-width: 479px)': {
              textAlign: 'end'
            }
          }}
        >
          {date}
        </Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'start',
          display: 'flex',
          padding: 0,
          '& > p': {
            padding: '8px 16px',
            backgroundColor: getStatusColor(status),
            borderRadius: '4px'
          },
          '@media only screen and (max-width: 479px)': {
            flexBasis: '100%',
            minWidth: '100%',
            '& > p': {
              width: '100%'
            }
          }
        }}
      >
        <Typography color='secondary.800' variant='body1' fontSize='14px' lineHeight='16px' textAlign='center'>
          {intl.formatMessage({ id: `registration.status.${status}` })}
        </Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          padding: 0,
          position: 'relative',
          '@media only screen and (max-width: 479px)': {
            flexBasis: '100%',
            minWidth: '100%'
          }
        }}
      >
        <Button
          variant='outlined'
          onClick={onDownloadHandler}
          sx={{
            padding: '8px 16px',
            width: '232px',
            borderRadius: isMenuOpened ? '8px 8px 0 0' : '8px',
            '@media only screen and (max-width: 1434px)': {
              width: '96px'
            },
            '@media only screen and (max-width: 479px)': {
              width: '100%'
            }
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              width: '100%',
              color: isDownloadAvailable ? secondary[800] : secondary[500],
              '& svg': {
                color: isDownloadAvailable ? primary[600] : info[500]
              }
            }}
          >
            <Box display='flex' alignItems='center' gap={2}>
              <FolderDownloadIcon />
              <Typography
                variant='body2'
                sx={{
                  '@media only screen and (max-width: 1434px)': {
                    display: 'none'
                  },
                  '@media only screen and (max-width: 479px)': {
                    display: 'inline-block'
                  }
                }}
              >
                {intl.formatMessage({ id: 'download' })}
              </Typography>
            </Box>
            {isMenuOpened ? <ChevronIcon direction='up' /> : <ChevronIcon direction='down' />}
          </Box>
        </Button>
        {isMenuOpened && (
          <ClickAwayListener
            onClickAway={() => {
              setIsMenuOpened(false);
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              sx={{
                border: `1px solid ${primary[600]}`,
                borderRadius: '0 0 8px 8px',
                position: 'absolute',
                top: '54px',
                background: '#FFFFFF',
                py: '4px',
                mt: '-1px',
                zIndex: 1,
                '& button': {
                  padding: '8px 16px',
                  borderRadius: 0,
                  fontWeight: 400,
                  color: secondary[700],
                  display: 'flex',
                  gap: 2,
                  '&.disabled': {
                    color: secondary[500]
                  }
                },
                width: '230px',
                '@media only screen and (max-width: 1364px)': {
                  borderRadius: '8px'
                },
                '@media only screen and (max-width: 479px)': {
                  top: '44px',
                  borderRadius: '0 0 8px 8px',
                  width: 'calc(100% - 2px)'
                }
              }}
            >
              <Button
                variant='text'
                onClick={async () => await dowloadFile.execute(DocumentType.CONTRACT)}
                className={!contract ? 'disabled' : ''}
              >
                <FileWithPenIcon color={contract ? undefined : secondary['400']} />
                {intl.formatMessage({ id: 'contract' })}
              </Button>
              <Button
                variant='text'
                className={!assignmentSchedule ? 'disabled' : ''}
                onClick={async () => await dowloadFile.execute(DocumentType.ASSIGNMENT_SCHEDULE)}
              >
                <FileWithPenIcon color={contract ? undefined : secondary['400']} />
                {intl.formatMessage({ id: 'assignment_schedule' })}
              </Button>
              <Button
                variant='text'
                onClick={async () => await dowloadFile.execute(DocumentType.REGISTRATION_FORM)}
                className={!registrationForm ? 'disabled' : ''}
              >
                <FileLockedIcon color={contract ? undefined : secondary['400']} />
                {intl.formatMessage({ id: 'full_registration_pdf' })}
              </Button>
              <Button
                variant='text'
                onClick={async () => await dowloadFile.execute('all')}
                className={registrationForm && contract && assignmentSchedule ? '' : 'disabled'}
              >
                <DocumentsIcon color={contract ? undefined : secondary['400']} />
                {intl.formatMessage({ id: 'all_documents' })}
              </Button>
            </Box>
          </ClickAwayListener>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          padding: 0,
          '@media only screen and (max-width: 479px)': {
            flexBasis: '100%',
            minWidth: '100%'
          }
        }}
      >
        <Button
          variant='outlined'
          onClick={openModal}
          sx={{
            '@media only screen and (max-width: 479px)': {
              width: '100%'
            }
          }}
        >
          {intl.formatMessage({ id: 'view' })}
        </Button>
      </Grid>
    </Grid>
  );
};
