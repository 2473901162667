import { Box, Button, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { type Dayjs } from 'dayjs';
import { Field, FormikContext, type FieldProps } from 'formik';
import { useCallback, useContext, useEffect, useState, type ChangeEvent, type FC } from 'react';
import { useIntl } from 'react-intl';
import { usePalette } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { ErrorHelper } from '../ErrorHelper';
import { CopyIcon } from '../icons';
import { Tooltip } from '../Tooltip';
import { type ComponentProps, type FormComponentProps } from './types';

type Periods = Record<string, Record<string, string | null>>;
const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const getActiveDays = (period: Periods): string[] => Object.keys(period);
const getPeriods = (value: string | undefined): Periods =>
  DAYS.reduce((obj: Periods, day: string) => {
    if (value?.includes(day)) {
      obj[day] = {};
      obj[day].start = null;
      obj[day].end = null;
    }

    return obj;
  }, {});

const TimeInput = ({
  disabled,
  label,
  time,
  setTime
}: {
  disabled?: boolean;
  label: string;
  time: string | null;
  setTime: (v: Dayjs | null) => void;
}): JSX.Element => {
  const splitedTime = time?.split(':') ?? [null, null];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        disabled={disabled}
        onChange={(v) => {
          setTime(v);
        }}
        value={time ? dayjs().hour(Number(splitedTime[0])).minute(Number(splitedTime[1])) : null}
        renderInput={({ inputProps, ...restParams }: any) => (
          <TextField
            {...restParams}
            disabled={disabled}
            label={label}
            InputProps={{
              endAdornment: restParams.InputProps.endAdornment,
              ...inputProps,
              placeholder: undefined
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const Component: FC<ComponentProps> = (props): JSX.Element => {
  const { disabled, field, text, form, id, withTimePicker, options, tooltip } = props;
  const { formatMessage } = useIntl();
  const { primary, secondary } = usePalette();

  const [period, setPeriod] = useState(getPeriods(field.value));

  const [activeDays, setActiveDays] = useState(getActiveDays(period));

  const setTime = useCallback(
    (day: string, part: 'start' | 'end', value: Dayjs | null) => {
      const newPeriod = period;
      newPeriod[day][part] = dayjs(value).format('HH:mm');
      const timeValue = Object.entries(newPeriod).reduce(
        (string, [key, { start, end }]) => `${string}${key}/${start}_${end};`,
        ''
      );
      field.onChange({ target: { name: field.name, value: timeValue } });
    },
    [field, period]
  );

  useEffect(() => {
    const value = field.value;

    if (value) {
      const dayPeriods: string[] = (Array.isArray(value) ? value[0] : value).split(';').filter((v: string) => !!v);
      const newPeriods: Periods = {};

      dayPeriods.forEach((part) => {
        const [day, time] = part.split('/');
        const [start, end] = time.split('_');
        newPeriods[day] = { start: start !== 'null' ? start : null, end: end !== 'null' ? end : null };
      });

      setPeriod((prev) => Object.assign(prev, newPeriods));
      if (!activeDays.length) setActiveDays(getActiveDays(Object.assign(period, newPeriods)));
    }
  }, [activeDays.length, field.value, period]);

  const onActiveDaysChangeHandler = useCallback(
    (v: any) => {
      setActiveDays(v);
      field.onChange({
        target: {
          name: field.name,
          value: DAYS.reduce((string, day) => {
            const currentPeriod = period[day];
            return v.includes(day)
              ? `${string}${day}/${currentPeriod?.start ?? null}_${currentPeriod?.end ?? null};`
              : string;
          }, '')
        }
      });
    },
    [field, period]
  );

  const spreadTime = useCallback(() => {
    const { start, end } = period[DAYS.find((day) => activeDays.includes(day))!];
    const newPeriods = activeDays.reduce((obj: Periods, day: string) => {
      obj[day] = { start, end };
      return obj;
    }, {});
    const newPeriodsObject = Object.assign(period, newPeriods);
    field?.onChange({
      target: {
        name: field.name,
        value: Object.entries(newPeriodsObject).reduce(
          (string, [key, { start, end }]) => `${string}${key}/${start}_${end};`,
          ''
        )
      }
    });
  }, [activeDays, field, period]);

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', pb: '6px' }}>
        <Typography variant='body1' color={secondary[800]} fontWeight={600} mb={1}>
          {text}
        </Typography>
        <Tooltip text={tooltip ?? ''} />
      </Box>
      <ToggleButtonGroup
        disabled={disabled}
        value={activeDays}
        exclusive={false}
        onChange={(e, v) => {
          onActiveDaysChangeHandler(v);
        }}
        aria-label='Days'
        orientation='horizontal'
        sx={{
          color: secondary[800],
          flexWrap: 'wrap',
          gap: '14px',
          my: '4px'
        }}
      >
        {options
          .filter(({ value }) => value)
          .map(({ label, optionId }) => (
            <ToggleButton
              key={optionId}
              value={label.toLowerCase()}
              sx={{
                width: '32px',
                height: '32px',
                '&.MuiToggleButtonGroup-grouped': {
                  backgroundColor: '#FFFFFF',
                  '&.Mui-selected': {
                    border: `1px solid ${primary[600]} !important`,
                    backgroundColor: `${primary[300]} !important`
                  },
                  '&:hover': {
                    backgroundColor: secondary[100],
                    border: `1px solid ${primary[500]} !important`
                  }
                }
              }}
            >
              {label[0]}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>

      {withTimePicker &&
        DAYS.filter((day) => activeDays.includes(day)).map((day, index) => (
          <>
            <Box key={`${day}${index}`} display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='body1' color={secondary[700]}>
                {formatMessage({ id: day })}
              </Typography>
              <Box display='flex' gap={3} flexBasis='70%' alignItems='center'>
                <TimeInput
                  disabled={disabled}
                  label={formatMessage({ id: 'time' })}
                  time={period[day]?.start}
                  setTime={(v: Dayjs | null) => {
                    setTime(day, 'start', v);
                  }}
                />
                <Typography variant='body1' color='secondary.800'>
                  {formatMessage({ id: 'to' })}
                </Typography>
                <TimeInput
                  disabled={disabled}
                  label={formatMessage({ id: 'time' })}
                  time={period[day]?.end}
                  setTime={(v: Dayjs | null) => {
                    setTime(day, 'end', v);
                  }}
                />
              </Box>
            </Box>
            {!index && activeDays.length > 1 && (
              <Button
                variant='text'
                sx={{
                  gap: '10px',
                  width: 'auto',
                  alignSelf: 'end',
                  justifyContent: 'space-around',
                  padding: '8px 16px'
                }}
                onClick={spreadTime}
              >
                <CopyIcon />
                <Typography variant='body2' color={secondary[800]}>
                  {formatMessage({ id: 'copy_time_to_all' })}
                </Typography>
              </Button>
            )}
          </>
        ))}
      <ErrorHelper error={form?.errors[id] as string} />
    </Box>
  );
};

export const DayComponent: FC<FormComponentProps> = (props) => {
  const { required: initialRequired, id } = props;
  const [value, setValue] = useState<string>('');
  const required = initialRequired ?? false;

  const formik = useContext(FormikContext);
  return formik ? (
    <Field
      {...props}
      name={id}
      component={Component}
      validate={required && Validator.pipe(Validator.methods.required())}
    />
  ) : (
    <Component
      {...props}
      field={
        {
          value,
          onChange: ({ target }: ChangeEvent<any>) => {
            setValue(target.value);
          }
        } as FieldProps['field']
      }
    />
  );
};
