import { useQuery } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';
import { OrderDirection, TenantWithAnalyticsOrderFields } from '__graphql__/globalTypes';
import { Statistic } from 'admin/pages/Reports/Statistic';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Loader, PageWrapper, StyledTablePagination } from 'shared/components';
import { ConnectionsIcon, DashboardIcon, MoneyIcon, ReportsIcon, UsersIcon } from 'shared/components/icons';
import { DateFilter, TenantsFilter } from 'shared/components/ReportFilters/components';
import { DEFAULT_REPORT_DATES } from 'shared/components/ReportFilters/const';
import { AttentionSnackbar } from 'shared/components/Snackbars';
import { usePalette, useTablePagination, useTableSort } from 'shared/hooks';
import { getCSV } from 'shared/utils/csv';
import { getTruncatedNumber } from 'shared/utils/formatters';
import { type DateRange } from '../../../admin/pages/Reports/Calendars';
import { type AnalyticsOfTenants, type AnalyticsOfTenantsVariables } from './__graphql__/AnalyticsOfTenants';
import { analyticsQuery } from './api';
import { TenantsTable } from './TenantsTable';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1
});

export const Analytics: FC = () => {
  const { formatMessage } = useIntl();
  const { success } = usePalette();
  const { limit, offset, ...tablePaginationProps } = useTablePagination();
  const sort = useTableSort<TenantWithAnalyticsOrderFields>({
    defaultField: TenantWithAnalyticsOrderFields.Name,
    defaultDirection: OrderDirection.asc
  });
  const { field, direction } = sort;

  const [tenantsIds, setTenantsIds] = useState<string[]>([]);
  const [calendarDates, setCalendarDates] = useState<DateRange<Date>>(DEFAULT_REPORT_DATES);

  const { data, loading } = useQuery<AnalyticsOfTenants, AnalyticsOfTenantsVariables>(analyticsQuery, {
    variables: {
      orderBy: {
        field: field!,
        direction: direction!
      },
      filter: { tenantsIds, startDate: calendarDates[0]!, endDate: calendarDates[1]! }
    },
    skip: !calendarDates[0] || !calendarDates[1],
    context: API_CONTEXT.SUPERADMIN
  });

  const tableData = data?.analytics.items ?? [];
  const paginatedData = tableData.slice(offset, offset + limit);

  const downloadReport = async (): Promise<void> => {
    if (!calendarDates[0] || !calendarDates[1]) {
      toast(
        <AttentionSnackbar
          title={formatMessage({ id: 'date_range_required_title' })}
          message={formatMessage({ id: 'date_range_required_message' })}
        />
      );
    } else if (!tableData.length) {
      toast(
        <AttentionSnackbar
          title={formatMessage({ id: 'empty_report_title' })}
          message={formatMessage({ id: 'empty_report_message' })}
        />
      );
    } else {
      const fileData = tableData.map((item) => ({
        [formatMessage({ id: 'tenant_name' })]: item.name,
        [formatMessage({ id: 'total_registrations' })]: item.registrations,
        [formatMessage({ id: 'total_revenue' })]: item.revenue,
        [formatMessage({ id: 'users' })]: item.users,
        [formatMessage({ id: 'last_activity' })]: item.lastActivityDate,
        [formatMessage({ id: 'plan' })]: item.plan,
        [formatMessage({ id: 'used_registrations' })]: `${item.usedItems}/${item.totalItems}`
      }));
      const periodStart = dayjs(calendarDates[0]).format('DD/MM/YYYY');
      const periodEnd = dayjs(calendarDates[1]).format('DD/MM/YYYY');
      getCSV(fileData, `Report ${periodStart}-${periodEnd}`);
    }
  };

  if (loading) {
    return (
      <PageWrapper sx={{ p: '24px 40px 16px 24px' }}>
        <Loader />
      </PageWrapper>
    );
  }

  const analytics: Omit<AnalyticsOfTenants['analytics']['general'], '__typename'> = data?.analytics?.general ?? {
    tenants: 0,
    users: 0,
    totalRevenue: 0,
    registrations: 0
  };

  return (
    <PageWrapper sx={{ p: '24px 40px 16px 24px' }}>
      <>
        <Box>
          <Typography variant='h2' sx={{ fontWeight: 400, fontSize: '24px', lineHeight: '24px', mb: '16px' }}>
            {formatMessage({ id: 'general_analytics' })}
          </Typography>
          <Grid container sx={{ marginBottom: '16px' }} spacing={6}>
            <Grid item xs={3}>
              <Statistic nameIntl='new_registrations' value={analytics.registrations} icon={<DashboardIcon />} />
            </Grid>
            <Grid item xs={3}>
              <Statistic nameIntl='active_tenants' value={analytics.tenants} icon={<ConnectionsIcon />} />
            </Grid>
            <Grid item xs={3}>
              <Statistic nameIntl='active_users' value={analytics.users} icon={<UsersIcon color={success[200]} />} />
            </Grid>
            <Grid item xs={3}>
              <Statistic
                nameIntl='total_revenue'
                value={getTruncatedNumber(analytics.totalRevenue)}
                icon={<MoneyIcon />}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Box sx={{ mb: '16px' }}>
            <Typography variant='h2' sx={{ fontWeight: 400, fontSize: '24px', lineHeight: '24px', mb: '16px' }}>
              {formatMessage({ id: 'tenants_review' })}
            </Typography>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <TenantsFilter value={tenantsIds} onChange={setTenantsIds} />
              <DateFilter value={calendarDates} onChange={setCalendarDates} />
              <Button
                onClick={downloadReport}
                variant='contained'
                sx={{ marginLeft: '4px', display: 'flex', gap: '10px', minWidth: '220px' }}
              >
                <ReportsIcon color='#ffffff' />
                {formatMessage({ id: 'download_report' })}
              </Button>
            </Box>
          </Box>
          <TenantsTable
            data={paginatedData}
            sort={sort}
            page={tablePaginationProps.page}
            rowsPerPage={tablePaginationProps.rowsPerPage}
          />
          <StyledTablePagination count={tableData.length} {...tablePaginationProps} />
        </Box>
      </>
    </PageWrapper>
  );
};
