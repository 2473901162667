import { useMutation } from '@apollo/client';
import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  TextField,
  Typography,
  type SxProps,
  type Theme
} from '@mui/material';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Field, type FormikProps } from 'formik';
import { useState, type FC, type SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { type Discount_discount } from 'shared/api/__graphql__/Discount';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { FormDialog, Input, Select, TriangleMarkeredList } from 'shared/components';
import { CalendarIcon } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { TENANT_HEADER_NAME } from 'shared/constants';
import { usePalette } from 'shared/hooks';
import { isValidDate } from 'shared/utils/date';
import { getDiscountedPrice } from 'shared/utils/subscription';
import { Validator } from 'shared/utils/validator';
import { type ExtendTrial, type ExtendTrialVariables } from 'superAdmin/__graphql__/ExtendTrial';
import { extendTrialMutation } from 'superAdmin/api';
import { type RemoveDiscount } from './__graphql__/RemoveDiscount';
import { type SetDiscount, type SetDiscountVariables } from './__graphql__/SetDiscount';
import { removeDiscountMutation, setDiscountMutation } from './api';
import { type PlanCardConfig } from '.';

interface Props {
  plan: PlanCardConfig;
  tenant: CompanyInfo_companyInfo;
  onClose: () => void;
}

interface CalendarProps {
  name: string;
  label: string;
  value: Date;
  setFieldValue: (name: string, value: Date) => void;
  disableDay: (day: string | Date) => boolean;
  sx: SxProps<Theme>;
  validate?: (value: unknown) => Promise<string | void>;
  disabled?: boolean;
}

const CalendarField: FC<CalendarProps> = ({
  disabled,
  validate,
  label,
  name,
  value,
  setFieldValue,
  disableDay,
  sx
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { primary, info } = usePalette();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Field
        id={name}
        name={name}
        label={label}
        component={Input}
        validate={validate}
        value={value ? dayjs(value).format('DD/MM/YYYY') : ''}
        InputProps={{
          disabled,
          endAdornment: (
            <IconButton sx={{ cursor: disabled ? 'default' : 'pointer' }} onClick={() => !disabled && setIsOpen(true)}>
              <CalendarIcon />
            </IconButton>
          )
        }}
      />
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Box
            sx={{
              ...sx,
              display: 'flex',
              position: 'absolute',
              zIndex: 2,
              background: '#FFF',
              border: `1px solid ${primary[600]}`,
              '& .MuiDayPicker-header': {
                borderBottom: `1px solid ${info[500]}`,
                margin: '0 16px 8px',
                '& > span': {
                  color: 'secondary.600',
                  fontSize: '16px'
                }
              }
            }}
          >
            <Box>
              <StaticDatePicker
                inputFormat='DD/MM/YYYY'
                mask='__/__/____'
                displayStaticWrapperAs='desktop'
                value={isValidDate(value) ? value : ''}
                onChange={(input) => {
                  setFieldValue(name, dayjs(input).endOf('day').toDate());
                  setIsOpen(false);
                }}
                renderInput={(props: any) => <TextField {...props} />}
                shouldDisableDate={disableDay}
                renderDay={(_day, _value, DayComponentProps) => (
                  <PickersDay
                    {...DayComponentProps}
                    disableHighlightToday
                    showDaysOutsideCurrentMonth
                    className='pickers-day'
                  />
                )}
              />
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </LocalizationProvider>
  );
};

export const ExtendTrialWidget: FC<Props> = ({ tenant, plan, onClose }) => {
  const { name } = tenant;
  const { startDate, endDate } = plan;
  const [currentEndDate, setCurrentEndDate] = useState(endDate ?? new Date());
  const { formatMessage } = useIntl();

  const [extendTrial] = useMutation<ExtendTrial, ExtendTrialVariables>(extendTrialMutation, {
    refetchQueries: ['CompanyInfo'],
    awaitRefetchQueries: true,
    context: {
      headers: {
        [TENANT_HEADER_NAME]: tenant.id
      }
    }
  });

  const Form: FC<FormikProps<{ trialEndDate: Date }>> = ({ setFieldValue }) => (
    <Box sx={{ display: 'flex', gap: '20px' }}>
      <Box sx={{ flex: '1.3' }}>
        <Typography>{formatMessage({ id: 'you_can_extend_trial' }, { tenant: name })}</Typography>
        <TriangleMarkeredList
          sx={{ maxHeight: '200px' }}
          itemsIntlIds={[
            'extend_trial_points.1',
            'extend_trial_points.2',
            'extend_trial_points.3',
            'extend_trial_points.4'
          ]}
        />
      </Box>
      <Box sx={{ flex: '1' }}>
        <Typography sx={{ mb: '24px', fontWeight: 600 }}>{formatMessage({ id: 'timing' })}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Field
            id='startDate'
            name='startDate'
            label={formatMessage({ id: 'trial_start_date' })}
            component={Input}
            value={dayjs(startDate).format('DD/MM/YYYY')}
            InputProps={{
              disabled: true,
              readOnly: true,
              endAdornment: (
                <Box>
                  <CalendarIcon />
                </Box>
              )
            }}
          />
          <CalendarField
            sx={{
              '@media only screen and (max-width: 800px)': {
                top: 'calc((100vh / 2) + 34px)',
                left: 'calc(100vw / 2 + 24px)'
              },
              '@media only screen and (max-width: 1200px)': {
                top: 'calc((100vh / 2) + 34px)',
                left: 'calc(100vw / 2 + 85px)'
              },
              '@media only screen and (min-width: 1200px)': {
                left: 'calc(100vw / 2 + 5vw)',
                top: 'calc(100vh / 2 + 3%)'
              }
            }}
            label={formatMessage({ id: 'trial_end_date' })}
            value={currentEndDate}
            name='endDate'
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
              setCurrentEndDate(value);
            }}
            disableDay={(day) => dayjs(day).isBefore(dayjs(startDate).add(14, 'days')) || dayjs(day).isBefore(dayjs())}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <FormDialog
      isOpen={true}
      onClose={onClose}
      header={formatMessage({ id: 'extend_trial' })}
      initialValues={{ endDate }}
      confirmBtnName={formatMessage({ id: 'save' })}
      resetBtnConfig={{
        title: formatMessage({ id: 'reset_extension' }),
        handler: () => {
          setCurrentEndDate(dayjs(startDate).add(14, 'days').toDate());
        },
        disabled: dayjs(startDate).add(14, 'days').isSame(currentEndDate, 'day')
      }}
      onSubmit={async () => {
        await extendTrial({ variables: { trialEndDate: currentEndDate } });
        onClose();
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: 'trial_extended' })}
            message={formatMessage({ id: 'trial_extended_message' })}
          />
        );
      }}
    >
      {/* @ts-expect-error: props are getting here from React.clone */}
      <Form />
    </FormDialog>
  );
};

interface DiscountFormValues {
  startDate?: Date | null;
  endDate?: Date | null;
  months?: number | null;
  currentPrice: number;
  discount?: number | null;
  newPrice?: number | null;
}

export const DiscountWidget: FC<Props> = ({ tenant, plan, onClose }) => {
  const { formatMessage } = useIntl();
  const { defaultPrice, discount } = plan;
  const { startDate = null, endDate = null, valueInPercent = null } = discount ?? ({} as Discount_discount);
  const isRemoveMode = !!valueInPercent;

  const [initialValues, setInitialValues] = useState<DiscountFormValues>({
    startDate,
    endDate,
    discount: valueInPercent,
    months: startDate ? dayjs(endDate).diff(dayjs(startDate), 'months') : null,
    currentPrice: defaultPrice,
    newPrice: getDiscountedPrice(valueInPercent, defaultPrice)
  });

  const [removeDiscount, { loading: removeLoading }] = useMutation<RemoveDiscount>(removeDiscountMutation, {
    context: {
      ...API_CONTEXT.PAYMENT,
      headers: {
        [TENANT_HEADER_NAME]: tenant.id
      }
    },
    refetchQueries: ['Discount'],
    awaitRefetchQueries: true
  });

  const [applyDiscount, { loading: applyLoading }] = useMutation<SetDiscount, SetDiscountVariables>(
    setDiscountMutation,
    {
      context: {
        ...API_CONTEXT.PAYMENT,
        headers: {
          [TENANT_HEADER_NAME]: tenant.id
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: ['Discount']
    }
  );

  const Form: FC<FormikProps<DiscountFormValues>> = ({ values, setFieldValue }) => (
    <Box>
      <Box>
        <Typography>{formatMessage({ id: 'give_discount' })}</Typography>
        <TriangleMarkeredList itemsIntlIds={['discount_points.1', 'discount_points.2']} />
      </Box>
      <Grid container columnSpacing='20px' rowSpacing='12px'>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 'bold' }}>{formatMessage({ id: 'timing' })}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 'bold' }}>{formatMessage({ id: 'pricing' })}</Typography>
        </Grid>
        <Grid item xs={6}>
          <CalendarField
            sx={{
              '@media only screen and (min-width: 900px)': {
                top: 'calc((100vh / 2) + 75px)',
                left: 'calc(100vw / 2 - 385px)'
              }
            }}
            value={values.startDate as Date}
            label={formatMessage({ id: 'start_date' })}
            name='startDate'
            validate={isRemoveMode ? undefined : Validator.pipe(Validator.methods.required())}
            setFieldValue={(name, v) => {
              setFieldValue(name, v);
              if (values.months) {
                setFieldValue('endDate', dayjs(v).add(values.months, 'months').toDate());
              }
            }}
            disabled={isRemoveMode}
            disableDay={(day) => dayjs(day).isBefore(dayjs().subtract(1, 'day'))}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            id='currentPrice'
            name='currentPrice'
            value={`£${values.currentPrice}`}
            label={formatMessage({ id: 'current_price' })}
            component={Input}
            InputProps={{ disabled: true, readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            id='months'
            name='months'
            label={formatMessage({ id: 'months' })}
            onChange={({ target }: any) => {
              setFieldValue('months', target.value);
              if (values.startDate) {
                setFieldValue('endDate', dayjs(values.startDate).add(target.value, 'months').toDate());
              }
            }}
            component={Select}
            validate={isRemoveMode ? undefined : Validator.pipe(Validator.methods.required())}
            options={Array.from(Array(12)).map((_, index) => ({
              label: formatMessage({ id: index ? 'number_months' : 'one_month' }, { number: index + 1 }),
              value: index + 1
            }))}
            InputProps={{ disabled: isRemoveMode }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            id='discount'
            name='discount'
            value={values.discount ?? ''}
            placeholder={`${formatMessage({ id: 'discount' })}, %`}
            label={formatMessage({ id: 'discount' })}
            validate={isRemoveMode ? undefined : Validator.pipe(Validator.methods.required(), Validator.methods.min(1))}
            onChange={({ target }: SyntheticEvent) => {
              let value = +(target as HTMLInputElement).value;
              if (value > 100) {
                value = 100;
                setFieldValue('discount', 100);
              }
              setFieldValue('newPrice', getDiscountedPrice(value, defaultPrice));
            }}
            component={Input}
            InputProps={{ disabled: isRemoveMode }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            id='endDate'
            name='endDate'
            value={values.endDate ? dayjs(values.endDate).format('DD/MM/YYYY') : ''}
            label={formatMessage({ id: 'end_date' })}
            component={Input}
            InputProps={{ disabled: true, readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            id='newPrice'
            name='newPrice'
            value={values.newPrice != null ? `£${values.newPrice}` : ''}
            label={formatMessage({ id: 'new_price' })}
            component={Input}
            InputProps={{ disabled: true, readOnly: true }}
          />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <FormDialog
      isOpen={true}
      onClose={onClose}
      loading={removeLoading || applyLoading}
      header={formatMessage({ id: valueInPercent ? 'remove_discount' : 'apply_discount' })}
      initialValues={initialValues}
      resetBtnConfig={
        valueInPercent
          ? {
              title: formatMessage({ id: 'remove_discount' }),
              handler: async () => {
                setInitialValues({ startDate: null, endDate: null, discount: null, currentPrice: defaultPrice });
              },
              disabled: !initialValues.discount
            }
          : undefined
      }
      confirmBtnName={formatMessage({ id: 'save' })}
      onSubmit={async (values) => {
        if (isRemoveMode) {
          await removeDiscount();
          onClose();
          toast(
            <SuccessSnackbar
              title={formatMessage({ id: 'discount_removed' })}
              message={formatMessage({ id: 'discount_removed_message' })}
            />
          );
          return;
        }

        await applyDiscount({
          variables: {
            data: { startDate: values.startDate!, endDate: values.endDate!, valueInPercent: +values.discount! }
          }
        });
        onClose();
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: 'discount_applied_title' })}
            message={formatMessage({ id: 'discount_applied_message' })}
          />
        );
      }}
    >
      {/* @ts-expect-error: props are getting here from React.clone */}
      <Form />
    </FormDialog>
  );
};
