import { type Dispatch, type SetStateAction, createContext } from 'react';

export interface ISearchContext {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

export const SearchContext = createContext<ISearchContext>({
  search: '',
  setSearch: () => {}
});
