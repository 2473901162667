import gql from 'graphql-tag';

export const signInMutation = gql`
  mutation SignIn($data: LoginInput!) {
    signIn(data: $data) {
      refreshToken
      accessToken
      user {
        id
        role
      }
    }
  }
`;

export const inviteConfirmMutation = gql`
  mutation InviteConfirm($data: InviteConfirmInput!, $token: JWT!) {
    inviteConfirm(data: $data, token: $token) {
      refreshToken
      accessToken
      user {
        id
        role
      }
    }
  }
`;

export const forgotPasswordMutation = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const resetPasswordMutation = gql`
  mutation ResetPassword($data: ResetPasswordInput!, $token: JWT!) {
    resetPassword(data: $data, token: $token)
  }
`;

export const changeEmailMutation = gql`
  mutation ChangeEmail($token: JWT!) {
    changeEmail(token: $token)
  }
`;

export const signOutMutation = gql`
  mutation SignOut {
    signOut
  }
`;
