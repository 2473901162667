import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const DoubleChevronIcon: FC<IconProps & { direction: 'left' | 'right' }> = (props) => {
  const { direction, color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return direction === 'left' ? (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 18L6 12L12 6' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 18L12 12L18 6' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ) : (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 6L18 12L12 18' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6L12 12L6 18' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
