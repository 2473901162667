import { useQuery } from '@apollo/client';
import { Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { OrderDirection } from '__graphql__/globalTypes';
import { prop } from 'ramda';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { EmptyTableBox, StyledTablePagination } from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { useTablePagination, useTableSort } from 'shared/hooks';
import { type SectionsList, type SectionsListVariables, type SectionsList_sections } from './__graphql__/SectionsList';
import { sectionsListQuery } from './api';
import { excludeSectionFromTargets } from './utils';

interface Props {
  choosenId: string;
  onChange: (item: SectionsList_sections) => void;
}

enum OrderField {
  Name = 'name',
  Variable = 'variable',
  Description = 'description',
  Relationship = 'relationshipType'
}

export const SectionsTable: FC<Props> = (props) => {
  const { onChange, choosenId } = props;
  const { formatMessage } = useIntl();
  const pagination = useTablePagination();
  const { form, component } = useContext(FormBuilderContext);

  const { createSortHandler, field, direction } = useTableSort({
    defaultField: OrderField.Name,
    defaultDirection: OrderDirection.asc
  });

  const { data } = useQuery<SectionsList, SectionsListVariables>(sectionsListQuery, {
    variables: { filters: { formId: form?.id! } },
    skip: !form?.id
  });

  let items = excludeSectionFromTargets(data?.sections ?? [], component?.id!);
  items = items.sort((a, b) =>
    direction === OrderDirection.asc
      ? prop<string>(field!, a)?.localeCompare(prop<string>(field!, b))
      : prop<string>(field!, b)?.localeCompare(prop<string>(field!, a))
  );
  const total = items.length;
  items = items.slice(pagination.offset, pagination.offset + pagination.limit);

  const emptyRows = pagination.page > 0 ? Math.max(0, (1 + pagination.page) * pagination.rowsPerPage - total) : 0;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel onClick={createSortHandler(OrderField.Name)} hideSortIcon={true}>
                  {formatMessage({ id: 'section_name' })}
                  <SortIcon active={field === OrderField.Name} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(OrderField.Variable)} hideSortIcon={true}>
                  {formatMessage({ id: 'variable' })}
                  <SortIcon active={field === OrderField.Variable} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(OrderField.Description)} hideSortIcon={true}>
                  {formatMessage({ id: 'description' })}
                  <SortIcon active={field === OrderField.Description} direction={direction} />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel onClick={createSortHandler(OrderField.Relationship)} hideSortIcon={true}>
                  {formatMessage({ id: 'relationship' })}
                  <SortIcon active={field === OrderField.Relationship} direction={direction} />
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Radio
                    checked={item.id === choosenId}
                    onClick={() => {
                      onChange(item);
                    }}
                  />
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.variable}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{formatMessage({ id: item.relationshipType.toLowerCase() })}</TableCell>
              </TableRow>
            ))}
            {!items.length && pagination.page === 0 && (
              <TableRow
                style={{
                  height: 64 * pagination.rowsPerPage,
                  background: 'inherit'
                }}
              >
                <TableCell
                  colSpan={5}
                  style={{
                    height: 64 * pagination.rowsPerPage,
                    padding: 0
                  }}
                >
                  <EmptyTableBox />
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 64 * emptyRows
                }}
              >
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination count={total} {...pagination} />
    </>
  );
};
