import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { Validator } from 'shared/utils/validator';
import { FileFormatsProperty } from './components/FileFormatsProperty';
import { PropertyBlock, RequirementProp, VariableProp } from './shared';
import { type ComponentPropertiesProps } from './shared/types';

export const FileUploadComponentProperties: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  return (
    <>
      <VariableProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <PropertyBlock
        readOnly
        title={formatMessage({ id: 'component_properties.type' })}
        value={{ component: formatMessage({ id: `components.${component?.component}` }) }}
        tooltip={formatMessage({ id: 'component_tooltips.type' })}
      />
      <PropertyBlock
        isMainProperty
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.main_text' })}
        value={{ text: component?.text }}
        tooltip={formatMessage({ id: 'component_tooltips.main_text' })}
      />
      <PropertyBlock
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.additional_text' })}
        value={{ additionalText: component?.additionalText }}
        tooltip={formatMessage({ id: 'component_tooltips.additional_text' })}
        validate={Validator.pipe(Validator.methods.required())}
      />
      <FileFormatsProperty isReadOnly={isReadOnly} onSubmit={onSubmit} />
      <RequirementProp isReadOnly={isReadOnly} onSubmit={onSubmit} />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.description' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.description' })}
        value={{ description: component?.description }}
        tooltip={formatMessage({ id: 'component_tooltips.description' })}
      />
    </>
  );
};
