import { IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';
import { type FC } from 'react';
import { CircleQuestionIcon } from 'shared/components/icons';
import { usePalette } from 'shared/hooks';

interface Props {
  title: string;
  palette?: 'primary' | 'warning';
}

export const InputTooltip: FC<Props> = ({ title, palette = 'primary' }) => {
  const { warning, primary, secondary } = usePalette();
  const isWarning = palette === 'warning';
  return (
    <InputAdornment position='end'>
      <Tooltip
        onClick={(e) => {
          e.stopPropagation();
        }}
        enterTouchDelay={0}
        placement='top-start'
        PopperProps={{ sx: { '& > div.MuiTooltip-tooltip': { margin: '0 0 -15px 12px !important' } } }}
        title={
          <Typography
            variant='body1'
            lineHeight='20px'
            sx={{
              margin: '-5px -9px',
              background: '#FFF',
              border: `1px solid ${isWarning ? warning[200] : primary[600]}`,
              borderRadius: '8px',
              maxWidth: '244px',
              color: secondary[700],
              p: '16px'
            }}
          >
            {title}
          </Typography>
        }
      >
        <IconButton sx={{ '&, &:hover': { zIndex: 1 } }}>
          <CircleQuestionIcon color={isWarning ? warning[200] : undefined} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};
