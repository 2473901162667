import { Box, type TextFieldProps } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Field, FormikContext, type FieldProps } from 'formik';
import { path, split } from 'ramda';
import { useContext, useState, type ChangeEvent, type FC } from 'react';
import { isValidDate, localToUTC, makeLocalAppearUTC } from 'shared/utils/date';
import { Validator, type TValidationFn } from 'shared/utils/validator';
import { CalendarIcon } from '../icons';
import { BaseInput, type BaseInputProps } from './InputComponent';
import { InputTooltip } from './InputTooltip';
import { type ComponentProps, type FormComponentProps } from './types';

const Component: FC<ComponentProps> = (props) => {
  const { disabled, field, tooltip, required, id } = props;

  const value = field.value;

  const { errors, validateField } = useContext(FormikContext) ?? { errors: {}, validateField: () => {} };
  const errorMessage = path(split('.', id), errors) as string;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        disabled={disabled}
        inputFormat='DD/MM/YYYY'
        mask='__/__/____'
        value={isValidDate(value) ? makeLocalAppearUTC(value) : null}
        onChange={(input, keyboardInput) => {
          if (input?.toString() === 'Invalid Date') {
            field.onChange({ target: { value: keyboardInput, name: field.name } });
          } else {
            field.onChange({ target: { value: localToUTC(input), name: field.name } });
          }
        }}
        components={{
          OpenPickerIcon: CalendarIcon
        }}
        renderInput={(renderProps) => (
          <BaseInput
            {...renderProps}
            {...(props as BaseInputProps)}
            onBlur={() => {
              validateField(id);
            }}
            label={props.text}
            error={!!errorMessage}
            helperText={errorMessage}
            InputLabelProps={{ required: required ?? false }}
            InputProps={
              {
                ...renderProps.inputProps,
                endAdornment: tooltip ? (
                  <>
                    {renderProps.InputProps?.endAdornment}
                    <InputTooltip title={tooltip} />
                  </>
                ) : (
                  <Box sx={{ mr: '16px' }}>{renderProps.InputProps?.endAdornment}</Box>
                )
              } as TextFieldProps['InputProps']
            }
            sx={props.sx}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const DateComponent: FC<FormComponentProps> = (props) => {
  const [value, setValue] = useState<string>('');
  const { id, options, required, validate } = props;

  const formik = useContext(FormikContext);

  const validators: TValidationFn[] = [];
  if (required) {
    validators.push(Validator.methods.required());
  }
  validators.push(Validator.methods.date());

  return formik ? (
    <Field {...props} name={id} component={Component} validate={validate || Validator.pipe(...validators)} />
  ) : (
    <Component
      {...props}
      options={options ?? []}
      field={
        {
          value,
          onChange: (e: ChangeEvent<any>) => {
            setValue(e.target.value);
          }
        } as FieldProps['field']
      }
    />
  );
};
