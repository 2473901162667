import { Box, Typography } from '@mui/material';
import { type OptionInput } from '__graphql__/globalTypes';
import { FieldArray, type FormikConfig } from 'formik';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { DAYS } from '../../utils';
import { CheckboxRenderer, PropertyBlock, RequirementProp, VariableProp } from './shared';
import { type ComponentPropertiesProps } from './shared/types';

const DayProp: FC<ComponentPropertiesProps> = ({ isReadOnly, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  const handleSubmit: FormikConfig<{ options: OptionInput[] }>['onSubmit'] = async (values) => {
    const optionsToSubmit = values.options.reduce<OptionInput[]>((result, { value, label, optionId }) => {
      result.push({ optionId, label, value: value ? `${label.toLowerCase()}/null_null` : '' });
      return result;
    }, []);

    await onSubmit({ options: optionsToSubmit });
  };

  const Renderer: FC = () => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '24px', flexWrap: 'wrap' }}>
      <FieldArray
        name='options'
        render={() => (
          <>
            <Box>
              {DAYS.slice(0, 4).map((day, index) => (
                <CheckboxRenderer
                  key={day}
                  isReadOnly={isReadOnly}
                  label={formatMessage({ id: day })}
                  name={`options.${index}.value`}
                />
              ))}
            </Box>
            <Box>
              {DAYS.slice(4).map((day, index) => (
                <CheckboxRenderer
                  key={day}
                  label={formatMessage({ id: day })}
                  name={`options.${index + 4}.value`}
                  isReadOnly={isReadOnly}
                />
              ))}
            </Box>
          </>
        )}
      />
    </Box>
  );

  const options = component?.options?.map(({ optionId, value, label }) => ({ optionId, label, value: !!value }));

  return (
    <PropertyBlock
      onSubmit={handleSubmit}
      readOnly
      title={formatMessage({ id: 'component_properties.days' })}
      value={{ options: options ?? [] }}
      tooltip={formatMessage({ id: 'component_tooltips.day' })}
      ViewComponent={<Renderer />}
    />
  );
};

const TimePickerProp: FC<ComponentPropertiesProps> = ({ isReadOnly, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  const Renderer: FC = () => (
    <Box>
      <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
        {formatMessage({ id: 'time_picker_associated' })}
      </Typography>
      <CheckboxRenderer
        isReadOnly={isReadOnly}
        label={formatMessage({ id: 'add_time_picker' })}
        name='withTimePicker'
      />
    </Box>
  );

  return (
    <PropertyBlock
      onSubmit={onSubmit}
      readOnly
      title={formatMessage({ id: 'component_properties.time_picker' })}
      value={{ withTimePicker: component?.withTimePicker }}
      tooltip={formatMessage({ id: 'component_tooltips.day' })}
      ViewComponent={<Renderer />}
    />
  );
};

export const DayComponentProperties: FC<ComponentPropertiesProps> = ({ isReadOnly, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  return (
    <>
      <VariableProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <PropertyBlock
        readOnly
        title={formatMessage({ id: 'component_properties.type' })}
        value={{ component: formatMessage({ id: `components.${component?.component}` }) }}
        tooltip={formatMessage({ id: 'component_tooltips.type' })}
      />
      <PropertyBlock
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.question_text' })}
        value={{ text: component?.text }}
        tooltip={formatMessage({ id: 'component_tooltips.question_text' })}
        isMainProperty
      />
      <DayProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <TimePickerProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <RequirementProp isReadOnly={isReadOnly} onSubmit={onSubmit} />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.tooltip' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.tooltip' })}
        value={{ tooltip: component?.tooltip }}
        tooltip={formatMessage({ id: 'component_tooltips.tooltip' })}
      />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.description' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.description' })}
        value={{ description: component?.description }}
        tooltip={formatMessage({ id: 'component_tooltips.description' })}
      />
    </>
  );
};
