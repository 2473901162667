import { useLazyQuery, useMutation, useQuery, type MutationHookOptions } from '@apollo/client';
import { FormStatus } from '__graphql__/globalTypes';
import { BASIC_INFO_SECTION } from 'admin/pages/Forms/ViewForm/constants';
import { getAllFormSections } from 'admin/pages/Forms/ViewForm/utlis';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { AttentionSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import routes from 'shared/constants/routes';
import { type FormContext } from 'shared/contexts';
import { retrieveErrorMessage } from 'shared/utils/errors';
import { useTenantNavigate } from '../useTenantNavigate';
import { type AddSection, type AddSectionVariables } from './__graphql__/AddSection';
import { type CloneQuestion, type CloneQuestionVariables } from './__graphql__/CloneQuestion';
import { type CloneSection, type CloneSectionVariables } from './__graphql__/CloneSection';
import { type CreateQuestion, type CreateQuestionVariables } from './__graphql__/CreateQuestion';
import { type DeleteQuestion, type DeleteQuestionVariables } from './__graphql__/DeleteQuestion';
import { type FormBuilderErrors, type FormBuilderErrorsVariables } from './__graphql__/FormBuilderErrors';
import { type FormBuilderQuestions, type FormBuilderQuestionsVariables } from './__graphql__/FormBuilderQuestions';
import {
  type FormDetails,
  type FormDetailsVariables,
  type FormDetails_formDetails_sections
} from './__graphql__/FormDetails';
import { type UpdateQuestion, type UpdateQuestionVariables } from './__graphql__/UpdateQuestion';
import { type UpdateSection, type UpdateSectionVariables } from './__graphql__/UpdateSection';
import { type UpsertForm, type UpsertFormVariables } from './__graphql__/UpsertForm';
import {
  addSectionMutation,
  cloneQuestionMutation,
  cloneSectionMutation,
  createQuestionMutation,
  deleteQuestionMutation,
  getFormDetailsQuery,
  getFormErrorsQuery,
  getQuestionsQuery,
  updateQuestionMutation,
  updateSectionMutation,
  upsertFormMutation
} from './api';
import { BASIC_INFO_QUESTIONS } from './constants';

const FORM_CHANGED_REFETCH = {
  refetchQueries: ['FormDetails'],
  awaitRefetchQueries: true
};

export function useFormBuilder(): FormContext {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const navigate = useTenantNavigate();

  const [actionOnSection, setActionOnSection] = useState<FormContext['actionOnSection']>(null);
  const [componentIdToBeActive, setComponentIdToBeActive] = useState<string | null>(null);
  const [choosenSection, setChoosenSection] = useState<FormDetails_formDetails_sections>(
    BASIC_INFO_SECTION as FormDetails_formDetails_sections
  );
  const [component, setComponent] = useState<FormContext['component']>(null);
  const id = pathname.match(/\/forms\/([0-9A-Za-z-]+)/)?.[1] ?? '';
  const [draggableId, setDraggableId] = useState('');
  const [editMainProperty, setEditMainProperty] = useState(false);

  const { data, loading: formDetailsLoading } = useQuery<FormDetails, FormDetailsVariables>(getFormDetailsQuery, {
    variables: { id },
    skip: !id
  });

  const [fetchErrorsRequest, { data: formErrorsData }] = useLazyQuery<FormBuilderErrors, FormBuilderErrorsVariables>(
    getFormErrorsQuery
  );

  const fetchErrors = useCallback(
    async (isPublishing = false, formId = '') => {
      const { data } = await fetchErrorsRequest({ variables: { isPublishing, id: formId || id } });
      return data?.formErrors ?? [];
    },
    [fetchErrorsRequest, id]
  );

  const formErrors = useMemo(() => {
    const errors: Record<string, string> = {};

    formErrorsData?.formErrors.forEach(({ questionId, sectionId, error }) => {
      if (questionId && sectionId) {
        errors[questionId] = error;
        errors[sectionId] = error;
      }
    });

    return errors;
  }, [formErrorsData?.formErrors]);

  const [createSection] = useMutation<AddSection, AddSectionVariables>(addSectionMutation, FORM_CHANGED_REFETCH);

  const [cloneSection] = useMutation<CloneSection, CloneSectionVariables>(cloneSectionMutation, FORM_CHANGED_REFETCH);

  const [updateSection] = useMutation<UpdateSection, UpdateSectionVariables>(
    updateSectionMutation,
    FORM_CHANGED_REFETCH
  );

  const [upsertForm, { loading: upsertFormLoading }] = useMutation<UpsertForm, UpsertFormVariables>(upsertFormMutation);

  const { data: questionsData } = useQuery<FormBuilderQuestions, FormBuilderQuestionsVariables>(getQuestionsQuery, {
    skip: !choosenSection.id || choosenSection.id === BASIC_INFO_SECTION.id,
    variables: { sectionId: choosenSection.id }
  });

  const [createQuestion] = useMutation<CreateQuestion, CreateQuestionVariables>(createQuestionMutation, {
    refetchQueries: ['FormBuilderQuestions', 'AddedComponents'],
    awaitRefetchQueries: true
  });

  const [updateQuestion] = useMutation<UpdateQuestion, UpdateQuestionVariables>(updateQuestionMutation, {
    refetchQueries: ['FormBuilderQuestions'],
    awaitRefetchQueries: true
  });

  const [cloneQuestion] = useMutation<CloneQuestion, CloneQuestionVariables>(cloneQuestionMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['FormBuilderQuestions']
  });

  const [deleteQuestion] = useMutation<DeleteQuestion, DeleteQuestionVariables>(deleteQuestionMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['FormBuilderQuestions', 'AddedComponents', 'FormDetails']
  });

  useEffect(() => {
    const currentSection = getAllFormSections(data?.formDetails.sections).find(({ id }) => choosenSection.id === id);
    setChoosenSection(currentSection ?? (BASIC_INFO_SECTION as FormDetails_formDetails_sections));
    setComponent(null);
    setEditMainProperty(false);
  }, [choosenSection?.id, data?.formDetails.sections]);

  useEffect(() => {
    if (componentIdToBeActive) {
      const question = questionsData?.questions.find(({ id }) => id === componentIdToBeActive);
      setComponent(question ?? null);
      setComponentIdToBeActive(null);
    }
  }, [componentIdToBeActive, questionsData?.questions]);

  useEffect(() => {
    setEditMainProperty(false);
  }, [component?.id]);

  const form = data?.formDetails;

  const submitForm = useCallback(
    async (options: MutationHookOptions<UpsertForm, UpsertFormVariables>) => {
      try {
        const { data } = await upsertForm(options);
        const status = options.variables?.data.status;
        setChoosenSection(BASIC_INFO_SECTION as FormDetails_formDetails_sections);
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: `form_${status}` })}
            message={formatMessage({ id: `form_${status}_message` })}
          />
        );

        toast(
          <SuccessSnackbar
            title={formatMessage({ id: `email_template_${status}_title` })}
            message={formatMessage({ id: `email_template_${status}_message` })}
          />
        );

        navigate(
          status === FormStatus.bUNPUBLISHED && id ? routes.form.replace(':id', data?.upsertForm!) : routes.forms
        );
      } catch (err) {
        const error = retrieveErrorMessage(err);

        toast(
          <AttentionSnackbar
            title={formatMessage({ id: `${error}_title` })}
            message={formatMessage({ id: `${error}_message` })}
          />
        );
      }
    },
    [formatMessage, id, navigate, upsertForm]
  );

  return {
    form,
    isReadOnly: data?.formDetails.status === FormStatus.aPUBLISHED,
    loading: {
      upsertForm: upsertFormLoading,
      formDetails: formDetailsLoading
    },
    section: choosenSection,
    actionOnSection,
    questions: choosenSection?.id === BASIC_INFO_SECTION.id ? BASIC_INFO_QUESTIONS : questionsData?.questions ?? [],
    draggableId,
    component,
    editMainProperty,
    formErrors,
    submitForm,
    fetchErrors,
    setEditMainProperty,
    setComponent,
    setDraggableId,
    setSection: setChoosenSection,
    setActionOnSection,
    createSection: async (options) => {
      const result = await createSection(options);
      setChoosenSection({
        id: result.data?.addSection!,
        name: options.variables?.args.name!
      } as FormDetails_formDetails_sections);
      return result;
    },
    cloneSection,
    updateSection,
    upsertForm,
    createQuestion: async (args) =>
      await createQuestion(args).then((res) => {
        setComponentIdToBeActive(res.data?.createQuestion!);
        return res;
      }),
    updateQuestion: async (args) =>
      await updateQuestion(args).then((res) => {
        setComponentIdToBeActive(res.data?.updateQuestion!);
        return res;
      }),
    cloneQuestion: async (args) =>
      await cloneQuestion(args).then((res) => {
        setComponentIdToBeActive(res.data?.cloneQuestion!);
        return res;
      }),
    deleteQuestion
  };
}
