import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const Agencies2Icon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0003 6H14.0003M10.0003 10H14.0003M10.0003 14H14.0003M10.0003 18H14.0003M6.00027 22V4C6.00027 3.73 6.00027 3.45 6.07027 3.18C6.13148 2.91063 6.267 2.66382 6.46144 2.46761C6.65589 2.27139 6.90146 2.13364 7.17027 2.07C7.46027 2 8.73027 2 9.00027 2H16.0003C16.2703 2 16.5503 2 16.8203 2.07C17.0896 2.13121 17.3365 2.26673 17.5327 2.46117C17.7289 2.65562 17.8666 2.90119 17.9303 3.17C18.0003 3.45 18.0003 3.73 18.0003 4V22H6.00027ZM2.00027 14V20C2.00027 21.1 2.90027 22 4.00027 22H6.00027V12H4.00027C3.73027 12 3.45027 12 3.18027 12.07C2.91027 12.14 2.66027 12.27 2.46027 12.47C2.27027 12.66 2.14027 12.91 2.07027 13.18C2.01382 13.4495 1.99031 13.7248 2.00027 14ZM20.8203 9.07C20.5508 9.01355 20.2754 8.99004 20.0003 9H18.0003V22H20.0003C20.5307 22 21.0394 21.7893 21.4145 21.4142C21.7896 21.0391 22.0003 20.5304 22.0003 20V11C22.0003 10.72 22.0003 10.45 21.9303 10.18C21.8603 9.91 21.7303 9.66 21.5303 9.46C21.3403 9.27 21.0903 9.14 20.8203 9.07V9.07Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
