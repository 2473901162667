import { useLazyQuery } from '@apollo/client';
import { createContext, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import client, { cache } from 'shared/apolloClient';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { type TenantSetupFinished, type TenantSetupFinishedVariables } from './__graphql__/TenantSetupFinished';
import { tenantSetupFinishedQuery } from './api';

const LOCAL_STORAGE_ITEM = 'tenant_setup';

export const TenantSetupTrackingContext = createContext<{
  startTracking: (id: string) => Promise<void>;
  getCurrentTracking: () => string | null;
}>({
  startTracking: () => Promise.resolve(),
  getCurrentTracking: () => null
});

export const TenantSetupTrackingContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const intl = useIntl();

  const [startTenantPolling, { data, stopPolling }] = useLazyQuery<TenantSetupFinished, TenantSetupFinishedVariables>(
    tenantSetupFinishedQuery,
    {
      pollInterval: 5000,
      context: API_CONTEXT.SUPERADMIN
    }
  );

  const startTracking = useCallback(async (id: string) => {
    localStorage.setItem(LOCAL_STORAGE_ITEM, id);
    await startTenantPolling({ variables: { id } });
  }, [startTenantPolling]);

  const getCurrentTracking = useCallback(() => localStorage.getItem(LOCAL_STORAGE_ITEM), []);
  const handleStop = useCallback(async () => {
    stopPolling();
    if (data?.tenant) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'tenant' });
      cache.gc();
      await startTenantPolling({ fetchPolicy: 'cache-only', pollInterval: 0 });
    }
  }, [stopPolling, data?.tenant, startTenantPolling]);

  const handleSuccess = useCallback(async () => {
    await handleStop();
    localStorage.removeItem(LOCAL_STORAGE_ITEM);
    toast(
      <SuccessSnackbar
        title={intl.formatMessage({ id: 'new_tenant_successfully_added' })}
        message={intl.formatMessage({ id: 'the_tenant_is_ready_to_use' }, { name: '' })}
      />
    );
    await client.refetchQueries({
      include: ['Tenants']
    });
  }, [intl, handleStop]);

  useEffect(() => {
    const tenantToTrack = localStorage.getItem(LOCAL_STORAGE_ITEM);
    if (tenantToTrack) {
      startTenantPolling({ variables: { id: tenantToTrack } });
    }
    return () => {
      const tenantToTrack = localStorage.getItem(LOCAL_STORAGE_ITEM);
      if (tenantToTrack) {
        handleStop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.tenant?.setupFinished) {
      handleSuccess();
    }
  }, [data?.tenant?.setupFinished, handleSuccess, stopPolling]);

  return (
    <TenantSetupTrackingContext.Provider value={{
      startTracking,
      getCurrentTracking
    }}>
      {children}
    </TenantSetupTrackingContext.Provider>
  );
};
