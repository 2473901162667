import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const OctagonQuestionMark: FC<IconProps> = (props) => {
  const { color, size = 24 } = props;
  const { warning } = usePalette();
  const currentColor = color ?? warning[500];

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 9.28197C10.2 8.88197 10.5 8.48197 10.9 8.28197C11.3141 8.04255 11.7976 7.95168 12.2704 8.02442C12.7432 8.09716 13.177 8.32914 13.5 8.68197C13.8 9.08197 14 9.48197 14 9.98197C14 11.282 12 11.982 12 11.982M12 15.982H12.01'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
