import { FC } from 'react';
import { IconProps } from './props';
import { usePalette } from 'shared/hooks';

export const ThreeDotsIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { secondary } = usePalette();
  const currentColor = color ?? secondary[600];
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='6' r='2' fill={currentColor} />
      <circle cx='12' cy='12' r='2' fill={currentColor} />
      <circle cx='12' cy='18' r='2' fill={currentColor} />
    </svg>
  );
};
