import { Box, Button, IconButton, Popover, TextField, Typography } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { ErrorHelper, Tooltip } from 'shared/components';
import {
  CheckmarkIcon,
  CloneIcon,
  CrossIcon,
  LockIcon,
  PencilIcon,
  ThreeDotsIcon,
  TrashIcon
} from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { type FormDetails_formDetails_sections } from 'shared/hooks/useFormBuilder/__graphql__/FormDetails';
import { BASIC_INFO_SECTION } from './constants';
import { DeleteSectionModal } from './DeleteSectionModal';
import { getAllFormSections } from './utlis';

interface ChangeableSectionProps {
  name: string;
  setName: (_: string) => void;
}

const ChangeableSection: FC<ChangeableSectionProps> = ({ name, setName }) => {
  const { secondary } = usePalette();
  const { formatMessage } = useIntl();
  const [error, setError] = useState<null | string>(null);
  const { form, actionOnSection, setActionOnSection, createSection, section, updateSection } =
    useContext(FormBuilderContext);
  const isAdding = actionOnSection === 'add';
  const actionSectionControls = [
    {
      Icon: CheckmarkIcon,
      handler: async () => {
        if (name && name.length > 60) {
          setError('max_symbols');
        } else {
          await (isAdding
            ? createSection({ variables: { args: { formId: form?.id!, name } } })
            : updateSection({ variables: { id: section?.id!, data: { name } } }));
          setActionOnSection(null);
          setName('');
        }
      }
    },
    {
      Icon: CrossIcon,
      handler: () => {
        setActionOnSection(null);
      }
    }
  ].map(({ Icon, handler }, index) => (
    <Button
      key={index}
      sx={{
        backgroundColor: 'secondary.200',
        borderRadius: '8px',
        minWidth: '44px'
      }}
      onClick={handler}
    >
      <Icon color={secondary[700]} />
    </Button>
  ));

  return (
    <Box
      sx={{
        ...(isAdding
          ? { p: '16px', backgroundColor: 'secondary.50', border: `1px solid ${secondary[300]}`, borderRadius: '8px' }
          : {}),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '12px'
      }}
    >
      <Typography variant='body1'>{formatMessage({ id: isAdding ? 'new_section' : 'edit_section' })}</Typography>
      <Box sx={{ display: 'flex', gap: '4px', width: '100%' }}>
        <TextField
          label={formatMessage({ id: 'section_name' })}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        {actionSectionControls}
      </Box>
      {!!error && <ErrorHelper error={formatMessage({ id: error }, { amount: 60 })} />}
    </Box>
  );
};

export const SectionsList: FC = () => {
  const { secondary, primary, warning } = usePalette();
  const { formatMessage } = useIntl();
  const [newSectionName, setNewSectionName] = useState('');
  const {
    form,
    actionOnSection,
    setActionOnSection,
    setSection,
    setComponent,
    section,
    cloneSection,
    isReadOnly,
    formErrors
  } = useContext(FormBuilderContext);

  const sections = [BASIC_INFO_SECTION, ...getAllFormSections(form?.sections)] as FormDetails_formDetails_sections[];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        overflowY: 'auto'
      }}
    >
      {sections.map((i) => {
        const requiresAttention = !!formErrors[i.id];
        return (
          <Box
            onClick={() => {
              setSection(i);
              setComponent(null);
            }}
            key={i.id}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              p: '16px',
              backgroundColor: requiresAttention ? 'warning.50' : section?.id === i.id ? 'primary.200' : 'secondary.50',
              border: `1px solid ${
                requiresAttention ? warning[200] : section?.id === i.id ? primary[500] : secondary[300]
              }`,
              borderRadius: '8px',
              alignItems: 'flex-start',
              pl: i.parent ? '48px' : undefined
            }}
          >
            {actionOnSection === 'edit' && section?.id === i.id ? (
              <ChangeableSection name={newSectionName} setName={setNewSectionName} />
            ) : (
              <Box>
                <Typography variant='subtitle2'>{i.name}</Typography>
                <Typography variant='caption' sx={{ lineHeight: '18px', color: 'secondary.600' }}>
                  {formatMessage({ id: 'step' })} {i.parent ? `${i.parent.order + 1}.${i.order}` : i.order + 1}
                </Typography>
              </Box>
            )}
            {BASIC_INFO_SECTION.id === i.id ? (
              <Tooltip text={formatMessage({ id: 'standard_section' })} icon={<LockIcon closed />} />
            ) : (
              !isReadOnly && (
                <PopupState variant='popover' popupId='popover'>
                  {(popupState) => {
                    const { onClick, onTouchStart, ...ariaProps } = bindTrigger(popupState);
                    const { onClose, ...rest } = bindPopover(popupState);
                    return (
                      <>
                        <IconButton
                          {...ariaProps}
                          onClick={(event) => {
                            event.stopPropagation();
                            onClick(event);
                            setSection(i);
                          }}
                          onTouchStart={(event) => {
                            event.stopPropagation();
                            onTouchStart(event);
                          }}
                        >
                          <ThreeDotsIcon />
                        </IconButton>
                        <Popover
                          {...rest}
                          onClose={(e: any) => {
                            e.stopPropagation();
                            onClose();
                          }}
                          sx={{
                            '& .MuiPaper-rounded': { borderRadius: '8px', boxShadow: 'none' }
                          }}
                        >
                          <Box
                            sx={{
                              p: '16px',
                              display: 'flex',
                              gap: '16px',
                              border: `1px solid ${secondary[300]}`,
                              borderRadius: '8px'
                            }}
                          >
                            <IconButton
                              onClick={async () => {
                                await cloneSection({ variables: { id: i.id } });
                                onClose();
                              }}
                            >
                              <CloneIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setActionOnSection('edit');
                                setNewSectionName(i.name);
                                onClose();
                              }}
                            >
                              <PencilIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setActionOnSection('delete');
                                onClose();
                              }}
                            >
                              <TrashIcon color={primary[600]} />
                            </IconButton>
                          </Box>
                        </Popover>
                      </>
                    );
                  }}
                </PopupState>
              )
            )}
          </Box>
        );
      })}
      {actionOnSection === 'add' && <ChangeableSection name={newSectionName} setName={setNewSectionName} />}
      {actionOnSection === 'delete' && (
        <DeleteSectionModal
          cleanup={() => {
            setActionOnSection(null);
          }}
        />
      )}
    </Box>
  );
};
