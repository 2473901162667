import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const SuperAdminsIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 5C5.25 6.5 5.5 7.25 5.75 9C5.95 10.4 6 12.5835 6 13.5C4.9165 14 2.5 15 2.5 17C2.5 19 5 21.5 12 21.5C19 21.5 21.5 19 21.5 17C21.5 15 18 13.5 18 13.5C18 13.5 18 10.75 18.25 9C18.5 7.25 18.75 6.5 19 5'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 13.5C18 15.5 17.5 17.5 11.75 17.5'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 7.5C15.866 7.5 19 6.38071 19 5C19 3.61929 15.866 2.5 12 2.5C8.13401 2.5 5 3.61929 5 5C5 6.38071 8.13401 7.5 12 7.5Z'
        stroke={currentColor}
        strokeWidth='2'
      />
    </svg>
  );
};
