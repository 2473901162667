import gql from 'graphql-tag';

export const tenantsListQuery = gql`
  query TenantsList {
    tenants(orderBy: { field: name, direction: asc }, limit: 1000, offset: 0, filter: { setupFinished: true }) {
      data {
        id
        name
      }
    }
  }
`;
