import { useMutation, useQuery } from '@apollo/client';
import { type ErrorResponse } from '@apollo/client/link/error';
import {
  Box,
  FormControlLabel,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { AgencyOrderField, OrderDirection, Role, type AgencySave } from '__graphql__/globalTypes';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { inviteMutation } from 'shared/api';
import { type Invite, type InviteVariables } from 'shared/api/__graphql__/Invite';
import { ActionsPopup, ConfirmationDialog, EmptyTableBox, FormDialog, Loader, PageWrapper } from 'shared/components';
import { CustomCheckbox } from 'shared/components/CustomCheckbox';
import { SortIcon, TriangleListMarker } from 'shared/components/icons';
import { ErrorSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { StyledTablePagination } from 'shared/components/StyledTablePagination';
import { useCustomStyles, useFiltersQuery, usePalette, useTablePagination, useTableSort } from 'shared/hooks';
import { isConflictError } from 'shared/utils/errors';
import { type CreateAgency, type CreateAgencyVariables } from '../Users/__graphql__/CreateAgency';
import { createAgencyMutation } from '../Users/api';
import {
  type AgenciesQuery,
  type AgenciesQueryVariables,
  type AgenciesQuery_agencies_data
} from './__graphql__/AgenciesQuery';
import { type DeleteAgency, type DeleteAgencyVariables } from './__graphql__/DeleteAgency';
import { AddAgencyForm } from './AddAgencyWidget';
import { AddConsultantToAgencyForm } from './AddConsultantToAgencyForm';
import { agenciesQuery, deleteAgencyMutation } from './api';
import { AgencyTab } from '.';

interface AddConsultantToAgencyProps {
  name: string;
  email: string;
  agencyId: string;
  agency?: string;
}
interface AgenciesTableProps {
  query: string;
  selectAgency: (agencyId: string) => void;
  setQuery: (query: string) => void;
}

export const AgenciesTable: FC<AgenciesTableProps> = ({ query, selectAgency, setQuery }) => {
  const intl = useIntl();
  const { primary, secondary } = usePalette();
  const customStyles = useCustomStyles();
  const { state } = useLocation();

  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { field, direction, createSortHandler, setSort } = useTableSort<AgencyOrderField>({});
  const { parsedQuery, pushQuery } = useFiltersQuery();

  const [agencyForAction, setAgencyForAction] = useState<AgenciesQuery_agencies_data>();
  const [agencyForEdit, setAgencyForEdit] = useState<AgenciesQuery_agencies_data | undefined>();
  const [agencyForAddingConsultant, setAgencyForAddingConsultant] = useState<AgenciesQuery_agencies_data | undefined>();

  const [isDeletionWithoutConsultants, setIsDeletionWithoutConsultants] = useState(false);
  const agenciesResult = useQuery<AgenciesQuery, AgenciesQueryVariables>(agenciesQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
              field,
              direction
            }
          : null,
      offset,
      limit,
      query: query?.length > 2 ? query.trim() : null
    }
  });

  useEffect(() => {
    pushQuery({
      filters: [
        {
          id: 'tab',
          value: AgencyTab.agencies
        },
        ...(query
          ? [
              {
                id: 'search',
                value: query
              }
            ]
          : [])
      ],
      sort: [
        ...(field
          ? [
              {
                id: field,
                desc: direction === OrderDirection.desc
              }
            ]
          : [])
      ],
      pageIndex: tablePaginationProps.page
    });
  }, [direction, field, pushQuery, query, tablePaginationProps.page]);

  useEffect(() => {
    setSort(
      (parsedQuery?.sortBy ?? AgencyOrderField.name) as AgencyOrderField,
      (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
    );
    setQuery((parsedQuery?.search as string) ?? '');
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const isTableEmpty = useMemo(
    () => !agenciesResult.data?.agencies.data?.length && tablePaginationProps.page === 0,
    [agenciesResult.data?.agencies.data?.length, tablePaginationProps.page]
  );

  const [updateAgency] = useMutation<CreateAgency, CreateAgencyVariables>(createAgencyMutation, {
    refetchQueries: ['AgenciesQuery'],
    awaitRefetchQueries: true
  });

  const handleCloseDeletionModal = useCallback(() => {
    setAgencyForAction(undefined);
    setIsDeletionWithoutConsultants(false);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setAgencyForEdit(undefined);
  }, []);

  const handleUpdateAgency = useAsyncCallback(async ({ id, name, website, phone }: AgencySave) => {
    await updateAgency({ variables: { data: { id, name, website, phone } } }).then(() =>
      toast(<SuccessSnackbar title={intl.formatMessage({ id: 'the_agency_successfully_updated' })} />)
    );
    handleCloseEditModal();
  });

  const [inviteUser] = useMutation<Invite, InviteVariables>(inviteMutation, {
    refetchQueries: ['AgenciesQuery'],
    awaitRefetchQueries: true,
    context: {
      uri: `${process.env.REACT_APP_API_AUTH_BASE}graphql`
    }
  });

  const handleCloseAddConsultantModal = useCallback(() => {
    setAgencyForAddingConsultant(undefined);
  }, []);

  const handleAddConsultantToAgency = useAsyncCallback(
    async ({ name, email, agencyId }: AddConsultantToAgencyProps) => {
      const [firstName = '', lastName = ''] = name.split(' ');
      await inviteUser({
        variables: {
          data: {
            firstName,
            lastName,
            email,
            agencyId,
            role: Role.CONSULTANT
          }
        }
      }).then(() => {
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'new_consultant_successfully_added' })}
            message={intl.formatMessage({ id: 'consultant_has_been_successfully_created' })}
          />
        );
        handleCloseAddConsultantModal();
      });
    }
  );

  const [deleteAgency] = useMutation<DeleteAgency, DeleteAgencyVariables>(deleteAgencyMutation, {
    refetchQueries: ['AgenciesQuery'],
    awaitRefetchQueries: true
  });

  const actionAgencyHandler = useCallback(async () => {
    await deleteAgency({ variables: { id: agencyForAction!.id, withConsultants: !isDeletionWithoutConsultants } }).then(
      () =>
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'the_agency_successfully_deleted' })}
            message={intl.formatMessage({ id: 'agency_data_was_successfully_removed_from_the_system' })}
          />
        )
    );
    handleCloseDeletionModal();
  }, [deleteAgency, agencyForAction, isDeletionWithoutConsultants, handleCloseDeletionModal, intl]);

  return (
    <>
      {agenciesResult.loading || handleUpdateAgency.loading ? (
        <Loader />
      ) : (
        <PageWrapper>
          <>
            <TableContainer>
              <Table
                sx={{
                  '& tr': {
                    height: 'auto',
                    display: 'grid'
                  },
                  '& > thead tr': {
                    gridTemplateColumns: '13fr 11fr 12fr 8fr 8fr 5fr'
                  },
                  '& > tbody tr': {
                    gridTemplateColumns: isTableEmpty ? 'auto' : '13fr 11fr 12fr 8fr 8fr 5fr'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(AgencyOrderField.name)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'agency' })}
                        <SortIcon active={field === AgencyOrderField.name} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(AgencyOrderField.website)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'website' })}
                        <SortIcon active={field === AgencyOrderField.website} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(AgencyOrderField.phone)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'telephone' })}
                        <SortIcon active={field === AgencyOrderField.phone} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(AgencyOrderField.consultants)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'consultants' })}
                        <SortIcon active={field === AgencyOrderField.consultants} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(AgencyOrderField.registrations)} hideSortIcon={true}>
                        {intl.formatMessage({ id: 'registrations' })}
                        <SortIcon active={field === AgencyOrderField.registrations} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>{intl.formatMessage({ id: 'action' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    '& td': {
                      lineHeight: '28px',
                      '&, & span': {
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }
                  }}
                >
                  {agenciesResult.data?.agencies.data?.map((agency) => (
                    <TableRow
                      key={agency.id}
                      sx={{
                        '& a': {
                          color: secondary[700],
                          fontWeight: '400',
                          textDecoration: 'underline',
                          '&:hover': {
                            color: primary[600]
                          },
                          '&:active': {
                            color: primary[800]
                          }
                        }
                      }}
                    >
                      <TableCell>
                        <Highlighter
                          autoEscape
                          highlightStyle={customStyles.highlighter}
                          searchWords={[`${query}`]}
                          textToHighlight={agency.name ?? ''}
                        />
                      </TableCell>
                      <TableCell>
                        {agency.website ? (
                          <Link href={agency?.website || ''} target='blank'>
                            {agency.website}
                          </Link>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>{agency.phone}</TableCell>
                      <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Link
                          onClick={() => {
                            selectAgency(agency.id);
                          }}
                        >
                          {agency.consultants?.length}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                        {agency.registrations?.length}
                      </TableCell>
                      <TableCell sx={{ '&, & span': { display: 'block' } }}>
                        <ActionsPopup
                          items={[
                            {
                              id: 'edit',
                              onClick: () => {
                                setAgencyForEdit(agency);
                              }
                            },
                            {
                              id: 'delete',
                              onClick: () => {
                                setAgencyForAction(agency);
                              }
                            },
                            {
                              id: 'add_consultant',
                              onClick: () => {
                                setAgencyForAddingConsultant(agency);
                              }
                            }
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {isTableEmpty && (
                    <TableRow
                      style={{
                        height: 64 * tablePaginationProps.rowsPerPage,
                        background: 'inherit'
                      }}
                    >
                      <TableCell
                        colSpan={5}
                        style={{
                          height: 64 * tablePaginationProps.rowsPerPage,
                          padding: 0
                        }}
                      >
                        <EmptyTableBox />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <StyledTablePagination count={agenciesResult.data?.agencies.total ?? 0} {...tablePaginationProps} />
            <ConfirmationDialog
              open={!!agencyForAction}
              onClose={handleCloseDeletionModal}
              onConfirm={actionAgencyHandler}
              confirmButtonContent={intl.formatMessage({ id: 'delete' })}
              title={intl.formatMessage({ id: 'agency_removal' })}
              content={
                <Typography variant='body2' fontWeight={400} color='secondary.800'>
                  <Typography mb={4}>
                    {intl.formatMessage(
                      {
                        id: 'tenant_deletion_title'
                      },
                      { tenant: agencyForAction?.name }
                    )}
                  </Typography>
                  <Box display='flex' flexDirection='column' gap={2} mb={4}>
                    {[
                      intl.formatMessage({ id: 'agency_deletion_points.0' }),
                      intl.formatMessage({ id: 'agency_deletion_points.1' })
                    ].map((prompt, index) => (
                      <Box key={index} display='flex' gap={2}>
                        <Box><TriangleListMarker /></Box>
                        <Typography>{prompt}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <FormControlLabel
                    sx={{ margin: 0, color: secondary[800], '& > span': { padding: 0 } }}
                    value={isDeletionWithoutConsultants}
                    onChange={() => {
                      setIsDeletionWithoutConsultants((prev) => !prev);
                    }}
                    disabled={!agencyForAction?.consultants?.length}
                    control={<CustomCheckbox checked={isDeletionWithoutConsultants} />}
                    label={intl.formatMessage({ id: 'leave_the_consultants_in_the_system' })}
                  />
                </Typography>
              }
            />
            <FormDialog
              isOpen={!!agencyForEdit}
              onClose={handleCloseEditModal}
              header={intl.formatMessage({ id: 'edit_agency' })}
              size='xs'
              loading={handleUpdateAgency.loading}
              confirmBtnName={intl.formatMessage({ id: 'save' })}
              initialValues={agencyForEdit}
              onSubmit={async (agency: AgencySave) => {
                await handleUpdateAgency.execute(agency);
              }}
            >
              <AddAgencyForm />
            </FormDialog>
            <FormDialog
              isOpen={!!agencyForAddingConsultant}
              onClose={handleCloseAddConsultantModal}
              header={intl.formatMessage({ id: 'new_consultant' })}
              size='xs'
              loading={handleAddConsultantToAgency.loading}
              confirmBtnName={intl.formatMessage({ id: 'save_and_send_email' })}
              initialValues={{
                name: '',
                email: '',
                agencyId: agencyForAddingConsultant?.id,
                agency: agencyForAddingConsultant?.name
              }}
              onSubmit={async (consultant: AddConsultantToAgencyProps, formikHelpers) => {
                try {
                  await handleAddConsultantToAgency.execute(consultant);
                } catch (e) {
                  if (isConflictError((e as ErrorResponse).graphQLErrors?.[0].extensions.code as string)) {
                    toast(
                      <ErrorSnackbar
                        title={intl.formatMessage({ id: 'email_alredy_in_use' })}
                        message={intl.formatMessage({ id: 'you_cant_create_user_with_existing_email' })}
                      />,
                      {
                        position: toast.POSITION.BOTTOM_CENTER
                      }
                    );
                    formikHelpers?.setFieldError('email', ' ');
                  }
                }
              }}
            >
              <AddConsultantToAgencyForm />
            </FormDialog>
          </>
        </PageWrapper>
      )}
    </>
  );
};
