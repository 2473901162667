import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from '@mui/material';
import { OrderDirection, RelationshipOrderField } from '__graphql__/globalTypes';
import { type MakeChildOf, type MakeChildOfVariables } from 'admin/pages/Forms/__graphql__/MakeChildOf';
import { type MakeSeparated, type MakeSeparatedVariables } from 'admin/pages/Forms/__graphql__/MakeSeparated';
import { type Relationships, type RelationshipsVariables } from 'admin/pages/Forms/__graphql__/Relationships';
import { type SetParentFor, type SetParentForVariables } from 'admin/pages/Forms/__graphql__/SetParentFor';
import {
  getRelationshipsQuery,
  setParentForMutation,
  makeChildOfMutation,
  makeSeparatedMutation
} from 'admin/pages/Forms/api';
import { Fragment, useContext, useEffect, useState, type Dispatch, type FC, type SetStateAction, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CustomCheckbox, EmptyTableBox, Loader, StyledTablePagination, Tooltip } from 'shared/components';
import { CheckmarkIcon, CrossIcon, PencilIcon, SortIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette, useTablePagination, useTableSort } from 'shared/hooks';
import { type FormDetails_formDetails_sections } from 'shared/hooks/useFormBuilder/__graphql__/FormDetails';

const RELATIONSHIP_OPTIONS = ['separate_section', 'parent_section', 'child_section'];

type SectionRelationships = 'separate_section' | 'parent_section' | 'child_section';

interface RelationshipProps {
  readOnly?: boolean;
}

interface RelationshipDialogProps {
  relationshipValues: string[];
  setRelationshipValue: Dispatch<SetStateAction<string[]>>;
  closeModal: () => void;

  asParent?: boolean;
}

const RelationshipDialog: FC<RelationshipDialogProps> = ({
  asParent = false,
  relationshipValues,
  setRelationshipValue,
  closeModal
}) => {
  const { formatMessage } = useIntl();
  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { createSortHandler, field, direction } = useTableSort<RelationshipOrderField>({
    defaultField: RelationshipOrderField.name,
    defaultDirection: OrderDirection.asc
  });
  const { form, section } = useContext(FormBuilderContext);

  const { data, loading } = useQuery<Relationships, RelationshipsVariables>(getRelationshipsQuery, {
    variables: {
      filters: { field, direction, offset, limit, sectionId: section?.id, formId: form?.id! }
    } as RelationshipsVariables
  });

  return (
    <Dialog
      open={true}
      fullWidth
      onClose={closeModal}
      maxWidth='md'
      sx={{ '& .MuiPaper-root': { borderRadius: '16px', position: 'initial' } }}
    >
      <DialogTitle sx={{ padding: '24px 24px 16px' }}>
        <Typography variant='h5' color='secondary.800'>
          {formatMessage({ id: asParent ? 'select_sections' : 'select_parent' })}
        </Typography>
        <IconButton onClick={closeModal}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(RelationshipOrderField.name)} hideSortIcon={true}>
                        {formatMessage({ id: 'section_name' })}
                        <SortIcon active={field === RelationshipOrderField.name} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel onClick={createSortHandler(RelationshipOrderField.variable)} hideSortIcon={true}>
                        {formatMessage({ id: 'section_variable' })}
                        <SortIcon active={field === RelationshipOrderField.variable} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        onClick={createSortHandler(RelationshipOrderField.description)}
                        hideSortIcon={true}
                      >
                        {formatMessage({ id: 'description' })}
                        <SortIcon active={field === RelationshipOrderField.description} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        onClick={createSortHandler(RelationshipOrderField.relationship)}
                        hideSortIcon={true}
                      >
                        {formatMessage({ id: 'relationship' })}
                        <SortIcon active={field === RelationshipOrderField.relationship} direction={direction} />
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.relationships.data?.map((item) => (
                    <TableRow key={item.id} sx={{ cursor: 'pointer' }}>
                      <TableCell>
                        {asParent ? (
                          <CustomCheckbox
                            checked={relationshipValues.includes(item.id)}
                            onClick={({ target }) => {
                              const input = target as HTMLInputElement;
                              setRelationshipValue((prev) =>
                                input.checked ? [...prev, item.id] : prev.filter((id) => id !== item.id)
                              );
                            }}
                          />
                        ) : (
                          <Radio
                            checked={relationshipValues.includes(item.id)}
                            onClick={() => {
                              setRelationshipValue([item.id]);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.variable}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{formatMessage({ id: item.relationshipType })}</TableCell>
                    </TableRow>
                  ))}
                  {!data?.relationships.data?.length && tablePaginationProps.page === 0 && (
                    <TableRow
                      style={{
                        height: 64 * tablePaginationProps.rowsPerPage,
                        background: 'inherit'
                      }}
                    >
                      <TableCell
                        colSpan={7}
                        style={{
                          height: 64 * tablePaginationProps.rowsPerPage,
                          padding: 0
                        }}
                      >
                        <EmptyTableBox />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <StyledTablePagination count={data?.relationships.total ?? 0} {...tablePaginationProps} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          size='medium'
          variant='text'
          onClick={() => {
            const initialValues = section?.children?.map(({ id }) => id) ?? [];
            setRelationshipValue(initialValues);
            closeModal();
          }}
        >
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button size='medium' variant='contained' onClick={closeModal}>
          {formatMessage({ id: 'save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getRelationshipValue = (section: FormDetails_formDetails_sections | undefined): string[] => {
  return section?.parent?.id ? [section.parent.id] : section?.children?.map(({ id }) => id) ?? [];
};

const getTypeOfRelation = (section: FormDetails_formDetails_sections | undefined): SectionRelationships => {
  const isParent = !!section?.children?.length;
  const typeOfRelation = section?.parent ? 'child_section' : isParent ? 'parent_section' : 'separate_section';
  return typeOfRelation;
};

export const RelationshipPropertyBlock: FC<RelationshipProps> = (props) => {
  const { formatMessage } = useIntl();
  const { secondary, primary } = usePalette();
  const { readOnly } = props;
  const [isEdit, setIsEdit] = useState(false);
  const { form, section } = useContext(FormBuilderContext);
  const typeOfRelation = getTypeOfRelation(section);
  const [fieldValue, setFieldValue] = useState<SectionRelationships>(typeOfRelation);
  const [relationshipValue, setRelationshipValue] = useState<string[]>(getRelationshipValue(section));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [setParentFor] = useMutation<SetParentFor, SetParentForVariables>(setParentForMutation, {
    refetchQueries: ['FormDetails'],
    awaitRefetchQueries: true
  });

  const [makeChildOf] = useMutation<MakeChildOf, MakeChildOfVariables>(makeChildOfMutation, {
    refetchQueries: ['FormDetails'],
    awaitRefetchQueries: true
  });

  const [makeSeparated] = useMutation<MakeSeparated, MakeSeparatedVariables>(makeSeparatedMutation, {
    refetchQueries: ['FormDetails'],
    awaitRefetchQueries: true
  });

  const displayedRelationships = useMemo(
    () =>
      form?.sections.reduce<string[]>((result, current) => {
        if (relationshipValue.includes(current.id)) {
          result.push(current.name);
        } else if (current.children) {
          current.children.forEach(({ id, name }) => {
            if (relationshipValue.includes(id)) {
              result.push(name);
            }
          });
        }
        return result;
      }, []),
    [form?.sections, relationshipValue]
  );

  useEffect(() => {
    setRelationshipValue(getRelationshipValue(section));
    setFieldValue(typeOfRelation);
    setIsEdit(false);
  }, [section, typeOfRelation]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: isEdit ? 'column' : 'row',
        gap: isEdit ? '12px' : '0px',
        background: 'secondary.50',
        borderRadius: '8px',
        p: '16px',
        border: `1px solid ${secondary[200]}`
      }}
    >
      {isEdit ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant='body1' sx={{ color: 'secondary.600' }}>
              {formatMessage({ id: 'relationship' })}
            </Typography>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <IconButton
                onClick={async () => {
                  switch (fieldValue) {
                    case 'child_section':
                      await makeChildOf({
                        variables: { childId: section?.id!, parentId: relationshipValue[0] }
                      });
                      break;
                    case 'parent_section':
                      await setParentFor({
                        variables: { childrenIds: relationshipValue, parentId: section?.id! }
                      });
                      break;
                    case 'separate_section':
                      await makeSeparated({ variables: { id: section?.id! } });
                      break;
                  }
                  setIsEdit(false);
                }}
              >
                <CheckmarkIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setFieldValue(getTypeOfRelation(section));
                  setIsEdit(false);
                }}
              >
                <CrossIcon color={primary[600]} />
              </IconButton>
            </Box>
          </Box>
          <Select
            renderValue={(v) => formatMessage({ id: v })}
            label={formatMessage({ id: 'relationship' })}
            value={fieldValue}
            onChange={(e) => {
              setFieldValue(e.target.value as SectionRelationships);
              setRelationshipValue([]);
            }}
          >
            {RELATIONSHIP_OPTIONS.map((i) => (
              <MenuItem value={i} key={i}>
                {formatMessage({ id: i })}
              </MenuItem>
            ))}
          </Select>
          {fieldValue !== 'separate_section' && (
            <TextField
              label={formatMessage({ id: 'relationship' })}
              value={displayedRelationships?.join(', ')}
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          )}
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography variant='body1' sx={{ color: 'secondary.600' }}>
              {formatMessage({ id: 'relationship' })}
            </Typography>
            <Typography sx={{ color: 'secondary.700' }}>{formatMessage({ id: fieldValue })}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            {!readOnly && (
              <IconButton
                onClick={() => {
                  setFieldValue(typeOfRelation);
                  setIsEdit(true);
                }}
              >
                <PencilIcon />
              </IconButton>
            )}
            <Tooltip
              text={
                <>
                  {formatMessage({ id: 'section_tooltips.relationship' })
                    .split('\n')
                    .map((line, index) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                </>
              }
            />
          </Box>
        </>
      )}
      {isModalOpen && (
        <RelationshipDialog
          relationshipValues={relationshipValue}
          asParent={fieldValue === 'parent_section'}
          setRelationshipValue={setRelationshipValue}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </Box>
  );
};
