import { Role } from '__graphql__/globalTypes';
import { type FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import routes from 'shared/constants/routes';
import AuthContext, { isAnonymous, isAuthenticated, type AuthContextBase } from 'shared/contexts/authContext';
import AdminApp from '../../admin/App';
import ConsultantApp from '../../consultant/App';
import ContractorApp from '../../contractor/App';
import SuperAdminApp from '../../superAdmin/App';
import { LoginLayout } from './LoginLayout';

interface RouteProps {
  children: JSX.Element;
}

interface MountRouteProps {
  mount: boolean;
  role?: Role;
}

const MountRoute: FC<MountRouteProps> = ({ mount, role }) => {
  if (mount) {
    switch (role) {
      case Role.CONTRACTOR:
        return <ContractorApp />;
      case Role.ADMIN:
      case Role.CUSTOMER_CARE:
      case Role.REGISTRAR:
        return <AdminApp />;
      case Role.SUPER_ADMIN:
        return <SuperAdminApp />;
      case Role.CONSULTANT:
        return <ConsultantApp />;
      default:
        return <Navigate to={routes.login} />;
    }
  } else return <Navigate to={routes.login} />;
};

export const UserRoute = (): JSX.Element => {
  const authContext = useContext<AuthContextBase>(AuthContext);
  const isUserAuthenticated = isAuthenticated(authContext);
  return <MountRoute role={authContext.authState.user?.role} mount={isUserAuthenticated} />;
};

export const AnonymousRoute: FC<RouteProps> = ({ children }) => {
  const authContext = useContext<AuthContextBase>(AuthContext);
  const isUserAnonymous = isAnonymous(authContext);

  return isUserAnonymous ? <LoginLayout>{children}</LoginLayout> : <Navigate to={routes.root + '/'} />;
};
