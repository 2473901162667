import { Box, Button, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { TriangleMarkeredList } from 'shared/components';
import { CrossIcon } from 'shared/components/icons';
import { localStorageUtils } from 'shared/utils/local-storage';
import { FormBuilderIntro } from './FormBuildIntro';
import { Form, FormWidgetContent } from './FormWidgetContent';

export const NewFormWidget: FC = () => {
  const localStorageValue = localStorageUtils.getItem<boolean>('hideFormBuilderIntro');
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIntroHidden, setIsIntroHidden] = useState(typeof localStorageValue === 'object' ? false : localStorageValue);

  return (
    <Box>
      <Button sx={{ width: '140px' }} variant='contained' onClick={() => setIsModalOpen(true)}>
        {formatMessage({ id: 'new_form' })}
      </Button>
      <Dialog
        fullWidth
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth='md'
        sx={{ '& .MuiPaper-root': { borderRadius: '16px', position: 'initial' } }}
      >
        <DialogTitle sx={{ padding: '24px 24px 16px' }}>
          <Typography variant={isIntroHidden ? 'h5' : 'h1'} color='secondary.800'>
            {formatMessage({ id: isIntroHidden ? 'add_new_form' : 'form_builder' })}
          </Typography>
          <IconButton onClick={() => setIsModalOpen(false)}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        {isIntroHidden ? (
          <FormWidgetContent
            mode='add'
            initialValues={{ name: '', description: '' }}
            closeModal={() => setIsModalOpen(false)}
          >
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Box sx={{ maxWidth: '50%' }}>
                <Typography variant='body1'>{formatMessage({ id: 'consider_options' })}</Typography>
                <TriangleMarkeredList
                  sx={{ justifyContent: 'flex-start' }}
                  itemsIntlIds={['new_form_options.1', 'new_form_options.2', 'new_form_options.3']}
                />
              </Box>
              <Form />
            </Box>
          </FormWidgetContent>
        ) : (
          <FormBuilderIntro next={() => setIsIntroHidden(true)} closeModal={() => setIsModalOpen(false)} />
        )}
      </Dialog>
    </Box>
  );
};
