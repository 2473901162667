import gql from 'graphql-tag';

export const agenciesQuery = gql`
  query AgenciesQuery(
    $offset: Int!
    $limit: Int!
    $orderBy: AgencyOrder
    $query: String
  ) {
    agencies(limit: $limit, offset: $offset, orderBy: $orderBy, query: $query) {
      total
      data {
        id
        name
        website
        phone
        consultants {
          id
        }
        registrations {
          id
        }
      }
    }
  }
`;

export const deleteAgencyMutation = gql`
  mutation DeleteAgency($id: String!, $withConsultants: Boolean) {
    deleteAgency(id: $id, withConsultants: $withConsultants) {
      id
    }
  }
`;

export const consultantsQuery = gql`
  query Consultants(
    $offset: Int!
    $limit: Int!
    $orderBy: UserOrder
    $query: String
    $agencyId: String
  ) {
    users(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      filter: { roles: CONSULTANT, agencyId: $agencyId }
      query: $query
    ) {
      total
      data {
        id
        email
        firstName
        lastName
        isActive
        lastLogin
        agency {
          id
          name
        }
        consultantRegistrations {
          id
        }
      }
    }
  }
`;
