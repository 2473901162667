import { useQuery } from '@apollo/client';
import { DocumentType } from '__graphql__/globalTypes';
import { omit } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { type ProfileContext } from 'shared/contexts';
import { type ProfilePhoto, type ProfilePhotoVariables } from './__graphql__/ProfilePhoto';
import { type ProfilePhotoUrl, type ProfilePhotoUrlVariables } from './__graphql__/ProfilePhotoUrl';
import { type ProfileUserData } from './__graphql__/ProfileUserData';
import { profilePhotoQuery, profilePhotoUrlQuery, profileUserQuery } from './api';

const INIT_STATE: ProfileContext['user'] = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  avatarUrl: '',
  documentId: ''
} as const;

export function useProfileInfo(): ProfileContext {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [user, setUser] = useState<ProfileContext['user']>(INIT_STATE);

  const { data: profileInfoData } = useQuery<ProfileUserData>(profileUserQuery, {
    context: API_CONTEXT.AUTH,
    skip: user.id !== INIT_STATE.id
  });

  const { data: photoDocumentData } = useQuery<ProfilePhoto, ProfilePhotoVariables>(profilePhotoQuery, {
    variables: { documentFilter: { relatedId: user.id, documentType: DocumentType.PROFILE_PHOTO } },
    context: API_CONTEXT.DOCUMENT,
    skip: !!user.documentId || !user.id
  });

  const { data: photoUrlData } = useQuery<ProfilePhotoUrl, ProfilePhotoUrlVariables>(profilePhotoUrlQuery, {
    variables: { id: photoDocumentData?.document?.id! },
    context: API_CONTEXT.DOCUMENT,
    skip: !photoDocumentData?.document?.id
  });

  useEffect(() => {
    const userData = profileInfoData?.activeUser;
    if (userData) {
      setUser((prevUser) => ({ ...prevUser, ...omit(['__typename'], userData) } as ProfileContext['user']));
    }

    const photoUrl = photoUrlData?.avatarUrl;
    if (photoUrl) {
      setUser((prevUser) => ({ ...prevUser, avatarUrl: photoUrl, documentId: photoDocumentData?.document?.id! }));
    }
  }, [profileInfoData?.activeUser, photoUrlData?.avatarUrl, photoDocumentData?.document?.id]);

  return {
    user,
    isModalOpened,
    closeModal: useCallback(() => { setIsModalOpened(false); }, []),
    openModal: useCallback(() => { setIsModalOpened(true); }, []),
    updateUserInfo: useCallback((data) => { setUser((user) => ({ ...user, ...data })); }, [])
  };
}
