import gql from 'graphql-tag';

export const contractorEmailsQuery = gql`
  query ContractorEmails($userId: String!) {
    userEmails(userId: $userId) {
      id
      to
      subject
      content
      createdAt
      sentBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const sendContractorEmailMutation = gql`
  mutation SendContractorEmail($data: CreateUserEmail!) {
    sendAndSaveUserEmail(data: $data) {
      id
    }
  }
`;

export const contractorRegistrationsQuery = gql`
  query ViewContractorRegistrations(
    $offset: Int!
    $limit: Int!
    $orderBy: RegistrationOrder
    $filter: RegistrationFilter
  ) {
    registrations(limit: $limit, offset: $offset, orderBy: $orderBy, filter: $filter) {
      data {
        id
        form {
          contractType
        }
        status
        createdAt
      }
    }
  }
`;
