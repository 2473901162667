import { Box, Button, ClickAwayListener, IconButton, TextField } from '@mui/material';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { usePalette } from 'shared/hooks';
import { isValidDate } from 'shared/utils/date';
import { Validator } from 'shared/utils/validator';
import { CalendarIcon } from './icons';
import { Input } from './Input';

export const CalendarRangeFormControl: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { primary, secondary, info } = usePalette();
  const { formatMessage } = useIntl();
  const {
    initialValues,
    values: { startDate, dueDate },
    setFieldValue
  } = useFormikContext<Record<string, any>>();

  const startDateInitialValue = initialValues.startDate ?? new Date();
  const dueDateInitialValue = initialValues.dueDate ?? new Date();

  const onCancel = (): void => {
    setFieldValue('startDate', startDateInitialValue);
    setFieldValue('dueDate', dueDateInitialValue);
    setIsOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
      <Field
        id='startDate'
        name='startDate'
        validate={Validator.pipe(Validator.methods.required())}
        label={formatMessage({ id: 'start_date' })}
        component={Input}
        value={dayjs(startDate).format('DD/MM/YYYY')}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton onClick={() => setIsOpen(true)}>
              <CalendarIcon />
            </IconButton>
          )
        }}
      />
      <Field
        id='dueDate'
        name='dueDate'
        value={dayjs(dueDate).format('DD/MM/YYYY')}
        validate={Validator.pipe(Validator.methods.required())}
        label={formatMessage({ id: 'due_date' })}
        component={Input}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton onClick={() => setIsOpen(true)}>
              <CalendarIcon />
            </IconButton>
          )
        }}
      />
      {isOpen && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ClickAwayListener
            onClickAway={() => {
              setIsOpen(false);
            }}
          >
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                left: 'calc(50% - 321px)',
                top: 'calc(50% + 40px)',
                zIndex: 2,
                background: '#FFF',
                border: `1px solid ${primary[600]}`,
                '& > div:first-of-type': {
                  borderRight: `1px solid ${primary[600]}`
                },
                '& .MuiDayPicker-header': {
                  borderBottom: `1px solid ${info[500]}`,
                  margin: '0 16px 8px',
                  '& > span': {
                    color: secondary[600],
                    fontSize: '16px'
                  }
                }
              }}
            >
              <Box>
                <StaticDatePicker
                  inputFormat='DD/MM/YYYY'
                  mask='__/__/____'
                  displayStaticWrapperAs='desktop'
                  value={startDate}
                  onChange={(input) => {
                    if (isValidDate(input)) {
                      setFieldValue('startDate', dayjs(input).startOf('day').toDate());
                    }
                  }}
                  renderInput={(props: any) => <TextField {...props} />}
                  shouldDisableDate={(day) => dayjs(day).isAfter(dueDate)}
                  renderDay={(_day, _value, DayComponentProps) => (
                    <PickersDay
                      {...DayComponentProps}
                      disableHighlightToday
                      showDaysOutsideCurrentMonth
                      className='pickers-day'
                    />
                  )}
                />
                <Button variant='text' onClick={onCancel} sx={{ margin: '12px 16px 16px' }}>
                  {formatMessage({ id: 'cancel' })}
                </Button>
              </Box>
              <Box>
                <StaticDatePicker
                  inputFormat='DD/MM/YYYY'
                  mask='__/__/____'
                  displayStaticWrapperAs='desktop'
                  value={dueDate}
                  onChange={(input) => {
                    setFieldValue('dueDate', dayjs(input).endOf('day').toDate());
                  }}
                  renderInput={(props: any) => <TextField {...props} />}
                  shouldDisableDate={(day) => dayjs(day).isBefore(startDate)}
                  renderDay={(_day, _value, DayComponentProps) => (
                    <PickersDay
                      {...DayComponentProps}
                      disableHighlightToday
                      showDaysOutsideCurrentMonth
                      className='pickers-day'
                    />
                  )}
                />
                <Button
                  onClick={() => setIsOpen(false)}
                  variant='outlined'
                  sx={{ margin: '12px 16px 16px', float: 'right' }}
                >
                  {formatMessage({ id: 'apply' })}
                </Button>
              </Box>
            </Box>
          </ClickAwayListener>
        </LocalizationProvider>
      )}
    </Box>
  );
};
