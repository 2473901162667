import { Box } from '@mui/material';
import { type OptionInput } from '__graphql__/globalTypes';
import { FILE_FORMATS_OPTIONS } from 'admin/pages/Forms/utils';
import { FieldArray } from 'formik';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { CheckboxRenderer, PropertyBlock } from '../shared';
import { type ComponentPropertiesProps } from '../shared/types';

export const FileFormatsProperty: FC<ComponentPropertiesProps> = ({ onSubmit, isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  return (
    <PropertyBlock
      readOnly
      onSubmit={({ options }: { options: OptionInput[] }) => {
        onSubmit({
          options: options.map(({ optionId, label, value }) => ({
            optionId,
            label,
            value: (typeof value === 'boolean' && value) || value === 'true' ? 'true' : 'false'
          }))
        });
      }}
      title={formatMessage({ id: 'component_properties.file_formats' })}
      value={{
        options: (component?.options ?? []).map(({ label, value, optionId }) => ({
          label,
          optionId,
          value: value === 'true'
        }))
      }}
      tooltip={formatMessage({ id: 'component_tooltips.file_formats' })}
      ViewComponent={
        <FieldArray
          name='options'
          render={() => (
            <>
              <Box>
                {FILE_FORMATS_OPTIONS.map(({ label }, index) => (
                  <CheckboxRenderer key={label} isReadOnly={isReadOnly} label={label} name={`options.${index}.value`} />
                ))}
              </Box>
            </>
          )}
        />
      }
    />
  );
};
