import { Box, ThemeProvider } from '@mui/material';
import { ChangeEmail, ForgotPassword, InviteConfirm, Login, NewReferral, ResetPassword } from 'pages';
import { useMemo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnonymousRoute, Loader, LoginLayout, UserRoute } from 'shared/components';
import routes from 'shared/constants/routes';
import { CompanyContext } from 'shared/contexts';
import AuthContext from 'shared/contexts/authContext';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { useAuthentication, useRegistration } from 'shared/hooks';
import { useCompany } from 'shared/hooks/useCompany';
import { setupTheme } from 'shared/theme';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function RoutedApp(): JSX.Element {
  const {
    isOpened,
    id,
    registration,
    loading,
    setId,
    save,
    openModal,
    openModalWithId,
    closeModal,
    sendAnswer,
    documents,
    completenessOfRegistration,
    isDirty,
    setIsDirty,
    basicInfo,
    openWithBasicInfo,
    clearBasicInfo,
    sections,
    answers,
    setPreviewForm,
    previewForm,
    previewFormValues,
    setPreviewFormValues,
    rules,
    setSectionValues,
    combinedAnswers,
    invalidSections,
    setInvalidSections
  } = useRegistration();

  return (
    <Routes>
      {/* Unauthenticated routes */}
      <Route
        path={routes.login}
        element={
          <AnonymousRoute>
            <Login />
          </AnonymousRoute>
        }
      />
      <Route
        path={routes.forgotPassword}
        element={
          <AnonymousRoute>
            <ForgotPassword />
          </AnonymousRoute>
        }
      />
      <Route
        path={routes.resetPassword}
        element={
          <AnonymousRoute>
            <ResetPassword />
          </AnonymousRoute>
        }
      />
      {/* Confirm routes */}
      <Route
        path={routes.inviteConfirm}
        element={
          <LoginLayout>
            <InviteConfirm />
          </LoginLayout>
        }
      />
      <Route
        path={routes.changeEmail}
        element={
          <LoginLayout>
            <ChangeEmail />
          </LoginLayout>
        }
      />
      <Route
        path={routes.newReferral}
        element={
          <LoginLayout>
            <NewReferral />
          </LoginLayout>
        }
      />
      <Route
        path={`${routes.root}*`}
        element={
          <RegistrationContext.Provider
            value={{
              rules,
              isOpened,
              id,
              registration,
              loading,
              basicInfo,
              clearBasicInfo,
              openWithBasicInfo,
              setId,
              save,
              openModal,
              openModalWithId,
              closeModal,
              sendAnswer,
              documents,
              completenessOfRegistration,
              isDirty,
              setIsDirty,
              sections,
              answers,
              setPreviewForm,
              previewForm,
              previewFormValues,
              setPreviewFormValues,
              setSectionValues,
              combinedAnswers,
              setInvalidSections,
              invalidSections
            }}
          >
            <UserRoute />
          </RegistrationContext.Provider>
        }
      />
    </Routes>
  );
}

function App(): JSX.Element {
  const {
    authState,
    rehydrated,
    login,
    inviteConfirm,
    logout,
    forgotPassword,
    resetPassword,
    changeEmail,
    fetchCurrentUser,
    error,
    errorCode,
    setError
  } = useAuthentication();

  const companyContextValues = useCompany();

  const theme = useMemo(
    () => setupTheme({ themeColor: companyContextValues.themeColor, font: companyContextValues.font }),
    [companyContextValues.font, companyContextValues.themeColor]
  );

  if (!rehydrated || companyContextValues.initialLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Loader sx={{ maxWidth: '100px', maxHeight: '100px' }} />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
        <CompanyContext.Provider value={companyContextValues}>
          <AuthContext.Provider
            value={{
              authState,
              login,
              inviteConfirm,
              logout,
              forgotPassword,
              resetPassword,
              changeEmail,
              fetchCurrentUser,
              error,
              errorCode,
              setError
            }}
          >
            <RoutedApp />
          </AuthContext.Provider>
        </CompanyContext.Provider>
      </GoogleReCaptchaProvider>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme='colored'
        bodyStyle={{ padding: 0 }}
        toastStyle={{ padding: 0, borderRadius: '8px', boxShadow: 'none', minHeight: 'unset' }}
        closeButton={false}
        className='toast-container'
      />
    </ThemeProvider>
  );
}

export default App;
