import { useQuery } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { type CountOfIssuerRules, type CountOfIssuerRulesVariables } from '../../__graphql__/CountOfIssuerRules';
import { countOfRules } from '../../api';
import { RuleBuilder } from '../../RuleBuilder';
import { BASIC_INFO_SECTION, TEXT_COMPONENTS } from '../constants';
import { DeleteSectionModal } from '../DeleteSectionModal';
import { WidgetCard } from '../WidgetCard';
import { ComponentProperties } from './ComponentProperties';
import { SectionProperties } from './SectionProperties';

export const Properties: FC = () => {
  const { section, component, isReadOnly, deleteQuestion, setComponent } = useContext(FormBuilderContext);
  const { formatMessage } = useIntl();
  const [isDeleteSection, setIsDeleteSection] = useState(false);
  const isComponentWithRules = !!component && !TEXT_COMPONENTS.includes(component.component);
  const [isRuleBuilderOpened, setIsRuleBuilderOpened] = useState(false);

  const { data } = useQuery<CountOfIssuerRules, CountOfIssuerRulesVariables>(countOfRules, {
    variables: { issuerId: component?.id! },
    skip: !component?.id
  });

  const isBasicInfo = section?.id === BASIC_INFO_SECTION.id;
  const hasRules = !!data?.issuerRules.length;
  const addViewRules = isReadOnly ? hasRules : isComponentWithRules;

  return (
    <WidgetCard
      sx={{ flex: 1, maxWidth: '380px' }}
      header={
        <Typography variant='h3' color='secondary.700'>
          {formatMessage({ id: 'properties' })}
        </Typography>
      }
      body={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {component ? <ComponentProperties isReadOnly={isReadOnly} /> : <SectionProperties isReadOnly={isReadOnly} />}
        </Box>
      }
      footer={
        isBasicInfo || (isReadOnly && !hasRules) ? undefined : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', gap: '12px' }}>
              {addViewRules && (
                <Button sx={{ px: '4px', flex: 1 }} variant='contained' onClick={() => setIsRuleBuilderOpened(true)}>
                  {`${formatMessage({ id: isReadOnly ? 'view_rules' : 'add_rule' })} (${
                    data?.issuerRules.length ?? 0
                  })`}
                </Button>
              )}
              {!isReadOnly && (
                <Button
                  sx={{ width: !isComponentWithRules ? '100%' : 'initial', px: '12px', flex: 1 }}
                  variant='contained'
                  color='error'
                  onClick={async () => {
                    if (component) {
                      await deleteQuestion({ variables: { id: component.id } });
                      setComponent(null);
                    } else {
                      setIsDeleteSection(true);
                    }
                  }}
                >
                  {formatMessage({ id: component ? 'delete_component' : 'delete_section' })}
                </Button>
              )}
            </Box>
            {isDeleteSection && <DeleteSectionModal cleanup={() => setIsDeleteSection(false)} />}
            {isRuleBuilderOpened && (
              <RuleBuilder
                readonly={isReadOnly}
                issuerId={component?.id}
                onClose={() => setIsRuleBuilderOpened(false)}
              />
            )}
          </>
        )
      }
    />
  );
};
