import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';
import { OctagonQuestionMark } from '../icons';
import { type SnackbarProps } from './snackbar-props.interface';

const AttentionSnackbar: FC<SnackbarProps> = ({ title, message }) => {
  const { warning } = usePalette();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      gap={2}
      p={4}
      sx={{
        backgroundColor: warning[50],
        border: `1px solid ${warning[200]}`,
        borderRadius: '8px'
      }}
    >
      <Box>
        <Typography variant='subtitle1' color='secondary.800' mb={1}>
          {title}
        </Typography>
        {message && (
          <Typography variant='body1' color='secondary.800' mb={1}>
            {message}
          </Typography>
        )}
      </Box>
      <OctagonQuestionMark size={36}/>
    </Box>
  );
};

export default AttentionSnackbar;
