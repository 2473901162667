import { Box, Button, IconButton, Modal, Typography, useMediaQuery } from '@mui/material';
import { Role } from '__graphql__/globalTypes';
import { BasicInfoSection } from 'admin/pages/Forms/ViewForm/BasicInfoSection';
import { useCallback, useContext, useState, type FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AuthContext } from 'shared/contexts';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette, useRoles } from 'shared/hooks';
import { BurgerIcon, CrossIcon } from '../icons';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { RegistrationSteps } from './RegistrationSteps';
import { SectionForm } from './SectionForm';
import { UnsavedDataConfirmation } from './UnsavedDataConfirmation';

export const RegistrationModal: FC = () => {
  const { primary, secondary, info } = usePalette();
  const { formatMessage } = useIntl();
  const { isContractor } = useRoles();

  const [parentTab, setParentTab] = useState(0);
  const [childTab, setChildTab] = useState(-1);

  const [isOnClosingProcess, setIsOnClosingProcess] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationFunc, setConfirmationFunc] = useState({ func: () => {} });
  const isMobile = useMediaQuery('(max-width: 620px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAfterFetchingSections, setIsAfterFetchingSections] = useState(false);
  const { isOpened, registration, sections, isDirty, previewForm, closeModal } = useContext(RegistrationContext);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    setIsAfterFetchingSections(false);
  }, []);

  useEffect(() => {
    const isFirstTab = parentTab === 0;

    if (sections.length && isFirstTab && !isAfterFetchingSections) {
      const currentSection = sections[0];
      setChildTab(currentSection?.questions.length ? -1 : 0);
      setParentTab((prev) => ++prev);
      setIsAfterFetchingSections(true);
    }
  }, [parentTab, sections, isAfterFetchingSections]);

  const openConfirmation = useCallback((isClosingProcess: boolean, func: () => void) => {
    setIsConfirmationOpen(true);
    setIsOnClosingProcess(isClosingProcess);
    setConfirmationFunc({ func });
  }, []);

  const handleSectionChange = useCallback(
    (handleChange: () => void): void => {
      isDirty[0] ? openConfirmation(false, handleChange) : handleChange();
    },
    [isDirty, openConfirmation]
  );

  const onClose = useCallback(() => {
    const closeModalFunc = (): void => {
      setParentTab(0);
      setChildTab(-1);
      setIsAfterFetchingSections(false);
      closeModal();
    };

    if (isDirty[0]) {
      openConfirmation(true, () => {
        closeModalFunc();
      });
      return;
    }
    closeModalFunc();
  }, [closeModal, isDirty, openConfirmation]);

  const goToNextTab = useCallback((): void => {
    const currentSection = sections[parentTab - 1];
    const shouldChangeChildTab = childTab < currentSection?.children.length - 1;

    if (shouldChangeChildTab) {
      setChildTab((prev) => ++prev);
    } else if (sections.length) {
      setChildTab(currentSection?.questions.length ? -1 : 0);
      setParentTab((prev) => ++prev);
    }
  }, [childTab, parentTab, sections]);

  const goToPreviousTab = (): void => {
    const currentSection = sections[parentTab - 1];
    const shouldChangeChildTab = childTab > 0;

    if (shouldChangeChildTab || (currentSection.questions.length && childTab !== -1)) {
      setChildTab((prev) => --prev);
    } else if (parentTab - 2 >= 0) {
      setChildTab((sections[parentTab - 2]?.children.length ?? 0) - 1);
      setParentTab((prev) => --prev);
    } else {
      setParentTab(0);
      setChildTab(-1);
    }
  };

  const changeParentTab = (index: number, afterChange?: () => void): void => {
    if (index) {
      handleSectionChange(() => {
        const sectionToActive = sections[index - 1];
        setChildTab(sectionToActive?.questions.length ? -1 : 0);
        setParentTab(index);
        afterChange?.();
      });
    } else {
      handleSectionChange(() => {
        setParentTab(0);
        setChildTab(-1);
        afterChange?.();
      });
    }
  };

  const changeChildTab = (index: number): void => {
    handleSectionChange(() => {
      setChildTab(index);
    });
  };

  const currentParentSection = sections[parentTab - 1];
  const currentChildSection = currentParentSection?.children[childTab];
  const sectionToRender = currentChildSection ?? currentParentSection;

  return (
    <Modal open={isOpened} onClose={onClose} componentsProps={{ backdrop: { style: { background: info[200] } } }}>
      <>
        <Box
          sx={{
            height: '100%',
            background: secondary[50],
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: '20px 48px',
              background: 'white',
              borderBottom: `1px solid ${secondary[200]}`,
              '@media only screen and (max-width: 621px)': {
                p: '12px 16px',
                alignItems: 'center'
              }
            }}
          >
            {!isMenuOpen && (
              <Button
                sx={{
                  '@media only screen and (min-width: 621px)': {
                    display: 'none'
                  }
                }}
                onClick={() => setIsMenuOpen(true)}
              >
                <BurgerIcon />
              </Button>
            )}

            <Typography
              color='secondary.800'
              variant='h2'
              sx={{
                '@media only screen and (max-width: 620px)': {
                  fontSize: '24px'
                }
              }}
            >
              {isMenuOpen
                ? formatMessage({ id: 'registration_steps' })
                : previewForm.id
                ? `${previewForm.name} ${formatMessage({ id: 'preview' })}`
                : authState.user?.role === Role.CONTRACTOR
                ? formatMessage({ id: 'my_registration' })
                : (registration?.id && formatMessage({ id: 'registration_id' }) + ' ' + registration?.id) ||
                  formatMessage({ id: 'new_registration' })}
            </Typography>
            <IconButton
              onClick={() => {
                if (isMenuOpen) {
                  setIsMenuOpen(false);
                } else {
                  onClose();
                }
              }}
              sx={{ '&:hover path': { stroke: primary[600] } }}
            >
              <CrossIcon />
            </IconButton>
          </Box>

          <LinearProgressWithLabel />

          <Box
            sx={{
              display: 'flex',
              gap: '32px',
              p: '48px 32px 24px 32px',
              '@media only screen and (max-width: 620px)': {
                pt: '24px'
              }
            }}
          >
            {!isMobile && (
              <Box
                sx={{
                  maxWidth: '320px',
                  '@media only screen and (max-width: 767px)': {
                    maxWidth: '240px'
                  }
                }}
              >
                <RegistrationSteps
                  parentTabIndex={parentTab}
                  childTabIndex={childTab}
                  changeChildTab={changeChildTab}
                  changeParentTab={changeParentTab}
                />
              </Box>
            )}
            <Box
              sx={{
                maxHeight: 'calc(100vh - 90px - 155px)',
                overflowY: 'auto',
                width: '100%'
              }}
            >
              {sectionToRender ? (
                <SectionForm
                  section={sectionToRender}
                  childTab={childTab}
                  parentTab={parentTab}
                  goToPreviousTab={goToPreviousTab}
                  goToNextTab={goToNextTab}
                />
              ) : (
                <BasicInfoSection goToNextTab={goToNextTab} />
              )}
            </Box>
          </Box>

          <UnsavedDataConfirmation
            isOpened={isConfirmationOpen}
            close={() => {
              setIsConfirmationOpen(false);
            }}
            continueFunc={confirmationFunc.func}
            isOnClosingProcess={isOnClosingProcess}
          />
        </Box>

        {isContractor ? (
          <IconButton
            sx={{
              position: 'fixed',
              bottom: '100px',
              left: '5px',
              borderRadius: '50%',
              zIndex: 100000,
              p: 0
            }}
            // @ts-expect-error: error
            onClick={loadService}
          >
            <img
              src='https://res.cloudinary.com/djggmzwow/image/upload/v1624631760/accessibility-icon_ahrhie.png'
              alt='Accessibility Tools'
              style={{
                width: '50px',
                height: '50px',
                cursor: 'pointer'
              }}
            />
          </IconButton>
        ) : null}

        {isMenuOpen && (
          <Modal open componentsProps={{ backdrop: { style: { background: secondary[100] } } }}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: '16px 24px'
                }}
              >
                <Typography color='secondary.800' variant='h2' sx={{ fontSize: '24px' }}>
                  {formatMessage({ id: 'registration_steps' })}
                </Typography>
                <IconButton onClick={() => setIsMenuOpen(false)} sx={{ '&:hover path': { stroke: primary[600] } }}>
                  <CrossIcon />
                </IconButton>
              </Box>

              <Box sx={{ p: '16px 24px' }}>
                <RegistrationSteps
                  parentTabIndex={parentTab}
                  childTabIndex={childTab}
                  changeChildTab={changeChildTab}
                  changeParentTab={changeParentTab}
                />
              </Box>
            </Box>
          </Modal>
        )}
      </>
    </Modal>
  );
};
