import { Grid, IconButton, Typography } from '@mui/material';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { CrossIcon } from 'shared/components/icons';
import { ProfileInfoContext } from 'shared/contexts';

export const Header: FC = () => {
  const { closeModal } = useContext(ProfileInfoContext);
  const intl = useIntl();

  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center' flexWrap='nowrap'>
      <Typography variant='h6'>{intl.formatMessage({ id: 'my_profile' })}</Typography>
      <IconButton onClick={closeModal}>
        <CrossIcon />
      </IconButton>
    </Grid>
  );
};
