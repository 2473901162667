import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { type UpdateSectionVariables } from 'shared/hooks/useFormBuilder/__graphql__/UpdateSection';
import { BASIC_INFO_SECTION } from '../constants';
import { PropertyBlock } from './shared';
import { RelationshipPropertyBlock } from './shared/RelationshipProperty';
import { StepPropertyBlock } from './shared/StepProperty';

interface Props {
  isReadOnly: boolean;
}

export const SectionProperties: FC<Props> = ({ isReadOnly }) => {
  const { formatMessage } = useIntl();
  const { section: contextSection, updateSection } = useContext(FormBuilderContext);
  const section = contextSection!;

  const update = async (data: UpdateSectionVariables['data']): Promise<void> => {
    await updateSection({ variables: { id: section.id, data } });
  };

  const readOnly = isReadOnly || section.id === BASIC_INFO_SECTION.id;

  return (
    <>
      <PropertyBlock
        onSubmit={update}
        readOnly={readOnly}
        title={formatMessage({ id: 'section_name' })}
        value={{ name: section.name }}
        tooltip={formatMessage({ id: 'section_tooltips.name' })}
      />
      <PropertyBlock
        onSubmit={update}
        readOnly={readOnly}
        title={formatMessage({ id: 'section_variable' })}
        value={{ variable: section.variable }}
        tooltip={formatMessage({ id: 'section_tooltips.variable' })}
      />
      <PropertyBlock
        onSubmit={update}
        readOnly={readOnly}
        title={formatMessage({ id: 'description' })}
        placeholder={formatMessage({ id: 'component_placeholders.description' })}
        value={{ description: section.description }}
        tooltip={formatMessage({ id: 'section_tooltips.description' })}
      />
      <RelationshipPropertyBlock readOnly={readOnly} />
      <StepPropertyBlock readOnly={readOnly} />
    </>
  );
};
