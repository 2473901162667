import { useQuery } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';

import { type TenantCreate } from '__graphql__/globalTypes';
import { Field, type FormikProps } from 'formik';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { updatePaymentInfoLinkQuery } from 'shared/api';
import {
  type UpdatePaymentInfoLinkVariables,
  type UpdatePaymentInfoLink
} from 'shared/api/__graphql__/UpdatePaymentInfoLink';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Input, Loader } from 'shared/components';
import routes from 'shared/constants/routes';
import { getTenantUrl } from 'shared/utils/url';
import { Validator } from 'shared/utils/validator';
import { InputTooltip } from './form';
import { ChevronIcon } from './icons';

const getInputProps = (message: string): Record<string, any> => ({
  endAdornment: (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <InputTooltip title={message} />
    </Box>
  )
});

const PaymentInfo: FC<{ containerSize: number; companyId: string }> = ({ containerSize, companyId }) => {
  const { formatMessage } = useIntl();

  const { data, loading } = useQuery<UpdatePaymentInfoLink, UpdatePaymentInfoLinkVariables>(
    updatePaymentInfoLinkQuery,
    {
      variables: { returnUrl: `https://${getTenantUrl(companyId)}/${routes.myCompany}` },
      context: API_CONTEXT.PAYMENT
    }
  );

  if (loading) {
    return (
      <Grid item sm={containerSize}>
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid item sm={containerSize}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: '10px' }}>
        <Typography
          variant='h4'
          color='secondary.700'
          sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 600, mb: '12px' }}
        >
          {formatMessage({ id: 'payment_info' })}
        </Typography>
        <Button
          variant='text'
          onClick={() => {
            window.open(data?.updatePaymentInStripeLink, '_self');
          }}
        >
          {formatMessage({ id: 'edit_in_stripe' })}
          <ChevronIcon direction='right' />
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Field
          disabled
          id='card'
          name='card'
          component={Input}
          label={formatMessage({ id: 'card_number' })}
          InputProps={getInputProps(formatMessage({ id: 'card_number' }))}
        />
        <Field
          disabled
          id='expiringDate'
          name='expiringDate'
          component={Input}
          label={formatMessage({ id: 'expiring_date' })}
          InputProps={getInputProps(formatMessage({ id: 'expiring_date' }))}
        />
      </Box>
    </Grid>
  );
};

export const CompanyForm: FC<
  FormikProps<TenantCreate> & {
    forTenant?: boolean;
    withBillingContact?: boolean;
    withPaymentInfo?: boolean;
    loading: boolean;
  }
> = ({
  values,
  initialValues,
  setFieldValue,
  isSubmitting,
  loading,
  forTenant = true,
  withBillingContact,
  withPaymentInfo
}) => {
  const tenantBaseUrl = getTenantUrl();
  const { formatMessage } = useIntl();

  if (loading || isSubmitting) {
    return <Loader />;
  }

  const containerSize = withBillingContact ? 6 : 12;

  return (
    <Grid container rowSpacing={{ sm: 5 }} columnSpacing={{ sm: 5 }}>
      <Grid item sm={containerSize}>
        <Typography
          variant='h4'
          color='secondary.700'
          sx={{ fontSize: '20px', lineHeight: '43px', fontWeight: 600, mb: '12px' }}
        >
          {formatMessage({ id: 'basic_info' })}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Field
            id='name'
            name='name'
            label={formatMessage({ id: forTenant ? 'tenant_name' : 'company_name' })}
            component={Input}
            validate={Validator.pipe(Validator.methods.required())}
            InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.name' }))}
          />
          <Field
            id='id'
            name='id'
            disabled={forTenant && !!initialValues.id}
            label={formatMessage({ id: forTenant ? 'tenant_url' : 'system_url' })}
            placeholder={`tenantname${tenantBaseUrl}`}
            value={values.id ? `${values.id + tenantBaseUrl}` : values.id}
            onChange={(e: any) => {
              const tenantId = e.target.value.replaceAll(tenantBaseUrl, '').split('.')[0];
              setFieldValue('id', tenantId);
              setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = tenantId.length;
              }, 0);
            }}
            onFocus={(e: any) => {
              setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = values.id?.length;
              }, 0);
            }}
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = values.id?.length;
              }, 0);
            }}
            component={Input}
            validate={Validator.pipe(Validator.methods.required(), Validator.methods.startsWithLetterAndAlphanumeric())}
            InputProps={getInputProps(
              formatMessage({
                id: forTenant ? 'new_tenant_tooltip.url' : 'company_form.tooltip.url'
              })
            )}
          />
        </Box>
      </Grid>
      {withBillingContact && (
        <Grid item sm={containerSize}>
          <Typography
            variant='h4'
            color='secondary.700'
            sx={{ fontSize: '20px', lineHeight: '43px', fontWeight: 600, mb: '12px' }}
          >
            {formatMessage({ id: 'billing_contacts' })}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Field
              id='billingContactName'
              name='billingContactName'
              component={Input}
              label={formatMessage({ id: 'contact_name' })}
              InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.contactName' }))}
            />
            <Field
              id='billingContactEmail'
              name='billingContactEmail'
              component={Input}
              label={formatMessage({ id: 'contact_email' })}
              validate={Validator.pipe(Validator.methods.email())}
              InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.contactEmail' }))}
            />
          </Box>
        </Grid>
      )}
      <Grid item sm={containerSize}>
        <Typography
          variant='h4'
          color='secondary.700'
          sx={{ fontSize: '20px', lineHeight: '43px', fontWeight: 600, mb: '12px' }}
        >
          {formatMessage({ id: 'admin_access' })}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Field
            id='adminName'
            name='adminName'
            label={formatMessage({ id: 'admin_name' })}
            component={Input}
            validate={Validator.pipe(Validator.methods.required())}
            InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.adminName' }))}
          />
          <Field
            id='adminEmail'
            name='adminEmail'
            label={formatMessage({ id: 'email' })}
            component={Input}
            validate={Validator.pipe(Validator.methods.required(), Validator.methods.email())}
            InputProps={getInputProps(formatMessage({ id: 'new_tenant_tooltip.adminEmail' }))}
          />
        </Box>
      </Grid>
      {withPaymentInfo && <PaymentInfo containerSize={containerSize} companyId={values.id} />}
    </Grid>
  );
};
