import gql from 'graphql-tag';

export const referralsMatchQuery = gql`
  query ReferralsMatch(
    $offset: Int!
    $limit: Int!
    $query: String
  ) {
    registrations(
      limit: $limit
      offset: $offset
      query: $query
    ) {
      total
      data {
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;
