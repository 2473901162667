import { Box, Grid, Typography } from '@mui/material';
import React, { type FC } from 'react';

interface Props {
  name?: string;
  children?: React.ReactElement;
}

export const PageTitle: FC<Props> = ({ name, children }) => (
  <Box p='24px 40px 16px 24px'>
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      {name && (
        <Typography color='secondary.800' variant='h2'>
          {name}
        </Typography>
      )}
      {children}
    </Grid>
  </Box>
);
