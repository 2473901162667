import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import {
  DocumentOrderField,
  DocumentRelation,
  DocumentType,
  OrderDirection,
  RegistrationStatus
} from '__graphql__/globalTypes';
import { Audit, Notes, TabPanel } from 'admin/components';
import { useCallback, useContext, useMemo, useState, type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getDownloadUrlQuery } from 'shared/api';
import { type GetDownloadUrl, type GetDownloadUrlVariables } from 'shared/api/__graphql__/GetDownloadUrl';
import { API_CONTEXT } from 'shared/api/api-contexts';
import apolloClient from 'shared/apolloClient';
import { Card, ConfirmationDialog, Footer, Loader } from 'shared/components';
import { CustomCheckbox } from 'shared/components/CustomCheckbox';
import { ChevronIcon, CrossIcon, FileDownloadIcon, MailIcon, NoteIcon } from 'shared/components/icons';
import {
  type UpdateStatus,
  type UpdateStatusVariables
} from 'shared/components/RegistrationModal/__graphql__/UpdateStatus';
import { updateStatusMutation } from 'shared/components/RegistrationModal/api';
import { ErrorSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette, useRoles, useTenantNavigate } from 'shared/hooks';
import { getStatusColor } from 'shared/utils/colors';
import { createLocalFile } from 'shared/utils/file';
import { type ArchiveRegistration, type ArchiveRegistrationVariables } from './__graphql__/ArchiveRegistration';
import { type DeleteRegitration, type DeleteRegitrationVariables } from './__graphql__/DeleteRegitration';
import {
  type DeleteRegitrationDocuments,
  type DeleteRegitrationDocumentsVariables
} from './__graphql__/DeleteRegitrationDocuments';
import { type Documents, type DocumentsVariables, type Documents_documents_data } from './__graphql__/Documents';
import {
  type GenerateRegistrationForm,
  type GenerateRegistrationFormVariables
} from './__graphql__/GenerateRegistrationForm';
import {
  type RegistrationDetails as RegistrationDetailsGraphql,
  type RegistrationDetailsVariables,
  type RegistrationDetails_registration
} from './__graphql__/RegistrationDetails';
import { type SetDummyRecord, type SetDummyRecordVariables } from './__graphql__/SetDummyRecord';
import { type SetFormSent, type SetFormSentVariables } from './__graphql__/SetFormSent';
import { type setPaymentReceived, type setPaymentReceivedVariables } from './__graphql__/setPaymentReceived';
import { type SetUploadToPayroll, type SetUploadToPayrollVariables } from './__graphql__/SetUploadToPayroll';
import { type UnarchiveRegistration, type UnarchiveRegistrationVariables } from './__graphql__/UnarchiveRegistration';
import { type UnverifyDocuments, type UnverifyDocumentsVariables } from './__graphql__/UnverifyDocuments';
import {
  archiveRegistrationMutation,
  deleteRegistrationDocumentsMutation,
  deleteRegistrationMutation,
  documentsQuery,
  generateRegistrationFormMutation,
  registrationDetailsQuery,
  setDummyRecordMutation,
  setFormSentMutation,
  setPaymentReceivedMutation,
  setUploadToPayrollMutation,
  unarchiveRegistrationMutation,
  unverifyDocumentsMutation
} from './api';
import { DetailsItem } from './DetailsItem';
import { DocumentsWidget } from './DocumentsWidget';

export const RegistrationDetails: FC = () => {
  const intl = useIntl();
  const { primary, secondary, success, error, info } = usePalette();

  const { id } = useParams();
  const navigate = useTenantNavigate();
  const { isCustomerCare, isAdmin, isSuperAdmin } = useRoles();
  const { openModalWithId } = useContext(RegistrationContext);
  const [notesTab, setNotesTab] = useState(0);
  const [isNewNoteVisible, setIsNewNoteVisible] = useState(false);
  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] = useState(false);

  const onClose = useCallback(
    (action?: 'deletion') => {
      navigate('');
      if (action === 'deletion') {
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'the_registration_is_successfully_deleted' })}
            message={intl.formatMessage({ id: 'the_registration_was_removed' })}
          />
        );
      }
    },
    [intl, navigate]
  );

  const registrationResult = useQuery<RegistrationDetailsGraphql, RegistrationDetailsVariables>(
    registrationDetailsQuery,
    {
      variables: {
        id: id!
      },
      skip: !id
    }
  );

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const registration = registrationResult.data?.registration ?? ({} as RegistrationDetails_registration);

  const statusOptions = useMemo(() => {
    let availableStatuses: RegistrationStatus[] = [];
    switch (registration.status) {
      case RegistrationStatus.bREFERRED: {
        availableStatuses = [RegistrationStatus.dNO_RESPONSE];
        break;
      }
      case RegistrationStatus.dNO_RESPONSE: {
        availableStatuses = [RegistrationStatus.bREFERRED];
        break;
      }
      case RegistrationStatus.oAWAITING_ID: {
        availableStatuses = [RegistrationStatus.rON_HOLD];
        break;
      }
      case RegistrationStatus.rON_HOLD: {
        availableStatuses = [RegistrationStatus.lID_CHECKS];
        break;
      }
      case RegistrationStatus.tUPLOAD_TO_PAYROLL: {
        availableStatuses = [RegistrationStatus.vREGISTERED];
        break;
      }
      case RegistrationStatus.vREGISTERED: {
        availableStatuses = [RegistrationStatus.lID_CHECKS];
        break;
      }
      case RegistrationStatus.xNOT_GOING_AHEAD: {
        availableStatuses = [registration.previousStatus!];
        break;
      }
      default: {
        break;
      }
    }
    return [...new Set([registration.status, ...availableStatuses, RegistrationStatus.xNOT_GOING_AHEAD])];
  }, [registration.status, registration.previousStatus]);

  const documentsResult = useQuery<Documents, DocumentsVariables>(documentsQuery, {
    variables: {
      limit: 100,
      offset: 0,
      filter: {
        relatedId: id!,
        relatedTo: DocumentRelation.REGISTRATION
      },
      orderBy: {
        field: DocumentOrderField.createdAt,
        direction: OrderDirection.desc
      }
    },
    skip: !id,
    context: {
      uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
    }
  });

  const [archiveRegistration] = useMutation<ArchiveRegistration, ArchiveRegistrationVariables>(
    archiveRegistrationMutation,
    {
      refetchQueries: ['RegistrationDetails', 'Audit'],
      awaitRefetchQueries: true
    }
  );

  const [unarchiveRegistration] = useMutation<UnarchiveRegistration, UnarchiveRegistrationVariables>(
    unarchiveRegistrationMutation,
    {
      refetchQueries: ['RegistrationDetails', 'Audit'],
      awaitRefetchQueries: true
    }
  );

  const [setFormSent] = useMutation<SetFormSent, SetFormSentVariables>(setFormSentMutation, {
    refetchQueries: ['RegistrationDetails', 'Audit'],
    awaitRefetchQueries: true
  });

  const [setUploadToPayroll] = useMutation<SetUploadToPayroll, SetUploadToPayrollVariables>(
    setUploadToPayrollMutation,
    {
      refetchQueries: ['RegistrationDetails', 'Audit'],
      awaitRefetchQueries: true
    }
  );

  const [setPaymentReceived] = useMutation<setPaymentReceived, setPaymentReceivedVariables>(
    setPaymentReceivedMutation,
    {
      refetchQueries: ['RegistrationDetails', 'Audit'],
      awaitRefetchQueries: true
    }
  );

  const [setDummyRecord] = useMutation<SetDummyRecord, SetDummyRecordVariables>(setDummyRecordMutation, {
    refetchQueries: ['RegistrationDetails', 'Audit'],
    awaitRefetchQueries: true
  });

  const [updateStatus] = useMutation<UpdateStatus, UpdateStatusVariables>(updateStatusMutation, {
    refetchQueries: ['RegistrationDetails', 'Audit'],
    awaitRefetchQueries: true
  });

  const [generateRegistationForm] = useMutation<GenerateRegistrationForm, GenerateRegistrationFormVariables>(
    generateRegistrationFormMutation,
    {
      context: API_CONTEXT.DOCUMENT,
      refetchQueries: ['Documents'],
      variables: { registrationId: id! }
    }
  );

  const [unverifyAll] = useMutation<UnverifyDocuments, UnverifyDocumentsVariables>(unverifyDocumentsMutation, {
    refetchQueries: ['Documents'],
    awaitRefetchQueries: true,
    context: {
      uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
    }
  });

  const [deleteRegistration] = useMutation<DeleteRegitration, DeleteRegitrationVariables>(deleteRegistrationMutation);

  const [deleteRegistrationDocuments] = useMutation<DeleteRegitrationDocuments, DeleteRegitrationDocumentsVariables>(
    deleteRegistrationDocumentsMutation,
    {
      context: {
        uri: `${process.env.REACT_APP_API_DOCUMENT_BASE}graphql`
      }
    }
  );

  const documents = useMemo(
    () =>
      documentsResult.data?.documents?.data?.filter(
        (d: Documents_documents_data) => d.documentType === DocumentType.DOCUMENT
      ),
    [documentsResult.data?.documents?.data]
  );

  const [contract, assignmentSchedule, registrationForm] = useMemo(
    () => [
      documentsResult.data?.documents?.data?.find(
        (d: Documents_documents_data) => d.documentType === DocumentType.CONTRACT
      ),
      documentsResult.data?.documents?.data?.find(
        (d: Documents_documents_data) => d.documentType === DocumentType.ASSIGNMENT_SCHEDULE
      ),
      documentsResult.data?.documents?.data?.find(
        (d: Documents_documents_data) => d.documentType === DocumentType.REGISTRATION_FORM
      )
    ],
    [documentsResult.data?.documents]
  );

  const downloadFile = useAsyncCallback(async (document?: Documents_documents_data) => {
    if (document) {
      const urlResponse = await apolloClient.query<GetDownloadUrl, GetDownloadUrlVariables>({
        query: getDownloadUrlQuery,
        variables: { id: document.id },
        context: API_CONTEXT.DOCUMENT
      });
      const url = urlResponse.data?.getDowloadUrl;
      if (url) {
        const buffer = await fetch(url);
        createLocalFile(await buffer.blob(), document.filename);
      }
    }
  });

  const handleChangeStatus = useAsyncCallback(async (e) => {
    if (id) {
      const status = e.target.value as RegistrationStatus;
      if (
        status === RegistrationStatus.vREGISTERED &&
        !registration.uploadedToPayroll &&
        registration.status !== RegistrationStatus.xNOT_GOING_AHEAD
      ) {
        toast(
          <ErrorSnackbar
            title={intl.formatMessage({ id: 'not_uploaded_to_payroll' })}
            message={intl.formatMessage({ id: 'upload_before_set_to_registered' })}
          />,
          {
            position: toast.POSITION.TOP_RIGHT
          }
        );
        return;
      }
      await updateStatus({ variables: { id, status } });
      if (status === RegistrationStatus.lID_CHECKS && documents?.some((d) => d.verified)) {
        await unverifyAll({ variables: { ids: documents.map((d) => d.id) } });
      }
      if (status === RegistrationStatus.vREGISTERED && !registrationForm) {
        await generateRegistationForm();
      }
    }
  });

  const handleDeleteRegistration = useAsyncCallback(async () => {
    if (id) {
      await deleteRegistrationDocuments({ variables: { relatedId: id } });
      await deleteRegistration({ variables: { id } });
      onClose('deletion');
    }
  });

  const openNewNoteInput = useCallback(() => {
    setNotesTab(0);
    setIsNewNoteVisible(true);
  }, []);

  const closeNewNoteInput = useCallback(() => {
    setIsNewNoteVisible(false);
  }, []);

  if (registrationResult.loading) {
    return <Loader />;
  }

  if (!id) {
    return <div>Wrong registration</div>;
  }

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          flexWrap='nowrap'
          bgcolor='#FFF'
          sx={{
            padding: '20px 48px 19px',
            borderBottom: `1px solid ${secondary[200]}`,
            '@media only screen and (max-width: 767px)': {
              paddingX: '16px 16px 11px'
            }
          }}
        >
          <Typography color='secondary.800' variant='h2'>
            {intl.formatMessage({ id: 'registration_id' }) + ' ' + registration.id}
          </Typography>
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <CrossIcon />
          </IconButton>
        </Grid>
        <Grid container height='calc(100vh - 88px - 84px)' sx={{ overflowY: 'auto', '& > div': { overflowY: 'auto' } }}>
          <Grid item xs={8} sx={{ padding: '24px 48px 32px' }} bgcolor={info[200]}>
            <Select
              sx={{
                width: '224px',
                mb: '16px',
                '& > div': {
                  borderRadius: '8px',
                  backgroundColor: getStatusColor(registration.status),
                  border: `1px solid ${getStatusColor(registration.status)}`,
                  '&[aria-expanded=true]': {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${primary[600]}`
                  }
                },
                '& fieldset': {
                  border: 'none'
                },
                '& svg': {
                  color: secondary[700],
                  width: '24px',
                  height: '24px',
                  top: 'calc(50% - 12px)'
                }
              }}
              disabled={isCustomerCare || registration.locked}
              value={registration.status}
              onChange={handleChangeStatus.execute}
              IconComponent={ChevronIcon}
              MenuProps={{
                MenuListProps: { sx: { border: 'none' } },
                sx: {
                  '& .MuiPaper-root': {
                    border: `1px solid ${primary[600]}`,
                    borderRadius: '8px',
                    py: '4px',
                    mt: '0.5px'
                  }
                }
              }}
            >
              {statusOptions.map((status) => (
                <MenuItem
                  key={status}
                  value={status}
                  sx={{
                    '&, &:hover, &.Mui-selected, &.Mui-selected:hover': {
                      m: '8px 16px',
                      p: '8px 16px',
                      width: 'fit-content',
                      borderRadius: '4px',
                      backgroundColor: `${getStatusColor(status)} !important`
                    }
                  }}
                >
                  {intl.formatMessage({ id: `registration.status.${status as string}` })}
                </MenuItem>
              ))}
            </Select>
            <Card
              sx={{
                width: '448px',
                padding: '24px 24px 16px',
                '& > *:not(:last-child)': {
                  marginBottom: '14px'
                }
              }}
            >
              <Typography variant='h6' color='secondary.800' fontWeight={400}>
                {intl.formatMessage({ id: 'basic_information' })}
              </Typography>
              <DetailsItem label={intl.formatMessage({ id: 'title' })} value={registration.title} />
              <DetailsItem label={intl.formatMessage({ id: 'first_name' })} value={registration.firstName} />
              <DetailsItem label={intl.formatMessage({ id: 'surname' })} value={registration.lastName} />
              <DetailsItem label={intl.formatMessage({ id: 'phone_number' })} value={registration.phoneNumber} />
              <DetailsItem label={intl.formatMessage({ id: 'email' })} value={registration.email} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  className='with-icon'
                  disabled={
                    !registration.email ||
                    [RegistrationStatus.vREGISTERED, RegistrationStatus.xNOT_GOING_AHEAD].includes(registration.status)
                  }
                  size='small'
                  variant='text'
                  onClick={async () => {
                    await setFormSent({ variables: { id } });
                    toast(
                      <SuccessSnackbar
                        title={intl.formatMessage({ id: 'form_sent' })}
                        message={intl.formatMessage({ id: 'form_sent_message' })}
                      />
                    );
                  }}
                  sx={{ padding: '8px 24px' }}
                >
                  <MailIcon color={secondary[700]} />
                  {intl.formatMessage({ id: 'send_form' })}
                </Button>
              </Box>
            </Card>
            <Card
              sx={{
                width: '448px',
                padding: '24px'
              }}
            >
              <Typography variant='h6' color='secondary.800' fontWeight={400} mb={2}>
                {intl.formatMessage({ id: 'documents' })}
              </Typography>
              {documents?.[0] ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Typography variant='subtitle2' color='secondary.800'>
                    {intl.formatMessage({ id: documents[0].documentType })}
                  </Typography>
                  <DocumentsWidget documents={documents} id={registration.id} status={registration.status}>
                    {documents?.length ? (
                      <Typography
                        color='secondary.800'
                        sx={{
                          backgroundColor: documents?.every((d) => d.verified) ? success[200] : error[100],
                          padding: '8px 12px',
                          borderRadius: '4px'
                        }}
                      >
                        {intl.formatMessage({
                          id: documents?.every((d) => d.verified) ? 'verified' : 'not_verified'
                        })}
                      </Typography>
                    ) : (
                      <Typography
                        color='secondary.800'
                        sx={{ backgroundColor: primary[300], padding: '8px 12px', borderRadius: '4px' }}
                      >
                        {intl.formatMessage({ id: 'ready_to_check' })}
                      </Typography>
                    )}
                  </DocumentsWidget>
                </Box>
              ) : (
                <Typography variant='body1' color={secondary[500]}>
                  {intl.formatMessage({ id: 'no_documents_uploaded' })}
                </Typography>
              )}
            </Card>
            <Card
              sx={{
                width: '448px',
                padding: '24px'
              }}
            >
              <Typography variant='h6' color='secondary.800' fontWeight={400} mb={3}>
                {intl.formatMessage({ id: 'registration_settings' })}
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  '& > *:not(:last-child)': {
                    marginBottom: '24px'
                  }
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    disabled={[RegistrationStatus.vREGISTERED, RegistrationStatus.xNOT_GOING_AHEAD].includes(
                      registration.status
                    )}
                    label={intl.formatMessage({ id: 'dummy_record' })}
                    componentsProps={{ typography: { sx: { '&:not(.Mui-disabled)': { color: secondary[800] } } } }}
                    sx={{ '& > span': { marginRight: '12px' } }}
                    control={
                      <CustomCheckbox
                        checked={registration.dummyRecord ?? false}
                        onChange={async () =>
                          await setDummyRecord({ variables: { id, state: !registration.dummyRecord } })
                        }
                      />
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    disabled={isCustomerCare || registration.status !== RegistrationStatus.tUPLOAD_TO_PAYROLL}
                    label={intl.formatMessage({ id: 'upload_to_payroll' })}
                    componentsProps={{ typography: { sx: { '&:not(.Mui-disabled)': { color: secondary[800] } } } }}
                    sx={{ '& > span': { marginRight: '12px' } }}
                    control={
                      <CustomCheckbox
                        checked={registration.uploadedToPayroll ?? false}
                        onChange={async () =>
                          await setUploadToPayroll({ variables: { id, state: !registration.uploadedToPayroll } })
                        }
                      />
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    disabled={isCustomerCare || registration.status !== RegistrationStatus.vREGISTERED}
                    label={intl.formatMessage({ id: 'payment_received' })}
                    componentsProps={{ typography: { sx: { '&:not(.Mui-disabled)': { color: secondary[800] } } } }}
                    sx={{ '& > span': { marginRight: '12px' } }}
                    control={
                      <CustomCheckbox
                        checked={registration.paymentReceived ?? false}
                        onChange={async () =>
                          await setPaymentReceived({ variables: { id, state: !registration.paymentReceived } })
                        }
                      />
                    }
                  />
                </FormGroup>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={4} sx={{ borderLeft: `1px solid ${secondary[200]}` }} bgcolor={info[300]}>
            <Grid
              item
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              flexWrap='wrap'
              sx={{
                padding: '16px 24px',
                borderBottom: `1px solid ${secondary[200]}`
              }}
              bgcolor={info[200]}
            >
              <Tabs
                value={notesTab}
                onChange={(_, v) => {
                  setNotesTab(v);
                }}
                aria-label='note tabs'
                sx={{
                  background: 'transparent',
                  padding: 0,
                  border: 'none',
                  borderRadius: 0,
                  '& .MuiTabs-flexContainer': {
                    gap: '8px'
                  },
                  '& button': {
                    borderRadius: '8px',
                    border: `1px solid ${secondary[400]}`,
                    bgcolor: info[200],
                    color: secondary[700],
                    '&.Mui-selected': {
                      bgcolor: primary[400],
                      color: secondary[800],
                      borderColor: primary[600]
                    }
                  }
                }}
              >
                <Tab label={intl.formatMessage({ id: 'notes' })} id='notes' aria-controls='notes' />
                <Tab label={intl.formatMessage({ id: 'audit_trail' })} id='audit_trail' aria-controls='audit_trail' />
              </Tabs>
              <Button className='with-icon' variant='text' onClick={openNewNoteInput} sx={{ padding: '8px 24px' }}>
                <NoteIcon color={secondary[700]} />
                {intl.formatMessage({ id: 'add_note' })}
              </Button>
            </Grid>
            <Grid item sx={{ padding: '20px 24px' }}>
              <TabPanel value={notesTab} index={0}>
                <Notes
                  idData={{ registrationId: registration.id }}
                  isNewNoteVisible={isNewNoteVisible}
                  emptyBoxIntlId='create_note_for_this_registration'
                  closeNewNoteInput={closeNewNoteInput}
                  openNewNoteInput={openNewNoteInput}
                />
              </TabPanel>
              <TabPanel value={notesTab} index={1}>
                <Audit registrationId={registration.id} />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px'
            }}
          >
            {/* <Button
              size="medium"
              variant="outlined"
              onClick={downloadCSV.execute}
            >
              {intl.formatMessage({ id: 'download_csv' })}
            </Button> */}
            {contract && (
              <Button
                className='with-icon'
                size='medium'
                variant='outlined'
                onClick={async () => {
                  await downloadFile.execute(contract);
                }}
              >
                <FileDownloadIcon color={primary[600]} />
                {intl.formatMessage({ id: 'contract' })}
              </Button>
            )}
            {assignmentSchedule && (
              <Button
                className='with-icon'
                size='medium'
                variant='outlined'
                onClick={async () => {
                  await downloadFile.execute(assignmentSchedule);
                }}
              >
                <FileDownloadIcon color={primary[600]} />
                {intl.formatMessage({ id: 'assignment_schedule' })}
              </Button>
            )}
            {registrationForm && registration.status === RegistrationStatus.vREGISTERED && (
              <Button
                className='with-icon'
                size='medium'
                variant='outlined'
                onClick={async () => {
                  await downloadFile.execute(registrationForm);
                }}
              >
                <FileDownloadIcon color={primary[600]} />
                {intl.formatMessage({ id: 'registration' })}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px'
            }}
          >
            <Button
              disabled={
                isCustomerCare ||
                (![RegistrationStatus.vREGISTERED, RegistrationStatus.xNOT_GOING_AHEAD].includes(registration.status) &&
                  !registration.locked)
              }
              size='medium'
              variant='outlined'
              onClick={async () => {
                const mutation = registration.locked ? unarchiveRegistration : archiveRegistration;
                await mutation({ variables: { id } });
              }}
            >
              {registration.locked ? intl.formatMessage({ id: 'unarchive' }) : intl.formatMessage({ id: 'archive' })}
            </Button>
            <Button
              size='medium'
              variant='contained'
              onClick={() => {
                openModalWithId(id);
              }}
            >
              {intl.formatMessage({ id: 'impersonate_contractor' })}
            </Button>
            {(isAdmin || isSuperAdmin) && (
              <>
                <Button
                  size='medium'
                  variant='contained'
                  color='error'
                  onClick={() => {
                    setIsDeleteConfirmationOpened(true);
                  }}
                >
                  {intl.formatMessage({ id: 'delete' })}
                </Button>
                <ConfirmationDialog
                  open={isDeleteConfirmationOpened}
                  onClose={() => {
                    setIsDeleteConfirmationOpened(false);
                  }}
                  onConfirm={handleDeleteRegistration.execute}
                  confirmButtonContent={intl.formatMessage({ id: 'delete' })}
                  title={intl.formatMessage({ id: 'registration_delete' })}
                  content={
                    [RegistrationStatus.vREGISTERED, RegistrationStatus.xNOT_GOING_AHEAD].includes(
                      registration.status
                    ) && !registration.locked
                      ? intl.formatMessage({ id: 'registration_delete_body_with_archive' })
                      : intl.formatMessage({ id: 'registration_delete_body' })
                  }
                  additionalButton={
                    [RegistrationStatus.vREGISTERED, RegistrationStatus.xNOT_GOING_AHEAD].includes(
                      registration.status
                    ) && !registration.locked ? (
                      <Button
                        size='medium'
                        variant='outlined'
                        onClick={async () => {
                          await archiveRegistration({ variables: { id } });
                          setIsDeleteConfirmationOpened(false);
                        }}
                        sx={{ marginRight: '12px' }}
                      >
                        {intl.formatMessage({ id: 'archive' })}
                      </Button>
                    ) : undefined
                  }
                />
              </>
            )}
          </Box>
        </Box>
      </Footer>
    </Box>
  );
};
