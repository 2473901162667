import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const FileLockedIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[400];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 7V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H14.5L20 7.5V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H12'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M14 2V8H20M5 17C5.39397 17 5.78407 16.9224 6.14805 16.7716C6.51203 16.6209 6.84274 16.3999 7.12132 16.1213C7.3999 15.8427 7.62087 15.512 7.77164 15.1481C7.9224 14.7841 8 14.394 8 14C8 13.606 7.9224 13.2159 7.77164 12.8519C7.62087 12.488 7.3999 12.1573 7.12132 11.8787C6.84274 11.6001 6.51203 11.3791 6.14805 11.2284C5.78407 11.0776 5.39397 11 5 11C4.20435 11 3.44129 11.3161 2.87868 11.8787C2.31607 12.4413 2 13.2044 2 14C2 14.7956 2.31607 15.5587 2.87868 16.1213C3.44129 16.6839 4.20435 17 5 17V17Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M7.98387 16.3211C7.88507 15.7777 7.36449 15.4173 6.82111 15.5161C6.27774 15.6149 5.91733 16.1355 6.01613 16.6789L7.98387 16.3211ZM8 22L7.68377 22.9487C8.01898 23.0604 8.38827 22.987 8.6552 22.7555C8.92213 22.5239 9.04708 22.1688 8.98387 21.8211L8 22ZM5 21L5.31623 20.0513L5 19.9459L4.68377 20.0513L5 21ZM2 22L1.01613 21.8211C0.952923 22.1688 1.07787 22.5239 1.3448 22.7555C1.61173 22.987 1.98102 23.0604 2.31623 22.9487L2 22ZM3.98387 16.6789C4.08267 16.1355 3.72226 15.6149 3.17889 15.5161C2.63551 15.4173 2.11493 15.7777 2.01613 16.3211L3.98387 16.6789ZM6.01613 16.6789L7.01613 22.1789L8.98387 21.8211L7.98387 16.3211L6.01613 16.6789ZM8.31623 21.0513L5.31623 20.0513L4.68377 21.9487L7.68377 22.9487L8.31623 21.0513ZM4.68377 20.0513L1.68377 21.0513L2.31623 22.9487L5.31623 21.9487L4.68377 20.0513ZM2.98387 22.1789L3.98387 16.6789L2.01613 16.3211L1.01613 21.8211L2.98387 22.1789Z'
        fill={currentColor}
      />
    </svg>
  );
};
