import gql from 'graphql-tag';

export const registrationsMatchQuery = gql`
  query RegistrationsMatch($offset: Int!, $limit: Int!, $query: String) {
    registrations(limit: $limit, offset: $offset, query: $query) {
      total
      data {
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export const contractorsMatchQuery = gql`
  query ContractorsMatch($args: ContractorsArgs!) {
    contractors(args: $args) {
      total
      data {
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export const tenantsMatchQuery = gql`
  query TenantsMatch($offset: Int!, $limit: Int!, $query: String, $orderBy: TenantOrder) {
    tenants(limit: $limit, offset: $offset, query: $query, orderBy: $orderBy) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const companyFAQLinkQuery = gql`
  query CompanyFAQLink {
    companyLinks {
      faqLink
    }
  }
`;
