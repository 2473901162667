import gql from 'graphql-tag';

export const getFormsQuery = gql`
  query Forms($statuses: [FormStatus!], $search: String, $orderBy: FormOrder!, $limit: Int!, $offset: Int!) {
    forms(statuses: $statuses, search: $search, orderBy: $orderBy, limit: $limit, offset: $offset) {
      data {
        id
        name
        description
        creator {
          firstName
          lastName
        }
        createdAt
        publishedAt
        status
      }
      total
    }
  }
`;

export const deleteFormMutation = gql`
  mutation DeleteForm($id: String!) {
    deleteForm(id: $id)
  }
`;

export const copyFormMutation = gql`
  mutation CopyForm($id: String!) {
    copyForm(id: $id)
  }
`;

export const getRelationshipsQuery = gql`
  query Relationships($filters: RelationshipFilters!) {
    relationships(filters: $filters) {
      data {
        id
        name
        variable
        description
        relationshipType
      }
      total
    }
  }
`;

export const setParentForMutation = gql`
  mutation SetParentFor($childrenIds: [String!]!, $parentId: String!) {
    setParentFor(childrenIds: $childrenIds, parentId: $parentId)
  }
`;

export const makeChildOfMutation = gql`
  mutation MakeChildOf($childId: String!, $parentId: String!) {
    makeChildOf(childId: $childId, parentId: $parentId)
  }
`;

export const makeSeparatedMutation = gql`
  mutation MakeSeparated($id: String!) {
    makeSeparated(id: $id)
  }
`;

export const formQuestionsQuery = gql`
  query FormQuestions($formId: String!) {
    formQuestions(formId: $formId) {
      id
      section {
        id
        name
        parentId
      }
      variable
      description
    }
  }
`;

export const addedComponentsQuery = gql`
  query AddedComponents($formId: String!) {
    addedComponents(formId: $formId)
  }
`;

export const parseDocumentMutation = gql`
  mutation ParsedDocument($file: Upload!) {
    parseDocument(file: $file) {
      content
      variables
    }
  }
`;

export const saveDocumentContentMutation = gql`
  mutation SaveDocumentContent($data: Upload!) {
    saveDocumentContentToQuestion(data: $data)
  }
`;

export const deleteSectionMutation = gql`
  mutation DeleteSection($id: String!, $childrenAsSeparate: Boolean!) {
    deleteSection(id: $id, childrenAsSeparate: $childrenAsSeparate)
  }
`;

export const updateQuestionOrderMutation = gql`
  mutation UpdateQuestionOrder($id: String!, $order: Int!) {
    updateQuestionOrder(id: $id, order: $order)
  }
`;

export const countOfRules = gql`
  query CountOfIssuerRules($issuerId: String!) {
    issuerRules(issuerId: $issuerId) {
      id
    }
  }
`;
