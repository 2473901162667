import { Font } from '__graphql__/globalTypes';
import { useContext } from 'react';
import { CompanyContext } from 'shared/contexts';
import { usePalette } from '../usePalette';

export function useCustomStyles(): Record<string, object> {
  const { primary, secondary } = usePalette();
  const companyContextValues = useContext(CompanyContext);

  return {
    highlighter: {
      backgroundColor: primary[200],
      color: 'inherit',
      borderRadius: '4px',
      padding: '3px 0.5px',
      margin: '-3px -0.5px'
    },
    pdfFrame: {
      border: `1px solid ${primary[400]}`,
      borderRadius: '8px'
    },
    editor: {
      maxHeight: '190px',
      overflow: 'auto',
      color: secondary[700],
      fontFamily: companyContextValues.font === Font.MYRIAD_PRO ? 'Myriad Pro' : 'Assistant'
    },
    carousel: {
      '.carousel': {
        borderRadius: '16px'
      },
      '.dot': {
        boxShadow: 'none',
        background: secondary[400]
      },
      '.dot.selected': {
        background: primary[600]
      }
    }
  };
}
