import { Box, Button, Popover, Typography } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useContext, useMemo, type FC, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { ComponentRenderer } from 'shared/components/form';
import { CloneIcon, EditIcon, RuleIcon, TrashIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { RuleBuilder } from '../RuleBuilder';
import { shouldBeUniqueComponent } from '../utils';
import { BasicInfoSection } from './BasicInfoSection';
import { BASIC_INFO_SECTION, DroppableArea, TEXT_COMPONENTS } from './constants';
import { StrictModeDroppable } from './StrictModeDroppable';
import { getComponentWrapperStyles } from './utlis';
import { WidgetCard } from './WidgetCard';

export const Canvas: FC = () => {
  const { formatMessage } = useIntl();
  const palettes = usePalette();
  const { primary } = palettes;
  const {
    questions,
    section,
    component,
    setComponent,
    cloneQuestion,
    setEditMainProperty,
    deleteQuestion,
    isReadOnly,
    formErrors
  } = useContext(FormBuilderContext);
  const [ruleIssuerId, setRuleIssuerId] = useState<string>('');

  const COMPONENT_ACTIONS = useMemo(
    () => [
      {
        id: 'clone',
        onClick: async () => {
          await cloneQuestion({ variables: { id: component?.id! } });
        },
        Icon: CloneIcon
      },
      {
        id: 'edit',
        onClick: async () => {
          setEditMainProperty(true);
        },
        Icon: EditIcon
      },
      {
        id: 'delete',
        onClick: async () => {
          setComponent(null);
          await deleteQuestion({
            variables: { id: component?.id! }
          });
        },
        Icon: TrashIcon
      },
      {
        id: 'adjust_rule',
        onClick: async () => {
          setRuleIssuerId(component?.id!);
        },
        Icon: RuleIcon
      }
    ],
    [cloneQuestion, component?.id, deleteQuestion, setComponent, setEditMainProperty]
  );

  return (
    <>
      <WidgetCard
        sx={{ flex: 2, height: '100%' }}
        header={
          <Typography variant='h3' color='secondary.700'>
            {section?.name}
          </Typography>
        }
        body={
          section?.id === BASIC_INFO_SECTION.id ? (
            <BasicInfoSection isFormBuilder />
          ) : (
            <StrictModeDroppable droppableId={DroppableArea.Canvas}>
              {(provided) => (
                <Box sx={{ minHeight: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
                  {questions.length ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px'
                      }}
                    >
                      {questions.map((i, index) => (
                        <Draggable key={i.id} isDragDisabled={isReadOnly} draggableId={i.id} index={index}>
                          {(provided) => (
                            <PopupState variant='popover' popupId='popover'>
                              {(popupState) => {
                                const { onClick, onTouchStart, ...ariaProps } = bindTrigger(popupState);
                                const { onClose, ...rest } = bindPopover(popupState);

                                return (
                                  <>
                                    <Box
                                      {...ariaProps}
                                      onContextMenu={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        onClick(event);
                                        setComponent(i);
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setComponent(i);
                                      }}
                                      onTouchStart={(event) => {
                                        event.stopPropagation();
                                        onTouchStart(event);
                                      }}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      sx={getComponentWrapperStyles({
                                        palettes,
                                        currentId: i.id,
                                        activeId: component?.id,
                                        requiredAttention: !!formErrors[i.id]
                                      })}
                                    >
                                      <ComponentRenderer {...i} />
                                    </Box>
                                    {!isReadOnly && (
                                      <Popover
                                        {...rest}
                                        onClose={(e: any) => {
                                          e.stopPropagation();
                                          onClose();
                                        }}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right'
                                        }}
                                        sx={{
                                          '& .MuiPaper-rounded': { borderRadius: '8px' }
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'self-start',
                                            border: `1px solid ${primary[600]}`,
                                            borderRadius: '8px',
                                            pY: '4px',
                                            '& > button': {
                                              width: '100%',
                                              padding: '8px 16px'
                                            }
                                          }}
                                        >
                                          {COMPONENT_ACTIONS.filter(({ id }) =>
                                            shouldBeUniqueComponent(i.component)
                                              ? id !== 'clone'
                                              : TEXT_COMPONENTS.includes(i.component)
                                              ? id !== 'adjust_rule'
                                              : true
                                          ).map(({ onClick, id, Icon }) => (
                                            <Button
                                              key={id}
                                              onClick={async (e) => {
                                                e.stopPropagation();
                                                await onClick();
                                                popupState.close();
                                              }}
                                            >
                                              <Icon color={primary[600]} />
                                            </Button>
                                          ))}
                                        </Box>
                                      </Popover>
                                    )}
                                  </>
                                );
                              }}
                            </PopupState>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '100%'
                      }}
                    >
                      <Typography variant='body2' sx={{ color: 'secondary.700', fontWeight: 'normal' }}>
                        {formatMessage({ id: 'empty_form_page' })}
                        <br />
                        {formatMessage({ id: 'add_from_components' })}
                      </Typography>
                    </Box>
                  )}
                  {provided.placeholder}
                </Box>
              )}
            </StrictModeDroppable>
          )
        }
      />
      {!!ruleIssuerId && <RuleBuilder issuerId={ruleIssuerId} onClose={() => setRuleIssuerId('')} />}
    </>
  );
};
