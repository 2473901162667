import { AppBar, Button, Grid, Link, Toolbar } from '@mui/material';
import { type FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import routes from 'shared/constants/routes';
import { CompanyContext } from 'shared/contexts';
import AuthContext from 'shared/contexts/authContext';
import { usePalette } from 'shared/hooks';

export const Header: FC = () => {
  const intl = useIntl();
  const { secondary } = usePalette();

  const { logo } = useContext(CompanyContext);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <AppBar
      position='static'
      color='inherit'
      elevation={1}
      sx={{ p: 0, mb: 0, borderBottom: `1px solid ${secondary[100]}` }}
    >
      <Toolbar sx={{ paddingRight: '41px !important', paddingLeft: '41px !important' }}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Link
              onClick={() => {
                navigate(routes.rootForNavigation);
              }}
            >
              <img height='40px' src={logo?.url ? logo.url : '/swift-onboard-logo.svg'} alt='logo' />
            </Link>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              onClick={async () => {
                await logout.execute();
              }}
            >
              {intl.formatMessage({ id: 'logout' })}
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
