import { Autocomplete, TextField, type AutocompleteProps, type TextFieldProps } from '@mui/material';
import { type FieldProps } from 'formik';
import { useState, type FC, type KeyboardEvent } from 'react';

type Props = FieldProps &
  Pick<TextFieldProps, 'InputProps' | 'InputLabelProps'> & {
    onChange?: (event: KeyboardEvent, options: string[]) => Promise<boolean>;
    renderTags?: AutocompleteProps<string, true, true, true, 'div'>['renderTags'];
  };

export const InputMultipleValues: FC<Props> = (props) => {
  const { onChange, renderTags, ...rest } = props;

  const [values, setValues] = useState<string[]>(rest.field.value);

  return (
    <Autocomplete
      placeholder='To'
      disableClearable
      sx={{ '.MuiInputBase-root': { pr: '14px' } }}
      PopperComponent={() => null}
      {...props}
      value={values}
      onChange={async (event, choosenOption) => {
        if (await onChange?.(event as KeyboardEvent, choosenOption)) {
          return;
        }

        setValues(choosenOption);
        rest.form.setFieldValue(rest.field.name, choosenOption);
      }}
      freeSolo
      fullWidth
      multiple
      readOnly={rest.InputProps?.readOnly}
      options={[]}
      renderTags={renderTags}
      renderInput={(params) => {
        const { InputLabelProps, InputProps } = params;
        const resolvedErrorMessage = rest.form.errors[rest.field.name];

        return (
          <TextField
            {...rest}
            {...params}
            helperText={resolvedErrorMessage as string | undefined}
            InputLabelProps={{
              ...InputLabelProps,
              ...props.InputLabelProps,
              className: 'form',
              classes: { shrink: 'MuiFormLabel-filled' }
            }}
            InputProps={{
              ...InputProps,
              ...props.InputProps,
              className: 'form',
              error: !!resolvedErrorMessage
            }}
          />
        );
      }}
    />
  );
};
