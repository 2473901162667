import { useQuery } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { QuestionComponent, RegistrationStatus, type OptionInput } from '__graphql__/globalTypes';
import { Form, Formik, useFormikContext } from 'formik';
import { compose, defaultTo, equals, mapObjIndexed, not, pickBy } from 'ramda';
import { useContext, useMemo, type FC } from 'react';
import { useIntl } from 'react-intl';
import { InputComponent } from 'shared/components/form/InputComponent';
import { SelectComponent } from 'shared/components/form/SelectComponent';
import { TextComponent } from 'shared/components/form/TextComponent';
import { type Agencies, type AgenciesVariables } from 'shared/components/RegistrationModal/__graphql__/Agencies';
import { type AvailableFormTypes } from 'shared/components/RegistrationModal/__graphql__/AvailableFormTypes';
import { agenciesQuery, availableFormTypesQuery } from 'shared/components/RegistrationModal/api';
import { FormikListener } from 'shared/components/RegistrationModal/FormikListener';
import routes from 'shared/constants/routes';
import { FormBuilderContext } from 'shared/contexts';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { usePalette, useRoles, useTenantRoute } from 'shared/hooks';
import { BASIC_INFO_QUESTIONS, BasicInfoVariables } from 'shared/hooks/useFormBuilder/constants';
import { Validator } from 'shared/utils/validator';
import { getComponentWrapperStyles } from './utlis';

export interface BasicInfoValues {
  title: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
  contractType: string | undefined;
  agencyId: string | undefined;
  consultantId: string | undefined;
  weeklyFee: string | undefined;
  contractorId: string | undefined;
}

interface Props {
  isFormBuilder?: boolean;
  disabled?: boolean;
  goToNextTab?: () => void;
}

const Questions: FC<Omit<Props, 'goToNextTab'>> = ({ disabled, isFormBuilder }) => {
  const { component, setComponent } = useContext(FormBuilderContext);
  const { previewForm } = useContext(RegistrationContext);
  const { values } = useFormikContext<BasicInfoValues>();

  const palettes = usePalette();
  const { data: agencies } = useQuery<Agencies, AgenciesVariables>(agenciesQuery, {
    variables: {
      offset: 0,
      limit: 1000
    }
  });

  const { data } = useQuery<AvailableFormTypes>(availableFormTypesQuery);

  const agenciesOptions: OptionInput[] = useMemo(
    () =>
      agencies?.agencies.data?.map((agency) => ({
        optionId: agency.id,
        value: agency.id,
        label: agency.name
      })) ?? [],
    [agencies?.agencies.data]
  );

  const contractTypesOptions: OptionInput[] = useMemo(
    () => [
      ...(data?.availableFormTypes.includes(values.contractType!) || !values.contractType
        ? []
        : [
            {
              optionId: values.contractType,
              value: values.contractType,
              label: values.contractType
            }
          ]),
      ...(data?.availableFormTypes.map((t) => ({
        optionId: t,
        value: t,
        label: t
      })) ?? [])
    ],
    [data?.availableFormTypes, values.contractType]
  );

  const consultantsOptions: OptionInput[] = useMemo(
    () =>
      agencies?.agencies?.data
        ?.find((agency) => agency.id === values.agencyId)
        ?.consultants?.map((con) => ({
          optionId: con.id,
          label: `${con.firstName} ${con.lastName}`,
          value: con.id
        })) ?? [],
    [agencies?.agencies?.data, values.agencyId]
  );

  const additionalComponentProps = useMemo(
    () =>
      new Map([
        [
          BasicInfoVariables.Agency,
          {
            options: agenciesOptions,
            disabled: !agenciesOptions.length,
            validate: Validator.pipe(Validator.methods.required())
          }
        ],
        [
          BasicInfoVariables.Consultant,
          {
            options: consultantsOptions,
            disabled: !consultantsOptions.length || !values.agencyId,
            validate: Validator.pipe(Validator.methods.required())
          }
        ],
        [
          BasicInfoVariables.FirstName,
          { validate: Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3)) }
        ],
        [
          BasicInfoVariables.Surname,
          { validate: Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3)) }
        ],
        [BasicInfoVariables.PhoneNumber, { validate: Validator.pipe(Validator.methods.phone()) }],
        [BasicInfoVariables.Email, { validate: Validator.pipe(Validator.methods.email()) }],
        [
          BasicInfoVariables.ContractType,
          {
            disabled: !!previewForm.id,
            options: contractTypesOptions,
            validate: Validator.pipe(Validator.methods.required())
          }
        ],
        [BasicInfoVariables.WeeklyFee, { validate: Validator.pipe(Validator.methods.min(0)) }]
      ]),
    [agenciesOptions, consultantsOptions, contractTypesOptions, previewForm.id, values.agencyId]
  );

  return (
    <>
      {BASIC_INFO_QUESTIONS.map((q) => {
        let current = null;

        const props = additionalComponentProps.get(q.variable as BasicInfoVariables) ?? { disabled: false };
        if (q.component === QuestionComponent.HEADER_3) {
          current = <TextComponent {...q} {...props} />;
        } else if (q.component === QuestionComponent.SHORT_TEXT) {
          current = <InputComponent {...q} {...props} disabled={props.disabled || disabled} />;
        } else if (q.component === QuestionComponent.SINGLE_SELECT) {
          current = <SelectComponent {...q} {...props} disabled={props.disabled || disabled} />;
        }

        return (
          <Box
            key={q.id}
            sx={
              isFormBuilder
                ? getComponentWrapperStyles({
                    palettes,
                    activeId: component?.id,
                    currentId: q.id
                  })
                : {}
            }
            onClick={(event) => {
              if (isFormBuilder) {
                event.stopPropagation();
                event.preventDefault();
                setComponent(q);
              }
            }}
          >
            {current}
          </Box>
        );
      })}
    </>
  );
};

export const BasicInfoSection: FC<Props> = ({ goToNextTab, isFormBuilder }) => {
  const { previewForm, previewFormValues, basicInfo, registration, save, id, setId, isDirty, setIsDirty, sections } =
    useContext(RegistrationContext);

  const { secondary } = usePalette();
  const { navigateWithTenant } = useTenantRoute();
  const { isStaffUser, isContractor } = useRoles();
  const { formatMessage } = useIntl();

  const initialValues: BasicInfoValues = {
    ...mapObjIndexed(defaultTo(''), {
      title: previewForm.id ? previewFormValues.title : basicInfo?.title ?? registration?.title,
      firstName: previewForm.id ? previewFormValues.firstName : basicInfo?.firstName ?? registration?.firstName,
      lastName: previewForm.id ? previewFormValues.lastName : basicInfo?.lastName ?? registration?.lastName,
      phoneNumber: previewForm.id ? previewFormValues.phoneNumber : basicInfo?.phoneNumber ?? registration?.phoneNumber,
      email: previewForm.id ? previewFormValues.email : basicInfo?.email ?? registration?.email,
      contractType: previewForm.id
        ? previewFormValues.contractType
        : basicInfo?.contractType ?? registration?.form?.contractType,
      agencyId: previewForm.id ? previewFormValues.agencyId : registration?.agencyId,
      consultantId: previewForm.id ? previewFormValues.consultantId : registration?.consultantId,
      weeklyFee: previewForm.id ? previewFormValues.weeklyFee : registration?.weeklyFee,
      contractorId: previewForm.id ? previewFormValues.contractorId : basicInfo?.contractorId
    })
  };

  const contractorReadonly =
    isContractor && [RegistrationStatus.gIN_PROGRESS, RegistrationStatus.iFORM_SENT].includes(registration?.status!);

  const disabled = [
    RegistrationStatus.gIN_PROGRESS,
    RegistrationStatus.lID_CHECKS,
    RegistrationStatus.vREGISTERED,
    RegistrationStatus.xNOT_GOING_AHEAD,
    RegistrationStatus.tUPLOAD_TO_PAYROLL,
    RegistrationStatus.oAWAITING_ID
  ].includes(registration?.status!);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (formValues, { validateForm, setSubmitting }) => {
        const errors = await validateForm();

        if (Object.keys(errors).length) {
          return;
        }

        if (isDirty) {
          const values: BasicInfoValues = pickBy(compose(not, equals('')))(formValues);
          const response = await save({
            variables: {
              data: {
                ...values,
                weeklyFee: values.weeklyFee && (Math.floor(Number(values.weeklyFee) * 100) / 100).toString(),
                ...(id ? { id } : {})
              }
            }
          });

          if (response.data?.saveRegistration.id) {
            setId(response.data.saveRegistration.id);
            setSubmitting(false);
            setIsDirty([false, () => {}]);
            if (!id && isStaffUser && !previewForm.id) {
              navigateWithTenant(routes.registration.replace(':id', response.data.saveRegistration.id));
            }
          }
          goToNextTab?.();
        } else if (sections.length) {
          goToNextTab?.();
        }
      }}
    >
      {({ isSubmitting, dirty, submitForm }) => (
        <Form>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              width: isFormBuilder ? undefined : '400px',
              '@media only screen and (max-width: 620px)': {
                margin: '0 auto',
                maxWidth: '100%',
                width: '100%'
              }
            }}
          >
            <Questions disabled={disabled || contractorReadonly} isFormBuilder={isFormBuilder} />
          </Box>
          <Box
            sx={{
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'flex-end',
              background: secondary[100],
              p: '20px 48px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100vw'
            }}
          >
            <Button type='submit' onClick={submitForm} disabled={isSubmitting} variant='contained'>
              {formatMessage({ id: dirty ? 'save_and_next' : 'next' })}
            </Button>
          </Box>
          <FormikListener />
        </Form>
      )}
    </Formik>
  );
};
