import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const SuccessOctagonIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { success } = usePalette();
  const currentColor = color ?? success[300];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 10L10.5 15L8 12.7273'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
