import { useMutation, useQuery } from '@apollo/client';
import { type ErrorResponse } from '@apollo/client/link/error';
import { Box, Button } from '@mui/material';
import { Role, Title, type InviteInput } from '__graphql__/globalTypes';
import { Field } from 'formik';
import { useMemo, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { addNewUserMutation, inviteMutation, usersWithNameQuery } from 'shared/api';
import { type AddNewUser, type AddNewUserVariables } from 'shared/api/__graphql__/AddNewUser';
import { type Invite, type InviteVariables } from 'shared/api/__graphql__/Invite';
import { type UsersWithName, type UsersWithNameVariables } from 'shared/api/__graphql__/UsersWithName';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { FilterableSelect, FormDialog, Input, Select } from 'shared/components';
import { InputTooltip } from 'shared/components/form';
import ErrorSnackbar from 'shared/components/Snackbars/ErrorSnackbar';
import { isConflictError } from 'shared/utils/errors';
import { getEmailFromValues } from 'shared/utils/users';
import { Validator } from 'shared/utils/validator';

export const ContractorForm = ({ emailExists }: { emailExists?: boolean }): JSX.Element => {
  const intl = useIntl();

  const titleOptions = useMemo(() => Object.values(Title).map((t) => ({ value: t, label: t })), []);

  const contractorOwners = useQuery<UsersWithName, UsersWithNameVariables>(usersWithNameQuery, {
    variables: {
      offset: 0,
      limit: 1000,
      filter: {
        roles: [Role.ADMIN, Role.CUSTOMER_CARE, Role.REGISTRAR],
        isActive: true
      }
    },
    context: API_CONTEXT.AUTH
  });

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Field
        id='title'
        name='title'
        label={intl.formatMessage({ id: 'title' })}
        component={Select}
        options={titleOptions}
      />
      <Field
        id='firstName'
        name='firstName'
        label={intl.formatMessage({ id: 'name' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3))}
      />
      <Field
        id='lastName'
        name='lastName'
        label={intl.formatMessage({ id: 'surname' })}
        component={Input}
        validate={Validator.pipe(Validator.methods.required(), Validator.methods.minLength(3))}
      />
      <Field
        id='email'
        name='email'
        label={intl.formatMessage({ id: 'email' })}
        type='email'
        component={Input}
        validate={Validator.pipe(
          ...[...(emailExists ? [Validator.methods.required()] : []), Validator.methods.email()]
        )}
      />
      <Field
        id='phoneNumber'
        name='phoneNumber'
        label={intl.formatMessage({ id: 'phone_number' })}
        type='tel'
        component={Input}
        validate={Validator.pipe(Validator.methods.phone())}
      />
      <Field
        id='assignedTo'
        name='assignedTo'
        label={intl.formatMessage({ id: 'assigned_to' })}
        component={FilterableSelect}
        InputProps={{
          endAdornment: (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InputTooltip
                title={intl.formatMessage({
                  id: 'select_responsible_user'
                })}
              />
            </Box>
          )
        }}
        options={
          contractorOwners?.data?.users?.data?.map(({ id, firstName, lastName }) => ({
            value: id,
            label: `${firstName} ${lastName}`.trim()
          })) ?? []
        }
      />
    </Box>
  );
};

export const AddContractorWidget: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const [addNewUser, { loading: createLoading }] = useMutation<AddNewUser, AddNewUserVariables>(addNewUserMutation, {
    refetchQueries: ['Contractors'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const [inviteUser, { loading: inviteLoading }] = useMutation<Invite, InviteVariables>(inviteMutation, {
    refetchQueries: ['Contractors'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  return (
    <>
      <Button
        type='button'
        size='medium'
        variant='contained'
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {intl.formatMessage({ id: 'new_contractor' })}
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        header={intl.formatMessage({ id: 'add_new_contractor' })}
        size='xs'
        loading={createLoading || inviteLoading}
        initialValues={{
          role: Role.CONTRACTOR
        }}
        confirmBtnName={intl.formatMessage({ id: 'save' })}
        onSubmit={async ({ email, ...values }: InviteInput & { role: Role }, formikHelpers) => {
          try {
            let id;
            const data = {
              ...values,
              assignedTo: values.assignedTo || null
            };
            if (email) {
              const response = await inviteUser({ variables: { data: { email, ...data } } });
              id = response.data?.invite?.id;
            } else {
              const response = await addNewUser({ variables: { data: { ...getEmailFromValues({ email }), ...data } } });
              id = response.data?.addUser?.id;
            }
            if (id) navigate(id);
            setModalOpen(false);
          } catch (e) {
            if (isConflictError((e as ErrorResponse).graphQLErrors?.[0].extensions.code as string)) {
              toast(
                <ErrorSnackbar
                  title={intl.formatMessage({ id: 'email_alredy_in_use' })}
                  message={intl.formatMessage({ id: 'you_cant_create_user_with_existing_email' })}
                />,
                {
                  position: toast.POSITION.BOTTOM_CENTER
                }
              );
              formikHelpers?.setFieldError('email', ' ');
            }
          }
        }}
      >
        <ContractorForm />
      </FormDialog>
    </>
  );
};
