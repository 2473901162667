export const getExpiringDate = (data?: Record<'expirationYear' | 'expirationMonth', number>): string => {
  if (data) {
    const { expirationMonth, expirationYear } = data;
    return `${expirationMonth}/${expirationYear % 100}`;
  }
  return '';
};

export const getCardNumber = (number?: string): string => {
  return number ? `**** **** **** ${number}` : '';
};

export const getTruncatedNumber = (value: number): number => {
  let truncatedNumber = parseFloat(value.toFixed(2));

  if (truncatedNumber % 1 === 0) {
    truncatedNumber = parseInt(truncatedNumber.toString());
  }

  return truncatedNumber;
};
