import gql from 'graphql-tag';

export const tenantSetupFinishedQuery = gql`
  query TenantSetupFinished($id: String!) {
    tenant(id: $id) {
      id
      setupFinished
    }
  }
`;
