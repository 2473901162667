import {
  Autocomplete,
  ListItem,
  TextField,
  type AutocompleteInputChangeReason,
  type TextFieldProps
} from '@mui/material';
import { type FieldProps } from 'formik';
import { path, split } from 'ramda';
import { useEffect, useState, type SyntheticEvent, useCallback, type ChangeEvent, type FocusEvent } from 'react';
import Highlighter from 'react-highlight-words';
import { useCustomStyles } from 'shared/hooks';

interface DropdownOption {
  label: string;
  value: string;
}

type Props = FieldProps &
  Pick<TextFieldProps, 'InputProps' | 'InputLabelProps'> & {
    options: DropdownOption[];
  };

const NO_VALUE_OPTION = { label: '', value: '' };

export function FilterableSelect(props: Props): JSX.Element {
  const [currentOption, setCurrentOption] = useState<DropdownOption>(NO_VALUE_OPTION);
  const { options, InputProps, InputLabelProps, form, field } = props;
  const customStyles = useCustomStyles();

  const optionsWithDefaultValues = [NO_VALUE_OPTION, ...options];
  useEffect(() => {
    if (props.options?.length && props.field.value) {
      setCurrentOption(props.options.find((o) => o.value === props.field.value) ?? NO_VALUE_OPTION);
    }
  }, [props.options, props.field.value]);
  const handleInputChange = (
    _: SyntheticEvent<Element, Event>,
    inputValue: string,
    reason: AutocompleteInputChangeReason
  ): void => {
    if (reason === 'input' && inputValue === NO_VALUE_OPTION.value) {
      setCurrentOption(NO_VALUE_OPTION);
      form.setFieldValue(field.name, NO_VALUE_OPTION.value);
    }
  };

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.target.value = e.target.value.trimStart();

      if (field.onChange) field.onChange(e.target.value);
      if (form.errors[field.name]) form.setFieldError(field.name, undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onChange, form.errors[field.name]]
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (field.onBlur) field.onBlur(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onBlur]
  );

  const resolvedErrorMessage =
    form && field?.name && (path(split('.', field.name), form.touched) || form.submitCount > 0)
      ? path(split('.', field.name), form.errors)
      : undefined;

  return (
    <Autocomplete
      fullWidth
      disableClearable
      forcePopupIcon={false}
      onInputChange={handleInputChange}
      options={optionsWithDefaultValues}
      getOptionLabel={({ label }) => label}
      onChange={(_, choosenOption) => {
        setCurrentOption(choosenOption);
        form.setFieldValue(field.name, choosenOption.value);
      }}
      isOptionEqualToValue={(option, { value }) => option.value === value}
      value={currentOption}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={resolvedErrorMessage as string | undefined}
          error={!!resolvedErrorMessage}
          onBlur={onBlur}
          onChange={onChange}
          InputLabelProps={InputLabelProps}
          InputProps={{ ...params.InputProps, ...InputProps }}
        />
      )}
      renderOption={(renderProps, option, { inputValue }) => {
        if (option.value === NO_VALUE_OPTION.value) {
          return null;
        }
        return (
          <ListItem key={option.value} value={option.value} {...renderProps}>
            <Highlighter
              autoEscape
              highlightStyle={customStyles.highlighter}
              searchWords={[inputValue]}
              textToHighlight={option.label}
            />
          </ListItem>
        );
      }}
    />
  );
}
