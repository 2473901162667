import { Role } from '__graphql__/globalTypes';
import { useContext, useMemo } from 'react';
import AuthContext from 'shared/contexts/authContext';

interface IsRoles {
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isRegistrar: boolean;
  isContractor: boolean;
  isCustomerCare: boolean;
  isConsultant: boolean;
  isStaffUser: boolean;
}

export const useRoles = (): IsRoles => {
  const { authState } = useContext(AuthContext);

  const roles = useMemo(
    () => ({
      isSuperAdmin: authState.user?.role === Role.SUPER_ADMIN,
      isAdmin: authState.user?.role === Role.ADMIN,
      isRegistrar: authState.user?.role === Role.REGISTRAR,
      isContractor: authState.user?.role === Role.CONTRACTOR,
      isCustomerCare: authState.user?.role === Role.CUSTOMER_CARE,
      isConsultant: authState.user?.role === Role.CONSULTANT,
      isStaffUser: [Role.SUPER_ADMIN, Role.ADMIN, Role.CUSTOMER_CARE, Role.REGISTRAR].includes(
        authState.user?.role as Role
      )
    }),
    [authState.user?.role]
  );
  return roles;
};
