import gql from 'graphql-tag';

export const setCompanyLinksMutation = gql`
  mutation SetCompanyLinks($data: CompanyLinksData!) {
    setCompanyLinks(data: $data)
  }
`;

export const retryPaymentMutation = gql`
  mutation RetryPayment {
    retryPayment
  }
`;
