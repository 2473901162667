import { Typography } from '@mui/material';
import { type Variant } from '@mui/material/styles/createTypography';
import { QuestionComponent } from '__graphql__/globalTypes';
import { type FC } from 'react';
import { type Component } from 'shared/contexts';
import { TextWithNewLines } from '../TextWithNewLines';

type TextComponents =
  | QuestionComponent.HEADER_1
  | QuestionComponent.HEADER_2
  | QuestionComponent.HEADER_3
  | QuestionComponent.PARAGRAPH;

const STYLES: Record<TextComponents, Record<'fontSize' | 'fontWeight', string>> = {
  [QuestionComponent.HEADER_1]: { fontSize: '24px', fontWeight: '400' },
  [QuestionComponent.HEADER_2]: { fontSize: '20px', fontWeight: '600' },
  [QuestionComponent.HEADER_3]: { fontSize: '18px', fontWeight: '600' },
  [QuestionComponent.PARAGRAPH]: { fontSize: '16px', fontWeight: '400' }
};

const VARIANTS: Record<TextComponents, Variant> = {
  [QuestionComponent.HEADER_1]: 'h1',
  [QuestionComponent.HEADER_2]: 'h2',
  [QuestionComponent.HEADER_3]: 'h3',
  [QuestionComponent.PARAGRAPH]: 'body1'
};

export const TextComponent: FC<Component> = ({ text, component }) => {
  return (
    <Typography
      variant={VARIANTS[component as TextComponents]}
      color='secondary.700'
      sx={{
        ...STYLES[component as TextComponents],
        lineHeight: '28px'
      }}
    >
      <TextWithNewLines text={text ?? ''} />
    </Typography>
  );
};
