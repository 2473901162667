import { Box, Typography } from '@mui/material';
import { useMemo, type FC } from 'react';
import { useIntl } from 'react-intl';
import { usePalette } from 'shared/hooks';

interface Props {
  nameIntl: string;
  value?: number;
  icon?: JSX.Element;
}

export const Statistic: FC<Props> = ({ nameIntl, value, icon = <></> }) => {
  const intl = useIntl();
  const { primary, secondary, success, error, warning } = usePalette();

  const colorScheme = useMemo(() => {
    switch (nameIntl) {
      case 'new_registrations':
        return {
          borderColor: primary[500],
          backgroundColor: primary[200]
        };
      case 'active_tenants': {
        return {
          borderColor: warning[200],
          backgroundColor: warning[50]
        };
      }
      case 'completed_registrations':
      case 'active_users':
        return {
          borderColor: success[200],
          backgroundColor: success[50]
        };
      case 'did_not_proceed':
      case 'total_revenue':
        return {
          borderColor: error[200],
          backgroundColor: error[50]
        };
      default:
        return {
          borderColor: secondary[500],
          backgroundColor: secondary[100]
        };
    }
  }, [nameIntl, primary, success, error, secondary, warning]);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        padding: '12px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderWidth: '1px',
        borderStyle: 'solid',
        ...colorScheme
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='subtitle2'>{intl.formatMessage({ id: nameIntl })}</Typography>
        {icon}
      </Box>
      <Typography variant='body2' fontSize='24px'>
        {value ?? '-'}
      </Typography>
    </Box>
  );
};
