import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Field, FormikContext, type FieldProps } from 'formik';
import { useContext, useState, type ChangeEvent, type FC } from 'react';
import { useIntl } from 'react-intl';
import { type Component as FormBuilderComponent } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { Validator } from 'shared/utils/validator';
import { ErrorHelper } from '../ErrorHelper';
import { InputTooltip } from './InputTooltip';
import { type FormComponentProps } from './types';

interface ComponentProps extends FormBuilderComponent, Partial<FieldProps> {
  field?: FieldProps['field'];
  disabled?: boolean;
}

const Component: FC<ComponentProps> = (props) => {
  const { formatMessage } = useIntl();
  const { info } = usePalette();
  const { form, id, text, disabled, field = {} as FieldProps['field'], options, tooltip } = props;
  const { error, secondary, primary } = usePalette();
  const errorMessage = form?.errors[id] as string;

  return (
    <FormControl sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '12px' }}>
        <FormLabel id={`${id}-label`} sx={{ color: 'secondary.800' }}>
          {text}
        </FormLabel>
        {!!tooltip && <InputTooltip title={tooltip} />}
      </Box>
      <RadioGroup
        {...props}
        {...field}
        placeholder={props.placeholder ?? ''}
        onBlur={() => {
          form?.validateField(field.name);
        }}
        sx={{ gap: '16px' }}
      >
        {(options ?? []).map(({ value, label }, index) => (
          <FormControlLabel
            disabled={disabled}
            key={value}
            value={value}
            label={
              <Box>
                <Typography variant='body2' fontSize={18} color='secondary.700'>
                  {label || formatMessage({ id: 'ordered_option' }, { index: index + 1 })}
                </Typography>
                <Typography variant='body1' mt='10px' color='secondary.700' whiteSpace='pre-line'>
                  {value}
                </Typography>
              </Box>
            }
            control={
              <Radio
                disabled={disabled}
                sx={{
                  position: 'absolute',
                  right: '16px',
                  top: '14px',
                  padding: 0,
                  color: info[500],
                  '& > span > svg': {
                    margin: '-4px'
                  },
                  '&:not(.Mui-checked) > span': {
                    backgroundColor: primary[200]
                  },
                  '&.Mui-checked': {
                    color: info[500]
                  }
                }}
              />
            }
            sx={{
              flexDirection: 'row-reverse',
              alignItems: 'start',
              justifyContent: 'start',
              position: 'relative',
              background: '#FFFFFF',
              border: `1px solid ${errorMessage ? error[100] : secondary[200]}`,
              borderRadius: '8px',
              padding: '14px 16px 16px'
            }}
          />
        ))}
      </RadioGroup>
      <ErrorHelper error={errorMessage} />
    </FormControl>
  );
};

export const OptionsBigComponent: FC<FormComponentProps> = (props) => {
  const [value, setValue] = useState<string>('');
  const { id, options, required: initialRequired } = props;
  const required = initialRequired ?? false;

  const formik = useContext(FormikContext);

  return formik ? (
    <Field
      {...props}
      name={id}
      component={Component}
      validate={required && Validator.pipe(Validator.methods.required())}
    />
  ) : (
    <Component
      {...props}
      options={options ?? []}
      field={
        {
          value,
          onChange: (e: ChangeEvent<any>) => {
            setValue(e.target.value);
          }
        } as FieldProps['field']
      }
    />
  );
};
