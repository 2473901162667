import { useMutation } from '@apollo/client';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { TaskOrderField } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { useState, type FC, useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import {
  ActionsPopup,
  ConfirmationDialog,
  CustomCheckbox,
  EmptyTableBox,
  FormDialog
} from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { AuthContext } from 'shared/contexts';
import { useCustomStyles, useRoles, type UseTableSort } from 'shared/hooks';
import { getTaskCategoryColor } from 'shared/utils/colors';
import { type DeleteTask, type DeleteTaskVariables } from './__graphql__/DeleteTask';
import { type Tasks_tasks_data } from './__graphql__/Tasks';
import { type UpdateTask, type UpdateTaskVariables } from './__graphql__/UpdateTask';
import { deleteTaskMutation, updateTaskMutation } from './api';
import { TaskForm } from './TaskWidget';
import { getTaskCategory } from './utils';

interface Props {
  data?: Tasks_tasks_data[] | null;
  page: number;
  rowsPerPage: number;
  sort: UseTableSort<TaskOrderField>;
  updateTaskDone: (data: { id: string; done: boolean }) => Promise<void>;
  changedTasks: Record<string, boolean>;
  onChangeTask: (id: string, done: boolean) => void;
  search: string;
}

const tableHeaderConfig = [
  { field: TaskOrderField.Name, labelId: 'task_name' },
  { field: TaskOrderField.AssignedTo, labelId: 'assigned_to' },
  { field: TaskOrderField.Contractor, labelId: 'contractor' },
  { field: TaskOrderField.StartDate, labelId: 'start_date' },
  { field: TaskOrderField.DueDate, labelId: 'due_date' },
  { field: TaskOrderField.Category, labelId: 'category' },
  { field: TaskOrderField.Done, labelId: 'done' }
];

export const TasksTable: FC<Props> = ({
  data,
  page,
  rowsPerPage,
  sort: { createSortHandler, direction, field },
  updateTaskDone,
  changedTasks,
  onChangeTask,
  search
}) => {
  const customStyles = useCustomStyles();
  const { formatMessage } = useIntl();
  const {
    authState: { user }
  } = useContext(AuthContext);
  const { isAdmin } = useRoles();

  const [taskToDelete, setTaskToDelete] = useState<Tasks_tasks_data>();
  const [taskToEdit, setTaskToEdit] = useState<Tasks_tasks_data>();

  const [deleteTask] = useMutation<DeleteTask, DeleteTaskVariables>(deleteTaskMutation, {
    refetchQueries: ['Tasks'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const [updateTask, { loading: updateLoading }] = useMutation<UpdateTask, UpdateTaskVariables>(updateTaskMutation, {
    refetchQueries: ['Tasks'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaderConfig.map(({ field: cellField, labelId }) => (
              <TableCell key={cellField}>
                <TableSortLabel onClick={createSortHandler(cellField)} hideSortIcon={true}>
                  {formatMessage({ id: labelId })}
                  <SortIcon active={field === cellField} direction={direction} />
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>{formatMessage({ id: 'actions' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((task) => (
            <TableRow key={task.id}>
              <TableCell sx={{ maxWidth: '300px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={task.name}
                />
              </TableCell>
              <TableCell>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={`${task.staffUser.firstName ?? ''} ${task.staffUser.lastName ?? ''}`}
                />
              </TableCell>
              <TableCell>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={`${task.contractor?.firstName ?? ''} ${task.contractor?.lastName ?? ''}`}
                />
              </TableCell>
              <TableCell>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={dayjs(task.startDate).format('DD/MM/YYYY')}
                />
              </TableCell>
              <TableCell>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={dayjs(task.dueDate).format('DD/MM/YYYY')}
                />
              </TableCell>
              <TableCell className='badge status'>
                <Box
                  sx={{
                    backgroundColor: getTaskCategoryColor(getTaskCategory(task))
                  }}
                >
                  {formatMessage({ id: `task.category.${getTaskCategory(task)}` })}
                </Box>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <CustomCheckbox
                  checked={changedTasks[task.id] ?? task.done}
                  onChange={async ({ target }) => {
                    const done = target.checked;
                    await updateTaskDone({ id: task.id, done });
                    onChangeTask(task.id, done);
                  }}
                />
              </TableCell>
              <TableCell sx={{ width: 0 }}>
                <ActionsPopup
                  items={[
                    {
                      id: 'view/edit',
                      onClick: () => setTaskToEdit(task)
                    },
                    ...(task.createdBy === user?.id || isAdmin
                      ? [
                          {
                            id: 'delete',
                            onClick: () => {
                              setTaskToDelete(task);
                            }
                          }
                        ]
                      : [])
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
          {!data?.length && !page && (
            <TableRow
              style={{
                height: 64 * rowsPerPage,
                background: 'inherit'
              }}
            >
              <TableCell
                colSpan={tableHeaderConfig.length}
                style={{
                  height: 64 * rowsPerPage,
                  padding: 0
                }}
              >
                <EmptyTableBox />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={!!taskToDelete}
        onClose={() => {
          setTaskToDelete(undefined);
        }}
        onConfirm={async () => {
          await deleteTask({ variables: { id: taskToDelete!.id } });
          toast(
            <SuccessSnackbar
              title={formatMessage({ id: 'task_delete' })}
              message={formatMessage({ id: 'task_delete_message' })}
            />
          );
          setTaskToDelete(undefined);
        }}
        confirmButtonContent={formatMessage({ id: 'delete' })}
        title={formatMessage({ id: 'task_removal' })}
        content={<Typography>{formatMessage({ id: 'task_removal_warning' }, { name: taskToDelete?.name })}</Typography>}
      />
      <FormDialog
        isOpen={!!taskToEdit}
        onClose={() => setTaskToEdit(undefined)}
        header={formatMessage({ id: 'view/edit_task' })}
        size='sm'
        loading={updateLoading}
        confirmBtnName={formatMessage({ id: 'save' })}
        initialValues={taskToEdit}
        onSubmit={async (values) => {
          const { id, staffUserId, contractorId, name, startDate, dueDate, description } = values;
          await updateTask({
            variables: {
              data: {
                id,
                staffUserId,
                contractorId: contractorId || null,
                name,
                startDate,
                dueDate,
                description: description || null
              }
            }
          });
          toast(<SuccessSnackbar title={formatMessage({ id: 'task_updated' })} />);
          setTaskToEdit(undefined);
        }}
      >
        <TaskForm />
      </FormDialog>
    </TableContainer>
  );
};
