import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { FormBuilderContext } from 'shared/contexts';
import { PropertyBlock, VariableProp } from './shared';
import { type ComponentPropertiesProps } from './shared/types';

export const TextComponentProperties: FC<ComponentPropertiesProps> = ({ isReadOnly, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { component } = useContext(FormBuilderContext);

  return (
    <>
      <VariableProp onSubmit={onSubmit} isReadOnly={isReadOnly} />
      <PropertyBlock
        readOnly
        title={formatMessage({ id: 'component_properties.type' })}
        value={{ component: formatMessage({ id: `components.${component?.component}` }) }}
        tooltip={formatMessage({ id: 'component_tooltips.type' })}
      />
      <PropertyBlock
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.content' })}
        value={{ text: component?.text }}
        tooltip={formatMessage({ id: 'component_tooltips.content' })}
        isMainProperty
      />
      <PropertyBlock
        placeholder={formatMessage({ id: 'component_placeholders.description' })}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
        title={formatMessage({ id: 'component_properties.description' })}
        value={{ description: component?.description }}
        tooltip={formatMessage({ id: 'component_tooltips.description' })}
      />
    </>
  );
};
