import { IconButton, Tooltip as MuiTooltip, Typography } from '@mui/material';
import { type FC } from 'react';
import { usePalette } from 'shared/hooks';
import { CircleQuestionIcon } from './icons';

interface Props {
  text: string | JSX.Element;
  icon?: JSX.Element;
}

export const Tooltip: FC<Props> = ({ text, icon }) => {
  const { primary } = usePalette();

  return (
    <MuiTooltip
      onClick={(e) => {
        e.stopPropagation();
      }}
      enterTouchDelay={0}
      placement='bottom-start'
      title={
        <Typography
          variant='body1'
          lineHeight='20px'
          sx={{
            margin: '-5px -9px',
            background: '#FFF',
            border: `1px solid ${primary[600]}`,
            borderRadius: '8px',
            maxWidth: '244px',
            color: 'secondary.700',
            p: '16px'
          }}
        >
          {text}
        </Typography>
      }
    >
      <IconButton
        sx={{
          '&, &:hover': {
            p: '2px',
            backgroundColor: 'info.200'
          }
        }}
      >
        {icon ?? <CircleQuestionIcon />}
      </IconButton>
    </MuiTooltip>
  );
};
