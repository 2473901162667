import { FC } from 'react';
import { IconProps } from './props';
import { usePalette } from 'shared/hooks';

export const PlusIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 4V19.9997' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 12L4.00033 12' stroke={currentColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
