import { useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ConfirmationDialog, CustomCheckbox } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { FormBuilderContext } from 'shared/contexts';
import { type DeleteSection, type DeleteSectionVariables } from '../__graphql__/DeleteSection';
import { deleteSectionMutation } from '../api';

interface Props {
  cleanup: () => void;
}

export const DeleteSectionModal: FC<Props> = ({ cleanup }) => {
  const { formatMessage } = useIntl();
  const [shouldDeleteChildren, setShouldDeleteChildren] = useState(false);
  const { section } = useContext(FormBuilderContext);

  const [deleteSection] = useMutation<DeleteSection, DeleteSectionVariables>(deleteSectionMutation, {
    refetchQueries: ['FormDetails'],
    awaitRefetchQueries: true
  });

  return (
    <ConfirmationDialog
      open
      confirmButtonContent={formatMessage({ id: 'delete' })}
      onClose={cleanup}
      onConfirm={async () => {
        const childrenAsSeparate = section?.children?.length ? !shouldDeleteChildren : false;
        await deleteSection({
          variables: {
            id: section?.id!,
            childrenAsSeparate
          }
        });
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: 'section_deleted' })}
            message={formatMessage(
              {
                id: shouldDeleteChildren
                  ? 'section_deleted_with_subsections_message'
                  : section?.children?.length
                  ? 'section_deleted_withou_subsections_message'
                  : 'section_deleted_message'
              },
              { name: section?.name }
            )}
          />
        );
        setShouldDeleteChildren(false);
        cleanup();
      }}
      title={formatMessage({ id: 'section_removal' })}
      content={
        section?.children?.length ? (
          <Box>
            <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
              {formatMessage({ id: 'delete_parent_section_warning' }, { name: section.name })}
            </Typography>
            <Box sx={{ display: 'flex', mt: '16px' }}>
              <CustomCheckbox
                value={shouldDeleteChildren}
                onChange={({ target }) => {
                  setShouldDeleteChildren(target.checked);
                }}
              />
              <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
                {formatMessage({ id: 'delete_included_sections' })}
              </Typography>
            </Box>
          </Box>
        ) : (
          formatMessage({ id: 'remove_section_warning' }, { name: section?.name })
        )
      }
    />
  );
};
