import { Box } from '@mui/material';
import React, { type FC } from 'react';
import { usePalette } from 'shared/hooks';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const Footer: FC<Props> = ({ children }: Props) => {
  const { secondary, info } = usePalette();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'sticky',
        paddingLeft: '48px',
        paddingRight: '48px',
        bottom: 0,
        minHeight: '83px',
        borderTop: `1px solid ${info[400]}`,
        zIndex: 1
      }}
      bgcolor={secondary[100]}
    >
      {React.Children.map(children, (item) => item)}
    </Box>
  );
};
