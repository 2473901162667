import { useTheme } from '@mui/material';
import { type ColorPartial } from '@mui/material/styles/createPalette';

export type Palette = Record<'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'info', ColorPartial>;

export function usePalette(): Palette {
  const {
    palette: { primary, secondary, success, warning, error, info }
  } = useTheme();

  return { primary, secondary, success, warning, error, info } as Palette;
}
