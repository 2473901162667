import { Box, type TextFieldProps } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Field, FormikContext, type FieldProps } from 'formik';
import { path, split } from 'ramda';
import { useContext, useState, type ChangeEvent, type FC } from 'react';
import { Validator } from 'shared/utils/validator';
import { BaseInput } from './InputComponent';
import { InputTooltip } from './InputTooltip';
import { type ComponentProps, type FormComponentProps } from './types';

export const TimeBaseComponent: FC<ComponentProps> = (props) => {
  const { field, form, id, tooltip, required, text, disabled } = props;
  const value = field.value;

  const errorMessage = path(split('.', id), form?.errors) as string;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={text}
        disabled={disabled}
        value={value ?? null}
        onChange={(v) =>
          field.onChange({ target: { value: v && dayjs(v).isValid() ? dayjs(v).toDate() : null, name: field.name } })
        }
        renderInput={(renderProps) => (
          <BaseInput
            {...renderProps}
            {...props}
            placeholder={props.placeholder ?? ''}
            required={required ?? false}
            error={!!errorMessage}
            helperText={errorMessage}
            InputLabelProps={{ required: required ?? false }}
            InputProps={
              {
                ...renderProps.inputProps,
                endAdornment: tooltip ? (
                  <>
                    {renderProps.InputProps?.endAdornment}
                    <InputTooltip title={tooltip} />
                  </>
                ) : (
                  <Box sx={{ mr: '16px' }}>{renderProps.InputProps?.endAdornment}</Box>
                )
              } as TextFieldProps['InputProps']
            }
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const TimeComponent: FC<FormComponentProps> = (props) => {
  const formik = useContext(FormikContext);
  const [value, setValue] = useState<Date | null>(null);

  const { required: initialRequired, id, validate } = props;
  const required = initialRequired ?? false;

  return formik ? (
    <Field
      {...props}
      component={TimeBaseComponent}
      name={id}
      validate={validate || (required && Validator.pipe(Validator.methods.required()))}
    />
  ) : (
    <TimeBaseComponent
      {...props}
      field={
        {
          value,
          onChange: ({ target }: ChangeEvent<any>) => {
            const date = dayjs(target.value);
            const value = target.value && date.isValid() ? date.toDate() : null;
            setValue(value);
          }
        } as FieldProps['field']
      }
    />
  );
};
