import { type Action } from '__graphql__/globalTypes';
import { TEXT_COMPONENTS } from 'admin/pages/Forms/ViewForm/constants';
import { RuleProcessor } from 'shared/components/RegistrationModal/rule-processor';
import { type FormRules_formRules } from './__graphql__/FormRules';
import {
  type Sections_sections,
  type Sections_sections_children,
  type Sections_sections_children_questions
} from './__graphql__/Sections';
import { type FilterableQuestion, type FilterableSection } from './types';

export function filterQuestionsByRules(
  questions: Sections_sections_children_questions[],
  rules: FormRules_formRules[],
  combinedAnswers: Record<string, string[]>
): FilterableQuestion[] {
  return questions.reduce<FilterableQuestion[]>((result, q) => {
    const questionRules = rules.filter(({ actions }) => !!actions.find(({ questionId }) => questionId === q.id));

    let actions: Record<Action, boolean> | null = null;

    if (questionRules.length) {
      for (const rule of questionRules) {
        actions = RuleProcessor.getAppliedActions({ rule, answers: combinedAnswers, targetId: q.id });

        if (actions.SHOW) {
          result.push({ ...q, readonly: actions.READ_ONLY });
        } else if (!actions.HIDE) {
          result.push({ ...q, readonly: actions.READ_ONLY });
        }
      }
    } else if (!TEXT_COMPONENTS.includes(q.component)) {
      result.push(q);
    }

    return result;
  }, []);
}

function isParentSection(section: FilterableSection): section is Sections_sections {
  return !!(section as Sections_sections)?.children?.length;
}

export function filterSectionsByRules(
  sections: FilterableSection[],
  rules: FormRules_formRules[],
  combinedAnswers: Record<string, string[]>,
  isParentReadOnly?: boolean
): FilterableSection[] {
  return sections
    .map((s) => {
      const sectionRules = rules.filter(({ actions }) => actions.find(({ sectionId }) => sectionId === s.id));

      s = { ...s, readonly: isParentReadOnly };

      for (const rule of sectionRules) {
        const actionsConfig = RuleProcessor.getAppliedActions({
          rule,
          answers: combinedAnswers,
          targetId: s.id
        });

        s = { ...s, readonly: actionsConfig.READ_ONLY || s.readonly };

        if (actionsConfig.SHOW) {
          if (isParentSection(s)) {
            const filteredChildren = filterSectionsByRules(s.children, rules, combinedAnswers, s.readonly);
            s = {
              ...s,
              children: filteredChildren as Sections_sections_children[],
              questions: filterQuestionsByRules(s.questions, rules, combinedAnswers)
            };
          }
          return { ...s, questions: filterQuestionsByRules(s.questions, rules, combinedAnswers) };
        } else if (actionsConfig.HIDE) {
          return null;
        }
      }

      if (isParentSection(s)) {
        const filteredChildren = filterSectionsByRules(s.children, rules, combinedAnswers, s.readonly);
        s = {
          ...s,
          children: filteredChildren as Sections_sections_children[],
          questions: filterQuestionsByRules(s.questions, rules, combinedAnswers)
        };
      }

      return s;
    })
    .filter(Boolean) as FilterableSection[];
}
