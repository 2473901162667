import { Grid, Box, type GridProps, type BoxProps } from '@mui/material';
import { type PropsWithChildren } from 'react';
import { usePalette } from 'shared/hooks';

export const Card = ({ children, ...props }: PropsWithChildren<GridProps>): JSX.Element => {
  const { secondary } = usePalette();
  return (
    <Grid
      item
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px',
        marginBottom: '12px',
        border: `1px solid ${secondary[300]}`,
        ...(props.sx ?? {})
      }}
    >
      {children}
    </Grid>
  );
};

export const CardHeader = ({ children, ...props }: PropsWithChildren<BoxProps>): JSX.Element => {
  const { secondary } = usePalette();

  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        borderBottom: `1px solid ${secondary[200]}`,
        padding: '16px 24px 12px 24px',
        ...(props.sx ?? {})
      }}
    >
      {children}
    </Box>
  );
};

export const CardContent = ({ children, ...props }: PropsWithChildren<GridProps>): JSX.Element => (
  <Grid
    container
    {...props}
    sx={{
      padding: '0px 24px 24px 24px',
      ...(props.sx ?? {})
    }}
  >
    {children}
  </Grid>
);
