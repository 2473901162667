import { useQuery } from '@apollo/client';
import {
  Agencies,
  ContractorDetails,
  Contractors,
  Dashboard,
  EmailsTemplates,
  FormsPage,
  MyCompany,
  RegistrationDetails,
  Reports,
  Tasks,
  Users,
  ViewForm
} from 'admin/pages';
import { assocPath, last } from 'ramda';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { Navigation, RegistrationModal } from 'shared/components';
import { ProfileModal } from 'shared/components/ProfileModal';
import routes from 'shared/constants/routes';
import { CompanyContext, FormBuilderContext, ProfileInfoContext, SubscriptionContextProvider } from 'shared/contexts';
import { ProductsContextProvider } from 'shared/contexts/productsContext';
import { SearchContext } from 'shared/contexts/searchContext';
import { useFormBuilder, useRoles, useSearch } from 'shared/hooks';
import { useProfileInfo } from 'shared/hooks/useProfileInfo';
import { localStorageUtils } from 'shared/utils/local-storage';
import { type IsEmailsConfigured } from './__graphql__/IsEmailsConfigured';
import { isEmailsConfiguredQuery } from './api';
import { WarningBanner } from './components/WarningBanner';

export const appRoutes = [
  <Route key={0} index element={<Dashboard />} />,
  <Route key={routes.registration} path={routes.registration} element={<RegistrationDetails />} />,
  <Route key={routes.users} path={routes.users} element={<Users />} />,
  <Route key={routes.contractors} path={routes.contractors} element={<Contractors />} />,
  <Route key={routes.contractor} path={routes.contractor} element={<ContractorDetails />} />,
  <Route key={routes.reports} path={routes.reports} element={<Reports />} />,
  <Route key={routes.agencies} path={routes.agencies} element={<Agencies />} />,
  <Route key={routes.forms} path={routes.forms} element={<FormsPage />} />,
  <Route key={routes.form} path={routes.form} element={<ViewForm />} />,
  <Route key={routes.tasks} path={routes.tasks} element={<Tasks />} />,
  <Route key={routes.emails} path={routes.emails} element={<EmailsTemplates />} />,
  <Route key={routes.myCompany} path={routes.myCompany} element={<MyCompany />} />
];

interface BannerState {
  closedForever?: boolean;
  remindLater?: boolean;
}

export interface WarningsState {
  emailsConfig?: BannerState & Record<'isMarkerHidden', boolean>;
  noFunds?: BannerState;
  cardExpiration?: BannerState;
}

function App(): JSX.Element {
  const profileContextData = useProfileInfo();
  const formBuilderContextData = useFormBuilder();

  const storageWarnings: WarningsState = localStorageUtils.getItem<WarningsState>('warnings');
  const [warnings, setWarnings] = useState<WarningsState>(storageWarnings);

  const storeBannerDecision = useCallback((path: string, value: boolean): void => {
    setWarnings((prev) => assocPath(path.split('.'), value, prev));
    localStorageUtils.setProperty(`warnings.${path}`, value);
  }, []);

  const { paymentFailureCode } = useContext(CompanyContext);

  const searchValue = useSearch();
  const { isAdmin, isSuperAdmin } = useRoles();

  const { data } = useQuery<IsEmailsConfigured>(isEmailsConfiguredQuery, {
    context: API_CONTEXT.NOTIFICATION,
    skip: !isAdmin
  });

  const isBannerVisible = useCallback(
    (type: keyof WarningsState): boolean => !warnings[type]?.closedForever && !warnings[type]?.remindLater,
    [warnings]
  );

  const isCorrectRoles = isAdmin || isSuperAdmin;

  const activeBanners = useMemo(() => {
    const temp = [];
    if (!data?.isEmailsConfigured && isCorrectRoles && isBannerVisible('emailsConfig')) {
      temp.push(<WarningBanner key='emailsConfig' reason='emailsConfig' storeAction={storeBannerDecision} />);
    }

    if (paymentFailureCode && isCorrectRoles && isBannerVisible('noFunds')) {
      temp.push(<WarningBanner key='noFunds' reason='noFunds' storeAction={storeBannerDecision} />);
    }
    return temp;
  }, [data?.isEmailsConfigured, isBannerVisible, isCorrectRoles, paymentFailureCode, storeBannerDecision]);

  return (
    <ProfileInfoContext.Provider value={profileContextData}>
      <FormBuilderContext.Provider value={formBuilderContextData}>
        <ProductsContextProvider>
          <SubscriptionContextProvider>
            <SearchContext.Provider value={searchValue}>
              <Routes>
                <Route
                  element={
                    <Navigation
                      banners={activeBanners}
                      hideMarker={warnings.emailsConfig?.isMarkerHidden}
                      view='admin'
                    />
                  }
                >
                  {paymentFailureCode
                    ? [
                        last(appRoutes),
                        <Route
                          path={`${routes.root}*`}
                          key={`${routes.root}*`}
                          element={<Navigate to={routes.myCompany} />}
                        />
                      ]
                    : appRoutes.map((r) => r)}
                </Route>
              </Routes>
              <RegistrationModal />
            </SearchContext.Provider>
          </SubscriptionContextProvider>
        </ProductsContextProvider>
      </FormBuilderContext.Provider>
      <ProfileModal />
    </ProfileInfoContext.Provider>
  );
}

export default App;
