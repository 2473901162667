import { Pagination, PaginationItem } from '@mui/material';
import { ChevronIcon, DoubleChevronIcon } from './icons';

interface StyledTablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export function StyledTablePagination(props: StyledTablePaginationProps): JSX.Element {
  const { count, page, rowsPerPage, onPageChange } = props;

  return (
    <Pagination
      count={Math.ceil(count / rowsPerPage)}
      variant='outlined'
      shape='rounded'
      showFirstButton
      showLastButton
      page={page + 1}
      onChange={(e, v) => {
        // @ts-expect-error: error
        onPageChange(e, v - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          components={{
            first: () => <DoubleChevronIcon direction='left' />,
            last: () => <DoubleChevronIcon direction='right' />,
            previous: () => <ChevronIcon direction='left' />,
            next: () => <ChevronIcon direction='right' />
          }}
          {...item}
        />
      )}
      sx={{ display: 'flex', justifyContent: 'flex-end', mt: '16px', mb: 6 }}
    />
  );
}
