import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { useCustomStyles, usePalette } from 'shared/hooks';
import { isBadInputError, retrieveErrorCode } from 'shared/utils/errors';
import { CircleCheckIcon, DocumentWithPenIcon } from '../icons';
import { Loader } from '../Loader';
import { type PreviewDocument, type PreviewDocumentVariables } from './__graphql__/PreviewDocument';
import { previewDocumentQuery } from './api';
import { type FormComponentProps } from './types';

export const DocumentComponent: FC<FormComponentProps & { documentContent?: string }> = (props) => {
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();
  const { text, documentContent, id, component } = props;
  const { pdfFrame } = useCustomStyles();
  const { id: registrationId, documents, previewForm, previewFormValues } = useContext(RegistrationContext);

  const isSigned = previewForm?.id
    ? !!previewFormValues[id]
    : !!documents.find((d) => {
        const fileNameWithoutExt = d.filename.split('.')[0];
        return fileNameWithoutExt === id || fileNameWithoutExt === component;
      });

  const { data, loading, error } = useQuery<PreviewDocument, PreviewDocumentVariables>(previewDocumentQuery, {
    context: API_CONTEXT.DOCUMENT,
    variables: {
      questionId: id,
      registrationId: previewForm?.id ? '' : registrationId
    },
    skip: !!documentContent
  });

  const isNoSufficientData = error ? isBadInputError(retrieveErrorCode(error)) : false;
  const preview = documentContent || data?.previewDocument;

  return preview && !loading ? (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {!!text && (
          <Typography variant='subtitle2' sx={{ fontWeight: 600, mb: '12px' }}>
            {formatMessage({ id: `components.${props.component}` })}
          </Typography>
        )}
        {isSigned && (
          <Box sx={{ display: 'flex' }}>
            <CircleCheckIcon />
            <Typography sx={{ ml: '8px' }}>{formatMessage({ id: 'the_document_is_signed' })}</Typography>
          </Box>
        )}
      </Box>
      <iframe
        src={preview.startsWith('https://') ? preview : `data:application/pdf;base64,${preview}#view=fitH`}
        name='Document'
        style={{ ...pdfFrame, minHeight: '60vh', '#viewerContainer': { maxHeight: '100vh' } } as any}
        height='100%'
        width='100%'
      />
    </Box>
  ) : (
    <Box>
      <Typography variant='subtitle2' sx={{ fontWeight: 600, mb: '12px' }}>
        {formatMessage({ id: `components.${props.component}` })}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'white',
          borderRadius: '8px',
          height: '50vh',
          border: `1px dashed ${secondary[400]}`
        }}
      >
        {loading ? (
          <Loader />
        ) : isNoSufficientData ? (
          <Box sx={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DocumentWithPenIcon />
            <Typography sx={{ maxWidth: '50%', textAlign: 'center' }} variant='caption'>
              {formatMessage(
                { id: 'document_preview_fail' },
                { documentName: component.replace('_', '').toLowerCase() }
              )}
            </Typography>
          </Box>
        ) : (
          <Typography sx={{ maxWidth: '50%', textAlign: 'center' }} variant='caption'>
            {formatMessage({ id: 'configure_document_through_properties' })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
