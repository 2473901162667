import { FC } from 'react';
import { IconProps } from './props';
import { usePalette } from 'shared/hooks';

export const DocumentFormIcon: FC<IconProps> = (props) => {
  const { color, size = 24 } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width={size} height={size} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.5 2.75H6C5.46957 2.75 4.96086 2.96071 4.58579 3.33579C4.21071 3.71086 4 4.21957 4 4.75V20.75C4 21.2804 4.21071 21.7891 4.58579 22.1642C4.96086 22.5393 5.46957 22.75 6 22.75H18C18.5304 22.75 19.0391 22.5393 19.4142 22.1642C19.7893 21.7891 20 21.2804 20 20.75V8.25L14.5 2.75Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 2.75V8.75H20M8 13.75H10M8 17.75H10M14 13.75H16M14 17.75H16'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
