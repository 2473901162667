import { Select, MenuItem, Typography, IconButton, Box, Button } from '@mui/material';
import { symmetricDifference } from 'ramda';
import { type FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePalette } from 'shared/hooks';
import { CheckboxIcon, ChevronIcon, CrossIcon } from './icons';

interface ItemProps {
  value?: string;
  label: string;
  selected: boolean;
  onClick?: () => void;
}

export const SelectFilterItem: FC<ItemProps> = ({ value, label, selected, ...props }) => {
  return (
    <MenuItem
      {...props}
      value={value}
      sx={{
        display: 'flex',
        height: '44px',
        padding: '10px 16px'
      }}
    >
      <CheckboxIcon checked={selected} />
      <Typography variant='body1' color='secondary.700' sx={{ ml: '10px' }}>
        {label}
      </Typography>
    </MenuItem>
  );
};

interface Props<T> {
  defaultValue?: T[];
  onApply: (arg0: T[]) => void;
  placeholder: string;
  options: Array<{ value: T; label: string }>;
  allItemLabel?: string;
  values: T[];
}

export const SelectFilter = <T,>({
  defaultValue = [],
  values,
  placeholder,
  options,
  allItemLabel,
  onApply
}: Props<T>): JSX.Element => {
  const { secondary } = usePalette();

  const EMPTY_OPTION = 'none' as T;
  const defaultValueOrEmptyOption = defaultValue?.length ? defaultValue : [EMPTY_OPTION];
  const valuesOrEmptyOption = values?.length ? values : [EMPTY_OPTION];
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<T[]>(defaultValueOrEmptyOption);

  useEffect(() => {
    if (open) setSelected(valuesOrEmptyOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = useCallback(
    (v: T[]) => {
      onApply(v.filter((o) => o !== EMPTY_OPTION));
      setOpen(false);
    },
    [onApply]
  );

  return (
    <Select
      multiple
      displayEmpty
      open={open}
      value={selected}
      onChange={(e) => {
        const valuesToChange = e.target.value as T[];
        if (valuesToChange[valuesToChange.length - 1] === EMPTY_OPTION) {
          setSelected([EMPTY_OPTION]);
        } else {
          setSelected(valuesToChange.filter((o) => o !== EMPTY_OPTION));
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderValue={() => {
        if (!values?.length && open) return '';
        if (!values?.length) return placeholder;
        return values.map((value) => options.find((op) => op.value === value)?.label).join(', ');
      }}
      className='filter'
      sx={{ padding: 0, '& .MuiSelect-select': { color: secondary[600] } }}
      IconComponent={() => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setOpen((prev) => !prev);
          }}
          sx={{ mr: '14px', cursor: 'pointer' }}
        >
          <ChevronIcon direction={open ? 'up' : 'down'} size={26} />
        </IconButton>
      )}
      endAdornment={
        symmetricDifference(values, defaultValue)?.length ? (
          <IconButton
            sx={{
              right: '26px',
              position: 'absolute',
              mr: '14px'
            }}
            onClick={() => {
              setSelected(defaultValueOrEmptyOption);
              handleChange(defaultValue);
            }}
          >
            <CrossIcon size={22} />
          </IconButton>
        ) : null
      }
    >
      {allItemLabel && (
        <SelectFilterItem
          value={EMPTY_OPTION as string}
          label={allItemLabel}
          selected={selected.includes(EMPTY_OPTION)}
        />
      )}
      {options.map((option) => (
        <SelectFilterItem
          key={`${option.value as string}`}
          value={option.value as string}
          label={option.label}
          selected={selected.includes(option.value)}
        />
      ))}
      <Box
        sx={{
          padding: '12px 28px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Button
          variant='text'
          sx={{
            padding: 0,
            height: '28px',
            ':hover': {
              background: 'inherit'
            }
          }}
          onClick={() => {
            setSelected([EMPTY_OPTION]);
          }}
        >
          {intl.formatMessage({ id: 'reset' })}
        </Button>
        <Button
          variant='text'
          sx={{
            padding: 0,
            height: '28px',
            ':hover': {
              background: 'inherit'
            }
          }}
          onClick={() => {
            handleChange(selected);
          }}
        >
          {intl.formatMessage({ id: 'apply' })}
        </Button>
      </Box>
    </Select>
  );
};
