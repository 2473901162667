import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const LinkedinIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.80571 19H3.28112V7.65007H6.80571V19ZM5.04129 6.10186C3.91439 6.10186 3 5.16807 3 4.04121C3 3.49985 3.21506 2.98066 3.59788 2.59786C3.98069 2.21506 4.4999 2 5.04129 2C5.58267 2 6.10188 2.21506 6.48469 2.59786C6.86751 2.98066 7.08257 3.49985 7.08257 4.04121C7.08257 5.16807 6.16818 6.10186 5.04129 6.10186ZM19.997 19H16.4803V13.475C16.4803 12.1581 16.4536 10.4696 14.6478 10.4696C12.8154 10.4696 12.5343 11.9001 12.5343 13.3803V19H9.01336V7.65007H12.3934V9.19829H12.4426C12.9132 8.30639 14.0625 7.36532 15.7772 7.36532C19.3443 7.36532 20 9.71436 20 12.7653V19H19.997Z'
        fill={currentColor}
      />
    </svg>
  );
};
