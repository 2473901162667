import { Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { AnalyticsIcon, StructureIcon, SuperAdminsIcon } from 'shared/components/icons';
import routes from 'shared/constants/routes';
import { usePalette } from 'shared/hooks';

interface AdminDrawerListProps {
  open: boolean;
}

export const SuperAdminDrawerList: React.FC<AdminDrawerListProps> = ({ open }) => {
  const intl = useIntl();
  const { primary, secondary } = usePalette();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = useMemo(() => location.pathname.substring(1), [location.pathname]);

  const superAdminMenu = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'my_tenants' }),
        icon: <StructureIcon />,
        route: routes.root
      },
      {
        title: intl.formatMessage({ id: 'analytics' }),
        icon: <AnalyticsIcon />,
        route: routes.analytics
      },
      {
        title: intl.formatMessage({ id: 'super_admins' }),
        icon: <SuperAdminsIcon />,
        route: routes.superAdmins
      }
    ],
    [intl]
  );

  return (
    <List
      sx={{
        border: 'none',
        p: '16px 16px 38px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      {superAdminMenu.map(({ title, icon, route }) => (
        <ListItem key={title} disablePadding>
          <Button
            variant='text'
            onClick={() => {
              navigate(route);
            }}
            sx={{
              width: '100%',
              padding: '10px 16px',
              color: secondary[800],
              justifyContent: open ? 'start' : 'center',
              ...(currentRoute === route
                ? {
                    backgroundColor: primary[200]
                  }
                : {})
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? '10px' : 'auto',
                justifyContent: 'center'
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={title}
              sx={{
                opacity: open ? 1 : 0,
                textAlign: 'start',
                '& > span': {
                  fontSize: '14px',
                  lineHeight: '20px'
                }
              }}
            />
          </Button>
        </ListItem>
      ))}
    </List>
  );
};
