import { useMutation } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { Title } from '__graphql__/globalTypes';
import { Field, Form, Formik, type FormikProps } from 'formik';
import { filter } from 'ramda';
import { useState, type FC, type MouseEventHandler } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Input, Loader, Select } from 'shared/components';
import { AttentionSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import { Validator } from 'shared/utils/validator';
import { type CreateSelfReferral, type CreateSelfReferralVariables } from './__graphql__/CreateSelfReferral';
import { createSelfReferralMutation } from './api';

const INIT_VALUES = {
  title: null,
  firstName: '',
  lastName: '',
  phoneNumber: null,
  email: null,
  note: null
};
const titleOptions = Object.values(Title).map((t) => ({ value: t, label: t }));

const ReferralForm: FC<FormikProps<typeof INIT_VALUES> & { readOnly: boolean }> = (props) => {
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const { values, setFieldError, readOnly, setTouched, submitForm, validateForm } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { formatMessage } = useIntl();

  const validateOnSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    setIsSubmitBtnDisabled(true);
    e.preventDefault();

    if (!executeRecaptcha) {
      setIsSubmitBtnDisabled(false);
      return;
    }

    setTouched({ title: true, firstName: true, lastName: true, phoneNumber: true, email: true });

    const errors = await validateForm();
    if (Object.keys(errors).length) {
      setIsSubmitBtnDisabled(false);
      return;
    }

    if (!values.email && !values.phoneNumber) {
      setFieldError('email', formatMessage({ id: 'required' }));
      setFieldError('phoneNumber', formatMessage({ id: 'required' }));
      setIsSubmitBtnDisabled(false);
      return;
    }

    const token = await executeRecaptcha('ContractorSelfReferral');

    if (!token) {
      setIsSubmitBtnDisabled(false);
      toast(
        <AttentionSnackbar
          title={formatMessage({ id: 'required_recaptcha' })}
          message={formatMessage({ id: 'complete_recaptcha' })}
        />
      );
      return;
    }

    await submitForm();
  };

  return (
    <Form {...props}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '12px' }}>
        <Typography variant='h1' color='secondary.800' sx={{ fontSize: '42px', lineHeight: '48px', fontWeight: 400 }}>
          {formatMessage({ id: 'create_referral' })}
        </Typography>
        <Field
          name='title'
          label={formatMessage({ id: 'title' })}
          validate={Validator.pipe(Validator.methods.required())}
          component={Select}
          options={titleOptions}
          InputProps={{ readOnly }}
        />
        <Field
          name='firstName'
          label={formatMessage({ id: 'first_name' })}
          placeholder={formatMessage({ id: 'first_name' })}
          validate={Validator.pipe(Validator.methods.required())}
          component={Input}
          InputProps={{ readOnly }}
        />
        <Field
          name='lastName'
          label={formatMessage({ id: 'surname' })}
          placeholder={formatMessage({ id: 'surname' })}
          validate={Validator.pipe(Validator.methods.required())}
          component={Input}
          InputProps={{ readOnly }}
        />
        <Field
          name='phoneNumber'
          label={formatMessage({ id: 'phone_number' })}
          placeholder={formatMessage({ id: 'phone_number' })}
          component={Input}
          validate={Validator.pipe(Validator.methods.phone())}
          InputProps={{ readOnly }}
        />
        <Field
          name='email'
          label={formatMessage({ id: 'email' })}
          placeholder={formatMessage({ id: 'email' })}
          validate={Validator.pipe(Validator.methods.email())}
          component={Input}
          InputProps={{ readOnly }}
        />
        <Field
          multiline
          component={Input}
          name='note'
          label={formatMessage({ id: 'note.consultant.label' })}
          InputProps={{ readOnly }}
          sx={{
            '& textarea': {
              overflowY: 'auto !important',
              minHeight: '120px',
              maxHeight: '200px'
            }
          }}
        />
        <Button variant='contained' type='submit' onClick={validateOnSubmit} disabled={readOnly || isSubmitBtnDisabled}>
          {formatMessage({ id: 'create_referral' })}
        </Button>
      </Box>
    </Form>
  );
};

export const NewReferral: FC = () => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const { token } = useParams();
  const { formatMessage } = useIntl();
  const [createSelfReferral, { loading }] = useMutation<CreateSelfReferral, CreateSelfReferralVariables>(
    createSelfReferralMutation,
    {
      context: { headers: { 'x-referral-token': token } }
    }
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={async (values) => {
        await createSelfReferral({ variables: { data: filter((v) => !!v, values) } });
        setIsReadOnly(true);
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: 'new_referral_created' })}
            message={formatMessage({ id: 'new_referral_created_message' })}
          />
        );
      }}
      initialValues={INIT_VALUES}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => (loading ? <Loader /> : <ReferralForm {...props} readOnly={isReadOnly} />)}
    </Formik>
  );
};
