import { type Role } from '__graphql__/globalTypes';

export enum AUTH_STATUS {
  authenticated = 'authenticated',
  anonymous = 'anonymous'
}

export interface LoginParams {
  email: string;
  password: string;
  redirectTo?: string;
}

export interface InviteConfirmParams {
  token: string;
  password: string;
}

export interface ForgotPasswordParams {
  email: string;
}

export interface ResetPasswordParams {
  token: string;
  password: string;
}

export interface ChangeEmailParams {
  token: string;
}

export interface AuthState {
  status: AUTH_STATUS;
  user?: {
    id: string;
    role: Role;
  } | null;
  accessToken?: string | null;
  refreshToken?: string | null;
}

export interface AuthError {
  error?: string;
  code: string;
}
