import { Box, Grid, Typography } from '@mui/material';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { CompanyContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';

export const LoginLayout: FC<{ children: JSX.Element }> = ({ children }): JSX.Element => {
  const [name] = window.location.hostname.split('.');
  const { logo, name: companyName } = useContext(CompanyContext);
  const { formatMessage } = useIntl();
  const { info } = usePalette();
  const logoUrl = logo?.url ? logo.url : '/swift-onboard-logo.svg';
  const shouldDisplayFancyView = [
    'manage',
    'alliancecombined',
    'swiftonboard',
    'onboarding',
    'localhost',
    'dev',
    'stage'
  ]
    .includes(name);

  return (
    <Grid
      container
      sx={{
        height: '100%'
      }}
    >
      {shouldDisplayFancyView && (
        <Grid
          item
          md={4}
          xs={0}
          sx={{
            background: `linear-gradient(153.79deg, ${info[700]} 0%, ${info[800]} 114.96%)`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingLeft: 18,
            paddingRight: 14,
            paddingY: '100px',
            '@media only screen and (max-width: 899px)': {
              display: 'none'
            }
          }}
        >
          <img style={{ height: '50px', maxWidth: '220px', width: 'max-content' }} src={logoUrl} alt='logo' />
          <Grid item>
            <Typography variant='h1' color='secondary.800'>
              {formatMessage({ id: 'welcome' })}
              <br />
              {formatMessage({ id: 'to' })} {name === 'manage' ? 'Swift Onboard' : companyName}!
            </Typography>
            <Typography mt={6} mb={2} variant='body1' color='secondary.800'>
              {formatMessage({ id: 'save_time_save_money' })}
            </Typography>
          </Grid>
        </Grid>
      )}
      {shouldDisplayFancyView && (
        <Grid
          item
          md={8}
          xs={12}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          bgcolor={info[200]}
          sx={{
            '@media only screen and (min-width: 899px)': {
              pr: '140px'
            }
          }}
        >
          {children}
        </Grid>
      )}
      {!shouldDisplayFancyView && (
        <Grid
          item
          xs={12}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          bgcolor={info[200]}
        >
          <Box sx={{ pr: '100vw / 2', pb: '5%' }}>
            <img style={{ height: '50px', maxWidth: '220px', width: 'max-content' }} src={logoUrl} alt='logo' />
          </Box>
          {children}
        </Grid>
      )}
    </Grid>
  );
};
