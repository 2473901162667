import { useMutation, useQuery } from '@apollo/client';
import { DocumentOrderField, DocumentRelation, OrderDirection, QuestionComponent } from '__graphql__/globalTypes';
import { BASIC_INFO_SECTION } from 'admin/pages/Forms/ViewForm/constants';
import { is, mapObjIndexed, of, unless } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { type Contractor_user } from 'shared/api/__graphql__/Contractor';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { type IRegistrationContext } from '../../contexts/registrationContext';
import { useRoles } from '../useRoles';
import { type CreateFormAnswers, type CreateFormAnswersVariables } from './__graphql__/CreateFormAnswers';
import { type DocumentsModal, type DocumentsModalVariables } from './__graphql__/DocumentsModal';
import { type FormRules, type FormRulesVariables } from './__graphql__/FormRules';
import { type Registration, type RegistrationVariables } from './__graphql__/Registration';
import { type RegistrationAnswers, type RegistrationAnswersVariables } from './__graphql__/RegistrationAnswers';
import { type SaveRegistration, type SaveRegistrationVariables } from './__graphql__/SaveRegistration';
import {
  type Sections,
  type SectionsVariables,
  type Sections_sections,
  type Sections_sections_children_questions
} from './__graphql__/Sections';
import {
  createFormAnswersMutation,
  documentsModalQuery,
  formRulesQuery,
  registrationAnswersQuery,
  registrationQuery,
  saveRegistrationMutation,
  sectionsQuery
} from './api';
import { filterQuestionsByRules, filterSectionsByRules } from './utils';

export type RegistrationBasicInfo = Partial<
  Pick<Contractor_user, 'firstName' | 'lastName' | 'title' | 'phoneNumber' | 'email'> & {
    contractorId: string;
    contractType: string;
  }
>;

export const useRegistration = (): IRegistrationContext => {
  const [sectionValues, setSectionValues] = useState<Record<string, string | string[]>>({});
  const [isOpened, setIsOpened] = useState(false);
  const [previewForm, setPreviewForm] = useState<IRegistrationContext['previewForm']>({ id: '', name: '' });
  const [previewFormValues, setPreviewFormValues] = useState<Record<string, any>>({});
  const [id, setId] = useState('');
  const [isDirty, setIsDirty] = useState([false, () => {}]);
  const { isConsultant, isContractor } = useRoles();
  const [basicInfo, setBasicInfo] = useState<RegistrationBasicInfo>();
  const [invalidSections, setInvalidSections] = useState<string[]>([]);

  const result = useQuery<Registration, RegistrationVariables>(registrationQuery, {
    variables: {
      id
    },
    skip: !id || isConsultant || !!previewForm.id
  });

  const { data: answersData, loading: answersLoading } = useQuery<RegistrationAnswers, RegistrationAnswersVariables>(
    registrationAnswersQuery,
    { variables: { registrationId: id }, skip: !id || !!previewForm.id }
  );

  const answers = useMemo(
    () =>
      (answersData?.answers ?? []).reduce<Record<string, string[]>>((result, { questionId, answer }) => {
        result[questionId] = answer;
        return result;
      }, {}),
    [answersData?.answers]
  );

  const documentsResult = useQuery<DocumentsModal, DocumentsModalVariables>(documentsModalQuery, {
    variables: {
      limit: 100,
      offset: 0,
      filter: {
        relatedId: id,
        relatedTo: DocumentRelation.REGISTRATION
      },
      orderBy: {
        field: DocumentOrderField.createdAt,
        direction: OrderDirection.desc
      }
    },
    skip: !id || isConsultant,
    context: API_CONTEXT.DOCUMENT
  });

  const clearBasicInfo = (): void => {
    setBasicInfo(undefined);
  };

  const documents = useMemo(() => documentsResult.data?.documents?.data ?? [], [documentsResult.data?.documents?.data]);

  const [saveRegistration] = useMutation<SaveRegistration, SaveRegistrationVariables>(saveRegistrationMutation, {
    refetchQueries: [
      ...(isConsultant ? ['Referrals'] : ['Registration']),
      ...(isContractor ? ['ContractorRegistrations', 'Contractor'] : []),
      'RegistrationDetails',
      'ViewContractorRegistrations'
    ],
    awaitRefetchQueries: true
  });

  const previewSaveRegistration: IRegistrationContext['save'] = (values) => {
    setPreviewFormValues((prev) => ({ ...prev, ...values.variables.data, id: previewForm.id }));
    return Promise.resolve({ data: { saveRegistration: { id: previewForm.id } } as SaveRegistration });
  };

  const save: IRegistrationContext['save'] = async (values) => {
    return previewForm.id ? await previewSaveRegistration(values) : await saveRegistration(values);
  };

  const [createAnswer] = useMutation<CreateFormAnswers, CreateFormAnswersVariables>(createFormAnswersMutation, {
    refetchQueries: ['Registration', 'RegistrationDetails', 'RegistrationAnswers'],
    awaitRefetchQueries: true
  });

  const previewCreateAnswer: IRegistrationContext['sendAnswer'] = (values) => {
    const answers = values.variables.data.reduce(
      (result: Record<string, string[]>, current: Record<'questionId' | 'answer', any>) => {
        result[current.questionId] = current.answer;
        return result;
      },
      {}
    );

    setPreviewFormValues((prev) => ({ ...prev, ...answers }));
    return Promise.resolve({});
  };

  const sendAnswer: IRegistrationContext['sendAnswer'] = async (values) => {
    return previewForm.id ? await previewCreateAnswer(values) : await createAnswer(values);
  };

  const registration = useMemo(
    () => (id && result.data?.registration ? result.data.registration : undefined),
    [id, result?.data?.registration]
  );

  const { data: sectionsData } = useQuery<Sections, SectionsVariables>(sectionsQuery, {
    variables: {
      filters: {
        ...(previewForm.id ? { contractType: previewFormValues.contractType } : { formId: registration?.formId }),
        sectionFilter: { parentId: null }
      }
    },
    skip: !registration?.form?.contractType && !previewFormValues.firstName
  });

  const rulesData = useQuery<FormRules, FormRulesVariables>(formRulesQuery, {
    variables: { formId: registration?.formId! },
    skip: !registration?.formId
  });

  const rules = useMemo(() => rulesData.data?.formRules ?? [], [rulesData.data?.formRules]);

  const combinedAnswers: Record<string, string[]> = useMemo(() => {
    const convertValuesToArray = mapObjIndexed((value: string | string[]) => unless(is(Array), of)(value));
    const resultObject = convertValuesToArray(sectionValues);
    return { ...answers, ...resultObject };
  }, [answers, sectionValues]);

  const sections = useMemo(() => {
    let items: Sections_sections[] = sectionsData?.sections ?? [];

    if (rules.length) {
      items = filterSectionsByRules(items, rules, combinedAnswers) as Sections_sections[];
    }

    return items;
  }, [combinedAnswers, rules, sectionsData?.sections]);

  const openModal = useCallback(() => {
    setIsOpened(true);
  }, []);

  const openWithBasicInfo = (basicInfo: RegistrationBasicInfo): void => {
    setBasicInfo(basicInfo);
    setIsOpened(true);
  };

  const openModalWithId = useCallback((idToSet: string) => {
    if (idToSet) setId(idToSet);
    setIsOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpened(false);
    setId('');
    setPreviewForm({ id: '', name: '' });
    setPreviewFormValues({});
  }, []);

  const completenessOfRegistration = useMemo(() => {
    const sectionsCompleteness = sections.reduce<Record<string, boolean>>((result, { id, questions, children }) => {
      let isSectionCompleted = false;
      const checkAnswers = (fq: Sections_sections_children_questions[]): boolean =>
        fq.every(({ id: qId, required, component }) => {
          const answersToCheck: string[] = previewForm.id ? previewFormValues[qId] : answers[qId];
          const isDocumentComponent = [
            QuestionComponent.DOCUMENT,
            QuestionComponent.CONTRACT,
            QuestionComponent.ASSIGNMENT_SCHEDULE
          ].includes(component);

          if (!required) {
            return true;
          } else if (isDocumentComponent) {
            const hasAnswers = answersToCheck?.every((a) => !!a);
            const hasDocuments = !!documents.find((d) => {
              const fileNameWithoutExt = d.filename.split('.')[0];
              return fileNameWithoutExt === component;
            });
            return hasAnswers || hasDocuments;
          }
          return answersToCheck?.length;
        });

      const questionToCheck = filterQuestionsByRules(questions, rules, answers);

      if (children.length) {
        const questionsCompleted = checkAnswers(questionToCheck);
        const childrenSectionsCompleted = children.every(({ questions }) =>
          checkAnswers(filterQuestionsByRules(questions, rules, answers))
        );

        isSectionCompleted = questionsCompleted && childrenSectionsCompleted;
      } else {
        isSectionCompleted = checkAnswers(questionToCheck);
      }

      result[id] = isSectionCompleted;
      return result;
    }, {});

    const { id, firstName, lastName, form } = registration || {};

    return {
      ...sectionsCompleteness,
      [BASIC_INFO_SECTION.id]: Boolean(
        previewForm.id
          ? previewFormValues.id &&
              previewFormValues.firstName &&
              previewFormValues.lastName &&
              previewFormValues.contractType
          : id && firstName && lastName && form?.contractType
      )
    };
  }, [sections, registration, previewForm.id, previewFormValues, rules, answers, documents]);

  return {
    rules,
    combinedAnswers,
    setSectionValues,
    isOpened: isOpened || !!previewForm.id,
    id,
    registration,
    loading: result.loading || documentsResult.loading || answersLoading,
    basicInfo,
    clearBasicInfo,
    openWithBasicInfo,
    setId,
    save,
    openModal,
    openModalWithId,
    closeModal,
    sendAnswer,
    documents,
    completenessOfRegistration,
    isDirty,
    setIsDirty,
    sections,
    answers,
    previewForm,
    setPreviewForm,
    previewFormValues,
    setPreviewFormValues,
    invalidSections,
    setInvalidSections
  };
};
