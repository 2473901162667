import { useMutation } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { type AgencySave } from '__graphql__/globalTypes';
import { Field } from 'formik';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { FormDialog, Input } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { Validator } from 'shared/utils/validator';
import { type CreateAgency, type CreateAgencyVariables } from '../Users/__graphql__/CreateAgency';
import { createAgencyMutation } from '../Users/api';

export const AddAgencyForm: FC = () => {
  const intl = useIntl();

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Field
        id="name"
        name="name"
        label={intl.formatMessage({ id: 'agency_name' })}
        component={Input}
        validate={Validator.pipe(
          Validator.methods.required()
        )}
        sx={{ mt: 2 }}
      />
      <Field
        id="website"
        name="website"
        label={intl.formatMessage({ id: 'website' })}
        component={Input}
        validate={Validator.pipe(
          Validator.methods.required(),
          Validator.methods.website()
        )}
      />
      <Field
        id="phone"
        name="phone"
        label={intl.formatMessage({ id: 'telephone' })}
        component={Input}
        validate={Validator.pipe(
          Validator.methods.required(),
          Validator.methods.phone()
        )}
      />
    </Box>
  );
};

export const AddAgencyWidget: FC = () => {
  const intl = useIntl();

  const [isModalOpen, setModalOpen] = useState(false);

  const [createAgency, { loading }] = useMutation<
  CreateAgency,
  CreateAgencyVariables
  >(createAgencyMutation, {
    refetchQueries: ['AgenciesQuery'],
    awaitRefetchQueries: true
  });

  return (
    <>
      <Button
        type="button"
        size="medium"
        variant="contained"
        onClick={() => { setModalOpen(true); }}
      >
        {intl.formatMessage({ id: 'new_agency' })}
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => { setModalOpen(false); }}
        header={intl.formatMessage({ id: 'new_agency' })}
        size="xs"
        loading={loading}
        confirmBtnName={intl.formatMessage({ id: 'save' })}
        onSubmit={async (values: AgencySave) => {
          try {
            await createAgency({ variables: { data: values } });
            setModalOpen(false);
            toast(
              <SuccessSnackbar
                title={intl.formatMessage({ id: 'new_agency_successfully_created' })}
                message={intl.formatMessage({ id: 'this_agency_is_added' })}
              />
            );
          } catch (e) {
            console.log(e);
          }
        }}
      >
        <AddAgencyForm />
      </FormDialog>
    </>
  );
};
