import { assocPath, dissocPath, path } from 'ramda';

function setItem(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

function deleteProperty(dotPath: string): void {
  const [key, ...path] = dotPath.split('.');
  const value = JSON.parse(localStorage.getItem(key) ?? '{}');
  const result = dissocPath(path, value);
  setItem(key, result);
}

function getItem<T = any>(dotPath: string): T {
  const [key, ...pathStrings] = dotPath.split('.');
  const value = JSON.parse(localStorage.getItem(key) ?? '{}');
  return path(pathStrings, value) as T;
}

function setProperty(dotPath: string, value: any): void {
  const [key, ...pathStrings] = dotPath.split('.');
  const existValue = getItem(key);
  const withAddedProperty = assocPath(pathStrings, value, existValue);
  setItem(key, withAddedProperty);
}

export const localStorageUtils = {
  setItem,
  getItem,
  setProperty,
  deleteProperty
};
