import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useCallback, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { CrossIcon } from 'shared/components/icons';

interface Props {
  disabled?: boolean;
  onSubmit: (arg0: string) => void;
}

export const EmailWidget: FC<Props> = ({ disabled, onSubmit }) => {
  const intl = useIntl();
  const [isEmailOpened, setIsEmailOpened] = useState(false);
  const [html, setHtml] = useState('');

  const onEditorChange = useCallback((e: any) => {
    setHtml(e.target.value);
  }, [setHtml]);

  const handleSubmit = useCallback(() => {
    onSubmit(html);
  }, [onSubmit, html]);

  return (
    <>
      <Button
        variant="contained"
        color='error'
        disabled={disabled}
        onClick={() => { setIsEmailOpened(true); }}
      >
        {intl.formatMessage({ id: 'reject_all' })}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isEmailOpened}
        onClose={() => { setIsEmailOpened(false); }}
      >
        <DialogTitle>
          <Typography variant="h2" color="primary.main">
            {intl.formatMessage({ id: 'requested_information' })}
          </Typography>
          <IconButton onClick={() => { setIsEmailOpened(false); }}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ '& .rsw-ce': { minHeight: '150px' } }}>
          <DefaultEditor value={html} onChange={onEditorChange} />
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            variant="contained"
            disabled={!html}
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: 'submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
