import { useMutation, useQuery } from '@apollo/client';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { OrderDirection, Role, UserOrderField, type UpdateUserInput } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo, useState, type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { deleteUserMutation, inviteResendMutation, requestEmailChangeMutation, updateUserMutation } from 'shared/api';
import { type DeleteUser, type DeleteUserVariables } from 'shared/api/__graphql__/DeleteUser';
import { type InviteResend, type InviteResendVariables } from 'shared/api/__graphql__/InviteResend';
import { type RequestEmailChange, type RequestEmailChangeVariables } from 'shared/api/__graphql__/RequestEmailChange';
import { type UpdateUser, type UpdateUserVariables } from 'shared/api/__graphql__/UpdateUser';
import { API_CONTEXT } from 'shared/api/api-contexts';
import {
  ActionsPopup,
  ConfirmationDialog,
  EmptyTableBox,
  FormDialog,
  Loader,
  PageTitle,
  PageWrapper,
  SearchFilter,
  SelectFilter
} from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { StyledTablePagination } from 'shared/components/StyledTablePagination';
import AuthContext from 'shared/contexts/authContext';
import { useCustomStyles, useFiltersQuery, usePalette, useRoles, useTablePagination, useTableSort } from 'shared/hooks';
import { getRoleColor } from 'shared/utils/colors';
import { isStaffUser } from 'shared/utils/users';
import { type Users as UsersQuery, type UsersVariables, type Users_users_data } from './__graphql__/Users';
import { AddUserForm, AddUserWidget } from './AddUserWidget';
import { usersQuery } from './api';

export const Users: FC = () => {
  const intl = useIntl();
  const { success, error } = usePalette();
  const customStyles = useCustomStyles();
  const { authState } = useContext(AuthContext);
  const { isAdmin, isSuperAdmin, isCustomerCare, isRegistrar } = useRoles();
  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { field, direction, createSortHandler, setSort } = useTableSort<UserOrderField>({});
  const { parsedQuery, pushQuery } = useFiltersQuery();

  const [query, setQuery] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const [userForActivation, setUserForActivation] = useState<Users_users_data | undefined>();
  const [userForEdit, setUserForEdit] = useState<Users_users_data | undefined>();
  const [userForDelete, setUserForDelete] = useState<Users_users_data | undefined>();

  const usedRoles = useMemo(
    () => Object.values(Role).filter((r) => ![Role.SUPER_ADMIN, Role.CONTRACTOR].includes(r)),
    []
  );

  const roleOptions = useMemo(
    () =>
      usedRoles.map((r) => ({
        value: r,
        label: intl.formatMessage({ id: `user.role.${r}` })
      })),
    [usedRoles, intl]
  );

  const result = useQuery<UsersQuery, UsersVariables>(usersQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
              field,
              direction
            }
          : null,
      offset,
      limit,
      query: query?.length > 2 ? query.trim() : null,
      filter: {
        ...(roles?.length ? { roles } : { roles: usedRoles })
      }
    },
    context: API_CONTEXT.AUTH
  });

  useEffect(() => {
    pushQuery({
      filters: [
        ...(roles?.length
          ? [
              {
                id: 'roles',
                value: roles
              }
            ]
          : []),
        ...(query
          ? [
              {
                id: 'search',
                value: query
              }
            ]
          : [])
      ],
      sort: [
        ...(field
          ? [
              {
                id: field,
                desc: direction === OrderDirection.desc
              }
            ]
          : [])
      ],
      pageIndex: tablePaginationProps.page
    });
  }, [direction, field, pushQuery, roles, tablePaginationProps.page, query]);

  useEffect(() => {
    setSort(
      (parsedQuery?.sortBy ?? UserOrderField.firstName) as UserOrderField,
      (parsedQuery?.sortOrder as OrderDirection) ?? OrderDirection.asc
    );
    setQuery((parsedQuery?.search as string) ?? '');
    setRoles((parsedQuery?.roles as Role[]) ?? []);
    setTimeout(() => {
      tablePaginationProps.onPageChange(null, parsedQuery.pageIndex ? Number(parsedQuery.pageIndex) : 0);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(updateUserMutation, {
    refetchQueries: ['Users'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const [deleteUser] = useMutation<DeleteUser, DeleteUserVariables>(deleteUserMutation, {
    refetchQueries: ['Users'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.AUTH
  });

  const [inviteUser] = useMutation<InviteResend, InviteResendVariables>(inviteResendMutation, {
    context: API_CONTEXT.AUTH
  });

  const [requestEmailChange] = useMutation<RequestEmailChange, RequestEmailChangeVariables>(
    requestEmailChangeMutation,
    {
      context: API_CONTEXT.AUTH
    }
  );

  const sendInvite = useCallback(
    async (id: string) => {
      try {
        await inviteUser({ variables: { id } });
        toast(
          <SuccessSnackbar
            title={intl.formatMessage({ id: 'access_link_was_successfully_sent' })}
            message={intl.formatMessage({ id: 'the_user_received_new_link' })}
          />
        );
      } catch (e) {
        console.log('Error: ', e);
      }
    },
    [intl, inviteUser]
  );

  const handleCloseActivatingModal = useCallback(() => {
    setUserForActivation(undefined);
  }, []);

  const handleCloseDeletingModal = useCallback(() => {
    setUserForDelete(undefined);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setUserForEdit(undefined);
  }, []);

  const handleUpdateUser = useAsyncCallback(
    async ({ name, agency: _, email, ...values }: UpdateUserInput & { name: string; agency?: string }) => {
      const [firstName = '', lastName = ''] = name.split(' ');
      const isEmailChanged = userForEdit?.email !== email;
      const shouldRequestEmailChange = isStaffUser(userForEdit!.role) && userForEdit?.email && email && isEmailChanged;

      const requests: Array<Promise<any>> = [
        updateUser({
          variables: {
            data: {
              id: values.id,
              ...(shouldRequestEmailChange ? {} : { email }),
              role: values.role,
              firstName,
              lastName,
              agencyId: values.agencyId
            }
          }
        })
      ];
      if (shouldRequestEmailChange) {
        requests.push(requestEmailChange({ variables: { newEmail: email, userId: values.id } }));
      }
      await Promise.all(requests);
      toast(
        <SuccessSnackbar
          title={intl.formatMessage({ id: 'user_updated' })}
          message={isStaffUser(userForEdit!.role) ? intl.formatMessage({ id: 'user_updated.description' }) : undefined}
        />,
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
      handleCloseEditModal();
    }
  );

  const handleActivate = useAsyncCallback(async () => {
    await updateUser({
      variables: {
        data: {
          id: userForActivation?.id!,
          isActive: !userForActivation?.isActive
        }
      }
    });
    toast(
      <SuccessSnackbar
        title={intl.formatMessage({ id: 'user_updated' })}
        message={intl.formatMessage({
          id: userForActivation?.isActive ? 'the_user_deactivated' : 'the_user_is_activated'
        })}
      />,
      {
        position: toast.POSITION.TOP_RIGHT
      }
    );
    handleCloseActivatingModal();
  });

  const handleDeleteUser = useAsyncCallback(async (id: string) => {
    await deleteUser({ variables: { id } });
    handleCloseDeletingModal();
    toast(
      <SuccessSnackbar
        title={intl.formatMessage({ id: 'user_removal.success.title' })}
        message={intl.formatMessage({ id: 'user_removal.success.content' })}
      />
    );
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle>
          <Box
            sx={{
              '& > *:not(:last-child)': {
                marginRight: '16px'
              }
            }}
          >
            <SearchFilter value={query} onChange={setQuery} placeholderId='user_search' />
            {(isAdmin || isSuperAdmin) && (
              <SelectFilter
                values={roles}
                placeholder={intl.formatMessage({ id: 'user_role' })}
                allItemLabel={intl.formatMessage({ id: 'user.role.all' })}
                options={roleOptions}
                onApply={setRoles}
              />
            )}
            <AddUserWidget />
          </Box>
        </PageTitle>
        {result.loading || handleUpdateUser.loading ? (
          <Loader />
        ) : (
          <PageWrapper>
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(UserOrderField.firstName)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'name' })}
                          <SortIcon active={field === UserOrderField.firstName} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(UserOrderField.email)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'email' })}
                          <SortIcon active={field === UserOrderField.email} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(UserOrderField.role)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'role' })}
                          <SortIcon active={field === UserOrderField.role} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(UserOrderField.lastLogin)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'last_login' })}
                          <SortIcon active={field === UserOrderField.lastLogin} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={createSortHandler(UserOrderField.isActive)} hideSortIcon={true}>
                          {intl.formatMessage({ id: 'status' })}
                          <SortIcon active={field === UserOrderField.isActive} direction={direction} />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>{intl.formatMessage({ id: 'actions' })}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.data?.users.data?.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>
                          <Highlighter
                            autoEscape
                            highlightStyle={customStyles.highlighter}
                            searchWords={[`${query}`]}
                            textToHighlight={`${user.firstName ?? ''} ${user.lastName ?? ''}`}
                          />
                        </TableCell>
                        <TableCell>
                          <Highlighter
                            autoEscape
                            highlightStyle={customStyles.highlighter}
                            searchWords={[`${query}`]}
                            textToHighlight={user.email ?? ''}
                          />
                        </TableCell>
                        <TableCell className='badge'>
                          <Box
                            sx={{
                              backgroundColor: getRoleColor(user.role)
                            }}
                          >
                            {intl.formatMessage({ id: `user.role.${user.role}` })}
                          </Box>
                        </TableCell>
                        <TableCell>{user.lastLogin ? dayjs(user.lastLogin).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                        <TableCell className='badge status'>
                          <Box
                            sx={{
                              backgroundColor: user.isActive ? success[200] : error[100]
                            }}
                          >
                            {intl.formatMessage({ id: `status.${user.isActive ? 'ACTIVE' : 'INACTIVE'}` })}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ width: 0 }}>
                          <ActionsPopup
                            items={[
                              ...((isAdmin ||
                                isSuperAdmin ||
                                ((isCustomerCare || isRegistrar) && user.role === Role.CONSULTANT)) &&
                              user.id !== authState.user?.id
                                ? [
                                    {
                                      id: 'edit',
                                      onClick: () => {
                                        setUserForEdit(user);
                                      }
                                    }
                                  ]
                                : []),
                              ...((isAdmin ||
                                isSuperAdmin ||
                                ((isCustomerCare || isRegistrar) && user.role === Role.CONSULTANT)) &&
                              user.id !== authState.user?.id
                                ? [
                                    {
                                      id: user.isActive ? 'deactivate' : 'activate',
                                      onClick: () => {
                                        setUserForActivation(user);
                                      }
                                    }
                                  ]
                                : []),
                              {
                                id: 'send_link',
                                onClick: async () => {
                                  await sendInvite(user.id);
                                },
                                disabled: !user.email || !user.isActive
                              },
                              ...((isAdmin || isSuperAdmin || (isRegistrar && user.role === Role.CONSULTANT)) &&
                              user.id !== authState.user?.id
                                ? [
                                    {
                                      id: 'delete',
                                      onClick: () => {
                                        setUserForDelete(user);
                                      }
                                    }
                                  ]
                                : [])
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {!result.data?.users.data?.length && tablePaginationProps.page === 0 && (
                      <TableRow
                        style={{
                          height: 64 * tablePaginationProps.rowsPerPage,
                          background: 'inherit'
                        }}
                      >
                        <TableCell
                          colSpan={5}
                          style={{
                            height: 64 * tablePaginationProps.rowsPerPage,
                            padding: 0
                          }}
                        >
                          <EmptyTableBox />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <StyledTablePagination count={result.data?.users.total ?? 0} {...tablePaginationProps} />
              <ConfirmationDialog
                open={!!userForActivation}
                onClose={handleCloseActivatingModal}
                onConfirm={handleActivate.execute}
                confirmButtonContent={intl.formatMessage({
                  id: userForActivation?.isActive ? 'deactivate' : 'activate'
                })}
                title={intl.formatMessage({
                  id: userForActivation?.isActive ? 'user_deactivation' : 'user_activation'
                })}
                content={intl.formatMessage(
                  { id: userForActivation?.isActive ? 'user_deactivation_confirm' : 'user_activation_confirm' },
                  {
                    role:
                      userForActivation?.role &&
                      userForActivation?.role.charAt(0) + userForActivation?.role.substring(1).toLowerCase(),
                    firstName: userForActivation?.firstName,
                    lastName: userForActivation?.lastName,
                    email: userForActivation?.email
                  }
                )}
              />
              <ConfirmationDialog
                open={!!userForDelete}
                onClose={handleCloseDeletingModal}
                onConfirm={async () => {
                  await handleDeleteUser.execute(userForDelete?.id!);
                }}
                confirmButtonContent={intl.formatMessage({ id: 'delete' })}
                title={intl.formatMessage({ id: 'user_removal' })}
                content={intl.formatMessage(
                  { id: 'user_removal_content' },
                  {
                    role:
                      userForDelete?.role &&
                      userForDelete?.role.charAt(0) +
                        userForDelete?.role.substring(1).replaceAll('_', ' ').toLowerCase(),
                    roleLowerCase: userForDelete?.role && userForDelete?.role.toLowerCase(),
                    firstName: userForDelete?.firstName,
                    lastName: userForDelete?.lastName,
                    email: userForDelete?.email
                  }
                )}
              />
              <FormDialog
                isOpen={!!userForEdit}
                onClose={handleCloseEditModal}
                header={intl.formatMessage({ id: 'edit_user' })}
                size='xs'
                loading={handleUpdateUser.loading}
                confirmBtnName={intl.formatMessage({ id: 'save' })}
                initialValues={{
                  ...userForEdit,
                  agency: undefined,
                  name: `${userForEdit?.firstName} ${userForEdit?.lastName}`
                }}
                onSubmit={handleUpdateUser.execute}
              >
                <AddUserForm />
              </FormDialog>
            </>
          </PageWrapper>
        )}
      </Box>
    </Box>
  );
};
