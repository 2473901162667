import { type ErrorResponse } from '@apollo/client/link/error';
import { QuestionComponent } from '__graphql__/globalTypes';
import { type GraphQLError } from 'graphql';
import { useContext, type FC } from 'react';
import { FormBuilderContext } from 'shared/contexts';
import { type UpdateQuestionVariables } from 'shared/hooks/useFormBuilder/__graphql__/UpdateQuestion';
import { BASIC_INFO_SECTION } from '../constants';
import { CheckboxComponentProperties } from './CheckboxComponentProperties';
import { DateTimeComponentProperties } from './DateTimeComponentProperties';
import { DayComponentProperties } from './DayComponentProperties';
import { DocumentComponentProperties } from './DocumentComponentProperties';
import { FileUploadComponentProperties } from './FileUploadComponentProperties';
import { InputComponentProperties } from './InputComponentProperties';
import { SelectComponentProperties } from './SelectComponentProperties';
import { type ComponentPropertiesProps } from './shared/types';
import { TextComponentProperties } from './TextComponentProperties';

interface Props {
  isReadOnly: boolean;
}

const MAP = {
  [QuestionComponent.HEADER_1]: TextComponentProperties,
  [QuestionComponent.HEADER_2]: TextComponentProperties,
  [QuestionComponent.HEADER_3]: TextComponentProperties,
  [QuestionComponent.PARAGRAPH]: TextComponentProperties,
  [QuestionComponent.SHORT_TEXT]: InputComponentProperties,
  [QuestionComponent.LONG_TEXT]: InputComponentProperties,
  [QuestionComponent.SINGLE_SELECT]: SelectComponentProperties,
  [QuestionComponent.MULTIPLE_SELECT]: SelectComponentProperties,
  [QuestionComponent.OPTIONS_SMALL]: SelectComponentProperties,
  [QuestionComponent.OPTIONS_BIG]: SelectComponentProperties,
  [QuestionComponent.DATE]: DateTimeComponentProperties,
  [QuestionComponent.TIME]: DateTimeComponentProperties,
  [QuestionComponent.TIME_PICKER]: DateTimeComponentProperties,
  [QuestionComponent.DAY]: DayComponentProperties,
  [QuestionComponent.CHECKBOX]: CheckboxComponentProperties,
  [QuestionComponent.CONTRACT]: DocumentComponentProperties,
  [QuestionComponent.DOCUMENT]: DocumentComponentProperties,
  [QuestionComponent.ASSIGNMENT_SCHEDULE]: DocumentComponentProperties,
  [QuestionComponent.FILE_UPLOAD]: FileUploadComponentProperties
} as Record<QuestionComponent, FC<ComponentPropertiesProps>>;

export const ComponentProperties: FC<Props> = ({ isReadOnly }) => {
  const { updateQuestion, component, section } = useContext(FormBuilderContext);

  const onSubmit = async (data: Omit<UpdateQuestionVariables['data'], 'id'>): Promise<GraphQLError | null> => {
    try {
      await updateQuestion({
        variables: {
          data: {
            id: component?.id!,
            ...data
          }
        }
      });
      return null;
    } catch (e) {
      const error = (e as ErrorResponse).graphQLErrors?.[0];
      return error ?? null;
    }
  };

  const Component = MAP[component?.component!];

  return Component ? (
    <Component isReadOnly={isReadOnly || section?.id === BASIC_INFO_SECTION.id} onSubmit={onSubmit} />
  ) : null;
};
