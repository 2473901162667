import { OrderDirection } from '__graphql__/globalTypes';
import { useState } from 'react';

interface Props<T> {
  defaultField?: T;
  defaultDirection?: OrderDirection;
}

export interface UseTableSort<T> {
  field?: T;
  direction?: OrderDirection;
  createSortHandler: (field: T) => () => void;
  setSort: (field: T, direction: OrderDirection) => void;
}

export const useTableSort = <T>({ defaultField, defaultDirection }: Props<T>): UseTableSort<T> => {
  const [field, setField] = useState<T | undefined>(defaultField);
  const [direction, setDirection] = useState<OrderDirection | undefined>(defaultDirection);

  const createSortHandler = (newField: T) => () => {
    setField(newField);
    if (newField !== field) {
      setDirection(OrderDirection.asc);
    } else {
      setDirection(direction === OrderDirection.asc ? OrderDirection.desc : OrderDirection.asc);
    }
  };

  const setSort = (newField: T, newDirection: OrderDirection): void => {
    setField(newField);
    setDirection(newDirection);
  };

  return { field, direction, createSortHandler, setSort };
};
