import { useQuery } from '@apollo/client';
import { Box, IconButton, MenuItem, Select } from '@mui/material';
import { OrderDirection, RegistrationOrderField, RegistrationStatus } from '__graphql__/globalTypes';
import { ReferralsTable } from 'consultant/components';
import { useEffect, useMemo, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Loader, PageTitle, PageWrapper, StaticFooter } from 'shared/components';
import { ChevronIcon, CrossIcon } from 'shared/components/icons';
import { useTablePagination, useTableSort } from 'shared/hooks';
import { CSVReferralsUploadWidget } from '../../../shared/components/CSVReferralsUploadWidget';
import { type Referrals as ReferralsQuery, type ReferralsVariables } from './__graphql__/Referrals';
import { referralsQuery } from './api';

enum TypeOption {
  current = 'current',
  dummyRecord = 'dummyRecord',
  locked = 'locked',
}

export const Dashboard: FC = () => {
  const intl = useIntl();
  const location = useLocation();

  const { offset, limit, ...tablePaginationProps } = useTablePagination();
  const { field, direction, createSortHandler } = useTableSort<RegistrationOrderField>({
    defaultField: RegistrationOrderField.status,
    defaultDirection: OrderDirection.asc
  });
  const [status, setStatus] = useState<RegistrationStatus | undefined>();
  const [type, setType] = useState<TypeOption | undefined>(TypeOption.current);

  const result = useQuery<ReferralsQuery, ReferralsVariables>(referralsQuery, {
    variables: {
      orderBy:
        field && direction
          ? {
              field,
              direction
            }
          : null,
      offset,
      limit,
      filter: {
        ...(status ? { status } : {}),
        ...(type ? { [type]: true } : {}),
        addedByMe: true
      },
      ...(location.state?.search ? { query: location.state.search.toLowerCase() } : {})
    }
  });

  useEffect(() => {
    tablePaginationProps.onPageChange(null, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, status, type]);

  const statusOptions = useMemo(() => Object.values(RegistrationStatus), []);
  const typeOptions = useMemo(() => Object.values(TypeOption), []);

  if (result.loading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PageTitle name={intl.formatMessage({ id: 'dashboard' })}>
          <Box>
            <Select
              displayEmpty
              value={status}
              className="filter"
              onChange={(e) => {
                setStatus(e.target.value as RegistrationStatus);
              }}
              renderValue={() => {
                if (!status) {
                  return intl.formatMessage({ id: 'status' });
                }
                return intl.formatMessage({ id: `registration.status.${status}` });
              }}
              sx={{ padding: 0, mr: 3 }}
              IconComponent={ChevronIcon}
              endAdornment={
                status
                  ? (
                  <IconButton
                    sx={{ right: '26px', position: 'absolute', padding: '2px' }}
                    onClick={() => {
                      setStatus(undefined);
                    }}
                  >
                    <CrossIcon />
                  </IconButton>
                    )
                  : null
              }
            >
              {statusOptions.map((s) => (
                <MenuItem key={s} value={s}>
                  {intl.formatMessage({ id: `registration.status.${s}` })}
                </MenuItem>
              ))}
            </Select>
            <Select
              displayEmpty
              value={type}
              className="filter"
              onChange={(e) => {
                setType(e.target.value as TypeOption);
              }}
              renderValue={() => {
                if (!type) {
                  return intl.formatMessage({ id: 'type' });
                }
                return intl.formatMessage({ id: `registration.type.${type}` });
              }}
              sx={{ padding: 0, mr: 8 }}
              IconComponent={ChevronIcon}
              endAdornment={
                type
                  ? (
                  <IconButton
                    sx={{ right: '26px', position: 'absolute', padding: '2px' }}
                    onClick={() => {
                      setType(undefined);
                    }}
                  >
                    <CrossIcon />
                  </IconButton>
                    )
                  : null
              }
            >
              {typeOptions.map((t) => (
                <MenuItem key={t} value={t}>
                  {intl.formatMessage({ id: `registration.type.${t}` })}
                </MenuItem>
              ))}
            </Select>
            <CSVReferralsUploadWidget />
          </Box>
        </PageTitle>
        <PageWrapper>
          <ReferralsTable
            data={result.data?.registrations.data}
            total={result.data?.registrations.total}
            field={field}
            direction={direction}
            createSortHandler={createSortHandler}
            tablePaginationProps={tablePaginationProps}
          />
        </PageWrapper>
      </Box>
      <StaticFooter />
    </Box>
  );
};
