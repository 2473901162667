import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useContext, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { PlusIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';
import { ComponentsList } from './ComponentsList';
import { SectionsList } from './SectionsList';
import { WidgetCard } from './WidgetCard';

enum Tabs {
  Sections = 'sections',
  Components = 'components'
}

export const Structure: FC = () => {
  const { secondary } = usePalette();
  const { formatMessage } = useIntl();

  const [currentTab, setCurrentTab] = useState(Tabs.Sections);
  const { isReadOnly, setActionOnSection } = useContext(FormBuilderContext);

  return (
    <WidgetCard
      sx={{ flex: 1, maxWidth: '380px' }}
      header={
        <ToggleButtonGroup
          sx={{ color: secondary[800], width: '100%' }}
          value={currentTab}
          exclusive
          onChange={(_, v) => {
            if (v) {
              setCurrentTab(v);
            }
          }}
          orientation='horizontal'
          className='stacked'
        >
          {Object.values(Tabs).map((tab) => (
            <ToggleButton key={tab} value={tab} sx={{ flex: 1, p: '4px' }}>
              {formatMessage({ id: tab })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      }
      body={currentTab === Tabs.Sections ? <SectionsList /> : <ComponentsList />}
      footer={
        isReadOnly ? undefined : (
          <Button
            onClick={() => {
              setActionOnSection('add');
              setCurrentTab(Tabs.Sections);
            }}
          >
            <Typography variant='body2' sx={{ mr: '10px' }}>
              {formatMessage({ id: 'add_new_section' })}
            </Typography>
            <PlusIcon />
          </Button>
        )
      }
    />
  );
};
