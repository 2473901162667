import { type ErrorResponse } from '@apollo/client/link/error';

export const NETWORK_ERROR = 'Network Error';
export const isNetworkError = (error?: string): boolean => error === NETWORK_ERROR;

export const AUTHENTICATION_ERROR = 'UNAUTHENTICATED';
export const isAuthenticationError = (error?: string): boolean => error === AUTHENTICATION_ERROR;

export const BAD_INPUT_ERROR = 'BAD_USER_INPUT';
export const isBadInputError = (error?: string): boolean => error === BAD_INPUT_ERROR;

export const USER_NOT_APPROVED_ERROR = 'User not approved';
export const isUserNotApprovedError = (error?: string): boolean => error === USER_NOT_APPROVED_ERROR;

export const I_AM_TEAPOT_ERROR = '418';
export const isTeapotError = (error?: string): boolean => error === I_AM_TEAPOT_ERROR;

export const GONE_ERROR = '410';
export const isGoneError = (error?: string): boolean => error === GONE_ERROR;

export const FORBIDDEN_ERROR = 'FORBIDDEN';
export const isForbiddenError = (error?: string): boolean => error === FORBIDDEN_ERROR;

export const CONFLICT_ERROR = '409';
export const isConflictError = (error?: string): boolean => error === CONFLICT_ERROR;

export const NOT_FOUND_ERROR = '404';
export const isNotFoundError = (error?: string): boolean => error === NOT_FOUND_ERROR;

export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';

export const retrieveErrorCode = (err: unknown): string =>
  (err as ErrorResponse).graphQLErrors?.[0]?.extensions?.code as string;

export const retrieveErrorMessage = (err: unknown): string =>
  (err as ErrorResponse).graphQLErrors?.[0]?.message as string;

const errors = {
  authenticationError: () => new Error(AUTHENTICATION_ERROR),
  badInputError: () => new Error(BAD_INPUT_ERROR),
  userNotApprovedError: () => new Error(USER_NOT_APPROVED_ERROR),
  teapotError: () => new Error(I_AM_TEAPOT_ERROR),
  goneError: () => new Error(GONE_ERROR),
  forbiddenError: () => new Error(FORBIDDEN_ERROR),
  conflictError: () => new Error(CONFLICT_ERROR),
  notFoundError: () => new Error(NOT_FOUND_ERROR)
};

export default errors;
