import { useMutation } from '@apollo/client';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { FormOrderField, FormStatus } from '__graphql__/globalTypes';
import dayjs from 'dayjs';
import { pick } from 'ramda';
import { useContext, useState, type FC } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ActionsPopup, ConfirmationDialog, EmptyTableBox } from 'shared/components';
import { SortIcon } from 'shared/components/icons';
import { AttentionSnackbar, SuccessSnackbar } from 'shared/components/Snackbars';
import routes from 'shared/constants/routes';
import { FormBuilderContext } from 'shared/contexts';
import { useCustomStyles, useTenantNavigate, type UseTableSort } from 'shared/hooks';
import { getFormStatusColor } from 'shared/utils/colors';
import { type CopyForm, type CopyFormVariables } from './__graphql__/CopyForm';
import { type DeleteForm, type DeleteFormVariables } from './__graphql__/DeleteForm';
import { type Forms_forms_data } from './__graphql__/Forms';
import { copyFormMutation, deleteFormMutation } from './api';
import { EditFormDialog } from './FormWidget';

const tableHeaderConfig = [
  { field: FormOrderField.name, labelId: 'name' },
  { field: FormOrderField.description, labelId: 'description' },
  { field: FormOrderField.creator, labelId: 'creator' },
  { field: FormOrderField.createdAt, labelId: 'created_on' },
  { field: FormOrderField.publishedAt, labelId: 'published_on' },
  { field: FormOrderField.status, labelId: 'status' }
];

interface Props {
  data?: Forms_forms_data[] | null;
  page: number;
  rowsPerPage: number;
  sort: UseTableSort<FormOrderField>;
  search: string;
}

export const FormsTable: FC<Props> = ({
  data,
  page,
  rowsPerPage,
  search,
  sort: { createSortHandler, field, direction }
}) => {
  const { formatMessage } = useIntl();
  const navigate = useTenantNavigate();
  const [editForm, setEditForm] = useState<Forms_forms_data | null>(null);
  const [formToDelete, setFormToDelete] = useState<Forms_forms_data | null>(null);
  const customStyles = useCustomStyles();
  const [formToPublish, setFormToPublish] = useState<Forms_forms_data | null>(null);

  const { submitForm, fetchErrors } = useContext(FormBuilderContext);

  const [deleteForm, { loading: deleteLoading }] = useMutation<DeleteForm, DeleteFormVariables>(deleteFormMutation, {
    variables: { id: formToDelete?.id! },
    refetchQueries: ['Forms'],
    awaitRefetchQueries: true
  });

  const [copyForm] = useMutation<CopyForm, CopyFormVariables>(copyFormMutation, {
    refetchQueries: ['Forms'],
    awaitRefetchQueries: true
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaderConfig.map(({ field: cellField, labelId }) => (
              <TableCell key={cellField}>
                <TableSortLabel onClick={createSortHandler(cellField)} hideSortIcon={true}>
                  {formatMessage({ id: labelId })}
                  <SortIcon active={field === cellField} direction={direction} />
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>{formatMessage({ id: 'actions' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((form) => (
            <TableRow key={form.id}>
              <TableCell sx={{ maxWidth: '300px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Highlighter
                  autoEscape
                  highlightStyle={customStyles.highlighter}
                  searchWords={[search]}
                  textToHighlight={form.name}
                />
              </TableCell>
              <TableCell sx={{ maxWidth: '300px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {form.description}
              </TableCell>
              <TableCell>
                {form.creator?.firstName} {form.creator?.lastName}
              </TableCell>
              <TableCell>{dayjs(form.createdAt).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{form.publishedAt ? dayjs(form.publishedAt).format('DD/MM/YYYY') : ''}</TableCell>
              <TableCell className='badge status'>
                <Box
                  sx={{
                    backgroundColor: getFormStatusColor(form.status)
                  }}
                >
                  {formatMessage({ id: `form.status.${form.status}` })}
                </Box>
              </TableCell>
              <TableCell sx={{ width: 0 }}>
                <ActionsPopup
                  items={[
                    {
                      id: 'edit',
                      onClick: () => setEditForm(form)
                    },
                    {
                      id: 'open',
                      onClick: () => navigate(routes.form.replace(':id', form.id))
                    },
                    {
                      id: 'copy',
                      onClick: async () => {
                        await copyForm({ variables: { id: form.id } });
                        toast(
                          <SuccessSnackbar
                            title={formatMessage({ id: 'form_copied' })}
                            message={formatMessage({ id: 'form_copied_message' })}
                          />
                        );
                      }
                    },
                    {
                      id: form.status === FormStatus.aPUBLISHED ? 'unpublish' : 'publish',
                      onClick: async () => {
                        const isPublished = form.status === FormStatus.aPUBLISHED;
                        const status = isPublished ? FormStatus.bUNPUBLISHED : FormStatus.aPUBLISHED;
                        if (!isPublished) {
                          const formErrors = await fetchErrors(true, form.id);
                          if (Object.keys(formErrors).length) {
                            formErrors.forEach(({ sectionName, error }) => {
                              toast(
                                sectionName ? (
                                  <AttentionSnackbar
                                    title={formatMessage({ id: 'section_need_to_be_fixed_title' }, { sectionName })}
                                    message={formatMessage({ id: `publish_form_${error}` }, { sectionName })}
                                  />
                                ) : (
                                  <AttentionSnackbar
                                    title={formatMessage({ id: 'form_need_to_be_fixed_title' })}
                                    message={formatMessage({ id: `publish_form_${error}` })}
                                  />
                                )
                              );
                            });
                            return;
                          }
                        } else if (form.status === FormStatus.cDRAFT) {
                          setFormToPublish(form);
                          return;
                        }
                        await submitForm({
                          variables: { data: { ...pick(['name', 'id'], form), status } },
                          refetchQueries: ['Forms'],
                          awaitRefetchQueries: true
                        });
                      },
                      disabled:
                        form.status === FormStatus.aPUBLISHED &&
                        data.filter(({ status }) => status === FormStatus.aPUBLISHED).length === 1
                    },
                    {
                      id: 'delete',
                      onClick: () => setFormToDelete(form),
                      disabled: form.status === FormStatus.aPUBLISHED
                    }
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
          {!data?.length && !page && (
            <TableRow
              style={{
                height: 64 * rowsPerPage,
                background: 'inherit'
              }}
            >
              <TableCell
                colSpan={tableHeaderConfig.length}
                style={{
                  height: 64 * rowsPerPage,
                  padding: 0
                }}
              >
                <EmptyTableBox />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!!editForm && <EditFormDialog closeModal={() => setEditForm(null)} form={editForm} />}
      <ConfirmationDialog
        loading={deleteLoading}
        open={!!formToDelete}
        onClose={() => {
          setFormToDelete(null);
        }}
        onConfirm={async () => {
          await deleteForm();
          toast(
            <SuccessSnackbar
              title={formatMessage({ id: 'form_delete' })}
              message={formatMessage({ id: 'form_delete_message' })}
            />
          );
          if (formToDelete?.status !== FormStatus.cDRAFT) {
            toast(
              <SuccessSnackbar
                title={formatMessage({ id: 'email_template_dDELETED_title' })}
                message={formatMessage({ id: 'email_template_dDELETED_message' })}
              />
            );
          }
          setFormToDelete(null);
        }}
        confirmButtonContent={formatMessage({ id: 'delete' })}
        title={formatMessage({ id: 'form_removal' })}
        content={
          <Typography>{formatMessage({ id: 'form_delete_confirmation' }, { name: formToDelete?.name })}</Typography>
        }
      />
      {!!formToPublish && (
        <ConfirmationDialog
          open
          onClose={() => {
            setFormToPublish(null);
          }}
          onConfirm={async () => {
            await submitForm({
              variables: { data: { id: formToPublish?.id, name: formToPublish?.name, status: FormStatus.aPUBLISHED } },
              refetchQueries: ['FormDetails'],
              awaitRefetchQueries: true
            });
            toast(
              <AttentionSnackbar
                title={formatMessage({ id: 'email_update_need_title' })}
                message={formatMessage({ id: 'email_update_need_message' })}
              />
            );
          }}
          confirmButtonContent={formatMessage({ id: 'got_it' })}
          title={formatMessage({ id: 'email_template_warning' })}
          content={formatMessage({ id: 'email_template_warning_message' })}
        />
      )}
    </TableContainer>
  );
};
