import { Box, Button, Typography } from '@mui/material';
import { type WarningsState } from 'admin/App';
import { useContext, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { OctagonQuestionMark } from 'shared/components/icons';
import routes from 'shared/constants/routes';
import { CompanyContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';

type BannerType = keyof WarningsState;

interface Props {
  reason: BannerType;
  storeAction: (path: string, value: boolean) => void;
}

const MESSAGES: Record<BannerType, string> = {
  emailsConfig: 'emails_setup_warning',
  noFunds: 'no_enough_funds',
  cardExpiration: ''
};

export const WarningBanner: FC<Props> = ({ storeAction, reason }) => {
  const { formatMessage } = useIntl();
  const { secondary, warning } = usePalette();
  const { setIsEdit } = useContext(CompanyContext);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 25px',
        backgroundColor: warning[50],
        borderBottom: `1px solid ${warning[100]}`
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <OctagonQuestionMark />
        <Typography sx={{ ml: '14px', color: secondary[800] }}>
          {formatMessage(
            { id: MESSAGES[reason] },
            {
              emails: (
                <Link style={{ color: 'inherit' }} to={routes.emails}>
                  {formatMessage({ id: 'emails' })}
                </Link>
              ),
              editModal: (
                <Button
                  sx={{
                    p: 0,
                    fontWeight: 'inherit',
                    textDecoration: 'underline',
                    '&:hover, &:active': {
                      background: 'none'
                    }
                  }}
                  onClick={() => setIsEdit(true)}
                >
                  {formatMessage({ id: 'my_company_edit' })}
                </Button>
              )
            }
          )}
        </Typography>
      </Box>

      <Box>
        <Button
          variant='text'
          sx={{ mr: '40px', color: secondary[800] }}
          onClick={() => {
            storeAction(`${reason}.remindLater`, true);
          }}
        >
          {formatMessage({ id: 'remind_me_later' })}
        </Button>
        <Button
          variant='text'
          sx={{ mr: '40px', color: secondary[800] }}
          onClick={() => {
            storeAction(`${reason}.closedForever`, true);
          }}
        >
          {formatMessage({ id: 'close' })}
        </Button>
      </Box>
    </Box>
  );
};
