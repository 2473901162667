import { useMutation } from '@apollo/client';
import { type ErrorResponse } from '@apollo/client/link/error';
import { Button } from '@mui/material';

import { type TenantCreate } from '__graphql__/globalTypes';
import { useCallback, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { FormDialog, CompanyForm } from 'shared/components';
import { InformationSnackbar, ErrorSnackbar } from 'shared/components/Snackbars';
import { isConflictError } from 'shared/utils/errors';
import { TenantSetupTrackingContext } from '../../contexts';
import { type CreateTenant, type CreateTenantVariables } from './__graphql__/CreateTenant';
import { createTenantMutation } from './api';

export const AddTenantWidget = (): JSX.Element => {
  const intl = useIntl();
  const { getCurrentTracking, startTracking } = useContext(TenantSetupTrackingContext);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleClose = useCallback(async () => {
    setModalOpen(false);
  }, []);

  const [createTenant, { loading }] = useMutation<CreateTenant, CreateTenantVariables>(createTenantMutation, {
    refetchQueries: ['Tenants'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.SUPERADMIN
  });

  return (
    <>
      <Button
        type='button'
        size='medium'
        variant='contained'
        onClick={() => {
          const currentTracking = getCurrentTracking();
          if (currentTracking) {
            toast(
              <InformationSnackbar
                title={intl.formatMessage({ id: 'tenant_cannot_create' })}
                message={intl.formatMessage({ id: 'tenant_wait_for_previous' })}
              />
            );
          } else {
            setModalOpen(true);
          }
        }}
      >
        {intl.formatMessage({ id: 'new_tenant' })}
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={handleClose}
        header={intl.formatMessage({ id: 'new_tenant' })}
        size='xs'
        loading={loading}
        confirmBtnName={intl.formatMessage({ id: 'save' })}
        onSubmit={async (values: TenantCreate, formikHelpers) => {
          formikHelpers?.setSubmitting(true);
          try {
            await createTenant({ variables: { data: values } });
            await startTracking(values.id);
            toast(
              <InformationSnackbar
                title={intl.formatMessage({ id: 'tenant_in_progress' })}
                message={intl.formatMessage({ id: 'tenant_create_info' })}
              />
            );
            handleClose();
          } catch (e) {
            if (isConflictError((e as ErrorResponse).graphQLErrors?.[0].extensions.code as string)) {
              toast(
                <ErrorSnackbar
                  title={intl.formatMessage({ id: 'tenant_url_name_or_email_already_in_use' })}
                  message={intl.formatMessage({ id: 'you_cant_create_tenant_with_url_name_or_email' })}
                />,
                {
                  position: toast.POSITION.BOTTOM_CENTER
                }
              );
              formikHelpers?.setFieldError('email', ' ');
            }
          } finally {
            formikHelpers?.setSubmitting(false);
          }
        }}
      >
        {/* @ts-expect-error: error */}
        <CompanyForm />
      </FormDialog>
    </>
  );
};
