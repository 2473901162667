import { createContext } from 'react';
import { type TenantInfo } from 'shared/types';

interface ITenantContext {
  tenant: TenantInfo | null;
  setTenant: (arg0: TenantInfo | null) => void;
}

export const TenantContext = createContext<ITenantContext>({
  tenant: null,
  setTenant: () => { }
});
