import { useContext } from 'react';
import { type NavigateFunction } from 'react-router-dom';
import { StableNavigateContext } from 'shared/contexts';

export const useStableNavigate = (): NavigateFunction => {
  const navigateRef = useContext(StableNavigateContext);
  if (navigateRef?.current === null) { throw new Error('StableNavigate context is not initialized'); }

  return navigateRef?.current || (() => { }) as NavigateFunction;
};
