import { Box, Button, Typography } from '@mui/material';
import { type FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePalette } from 'shared/hooks';

export const CookiesControlTool: FC = () => {
  const intl = useIntl();
  const { secondary } = usePalette();
  const [isCookiesToolVisible, setIsCookiesToolVisible] = useState(false);

  const cookiesHandler = useCallback((isAccepted: boolean) => {
    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    document.cookie = `cookiesIsAccepted=${isAccepted};${expires};path=/`;
    setIsCookiesToolVisible(false);
  }, []);

  const getCookie = useCallback((cname: string) => {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }, []);

  useEffect(() => {
    const isCookiesAccepted = getCookie('cookiesIsAccepted');
    if (isCookiesAccepted === '') {
      setIsCookiesToolVisible(true);
    } else {
      setIsCookiesToolVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: isCookiesToolVisible ? 'block' : 'none',
        position: 'absolute',
        bottom: 0,
        zIndex: 5,
        '@media only screen and (max-width: 1434px)': {
          maxWidth: 'calc(100vw)',
          overflowX: 'scroll'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: `1px solid ${secondary[400]}`,
          borderRadius: '16px',
          margin: '0 207px 0 144px',
          padding: '48px 48px 36px',
          background: '#FFFFFF',
          '@media only screen and (max-width: 1434px)': {
            margin: '0 51px',
            minWidth: '666px'
          },
          '@media only screen and (max-width: 479px)': {
            margin: '0 18px',
            minWidth: 'auto',
            padding: '24px 24px 20px'
          }
        }}
      >
        <Typography color='primary.main' variant='subtitle1' mb={3}>
          {intl.formatMessage({ id: 'this_website_stores_cookies_on_your_computer' })}
        </Typography>
        <Box
          display='flex'
          gap={4}
          mb={6}
          sx={{
            flexDirection: 'row',
            '@media only screen and (max-width: 1434px)': {
              flexDirection: 'column'
            }
          }}
        >
          <Typography color='secondary.700' variant='body1' fontSize='14px' lineHeight='16px'>
            {intl.formatMessage({ id: 'these_cookies_are_used_to_collect_information' })}
          </Typography>
          <Typography color='secondary.700' variant='body1' fontSize='14px' lineHeight='16px'>
            {intl.formatMessage({ id: 'if_you_decline' })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
            '@media only screen and (max-width: 479px)': {
              flexDirection: 'column'
            }
          }}
        >
          <Button
            variant='outlined'
            onClick={() => {
              cookiesHandler(false);
            }}
          >
            {intl.formatMessage({ id: 'decline' })}
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              cookiesHandler(true);
            }}
          >
            {intl.formatMessage({ id: 'accept' })}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
