import { useMutation } from '@apollo/client';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Loader } from 'shared/components';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { type SetCompanyLinks, type SetCompanyLinksVariables } from './__graphql__/SetCompanyLinks';
import { setCompanyLinksMutation } from './api';

interface Props {
  faqLink: string | null;
}

export const FAQForm: FC<Props> = ({ faqLink }) => {
  const [link, setLink] = useState(faqLink);
  const { formatMessage } = useIntl();
  const [setCompanyLinks, { loading }] = useMutation<SetCompanyLinks, SetCompanyLinksVariables>(
    setCompanyLinksMutation,
    { refetchQueries: ['CompanyFAQLink'], awaitRefetchQueries: true }
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={{ flex: '0.5' }}>
        <Typography
          variant='h4'
          color='secondary.700'
          sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 600, mb: '12px' }}
        >
          {formatMessage({ id: 'faq_link' })}
        </Typography>
        <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: 'secondary.700' }}>
          {formatMessage({ id: 'enter_faq' })}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px', flex: '0.5' }}>
        <TextField
          label={formatMessage({ id: 'faq_link' })}
          value={link}
          onChange={({ target }) => {
            setLink(target.value);
          }}
        />
        <Button
          variant='contained'
          onClick={async () => {
            await setCompanyLinks({ variables: { data: { faqLink: link } } });
            toast(<SuccessSnackbar title={formatMessage({ id: 'faq_updated' })} />);
          }}
        >
          {formatMessage({ id: 'save' })}
        </Button>
      </Box>
    </>
  );
};
