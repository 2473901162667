import { Box } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`notes-tabpanel-${index}`}
      aria-labelledby={`notes-tab-${index}`}
      sx={{
        '& > *': {
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};
