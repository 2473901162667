import { Box, IconButton, Typography } from '@mui/material';
import { Field, Form, Formik, type FormikConfig } from 'formik';
import { useContext, useEffect, useState, type FC } from 'react';
import { Input, Tooltip } from 'shared/components';
import { CheckmarkIcon, CrossIcon, PencilIcon } from 'shared/components/icons';
import { FormBuilderContext } from 'shared/contexts';
import { usePalette } from 'shared/hooks';

interface Props {
  title: string;
  value: Record<string, any>;
  tooltip: string | JSX.Element;
  readOnly?: boolean;
  placeholder?: string;
  onSubmit?: FormikConfig<any>['onSubmit'];
  onCancel?: () => void;
  EditComponent?: JSX.Element;
  ViewComponent?: JSX.Element;
  children?: JSX.Element;
  isMainProperty?: boolean;
  loading?: boolean;
  validate?: (value: unknown) => Promise<string | void>;
}

export const PropertyBlock: FC<Props> = ({
  title,
  tooltip,
  value = {},
  readOnly,
  placeholder,
  onSubmit,
  onCancel,
  EditComponent,
  ViewComponent,
  isMainProperty,
  loading,
  validate
}) => {
  const [initLoading, setInitLoading] = useState(false);
  const { secondary, primary } = usePalette();
  const [isEdit, setIsEdit] = useState(false);
  const { section, component, editMainProperty, setEditMainProperty } = useContext(FormBuilderContext);

  useEffect(() => {
    setIsEdit(isMainProperty ? editMainProperty : false);
  }, [section?.id, component?.id, editMainProperty, isMainProperty]);

  const field = Object.keys(value)[0];

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={value}
      onSubmit={async (values, helpers) => {
        setInitLoading(true);
        const error = await onSubmit?.(values, helpers);
        if (!error) {
          setIsEdit(false);
        }
        setInitLoading(false);
      }}
    >
      {({ resetForm }) => (
        <Form>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexDirection: isEdit ? 'column' : 'row',
              gap: isEdit ? '12px' : '0px',
              background: 'secondary.50',
              borderRadius: '8px',
              p: '16px',
              border: `1px solid ${secondary[200]}`
            }}
          >
            {isEdit ? (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant='body1' sx={{ color: 'secondary.600' }}>
                    {title}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '4px' }}>
                    <IconButton type='submit' disabled={loading || initLoading}>
                      <CheckmarkIcon />
                    </IconButton>
                    <IconButton
                      disabled={loading || initLoading}
                      onClick={() => {
                        setIsEdit(false);
                        setEditMainProperty(false);
                        onCancel?.();
                        resetForm();
                      }}
                    >
                      <CrossIcon color={primary[600]} />
                    </IconButton>
                  </Box>
                </Box>
                {EditComponent || (
                  <Field validate={validate} name={field} label={title} component={Input} disabled={loading} />
                )}
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant='body1' sx={{ color: 'secondary.600' }}>
                    {title}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '4px' }}>
                    {!readOnly && (
                      <IconButton
                        onClick={() => {
                          setIsEdit(true);
                        }}
                      >
                        <PencilIcon />
                      </IconButton>
                    )}
                    <Tooltip text={tooltip} />
                  </Box>
                </Box>

                {ViewComponent || (
                  <Typography sx={{ wordBreak: 'break-word', color: value[field] ? 'secondary.700' : 'secondary.400' }}>
                    {value[field] || placeholder}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
