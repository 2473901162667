import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const AnalyticsIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { primary } = usePalette();
  const currentColor = color ?? primary[600];

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V7'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9992 2V8H19.9992M4.03924 11.71C3.44226 12.2357 2.95895 12.8777 2.61896 13.5968C2.27897 14.3159 2.08946 15.0969 2.06206 15.8919C2.03466 16.6868 2.16994 17.479 2.45962 18.2198C2.74929 18.9606 3.18724 19.6344 3.74661 20.1999C4.30599 20.7655 4.97499 21.2107 5.71259 21.5085C6.45019 21.8062 7.24084 21.9501 8.03605 21.9314C8.83125 21.9127 9.61425 21.7317 10.337 21.3996C11.0598 21.0675 11.7071 20.5912 12.2392 20'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.83 15.9999C13.83 14.4537 13.2158 12.9708 12.1224 11.8775C11.0291 10.7842 9.54621 10.1699 8 10.1699V15.9999H13.83Z'
        stroke={currentColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
