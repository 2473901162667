import { useMutation } from '@apollo/client';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Field, FieldArray, Form, Formik, type FormikProps } from 'formik';
import { dissoc, map } from 'ramda';
import { type FC, type MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { Input, Loader } from 'shared/components';
import { FacebookIcon, LinkedinIcon, MailIcon, TrashIcon, TwitterIcon } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { usePalette } from 'shared/hooks';
import { type SetCompanyLinks, type SetCompanyLinksVariables } from './__graphql__/SetCompanyLinks';
import { setCompanyLinksMutation } from './api';

type Links = Pick<
  CompanyInfo_companyInfo,
  'footerLinks' | 'emailLink' | 'facebookLink' | 'linkedInLink' | 'twitterLink'
>;

interface Props {
  links: Links;
}

const LinksForm: FC<FormikProps<Props['links']>> = (props) => {
  const { values, handleSubmit, setFieldError, setFieldValue, setFieldTouched } = props;
  const { formatMessage } = useIntl();
  const { secondary } = usePalette();

  const validateOnSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    let terminateSubmit = false;

    values.footerLinks.forEach(({ name, link }, index) => {
      if ((name && !link) || (!name && link)) {
        terminateSubmit = true;
        const pathToField = `footerLinks.${index}.${name ? 'link' : 'name'}`;
        setFieldTouched(pathToField, true);
        setFieldError(pathToField, formatMessage({ id: name ? 'link_without_url' : 'link_without_name' }));
      }
    });

    if (terminateSubmit) {
      return;
    }

    handleSubmit();
  };

  return (
    <Form {...props}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', pb: '20px' }}>
        <Box sx={{ flex: '0.5' }}>
          <Typography
            variant='h4'
            sx={{ fontSize: '20px', color: 'secondary.700', lineHeight: '28px', fontWeight: 600, mb: '12px' }}
          >
            {formatMessage({ id: 'footer' })}
          </Typography>
          <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: 'secondary.700' }}>
            {formatMessage({ id: 'enter_links_for_footer' })}
          </Typography>
        </Box>
        <Button variant='contained' onClick={validateOnSubmit}>
          {formatMessage({ id: 'save' })}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '45px' }}>
        <Box sx={{ flex: '0.6' }}>
          <Typography variant='h5' color='secondary.700' sx={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>
            {formatMessage({ id: 'footer_links' })}
          </Typography>
          <FieldArray
            name='footerLinks'
            render={() =>
              values.footerLinks.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', gap: '12px' }}>
                  <Field
                    name={`footerLinks.${index}.name`}
                    label={formatMessage({ id: 'link_name' })}
                    placeholder={formatMessage({ id: 'link_name' })}
                    component={Input}
                    sx={{ mt: '16px' }}
                  />
                  <Field
                    name={`footerLinks.${index}.link`}
                    label={formatMessage({ id: 'link_address' })}
                    placeholder={formatMessage({ id: 'link_address' })}
                    component={Input}
                    sx={{ mt: '16px' }}
                  />
                  <IconButton
                    sx={{ mt: '16px' }}
                    disabled={!item.name || !item.link}
                    onClick={() => {
                      setFieldValue(`footerLinks.${index}.name`, '');
                      setFieldValue(`footerLinks.${index}.link`, '');
                    }}
                  >
                    <TrashIcon color={secondary[700]} />
                  </IconButton>
                </Box>
              ))
            }
          />
        </Box>
        <Box sx={{ flex: '0.3' }}>
          <Typography variant='h5' color='secondary.700' sx={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>
            {formatMessage({ id: 'social_media' })}
          </Typography>
          <Field
            name='emailLink'
            label={formatMessage({ id: 'email' })}
            placeholder={formatMessage({ id: 'email' })}
            component={Input}
            sx={{ mt: '16px' }}
            InputProps={{ endAdornment: <MailIcon /> }}
          />
          <Field
            name='linkedInLink'
            label={formatMessage({ id: 'linkedIn' })}
            placeholder={formatMessage({ id: 'linkedIn' })}
            component={Input}
            sx={{ mt: '16px' }}
            InputProps={{ endAdornment: <LinkedinIcon /> }}
          />
          <Field
            name='facebookLink'
            label={formatMessage({ id: 'facebook' })}
            placeholder={formatMessage({ id: 'facebook' })}
            component={Input}
            sx={{ mt: '16px' }}
            InputProps={{ endAdornment: <FacebookIcon /> }}
          />
          <Field
            name='twitterLink'
            label={formatMessage({ id: 'twitter' })}
            placeholder={formatMessage({ id: 'twitter' })}
            component={Input}
            sx={{ mt: '16px' }}
            InputProps={{ endAdornment: <TwitterIcon /> }}
          />
        </Box>
      </Box>
    </Form>
  );
};

export const FooterLinksForm: FC<Props> = ({ links }) => {
  const { formatMessage } = useIntl();
  const { footerLinks, emailLink, linkedInLink, facebookLink, twitterLink } = links;
  const customLinks = [
    ...map(dissoc('__typename'), footerLinks ?? []),
    ...Array(4 - (footerLinks?.length ?? 0)).fill({ name: '', link: '' })
  ];

  const [setLinks, { loading }] = useMutation<SetCompanyLinks, SetCompanyLinksVariables>(setCompanyLinksMutation);

  return (
    <Formik
      enableReinitialize
      onSubmit={async (values) => {
        await setLinks({
          variables: {
            data: {
              ...values,
              footerLinks: values.footerLinks?.filter(({ name, link }) => name && link)
            }
          }
        });
        toast(<SuccessSnackbar title={formatMessage({ id: 'footer_updated' })} />);
      }}
      initialValues={{ footerLinks: customLinks, emailLink, linkedInLink, facebookLink, twitterLink } as Links}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => (loading ? <Loader /> : <LinksForm {...props} />)}
    </Formik>
  );
};
