import gql from 'graphql-tag';

export const tasksQuery = gql`
  query Tasks($filter: TaskFilter!, $orderBy: TaskOrder!, $pagination: PaginationArgs!) {
    tasks(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
      data {
        id
        name
        createdBy
        staffUserId
        staffUser {
          firstName
          lastName
        }
        contractorId
        contractor {
          firstName
          lastName
        }
        description
        startDate
        dueDate
        done
      }
      total
    }
  }
`;

export const updateTaskMutation = gql`
  mutation UpdateTask($data: UpdateTaskData!) {
    updateTask(data: $data) {
      id
    }
  }
`;

export const createTaskMutation = gql`
  mutation CreateTask($data: CreateTaskData!) {
    createTask(data: $data) {
      id
    }
  }
`;

export const deleteTaskMutation = gql`
  mutation DeleteTask($id: String!) {
    deleteTask(id: $id) {
      id
    }
  }
`;
