import { FormikContext, type FormikContextType } from 'formik';
import { useCallback, useContext, useEffect, useState, type FC } from 'react';
import { useIntl } from 'react-intl';
import { ACCEPT_FILES } from 'shared/constants/file';
import { RegistrationContext } from 'shared/contexts/registrationContext';
import { type AttachedDocument, type FileFormat } from 'shared/types';
import { CustomDropzone } from '../CustomDropzone';
import { type FormComponentProps } from './types';

export const FileUploadComponent: FC<FormComponentProps> = (props) => {
  const { id, required, text, options, additionalText, section } = props;

  const formik = (useContext(FormikContext) ?? {}) as FormikContextType<Record<string, string>>;
  const { documents, setInvalidSections, invalidSections } = useContext(RegistrationContext);
  const { formatMessage } = useIntl();
  const [error, setError] = useState<string>();

  const docTypes = options.reduce<FileFormat[]>(
    (result, { label, value }) => (value === 'true' ? [...result, label] : result) as FileFormat[],
    []
  );

  const { isValidating, values, setFieldValue } = formik;

  useEffect(() => {
    const shouldBeRequired = required && !values?.[id]?.length;
    const newErrorValue = shouldBeRequired ? 'required' : '';

    if ((error === undefined && values?.[id]) || isValidating) {
      setError(newErrorValue);
      setInvalidSections((prev) => (newErrorValue ? [...prev, section?.id!] : prev.filter((id) => id !== section?.id)));
    } else if (error && !invalidSections.includes(section?.id as string)) {
      setInvalidSections((prev) => [...prev, section?.id!]);
    }
  }, [error, formatMessage, id, invalidSections, isValidating, required, section?.id, setInvalidSections, values]);

  useEffect(() => {
    return () => setInvalidSections((prev) => prev.filter((id) => id !== section?.id));
  }, [section?.id, setInvalidSections]);

  const onDrop = useCallback(
    (_: string, value: Array<string | AttachedDocument>) => {
      const withFiles = (value as AttachedDocument[]).filter(({ file }) => file);

      const uploadedTypes = withFiles.map(({ file }) => file?.type);
      const acceptedTypes = docTypes.flatMap((t) => Object.keys(ACCEPT_FILES[t]));

      if (uploadedTypes.some((type) => !acceptedTypes.includes(type))) {
        setError('wrong_file_format');
        setInvalidSections((prev) => [...prev, section?.id!]);
      } else if (value.some((v) => ((v as AttachedDocument).file?.size ?? 0) / 1024 / 1024 > 5)) {
        setError('file_is_too_big');
        setInvalidSections((prev) => [...prev, section?.id!]);
      } else if ((!value.length || !values?.[id]) && required) {
        setError('required');
        setInvalidSections((prev) => [...prev, section?.id!]);
      } else {
        setFieldValue(id, value);
        setError('');
        setInvalidSections((prev) => prev.filter((id) => id !== section?.id));
      }
    },
    [docTypes, id, required, section?.id, setFieldValue, setInvalidSections, values]
  );

  const onRemove = useCallback(
    (files: Array<string | AttachedDocument>) => {
      setFieldValue(id, files, true);
      const error = !files.length && required ? 'required' : '';
      setError(error);
      if (error) {
        setInvalidSections((prev) => [...prev, section?.id!]);
      } else {
        setInvalidSections((prev) => prev.filter((id) => id !== section?.id));
      }
    },
    [id, required, section?.id, setFieldValue, setInvalidSections]
  );

  const questionDocuments = documents.filter((d) => values[id]?.includes(d.id));

  return (
    <CustomDropzone
      {...props}
      documents={questionDocuments}
      name={id}
      docTypes={docTypes}
      additionalText={additionalText ?? ''}
      title={text!}
      value={formik.values?.[id] || []}
      error={error}
      onChange={onDrop}
      onRemove={onRemove}
    />
  );
};
