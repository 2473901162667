import { Button, Grid, IconButton, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';

import { toast } from 'react-toastify';
import { Input } from 'shared/components';
import { EyeIcon } from 'shared/components/icons';
import SuccessSnackbar from 'shared/components/Snackbars/SuccessSnackbar';
import routes from 'shared/constants/routes';
import AuthContext from 'shared/contexts/authContext';
import { type ResetPasswordParams } from 'shared/types/auth';
import { FORBIDDEN_ERROR } from 'shared/utils/errors';
import { Validator } from 'shared/utils/validator';

export const ResetPassword = (): JSX.Element => {
  const intl = useIntl();

  const { resetPassword } = useContext(AuthContext);
  const { token } = useParams();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const mapErrorToExplanation = useCallback(
    (error: string) => {
      switch (error) {
        case FORBIDDEN_ERROR:
          return intl.formatMessage({ id: 'token_expired_try_again' });
        default:
          return '';
      }
    },
    [intl]
  );

  useEffect(() => {
    if (resetPassword.result) {
      toast(
        <SuccessSnackbar
          title={intl.formatMessage({ id: 'new_password_successfully_created' })}
          message={intl.formatMessage({ id: 'now_you_can_use_it_to_login' })}
        />
      );
    }
  }, [intl, resetPassword.result]);

  return (
    <Formik
      initialValues={{
        password: '',
        token: '',
        passwordRepeat: ''
      }}
      onSubmit={async (formValues: ResetPasswordParams & { passwordRepeat: string }) => {
        if (token) {
          const success = await resetPassword?.execute({
            token,
            password: formValues.password
          });
          if (success) {
            setTimeout(() => {
              navigate(routes.login);
            }, 2000);
          }
        }
      }}
    >
      {({ values, errors }) => (
        <Form>
          <Grid container justifyContent='center'>
            <Grid item width='320px' mr={{ xs: 0 }}>
              <Typography variant='h1' color='secondary.800' mb={2}>
                {intl.formatMessage({ id: 'new_password' })}
              </Typography>
              <Typography mb={2} variant='body1' color='secondary.800'>
                {intl.formatMessage({ id: 'please_enter_password' })}
              </Typography>
              {resetPassword.error && (
                <Typography
                  variant='body1'
                  color='error'
                  sx={{
                    marginBottom: '16px'
                  }}
                >
                  {mapErrorToExplanation(resetPassword.error as unknown as string)}
                </Typography>
              )}
              <Field
                id='password'
                name='password'
                type={isPasswordVisible ? 'text' : 'password'}
                component={Input}
                label={intl.formatMessage({ id: 'new_password' })}
                sx={{
                  marginTop: '8px',
                  marginBottom: '12px'
                }}
                validate={Validator.pipe(Validator.methods.required(), Validator.methods.password())}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ color: 'secondary.600' }}
                      onClick={() => {
                        setIsPasswordVisible((pr) => !pr);
                      }}
                    >
                      <EyeIcon variant={isPasswordVisible ? 'closed' : 'opened'} />
                    </IconButton>
                  )
                }}
                error={
                  errors.passwordRepeat === intl.formatMessage({ id: 'passwords_does_not_match' })
                    ? ' '
                    : errors.password
                }
              />
              <Field
                id='passwordRepeat'
                name='passwordRepeat'
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                component={Input}
                label={intl.formatMessage({ id: 'confirm_password' })}
                sx={{
                  marginBottom: '16px'
                }}
                validate={(value: string) => {
                  if (value !== values.password) return intl.formatMessage({ id: 'passwords_does_not_match' });
                  return '';
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ color: 'secondary.600' }}
                      onClick={() => {
                        setIsConfirmPasswordVisible((pr) => !pr);
                      }}
                    >
                      <EyeIcon variant={isConfirmPasswordVisible ? 'closed' : 'opened'} />
                    </IconButton>
                  )
                }}
              />
              <Button type='submit' size='large' variant='contained'>
                {intl.formatMessage({ id: 'save_password' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
