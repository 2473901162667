import { FC } from 'react';
import { usePalette } from 'shared/hooks';
import { IconProps } from './props';

export const ConnectionsIcon: FC<IconProps> = (props) => {
  const { color } = props;
  const { warning } = usePalette();
  const currentColor = color ?? warning[200];

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M5.75 16V12H19.75V16M12.75 12V8M9.75 2H15.75V8H9.75V2ZM16.75 16H22.75V22H16.75V16ZM2.75 16H8.75V22H2.75V16Z'
        stroke={currentColor}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
