import { useMutation } from '@apollo/client';
import { Box, TextField, Typography } from '@mui/material';
import { type TenantStatus } from '__graphql__/globalTypes';
import { useState, type FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import { ConfirmationDialog } from 'shared/components';
import { TriangleListMarker } from 'shared/components/icons';
import { SuccessSnackbar } from 'shared/components/Snackbars';
import { type ActivateTenant, type ActivateTenantVariables } from './__graphql__/ActivateTenant';
import { type DeactivateTenant, type DeactivateTenantVariables } from './__graphql__/DeactivateTenant';
import { type DeleteTenant, type DeleteTenantVariables } from './__graphql__/DeleteTenant';
import { activateTenantMutation, deactivateTenantMutation, deleteTenantMutation } from './api';

export type TenantAction = 'delete' | 'activate' | 'deactivate';

interface Props {
  action: TenantAction;
  tenant: {
    id: string;
    name: string;
    status: TenantStatus;
  };
  onClose: () => void;
}

export const TenantActionDialog: FC<Props> = ({ action, tenant, onClose }) => {
  const { formatMessage } = useIntl();
  const [deletionSubmit, setDeletionSubmit] = useState<{ value?: string; error?: string }>();

  const [activateTenant] = useMutation<ActivateTenant, ActivateTenantVariables>(activateTenantMutation, {
    refetchQueries: ['Tenants', 'CompanyInfo'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.SUPERADMIN
  });

  const [deactivateTenant] = useMutation<DeactivateTenant, DeactivateTenantVariables>(deactivateTenantMutation, {
    refetchQueries: ['Tenants', 'CompanyInfo'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.SUPERADMIN
  });

  const [deleteTenant] = useMutation<DeleteTenant, DeleteTenantVariables>(deleteTenantMutation, {
    refetchQueries: ['Tenants'],
    awaitRefetchQueries: true,
    context: API_CONTEXT.SUPERADMIN
  });

  const isDelete = action === 'delete';
  const isDeactivate = action === 'deactivate';

  const actionTenantHandler = useAsyncCallback(async () => {
    if (isDelete) {
      if (deletionSubmit?.value?.trim() === tenant.name) {
        await deleteTenant({ variables: { id: tenant.id } });
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: 'the_tenant_successfully_deleted' })}
            message={formatMessage({ id: 'tenant_data_was_successfully_removed' })}
          />
        );
        setDeletionSubmit(undefined);
      } else {
        setDeletionSubmit((prev) => ({
          ...prev,
          error: formatMessage({
            id: !deletionSubmit?.value?.trim().length
              ? 'please_fill_this_input'
              : 'tenant_name_in_the_input_doesnt_match'
          })
        }));
        return;
      }
    } else {
      if (isDeactivate) {
        await deactivateTenant({ variables: { id: tenant.id } });
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: 'the_tenant_successfully_deactivated' })}
            message={formatMessage({ id: 'tenenant_status_changed_deactivated' })}
          />
        );
      } else {
        await activateTenant({ variables: { id: tenant.id } });
        toast(
          <SuccessSnackbar
            title={formatMessage({ id: 'the_tenant_successfully_activated' })}
            message={formatMessage({ id: 'tenenant_status_changed_activated' })}
          />
        );
      }
    }
    onClose();
  });

  return (
    <ConfirmationDialog
      open={!!action}
      onClose={onClose}
      onConfirm={actionTenantHandler.execute}
      confirmButtonContent={formatMessage({
        id: isDeactivate ? 'deactivate' : isDelete ? 'delete' : 'activate'
      })}
      title={formatMessage({
        id: isDeactivate ? 'tenant_deactivation' : isDelete ? 'delete_tenant' : 'tenant_activation'
      })}
      content={
        <Typography variant='body2' fontWeight={400} color='secondary.800'>
          <Typography mb={4}>
            {formatMessage(
              {
                id: isDeactivate
                  ? 'tenant_deactivation_title'
                  : isDelete
                    ? 'tenant_deletion_title'
                    : 'tenant_activation_title'
              },
              { tenant: tenant?.name }
            )}
          </Typography>
          <Box display='flex' flexDirection='column' gap={2}>
            {(isDeactivate
              ? [
                formatMessage({ id: 'tenant_deactivation_points.0' }),
                formatMessage({ id: 'tenant_deactivation_points.1' }),
                formatMessage({ id: 'tenant_deactivation_points.2' })
              ]
              : isDelete
                ? [
                  formatMessage({ id: 'tenant_deletion_points.0' }),
                  formatMessage({ id: 'tenant_deletion_points.1' }),
                  formatMessage({ id: 'tenant_deletion_points.2' }),
                  formatMessage({ id: 'tenant_deletion_points.3' })
                ]
                : [
                  formatMessage({ id: 'tenant_activation_points.0' }),
                  formatMessage({ id: 'tenant_activation_points.1' }),
                  formatMessage({ id: 'tenant_activation_points.2' })
                ]
            ).map((prompt, index) => (
              <Box key={index} display='flex' gap={2}>
                <Box><TriangleListMarker /></Box>
                <Typography>{prompt}</Typography>
              </Box>
            ))}
          </Box>
          {isDelete && (
            <>
              <Typography my={4}>{formatMessage({ id: 'to_confirm_the_removal' })}</Typography>
              <TextField
                label={formatMessage({ id: 'tenant_name' })}
                value={deletionSubmit?.value}
                onChange={(e: any) => {
                  setDeletionSubmit({ value: e.target.value, error: undefined });
                }}
                error={!!deletionSubmit?.error}
                helperText={deletionSubmit?.error}
              />
            </>
          )}
        </Typography>
      }
    />
  );
};
