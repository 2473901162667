import { SubscriptionStatus } from '__graphql__/globalTypes';
import { type CompanyAccess_companyInfo } from 'shared/api/__graphql__/CompanyAccess';
import { type CompanyInfo_companyInfo } from 'shared/api/__graphql__/CompanyInfo';
import { type CompanySubscriptions } from 'shared/api/__graphql__/CompanySubscriptions';
import { type Discount_discount } from 'shared/api/__graphql__/Discount';
import { type Products_products } from 'shared/api/__graphql__/Products';
import { MILISECONDS_IN_SECOND, TRIAL_PLAN_ID } from 'shared/constants';
import { getCardNumber, getExpiringDate, getTruncatedNumber } from './formatters';

export interface ICurrentPlan {
  productId?: string;
  name: string;
  startDate?: Date | null;
  endDate?: Date | null;
  defaultPrice: number;
  additionalPrice: number;
  description?: string;
  cardNumber?: string;
  cardExpirationDate?: string;
  numberOfRegistrations?: number;
  changedAt?: string | null;
  changedFrom?: string | null;
  cancelAtPeriodEnd?: boolean;
  discount?: Discount_discount | null;
}

export const getCurrentPlan = ({
  companySubscriptions,
  products,
  companyInfo,
  formatMessage,
  discount
}: {
  companySubscriptions?: CompanySubscriptions;
  products?: Products_products[];
  companyInfo?: CompanyAccess_companyInfo | CompanyInfo_companyInfo;
  discount?: Discount_discount | null;
  formatMessage: any;
}): ICurrentPlan => {
  const activeSubscription = companySubscriptions?.subscriptions.find((s) =>
    [SubscriptionStatus.active, SubscriptionStatus.past_due].includes(s.status)
  );
  if (activeSubscription && products?.length) {
    const activeProductIndex = products
      .sort((a, b) => b.prices[0].amount - a.prices[0].amount)
      .findIndex((p) => p.id === activeSubscription.productId);

    const activeProduct = products[activeProductIndex];
    const { defaultPrice, additionalPrice } = activeProduct?.prices?.reduce(
      (res, current) =>
        current.default ? { ...res, defaultPrice: current.amount / 100 } : { ...res, additionalPrice: current.amount },
      { defaultPrice: 0, additionalPrice: 0 }
    ) ?? { defaultPrice: 0, additionalPrice: 0 };

    return {
      productId: activeProduct?.id,
      name: activeProduct?.name ?? 'no_plan_selected',
      defaultPrice,
      additionalPrice,
      numberOfRegistrations: activeProduct.prices.find((p) => !p.default)?.startsFrom ?? 0,
      cardNumber: getCardNumber(activeSubscription.paymentMethod.cardLast4Digits),
      cardExpirationDate: getExpiringDate(activeSubscription.paymentMethod),
      description: activeProduct?.description,
      startDate: new Date(activeSubscription.current_period_start * MILISECONDS_IN_SECOND),
      endDate: new Date(activeSubscription.current_period_end * MILISECONDS_IN_SECOND),
      changedAt: activeSubscription.changedAt,
      changedFrom: activeSubscription.changedFrom,
      cancelAtPeriodEnd: activeSubscription.cancelAtPeriodEnd,
      discount
    };
  }
  if (companyInfo?.trialStartDate) {
    return {
      productId: TRIAL_PLAN_ID,
      name: formatMessage({ id: 'trial_plan' }),
      defaultPrice: 0,
      additionalPrice: 0,
      description: formatMessage({ id: 'trial_plan_description' }),
      startDate: companyInfo.trialStartDate,
      endDate: companyInfo.trialEndDate
    };
  }
  return {
    name: formatMessage({ id: 'no_plan_selected' }),
    defaultPrice: 0,
    additionalPrice: 0,
    description: formatMessage({ id: 'no_plan_description' })
  };
};

export const getDiscountedPrice = (discount?: number | null, price?: number | null): number | null => {
  if (!discount || !price) {
    return null;
  }
  return getTruncatedNumber(price - price * (discount / 100));
};
