import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { CrossIcon } from 'shared/components/icons';
import { type Forms_forms_data } from '../__graphql__/Forms';
import { Form, FormWidgetContent } from './FormWidgetContent';

interface Props {
  closeModal: () => void;
  form: Forms_forms_data;
}

export const EditFormDialog: FC<Props> = ({ closeModal, form }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      fullWidth
      open={true}
      onClose={closeModal}
      maxWidth='sm'
      sx={{ '& .MuiPaper-root': { borderRadius: '16px', position: 'initial' } }}
    >
      <DialogTitle sx={{ padding: '24px 24px 16px' }}>
        <Typography variant='h5' color='secondary.800'>
          {formatMessage({ id: 'edit_form' })}
        </Typography>
        <IconButton onClick={closeModal}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <FormWidgetContent
        mode='edit'
        initialValues={{ id: form.id, name: form.name, description: form.description ?? '' }}
        closeModal={closeModal}
      >
        <Form />
      </FormWidgetContent>
    </Dialog>
  );
};
