import { Box, Button, ClickAwayListener, IconButton, Tab, Tabs } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { API_CONTEXT } from 'shared/api/api-contexts';
import apolloClient from 'shared/apolloClient';
import { EmptySearchBox } from 'shared/components';
import routes from 'shared/constants/routes';
import { CompanyContext, SearchContext } from 'shared/contexts';
import { useCustomStyles, usePalette, useTenantRoute } from 'shared/hooks';
import { BaseInput } from '../form/InputComponent';
import { ChevronIcon, CrossIcon, MagnifyingGlassIcon } from '../icons';
import { AttentionSnackbar } from '../Snackbars';
import { type ContractorsMatch, type ContractorsMatchVariables } from './__graphql__/ContractorsMatch';
import { type RegistrationsMatch, type RegistrationsMatchVariables } from './__graphql__/RegistrationsMatch';
import { contractorsMatchQuery, registrationsMatchQuery } from './api';

interface ResultProps {
  data?: string[] | null;
  onOptionClick: (match: string) => void;
  onViewAllClick: () => void;
  hasSimilarResults?: boolean;
}

enum SearchTabs {
  contractors = 'contractors',
  registrations = 'registrations'
}

const Results = ({ data, onOptionClick, onViewAllClick, hasSimilarResults = false }: ResultProps): JSX.Element => {
  const intl = useIntl();
  const { secondary } = usePalette();
  const { search } = useContext(SearchContext);
  const customStyles = useCustomStyles();

  return (
    <>
      {data?.length ? (
        <>
          {data.map((match, index) => (
            <Button
              key={`${match}${index}`}
              variant='text'
              sx={{
                padding: '8px 16px',
                fontWeight: 400,
                color: secondary[700]
              }}
              onClick={() => {
                onOptionClick(match);
              }}
            >
              <Highlighter
                autoEscape
                highlightStyle={customStyles.highlighter}
                searchWords={[`${search}`]}
                textToHighlight={match}
              />
            </Button>
          ))}
          <Button
            variant='text'
            sx={{
              padding: '8px 16px',
              justifyContent: 'space-between'
            }}
            onClick={onViewAllClick}
          >
            {intl.formatMessage({ id: 'view_all_results' })}
            <ChevronIcon direction='right' />
          </Button>
        </>
      ) : (
        <>
          <EmptySearchBox message={hasSimilarResults ? 'no_100_matches_found' : undefined} />
          {hasSimilarResults && (
            <Button
              variant='text'
              sx={{
                padding: '8px 16px',
                justifyContent: 'space-between'
              }}
              onClick={onViewAllClick}
            >
              {intl.formatMessage({ id: 'view_similar_results' })}
              <ChevronIcon direction='right' />
            </Button>
          )}
        </>
      )}
    </>
  );
};

export const GlobalSearch = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { primary, info, secondary } = usePalette();
  const { search, setSearch } = useContext(SearchContext);
  const { paymentFailureCode } = useContext(CompanyContext);
  const { mainRoute, navigateWithTenant } = useTenantRoute();
  const [tab, setTab] = useState<SearchTabs>(SearchTabs.contractors);
  const location = useLocation();

  const pageToNavigate = useMemo(() => {
    if (tab === SearchTabs.registrations) return routes.root;
    return routes.contractors;
  }, [tab]);

  const pageToNavigateAfterClear = useMemo(() => {
    if (mainRoute === routes.contractors) return routes.contractors;
    if (mainRoute === routes.root) return routes.root;
    return pageToNavigate;
  }, [pageToNavigate, mainRoute]);

  const [isOpen, setIsOpen] = useState(false);

  const [hasSimilarResults, setHasSimilarResults] = useState<Record<'registrations' | 'contractors', boolean> | null>(
    null
  );
  const [matches, setMatches] = useState<{ registrations: string[]; contractors: string[] } | null>(null);
  const [totalCounts, setTotalCounts] = useState<{ registration: number; contractors: number } | null>(null);

  const clearSearch = useCallback(() => {
    setSearch('');
    setMatches(null);
  }, [setSearch]);

  const clearTab = useCallback(() => {
    setIsOpen(false);
    setTab(SearchTabs.contractors);
  }, []);

  useEffect(() => {
    const isContractors = location.pathname === `/${routes.contractors}`;
    const isDashboard = location.pathname === routes.rootForNavigation;
    const isTenantContractors = /^\/tenant\/[^/]+\/contractors$/.test(location.pathname);
    const isTenantDashboard = /^\/tenant\/[^/]+\/$/.test(location.pathname);

    if (!isContractors && !isDashboard && !isTenantContractors && !isTenantDashboard) {
      clearSearch();
      clearTab();
    }
  }, [clearSearch, clearTab, location.pathname]);

  const onSearchChangeHandler = useCallback(
    async (query: string) => {
      setSearch(query);

      if (query.length > 2) {
        const [registrationsResult, contractorsResult] = await Promise.all([
          apolloClient.query<RegistrationsMatch, RegistrationsMatchVariables>({
            query: registrationsMatchQuery,
            variables: {
              offset: 0,
              limit: 5,
              query: query.trim().toLowerCase()
            }
          }),
          apolloClient.query<ContractorsMatch, ContractorsMatchVariables>({
            query: contractorsMatchQuery,
            variables: {
              args: {
                pagination: { offset: 0, limit: 5 },
                filters: {
                  search: query.trim().toLowerCase()
                }
              }
            },
            context: API_CONTEXT.AUTH
          })
        ]);
        const contractorsTotal = contractorsResult.data.contractors.total;
        const registrationsTotal = registrationsResult.data.registrations.total;
        setTotalCounts({
          registration: registrationsTotal,
          contractors: contractorsTotal
        });
        const registrationsMatches =
          [
            ...new Set(
              registrationsResult.data.registrations.data?.reduce((result, registration) => {
                const fields = [
                  registration.firstName,
                  registration.lastName,
                  `${registration.firstName} ${registration.lastName}`,
                  registration.email,
                  registration.phoneNumber
                ];
                const matchString = fields.find((v) =>
                  v ? v.toLowerCase().includes(query.trim().toLowerCase()) : false
                );
                if (matchString) result.push(matchString);

                return result;
              }, [] as string[])
            )
          ] ?? [];
        const contractorsMatches =
          [
            ...new Set(
              contractorsResult.data.contractors.data?.reduce((result, contractor) => {
                const fields = [
                  contractor.firstName,
                  contractor.lastName,
                  `${contractor.firstName} ${contractor.lastName}`,
                  contractor.email,
                  contractor.phoneNumber
                ];
                const matchString = fields.find((v) =>
                  v ? v.toLowerCase().includes(query.trim().toLowerCase()) : false
                );

                if (matchString) result.push(matchString);

                return result;
              }, [] as string[])
            )
          ] ?? [];

        setMatches({
          registrations: registrationsMatches,
          contractors: contractorsMatches
        });
        setHasSimilarResults({
          contractors: !contractorsMatches.length && !!contractorsTotal,
          registrations: !registrationsMatches.length && !!registrationsTotal
        });
        let tabToSet: SearchTabs | null = null;
        if (tab === SearchTabs.contractors && !contractorsTotal) {
          tabToSet = SearchTabs.registrations;
        }
        if (tab === SearchTabs.registrations && !registrationsTotal) {
          tabToSet = SearchTabs.contractors;
        }
        if (tabToSet) setTab(tabToSet);
        setIsOpen(true);
      } else clearTab();
    },
    [clearTab, setSearch, tab]
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
      }}
    >
      <>
        <BaseInput
          value={search}
          onChange={async (e: any) => {
            await onSearchChangeHandler(e.target.value);
          }}
          onClick={() => {
            if (paymentFailureCode) {
              toast(
                <AttentionSnackbar
                  title={formatMessage({ id: 'feature_unavailable' })}
                  message={formatMessage({ id: `feature_unavailable_${paymentFailureCode.toLowerCase()}` })}
                />
              );
              return;
            }
            if (search.length > 2) {
              if (tab === SearchTabs.contractors && location.pathname === routes.root) {
                setTab(SearchTabs.registrations);
              } else if (tab === SearchTabs.registrations && location.pathname === routes.contractors) {
                setTab(SearchTabs.contractors);
              }
              setIsOpen(true);
            }
          }}
          placeholder={formatMessage({ id: 'search' })}
          InputProps={{
            className: 'search',
            readOnly: !!paymentFailureCode,
            endAdornment: search ? (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSearch('');
                  clearTab();
                  clearSearch();
                  navigateWithTenant(pageToNavigateAfterClear, { state: { search: '' } });
                }}
              >
                <CrossIcon />
              </IconButton>
            ) : (
              <MagnifyingGlassIcon />
            )
          }}
        />
        {isOpen && (
          <Box
            display='flex'
            flexDirection='column'
            sx={{
              width: '318px',
              border: `1px solid ${primary[600]}`,
              borderRadius: '8px',
              position: 'absolute',
              background: '#FFFFFF',
              py: '4px',
              mt: '43px',
              zIndex: 2
            }}
          >
            <Tabs
              value={tab}
              onChange={(_, v) => {
                setTab(v);
              }}
              aria-label='search tabs'
              sx={{
                padding: '8px 12px',
                background: 'none',
                border: 'none',
                minHeight: '0',
                '& .MuiTab-root': {
                  padding: '4px 0',
                  border: `1px solid ${secondary[400]}`,
                  background: info[200],
                  fontSize: '16px',
                  lineHeight: '28px',
                  color: secondary[800],
                  width: '50%',
                  minHeight: '0',

                  '&:hover': {
                    background: info[200],
                    borderColor: secondary[400]
                  },

                  '&.Mui-selected': {
                    borderColor: primary[600],
                    background: primary[400],

                    '&:hover': {
                      background: primary[400],
                      borderColor: primary[600]
                    }
                  }
                }
              }}
            >
              <Tab
                label={`${formatMessage({ id: 'contractors' })} (${
                  matches?.contractors.length ? totalCounts?.contractors ?? 0 : 0
                })`}
                value={SearchTabs.contractors}
                aria-controls='contractors'
                sx={{
                  borderRadius: '8px 0px 0px 8px',
                  '&.Mui-selected': {
                    marginRight: '-1px',
                    zIndex: 2
                  }
                }}
              />
              <Tab
                label={`${formatMessage({ id: 'referrals' })} (${
                  matches?.registrations.length ? totalCounts?.registration ?? 0 : 0
                })`}
                value={SearchTabs.registrations}
                aria-controls='referrals'
                sx={{
                  borderRadius: '0px 8px 8px 0px',
                  '&.Mui-selected': {
                    marginLeft: '-1px',
                    zIndex: 2
                  }
                }}
              />
            </Tabs>
            {tab === SearchTabs.contractors && (
              <Results
                data={matches?.contractors}
                hasSimilarResults={hasSimilarResults?.contractors}
                onOptionClick={(match) => {
                  setSearch(match);
                  navigateWithTenant(pageToNavigate, { state: { search: match } });
                  clearTab();
                }}
                onViewAllClick={() => {
                  navigateWithTenant(pageToNavigate, { state: { search } });
                  clearTab();
                }}
              />
            )}
            {tab === SearchTabs.registrations && (
              <Results
                hasSimilarResults={hasSimilarResults?.registrations}
                data={matches?.registrations}
                onOptionClick={(match) => {
                  setSearch(match);
                  navigateWithTenant(pageToNavigate, { state: { search: match } });
                  clearTab();
                }}
                onViewAllClick={() => {
                  navigateWithTenant(pageToNavigate, { state: { search } });
                  clearTab();
                }}
              />
            )}
          </Box>
        )}
      </>
    </ClickAwayListener>
  );
};
