import { AllowedCharacters, type OptionInput, QuestionComponent } from '__graphql__/globalTypes';
import { type CreateQuestionVariables } from 'shared/hooks/useFormBuilder/__graphql__/CreateQuestion';
import { FileFormat } from 'shared/types';
import { capitalizeFirstLetter } from 'shared/utils/strings';

const COMPONENTS_TEXT = {
  [QuestionComponent.HEADER_1]: 'Header text',
  [QuestionComponent.HEADER_2]: 'Header text',
  [QuestionComponent.HEADER_3]: 'Header text',
  [QuestionComponent.FILE_UPLOAD]: 'Select file or drag and drop',
  [QuestionComponent.CONTRACT]: null,
  [QuestionComponent.ASSIGNMENT_SCHEDULE]: null,
  [QuestionComponent.DOCUMENT]: null,
  [QuestionComponent.PARAGRAPH]:
    // eslint-disable-next-line max-len
    'Lorem ipsum dolor sit amet consectetur. Mi egestas sit purus netus tortor odio tempus porttitor. Commodo venenatis diam euismod ut suspendisse ut. '
} as Record<QuestionComponent, string | null>;

export const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const DAY_OPTIONS = DAYS.map((d) => ({
  optionId: d,
  label: capitalizeFirstLetter(d),
  value: `${d}/null_null`
}));

const DEFAULT_OPTIONS = [
  { optionId: '1', value: 'Option 1', label: 'Option 1' },
  { optionId: '2', value: 'Option 2', label: 'Option 2' },
  { optionId: '3', value: 'Option 3', label: 'Option 3' }
];

export const FILE_FORMATS_OPTIONS = [
  { optionId: '1', value: 'true', label: FileFormat.PDF },
  { optionId: '2', value: 'true', label: FileFormat.DOC_DOCX },
  { optionId: '3', value: 'true', label: FileFormat.JPG_JPEG },
  { optionId: '4', value: 'true', label: FileFormat.PNG },
  { optionId: '5', value: 'true', label: FileFormat.CSV }
];

const COMPONENTS_WITH_OPTIONS = {
  [QuestionComponent.SINGLE_SELECT]: DEFAULT_OPTIONS,
  [QuestionComponent.MULTIPLE_SELECT]: DEFAULT_OPTIONS,
  [QuestionComponent.OPTIONS_SMALL]: DEFAULT_OPTIONS,
  [QuestionComponent.OPTIONS_BIG]: DEFAULT_OPTIONS,
  [QuestionComponent.DAY]: DAY_OPTIONS,
  [QuestionComponent.FILE_UPLOAD]: FILE_FORMATS_OPTIONS
} as Record<QuestionComponent, OptionInput[]>;

const INPUT_COMPONENTS = [QuestionComponent.SHORT_TEXT, QuestionComponent.LONG_TEXT];

const CHAR_LIMIT = {
  [QuestionComponent.SHORT_TEXT]: { minChar: 3, maxChar: 60 },
  [QuestionComponent.LONG_TEXT]: { minChar: 3, maxChar: 60 }
} as Record<QuestionComponent, Record<'minChar' | 'maxChar', number>>;

interface ComponentArgs {
  component: QuestionComponent;
  sectionId: string;
  order: number;
}

export function getCreateComponentData(args: ComponentArgs): CreateQuestionVariables['data'] {
  const { component, sectionId, order } = args;
  const charLimits = CHAR_LIMIT[component] ?? {};

  return {
    text: COMPONENTS_TEXT[component] !== undefined ? COMPONENTS_TEXT[component] : 'Text',
    additionalText:
      component === QuestionComponent.FILE_UPLOAD
        ? `${COMPONENTS_WITH_OPTIONS[component].map(({ label }) => label).join(', ')} max 5Mb file`
        : null,
    options: COMPONENTS_WITH_OPTIONS[component],
    component,
    sectionId,
    order,
    ...charLimits,
    allowedCharacters: INPUT_COMPONENTS.includes(component) ? AllowedCharacters.ALL : null
  };
}

export function shouldBeUniqueComponent(componentType: QuestionComponent): boolean {
  return [QuestionComponent.ASSIGNMENT_SCHEDULE, QuestionComponent.CONTRACT].includes(componentType);
}
